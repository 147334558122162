import React, { useEffect } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { remove_underscore_capitalize } from "../../constants/utils";

export function Custom_Button({
  isPrimary = false,
  btnLabel,
  className,
  click,
  isLoading = false,
  style,
  hardText = false,
  borderColor = "white",
}) {
  useEffect(() => {
  }, []);
  useEffect(() => {}, [isLoading]);
  return (
    <button
      onClick={click}
      style={{ minWidth: "130px", ...style, width: isLoading && "130px" }}
      disabled={isLoading}
      className={`${className} ${
        isPrimary ? "custom_pri_btn" : "custom_sec_btn"
      }`}
    >
      {isLoading ? (
        <Spin
          style={{ color: `${borderColor}` }}
          indicator={
            <LoadingOutlined
              className="custom_antd_spinner"
              style={{
                fontSize: 16,
              }}
            />
          }
        />
      ) : (
        <div>
          {hardText ? btnLabel : remove_underscore_capitalize(btnLabel)}
        </div>
      )}
    </button>
  );
}

export function Button_without_Border({
  hardText = false,
  btnLabel,
  isLoading = false,
  click,
  isApiCallPending = false,
  style,
}) {
  return (
    <button
      style={{ ...style }}
      onClick={click}
      disabled={isApiCallPending}
      className="custom_btn_no_border"
    >
      {isLoading ? (
        <Spin
          style={{ color: "white" }}
          indicator={
            <LoadingOutlined
              className="custom_antd_spinner"
              style={{
                fontSize: 16,
              }}
            />
          }
        />
      ) : (
        <div>
          {hardText ? btnLabel : remove_underscore_capitalize(btnLabel)}
        </div>
      )}
    </button>
  );
}
