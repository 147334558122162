import { Component } from "react";
import redLine from "../../../src/assets/img/icons/red_line.svg";
import yellowLine from "../../../src/assets/img/icons/yellow_line.svg";
import orangeLine from "../../../src/assets/img/icons/orange_line.svg";
import greenLine from "../../../src/assets/img/icons/green_line.svg";
import allProjIcon from "../../../src/assets/img/icons/all_project.svg";
import overallRevenueIcon from "../../../src/assets/img/icons/overall_revenue.svg";
import infoIcon from "../../assets/img/icons/info_1.svg";
import ReactTooltip from "react-tooltip";
import ProjectTable from "components/ProjectTable/ProjectTable";
import projectTimeline from "../../../src/assets/img/brand/projectTimeline.svg";
import showMore from "../../assets/img/brand/showMore.svg";
import ApiService from "constants/ApiService";
import AddProject from "../Project/addProject";
import {
  Card,
  Table,
  Container,
  Row,
  Breadcrumb,
  Col,
  Button,
  CardBody,
  Spinner,
  DropdownMenu,
  DropdownItem,
  Media,
  UncontrolledDropdown,
  DropdownToggle,
} from "reactstrap";
import CountUp from "react-countup";
import activeProjIcon from "../../../src/assets/img/icons/active_projects.svg";
import completedProjIcon from "../../../src/assets/img/icons/completed_projects.svg";
import ProjectChart from "components/Charts/ProjectChart";
import moment from "moment";
import { Progress } from "reactstrap";
import TimeLine from "components/Timeline/Timeline";
import CustomCard from "components/Cards/Card";
import {
  remove_underscore_capitalize,
  getKeyValuesFromArray,
  showUtcDate,
} from "../../constants/utils";
import CustomContainer from "components/Container/CustomContainer";
import CustomTable from "components/Table/CustomeTable";
import { ListingHeaderLayout } from "components/Container/CustomListingContainer";
import { CustomListingContainer } from "components/Container/CustomListingContainer";
import { CustomDropDown } from "components/FilterFields/Filterfields";
import ToolTip from "components/ToolTip/ToolTip";
// import customCard from "../../components/Cards/Card"
const headerCards = [
  {
    name: "all_projects",
    image: orangeLine,
    value: 0,
    footerImg: allProjIcon,
    path: "all",
  },
  {
    name: "active_projects",
    image: greenLine,
    value: 0,
    footerImg: activeProjIcon,
    path: "active",
  },
  {
    name: "completed_projects",
    image: yellowLine,
    value: 0,
    footerImg: completedProjIcon,
    path: "completed",
  },
  {
    name: "all_tasks",
    image: redLine,
    value: 0,
    footerImg: allProjIcon,
    path: "",
  },
];
const expensesCards = [
  {
    name: "total",
    value: 0,
    total: 0,
    toolTip: "overall payments and invoices",
  },
  {
    name: "paid",
    value: 0,
    total: 0,
    toolTip: "all paid payments and invoices",
  },
  {
    name: "expense",
    value: 0,
    total: 0,
    toolTip: "overall expenses",
  },
  {
    name: "net",
    value: 0,
    total: 0,
    toolTip: "paid payments + paid invoices - expenses",
  },
];
// const responseArray = [
//   {
//     id: "hari",
//     profile_name: "harisankaran",
//     date_of_birth: "19-08-1997",
//     email: "harisa@123.com",
//     sign: true,
//   },
//   {
//     id: "gug",
//     profile_name: "gugasri",
//     date_of_birth: "02-12-2000",
//     email: "gugasri@123.com",
//   },
//   { id: "asd", profile_name: "asdf", date_of_birth: "sdf", email: "dsf" },
//   { id: "asdss", profile_name: "asdf", date_of_birth: "sdf", email: "dsf" },
//   { id: "asd", profile_name: "asdf", date_of_birth: "sdf", email: "dsf" },
//   { id: "asdss", profile_name: "123", date_of_birth: "sdf", email: "dsf" },
// ];
// const columns = [
//   {
//     head: "ID",
//     body: "id",
//   },
//   {
//     head: "name",
//     body: "profile_name",
//   },
//   {
//     head: "dob",
//     body: "date_of_birth",
//   },
//   {
//     head: "mail",
//     body: "email",
//   },
//   {
//     head: "edit",
//     body: "edit",
//     render: () => (
//       <>
//         <button onClick={this.handleClick}>Click Me</button>
//       </>
//     ),
//   },
// ];
export default class Overview extends Component {
  responseArray = [
    {
      _id: "hari",
      profile_name: "harisankaran",
      dob: "19-08-1997",
      email: "harisa@123.com",
      sign: true,
    },
    {
      _id: "gug",
      profile_name: "gugasri",
      dob: "02-12-2000",
      email: "gugasri@123.com",
    },
    { _id: "asd", profile_name: "asdf", dob: "sdf", email: "dsf" },
    { _id: "asdss", profile_name: "asdf", dob: "sdf", email: "dsf" },
    { _id: "asd", profile_name: "asdf", dob: "sdf", email: "dsf" },
    { _id: "asdss", profile_name: "123", dob: "sdf", email: "dsf" },
  ];
  columns = [
    {
      head: "ID",
      body: "id",
    },
    {
      head: "name",
      body: "profile_name",
    },
    {
      head: "dob",
      body: "date_of_birth",
    },
    {
      head: "mail",
      body: "email",
    },
    {
      head: "edit",
      body: "edit",
      render: (index) => (
        <>
          <button onClick={() => this.handleClick(index)}>Click Me</button>
        </>
      ),
    },
  ];
  constructor(props) {
    super(props);
    this.state = {
      all_projects: 0,
      active_projects: 0,
      completed_projects: 0,
      all_tasks: 0,
      type: "payment",
      statusSearch: this.props.location?.search ? "pending" : "",
      project_list: [],
      selectedTab: "",
      isTimeLineOpen: false,
      project_id: "",
      filter: "year",
      open: "",
      cardDetails: headerCards,
      expensesDetails: expensesCards,
    };
  }
  componentDidMount() {
    this.getProjectList();
  }
  handleClick(index) { }
  selectCard = (status) => {
    status &&
      this.props.props.props.history.push({
        pathname: `projects`,
        search: status === "all" ? "" : `?limit=10&page=1&&status=${status}`,
      });
  };
  getProjectList = async () => {
    let tempArray = [];
    const convertedObj = getKeyValuesFromArray(headerCards, "name");
    let response = await ApiService.getAPI(`contractor/project/dashboard`);
    let projectListResponse = await ApiService.getAPI(`contractor/project`);
    this.setState({ isLoading: false });
    if (projectListResponse.success) {
      this.setState({
        project_list: projectListResponse.data.projects.slice(0, 5),
      });
    }
    if (response.success) {
      let obj = response.data;
      Object.keys(obj).map((str) => {
        convertedObj[str].value = obj[str];
        tempArray.push(convertedObj[str]);
      });
      this.setState({ cardDetails: tempArray });
    }
  };
  closeTimeline = () => {
    this.setState({ isTimeLineOpen: false, project_id: "" });
  };
  redirectDetailPage = (id) => {
    this.props.props.props.history.push({
      pathname: `projects/detail/${id}`,
      state: { from: "overview" },
    });
  };

  ProjectList = () => {
    this.props.props.props.history.push("/admin/projects");
  };

  toPayemntPage = () => {
    this.props.props.props.history.push("/admin/overview_payment");
  };

  editProject = async (data) => {
    this.setState(
      {
        project_id: data
      },
      () => {
        this.setState({ open: true });
      }
    );
  };

  capitalizeFirstLetter = (str) => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  onCancelAddProjectPanel = () => {
    this.setState({ open: false });
  };

  onClose = () => {
    this.setState({
      open: false,
      project_id: "",
    });
    this.getProjectList();
  };

  setChartStats = (value) => {
    this.setState({ type: value });
  };

  setChartReccucringStats = (value) => {
    this.setState({ filter: "this_year" ? "year" : "month" });
  };

  render() {
    let {
      statusSearch,
      project_list,
      isTimeLineOpen,
      open,
      cardDetails,
      expensesDetails,
      column,
      filter,
      type,
    } = this.state;

    return (
      <>
        <ListingHeaderLayout
          primary_buttonLabel={"Add Project"}
          isPrimary
          label={"Overview"}
          click={() => {
            this.props.props.props.history.push({
              pathname: "projects/add_project",
              state: {
                type: "overview",
              },
            });
          }}
          mainClass="overview_header"
        ></ListingHeaderLayout>
        <div
          className="primary-background-color"
        // style={{ paddingTop: "24px" }}
        >
          <div className="header-body">
            <Row className="row-center">
              {cardDetails.map((obj) => (
                <Col
                  lg="6"
                  xl="3"
                  className="max-dash mb_mt_28 mb_mt_28 bread-cursor"
                >
                  <CustomCard onClick={() => this.selectCard(obj.path)}>
                    {/* <div style={{ width: "100%" }}> */}{" "}
                    <div
                      className="dash_content dash_content"
                      style={{ alignItems: "center" }}
                    >
                      <div
                        className="dash_left"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "1rem",
                        }}
                      >
                        <div>
                          <span className="project_card_sub">
                            {remove_underscore_capitalize(obj.name)}
                          </span>
                        </div>
                        <label className="point project_card_number">
                          <CountUp start={0} end={obj.value} separator="," />
                        </label>
                      </div>
                      <div>
                        <img src={obj.image} alt="total sales" />
                      </div>
                    </div>
                    <div
                      style={{
                        borderBottom: "1px solid #e1e1e1",
                        width: "100%",
                        margin: "1rem 0",
                      }}
                    ></div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          color: "#899aa7",
                          fontSize: "13px",
                          fontWeight: "500",
                        }}
                      >
                        To Date
                      </div>{" "}
                      <div>
                        {" "}
                        <img
                          src={allProjIcon}
                          alt="total sales"
                          style={{
                            height: "32px",
                            width: "32px",
                          }}
                        />
                      </div>
                    </div>
                    {/* </div> */}
                  </CustomCard>
                </Col>
              ))}
            </Row>
          </div>
          {/* <div className="single_card_big">
            {expensesDetails.map((obj) => (
              <div className="single_card">
                <div className="card_header">
                  <div className="head">
                    <div>{remove_underscore_capitalize(obj.name)}</div>
                    <div className="ml-10">
                      <img
                        src={infoIcon}
                        className="mr-10 cursor-point"
                        data-tip={remove_underscore_capitalize(obj.toolTip)}
                        alt="total"
                        data-iscapture="true"
                        data-for="syncInfo"
                        data-place="top"
                      />
                      <ReactTooltip
                        place="left"
                        type="info"
                        effect="solid"
                        id="syncInfo"
                        html={true}
                      />
                    </div>
                  </div>
                  <div className="card_value">${obj.value}</div>
                  <div className="card_footer">1 this month</div>
                </div>
              </div>
            ))}
          </div> */}
          {/* <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              columnGap: "28px",
              marginBottom: "1.8rem",
            }}
          > */}
          {/* <CustomContainer style={{ paddingTop: "10px" }}>
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: "2rem",
                  }}
                >
                  <span className="containerListing_name">Payments</span>
                  <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
                    <CustomDropDown
                      maxwidth={"130px"}
                      statusList={["projects", "payments"]}
                      status={type}
                      setStatus={this.setChartStats}
                    />
                    <CustomDropDown
                      innerStyle={{ marginLeft: '6px' }}
                      maxwidth={"130px"}
                      statusList={["this_year", "this_month"]}
                      status={filter === "year" ? "This Year" : "This Month"}
                      setStatus={this.setChartReccucringStats}
                    />
                  </div>
                </div>
                <ProjectChart />
              </>
            </CustomContainer> */}

          {/* <CustomListingContainer>
              <ListingHeaderLayout
                sec_buttonLabel={"View All"}
                label={"Payments"}
                click={() => this.toPayemntPage()}
              ></ListingHeaderLayout>
              <ProjectTable />
            </CustomListingContainer> */}
          {/* </div> */}

          <CustomListingContainer>
            <ListingHeaderLayout
              sec_buttonLabel={"View All"}
              label={"Projects"}
              click={this.ProjectList}
            ></ListingHeaderLayout>
            <div style={{ margin: "0", display: "block" }}>
              <Card className="shadow card_with_crm">
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th
                        scope="col"
                        className="w_13"
                        style={{ paddingLeft: "1.5rem" }}
                      >
                        ID
                      </th>
                      <th
                        // style={{ width: "15%" }}
                        scope="col"
                        className="w_16"
                      >
                        Name
                      </th>
                      <th className="w_16" scope="col">
                        Client
                      </th>
                      <th className="w_16" scope="col">
                        Due On
                      </th>
                      <th className="w_16" scope="col">
                        Progress
                      </th>
                      <th className="w_8" scope="col">
                        Status
                      </th>
                      <th className="w_2_5" scope="col"></th>
                      {/* {project_list.length ? (
                        <th
                          className=""
                          // style={{ width: "4%", cursor: "pointer" }}
                          scope="col"
                        ></th>
                      ) : (
                        <>
                          <th className="" style={{ width: "0%" }}></th>
                          <th className="" style={{ width: "20%" }}></th>
                          <th className="" style={{ width: "20%" }}></th>
                        </>
                      )} */}
                    </tr>
                  </thead>
                  {project_list && project_list.length !== 0 ? (
                    <tbody>
                      {project_list.map((project, i) => (
                        <tr key={i}>
                          <td
                            className="cursor-point capitalize"
                            onClick={() =>
                              this.redirectDetailPage(project.project_id)
                            }
                          >
                            <div className="project_list_content">
                              {project.project_id}
                            </div>
                          </td>
                          <td
                            className="cursor-point capitalize"
                            onClick={() =>
                              this.redirectDetailPage(project.project_id)
                            }
                          >
                            <div className="project_list_content">
                              {this.capitalizeFirstLetter(project.name)}
                            </div>
                          </td>
                          <td>
                            <div className="project_list_content">
                              {this.capitalizeFirstLetter(
                                project?.client?.name
                              )}
                            </div>
                          </td>

                          <td>
                            <div className="project_list_content">
                              {showUtcDate(project.end_date)}
                            </div>
                          </td>
                          <td
                            className={
                              project?.invoice?.status === "paid" ||
                                project?.invoice?.status === "assigned"
                                ? "text-capitalize status_accepted general_status_style"
                                : project?.invoice?.status === "cancelled" ||
                                  project?.invoice?.status === "rejected"
                                  ? "text-capitalize red general_status_style"
                                  : project?.invoice?.status === "created" ||
                                    project?.invoice?.status === "pending"
                                    ? "text-capitalize yellow general_status_style"
                                    : project?.invoice?.status === "completed"
                                      ? "text-capitalize status_completed general_status_style"
                                      : project?.invoice?.status === "closed"
                                        ? "text-capitalize primary general_status_style"
                                        : "text-capitalize"
                            }
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "4%",
                              }}
                            >
                              {" "}
                              <div>
                                {" "}
                                <Progress
                                  color="#0A8080"
                                  value={Math.round(project.progress ?? 0)}
                                />
                              </div>
                              <div className="project_list_percentage">
                                {Math.round(project.progress ?? 0)} %
                              </div>
                            </div>
                          </td>

                          <td>
                            <div
                              className="project_list_status"
                              style={{
                                color: `${project.status === "completed" ||
                                  project.status === "active" ||
                                  project.status === "closed"
                                  ? "#07A84E"
                                  : project.status === "discontinued" ||
                                    project.status === "cancelled"
                                    ? "#f75f18"
                                    : project.status === "due"
                                      ? "#0070FD"
                                      : "#FFCC3D"
                                  }`,
                              }}
                            >
                              {" "}
                              {this.capitalizeFirstLetter(
                                project.status === "on_hold"
                                  ? "On Hold"
                                  : project.status
                              )}
                            </div>
                          </td>

                          <td
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "20px",
                              borderTop: "none",
                            }}
                          >
                            <div>
                              <ToolTip label="Timeline">
                                <img
                                  onClick={() =>
                                    this.setState({
                                      isTimeLineOpen: true,
                                      project_id: project.project_id,
                                    })
                                  }
                                  alt="timeline"
                                  style={{ cursor: "pointer" }}
                                  src={projectTimeline}
                                ></img>
                              </ToolTip>
                            </div>
                            {project.status !== "closed" &&
                              project.status !== "cancelled" && (
                                <div className="">
                                  <UncontrolledDropdown
                                    nav
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <DropdownToggle
                                      className="pr-0 nav-notice"
                                      nav
                                    >
                                      <img src={showMore} alt="show more" />
                                    </DropdownToggle>
                                    <DropdownMenu
                                      className="dropdown-menu-arrow min-width-drop"
                                      right
                                    >
                                      <DropdownItem
                                        onClick={() =>
                                          this.editProject(project)
                                        }
                                      >
                                        <span>Edit</span>
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </div>
                              )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td className="no-data">No Projects Available</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  )}
                </Table>
              </Card>
            </div>
          </CustomListingContainer>
        </div>
        <Card className={isTimeLineOpen ? "exportSideBar" : "toggle-sidebar"}>
          {isTimeLineOpen && (
            <TimeLine
              from={"project"}
              id={this.state.project_id}
              closeTimelineModal={this.closeTimeline}
            ></TimeLine>
          )}
        </Card>
        <Card className={this.state.open ? "exportSideBar" : "toggle-sidebar"}>
          {open && (
            <AddProject
              type="project"
              props={this}
              projectId={this.state.project_id}
              close={() => this.onClose()}
              cancel={this.onCancelAddProjectPanel}
            />
          )}
        </Card>
      </>
    );
  }
}
