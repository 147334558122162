import { Component } from "react";
import {
  Spinner,
  Container,
  Breadcrumb,
  Row,
  Col,
  Card,
  BreadcrumbItem,
  Button,
  InputGroup,
  Dropdown,
  Input,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  DropdownMenu,
  DropdownItem,
  CardHeader,
  CardBody,
  Form,
  Table,
  FormGroup,
} from "reactstrap";
import Arrow from "../../../assets/img/icons/common/next.svg";
export default class ClientProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initPageLoading: true,
      isOpenToggle: true,
      name: "",
      email: "",
      phone_number: "",
      company_name: "",
      website: "",
      isAddressToggle: true,
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      zipCode: "",
      country: "",
    };
  }

  componentDidMount = () => {
    this.getProfileDetails();
    
  };

  getProfileDetails = () => {
    this.setState({ initPageLoading: false });
  };

  toggleCollapse = (data) => {
    if (data === "info") {
      this.setState((prevState) => ({
        isOpenToggle: !prevState.isOpenToggle,
      }));
    } else if (data === "address") {
      this.setState((prevState) => ({
        isAddressToggle: !prevState.isAddressToggle,
      }));
    }
  };

  render() {
    let {
      initPageLoading,
      isOpenToggle,
      isAddressToggle,
      name,
      email,
      phone_number,
      company_name,
      website,
      addressLine1,
      addressLine2,
      city,
      state,
      zipCode,
      country,
    } = this.state;
    return (
      <>
        <div className="clientBody">
          <div>
            <Breadcrumb></Breadcrumb>
          </div>
          <Row style={{ padding: "1%" }} className="">
            <Col xs="" className="manage-con">
              <Breadcrumb>
                <BreadcrumbItem
                  onClick={() => this.props.history.push("/client/123")}
                  className="bread-cursor"
                >
                  Project
                </BreadcrumbItem>
                <BreadcrumbItem active style={{ color: "#72569c" }}>Profile</BreadcrumbItem>
              </Breadcrumb>
            </Col>
          </Row>
          {initPageLoading ? (
            <>
              <div className="spin-loading mt-8">
                <Spinner type="border" color="#077e8b" />
              </div>
            </>
          ) : (
            <div style={{ marginTop: "20px" }}>
              <Row>
                <Row style={{ width: "100%", marginLeft: "0px" }}>
                  <Col sm="12">
                    <Card className="bg-secondary shadow">
                      <CardHeader className="border-0 pad-r-3">
                        <Row
                          className="align-items-center"
                          style={{ paddingLeft: "1rem" }}
                        >
                          <Col xs="8" style={{ display: "flex", gap: "1rem" }}>
                            <div
                              onClick={() => this.toggleCollapse("info")}
                              style={{ cursor: "pointer" }}
                            >
                              {" "}
                              {isOpenToggle ? (
                                <img
                                  src={Arrow}
                                  alt="up arrow"
                                  style={{ transform: "rotate(90deg)" }}
                                />
                              ) : (
                                <img
                                  src={Arrow}
                                  alt="up arrow"
                                  style={{ transform: "rotate(270deg)" }}
                                />
                              )}
                            </div>
                            <h3 className="mb-0 mt_0">Basic Information</h3>
                          </Col>
                        </Row>
                      </CardHeader>
                      {isOpenToggle && (
                        <CardBody style={{ paddingLeft: "2.2rem" }}>
                          <Form>
                            <div>
                              <Row>
                                <Col lg="4">
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="email"
                                    >
                                      Name
                                    </label>

                                    <Input
                                      className="form-control-alternative"
                                      name="email"
                                      value={name || ""}
                                      id="email"
                                      placeholder="Name"
                                      type="email"
                                      onChange={(e) =>
                                        this.setState({ name: e.target.value })
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                                <Col lg="4">
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="email"
                                    >
                                      Email
                                    </label>

                                    <Input
                                      className="form-control-alternative"
                                      name="email"
                                      value={email || ""}
                                      id="email"
                                      placeholder="Email"
                                      type="email"
                                      onChange={(e) =>
                                        this.setState({ email: e.target.value })
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                                <Col lg="4">
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="phone"
                                    >
                                      Phone Number
                                    </label>
                                    <Input
                                      className="form-control-alternative"
                                      name="phone"
                                      value={phone_number || ""}
                                      id="phone"
                                      onChange={(e) =>
                                        this.setState({
                                          phone_number: e.target.value,
                                        })
                                      }
                                      placeholder="Phone Number"
                                      type="text"
                                      maxLength="16"
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="4">
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="subject"
                                    >
                                      Company Name
                                    </label>
                                    <Input
                                      className="form-control-alternative"
                                      value={phone_number || ""}
                                      onChange={(e) =>
                                        this.setState({
                                          phone_number: e.target.value,
                                        })
                                      }
                                      name="subject"
                                      id="subject"
                                      placeholder="Subject"
                                    />
                                  </FormGroup>
                                </Col>
                                <Col lg="4">
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="subject"
                                    >
                                      WebSite
                                    </label>
                                    <Input
                                      className="form-control-alternative"
                                      value={website || ""}
                                      onChange={(e) =>
                                        this.setState({
                                          website: e.target.value,
                                        })
                                      }
                                      name="subject"
                                      id="subject"
                                      placeholder="Subject"
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                            </div>
                          </Form>
                        </CardBody>
                      )}
                    </Card>
                  </Col>
                </Row>
              </Row>

              <Row style={{marginTop:'40px'}}>
                <Row style={{ width: "100%", marginLeft: "0px" }}>
                  <Col sm="12">
                    <Card className="bg-secondary shadow">
                      <CardHeader className="border-0 pad-r-3">
                        <Row
                          className="align-items-center"
                          style={{ paddingLeft: "1rem" }}
                        >
                          <Col xs="8" style={{ display: "flex", gap: "1rem" }}>
                            <div
                              onClick={() => this.toggleCollapse("address")}
                              style={{ cursor: "pointer" }}
                            >
                              {" "}
                              {isAddressToggle ? (
                                <img
                                  src={Arrow}
                                  alt="up arrow"
                                  style={{ transform: "rotate(90deg)" }}
                                />
                              ) : (
                                <img
                                  src={Arrow}
                                  alt="up arrow"
                                  style={{ transform: "rotate(270deg)" }}
                                />
                              )}
                            </div>
                            <h3 className="mb-0 mt_0">Address</h3>
                          </Col>
                        </Row>
                      </CardHeader>
                      {isAddressToggle && (
                        <CardBody style={{ paddingLeft: "2.2rem" }}>
                          <Form>
                            <div>
                              <Row>
                                <Col lg="4">
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="email"
                                    >
                                      Address Line 1*
                                    </label>

                                    <Input
                                      className="form-control-alternative"
                                      name="email"
                                      value={addressLine1 || ""}
                                      id="email"
                                      placeholder="Name"
                                      type="email"
                                      onChange={(e) =>
                                        this.setState({ addressLine1: e.target.value })
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                                <Col lg="4">
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="email"
                                    >
                                      Address Line 2 (optional)
                                    </label>

                                    <Input
                                      className="form-control-alternative"
                                      name="email"
                                      value={addressLine2 || ""}
                                      id="email"
                                      placeholder="Email"
                                      type="email"
                                      onChange={(e) =>
                                        this.setState({ addressLine2: e.target.value })
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                                <Col lg="4">
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="phone"
                                    >
                                      City*
                                    </label>
                                    <Input
                                      className="form-control-alternative"
                                      name="phone"
                                      value={city || ""}
                                      id="phone"
                                      onChange={(e) =>
                                        this.setState({
                                            city: e.target.value,
                                        })
                                      }
                                      placeholder="Phone Number"
                                      type="text"
                                      maxLength="16"
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="4">
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="subject"
                                    >
                                      State *
                                    </label>
                                    <Input
                                      className="form-control-alternative"
                                      value={state || ""}
                                      onChange={(e) =>
                                        this.setState({
                                            state: e.target.value,
                                        })
                                      }
                                      name="subject"
                                      id="subject"
                                      placeholder="Subject"
                                    />
                                  </FormGroup>
                                </Col>
                                <Col lg="4">
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="subject"
                                    >
                                      Zip Code *
                                    </label>
                                    <Input
                                      className="form-control-alternative"
                                      value={zipCode || ""}
                                      onChange={(e) =>
                                        this.setState({
                                            zipCode: e.target.value,
                                        })
                                      }
                                      name="subject"
                                      id="subject"
                                      placeholder="Subject"
                                    />
                                  </FormGroup>
                                </Col>
                                <Col lg="4">
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="subject"
                                    >
                                      Country *
                                    </label>
                                    <Input
                                      className="form-control-alternative"
                                      value={country || ""}
                                      onChange={(e) =>
                                        this.setState({
                                            country: e.target.value,
                                        })
                                      }
                                      name="subject"
                                      id="subject"
                                      placeholder="Subject"
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                            </div>
                          </Form>
                        </CardBody>
                      )}
                    </Card>
                  </Col>
                </Row>
              </Row>
            </div>
          )}
        </div>
      </>
    );
  }
}
