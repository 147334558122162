import {
  Badge,
  Card,
  Table,
  Container,
  Row,
  Breadcrumb,
  Col,
  Button,
  InputGroup,
  InputGroupText,
  CardBody,
  Spinner,
  DropdownMenu,
  DropdownItem,
  Media,
  UncontrolledDropdown,
  DropdownToggle,
  Dropdown,
  Input,
  Modal,
  BreadcrumbItem,
} from "reactstrap";
// core components
import ApiService from "constants/ApiService";
import { returnFirstDegreeObjValue } from "constants/utils";
import { showUtcDate } from "constants/utils";
import ToastList from "components/Toast/ToastList";
import { CustomListingContainer } from "components/Container/CustomListingContainer";
import { ListingHeaderLayout } from "components/Container/CustomListingContainer";
import { CustomSearch } from "components/FilterFields/Filterfields";
import { Button_without_Border } from "components/Buttons/Button";
import projectTimeline from "../../assets/img/brand/projectTimeline.svg";
import showMore from "../../assets/img/brand/showMore.svg";
import { showSuccess } from "constants/utils";
import closeIcon from "../../assets/img/icons/closeIcon.svg";
import { Custom_Button } from "components/Buttons/Button";
import { Component } from "react";
import { formatAmountWithCommas } from "constants/utils";
import ToolTip from "components/ToolTip/ToolTip";
import AddChangeOrder from "./addChangeOrder";
import timeLineSideBar from "views/Invoices/timeLineSideBar";
import TimeLine from "components/Timeline/Timeline";
import DeleteModal from "./DeleteModal";
import { showToast } from "constants/utils";
import { CopyEstimateLink } from "constants/utils";
import { downloadDocuments } from "constants/utils";
import { showError } from "constants/utils";
import { returnSearchObject } from "constants/utils";
import { pushToFilteredPage } from "constants/utils";
import { buildQueryString } from "constants/utils";
import { filterQuery } from "constants/utils";
import { buildQueryStringAnd } from "constants/utils";
let ID;
const styleForDropDown = { padding: "0.7rem 1rem" };
class WorkOrderList extends Component {
  constructor(props) {
    super(props);
    ID = this.props.match.params.project_id;
    this.state = {
      workOrderLists: [],
      isLoading: true,
      toast: "",
      response: "",
      toastType: "",
      input_value: false,
      inputSearch: "",
      total: 0,
      page: 1,
      ID: "",
      openWorkOrder: false,
      orderId: "",
      timeLine: {},
      timeLineOpen: false,
      deleteModal: false,
      workOrderId: null,
      isWorkOrders: null,
      toast: {
        toast: false,
        toastType: "",
        response: "",
      },
      query: {},
    };
  }
  componentDidMount = async () => {
    this.setQueryStatus();
  };

  setQueryStatus = () => {
    let queryStatus = this.props.location.search;
    let queryObj = returnSearchObject(queryStatus);
    if (queryStatus) {
      this.setState(
        {
          statusSearch: queryObj.status ?? "",
          page: parseInt(queryObj.page) ?? "",
          query: returnSearchObject(queryStatus),
        },
        () => {
          pushToFilteredPage(this.props, { ...this.state.query });
          this.getWorkOrderList();
        }
      );
    } else {
      this.setState(
        {
          query: {
            ...queryObj,
            page: 1,
            limit: 10,
          },
        },
        () => {
          pushToFilteredPage(this.props, { ...this.state.query });
          this.getWorkOrderList();
        }
      );
    }
  };
  
  getWorkOrderList = async () => {
    let { filterOption, inputSearch, statusSearch, query } = this.state;
    let queryParams = "";
    if (filterOption !== "" && inputSearch !== "")
      queryParams += filterQuery(filterOption, inputSearch);
    if (statusSearch !== "") {
      queryParams += filterQuery("status", statusSearch);
    } else this.setState({ isLoading: true });
    let response = await ApiService.getAPI(
      `contractor/workOrder?project_id=${ID}${buildQueryString(
        this.state.query,
        "&"
      )}`
    );
    this.setState({ isLoading: false });
    if (response.success) {
      this.setState({ workOrderLists: response.data });
    }
  };
  openChangeOrderDetail = (e, id) => {
    this.setState({ orderId: id, openWorkOrder: true });
  };
  closeChangeOrder = (val) => {
    this.setState({ orderId: "", openWorkOrder: false });
    val && this.getWorkOrderList();
  };
  handleIncrementPage = () => {
    if (this.state.workOrderLists.length >= 10) {
      this.setState({ page: this.state.page + 1, input_value: true }, () =>
        this.handleFilterStatus()
      );
    }
  };
  handleDecrementPage = () => {
    if (this.state.page > 1) {
      this.setState(
        {
          page: this.state.page - 1,
          input_value: this.state.page - 1 === 1 ? false : true,
        },
        () => this.handleFilterStatus()
      );
    } else this.setState({ input_value: false });
  };
  handleFilterStatus = () => {
    let staticQuery = returnSearchObject(this.props.location.search);
    staticQuery.status = this.state.statusSearch ?? "";
    staticQuery.page = this.state.page ?? "";
    if (this.state.inputSearch !== "" && this.state.filterOption !== "") {
      staticQuery[this.state.filterOption] = this.state.inputSearch;
    } else staticQuery[this.state.filterOption] = "";
    this.setState({ query: staticQuery }, () => {
      pushToFilteredPage(this.props, { ...this.state.query });
      this.getChangeOrderList();
    });
  };
  openChangeOrders = () => {
    this.setState({ openWorkOrder: true });
  };
  openChangeOrderDetail = (e, id) => {
    this.setState({ orderId: id, openWorkOrder: true });
  };
  // cancelWorkOrder = (id)=> {
  //     setCancelModal(true);
  //     setChangeOrderId(id);
  //   }
  onTimeLineClick = (obj, from) => {
    this.setState({
      timeLineOpen: true,
      timeLine: {
        ...this.state.timeLine,
        id: obj.workOrder_id,
        from: from,
      },
    });
  };
  addSplitUp = (order) => {
    this.props.history.push({
      pathname: `/admin/projects/detail/workOrder/${ID}`,
      search: `?workOrder_id=${order.workOrder_id}`,
      state: { splitUp: true },
    });
  };
  closeTimeline = () => {
    this.setState({
      timeLineOpen: false,
      timeLine: {
        ...this.state.timeLine,
        id: "",
        from: "",
      },
    });
  };
  calculateTotalAmount = (array) => {
    return array.reduce(
      (accumulator, currentValue) =>
        accumulator + currentValue.quantity * currentValue.rate,
      0
    );
  };
  onEdit(obj, e) {
    this.props.history.push({
      pathname: `/admin/projects/detail/workOrder/${ID}`,
      search: `?workOrder_id=${obj.workOrder_id}`,
    });
    // setOpenAdd(true);
  }
  handlePreview = (order) => {
    this.props.history.push({
      pathname: "/admin/estimates/preview",
      search: `?workOrder_id=${order.workOrder_id}`,
      state: {
        from: "list",
        isWorkOrder: true,
        projectId: ID,
      },
    });
  };
  duplicate = async (id) => {
    try {
      let response = await ApiService.postAPI(
        `contractor/workOrder/duplicate/${id}`
      );
      if (response.success) {
        this.Showtoast("success", response.message);
        let { workOrder_id } = response.data;
        setTimeout(() => {
          this.props.history.push({
            pathname: `/admin/projects/detail/workOrder/${ID}`,
            search: `?workOrder_id=${workOrder_id}`,
          });
        }, 1000);
      } else throw new Error(response.message);
    } catch (err) {
      this.Showtoast("danger", err.message);
    }
  };
  onLinkClick = (id) => {
    this.state.isWorkOrders
      ? CopyEstimateLink(id, "work_order")
      : CopyEstimateLink(id, "estimate");
    this.Showtoast("success", "Link Copied");
  };

  resendWorkOrder = async (request) => {
    let response = await ApiService.postAPI(
      `contractor/workOrder/resend/${request.workOrder_id}`
    );
    if (response.success) {
      this.Showtoast("success", response.message);
      // showSuccess(this, response.message);
      this.getWorkOrderList();
    } else {
      this.Showtoast("danger", response.message);
      // showError(this, response.message);
    }
  };

  Showtoast = (type, message) => {
    this.setState({
      toast: {
        toast: true,
        toastType: type,
        response: message,
      },
    });
    this.clearToast();
  };

  clearToast = () => {
    setTimeout(
      () =>
        this.setState({
          toast: {
            toast: false,
            toastType: "",
            response: "",
          },
        }),
      2000
    );
  };
  closeDeleteModal = (val) => {
    this.state.workOrderId &&
      this.setState({ deleteModal: false, workOrderId: null });
    this.getWorkOrderList();
    // val && this.state.workOrderId
  };
  getWorkOrderDetailPage = (id) => {
    this.props.history.push({
      pathname: `/admin/projects/detail/workOrder/${ID}`,
      search: `?workOrder_id=${id}`,
    });
  };
  render() {
    let {
      workOrderLists,
      isLoading,
      toast,
      page,
      openWorkOrder,
      orderId,
      timeLineOpen,
      deleteModal,
      workOrderId,
    } = this.state;

    return isLoading ? (
      <div className="spin-loading">
        <Spinner type="border" color="#077e8b" />
      </div>
    ) : (
      <div
        style={{ height: "100vh", padding: "20px", backgroundColor: "#fafafa" }}
      >
        {toast.toast && (
          <ToastList message={toast.response} type={toast.toastType} />
        )}
        <div className="align-items-center" style={{ paddingBottom: "20px" }}>
          <div xs="8" className="manage-con">
            <div>
              <Breadcrumb className="">
                <BreadcrumbItem
                  onClick={() => {
                    this.props.history.push({
                      pathname: `/admin/projects/detail/${ID}`,
                    });
                  }}
                  className="bread-cursor"
                >
                  {"Project"}
                </BreadcrumbItem>
                <BreadcrumbItem
                  style={{ color: "#72569c" }}
                  className="bread-cursor"
                >
                  {"Work Order"}
                </BreadcrumbItem>
              </Breadcrumb>
            </div>
          </div>
        </div>
        <CustomListingContainer>
          <ListingHeaderLayout
            label={"Work Order"}
            primary_buttonLabel={"Add"}
            isPrimary
            click={() => {
              this.props.history.push({
                pathname: `/admin/projects/detail/workOrder/${ID}`,
                state: "workOrdersList",
              });
            }}
          />
          <div>
            <Row>
              <div className="col new-table">
                <Card className="shadow card_with_paginator">
                  {workOrderLists && workOrderLists?.length !== 0 ? (
                    <Table
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead className="thead-light">
                        <tr>
                          <th style={{ paddingLeft: "25px" }} scope="col">
                            ID
                          </th>
                          <th scope="col">Pro</th>
                          <th scope="col">Subject</th>
                          <th scope="col">Sent On</th>
                          <th scope="col">Amount</th>
                          <th scope="col">Status</th>
                          <th style={{ width: "5%" }} scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {workOrderLists.map((order, i) => (
                          <tr
                            className="cursor-point"
                            onClick={() =>
                              this.getWorkOrderDetailPage(order.workOrder_id)
                            }
                            key={i}
                          >
                            <td
                              style={{ paddingLeft: "25px" }}
                              className="text-capitalize"
                            >
                              {order.workOrder_id}
                            </td>
                            <td className="text-capitalize">
                              {order.sub_contractor.name}
                            </td>
                            <td className="">{order.subject}</td>
                            <td className="text-capitalize">
                              {showUtcDate(order.createdAt)}
                            </td>
                            <td className="text-capitalize">
                              {"$" +
                                formatAmountWithCommas(
                                  order.itemsTotal.totalAmount.toFixed(2)
                                )}
                            </td>
                            <td
                              style={{
                                color: `${
                                  order.status === "completed" ||
                                  order.status === "accepted" ||
                                  order.status === "closed"
                                    ? "#07A84E"
                                    : order.status === "discontinued" ||
                                      order.status === "cancelled" ||
                                      order.status === "rejected"
                                    ? "#f75f18"
                                    : order.status === "due" ||
                                      order.status === "sent" ||
                                      order.status === "paid"
                                    ? "#0070FD"
                                    : "#FFCC3D"
                                }`,
                                fontWeight: "500",
                              }}
                              className="text-capitalize"
                            >
                              {order.status}
                            </td>
                            <td
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end",
                                gap: "20px",
                                paddingLeft: "20px",
                              }}
                            >
                              <div
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                              >
                                <ToolTip label="Timeline">
                                  <img
                                    onClick={() =>
                                      this.onTimeLineClick(order, "workOrder")
                                    }
                                    style={{ cursor: "pointer" }}
                                    src={projectTimeline}
                                    alt="timeline"
                                  ></img>
                                </ToolTip>
                              </div>
                              <div
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                                className="project_list_content"
                              >
                                <UncontrolledDropdown
                                  nav
                                  className="flexy-content position_u"
                                >
                                  <DropdownToggle
                                    className="pr-0 nav-notice"
                                    nav
                                  >
                                    <Media className="align-items-center">
                                      <img src={showMore} alt="show more" />
                                    </Media>
                                  </DropdownToggle>
                                  <DropdownMenu
                                    className="dropdown-menu-arrow min-width-drop"
                                    right
                                  >
                                    {order.status === "draft" && (
                                      <>
                                        <DropdownItem
                                          style={styleForDropDown}
                                          onClick={() => this.onEdit(order)}
                                        >
                                          <span>Edit</span>
                                        </DropdownItem>
                                        <DropdownItem
                                          style={styleForDropDown}
                                          onClick={() =>
                                            this.onLinkClick(order.workOrder_id)
                                          }
                                        >
                                          <span>Copy Link</span>
                                        </DropdownItem>
                                        <DropdownItem
                                          style={styleForDropDown}
                                          onClick={() =>
                                            this.handlePreview(order)
                                          }
                                        >
                                          <span>Preview</span>
                                        </DropdownItem>
                                        <DropdownItem
                                          style={styleForDropDown}
                                          onClick={() =>
                                            this.duplicate(order.workOrder_id)
                                          }
                                        >
                                          <span>Duplicate</span>
                                        </DropdownItem>
                                        <DropdownItem
                                          style={styleForDropDown}
                                          onClick={() => {
                                            this.setState({
                                              deleteModal: true,
                                              workOrderId: order.workOrder_id,
                                            });
                                          }}
                                        >
                                          <span>Delete</span>
                                        </DropdownItem>
                                      </>
                                    )}
                                    {order.status === "sent" && (
                                      <>
                                        <DropdownItem
                                          style={styleForDropDown}
                                          onClick={() => this.onEdit(order)}
                                        >
                                          <span>Edit</span>
                                        </DropdownItem>
                                        <DropdownItem
                                          style={styleForDropDown}
                                          onClick={() =>
                                            this.onLinkClick(order.workOrder_id)
                                          }
                                        >
                                          <span>Copy Link</span>
                                        </DropdownItem>
                                        <DropdownItem
                                          style={styleForDropDown}
                                          onClick={() =>
                                            this.resendWorkOrder(order)
                                          }
                                        >
                                          <span>Resend Email</span>
                                        </DropdownItem>
                                        <DropdownItem
                                          style={styleForDropDown}
                                          onClick={() =>
                                            this.handlePreview(order)
                                          }
                                        >
                                          <span>Preview</span>
                                        </DropdownItem>
                                        <DropdownItem
                                          style={styleForDropDown}
                                          onClick={() =>
                                            this.duplicate(order.workOrder_id)
                                          }
                                        >
                                          <span>Duplicate</span>
                                        </DropdownItem>
                                        <DropdownItem
                                          style={styleForDropDown}
                                          onClick={() =>
                                            downloadDocuments(
                                              order.workOrder_id,
                                              "workOrder"
                                            )
                                          }
                                        >
                                          <span>Download</span>
                                        </DropdownItem>
                                     
                                      </>
                                    )}
                                    {order.status === "paid" && (
                                      <>
                                        <DropdownItem
                                          style={styleForDropDown}
                                          onClick={() => this.onEdit(order)}
                                        >
                                          <span>Edit</span>
                                        </DropdownItem>
                                        <DropdownItem
                                          style={styleForDropDown}
                                          onClick={() =>
                                            this.onLinkClick(order.workOrder_id)
                                          }
                                        >
                                          <span>Copy Link</span>
                                        </DropdownItem>
                                        <DropdownItem
                                          style={styleForDropDown}
                                          onClick={() =>
                                            this.resendWorkOrder(order)
                                          }
                                        >
                                          <span>Resend Email</span>
                                        </DropdownItem>
                                        <DropdownItem
                                          style={styleForDropDown}
                                          onClick={() =>
                                            this.handlePreview(order)
                                          }
                                        >
                                          <span>Preview</span>
                                        </DropdownItem>
                                        <DropdownItem
                                          style={styleForDropDown}
                                          // onClick={() => {
                                          //   this.duplicate(
                                          //     order.estimate_id
                                          //   );
                                          // }}
                                          onClick={() =>
                                            this.duplicate(order.workOrder_id)
                                          }
                                        >
                                          <span>Duplicate</span>
                                        </DropdownItem>
                                        <DropdownItem
                                          style={styleForDropDown}
                                          onClick={() =>
                                            downloadDocuments(
                                              order.workOrder_id,
                                              "workOrder"
                                            )
                                          }
                                        >
                                          Download
                                        </DropdownItem>
                                      </>
                                    )}
                                    {order.status === "accepted" && (
                                      <>
                                        <DropdownItem
                                          style={styleForDropDown}
                                          onClick={() =>
                                            this.handlePreview(order)
                                          }
                                        >
                                          <span>Preview</span>
                                        </DropdownItem>
                                        <DropdownItem
                                          style={styleForDropDown}
                                          onClick={() =>
                                            this.duplicate(order.workOrder_id)
                                          }
                                        >
                                          <span>Duplicate</span>
                                        </DropdownItem>
                                        <DropdownItem
                                          style={styleForDropDown}
                                          onClick={() =>
                                            downloadDocuments(
                                              order.workOrder_id,
                                              "workOrder"
                                            )
                                          }
                                        >
                                          <span>Download</span>
                                        </DropdownItem>
                                        {(
                                          order.itemsTotal.totalAmount -
                                          order.totalSplitUp
                                        ).toFixed(2) > 0 && (
                                          <DropdownItem
                                            style={styleForDropDown}
                                            onClick={() =>
                                              this.addSplitUp(order)
                                            }
                                          >
                                            <span>Add Split Up</span>
                                          </DropdownItem>
                                        )}
                                      </>
                                    )}
                                    {order.status === "rejected" && (
                                      <>
                                        <DropdownItem
                                          style={styleForDropDown}
                                          onClick={() =>
                                            downloadDocuments(
                                              order.workOrder_id,
                                              "workOrder"
                                            )
                                          }
                                        >
                                          <span>Download</span>
                                        </DropdownItem>
                                      </>
                                    )}
                                    {order.status === "cancelled" && (
                                      <>
                                        {/* <DropdownItem>
                                              <span>Duplicate</span>
                                            </DropdownItem> */}
                                        <DropdownItem
                                          style={styleForDropDown}
                                          onClick={() =>
                                            downloadDocuments(
                                              order.workOrder_id,
                                              "workOrder"
                                            )
                                          }
                                        >
                                          <span>Download</span>
                                        </DropdownItem>
                                      </>
                                    )}
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    <>
                      <Table className="align-items-center table-flush">
                        <thead className="thead-light">
                          <tr>
                            <th style={{ paddingLeft: "25px" }} scope="col">
                              ID
                            </th>
                            <th scope="col">Pro</th>
                            <th scope="col">Subject</th>
                            <th scope="col">Sent on</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Status</th>
                            <th style={{ width: "5%" }} scope="col"></th>
                          </tr>
                        </thead>
                      </Table>
                      <Card className="card_with_paginator no_data_card">
                        {" "}
                        <div className="no-data">No Work Orders Available</div>
                      </Card>
                    </>
                  )}
                </Card>
              </div>
            </Row>
            <Row className="align-items-center header-div jus-space new-margin-page paginator_box">
              <div className="paginate-flex-box ">
                <div
                  className="paginate-box"
                  onClick={() => this.handleDecrementPage()}
                >
                  <p
                    className={
                      page !== 1 ? "page_available" : "page_unavailable"
                    }
                  >
                    Previous
                  </p>
                </div>
                <div
                  className="paginate-box"
                  onClick={() => this.handleIncrementPage()}
                >
                  <p
                    className={
                      workOrderLists?.length < 10
                        ? "page_unavailable"
                        : "page_available"
                    }
                  >
                    Next
                  </p>
                </div>
              </div>
            </Row>
          </div>
        </CustomListingContainer>
        <Card className={openWorkOrder ? "exportSideBar" : "toggle-sidebar"}>
          {openWorkOrder && (
            <AddChangeOrder
              props={this.props}
              projectId={ID}
              orderId={orderId ?? ""}
              close={this.closeChangeOrder}
            ></AddChangeOrder>
          )}
        </Card>
        <Card className={timeLineOpen ? "exportSideBar" : "toggle-sidebar"}>
          {timeLineOpen && (
            <TimeLine
              id={this.state.timeLine.id}
              closeTimelineModal={this.closeTimeline}
              from={this.state.timeLine.from}
            ></TimeLine>
          )}
        </Card>
        {deleteModal && (
          <DeleteModal
            modalState={deleteModal}
            name={workOrderId}
            type="WorkOrder"
            props={this}
            toast={this.Showtoast}
            closeModal={this.closeDeleteModal}
            id={workOrderId}
            width={200}
          />
        )}
      </div>
    );
  }
}

export default WorkOrderList;
