import React, { Component } from "react";
import {
  Button,
  Card,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Media,
  UncontrolledDropdown,
} from "reactstrap";
import moment from "moment";
import previous from "../../assets/img/icons/common/previous.svg";
import next from "../../assets/img/icons/common/next.svg";
import marker from "../../assets/img/icons/common/Ellipse.svg";
import ApiService from "constants/ApiService";
import EventSideDragger from "./eventSideDragger";
import { Spinner } from "reactstrap";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import axios from "axios";
import GoogleEventButton from "./GoogleEventButton";
import { showError } from "constants/utils";
import ToastList from "components/Toast/ToastList";
import { showSuccess } from "constants/utils";
import EventListing from "./eventListingDragger";
import maintenanceInactiveIcon from "../../assets/img/brand/invoiceTimeline.svg";
import requestInactiveIcon from "../../assets/img/brand/Request.svg";
import googleInactiveIcon from "../../assets/img/brand/datePicker.svg";
import googleActiveIcon from "../../assets/img/brand/datePickerActive.svg";
import maintenanceActiveIcon from "../../assets/img/brand/invoiceTimelineActive.svg";
import requestActiveIcon from "../../assets/img/brand/rqCalenderActive.svg";
import ReactTooltip from "react-tooltip";
import { CustomListingContainer } from "components/Container/CustomListingContainer";
import { ListingHeaderLayout } from "components/Container/CustomListingContainer";
import { CustomDropDown } from "components/FilterFields/Filterfields";

class Calender extends Component {
  constructor(props) {
    super(props);
    this.state = {
      myAppointments: "",
      services: [],
      maintainenceList: [],
      requestList: [],
      googEventsList: [],
      weekDays: moment.weekdaysShort(),
      totalDays: [],
      currentMonth: moment().format("MMMM YYYY"),
      currentWeek: moment().week(),
      weekHead: "",
      currentDay: moment(),
      dayHead: "",
      from: moment().startOf("month").format("YYYY-MM-DD"),
      to: moment().endOf("month").format("YYYY-MM-DD"),
      yearArray: [],
      isLoading: false,
      daysDisplay: true,
      monthDisplay: false,
      yearDisplay: false,
      googleCode: "",
      allmonths: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      eventModal: false,
      googleAppointments: "",
      toast: "",
      response: "",
      toastType: "",
      eventDetails: "",
      eventListModal: false,
      status: "all",
      currentAppointments: "",
      overAllAppointments: {},
      currentView: "monthly",
      weekDisplay: [],
      weekDaysDisplay: [],
      dayArray: [],
      dailyEvents: [],
      dayHead: "",
      hidePrevious: false,
      hideNext: false,
      pageLoad: false,
    };
  }
  componentDidMount() {
    this.gethostDetails();
    this.yearsDisplay();
  }

  gethostDetails = async () => {
    this.setState({ pageLoad: true });

    let response = await ApiService.getAPI(`contractor`);

    if (response.success) {
      this.setState({ googleCode: response.data.contractor.token_id }, () => {
        Promise.all([this.getGoogleEvents(), this.getMyEvents()]).then(() => {
          this.setState(
            { currentAppointments: this.state.overAllAppointments },
            () => {
              this.daysInMonth();
              this.displayWeekDays();
            }
          );
        });
      });
    }
  };

  getGoogleEvents = () => {
    return new Promise(async (resolve, reject) => {
      let { googleCode, currentMonth, overAllAppointments, from, to } =
        this.state;
      if (!googleCode) return resolve(false);

      let response = await ApiService.getAPI(
        `contractor/google?from=${from}&to=${to}`
      );
      if (!response.success) resolve(false);
      const appointments = {};
      response?.data?.appointments?.map((appointment) => {
        const date = appointment?.createdAt;
        if (appointments[date]) {
          appointments[date].push(appointment);
          overAllAppointments[date]["google"].push(appointment);
        } else {
          appointments[date] = [appointment];
          if (!overAllAppointments[date]) overAllAppointments[date] = {};
          overAllAppointments[date]["google"] = [appointment];
        }
      });
      this.setState(
        {
          googleAppointments: appointments,
          overAllAppointments,
        },
        () => {
          resolve(appointments);
        }
      );
    });
  };

  getMyEvents = () => {
    return new Promise(async (resolve, reject) => {
      let { currentMonth, myAppointments, overAllAppointments, from, to } =
        this.state;
      let month = moment(currentMonth).format("YYYY-MM-DD");

      let response = await ApiService.getAPI(
        `contractor/web/find_appointment?from=${from}&to=${to}`
      );

      if (!response.success) resolve(false);
      if (Object.keys(response.data.appointments).length === 0) resolve(false);
      const appointments = {};
      response.data.appointments.map((appointment) => {
        const date = moment(appointment.date).format("YYYY-MM-DD");
        if (appointments[date]) {
          appointments[date].push(appointment);
          overAllAppointments[date]["my"].push(appointment);
        } else {
          appointments[date] = [appointment];
          if (!overAllAppointments[date]) overAllAppointments[date] = {};
          overAllAppointments[date]["my"] = [appointment];
        }
      });
      this.setState(
        {
          myAppointments: appointments,
          overAllAppointments,
        },
        () => {
          resolve(appointments);
        }
      );
    });
  };

  getAppointmentsOnDate = async (date) => {
    this.setState({ eventModal: false });

    let { currentMonth } = this.state;
    let presentDay = moment();

    if (moment(currentMonth).set("date", date) < presentDay) {
      this.setState({ isLoading: true, eventListModal: true });
      let fromDate = moment(this.state.currentMonth)
        .set("date", date)
        .format("YYYY-MM-DD");

      let response = await ApiService.getAPI(
        `contractor/appointements?from=${fromDate}`
      );
      let googResponse = await ApiService.getAPI(
        `contractor/google/detail?from=${fromDate}`
      );
      if (response.success || googResponse.success) {
        this.setState(
          {
            services: response.data?.services,
            googEventsList: googResponse.data?.services,
          },
          () => {
            this.displayEvents();
          }
        );
      } else {
        this.setState({ isLoading: false });
      }
    } else if (moment(currentMonth).set("date", date) > presentDay) {
      this.setState({ isLoading: true, eventListModal: true });
      let fromDate = moment(this.state.currentMonth)
        .set("date", date)
        .format("YYYY-MM-DD");

      let response = await ApiService.getAPI(
        `contractor/appointements?from=${fromDate}`
      );
      let googResponse = await ApiService.getAPI(
        `contractor/google/detail?from=${fromDate}`
      );
      if (response.success || googResponse.success) {
        this.setState(
          {
            services: response.data?.services,
            googEventsList: googResponse.data?.services,
          },
          () => {
            this.displayEvents();
          }
        );
      } else {
        this.setState({ isLoading: false });
      }
    }
  };

  displayEvents = () => {
    let { services } = this.state;
    let Maintainence = [];
    let Request = [];
    let events = [];
    services.map((service) =>
      service.history_id ? Maintainence.push(service) : Request.push(service)
    );

    this.setState(
      {
        maintainenceList: Maintainence,
        requestList: Request,
      },
      () => {
        this.setState({ isLoading: false });
      }
    );
  };

  firstDayOfMonth = () => {
    let { currentMonth } = this.state;
    let firstDay = moment(currentMonth).startOf("month").format("d");
    return firstDay;
  };

  yearsDisplay = () => {
    let currentYear = moment(this.state.currentMonth).format("Y");
    let previousYears = moment(currentYear).add(-5, "year").format("Y");
    let nextYears = moment(currentYear).add(5, "year").format("Y");
    let yearArr = [];

    for (previousYears; previousYears < currentYear; previousYears++) {
      yearArr.push(previousYears);
    }
    for (currentYear; currentYear < nextYears; currentYear++) {
      yearArr.push(currentYear);
    }
    this.setState({ yearArray: yearArr });
  };
  previousYear = () => {
    let { yearArray, currentMonth } = this.state;
    let currentYear = yearArray[0];
    let previousYears = moment(currentMonth)
      .set("year", currentYear)
      .add(-10, "year")
      .format("Y");
    let yearArr = [];
    for (previousYears; previousYears < currentYear; previousYears++) {
      yearArr.push(previousYears);
    }
    this.setState({ yearArray: yearArr });
  };

  nextYear = () => {
    let { yearArray, currentMonth } = this.state;
    let currentYear = yearArray[yearArray.length - 1];
    let nextYears = moment(currentMonth)
      .set("year", currentYear)
      .add(10, "year")
      .format("Y");
    let yearArr = [];
    for (currentYear; currentYear < nextYears; currentYear++) {
      yearArr.push(currentYear);
    }
    this.setState({ yearArray: yearArr });
  };

  convertTime = (data) => {
    return moment(data).format("h:mm A");
  };

  handleListLength = (data, status) => {
    let myLength = 0;
    let googleLength = 0;
    let total = 0;
    let requestArr = [];
    let mainArr = [];
    if (data.my) {
      myLength = data.my.length;
      requestArr = data.my.filter((d) => d.mode === "request");
      mainArr = data.my.filter((d) => d.mode === "maintenance");
    }
    if (data.google) {
      googleLength = data.google.length;
    }
    if (status === "all") {
      total = myLength + googleLength - 3;
    } else if (status === "google") {
      total = googleLength - 3;
    } else if (status === "request") {
      total = requestArr.length - 3;
    } else if (status === "maintenance") {
      total = mainArr.length - 3;
    }
    return total;
  };

  getGoogLength = (data) => {
    let { status } = this.state;
    let length = 0;
    if (data?.my?.length > 0 && status === "all") {
      if (data.my.length === 1) length = 1;
      else if (data.my.length === 2) length = 0;
      else if (data.my.length >= 3) length = -1;
    } else {
      length = 2;
    }
    return length;
  };

  openEventDetail = (e, data) => {
    e.stopPropagation();
    this.setState({ eventModal: true, eventDetails: data });
  };

  getAppointmentOnStatus = () => {
    let { currentAppointments, status } = this.state;
    let currentAppointmentsArray = {};
    Object.keys(currentAppointments).map((date) =>
      status === "all"
        ? (currentAppointmentsArray[date] = currentAppointments[date])
        : status === "request"
          ? currentAppointments[date]?.my?.map((data) => {
            if (data.mode === "request") {
              if (currentAppointmentsArray[date]) {
                currentAppointmentsArray[date]["my"].push(data);
              } else {
                if (!currentAppointmentsArray[date])
                  currentAppointmentsArray[date] = {};
                currentAppointmentsArray[date]["my"] = [data];
              }
            }
          })
          : status === "maintenance"
            ? currentAppointments[date]?.my?.map((data) => {
              if (data.mode === "maintenance") {
                if (currentAppointmentsArray[date]) {
                  currentAppointmentsArray[date]["my"].push(data);
                } else {
                  if (!currentAppointmentsArray[date])
                    currentAppointmentsArray[date] = {};
                  currentAppointmentsArray[date]["my"] = [data];
                }
              }
            })
            : status === "google"
              ? currentAppointments[date]?.google?.map((data) => {
                if (currentAppointmentsArray[date]) {
                  currentAppointmentsArray[date]["google"].push(data);
                } else {
                  if (!currentAppointmentsArray[date])
                    currentAppointmentsArray[date] = {};
                  currentAppointmentsArray[date]["google"] = [data];
                }
              })
              : {}
    );
    return currentAppointmentsArray;
  };

  daysInMonth = () => {
    let { myAppointments, googleAppointments, currentAppointments, status } =
      this.state;
    let blank = [];
    let days = [];
    let daysInMonth = moment(this.state.currentMonth).daysInMonth();
    const appointmentDates = {};

    Object.keys(this.getAppointmentOnStatus()).map(
      (date) =>
      (appointmentDates[parseInt(moment(date).format("D"))] =
        this.getAppointmentOnStatus()[date])
    );

    for (let i = 0; i < this.firstDayOfMonth(); i++) {
      blank.push(<div>{""}</div>);
    }

    for (let d = 1; d <= daysInMonth; d++) {
      days.push(
        <div
          className="marker"
          onClick={() => appointmentDates[d] && this.getAppointmentsOnDate(d)}
        >
          <div className="date">{d}</div>
          <div className="eventList">
            {status !== "google" &&
              appointmentDates[d]?.my?.map(
                (data, i) =>
                  i <= 2 && (
                    <div className=" eventDetail">
                      <img src={marker} alt="mark" width="8px" height="8px" />
                      <div className="eventname">
                        {this.convertTime(data?.date) + " " + data.type}
                      </div>
                    </div>
                  )
              )}
            {(appointmentDates[d]?.my?.length <= 3 ||
              appointmentDates[d]?.my?.length === undefined) &&
              appointmentDates[d]?.google?.map(
                (data, i) =>
                  i <= this.getGoogLength(appointmentDates[d]) && (
                    <div
                      className=" eventDetail googleEventList"
                      onClick={(e) => this.openEventDetail(e, data)}
                    >
                      <img src={marker} alt="mark" width="8px" height="8px" />
                      <div className="eventname">
                        {this.convertTime(data?.time?.from) + " " + data.name}
                      </div>
                    </div>
                  )
              )}
            {appointmentDates[d] &&
              this.handleListLength(appointmentDates[d], status) >= 1 && (
                <div className="f12 fw-600 cl333333 more">
                  {this.handleListLength(appointmentDates[d], status)} more
                </div>
              )}
          </div>
        </div>
      );
    }

    let total = [...blank, ...days];
    this.setState({ totalDays: total }, () => {
      this.setState({ pageLoad: false });
    });
  };

  displayMonth = () => {
    if (this.state.monthDisplay) {
      this.setState({ yearDisplay: true, monthDisplay: false });
    } else {
      this.setState({
        monthDisplay: true,
        daysDisplay: false,
        yearDisplay: false,
      });
    }
  };

  selectedMonth = (month) => {
    let index = this.state.allmonths.indexOf(month);
    let selectedMonth = moment(this.state.currentMonth).set("month", index);

    this.setState(
      {
        currentMonth: selectedMonth.format("MMMM YYYY"),
        daysDisplay: true,
        monthDisplay: false,
        yearDisplay: false,
      },
      () => {
        this.getMyEvents();
        this.getGoogleEvents();
      }
    );
  };

  displayDates = (year) => {
    let { currentMonth } = this.state;
    let selectedYear = moment(currentMonth).set("year", year);
    this.setState(
      {
        currentMonth: selectedYear.format("MMMM YYYY"),
        yearDisplay: false,
        daysDisplay: true,
        monthsDisplay: false,
      },
      () => {
        this.getMyEvents();
        this.getGoogleEvents();
      }
    );
  };

  previousMonth = () => {
    let { currentMonth, currentView, currentWeek } = this.state;

    if (currentView === "monthly") {
      let presentDay = moment();
      this.setState({ hideNext: false, hidePrevious: false });

      if (
        moment(currentMonth).month() === presentDay.month() ||
        moment(currentMonth) > presentDay
      ) {
        this.setState({ pageLoad: true });

        let previous = moment(this.state.currentMonth)
          .add(-1, "month")
          .format("MMMM YYYY");
        this.setState(
          {
            currentMonth: previous,
            overAllAppointments: {},
            currentAppointments: "",
            from: moment(this.state.currentMonth)
              .add(-1, "month")
              .startOf("month")
              .format("YYYY-MM-DD"),
            to: moment(this.state.currentMonth)
              .add(-1, "month")
              .endOf("month")
              .format("YYYY-MM-DD"),
          },
          () => {
            if (
              moment(this.state.currentMonth).month() ===
              presentDay.month() - 1
            ) {
              this.setState({ hidePrevious: true });
            }
            this.daysInMonth();
            this.gethostDetails();
          }
        );
      }
    } else if (currentView === "weekly") {
      this.setState({ pageLoad: true });

      this.setState(
        {
          currentWeek: this.state.currentWeek - 1,
          weekHead: this.weekHeadDisplay(this.state.currentWeek - 1),
          weekDisplay: this.weekArrayDisplay(this.state.currentWeek - 1),
          from: moment()
            .week(currentWeek - 1)
            .startOf("week")
            .format("YYYY-MM-DD"),
          to: moment()
            .week(currentWeek - 1)
            .endOf("week")
            .format("YYYY-MM-DD"),
          overAllAppointments: {},
          currentAppointments: "",
        },
        () => {
          this.gethostDetails();
        }
      );
    } else if (currentView === "daily") {
      this.setState({ pageLoad: true });

      this.setState(
        {
          currentDay: this.state.currentDay - 1,
          dayHead: this.dayHeadDisplay(this.state.currentDay - 1),
          from: moment()
            .date(this.state.currentDay - 1)
            .format("YYYY-MM-DD"),
          to: moment()
            .date(this.state.currentDay - 1)
            .format("YYYY-MM-DD"),
          overAllAppointments: {},
          currentAppointments: "",
        },
        () => {
          this.dayListDisplay();
        }
      );
    }
  };

  nextMonth = () => {
    let { currentMonth, currentView, currentWeek } = this.state;

    if (currentView === "monthly") {
      let nextMonth = moment().add(1, "month");
      if (moment(currentMonth) < nextMonth) {
        this.setState({ hideNext: false, hidePrevious: false });
        this.setState({ pageLoad: true });

        let next = moment(this.state.currentMonth)
          .add(1, "month")
          .format("MMMM YYYY");
        this.setState(
          {
            currentMonth: next,
            overAllAppointments: {},
            currentAppointments: "",
            from: moment(this.state.currentMonth)
              .add(1, "month")
              .startOf("month")
              .format("YYYY-MM-DD"),
            to: moment(this.state.currentMonth)
              .add(1, "month")
              .endOf("month")
              .format("YYYY-MM-DD"),
          },
          () => {
            if (
              moment(this.state.currentMonth).month() ===
              nextMonth.month() + 1
            ) {
              this.setState({ hideNext: true });
            }
            this.daysInMonth();
            this.gethostDetails();
          }
        );
      }
    } else if (currentView === "weekly") {
      this.setState({ pageLoad: true });

      let next = moment().week(currentWeek).add(1, "week");
      this.setState(
        {
          currentWeek: this.state.currentWeek + 1,
          weekHead: this.weekHeadDisplay(this.state.currentWeek + 1),
          weekDisplay: this.weekArrayDisplay(this.state.currentWeek + 1),
          from: moment()
            .week(currentWeek + 1)
            .startOf("week")
            .format("YYYY-MM-DD"),
          to: moment()
            .week(currentWeek + 1)
            .endOf("week")
            .format("YYYY-MM-DD"),
          currentAppointments: "",
          overAllAppointments: {},
        },
        () => {
          this.gethostDetails();
        }
      );
    } else if (currentView === "daily") {
      this.setState({ pageLoad: true });

      this.setState(
        {
          currentDay: this.state.currentDay + 1,
          dayHead: this.dayHeadDisplay(this.state.currentDay + 1),
          from: moment()
            .date(this.state.currentDay + 1)
            .format("YYYY-MM-DD"),
          to: moment()
            .date(this.state.currentDay + 1)
            .format("YYYY-MM-DD"),
          overAllAppointments: {},
          currentAppointments: "",
        },
        () => {
          this.dayListDisplay();
        }
      );
    }
  };

  openEventSideDragger = (status, msg) => {
    this.setState({
      eventModal: status,
      eventDetails: "",
    });
    if (msg) {
      showSuccess(this, msg);
      this.setState({ eventListModal: false, overAllAppointments: {} }, () => {
        this.gethostDetails();
      });
    }
  };

  openEventListModal = (status) => {
    this.setState({
      eventListModal: status,
    });
  };

  successResponse = async (response) => {
    const { code } = response;
    axios
      .post(`http://localhost:4000/api/create-tokens`, { code })
      .then((response) => { })
      .catch((error) => { });
  };

  failureResponse = (response) => { };

  displayToast = (msg) => {
    showError(this, msg);
  };

  showSuccess = (msg) => {
    showSuccess(this, msg);
    this.gethostDetails();
  };

  editGoogleEvent = (eventData) => {
    this.setState({ eventDetails: eventData, eventModal: true });
  };

  deleteEvent = async (event) => {
    if (event.id) {
      let response = await ApiService.deleteAPI(
        `contractor/google/${event.id}`
      );
      if (response.success) {
        this.setState(
          {
            eventListModal: false,
            overAllAppointments: {},
          },
          () => {
            this.gethostDetails();
          }
        );
        showSuccess(this, response.message);
      } else {
        showError(this, response.message);
      }
    }
  };

  handleStatusChange = (status) => {
    let { currentView } = this.state;
    this.setState({ status: status, pageLoad: true }, () => {
      currentView === "monthly"
        ? this.daysInMonth()
        : currentView === "weekly"
          ? this.displayWeekDays()
          : this.setDailyView();
    });
  };

  weekHeadDisplay = (weekNum) => {
    let startDate = moment().week(weekNum).startOf("week");
    let endDate = moment().week(weekNum).endOf("week");
    return `${startDate.format("MMMM D")} - ${endDate.format(
      `${startDate.format("MMMM") === endDate.format("MMMM") ? "" : "MMMM"
      } D, YYYY`
    )}`;
  };

  weekArrayDisplay = (weekNum) => {
    let arr = [];
    for (let i = 0; i < 7; i++) {
      arr.push({
        day: moment()
          .week(weekNum)
          .startOf("week")
          .add(i, "days")
          .format("dddd"),
        date: parseInt(
          moment().week(weekNum).startOf("week").add(i, "days").format("D")
        ),
      });
    }

    return arr;
  };

  dayHeadDisplay = (date) => {
    let num = moment().date(date).format("D MMMM , YYYY");
    return num;
  };

  dayListDisplay = async () => {
    let { from } = this.state;
    let response = await ApiService.getAPI(
      `contractor/appointements?from=${from}`
    );
    let googResponse = await ApiService.getAPI(
      `contractor/google/detail?from=${from}`
    );

    const hours = [];
    for (let i = 0; i < 24; i++) {
      hours.push({
        text: moment({ hour: i }).format("h A"),
        start: moment({ hour: i }).format("Hmm"),
        end: moment({ hour: i + 1 }).format("Hmm"),
        events: [],
      });
    }
    let overallArray = [];
    if (response.success) {
      overallArray = [...response.data.services];
    }
    if (googResponse.success) {
      overallArray = [...overallArray, ...googResponse.data.services];
    }
    overallArray.map((service, i) => {
      let time = service?.time?.from
        ? moment(service.time.from).format("Hmm")
        : moment(service.date).format("Hmm");

      hours.map((hour) => {
        if (
          parseInt(time) >= parseInt(hour.start) &&
          parseInt(time) <= parseInt(hour.end) - 1
        ) {
          hour.events.push(service);
        }
      });
    });

    hours.map((hour, index, arr) => {
      hour.events.length > 0 &&
        hour.events.map((event, i) => {
          event.top = this.returnTopValue(event);
          event.left = this.returnLeftValue(event, arr, index);
          event.height = this.returnHeightValue(event);
          event.position = this.returnPosition(event, arr, index);
          event.type = event?.request_id
            ? "request"
            : event?.history_id
              ? "maintenance"
              : "google";
        });
    });

    this.setState({ dailyEvents: hours }, () => {
      this.setDailyView();
    });
  };

  setDailyView = () => {
    let { dailyEvents, status } = this.state;
    let days = [];
    dailyEvents.map((hour, index, arr) => {
      days.push(
        <div className="flexStart h120">
          <div className="mr-20 f12 fw-400 cl000000">{hour.text}</div>
          <div className="dayLine ">
            {hour.events.length > 0 &&
              hour.events.map(
                (event, i) =>
                  (status === "all" || status === event.type) && (
                    <div
                      style={{
                        position: "absolute",
                        top: this.returnTopValue(event) + "px",
                        left:
                          this.returnLeftValue(event, arr, index) +
                          i * 210 +
                          "px",
                        height: this.returnHeightValue(event) + "px",
                      }}
                      className="dayEvent"
                    >
                      <div>{event.name}</div>
                      <div className="flexStart">
                        <div>
                          {this.convertTime(
                            event?.time?.from ? event.time?.from : event?.date
                          )}
                        </div>
                        {event?.time?.to && (
                          <div>
                            {"-" +
                              this.convertTime(
                                event?.time?.to ? event.time.to : ""
                              )}
                          </div>
                        )}
                      </div>
                    </div>
                  )
              )}
          </div>
        </div>
      );
    });
    this.setState({ dayArray: days }, () => {
      this.setState({ pageLoad: false });
    });
  };

  returnTopValue = (event) => {
    let time = moment(
      event?.time?.from ? event.time?.from : event?.date
    ).format("mm");
    return parseInt(time) * 2;
  };

  returnLeftValue = (event, arr, index) => {
    let data = this.checkPreviousArray(event, arr, index);
    if (data > 0) {
      return 210 * data;
    } else {
      return 0;
    }
  };

  returnPosition = (event, arr, index) => {
    let flag = 0;
    for (let i = 0; i < index; i++) {
      arr[i].events.map((ar) => {
        if (ar?.time?.from) {
          if (
            moment(event?.time?.from ? event.time.from : event.date).isBetween(
              ar?.time.from,
              ar?.time.to
            )
          ) {
            flag = flag + 1;
          }
        } else if (ar.date) {
          if (
            moment(event?.time?.from ? event.time.from : event.date).isBetween(
              ar?.date,
              moment(ar?.date).add(1, "hour")
            )
          ) {
            flag = flag + 1;
          }
        }
      });
    }
    return flag;
  };

  checkPreviousArray = (event, arr, index) => {
    let flag = 0;
    for (let i = 0; i < index; i++) {
      arr[i].events.map((ar) => {
        if (ar?.time?.from) {
          if (
            moment(event?.time?.from ? event.time.from : event.date).isBetween(
              ar?.time.from,
              ar?.time.to
            )
          ) {
            flag = flag + 1 + (ar.position > 0 && ar.position - 1);
          }
        } else if (ar.date) {
          if (
            moment(event?.time?.from ? event.time.from : event.date).isBetween(
              ar?.date,
              moment(ar?.date).add(1, "hour")
            )
          ) {
            flag = flag + 1 + (ar.position > 0 && ar.position - 1);
          }
        }
      });
    }
    return flag;
  };

  returnBottomValue = (event) => {
    let fromTime = moment(event.time.from).format("Hmm");
    let toTime = moment(event.time.to).format("Hmm");
    let time = parseInt(toTime) - parseInt(fromTime);

    return this.returnTopValue(event) + time;
  };

  returnHeightValue = (event) => {
    let givenFrom = event?.time?.from ? event.time.from : event?.date;
    let givenTo = event?.time?.to
      ? event.time.to
      : moment(event?.date).add(1, "hour");
    let fromTime = moment(givenFrom);
    let toTime = moment(givenTo);
    let time = toTime.diff(fromTime, "minutes");
    return time * 2;
  };

  setCurrentView = (view) => {
    let { currentMonth, weekDays, currentWeek } = this.state;
    this.setState({ currentView: view, pageLoad: true });
    let from, to;
    if (view === "daily") {
      this.setState({ hidePrevious: false, hideNext: false });

      let day = moment().date();
      from = moment().date(day).format("YYYY-MM-DD");
      to = moment().date(day).format("YYYY-MM-DD");
      this.setState(
        {
          dayHead: this.dayHeadDisplay(day),
          currentDay: day,
        },
        () => {
          this.dayListDisplay(from);
        }
      );
    } else if (view === "weekly") {
      this.setState({ hidePrevious: false, hideNext: false });

      let weekNum = moment().week();
      from = moment().week(weekNum).startOf("week").format("YYYY-MM-DD");
      to = moment().week(weekNum).endOf("week").format("YYYY-MM-DD");

      this.setState(
        {
          weekDisplay: this.weekArrayDisplay(weekNum),
          weekHead: this.weekHeadDisplay(weekNum),
          currentWeek: weekNum,
        },
        () => {
          this.displayWeekDays();
        }
      );
    } else {
      this.setState({ currentMonth: moment().format("MMMM YYYY") });
      from = moment().startOf("month").format("YYYY-MM-DD");
      to = moment().endOf("month").format("YYYY-MM-DD");
    }
    this.setState({ from: from, to: to }, () => {
      this.gethostDetails();
    });
  };

  displayWeekDays = () => {
    let { currentAppointments, weekDisplay, status, currentWeek } = this.state;
    let days = [];
    let daysInMonth = moment(this.state.currentMonth).daysInMonth();
    const appointmentDates = {};
    Object.keys(currentAppointments).map(
      (date) =>
        (appointmentDates[parseInt(date.slice(-2))] = currentAppointments[date])
    );
    if (weekDisplay.length > 0) {
      weekDisplay.map((day) => {
        days.push(
          <div
            className="marker"
            onClick={() =>
              appointmentDates[day.date] && this.getAppointmentsOnDate(day.date)
            }
          >
            <div className="eventList">
              {status !== "google" &&
                appointmentDates[day.date]?.my?.map(
                  (data, i) =>
                    (status === "all" || status === data.mode) &&
                    i <= 2 && (
                      <div className=" eventDetail">
                        <img src={marker} alt="mark" width="8px" height="8px" />
                        <div className="eventname">
                          {this.convertTime(data?.date) + " " + data.type}
                        </div>
                      </div>
                    )
                )}
              {(status === "google" ||
                (status === "all" &&
                  (appointmentDates[day.date]?.my?.length < 3 ||
                    appointmentDates[day.date]?.my?.length === undefined))) &&
                appointmentDates[day.date]?.google?.map(
                  (data, i) =>
                    i <= this.getGoogLength(appointmentDates[day.date]) && (
                      <div
                        className="eventDetail googleEventList"
                        onClick={(e) => this.openEventDetail(e, data)}
                      >
                        <img src={marker} alt="mark" width="8px" height="8px" />
                        <div className="eventname ">
                          {this.convertTime(data?.time?.from) + " " + data.name}
                        </div>
                      </div>
                    )
                )}
              {appointmentDates[day.date] &&
                this.handleListLength(appointmentDates[day.date], status) >=
                1 && (
                  <div className="f12 fw-600 cl333333 more">
                    {this.handleListLength(appointmentDates[day.date], status)}{" "}
                    more
                  </div>
                )}
            </div>
          </div>
        );
      });
      this.setState({ weekDaysDisplay: days }, () => {
        this.setState({ pageLoad: false });
      });
    }
  };

  render() {
    let {
      weekDays,
      totalDays,
      daysDisplay,
      isLoading,
      maintainenceList,
      requestList,
      googEventsList,
      eventModal,
      googleCode,
      toast,
      response,
      toastType,
      eventDetails,
      eventListModal,
      status,
      currentView,
      hidePrevious,
      hideNext,
      pageLoad,
      weekDisplay,
      weekDaysDisplay,
      dayHead,
      dayArray,
      dailyEvents,
    } = this.state;

    return (
      <div>
        {toast && <ToastList message={response} type={toastType} />}
        <div style={{ padding: "20px", backgroundColor: '#fafafa' }}>
          <CustomListingContainer   >
            <ListingHeaderLayout label={"Calender"} />
            <div style={{ margin: "24px" }}>
              <div
                className="d_flex_sb"
                style={{
                  marginBottom: "1.8rem",
                }}
              >
                <div style={{ display: "flex", width: "60%" }}>
                  <CustomDropDown
                    width={"126px"}
                    status={currentView}
                    setStatus={this.setCurrentView}
                    statusList={["monthly", "weekly", "daily"]}
                  ></CustomDropDown>
                </div>
                <div className="flexStart">
                  {(googleCode === "" ||
                    googleCode === undefined ||
                    googleCode === null) && (
                      <GoogleOAuthProvider clientId="798935119937-363nsjoujsjlcsdm7o38h23mdnlh3ob4.apps.googleusercontent.com">
                        <GoogleEventButton
                          success={this.showSuccess}
                          error={this.displayToast}
                        />
                      </GoogleOAuthProvider>
                    )}

                  {googleCode && (
                    <Button
                      className={googleCode ? "btn-info" : "btn-info-inactive"}
                      onClick={() =>
                        googleCode && this.openEventSideDragger(true)
                      }
                    >
                      Create
                    </Button>
                  )}
                </div>
              </div>
            </div>
            <div className="flex mb-30">
              <div></div>
              <div className="calender_selectedmonth">
                <div
                  className={
                    hidePrevious
                      ? "op5 cursor-disable calenderFilter "
                      : "calenderFilter point "
                  }
                  onClick={() => this.previousMonth()}
                >
                  <img src={previous} alt="previous" />
                </div>
                <div className="current_month_display">
                  {currentView === "monthly"
                    ? this.state.currentMonth
                    : currentView === "weekly"
                      ? this.state.weekHead
                      : this.state.dayHead}
                </div>

                <div
                  className={
                    hideNext
                      ? "calenderFilter op5 cursor-disable"
                      : "calenderFilter point "
                  }
                  onClick={() => this.nextMonth()}
                >
                  <img src={next} alt="next" />
                </div>
              </div>
              <div className="flexStart">
                <div
                  className={
                    status === "all"
                      ? "calenderFilter point mr-15 col_primary_2 f12"
                      : "calenderFilter point mr-15 f12"
                  }
                  onClick={() => this.handleStatusChange("all")}
                >
                  All
                </div>
                <div
                  className="calenderFilter point mr-15"
                  onClick={() => this.handleStatusChange("maintenance")}
                >
                  <img
                    src={
                      status === "maintenance"
                        ? maintenanceActiveIcon
                        : maintenanceInactiveIcon
                      // .tagInputDiv
                    }
                    data-tip="Maintenance"
                    data-iscapture="true"
                    data-for="mainIconInfo"
                    data-place="top"
                  />
                  <ReactTooltip
                    place="top"
                    type="info"
                    effect="solid"
                    id="mainIconInfo"
                    html={true}
                  />
                </div>
                <div
                  className="calenderFilter point mr-15"
                  onClick={() => this.handleStatusChange("request")}
                >
                  <img
                    src={
                      status === "request"
                        ? requestActiveIcon
                        : requestInactiveIcon
                    }
                    data-tip="Request"
                    data-iscapture="true"
                    data-for="reqIconInfo"
                    data-place="top"
                  />
                  <ReactTooltip
                    place="top"
                    type="info"
                    effect="solid"
                    id="reqIconInfo"
                    html={true}
                  />
                </div>
                <div
                  className="calenderFilter point "
                  onClick={() => this.handleStatusChange("google")}
                >
                  <img
                    src={
                      status === "google" ? googleActiveIcon : googleInactiveIcon
                    }
                    data-tip="Google"
                    data-iscapture="true"
                    data-for="googIconInfo"
                    data-place="top"
                  />
                  <ReactTooltip
                    place="top"
                    type="info"
                    effect="solid"
                    id="googIconInfo"
                    html={true}
                  />
                </div>
              </div>
            </div>
            <div className="h100">
              <div>
                {pageLoad ? (
                  <div
                    style={{
                      display: "flex",
                      color: "#2e1a47",
                      justifyContent: "center",
                      // minHeight: "500px",
                      height: '100vh',
                      alignItems: "center",
                    }}
                  >
                    <Spinner
                      type="border"
                      color="#2e1a47"
                      style={{ color: "#2e1a47" }}
                    />
                  </div>
                ) : (
                  <>
                    {currentView === "monthly" && (
                      <div>
                        {daysDisplay && (
                          <div>
                            <div className="weekDays_display">
                              {weekDays.map((day) => (
                                <div className="individual_day">{day}</div>
                              ))}
                            </div>
                            <div className="days_display">{totalDays}</div>
                          </div>
                        )}
                      </div>
                    )}
                    {currentView === "weekly" && (
                      <div>
                        <div className="weekDays_display">
                          {weekDisplay.map((week) => (
                            <div className="individual_day_week">
                              <div
                                className={
                                  week.date === parseInt(moment().format("D"))
                                    ? "col_primary_2 f18 fw-400 mb-10"
                                    : "cl404040 f18 fw-400 mb-10"
                                }
                              >
                                {week.date}
                              </div>
                              <div
                                className={
                                  week.date === parseInt(moment().format("D"))
                                    ? "col_primary_2 f13 fw-500 "
                                    : "f13 fw-400 cl6C757D"
                                }
                              >
                                {week.day}
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className="days_display">{weekDaysDisplay}</div>
                      </div>
                    )}

                    {currentView === "daily" && (
                      <div className="flexColumn daysDisplay">{dayArray}</div>
                    )}
                  </>
                )}
              </div>
            </div>
          </CustomListingContainer>
        </div>

        <Card className={eventModal ? "exportSideBar" : "toggle-sidebar"}>
          <EventSideDragger
            isOpen={eventModal}
            close={this.openEventSideDragger}
            toast={this.displayToast}
            eventDetails={eventDetails}
          />
        </Card>
        <Card className={eventListModal ? "eventSideBar" : "toggle-sidebar"}>
          <EventListing
            isOpen={eventListModal}
            close={this.openEventListModal}
            toast={this.displayToast}
            maintainenceList={maintainenceList}
            requestList={requestList}
            googEventsList={googEventsList}
            edit={this.editGoogleEvent}
            delete={this.deleteEvent}
            loading={isLoading}
            status={status}
          />
        </Card>
      </div>
    );
  }
}

export default Calender;
