import React, { Component } from "react";
import {
  Card,
  Table,
  Container,
  Row,
  Breadcrumb,
  Col,
  InputGroup,
  Spinner,
  DropdownMenu,
  DropdownItem,
  Media,
  UncontrolledDropdown,
  DropdownToggle,
  CardBody,
} from "reactstrap";
import { Drawer } from "antd";
import { Tooltip } from "antd";
import ApiService from "constants/ApiService";

import "react-datepicker/dist/react-datepicker.css";

import { getIsoString, filterQuery } from "constants/utils";

import ToastList from "components/Toast/ToastList";

import { showError } from "constants/utils";
import {
  returnSearchObject,
  remove_underscore_capitalize,
} from "constants/utils";
import { pushToFilteredPage } from "constants/utils";
import { buildQueryString } from "constants/utils";
import moment from "moment";
import TimeLine from "components/Timeline/Timeline";

import showMore from "../../assets/img/brand/showMore.svg";

import { showSuccess } from "constants/utils";
import { Progress } from "reactstrap";
import ActiveProjectIcon from "../../../src/assets/img/icons/active_project_icon.svg";
import AllProjectIcon from "../../../src/assets/img/icons/all_project_icon.svg";
import CompletedProjectIcon from "../../../src/assets/img/icons/completed_project_icon.svg";

import DueTask from "../../../src/assets/img/icons/due_task.svg";
import task_icon from "../../../src/assets/img/icons/task.svg";
import kanban from "../../../src/assets/img/icons/kanban_board.svg";
import active_task_icon from "../../../src/assets/img/icons/kanban.svg";
import active_kanban_icon from "../../../src/assets/img/icons/taskList.svg";
import { uploadCloudinary } from "constants/utils";
import projectTimeline from "../../../src/assets/img/brand/projectTimeline.svg";
import CountUp from "react-countup";
import Progressbar from "components/Progress/Progressbar";
import UpdateTask from "./updateTask";
import { CustomListingContainer } from "components/Container/CustomListingContainer";
import { ListingHeaderLayout } from "components/Container/CustomListingContainer";
import { Button_without_Border } from "components/Buttons/Button";
import CustomModal from "components/CustomModal/CustomModal";
import { showUtcDate } from "constants/utils";
import ToolTip from "components/ToolTip/ToolTip";

let overlap = document.querySelector(":root");
const cards = [
  {
    name: "all",
    value: 0,
    image: AllProjectIcon,
  },
  {
    name: "active",
    value: 0,
    image: ActiveProjectIcon,
  },
  {
    name: "completed",
    value: 0,
    image: CompletedProjectIcon,
  },
  {
    name: "due",
    value: 0,
    image: DueTask,
  },
];
class Task extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requestList: [],
      project_list: [],
      weekDays: moment.weekdaysShort(),
      totalDays: [3, 4, 5, 6, 7],
      total: 0,
      prevProgress: "0",
      filterToggle: false,
      from: "",
      to: "",
      openEditModal: false,
      page: 1,
      notes: "",
      edit_detail: {},
      board: true,
      open: false,
      client_id: "",
      inputSearch: "",
      filterOption: "",
      statusSearch: this.props.location?.search ? "pending" : "",
      toast: false,
      response: "",
      image: [],
      iamge_name: "",
      toastType: "",
      isLoading: true,
      clientsList: [],
      btnLoad: false,
      task_detail: {},
      query: {},
      name: "",
      currentMonth: new Date(),
      client: {},
      start_date: "",
      actual_start: "",
      status: "",
      imageLoading: false,
      client_name: "",
      inputText: "",
      items: [],
      desc: "",
      tags: [],
      task_data: {},
      task_list: [],
      end_date: "",
      requestId: "",
      kanban_data: {},
      openModal: false,
      calender: false,
      delete_data: {},
      cancelModal: false,
      taskId: "",
      isTimeLineOpen: false,
      task_id: "",
      // cardList:['all','active','completed','due'],
      cards: cards,
    };
  }
  componentDidMount = async () => {
    // overlap.style.setProperty("--width", "600px");
    this.setQueryStatus();
    this.kanbanBoardData();
  };

  componentWillUnmount() {
    overlap.style.setProperty("--width", "450px");
  }

  closeModal = () => {
    this.setState({ openModal: false });
  };
  handleDelete = async () => {
    let response = await ApiService.patchAPI(
      `contractor/project/delete/${this.state.delete_data.project_id}`,
      {}
    );
    if (response.success) {
      showSuccess(this, response.message);
      this.setState({ delete_data: {} });
      this.closeModal();
      this.getProjectList();
    } else {
      showError(this, response.message);
    }
  };

  updateProgress = (value) => {
    this.setState((prevState) => ({
      edit_detail: {
        ...prevState.edit_detail,
        progress: value,
      },
    }));
  };

  setQueryStatus = () => {
    let queryStatus = this.props.location.search;
    if (queryStatus) {
      let queryObj = returnSearchObject(queryStatus);

      this.setState(
        {
          from: queryObj.from ? new Date(queryObj.from) : "",
          to: queryObj.to ? new Date(queryObj.to) : "",
          statusSearch: queryObj.status ?? "",
          //   page: parseInt(queryObj.page) ?? "",
          query: returnSearchObject(queryStatus),
        },
        () => {
          pushToFilteredPage(this.props, { ...this.state.query });
          this.getProjectList();
        }
      );
    } else {
      this.setState(
        {
          query: {
            ...this.state.query,
            // page: 1,
            // limit: 10,
          },
        },
        () => {
          pushToFilteredPage(this.props, this.state.query);
          this.getProjectList();
        }
      );
    }
  };
  redirectDetailPage = (id) => {
    this.props.history.push(`projects/detail/${id}`);
  };

  setPage = (page) => {
    const routeMap = {
      over_view: "project_overview",
      projects: "projects",
      clients: "clients",
    };

    const route = routeMap[page];

    if (route) {
      this.props.history.push(`${route}?assignType=outside`);
    }
  };

  dateChangeHandler = (e, value) => {
    this.setState({ [value]: e });
  };

  taskDetail = async (data) => {
    this.setState(
      {
        task_detail: data,
      },
      () => {
        overlap.style.setProperty("--width", "600px");
        this.setState({ open: true });
      }
    );
  };

  editTask = async (data) => {
    this.setState(
      {
        edit_detail: data,
        openEditModal: true,
        taskId: data.task_id,
      },
      () => {}
    );
  };

  getRequestsList = async () => {
    let { filterOption, statusSearch, inputSearch, from, to } = this.state;
    let queryParams = "";
    if (filterOption !== "" && inputSearch !== "")
      queryParams += filterQuery(filterOption, inputSearch);
    if (statusSearch !== "") {
      queryParams += filterQuery("status", statusSearch);
    }
    if (from !== "") queryParams += filterQuery("from", getIsoString(from));
    if (to !== "") queryParams += filterQuery("to", getIsoString(to));
    if (from > to) {
      showError(this, "Please provide valid From and To date");
    } else {
      let response = await ApiService.getAPI(
        `contractor/open_request${buildQueryString(this.state.query)}`
      );

      if (response.success) {
        let { open_request, total } = response.data;
        this.setState({
          requestList: open_request,
          total: total,
          isLoading: false,
        });
      }
    }
  };
  droped = (e) => {
    let dropItem = e.currentTarget;

    const dropParent = dropItem.getAttribute("dropindex");
  };
  getProjectList = async () => {
    let { filterOption, statusSearch, inputSearch, from, to } = this.state;
    let queryParams = "";
    if (filterOption !== "" && inputSearch !== "")
      queryParams += filterQuery(filterOption, inputSearch);
    if (statusSearch !== "") {
      queryParams += filterQuery("status", statusSearch);
    }
    if (from !== "") queryParams += filterQuery("from", getIsoString(from));
    if (to !== "") queryParams += filterQuery("to", getIsoString(to));
    if (from > to) {
      showError(this, "Please provide valid From and To date");
    } else {
      let response = await ApiService.getAPI(
        `contractor/task/dashboard${buildQueryString(this.state.query)}`
      );
      let taskResponse = await ApiService.getAPI(
        `contractor/task/outside${buildQueryString(this.state.query)}`
      );

      this.setState({ isLoading: false });
      if (response.success) {
        let tempObj = {};
        let tempArray = [];
        let cardValues = response.data;
        cards.map((obj) => (tempObj[obj.name] = obj));
        Object.keys(cardValues).map((key) => {
          tempObj[
            key === "all_tasks"
              ? "all"
              : key === "active_tasks"
              ? "active"
              : key === "completed_tasks"
              ? "completed"
              : "due"
          ]["value"] = cardValues[key];
        });
        cards.forEach((value) => tempArray.push(tempObj[value.name]));
      }
      if (taskResponse.success) {
        this.setState({ task_list: taskResponse.data.tasks });
        // this.setState({ task_list: [] });
      }
    }
  };

  kanbanBoardData = async () => {
    let response = await ApiService.getAPI(`contractor/task/kanban_tasks`);

    if (response.success) {
      this.setState({
        kanban_data: response.data,
        board: true,
      });
    }
  };

  handleTagsChange = (tags) => {
    this.setState({ tags: tags });
  };
  handleInputChange = (event) => {
    this.setState({ inputText: event.target.value });
  };

  handleEnterKey = (event) => {
    if (event.key === "Enter" && this.state.inputText.trim() !== "") {
      this.setState((prevState) => ({
        inputText: "",
        items: [...prevState.items, prevState.inputText],
      }));
    }
  };
  toggleFilterMenu = () => {
    this.setState({ filterToggle: !this.state.filterToggle });
  };
  onChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  updateStateValues = (key, value) => {
    this.setState(
      {
        [key]: value,
      },
      function () {
        if (value === null) {
          this.setState({
            [key]: "",
          });
        }
      }
    );
  };
  handleOnToChange = (e) => {
    this.updateStateValues("to", e ? new Date(e) : "");
  };
  handleOnFromChange = (e) => {
    this.updateStateValues("from", e ? new Date(e) : "");
  };
  handleIncrementPage = () => {
    if (this.state.requestList?.length >= 10) {
      this.setState({ page: this.state.page + 1 }, () =>
        this.handleFilterStatus()
      );
    }
  };
  handleDecrementPage = () => {
    if (this.state.page > 1) {
      this.setState({ page: this.state.page - 1 }, () =>
        this.handleFilterStatus()
      );
    }
  };

  capitalizeFirstLetter = (str) => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  imageHandler = async (e, subStatus) => {
    let { image } = this.state;
    let file = e.target.files[0];

    if (e.target.files?.length > 0) {
      this.setState({ imageLoading: true });
    }

    if (
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/jpeg" ||
      file.type === "application/pdf"
    ) {
      if (file.size < 1000000) {
        let data = await uploadCloudinary(file, "host");
        if (data.url) {
          let imgArr = [...image];
          let imageObj = {
            format: data.format,
            public_id: data.public_id,
            version: `v${data.version}`,
            type: "image",
          };
          imgArr.push(imageObj);
          this.setState({
            image: imgArr,
            imageLoading: false,
          });
        } else {
          showError(this, "Could not upload image");
          this.setState({ imageLoading: false });
        }
      } else {
        showError(this, "File size should not exceeds 1 MB");
        this.setState({ imageLoading: false });
      }
    } else {
      showError(this, "Invalid Format");
      this.setState({ imageLoading: false });
    }
  };

  deleteImage = () => {
    this.setState({ image: [], image_name: "" });
  };

  handleFilterStatus = () => {
    let staticQuery = returnSearchObject(this.props.location.search);
    staticQuery.from = getIsoString(this.state.from) ?? "";
    staticQuery.to = getIsoString(this.state.to) ?? "";

    staticQuery.status = this.state.statusSearch ?? "";

    if (this.state.inputSearch !== "" && this.state.filterOption !== "") {
      staticQuery[this.state.filterOption] = this.state.inputSearch;
    }
    this.setState({ query: staticQuery }, () => {
      pushToFilteredPage(this.props, { ...this.state.query });
      this.getProjectList();
    });
  };

  viewAllTasks = () => {
    this.props.history.push("tasks_list?assignType=outside");
  };

  handleReset = () => {
    let self = this;
    self.setState(
      {
        from: "",
        to: "",
        statusSearch: "",
        filterOption: "",
        inputSearch: "",
      },
      () => {
        pushToFilteredPage(this.props, { ...this.state.query });
        this.getProjectList();
      }
    );
  };

  setStatus = (status) => {
    this.setState({ statusSearch: status }, () => {
      this.handleFilterStatus();
    });
  };

  redirectToAddRequest = async () => {
    let response = await ApiService.getAPI(`contractor/client?status=active`);

    if (response.success) {
      let { clients } = response.data;
      this.setState({
        clientsList: clients,
        edit: false,
        open: true,
      });
    }
  };

  editRequest = (data) => {
    this.props.history.push(`/admin/request/update/${data?.open_request_id}`);
  };

  onClose = () => {
    overlap.style.setProperty("--width", "450px");
    this.setState(
      {
        tags: [],
        desc: "",
        name: "",
        client: {},
        start_date: "",
        actual_start: "",
        end_date: "",
        client_name: "",
      },
      () => {
        this.setState({ open: false });
      }
    );
  };

  onUpdateClick = (e) => {
    e.stopPropagation();
  };

  cancelRequest = async (request) => {
    this.setState({ cancelModal: true, requestId: request.open_request_id });
  };

  resendRequest = async (request) => {
    let response = await ApiService.patchAPI(
      `contractor/open_request/resend/${request.open_request_id}`
    );
    if (response.success) {
      showSuccess(this, response.message);
      this.getProjectList();
    } else {
      showError(this, response.message);
    }
  };

  tagClick = (string) => {
    remove_underscore_capitalize(string);
  };

  resendInvoice = async (request) => {
    let response = await ApiService.patchAPI(
      `contractor/open_request/invoice/${request.open_request_id}`
    );
    if (response.success) {
      showSuccess(this, response.message);
      this.getProjectList();
    } else {
      showError(this, response.message);
    }
  };
  dragOver = (e) => {
    e.preventDefault();
  };
  handleDragStart = (e, task, sourceColumn) => {
    e.dataTransfer.setData(
      "taskData",
      JSON.stringify({ task, column: sourceColumn })
    );
  };

  handleDragOver = (e) => {
    e.preventDefault();
  };

  redirectStatus = (status) => {
    this.props.history.push({
      pathname: "tasks_list",
      search: `&page=1&limit=10&status=${status}&assignType=outside`,
    });
  };

  handleDrop = async (e, targetColumn) => {
    e.preventDefault();
    const taskData = JSON.parse(e.dataTransfer.getData("taskData"));
    // if(moment(taskData.task.end_date) > moment()) {
    //   return showError(this.'')
    // }
    // if(taskData.column === 'completed' && taskData.task.status === 'completed') {return showError(this,'Task had been completed')}
    const taskId = taskData.task.task_id;

    // if(taskData.task.end_date)

    if (targetColumn === "overdue") {
      return;
    }
    if (targetColumn === taskData.column) {
      return;
    }

    try {
      if (targetColumn === "inProgress" || targetColumn === "completed") {
        let endpoint, body;

        if (targetColumn === "inProgress") {
          endpoint = `contractor/task/in_progress/${taskId}`;
          body = {};
        } else {
          endpoint = `contractor/task/complete/${taskId}`;
          body = {};
        }

        const response = await ApiService.patchAPI(endpoint, body);

        if (response.success) {
          const kanbanResponse = await ApiService.getAPI(
            "contractor/task/kanban_tasks"
          );

          if (kanbanResponse.success) {
            this.getProjectList();
            this.setState({
              kanban_data: kanbanResponse.data,
              board: true,
            });
          }
        }
      } else if (targetColumn === "overdue") {
      }
    } catch (error) {}
  };

  closeUpdateModal = (value) => {
    this.setState({ openEditModal: false });
    value && this.state.board ? this.kanbanBoardData() : this.getProjectList();
  };

  closeTimeline = () => {
    this.setState({ isTimeLineOpen: false });
  };
  render() {
    let {
      isLoading,
      edit_detail,
      task_list,
      board,
      toast,
      response,
      kanban_data,
      toastType,
      task_data,
      openEditModal,
      btnLoad,
      task_id,
      task_detail,
      open,
      cards,
    } = this.state;
    return isLoading ? (
      <div className="spin-loading">
        <Spinner type="border" color="#2e1a47" />
      </div>
    ) : (
      <>
        {toast && <ToastList message={response} type={toastType} />}
        <div style={{ padding: "20px", backgroundColor: "#fafafa" }}>
          <div style={{ margin: "24px 0px" }}>
            <div
              style={{
                marginBottom: "1.8rem",
              }}
            >
              <div className="header-body">
                <Row className="row-center">
                  {cards.map((card) => (
                    <Col lg="6" xl="3" className="max-dash">
                      <div
                        className="cardBody"
                        style={{ cursor: "pointer", padding: "0px" }}
                        onClick={() =>
                          this.redirectStatus(
                            card.name === "all" ? "" : card.name
                          )
                        }
                      >
                        <CardBody
                          className="pad-dash"
                          style={{ height: "95px" }}
                        >
                          <div
                            className="dash_content dash_content"
                            style={{ alignItems: "center" }}
                          >
                            <div className="dash_left">
                              <label className="point project_card_number">
                                <CountUp
                                  start={0}
                                  end={card.value}
                                  separator=","
                                />
                              </label>
                              <div>
                                <span className="project_card_sub">
                                  {card.name === "all"
                                    ? "All Tasks"
                                    : card.name === "due"
                                    ? "Over Due"
                                    : remove_underscore_capitalize(card.name)}
                                </span>
                              </div>
                            </div>
                            <div>
                              <img
                                src={card.image}
                                className="img-dash"
                                alt="total sales"
                                style={{
                                  height: "32px",
                                  width: "32px",
                                  marginTop: "-23%",
                                }}
                              />
                            </div>
                          </div>
                        </CardBody>
                        {/* {card === "all" ? "All Tasks" : card === "due" ? 'Over Due' :remove_underscore_capitalize(card) } */}
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            </div>
            <div>
              <InputGroup className="mb-5 mb_mt_28 flex-content align-left">
                <div
                  className="filters_container"
                  style={{ justifyContent: "flex-end" }}
                >
                  <div
                    className="right_filter_container"
                    style={{ width: "32%", gap: "1rem" }}
                  >
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          border: "1px solid #F0F0F0",
                          padding: "0.5rem 1rem 0.5rem 1rem",
                          borderRadius: "5px 0px 0px 5px",
                          cursor: "pointer",
                        }}
                        // onClick={this.kanbanBoardData}
                        onClick={() =>
                          this.setState({
                            board: true,
                          })
                        }
                        className="task_dash_icon"
                      >
                        {board ? (
                          <img
                            src={active_kanban_icon}
                            alt="active_kanban_board"
                          />
                        ) : (
                          <img src={kanban} alt="kanban_board" />
                        )}
                      </div>
                      <div
                        style={{
                          border: "1px solid #F0F0F0",
                          padding: "0.5rem 1rem 0.5rem 1rem",
                          borderRadius: "0px 5px 5px 0px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          this.setState({ board: false });
                        }}
                        className="task_dash_icon"
                      >
                        {!board ? (
                          <img src={active_task_icon} alt="active_task" />
                        ) : (
                          <img src={task_icon} alt="task" />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </InputGroup>
              {!board && (
                <CustomListingContainer>
                  {/* <ListingHeaderLayout label={"tasks"}> */}
                  <div className="heading_without_border">
                    <span style={{ fontWeight: "500" }}>Tasks</span>
                    {task_list.length ? (
                      <Button_without_Border
                        btnLabel="view_all"
                        click={() => this.viewAllTasks()}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                  {/* </ListingHeaderLayout> */}
                  <div>
                    {!board && (
                      <Row>
                        <div className="col new-table">
                          <Card className="shadow card_with_paginator">
                            <Table
                              className="align-items-center table-flush"
                              responsive
                            >
                              <thead className="thead-light">
                                <tr>
                                  <th style={{ width: "25%" }} scope="col">
                                    Task
                                  </th>
                                  <th style={{ width: "25%" }} scope="col">
                                    Assigned By
                                  </th>
                                  <th style={{ width: "15%" }} scope="col">
                                    Due On
                                  </th>
                                  {/* <th style={{ width: "15%" }} scope="col">Actual Start</th> */}

                                  {/* <th style={{ width: "15%" }} scope="col">Due On</th> */}
                                  <th style={{ width: "15%" }} scope="col">
                                    Progress
                                  </th>
                                  <th style={{ width: "15%" }} scope="col">
                                    Status
                                  </th>
                                  <th></th>
                                </tr>
                              </thead>
                              {task_list.length && task_list?.length ? (
                                <tbody>
                                  {task_list.map((task, i) => (
                                    <tr key={i}>
                                      <td
                                        className="cursor-point capitalize"
                                        style={{ width: "100px" }}
                                      >
                                        <div className="add_eclipse">
                                          {this.capitalizeFirstLetter(
                                            task.name
                                          )}
                                        </div>
                                      </td>
                                      <td>
                                        <div className="add_eclipse">
                                          {" "}
                                          {this.capitalizeFirstLetter(
                                            task.contractor.name
                                          )}
                                        </div>
                                      </td>

                                      <td>
                                        <div className="add_eclipse">
                                          {showUtcDate(task.end_date)}
                                        </div>
                                      </td>
                                      <td
                                        className={
                                          task?.invoice?.status === "paid" ||
                                          task?.invoice?.status === "assigned"
                                            ? "text-capitalize status_accepted "
                                            : task?.invoice?.status ===
                                                "cancelled" ||
                                              task?.invoice?.status ===
                                                "rejected"
                                            ? "text-capitalize red "
                                            : task?.invoice?.status ===
                                                "created" ||
                                              task?.invoice?.status ===
                                                "pending"
                                            ? "text-capitalize yellow "
                                            : task?.invoice?.status ===
                                              "completed"
                                            ? "text-capitalize status_completed "
                                            : task?.invoice?.status === "closed"
                                            ? "text-capitalize primary "
                                            : "text-capitalize"
                                        }
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "4%",
                                          }}
                                        >
                                          <div>
                                            <Progress
                                              color="#0A8080"
                                              value={task.progress ?? 0}
                                            />
                                          </div>
                                          <div className="project_list_percentage">
                                            {task.progress ?? 0} %
                                          </div>
                                        </div>
                                      </td>

                                      <td>
                                        <div
                                          className="add_eclipse"
                                          style={{
                                            color: `${
                                              task.status === "completed" ||
                                              task.status === "approved"
                                                ? "#07A84E"
                                                : task.status === "pending"
                                                ? "#FF4949"
                                                : task.status === "due"
                                                ? "#0070FD"
                                                : "#FFCC3D"
                                            }`,
                                          }}
                                        >
                                          {" "}
                                          {this.capitalizeFirstLetter(
                                            task.status === "in_progress"
                                              ? "in progress"
                                              : task.status
                                          )}
                                        </div>
                                      </td>

                                      <td
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "flex-end",
                                          gap: "10px",
                                        }}
                                      >
                                        <div>
                                          <ToolTip label="Timeline">
                                            <img
                                              onClick={() =>
                                                this.setState({
                                                  isTimeLineOpen: true,
                                                  task_id: task.task_id,
                                                })
                                              }
                                              style={{ cursor: "pointer" }}
                                              src={projectTimeline}
                                            ></img>
                                          </ToolTip>
                                        </div>
                                        <div className="add_eclipse">
                                          <UncontrolledDropdown
                                            style={{ justifyContent: "center" }}
                                            nav
                                            className="flexy-content position_u"
                                          >
                                            <DropdownToggle
                                              className="pr-0 nav-notice"
                                              nav
                                            >
                                              {/* <Media className="align-items-center"> */}
                                              <img
                                                src={showMore}
                                                alt="show more"
                                              />
                                              {/* </Media> */}
                                            </DropdownToggle>
                                            <DropdownMenu
                                              className="dropdown-menu-arrow min-width-drop"
                                              right
                                            >
                                              <DropdownItem
                                                onClick={() =>
                                                  this.taskDetail(task)
                                                }
                                              >
                                                <span>Detail</span>
                                              </DropdownItem>
                                              {task.status !== "approved" && (
                                                <DropdownItem
                                                  onClick={() =>
                                                    this.editTask(task)
                                                  }
                                                >
                                                  <span>Update</span>
                                                </DropdownItem>
                                              )}
                                            </DropdownMenu>
                                          </UncontrolledDropdown>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              ) : (
                                <div
                                  style={{
                                    height: "300px",
                                  }}
                                >
                                  <div
                                    style={{ paddingLeft: "30px" }}
                                    className="no-data"
                                  >
                                    No Tasks Available
                                  </div>
                                </div>
                              )}
                            </Table>
                          </Card>
                        </div>
                      </Row>
                    )}
                  </div>
                </CustomListingContainer>
              )}

              {board && (
                <div>
                  <div className="kanBoardChart">
                    {/* Column 1: Overdue */}
                    <div
                      style={{ backgroundColor: "white" }}
                      className="overdueFiled"
                      onDragOver={(e) => this.handleDragOver(e)}
                      onDrop={(e) => this.handleDrop(e, "overdue")}
                    >
                      <div style={{ paddingBottom: "1rem" }}>Past Due</div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "1rem",
                          minWidth: "15rem",
                          cursor: "pointer",
                        }}
                      >
                        {kanban_data?.overdueTasks?.length ? (
                          <>
                            {kanban_data.overdueTasks?.map((data) => (
                              <div
                                onClick={() => this.taskDetail(data)}
                                key={data.task_id}
                                draggable
                                style={{
                                  border: "1px solid #F0F0F0",
                                  padding: "1rem",
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "7px",
                                  borderRadius: "5px",
                                }}
                                onDragStart={(e) =>
                                  this.handleDragStart(e, data, "over_due")
                                }
                              >
                                <div
                                  style={{
                                    display: "grid",
                                    gridTemplateColumns: "95% 15%",
                                  }}
                                >
                                  <div
                                    className="kanban-card-title"
                                    style={{
                                      fontSize: "17px",
                                      color: "#333333",
                                    }}
                                  >
                                    {this.capitalizeFirstLetter(data.name)}
                                  </div>

                                  <div
                                    onClick={this.onUpdateClick}
                                    className="add_eclipse"
                                  >
                                    <UncontrolledDropdown
                                      nav
                                      className="flexy-content position_u"
                                    >
                                      <DropdownToggle
                                        className="pr-0 nav-notice"
                                        nav
                                      >
                                        <Media className="align-items-center">
                                          <img src={showMore} alt="show more" />
                                        </Media>
                                      </DropdownToggle>
                                      <DropdownMenu
                                        className="dropdown-menu-arrow min-width-drop"
                                        right
                                      >
                                        <DropdownItem
                                          onClick={() =>
                                            this.setState({
                                              isTimeLineOpen: true,
                                              task_id: data.task_id,
                                            })
                                          }
                                        >
                                          <span>Timeline</span>
                                        </DropdownItem>
                                        <DropdownItem
                                          onClick={() => this.editTask(data)}
                                        >
                                          <span>Update</span>
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                  </div>
                                </div>

                                <div
                                  className="kanban-card-title"
                                  style={{
                                    fontWeight: "300",
                                    fontSize: "14px",
                                    width: "90%",
                                    lineHeight: "1.5rem",
                                    color: "#757575",
                                    wordBreak: "break-all",
                                  }}
                                >
                                  {data.desc}
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    gap: "3rem",
                                  }}
                                >
                                  <div style={{ display: "flex", gap: "10px" }}>
                                    <div
                                      className="kanban-card-title"
                                      style={{
                                        fontWeight: "300",
                                      }}
                                    >
                                      Due On :
                                    </div>
                                    <div
                                      className="kanban-card-title"
                                      style={{
                                        fontWeight: "300",
                                        color: "#A6A4E0",
                                      }}
                                    >
                                      {showUtcDate(data.end_date)}
                                    </div>
                                  </div>

                                  <div
                                    style={{
                                      display: "flex",
                                      width: "9rem",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      gap: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: "8rem",
                                        paddingTop: "1rem",
                                      }}
                                    >
                                      <Progress value={data.progress ?? 0} />
                                    </div>
                                    <div className="project_list_percentage">
                                      {data.progress ?? 0}%
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </>
                        ) : (
                          <div style={{ minHeight: "300px" }}>
                            <div className="noDataPresent">
                              No Pastdue Tasks
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    {/* Column 2: In Progress */}
                    <div
                      className="overdueFiled"
                      style={{
                        border: "1px solid #E1E8F0",
                        padding: "1rem",
                        borderRadius: "10px",
                        borderTop: "5px solid #FFCC3D",
                        minWidth: "26.5rem",
                        backgroundColor: "white",
                      }}
                      onDragOver={(e) => this.handleDragOver(e)}
                      onDrop={(e) => this.handleDrop(e, "inProgress")}
                    >
                      <div style={{ paddingBottom: "1rem" }}>Active Tasks</div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "1rem",
                          minWidth: "15rem",
                          cursor: "pointer",
                        }}
                      >
                        {kanban_data?.progressTasks?.length ? (
                          <>
                            {kanban_data.progressTasks?.map((data) => (
                              <div
                                onClick={() => this.taskDetail(data)}
                                key={data.task_id}
                                draggable
                                onDragStart={(e) =>
                                  this.handleDragStart(e, data, "inProgress")
                                }
                                style={{
                                  border: "1px solid #F0F0F0",
                                  padding: "1rem",
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "7px",
                                  borderRadius: "5px",
                                }}
                              >
                                <div
                                  style={{
                                    display: "grid",
                                    gridTemplateColumns: "95% 15%",
                                  }}
                                >
                                  <div
                                    className="kanban-card-title"
                                    style={{
                                      fontSize: "17px",
                                      color: "#333333",
                                    }}
                                  >
                                    {this.capitalizeFirstLetter(data.name)}
                                  </div>
                                  <div
                                    onClick={this.onUpdateClick}
                                    className="add_eclipse"
                                  >
                                    <UncontrolledDropdown
                                      nav
                                      className="flexy-content position_u"
                                    >
                                      <DropdownToggle
                                        className="pr-0 nav-notice"
                                        nav
                                      >
                                        <Media className="align-items-center">
                                          <img src={showMore} alt="show more" />
                                        </Media>
                                      </DropdownToggle>
                                      <DropdownMenu
                                        className="dropdown-menu-arrow min-width-drop"
                                        right
                                      >
                                        <DropdownItem
                                          onClick={() =>
                                            this.setState({
                                              isTimeLineOpen: true,
                                              task_id: data.task_id,
                                            })
                                          }
                                        >
                                          <span>Timeline</span>
                                        </DropdownItem>
                                        <DropdownItem
                                          onClick={() => this.editTask(data)}
                                        >
                                          <span>Update</span>
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                  </div>
                                </div>

                                <div
                                  className="kanban-card-title"
                                  style={{
                                    fontWeight: "300",
                                    fontSize: "14px",
                                    width: "90%",
                                    lineHeight: "1.5rem",
                                    color: "#757575",
                                    wordBreak: "break-all",
                                  }}
                                >
                                  {data.desc}
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    gap: "3rem",
                                  }}
                                >
                                  <div style={{ display: "flex", gap: "10px" }}>
                                    <div
                                      className="kanban-card-title"
                                      style={{
                                        fontWeight: "300",
                                        color: "#757575",
                                      }}
                                    >
                                      Due On :
                                    </div>
                                    <div
                                      className="kanban-card-title"
                                      style={{
                                        fontWeight: "300",
                                        color: "#A6A4E0",
                                      }}
                                    >
                                      {showUtcDate(data.end_date)}
                                    </div>
                                  </div>

                                  <div
                                    style={{
                                      display: "flex",
                                      width: "9rem",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      gap: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: "8rem",
                                        paddingTop: "1rem",
                                      }}
                                    >
                                      <Progress
                                        color="#0A8080"
                                        value={data.progress ?? 0}
                                      />
                                    </div>
                                    <div className="project_list_percentage">
                                      {data.progress ?? 0}%
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </>
                        ) : (
                          <div style={{ minHeight: "300px" }}>
                            <div className="noDataPresent">No Active tasks</div>
                          </div>
                        )}
                      </div>
                    </div>
                    {/* Column 3: Completed */}
                    <div
                      className="overdueFiled"
                      style={{
                        border: "1px solid #E1E8F0",
                        padding: "1rem",
                        borderRadius: "10px",
                        borderTop: "5px solid #1DC9A0",
                        minWidth: "26.5rem",
                        backgroundColor: "white",
                      }}
                      onDragOver={(e) => this.handleDragOver(e)}
                      onDrop={(e) => this.handleDrop(e, "completed")}
                    >
                      <div style={{ paddingBottom: "1rem" }}>Completed</div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "1rem",
                          minWidth: "15rem",
                          cursor: "pointer",
                        }}
                      >
                        {kanban_data.completedTasks?.length ? (
                          <>
                            {kanban_data.completedTasks?.map((data) => (
                              <div
                                onClick={() => this.taskDetail(data)}
                                key={data.task_id}
                                draggable
                                onDragStart={(e) =>
                                  this.handleDragStart(e, data, "completed")
                                }
                                style={{
                                  border: "1px solid #F0F0F0",
                                  padding: "1rem",
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "7px",
                                  borderRadius: "5px",
                                }}
                              >
                                <div
                                  style={{
                                    display: "grid",
                                    gridTemplateColumns: "95% 5%",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div
                                    className="kanban-card-title"
                                    style={{
                                      fontSize: "17px",
                                      color: "#333333",
                                    }}
                                  >
                                    {this.capitalizeFirstLetter(data.name)}
                                  </div>

                                  <div
                                    onClick={this.onUpdateClick}
                                    // style={{
                                    //   position: "absolute",
                                    //   right: "0",
                                    //   zIndex: "1000",
                                    // }}
                                    className="project_list_content"
                                  >
                                    <UncontrolledDropdown
                                      nav
                                      className="flexy-content position_u"
                                    >
                                      <DropdownToggle
                                        className="pr-0 nav-notice"
                                        nav
                                      >
                                        <Media className="align-items-center">
                                          <img src={showMore} alt="show more" />
                                        </Media>
                                      </DropdownToggle>
                                      <DropdownMenu
                                        className="dropdown-menu-arrow min-width-drop"
                                        right
                                      >
                                        <DropdownItem
                                          onClick={() =>
                                            this.setState({
                                              isTimeLineOpen: true,
                                              task_id: data.task_id,
                                            })
                                          }
                                        >
                                          <span>Timeline</span>
                                        </DropdownItem>
                                        {/* <DropdownItem
                                      onClick={() => this.editTask(data)}
                                    >
                                      <span>Update</span>
                                    </DropdownItem> */}
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                  </div>
                                </div>

                                <div
                                  className="kanban-card-title"
                                  style={{
                                    fontWeight: "300",
                                    fontSize: "14px",
                                    width: "90%",
                                    lineHeight: "1.5rem",
                                    color: "#757575",
                                    wordBreak: "break-all",
                                  }}
                                >
                                  {data.desc}
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    gap: "3rem",
                                  }}
                                >
                                  <div style={{ display: "flex", gap: "10px" }}>
                                    <div
                                      className="kanban-card-title"
                                      style={{
                                        fontWeight: "300",
                                        color: "#757575",
                                      }}
                                    >
                                      Due On :
                                    </div>
                                    <div
                                      className="kanban-card-title"
                                      style={{
                                        fontWeight: "300",
                                        color: "#A6A4E0",
                                      }}
                                    >
                                      {showUtcDate(data.end_date)}
                                    </div>
                                  </div>

                                  <div
                                    style={{
                                      display: "flex",
                                      width: "9rem",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      gap: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: "8rem",
                                        paddingTop: "1rem",
                                      }}
                                    >
                                      <Progress
                                        color="#0A8080"
                                        value={data.progress ?? 0}
                                      />
                                    </div>
                                    <div className="project_list_percentage">
                                      {data.progress ?? 0}%
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </>
                        ) : (
                          <div style={{ minHeight: "300px" }}>
                            <div className="noDataPresent">
                              No Completed tasks
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <Card
            className={open ? "exportSideBar" : "toggle-sidebar"}
            style={{ zIndex: "1000" }}
          >
            {open && (
              <CustomModal onlyView close={this.onClose} type="Task Detail">
                <div>
                  <div style={{ display: "flex", gap: "3%" }}>
                    <div>
                      {" "}
                      <Progressbar
                        progress={task_detail.progress ?? 0}
                        size={70}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        gap: "15%",
                      }}
                    >
                      <div
                        style={{}}
                        onClick={() => this.tagClick(task_detail.status)}
                        className="task_sub_drawer_title"
                      >
                        {this.capitalizeFirstLetter(task_detail.name)}
                      </div>
                      <div className="task_drawer_desc mt-10">
                        {this.capitalizeFirstLetter(task_detail.desc)}
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      marginTop: "1.5rem",
                      gap: "2rem",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "0.7rem",
                      }}
                    >
                      <div className="task_drawer_data">End Date</div>
                      <div
                        className="task_drawer_data"
                        style={{ color: "#333333", weight: "500" }}
                      >
                        {showUtcDate(task_detail.end_date)}
                      </div>
                    </div>
                    <div class="vertical-line"></div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "0.7rem",
                      }}
                    >
                      <div className="task_drawer_data">Status</div>
                      <div
                        className="task_drawer_data"
                        style={{
                          color: `${
                            task_detail.status === "completed" ||
                            task_detail.status === "approved"
                              ? "#07A84E"
                              : task_detail.status === "pending"
                              ? "#FF4949"
                              : task_detail.status === "due"
                              ? "#0070FD"
                              : "#FFCC3D"
                          }`,
                          weight: "500",
                        }}
                      >
                        {this.capitalizeFirstLetter(
                          task_detail?.status === "in_progress"
                            ? "In progress"
                            : task_detail.status
                        )}
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div
                      className="desc_heading"
                      style={{
                        paddingBottom: "1rem",
                        color: "#333333",
                        paddingTop: "1.4rem",
                      }}
                    >
                      Tags
                    </div>

                    <div
                      style={{
                        // display: "grid",
                        // gridTemplateColumns:"repeat(auto-fill, minmax(100px, 1fr))",
                        display:'flex',
                        overflowY:'scroll',
                        gap: "10px",
                        marginBottom: "1%",
                        marginTop: "0.7%",
                      }}
                      className="desc_sub_text"
                    >
                      {task_detail.tags && task_detail.tags?.length > 0 ? (
                        task_detail.tags.map((val) => (
                          <div
                            style={{
                              borderRadius: "30px",
                              background: "#EFEFEF",
                              padding: "5px 10px 5px 13px",
                            }}
                          >
                            <span>{val}</span>
                          </div>
                        ))
                      ) : (
                        <div>-</div>
                      )}
                    </div>
                  </div>
                  <hr />
                </div>
              </CustomModal>
            )}
          </Card>
          <Card
            className={openEditModal ? "exportSideBar" : "toggle-sidebar"}
            style={{ zIndex: "1000" }}
          >
            {openEditModal && (
              <UpdateTask
                closeUpdateModal={this.closeUpdateModal}
                taskId={this.state.taskId}
                props={this}
              ></UpdateTask>
            )}
          </Card>

          <Card
            className={
              this.state.isTimeLineOpen ? "exportSideBar" : "toggle-sidebar"
            }
            style={{ zIndex: "1000" }}
          >
            {this.state.isTimeLineOpen && (
              <TimeLine
                from={"task"}
                id={task_id}
                closeTimelineModal={this.closeTimeline}
              ></TimeLine>
            )}
          </Card>
        </div>
      </>
    );
  }
}

export default Task;
