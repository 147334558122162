import React, { Component } from "react";
import {
  CardHeader,
  Input,
  InputGroup,
  InputGroupText,
  Spinner,
  Button,
  Card,
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import closeIcon from "../../assets/img/icons/white_close_icon.svg";
import dateLight from "../../assets/img/brand/datePicker.svg";
import moment from "moment";
import AddClient from "./addClient";
import ApiService from "constants/ApiService";
import { showError } from "constants/utils";
import { showSuccess } from "constants/utils";
import { CustomInputFields } from "components/FilterFields/Filterfields";
import { CustomSingleDatePicker } from "components/FilterFields/Filterfields";
import { AmountInput } from "components/FilterFields/Filterfields";
import { CustomTextArea } from "components/FilterFields/Filterfields";
import { Button_without_Border } from "components/Buttons/Button";
import { Custom_Button } from "components/Buttons/Button";

class AddChangeOrder extends Component {
  constructor(props) {
    super(props);
    this.inputKey = Date.now();
    this.fileInputRef = React.createRef();
    this.myElementRef = React.createRef();
    this.myInputElementRef = React.createRef();
    this.state = {
      changeOrder: {},
      name: "",
      client_name: "",
      clientsList: [],
      subContractor_name: "",
      sub_contractorList: [],
      start_date: "",
      image: [],
      selected_team: "",
      actual_start: "",
      end_date: "",
      contractor_type: "",
      subContractor: {},
      imageLoading: false,
      desc: "",
      tags: [],
      contractorInput: "",
      btnLoad: false,
      edit: false,
      initPageLoading: true,
      tag: "",
      subContractor_id: "",
      clientAdd: false,
      isSearchOpen: false,
      activeIndex: -1,
      disabled: false,
      isApiCallPending: false,
      file: null,
      projectStartDate: null,
      projectActualStarDate: null,
      projectEndDate: null,
      otherProObj: {},
      title: "",
      due_date: "",
      amount: "",
      desc: "",
      status: "",
    };
  }

  componentDidMount = () => {
    this.setState({ initPageLoading: false });
    this.props.orderId && this.getOrderDetail();
  };

  getOrderDetail = async () => {
    this.setState({ initPageLoading: true , edit : true});
    const { orderId } = this.props;
    let response = await ApiService.getAPI(
      `contractor/changeOrder/detail/${orderId}`
    );
    this.setState({ initPageLoading: false });
    if (response.success) {
      let { data } = response;
      this.setState({
        title: data.title,
        due_date: data.due_date,
        amount: data.amount,
        desc: data.dec ?? "",
        status: data.status,
      });
    }
  };

  closeModal = () => {
    this.props.close();
  };

  updateState = (obj) => {
    let tempObj = {};
    const requiredFields = ["title", "createdAt", "due_date", "desc", "amount"];
    for (let field of requiredFields) {
      if (obj[field]) {
        if (field === "due_date" || field === "createdAt") {
          tempObj[field] = new Date(obj[field]);
        } else {
          tempObj[field] = obj[field];
        }
      }
    }
    this.setState((prevState) => ({
      changeOrder: {
        ...prevState.changeOrder,
        ...tempObj,
      },
    }));
  };

  setDescritpion = (value) => {
    this.setState({ desc: value });
  };

  createOrUpdateOrders = async () => {
    let { title, due_date, amount } = this.state;
    if (!title) return showError(this.props.props, "Please enter a title");
    else if (!due_date)
      return showError(this.props.props, "Please enter a due date");
    else if (!(+amount))
      return showError(this.props.props, "Please enter a amount");
    else {
      let response;
      const { props } = this.props;
      const { changeOrder } = this.state;
      const { orderId, projectId } = this.props;

      let data = {
        title: this.state.title,
        due_date: this.state.due_date,
        amount: this.state.amount,
        desc: this.state.desc ?? "",
      };
      delete data.createdAt;

      this.setState({ btnLoad: true });
      try {
        if (orderId) {
          response = await ApiService.patchAPI(
            `contractor/changeOrder/${orderId}`,
            data
          );
        } else {
          response = await ApiService.postAPI(
            `contractor/changeOrder/${projectId}`,
            data
          );
        }
        if (!response.success) throw new Error(response.message);
        else {
          showSuccess(props, response.message);
          this.props.close(true);
        }
      } catch (err) {
        showError(props, err.message);
        this.setState({ btnLoad: false });
      }
    }
  };

  handleInputChange = (value) => {
    this.setState({ title: value });
  };

  handleDuetDate = (e) => {
    this.setState({ due_date: e ? new Date(e) : "" });
  };

  getValue = (value) => {
    const pattern = /^\d+(\.\d+)?$/;
    if (pattern.test(value) || value === "") {
      this.setState({ amount: value });
    }
  };

  render() {
    const {
      changeOrder,
      name,
      start_date,
      actual_start,
      desc,
      btnLoad,
      edit,
      initPageLoading,
      title,
      due_date,
      amount,
      status,
    } = this.state;
    const { orderId, projectId } = this.props;

    return initPageLoading ? (
      <div className="spin-loading">
        <Spinner
          style={{
            height: "30px",
            width: "30px",
          }}
          type="border"
          color="#012A4D"
        />
      </div>
    ) : (
      <>
        <div>
          <CardHeader>
            <div className="flex">
              <div className="fw-500 f16" style={{ color: "white" }}>
              {["accepted", "completed", "paid"].includes(status) ? "Change Order Details"  : edit ? "Update Change Order" : "Add Change Order"}
              </div>
              <img
                src={closeIcon}
                onClick={() => this.props.close(false)}
                className="point"
              />
            </div>
          </CardHeader>
          <div className="cardContainer">
            <label className="customLabel">Title*</label>
            <CustomInputFields
              style={{ marginBottom: "20px" }}
              inputValue={title}
              type="text"
              change={this.handleInputChange}
              from="email"
              disabled={["paid"].includes(status)}
              placeHolder="Enter the title"
            />

            <label className="customLabel">Due on*</label>
            <CustomSingleDatePicker
              style={{ marginBottom: "20px", width: "100%" }}
              value={due_date}
              change={this.handleDuetDate}
              disabled={["accepted", "completed", "paid"].includes(status)}
            />

            <label className="customLabel">Amount*</label>
            <AmountInput
              disabled={["accepted", "completed", "paid"].includes(status)}
              style={{ marginBottom: "20px" }}
              amount={amount}
              change={this.getValue}
            />

            <label className="customLabel">Description</label>
            <CustomTextArea
              disabled={["paid", "accepted", "completed"].includes(status)}
              inputValue={desc}
              change={this.setDescritpion}
            />

            {!["paid", "accepted", "completed"].includes(status) && (
              <div style={{ marginTop: "30px" }} className="flexEnd">
                <Button_without_Border
                  style={{ marginRight: "20px" }}
                  btnLabel="cancel"
                  click={() => this.props.close(false)}
                />
                <Custom_Button
                  isPrimary
                  click={() => this.createOrUpdateOrders()}
                  isLoading={btnLoad}
                  btnLabel={orderId ? "Update" : "Save"}
                />
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}
export default AddChangeOrder;
