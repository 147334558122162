import React, { Component } from "react";
import {
  Card,
  Table,
  Container,
  Row,
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Button,
  InputGroup,
  InputGroupText,
  Spinner,
  DropdownMenu,
  DropdownItem,
  Media,
  UncontrolledDropdown,
  DropdownToggle,
  Modal,
  Input,
} from "reactstrap";
import NoData from "../../assets/Lotties/noData.json";
import moment from "moment";
import Lottie from "react-lottie";
import editIcon from "../../assets/img/brand/edit.svg";
import ApiService from "constants/ApiService";
import projectTimeline from "../../../src/assets/img/brand/projectTimeline.svg";
import "react-datepicker/dist/react-datepicker.css";
import sendChat from "../../assets/img/brand/send_chat.svg";
import { getIsoString, filterQuery } from "constants/utils";
import ToastList from "components/Toast/ToastList";
import CrmPhotos from "../../assets/img/icons/crm_photo.svg";
import CrmDocument from "../../assets/img/icons/crm_documents.svg";
import chatIcon from "../../assets/img/icons/chatIcon.svg";
import { showError } from "constants/utils";
import { returnSearchObject } from "constants/utils";
import { pushToFilteredPage } from "constants/utils";
import { buildQueryString } from "constants/utils";
import pksLogo from "../../../src/assets/img/brand/default_project_logo.svg";
import projectEdit from "../../../src/assets/img/icons/project_edit.svg";
import docIcon from "../../../src/assets/img/icons/docIcon.svg";
import downloadIcon from "../../../src/assets/img/icons/downloadIcon.svg";
import imageIcon from "../../../src/assets/img/icons/imageIcon.svg";
import showMore from "../../assets/img/brand/showMore.svg";
import { showSuccess } from "constants/utils";
import TimeLine from "components/Timeline/Timeline";
import { uploadCloudinary } from "constants/utils";
import { getCloudinaryImage } from "constants/utils";
import DeleteModal from "./DeleteModal";
import AddProject from "./addProject";
import UpdateStatus from "../Project/updateStatus";
import RejectNotes from "./rejectNotes";
import AddPhotosDocuments from "./addPhotosOrDocuments";
import Details_change_orders from "./projectDetails/Details_change_orders";
import { calculatePercentageNumber } from "constants/utils";
import { Progress } from "antd";
import ProjectDetailTable from "components/projectDetail/ProjectDetailTable";
import AddChangeOrder from "./addChangeOrder";
import AddWorkOrder from "./addWorkOrder";
import ProjectChat from "./projectChat";
import { CustomListingContainer } from "components/Container/CustomListingContainer";
import { Button_without_Border } from "components/Buttons/Button";
import { Custom_Button } from "components/Buttons/Button";
import { CustomDropDown } from "components/FilterFields/Filterfields";
import ProjectDetail_overview from "./projectDetails/projectDetail_overview";
import ProjectDetailTaskList from "./ProjectDetailTaskList";
import ChangeOrderList from "./changeOrderList";
let overlap = document.querySelector(":root");

let project_id;
let selectedTab;
class newProjectDetail extends Component {
  constructor(props) {
    super(props);
    project_id = this.props.match.params.project_id;
    this.textareaRef = React.createRef();

    this.state = {
      projectId: "",
      requestList: [],
      payments: [],
      upd_btn: false,
      total: 0,
      filterToggle: false,
      from: "",
      to: "",
      page: 1,
      openChangeOrder: false,
      orderId: "",
      first_table_slider: "task",
      second_table_slider: "invoice",
      third_table_slider: "doc",
      delete_data: {},
      open: false,
      inputSearch: "",
      filterOption: "",
      statusSearch: "",
      toast: false,
      response: "",
      toastType: "",
      isLoading: true,
      timeline: {},
      name: "",
      actual_start: "",
      end_date: "",
      start_date: "",
      inputText: "",
      image: [],
      changeOrdersList: [],
      workOrdersList: [],
      edit_project: false,
      btnLoad: false,
      open_project: false,
      subContractor_name: "",
      subContractor_id: "",
      subContractor_list: [],
      clientsList: [],
      final_project: {},
      desc: "",
      task_image: [],
      project_image: [],
      project: {},
      tasks: [],
      query: {},
      workOrder_id: "",
      requestId: "",
      cancelModal: false,
      openModal: false,
      tags: [],
      task_id: "",
      openOrder: false,
      project_id: project_id,
      notesOpen: false,
      isTimeLineOpen: false,
      workOrderEdit: false,
      workOrder_id: "",
      openProjectChat: false,
      type: "",
      content: [
        // {
        //   name: "Comments",
        //   img: CrmComment,
        //   selected: true,
        // },
        {
          name: "Documents",
          img: CrmDocument,
          selected: false,
        },
        {
          name: "Photos",
          img: CrmPhotos,
          selected: false,
        },
      ],
      comments: [
        {
          content: "hi",
          date: new Date(),
        },
        {
          content: "hi",
          date: new Date(),
        },
        {
          content: "hi",
          date: new Date(),
        },
        {
          content: "hi",
          date: new Date(),
        },
        {
          content: "hi",
          date: new Date(),
        },
        {
          content: "hi",
          date: new Date(),
        },
      ],

      selectedContent: "Documents",
      openAddDocumentsOrPhotos: false,
      photos: [],
      documents: [],
      orderData: {},
      docs: [
        { name: "Document 1" },
        { name: "Document 2" },
        { name: "Document 3" },
        { name: "Document 4" },
      ],
      photos: [
        { name: "Image 1" },
        { name: "Image 2" },
        { name: "Image 3" },
        { name: "Image 4" },
      ],
      // pageType: "",
    };
  }

  componentDidMount() {
    selectedTab = this.props.location?.state?.from;
    let queryStatus = this.props.location.search;
    let queryObj = returnSearchObject(queryStatus);
    if (queryObj.limit || queryObj.page) {
      delete queryObj.limit;
      delete queryObj.page;
      pushToFilteredPage(this.props, { ...queryObj });
    }

    overlap.style.setProperty("--backgroundColor", "transparent");
    this.setQueryStatus();

    // this.editTask()
  }

  componentWillUnmount() {
    overlap.style.setProperty("--backgroundColor", "#f5f5f5");
  }

  updateStatus = () => {
    this.setState({ upd_btn: true });
  };

  editOrder = (data) => {
    this.setState({ workOrderEdit: true, orderData: data });
  };

  addOrder = () => {
    this.setState({ openChangeOrder: true });
  };

  closeOrder = () => {
    this.setState({ openChangeOrder: false, orderId: "" });
  };

  editChangeOrder = (id) => {
    this.setState({ openChangeOrder: true, orderId: id });
  };

  successClose = () => {
    this.setState({ openChangeOrder: false, orderId: "" });
    this.getOrders();
  };

  getTaskList = async () => {
    let taskList = await ApiService.getAPI(
      `contractor/task?project_id=${project_id}`
    );
    this.setState({
      tasks: taskList?.data?.tasks?.slice(0, 5) || [],
    });
  };

  taskTimelineOpen = (obj, type, id) => {
    this.setState({
      isTimeLineOpen: true,
      type: obj[id],
      timeline: { id: id, type: type },
    });
  };

  getChangeOrderList = async () => {
    let response = await ApiService.getAPI(
      `contractor/changeOrder/${project_id}`
    );
    if (response.success) {
      this.setState({ changeOrdersList: response.data }, () => { });
    }
  };
  getWorkOrderList = async () => {
    let response = await ApiService.getAPI(
      `contractor/workOrder/${project_id}?page=1&limit=4`
    );
    if (response.success) {
      this.setState({ workOrdersList: response.data }, () => { });
    }
  };
  getProjectDetails = async () => {
    let [response, taskList] = await Promise.all([
      ApiService.getAPI(`contractor/project/${project_id}`),
      ApiService.getAPI(`contractor/task?project_id=${project_id}`),
    ]);
    if (!response.success) return this.setState({ isLoading: false });
    let data = response.data.project;

    this.setState(
      {
        project: data,
        final_project: data,
        projectId: data.project_id,
        tasks: taskList?.data?.tasks?.slice(0, 5) || [],
      },
      () => {
        this.setState({ isLoading: false });
      }
    );
  };
  setQueryStatus = () => {
    let queryStatus = this.props.location.search;
    let queryObj = returnSearchObject(queryStatus);
    if (project_id) this.getProjectDetails();
    // this.setState(
    //   { pageType: queryStatus ? queryObj.pageType : "overview" },
    //   () => {
    //     pushToFilteredPage(this.props, { pageType: this.state.pageType });
    //     if (project_id) this.getProjectDetails();
    //   }
    // );
    // queryStatus ?
    // if (queryStatus) {
    //   this.setState(
    //     {
    //         pageType:queryObj.pageType ?? ""
    //     },
    //     () => {
    //       pushToFilteredPage(this.props, this.state.pageType );
    //       if (project_id) this.getProjectDetails();
    //     }
    //   );
    // } else {
    //   this.setState(
    //     {
    //         pageType:'overview'
    //     },
    //     () => {
    //       pushToFilteredPage(this.props, this.state.pageType);
    //       if (project_id) this.getProjectDetails();
    //     }
    //   );
    // }
  };

  onClose = () => {
    this.setState(
      {
        open: false,
        openOrder: false,
        edit_project: false,
        workOrderEdit: false,
        tags: [],
        desc: "",
        name: "",
        start_date: "",
        actual_start: "",
        subContractor_name: "",
        subContractor_id: "",
        end_date: "",
      },
      () => this.getProjectDetails()
    );
  };

  closeModal = () => {
    this.setState({ openModal: false }, () => {
      this.getProjectDetails();
    });
  };

  handleDelete = async () => {
    let response = await ApiService.patchAPI(
      `contractor/task/delete/${this.state.task_id}`,
      {}
    );
    if (response.success) {
      showSuccess(this, response.message);
      this.setState({ delete_data: {} });
      this.closeModal();
      this.getProjectDetails();
    } else {
      showError(this, response.message);
    }
  };

  editTask = async (data) => {
    this.setState(
      {
        // tags: data.tags,
        task_id: data.task_id,
        project_id: data.project_id,
        // desc: data.desc,
        // subContractor_name: data.sub_contractor.name,
        // subContractor_id: data.sub_contractor.id,
      },
      () => {
        this.setState({ open: true });
      }
    );
  };
  getRequestsList = async () => {
    let { filterOption, statusSearch, inputSearch, from, to } = this.state;
    let queryParams = "";
    if (filterOption !== "" && inputSearch !== "")
      queryParams += filterQuery(filterOption, inputSearch);
    if (statusSearch !== "") {
      queryParams += filterQuery("status", statusSearch);
    }
    if (from !== "") queryParams += filterQuery("from", getIsoString(from));
    if (to !== "") queryParams += filterQuery("to", getIsoString(to));
    if (from > to) {
      showError(this, "Please provide valid From and To date");
    } else {
      let response = await ApiService.getAPI(
        `contractor/open_request${buildQueryString(this.state.query)}`
      );

      if (response.success) {
        let { open_request, total } = response.data;
        this.setState({
          requestList: open_request,
          total: total,
          isLoading: false,
        });
      }
    }
  };
  capitalizeFirstLetter = (str) => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  createProjectHandler = async () => {
    let { final_project } = this.state;
    if (
      !final_project.name ||
      !final_project.client.name ||
      !final_project.start_date ||
      !final_project.end_date ||
      !final_project.actual_start
    )
      return showError(this, "Please fill all the required fields");
    this.setState({ btnLoad: true });

    let response = await ApiService.patchAPI(
      `contractor/project/${project_id}`,
      final_project
    );

    if (response.success) {
      showSuccess(this, response.message);
      this.setState({ btnLoad: false, open: false, edit_project: false });
      this.getProjectDetails();
    } else {
      this.setState({ btnLoad: false });
      showError(this, response.message);
    }
  };
  createTaskHandler = async () => {
    let {
      name,
      subContractor_name,
      start_date,
      end_date,
      actual_start,
      subContractor_id,
      desc,
      edit,
      task_id,
      tags,
    } = this.state;

    if (
      !name ||
      !subContractor_name ||
      !start_date ||
      !end_date ||
      !actual_start
    )
      return showError(this, "Please fill all the required fields");
    this.setState({ btnLoad: true });
    let data = {
      name: name,
      sub_contractor: {
        id: subContractor_id,
        name: subContractor_name,
      },
      start_date: start_date,
      end_date: end_date,
      actual_start: actual_start,
      desc: desc,
      tags: tags,
    };
    let response = edit
      ? await ApiService.patchAPI(`contractor/task/${task_id}`, data)
      : await ApiService.postAPI(`contractor/task/${project_id}`, data);
    if (response.success) {
      showSuccess(this, response.message);
      this.setState({
        btnLoad: false,
        open: false,
        name: "",
        start_date: "",
        end_date: "",
        actual_start: "",
        desc: "",
        tags: [],
        subContractor_name: "",
        subContractor_id: "",
      });
      this.getProjectDetails();
    } else {
      this.setState({ btnLoad: false });
      showError(this, response.message);
    }
  };

  toggleFilterMenu = () => {
    this.setState({ filterToggle: !this.state.filterToggle });
  };
  onChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  updateStateValues = (key, value) => {
    this.setState(
      {
        [key]: value,
      },
      function () {
        if (value === null) {
          this.setState({
            [key]: "",
          });
        }
      }
    );
  };

  update = (e) => {
    const textarea = this.textareaRef.current;
    textarea.style.height = "28px";
    textarea.style.height = `${textarea.scrollHeight}px`;
    this.setState({
      inputMessage: e.target.value,
    });
  };

  handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey && !e.ctrlKey) {
      e.preventDefault();
    } else if (e.key === "Enter" && (e.shiftKey || e.ctrlKey) && !e.repeat) {
      this.insertLineBreak();
      e.preventDefault();
    }
    e.key === "Enter" &&
      !e.repeat &&
      !e.shiftKey &&
      !e.ctrlKey &&
      this.sendMessage();
    this.date = [];
  };

  sendMessage = () => {
    const textarea = this.textareaRef.current;
    textarea.style.height = "28px";
  };
  insertLineBreak() {
    const textarea = this.textareaRef.current;
    const { selectionStart, selectionEnd, value } = textarea;
    const newValue =
      value.substring(0, selectionStart) + "\n" + value.substring(selectionEnd);
    textarea.value = newValue;
    textarea.setSelectionRange(selectionStart + 1, selectionStart + 1);
    textarea.style.height = `${textarea.scrollHeight}px`;
  }

  handleOnToChange = (e) => {
    this.updateStateValues("to", e ? new Date(e) : '');
  };
  handleOnFromChange = (e) => {
    this.updateStateValues("from", e ? new Date(e) : '');
  };
  handleIncrementPage = () => {
    if (this.state.requestList.length >= 10) {
      this.setState({ page: this.state.page + 1 }, () =>
        this.handleFilterStatus()
      );
    }
  };
  handleDecrementPage = () => {
    if (this.state.page > 1) {
      this.setState({ page: this.state.page - 1 }, () =>
        this.handleFilterStatus()
      );
    }
  };
  redirectDetailPage = (id) => {
    this.props.history.push(`requests/detail/${id}`);
  };

  handleFilterStatus = () => {
    let staticQuery = returnSearchObject(this.props.location.search);
    // staticQuery.pageType = this.state.pageType ?? "";
    this.setState({ query: staticQuery }, () => {
      pushToFilteredPage(this.props, { ...this.state.query });
      this.getRequestsList();
    });
  };

  handleSearch = () => {
    this.setState({ page: 1 }, () => {
      this.handleFilterStatus();
    });
  };

  handleReset = () => {
    let self = this;
    self.setState(
      {
        from: "",
        to: "",
        statusSearch: "",
        filterOption: "",
        inputSearch: "",
        page: 1,
        limit: 10,
        query: {
          page: 1,
          limit: 10,
        },
      },
      () => {
        pushToFilteredPage(this.props, { ...this.state.query });
        this.getRequestsList();
      }
    );
  };

  addSubordinates = () => {
    let { first_table_slider } = this.state;
    first_table_slider === "changeOrders"
      ? this.setState({ openChangeOrder: true })
      : first_table_slider === "task"
        ? this.setState({ open: true })
        : this.setState({ workOrderEdit: true });
  };
  setStatus = (status) => {
    // this.setState({ pageType: status }, () => {
    //   this.handleFilterStatus();
    // });
  };

  redirectToAddRequest = async () => {
    this.setState({
      open: true,
      task_id: "",
      project_id: project_id,
    });
  };

  editProject = async (data) => {
    this.setState({
      edit_project: true,
    });
  };

  imageHandler = async (e, type) => {
    let dynamicImageValue = this.state[type];
    let file = e.target.files[0];

    if (e.target.files.length > 0) {
      this.setState({ imageLoading: true });
    }

    if (
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/jpeg" ||
      file.type === "application/pdf"
    ) {
      if (file.size < 1000000) {
        let data = await uploadCloudinary(file, "host");
        if (data.url) {
          let imgArr = [...dynamicImageValue];
          let imageObj = {
            format: data.format,
            public_id: data.public_id,
            version: `v${data.version}`,
            type: "image",
          };
          imgArr.push(imageObj);
          this.setState({
            [type]: imgArr,
            imageLoading: false,
          });
        } else {
          showError(this, "Could not upload image");
          this.setState({ imageLoading: false });
        }
      } else {
        showError(this, "File size should not exceeds 1 MB");
        this.setState({ imageLoading: false });
      }
    } else {
      showError(this, "Invalid Format");
      this.setState({ imageLoading: false });
    }
  };

  deleteImage = (type) => {
    this.setState({ [type]: [] });
  };

  cancelRequest = async (request) => {
    this.setState({ cancelModal: true, requestId: request.open_request_id });
  };
  handleNewOneChange = (newTags) => {
    this.setState({ tags: newTags });
  };
  handleProjectChange = (tags) => {
    this.setState((prevState) => ({
      final_project: {
        ...prevState.final_project,

        tags: tags,
      },
    }));
  };

  redirectToListWithQuery = () => {
    const { first_table_slider } = this.state;
    if (first_table_slider === "task") {
      this.props.history.push(
        `/admin/tasks_lists?project_id=${project_id}&assignType=inside`
      );
    } else if (first_table_slider === "changeOrders") {
      this.props.history.push(
        `/admin/tasks_lists?project_id=${project_id}&assignType=inside`
      );
    } else {
    }
  };

  closeUpdateModal = () => {
    this.setState({ upd_btn: false }, () => {
      this.getProjectDetails();
    });
  };

  closeRejectNotes = (boolean, notes) => {
    this.setState({ notesOpen: false });
    boolean && this.approveOrReject(this.state.type, this.state.task_id, notes);
  };

  approveOrReject = async (status, id, notes) => {
    let data = {
      notes: notes,
    };
    let response = await ApiService.patchAPI(
      `contractor/task/${status}/${id}`,
      data
    );
    if (response.success) {
      showSuccess(this, response.message);
      this.getProjectDetails();
    } else {
      showError(this, response.message);
    }
  };

  slidingChange = (type, value) => {
    this.setState({ [type]: value });
  };

  onReasonOpen = (task, type) => {
    this.setState({
      notesOpen: true,
      task_id: task.task_id,
      type: type,
    });
  };

  closeTimeline = () => {
    this.setState({ isTimeLineOpen: false, project_id: "" });
  };

  selectContent = (index) => {
    let obj = this.state.content;
    obj.forEach((each) => {
      each.selected = false;
    });
    obj[index].selected = true;
    this.setState({ content: obj, selectedContent: obj[index].name });
  };

  addDocumentsOrPhotos = () => {
    this.setState({ openAddDocumentsOrPhotos: true });
  };

  viewAll = () => {
    this.props.history.push(
      `/admin/${this.state.selectedContent.toLowerCase()}`
    );
  };

  viewPayments = () => {
    this.props.history.push("/admin/payments");
  };

  triggerChangeOrder = () => {
    this.setState({ first_table_slider: "changeOrders" }, async () => {
      await this.getChangeOrderList();
    });
  };

  triggerWorkOrder = () => {
    this.setState({ first_table_slider: "workOrders" }, async () => {
      await this.getWorkOrderList();
    });
  };

  updateWorkOrder = (data) => { };
  render() {
    const NodataFound = {
      loop: true,
      autoplay: true,
      animationData: NoData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    const EmptyMessage = (
      <>
        <div className="no_data_dashboard" style={{ height: "100%" }}>
          <Lottie options={NodataFound} height={100} width={100} />
          <div className="mt_20">
            <p>No {this.state.selectedContent} Found</p>
          </div>
        </div>
      </>
    );

    let {
      requestList,
      isLoading,
      page,
      workOrderEdit,
      imageLoading,
      notesOpen,
      task_image,
      project_image,
      toast,
      openChangeOrder,
      orderId,
      response,
      toastType,
      selectedContent,
      open,
      project_id,
      clientsList,
      name,
      actual_start,
      end_date,
      content,
      workOrder_id,
      openAddDocumentsOrPhotos,
      final_project,
      payments,
      btnLoad,
      subContractor_name,
      upd_btn,
      subContractor_list,
      project,
      tags,
      desc,
      tasks,
      edit_project,
      edit,
      task_id,
      openModal,
      delete_data,
      comments,
      photos,
      documents,
      changeOrdersList,
      workOrdersList,
      orderData,
      docs,
      openProjectChat,
      statusSearch,
      pageType,
    } = this.state;
    return isLoading ? (
      <div className="spin-loading">
        <Spinner type="border" color="#077e8b" />
      </div>
    ) : (
      <>
        {this.state.openModal && (
          <DeleteModal
            modalState={openModal}
            type="task"
            id={this.state.task_id}
            name={delete_data.name}
            closeModal={this.closeModal}
            onSubmit={this.handleDelete}
            props={this}
          />
        )}
        <div style={{ padding: "20px", backgroundColor: "#fafafa" }}>
          {toast && <ToastList message={response} type={toastType} />}
          <div className="maintainenceDetailHeader">
            <div className="manage-con">
              <Breadcrumb>
                <BreadcrumbItem
                  onClick={() => this.props.history.push('/admin/projects')}
                  className="bread-cursor"
                >
                  Projects
                </BreadcrumbItem>
                <BreadcrumbItem active style={{ color: "#72569c" }}>
                  Details
                </BreadcrumbItem>
              </Breadcrumb>
            </div>
            {/* <div className="manage-con request_button_div">
              <CustomDropDown
                width={"136px"}
                setStatus={this.setStatus}
                status={pageType}
                statusList={["overview", "tasks", "payments", "change_orders"]}
              />
            </div> */}
          </div>
          <ProjectDetail_overview
            props={this}
            project_details={this.state.project}
            updateProjectDetail={this.getProjectDetails}
          />
          {/* <Row
            className="align-items-center header-div"
            style={{ padding: "0 1rem 0 1rem" }}
          >
            <Col
              xs="4"
              className="manage-con flexy-content flexEnd"
              style={{ gap: "5%" }}
            >
              <div className="status_select ml-10" style={{ width: "29%" }}>
                <UncontrolledDropdown className="status_select_div">
                  <DropdownToggle className="pr-0 drop_down_container">
                    <Media className="capitalize">
                      Overview
                      <i className="fas fa-angle-down"></i>
                    </Media>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-arrow" bottom>
                    {" "}
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() =>
                        this.props.history.push("/admin/project_overview")
                      }
                    >
                      Overview
                    </DropdownItem>{" "}
                    <DropdownItem
                      className="dropdown_item"
                      onClick={this.redirectToListWithQuery}
                    >
                      Tasks
                    </DropdownItem>{" "}
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() => this.props.history.push("/admin/payments")}
                    >
                      Payments
                    </DropdownItem>
               
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() =>
                        this.props.history.push("/admin/changeOrders")
                      }
                    >
                      Change Orders
                    </DropdownItem>{" "}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </Col>
          </Row> */}
          {/* {pageType === "overview" && (
            <>
              <ProjectDetail_overview
                props={this}
                project_details={this.state.project}
                updateProjectDetail={this.getProjectDetails}
              />
            </>
          )}
          {pageType === "tasks" && <ProjectDetailTaskList props={this} />} */}
        </div>
        {/* <div style={{ boxShadow: "0px 2px 12px 0px rgba(51, 51, 51, 0.06)", width: "40%", borderRadius: "6px", marginBottom: "1rem" }}>
                            <div style={{ display: "flex", justifyContent: "space-between", borderBottom: "1px solid #F0F0F0", paddingRight: "2rem" }}>
                                <div style={{ display: "flex", alignItems: "center", gap: "3rem", marginLeft: "0.75rem" }}>
                                    <div style={{ cursor: "pointer", fontWeight: "500", fontSize: "15px", color: "#757575", borderBottom: this.state.third_table_slider === "doc" ? "2px solid #0B8080" : null, padding: "1rem 2rem 1.5rem 2rem" }} onClick={() => this.slidingChange("third_table_slider", "doc")}>Documents</div>
                                    <div style={{ cursor: "pointer", fontWeight: "500", fontSize: "15px", color: "#757575", borderBottom: this.state.third_table_slider === "photo" ? "2px solid #0B8080" : null, padding: "1rem 2rem 1.5rem 2rem" }} onClick={() => this.slidingChange("third_table_slider", "photo")}>Photos</div>

                                </div>
                                <div style={{ display: "flex", alignItems: "center", gap: "2rem" }}>
                                    <div
                                        style={{
                                            color: "#338180",
                                            fontSize: "14px",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => { this.setState({ openAddDocumentsOrPhotos: true }) }}
                                    >
                                        + Add
                                    </div>
                                    <div
                                        style={{
                                            color: "#338180",
                                            fontSize: "14px",
                                            cursor: "pointer",
                                        }}
                                        onClick={this.redirectToListWithQuery}
                                    >
                                        View All
                                    </div>
                                </div>
                            </div>
                            <div style={{ padding: " 0% 3%" }}>
                                {this.state.third_table_slider === "doc" ? (
                                    docs.map((doc) => (
                                        <div
                                            key={doc.id}
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                borderBottom: "1px solid #F0F0F0",
                                                width: "100%",
                                                padding: "1.5rem 1rem",
                                                justifyContent: "space-between"
                                            }}
                                        >
                                            <div style={{ display: "flex", gap: "1rem" }}>
                                                <img src={docIcon} alt="document" />
                                                <div>{doc.name}</div>
                                            </div>
                                            <img src={downloadIcon} alt="download" />
                                        </div>
                                    ))
                                ) : (
                                    photos.map((photo) => (
                                        <div
                                            key={photo.id}
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                borderBottom: "1px solid #F0F0F0",
                                                width: "100%",
                                                padding: "1.5rem 1rem",
                                                justifyContent: "space-between"
                                            }}
                                        >
                                            <div style={{ display: "flex", gap: "1rem" }}>
                                                <img src={imageIcon} alt="image" />
                                                <div>{photo.name}</div>
                                            </div>
                                            <img src={downloadIcon} alt="download" />
                                        </div>
                                    ))
                                )}
                            </div>

                        </div> */}
        {/* </div> */}
        <Card className={open ? "exportSideBar" : "toggle-sidebar"}>
          {open && (
            <AddProject
              props={this}
              taskId={task_id}
              projectId={project_id}
              close={() => this.onClose()}
              type="task"
            />
          )}
        </Card>

        <Card className={edit_project ? "exportSideBar" : "toggle-sidebar"}>
          {edit_project && (
            <AddProject
              type="project"
              props={this}
              projectId={project_id}
              close={() => this.onClose()}
            />
          )}
        </Card>

        <Card className={workOrderEdit ? "exportSideBar" : "toggle-sidebar"}>
          {workOrderEdit && (
            <AddWorkOrder
              type="workOrder"
              props={this}
              workOrder={orderData}
              projectId={project_id}
              updateWorkOrder={this.updateWorkOrder}
              close={this.onClose}
            />
          )}
        </Card>

        <Card className={upd_btn ? "exportSideBar" : "toggle-sidebar"}>
          {upd_btn && (
            <UpdateStatus
              id={project_id}
              closeUpdateModal={this.closeUpdateModal}
              props={this}
            />
          )}
        </Card>

        <Card className={notesOpen ? "exportSideBar" : "toggle-sidebar"}>
          {notesOpen && (
            <RejectNotes
              props={this}
              closeNotesModal={this.closeRejectNotes}
              getTaskList={this.getTaskList}
            ></RejectNotes>
          )}
        </Card>

        <Card
          className={
            this.state.isTimeLineOpen ? "exportSideBar" : "toggle-sidebar"
          }
        >
          {this.state.isTimeLineOpen && (
            <TimeLine
              id={this.state.timeline.id}
              closeTimelineModal={this.closeTimeline}
              from={this.state.timeline.type}
            ></TimeLine>
          )}
        </Card>

        <Card
          className={
            openAddDocumentsOrPhotos ? "exportSideBar" : "toggle-sidebar"
          }
        >
          {openAddDocumentsOrPhotos && (
            <AddPhotosDocuments
              selectedPannel={selectedContent}
              photDocClose={() =>
                this.setState({ openAddDocumentsOrPhotos: false })
              }
            ></AddPhotosDocuments>
          )}
        </Card>

        <Card className={openChangeOrder ? "exportSideBar" : "toggle-sidebar"}>
          {openChangeOrder && (
            <AddChangeOrder
              props={this}
              projectId={project_id}
              orderId={orderId}
              editChangeOrder={this.editChangeOrder}
              successClose={this.successClose}
              close={() =>
                this.setState({ openChangeOrder: false, orderId: "" })
              }
            ></AddChangeOrder>
          )}
        </Card>
        <Card className={openProjectChat ? "exportSideBar" : "toggle-sidebar"}>
          {openProjectChat && (
            <ProjectChat
              props={this}
              projectId={project_id}
              orderId={orderId}
              editChangeOrder={this.editChangeOrder}
              successClose={this.successClose}
              close={() => this.setState({ openProjectChat: false })}
            ></ProjectChat>
          )}
        </Card>
      </>
    );
  }
}

export default newProjectDetail;
