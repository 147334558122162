import React, { Component } from "react";
import {
  Card,
  Table,
  Container,
  Row,
  Breadcrumb,
  Col,
  Button,
  Spinner,
  DropdownMenu,
  DropdownItem,
  Media,
  UncontrolledDropdown,
  DropdownToggle,
  InputGroup,
  Dropdown,
  Modal,
  Input,
} from "reactstrap";
import Env from "constants/Env";
import AddProject from "views/Project/addProject";
import ApiService from "constants/ApiService";
import "react-datepicker/dist/react-datepicker.css";
import projectTimeline from "../../../assets/img/brand/projectTimeline.svg";
import { getIsoString, filterQuery } from "constants/utils";
import ToastList from "components/Toast/ToastList";
import { showError } from "constants/utils";
import { returnSearchObject } from "constants/utils";
import { pushToFilteredPage } from "constants/utils";
import { buildQueryString, downloadDocuments } from "constants/utils";
import moment from "moment";
import returnSecondDegreeObjValue, { getAuthToken } from "constants/utils";

import showMore from "../../../assets/img/brand/showMore.svg";
// import project_icon from "../../../assets/img/brand/project_icon.svg";
import project_icon from "../../../assets/img/new Logo/project_icon.svg";

import { showSuccess } from "constants/utils";
import { uploadCloudinary } from "constants/utils";
// import DeleteModal from "./DeleteModal";
import AddLead from "../addLead";
import TimeLine from "components/Timeline/Timeline";
import { CustomListingContainer } from "components/Container/CustomListingContainer";
import { ListingHeaderLayout } from "components/Container/CustomListingContainer";
import { CustomDatePicker } from "components/FilterFields/Filterfields";
import { CustomDropDown } from "components/FilterFields/Filterfields";
import { Button_without_Border } from "components/Buttons/Button";
import { CustomSearch } from "components/FilterFields/Filterfields";
import { Custom_Button } from "components/Buttons/Button";
import DeleteModal from "views/Project/DeleteModal";
import ReactTooltip from "react-tooltip";
import LineEllipsis from "components/LineEllipsis/LineEllipsis";
import { showUtcDate } from "constants/utils";
import { roundOff } from "constants/utils";
import { CopyEstimateLink } from "constants/utils";
import ToolTip from "components/ToolTip/ToolTip";
import Search from "antd/es/input/Search";
import { NumberWithCurrency } from "constants/utils";

let overlap = document.querySelector(":root");

class Estimates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requestList: [],
      estimate_list: [],
      lead: "",
      total: 0,
      filterToggle: false,
      from: "",
      projectModuleOpen: false,
      to: "",
      page: 1,
      open: false,
      client_id: "",
      inputSearch: "",
      filterOption: "name",
      statusSearch: "",
      //   status: this.props.props.location?.search ? "pending" : "",
      toast: false,
      response: "",
      image: [],
      iamge_name: "",
      toastType: "",
      isLoading: true,
      clientsList: [],
      btnLoad: false,
      filters: ["client", "id", "subject"],
      query: {},
      name: "",
      client: {},
      start_date: "",
      actual_start: "",
      imageLoading: false,
      client_name: "",
      inputText: "",
      isTimeLineOpen: false,
      timeline_estimate_id: "",
      estimate_id: "",
      items: [],
      desc: "",
      tags: [],
      end_date: "",
      requestId: "",
      openModal: false,
      delete_data: {},
      cancelModal: false,
      project_id: "",
      all_projects: 0,
      active_projects: 0,
      completed_projects: 0,
      cancel_data: {},
      all_tasks: 0,
      cancelModal: false,
      input_value: false,
      timoutId: "",
      deleteModal: false,
      timeoutId: null,
    };
  }

  componentDidMount = async () => {
    this.setQueryStatus();
  };

  onSuccessAddProject = () => {
    this.setState({ projectModuleOpen: false }, () => {
      this.props.tabChange("projects", "all");
    });
  };

  componentWillUnmount() {}

  closeModal = () => {
    this.setState({ openModal: false, project_id: "" });
    this.getProjectList();
  };

  closeDeleteModal = (view) => {
    this.setState({ deleteModal: false, estimate_id: "" }, () => {
      view && this.getProjectList();
    });
  };

  handleDelete = async () => {
    let response = await ApiService.patchAPI(
      `contractor/project/delete/${this.state.delete_data.project_id}`,
      {}
    );
    if (response.success) {
      showSuccess(this, response.message);
      this.setState({ delete_data: {} });
      this.closeModal();
      this.getProjectList();
    } else {
      showError(this, response.message);
    }
  };

  initCheckClerFilters = () => {
    this.state.filters.includes(this.state.filterOption) ||
    this.state.statusSearch ||
    this.state.inputSearch ||
    this.state.from ||
    this.state.to ||
    this.state.page > 1
      ? this.setState({ input_value: true })
      : this.setState({ input_value: false });
  };

  returnCurrentFilterOption = (obj) => {
    return (
      this.state.filters.filter((ele) => Object.keys(obj).includes(ele))[0] ??
      ""
    );
  };

  setQueryStatus = () => {
    let queryStatus = this.props.location?.search;
    let queryObj = returnSearchObject(queryStatus);
    if (queryStatus) {
      this.setState(
        {
          from: queryObj.from ? new Date(queryObj.from) : "",
          to: queryObj.to ? new Date(queryObj.to) : "",
          filterOption: queryObj.name
            ? "name"
            : queryObj.estimate_id
            ? "estimate_id"
            : queryObj.subject
            ? "subject"
            : "name",
          // this.returnCurrentFilterOption(queryObj),
          inputSearch: queryObj[this.returnCurrentFilterOption(queryObj)] ?? "",
          statusSearch: queryObj.status ?? "",
          page: parseInt(queryObj.page) ?? "",
          query: returnSearchObject(queryStatus),
        },
        () => {
          this.initCheckClerFilters();
          pushToFilteredPage(this.props, { ...this.state.query });
          this.getProjectList();
        }
      );
    } else {
      this.setState(
        {
          query: {
            ...this.state.query,
            page: 1,
            limit: 10,
          },
        },
        () => {
          pushToFilteredPage(this.props, { ...this.state.query });
          this.getProjectList();
        }
      );
    }
  };

  redirectDetailPage = (id) => {
    this.props.history.push(`add_estimates?estimate_id=${id}`);
  };

  setPage = (page) => {
    this.props.history.push(page);
  };
  dateChangeHandler = (e, value) => {
    this.setState({ [value]: e });
  };

  editProject = async (data) => {
    this.setState(
      {
        project_id: data.project_id,
      },
      () => {
        this.setState({ open: true });
      }
    );
  };
  getRequestsList = async () => {
    let { filterOption, status, inputSearch, from, to } = this.state;
    let queryParams = "";
    if (filterOption !== "" && inputSearch !== "")
      queryParams += filterQuery(filterOption, inputSearch);
    if (status !== "") {
      queryParams += filterQuery("status", status);
    }
    if (from !== "") queryParams += filterQuery("from", getIsoString(from));
    if (to !== "") queryParams += filterQuery("to", getIsoString(to));
    if (from > to) {
      showError(this, "Please provide valid From and To date");
    } else {
      let response = await ApiService.getAPI(
        `contractor/open_request${buildQueryString(this.state.query)}`
      );

      if (response.success) {
        let { open_request, total } = response.data;
        this.setState({
          requestList: open_request,
          total: total,
          isLoading: false,
        });
      }
    }
  };

  getProjectList = async () => {
    let { filterOption, status, inputSearch, from, to, query } = this.state;
    const queryObj = { ...query };
    if (filterOption && inputSearch) queryObj[filterOption] = inputSearch;
    let response = await ApiService.getAPI(
      `contractor/estimate${buildQueryString(queryObj)}`
    );

    if (response.success) {
      this.setState({
        isLoading: false,
        estimate_list: response.data.estimates,
      });
    }
  };
  createProjectHandler = async () => {
    let {
      name,
      client_name,
      start_date,
      end_date,
      actual_start,
      client_id,
      desc,
      project_id,
      edit,
      tags,
    } = this.state;
    if (!name || !client_name || !start_date || !end_date || !actual_start)
      return showError(this, "Please fill all the required fields");
    this.setState({ btnLoad: true });
    let data = {
      name: name,
      client: {
        id: client_id,
        name: client_name,
      },
      start_date: start_date,
      end_date: end_date,
      actual_start: actual_start,
      desc: desc,
      tags: tags,
    };
    let response = edit
      ? await ApiService.patchAPI(`contractor/project/${project_id}`, data)
      : await ApiService.postAPI("contractor/project", data);
    if (response.success) {
      showSuccess(this, response.message);
      this.setState({
        btnLoad: false,
        open: false,
        name: "",
        start_date: "",
        end_date: "",
        actual_start: "",
        desc: "",
        tags: [],
        client_name: "",
        client_id: "",
      });
      this.getProjectList();
    } else {
      this.setState({ btnLoad: false });
      showError(this, response.message);
    }
  };

  handleTagsChange = (tags) => {
    this.setState({ tags: tags });
  };
  handleInputChange = (event) => {
    this.setState({ inputText: event.target.value });
  };

  handleEnterKey = (event) => {
    if (event.key === "Enter" && this.state.inputText.trim() !== "") {
      this.setState((prevState) => ({
        inputText: "",
        items: [...prevState.items, prevState.inputText],
      }));
    }
  };
  toggleFilterMenu = () => {
    this.setState({ filterToggle: !this.state.filterToggle });
  };

  checkForClearFilter = () => {
    this.setState({
      input_value:
        !this.state.statusSearch &&
        (this.state.filterOption === "name" || !this.state.filterOption) &&
        !this.state.inputSearch
          ? false
          : true,
    });
  };

  calculateTotalAmount = (items) => {
    let totalAmount = 0;
    items.forEach((item) => {
      let amount = item.quantity * item.rate;
      totalAmount += amount;
    });
    return totalAmount;
  };

  calculateTotal = (items, tax) => {
    let baseAmount = this.calculateTotalAmount(items);
    let total = !tax
      ? baseAmount
      : tax?.type === "percentage"
      ? (baseAmount += (baseAmount * tax?.value) / 100)
      : baseAmount + tax?.value;
    return total % 1 ? roundOff(total).toFixed(2) : total;
  };
  // onChangeHandler = (e, from) => {
  //   this.setState(
  //     { [from]: e === "select" ? "" : e, input_value: true },
  //     () => {
  //       this.checkForClearFilter();
  //     }
  //   );
  //   clearTimeout(this.state.timoutId);
  //   const timoutId = setTimeout(() => {
  //     this.state.filterOption && this.handleSearch();
  //   }, 1000);
  //   this.setState({ timoutId });
  // };
  onChangeHandler = (e) => {
    e.target.value === "" && this.handleReset();
    this.setState({ [e.target.name]: e.target.value, input_value: true });
    clearTimeout(this.state.timeoutId);
    const timeoutId = setTimeout(() => {
      this.state.filterOption && this.handleSearch();
    }, 1000);
    this.setState({ timeoutId });
  };
  onChangeSelectHandler = (status) => {
    this.setState({ filterOption: status, input_value: true }, () => {
      this.handleFilterStatus();
    });
  };
  updateStateValues = (key, value) => {
    const updatedValue = value == null ? "" : value;
    this.setState({ [key]: updatedValue, input_value: true }, () => {
      if (this.state.from && this.state.to) {
        this.handleFilterStatus();
      }
    });
  };
  handleOnToChange = (e) => {
    this.updateStateValues("to", e ? new Date(e) : "");
  };
  handleOnFromChange = (e) => {
    this.updateStateValues("from", e ? new Date(e) : "");
  };
  handleIncrementPage = () => {
    if (this.state.estimate_list.length >= 10) {
      this.setState(
        {
          page: this.state.page + 1,
          input_value: true,
        },
        () => {
          this.handleFilterStatus();
        }
      );
    }
  };
  handleDecrementPage = () => {
    if (this.state.page > 1) {
      this.setState(
        {
          page: this.state.page - 1,
          input_value: this.state.page - 1 === 1 ? false : true,
        },
        () => {
          this.handleFilterStatus();
        }
      );
    }
  };
  capitalizeFirstLetter = (str) => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  imageHandler = async (e, subStatus) => {
    let { image } = this.state;
    let file = e.target.files[0];

    if (e.target.files.length > 0) {
      this.setState({ imageLoading: true });
    }

    if (
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/jpeg" ||
      file.type === "application/pdf"
    ) {
      if (file.size < 1000000) {
        let data = await uploadCloudinary(file, "host");
        if (data.url) {
          let imgArr = [...image];
          let imageObj = {
            format: data.format,
            public_id: data.public_id,
            version: `v${data.version}`,
            type: "image",
          };
          imgArr.push(imageObj);
          this.setState({
            image: imgArr,
            imageLoading: false,
          });
        } else {
          showError(this, "Could not upload image");
          this.setState({ imageLoading: false });
        }
      } else {
        showError(this, "File size should not exceeds 1 MB");
        this.setState({ imageLoading: false });
      }
    } else {
      showError(this, "Invalid Format");
      this.setState({ imageLoading: false });
    }
  };

  deleteImage = () => {
    this.setState({ image: [], image_name: "" });
  };

  closeTimeline = () => {
    this.setState({ isTimeLineOpen: false });
  };

  addProject = (data) => {
    this.props.history.push({
      pathname: "/admin/projects/add_project",
      state: {
        type: "estimates",
        lead: data.lead,
        estimate_id: data.estimate_id,
        subject: data.subject,
      },
    });
  };

  handleFilterStatus = () => {
    let staticQuery = returnSearchObject(this.props.location.search);
    staticQuery.from = getIsoString(this.state.from) ?? "";
    staticQuery.to = getIsoString(this.state.to) ?? "";
    staticQuery.status = this.state.statusSearch ?? "";
    staticQuery.page = this.state.page ?? "";
    for (let key in staticQuery) {
      if (key === "name" || key === "estimate_id" || key === "subject") {
        delete staticQuery[key];
      }
    }
    staticQuery[this.state.filterOption] = this.state.inputSearch;
    this.setState({ query: staticQuery }, () => {
      if (this.state.filterOption !== "select") {
        pushToFilteredPage(this.props, { ...this.state.query });
        this.getProjectList();
      }
    });
  };

  duplicate = async (id) => {
    try {
      let response = await ApiService.postAPI(
        `contractor/estimate/duplicate/${id}`
      );
      if (response.success) {
        showSuccess(this, response.message);
        let { estimate_id } = response.data;
        setTimeout(() => {
          this.props.history.push({
            pathname: `/admin/estimates/AddEstimates`,
            search: `?estimate_id=${estimate_id}`,
          });
        }, 1000);
      } else throw new Error(response.message);
    } catch (err) {
      showError(this, err.message);
    }
  };

  handleSearch = () => {
    if (this.state.filterOption) {
      this.setState({ query: { ...this.state.query, page: 1 } }, () => {
        this.handleFilterStatus();
      });
    }
  };

  handleReset = () => {
    let self = this;
    self.setState(
      {
        from: "",
        to: "",
        statusSearch: "",
        filterOption: "name",
        inputSearch: "",
        page: 1,
        limit: 10,
        query: {
          page: 1,
          limit: 10,
        },
        input_value: false,
      },
      () => {
        pushToFilteredPage(this.props, { ...this.state.query });
        this.getProjectList();
      }
    );
  };

  setStatus = (status) => {
    this.setState(
      {
        statusSearch: status === "all" ? "" : status,
        page: 1,
      },
      () => {
        this.checkForClearFilter();
        this.handleFilterStatus();
      }
    );
  };

  cancelModal = (estimate) => {
    this.setState({
      cancelModal: true,
      cancel_data: { id: estimate.estimate_id, name: estimate.name },
    });
  };

  cancelEstimate = async () => {
    let { id } = this.state.cancel_data;
    let response = await ApiService.patchAPI(
      `contractor/estimate/cancelled/${id}`
    );
    if (response.success) {
      showSuccess(this, response.message);
      this.setState({ cancelModal: false });
      this.getProjectList();
    } else {
      showError(this, response.message);
    }
  };

  resendEstimate = async (estimate) => {
    let data = { lead: estimate.lead };
    let response = await ApiService.postAPI(
      `contractor/estimate/resend/${estimate.estimate_id}`,
      data
    );
    if (response.success) {
      showSuccess(this, response.message);
      this.getProjectList();
    } else {
      showError(this, response.message);
    }
  };
  redirectToAddRequest = async () => {
    let response = await ApiService.getAPI(`contractor/client?status=active`);

    if (response.success) {
      let { clients } = response.data;
      this.setState({
        clientsList: clients,
        edit: false,
        open: true,
      });
    }
  };

  onLinkClick = (id) => {
    showSuccess(this, "Link Copied");
    CopyEstimateLink(id, "estimate");
  };
  onClose = () => {
    this.setState({ open: false, project_id: "", projectModuleOpen: false });
    this.getProjectList();
  };
  Showtoast = (type, message) => {
    this.setState({
      toast: true,
      toastType: type,
      response: message,
    });
    this.clearToast();
  };
  clearToast = () => {
    setTimeout(
      () => this.setState({ toast: false, toastType: "", response: "" }),
      2000
    );
  };
  cancelRequest = async (request) => {
    this.setState({ cancelModal: true, requestId: request.open_request_id });
  };

  resendRequest = async (request) => {
    let response = await ApiService.patchAPI(
      `contractor/open_request/resend/${request.open_request_id}`
    );
    if (response.success) {
      showSuccess(this, response.message);
      this.getProjectList();
    } else {
      showError(this, response.message);
    }
  };

  handlePreview = (estimateSchema) => {
    this.props.history.push({
      pathname: "estimates/preview",
      search: `?estimate_id=${estimateSchema.estimate_id}`,
      state: {
        from: "list",
      },
    });
  };

  proposalEditPage = (estimate, addSplitUp = false) => {
    this.props.history.push({
      pathname: `/admin/estimates/AddEstimates`,
      search: `?estimate_id=${estimate.estimate_id}`,
      state: { splitUp: addSplitUp },
    });
  };

  resendInvoice = async (request) => {
    let response = await ApiService.patchAPI(
      `contractor/open_request/invoice/${request.open_request_id}`
    );
    if (response.success) {
      showSuccess(this, response.message);
      this.getProjectList();
    } else {
      showError(this, response.message);
    }
  };

  formatAmountWithCommas = (amount) => {
    return amount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  getDetailPage = (estimate) => {
    this.props.history.push({
      pathname: `estimates/AddEstimates`,
      search: `?estimate_id=${estimate.estimate_id}`,
    });
  };

  render() {
    let {
      requestList,
      isLoading,
      project_id,
      page,
      status,
      toast,
      response,
      toastType,
      clientsList,
      projectModuleOpen,
      estimate_list,
      lead,
      name,
      imageLoading,
      start_date,
      actual_start,
      desc,
      end_date,
      tags,
      client_name,
      estimate_id,
      btnLoad,
      inputSearch,
      from,
      to,
      filterOption,
      statusSearch,
      input_value,
      filters,
      image,
      openModal,
      delete_data,
      open,
      edit,
      all_projects,
      active_projects,
      completed_projects,
      all_tasks,
      timoutId,
      deleteModal,
    } = this.state;
    return isLoading ? (
      <div className="spin-loading">
        <Spinner type="border" color="#077e8b" />
      </div>
    ) : (
      <>
        <div>
          {toast && <ToastList message={response} type={toastType} />}
          <div style={{ padding: "20px", backgroundColor: "#fafafa" }}>
            <CustomListingContainer>
              <ListingHeaderLayout
                primary_buttonLabel={"Add Estimate"}
                isPrimary
                label={"Estimates"}
                click={() => {
                  this.props.history.push("estimates/AddEstimates");
                }}
              ></ListingHeaderLayout>

              <div style={{ margin: "20px" }}>
                <div
                  // className="d_flex_sb"
                  style={{
                    display: "grid",
                    gridTemplateColumns: "65% 35%",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    {/* <div style={{ display: "flex", width: "80%" , justifyContent:'space-between'}}> */}
                    {/* <CustomSearch
                      style={{ marginRight: "10px" }}
                      options={filters}
                      change={this.onChangeHandler}
                      filterOption={filterOption}
                      inputSearch={inputSearch}
                    /> */}
                    <div
                      className="select_with_input status_select_input ml-10"
                      style={{ marginRight: "10px", width: "370px" }}
                    >
                      <UncontrolledDropdown
                        className="status_select_div "
                        style={{ minWidth: "115px" }}
                      >
                        <DropdownToggle className="pr-0 drop_down_container">
                          <Media
                            className="capitalize"
                            style={{ fontSize: "0.875rem !important" }}
                          >
                            {filterOption === "name"
                              ? "Client"
                              : filterOption === "estimate_id"
                              ? "ID"
                              : filterOption === "subject" && "Subject"}
                            <i
                              className="fas fa-angle-down"
                              style={{ marginLeft: "20px" }}
                            ></i>
                          </Media>
                        </DropdownToggle>
                        <DropdownMenu
                          className="dropdown-menu-arrow"
                          bottom
                          style={{ minWidth: "130px" }}
                        >
                          <DropdownItem
                            className="dropdown_item"
                            onClick={() => this.onChangeSelectHandler("name")}
                          >
                            Client
                          </DropdownItem>
                          {/* </DropdownMenu>
                        <DropdownMenu className="dropdown-menu-arrow" bottom style={{ minWidth: "130px" }}> */}
                          <DropdownItem
                            className="dropdown_item"
                            onClick={() =>
                              this.onChangeSelectHandler("estimate_id")
                            }
                          >
                            ID
                          </DropdownItem>
                          {/* </DropdownMenu>
                        <DropdownMenu className="dropdown-menu-arrow" bottom style={{ minWidth: "130px" }}> */}
                          <DropdownItem
                            className="dropdown_item"
                            onClick={() =>
                              this.onChangeSelectHandler("subject")
                            }
                          >
                            Subject
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                      <Input
                        aria-label="Text input with dropdown button"
                        value={inputSearch}
                        name="inputSearch"
                        // placeholder="search by"
                        onChange={this.onChangeHandler}
                      />
                    </div>
                    {/* </div> */}
                    {/* <CustomDatePicker
                      from={from}
                      to={to}
                      fromChange={this.handleOnFromChange}
                      toChange={this.handleOnToChange}
                    ></CustomDatePicker> */}
                    <CustomDropDown
                      width={"126px"}
                      setStatus={this.setStatus}
                      status={statusSearch}
                      statusList={[
                        "all",
                        "accepted",
                        "sent",
                        "draft",
                        "cancelled",
                        "rejected",
                      ]}
                    ></CustomDropDown>
                  </div>
                  {input_value && (statusSearch || inputSearch) && (
                    <div className="right_filter_container">
                      <Button_without_Border
                        click={this.handleReset}
                        btnLabel={"clear_filter"}
                      ></Button_without_Border>
                    </div>
                  )}
                </div>
              </div>

              <Row>
                <div className="col new-table">
                  <Card className="shadow card_with_paginator">
                    <Table
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead className="thead-light">
                        <tr>
                          <th scope="col" className="">
                            ID
                          </th>
                          <th scope="col" className="w_20">
                            Subject
                          </th>
                          <th scope="col w_20">Client</th>
                          <th scope="col w_20">Sent On</th>
                          <th scope="col w_20">Cost</th>
                          <th scope="col w_20">Status</th>
                          <th scope="col w_8"></th>
                          <th scope="col" style={{ width: "0px" }}></th>
                        </tr>
                      </thead>
                      {estimate_list && estimate_list.length !== 0 ? (
                        <tbody>
                          {estimate_list.map((estimate, i) => (
                            <tr key={i}>
                              <td onClick={() => this.getDetailPage(estimate)}>
                                <div className="cursor-point">
                                  {estimate.estimate_id}
                                </div>
                              </td>
                              <td>
                                <div
                                  className="word-break"
                                  style={{ wordBreak: "break-all" }}
                                >
                                  {estimate.subject ? (
                                    <LineEllipsis content={estimate.subject} />
                                  ) : (
                                    "-"
                                  )}
                                </div>
                              </td>
                              <td className="capitalize">
                                <div className="">
                                  {estimate.lead?.name ? (
                                    <LineEllipsis
                                      content={estimate.lead.name}
                                    />
                                  ) : (
                                    "-"
                                  )}
                                </div>
                              </td>

                              <td>
                                <div className="">
                                  {showUtcDate(estimate.createdAt)}
                                </div>
                              </td>

                              <td>
                                <div className="">
                                  {/* {estimate?.items?.length > 0
                                    ? "$" +
                                      this.formatAmountWithCommas(
                                        this.calculateTotal(
                                          estimate.items,
                                          estimate.tax
                                        )
                                      )
                                    : "-"} */}
                                  {NumberWithCurrency(
                                    estimate.itemsTotal.totalAmount.toFixed(2)
                                  )}
                                </div>
                              </td>

                              <td>
                                <div
                                  className="project_list_status"
                                  style={{
                                    color: `${
                                      estimate.status === "completed" ||
                                      estimate.status === "accepted" ||
                                      estimate.status === "closed"
                                        ? "#07A84E"
                                        : estimate.status === "discontinued" ||
                                          estimate.status === "cancelled" ||
                                          estimate.status === "rejected"
                                        ? "#f75f18"
                                        : estimate.status === "due" ||
                                          estimate.status === "sent"
                                        ? "#0070FD"
                                        : "#FFCC3D"
                                    }`,
                                    fontWeight: "500",
                                  }}
                                >
                                  {" "}
                                  {this.capitalizeFirstLetter(
                                    estimate.status === "on_hold"
                                      ? "On Hold"
                                      : estimate.status
                                  )}
                                </div>
                              </td>

                              <td>
                                {estimate.status === "accepted" &&
                                !estimate.project_id ? (
                                  <div>
                                    <img
                                      src={project_icon}
                                      alt="project icon"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => this.addProject(estimate)}
                                      data-tip="Create Project"
                                      data-iscapture="true"
                                      data-for="mainIconInfo"
                                      data-place="top"
                                    />
                                    <ReactTooltip
                                      place="top"
                                      type="info"
                                      effect="solid"
                                      id="mainIconInfo"
                                      html={true}
                                    />
                                  </div>
                                ) : (
                                  <div></div>
                                )}
                              </td>

                              <td>
                                {/* {estimate.status !== "cancelled" && ( */}
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    borderTop: "none",
                                    gap: "20px",
                                  }}
                                >
                                  <div>
                                    <ToolTip label="Timeline">
                                      <img
                                        onClick={() =>
                                          this.setState({
                                            timeline_estimate_id:
                                              estimate.estimate_id,
                                            isTimeLineOpen: true,
                                          })
                                        }
                                        alt="timeline"
                                        style={{ cursor: "pointer" }}
                                        src={projectTimeline}
                                      ></img>
                                    </ToolTip>
                                  </div>

                                  <div
                                    style={{
                                      display: ["cancelled"].includes(
                                        estimate.status
                                      )
                                        ? "none"
                                        : "",
                                    }}
                                  >
                                    <UncontrolledDropdown
                                      nav
                                      className="flexy-content position_u"
                                    >
                                      <DropdownToggle
                                        className="pr-0 nav-notice"
                                        nav
                                      >
                                        <Media className="align-items-center">
                                          <img src={showMore} alt="show more" />
                                        </Media>
                                      </DropdownToggle>
                                      <DropdownMenu
                                        className="dropdown-menu-arrow min-width-drop"
                                        right
                                      >
                                        {estimate.status === "draft" && (
                                          <>
                                            <DropdownItem
                                              onClick={() =>
                                                this.proposalEditPage(estimate)
                                              }
                                            >
                                              <span>Edit</span>
                                            </DropdownItem>
                                            <DropdownItem
                                              onClick={() =>
                                                this.onLinkClick(
                                                  estimate.estimate_id
                                                )
                                              }
                                            >
                                              <span>Copy Link</span>
                                            </DropdownItem>
                                            <DropdownItem
                                              onClick={() =>
                                                this.handlePreview(estimate)
                                              }
                                            >
                                              <span>Preview</span>
                                            </DropdownItem>
                                            {/* <DropdownItem
                                            >
                                              <span>Download</span>
                                            </DropdownItem> */}
                                            <DropdownItem
                                              onClick={() => {
                                                this.duplicate(
                                                  estimate.estimate_id
                                                );
                                              }}
                                            >
                                              <span>Duplicate</span>
                                            </DropdownItem>
                                            <DropdownItem
                                              onClick={() =>
                                                this.setState({
                                                  deleteModal: true,
                                                  estimate_id:
                                                    estimate.estimate_id,
                                                })
                                              }
                                            >
                                              <span>Delete</span>
                                            </DropdownItem>
                                          </>
                                        )}
                                        {estimate.status === "sent" && (
                                          <>
                                            <DropdownItem
                                              onClick={() =>
                                                this.proposalEditPage(estimate)
                                              }
                                            >
                                              <span>Edit</span>
                                            </DropdownItem>
                                            <DropdownItem
                                              onClick={() =>
                                                this.onLinkClick(
                                                  estimate.estimate_id
                                                )
                                              }
                                            >
                                              <span>Copy Link</span>
                                            </DropdownItem>
                                            <DropdownItem
                                              onClick={() =>
                                                this.resendEstimate(estimate)
                                              }
                                            >
                                              <span>Resend Email</span>
                                            </DropdownItem>
                                            <DropdownItem
                                              onClick={() =>
                                                this.handlePreview(estimate)
                                              }
                                            >
                                              <span>Preview</span>
                                            </DropdownItem>
                                            <DropdownItem
                                              onClick={() => {
                                                this.duplicate(
                                                  estimate.estimate_id
                                                );
                                              }}
                                            >
                                              <span>Duplicate</span>
                                            </DropdownItem>
                                            <DropdownItem
                                              onClick={() =>
                                                downloadDocuments(
                                                  estimate.estimate_id,
                                                  "estimate"
                                                )
                                              }
                                            >
                                              <span>Download</span>
                                            </DropdownItem>
                                          </>
                                        )}
                                        {estimate.status === "accepted" && (
                                          <>
                                            <DropdownItem
                                              onClick={() =>
                                                this.handlePreview(estimate)
                                              }
                                            >
                                              <span>Preview</span>
                                            </DropdownItem>
                                            <DropdownItem
                                              onClick={() => {
                                                this.duplicate(
                                                  estimate.estimate_id
                                                );
                                              }}
                                            >
                                              <span>Duplicate</span>
                                            </DropdownItem>
                                            <DropdownItem
                                              onClick={() =>
                                                downloadDocuments(
                                                  estimate.estimate_id,
                                                  "estimate"
                                                )
                                              }
                                            >
                                              <span>Download</span>
                                            </DropdownItem>
                                            {(
                                              +estimate.itemsTotal.totalAmount -
                                              estimate.totalSplitUp
                                            ).toFixed(2) > 0 && (
                                              <DropdownItem
                                                onClick={() =>
                                                  this.proposalEditPage(
                                                    estimate,
                                                    true
                                                  )
                                                }
                                              >
                                                <span>Add Split Up</span>
                                              </DropdownItem>
                                            )}
                                          </>
                                        )}
                                        {estimate.status === "rejected" && (
                                          <>
                                            <DropdownItem
                                              onClick={() => {
                                                this.duplicate(
                                                  estimate.estimate_id
                                                );
                                              }}
                                            >
                                              <span>Duplicate</span>
                                            </DropdownItem>
                                            <DropdownItem
                                              onClick={() =>
                                                downloadDocuments(
                                                  estimate.estimate_id,
                                                  "estimate"
                                                )
                                              }
                                            >
                                              <span>Download</span>
                                            </DropdownItem>
                                          </>
                                        )}
                                        {estimate.status === "cancelled" && (
                                          <>
                                            {/* <DropdownItem>
                                              <span>Duplicate</span>
                                            </DropdownItem> */}
                                          </>
                                        )}
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                  </div>
                                </div>
                                {/* )} */}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      ) : (
                        <tbody>
                          <tr>
                            <td className="no-data">No Estimates Available</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        </tbody>
                      )}
                    </Table>
                  </Card>
                </div>
              </Row>

              <Row className="align-items-center header-div jus-space new-margin-page paginator_box">
                <div className="paginate-flex-box ">
                  <div
                    className="paginate-box"
                    onClick={() => this.handleDecrementPage()}
                  >
                    <p
                      className={
                        page !== 1 ? "page_available" : "page_unavailable"
                      }
                    >
                      Previous
                    </p>
                  </div>
                  <div
                    className="paginate-box"
                    onClick={() => this.handleIncrementPage()}
                  >
                    <p
                      className={
                        estimate_list.length < 10
                          ? "page_unavailable"
                          : "page_available"
                      }
                    >
                      Next
                    </p>
                  </div>
                </div>
              </Row>

              <Card
                className={this.state.open ? "exportSideBar" : "toggle-sidebar"}
              >
                {this.state.open && (
                  <AddLead
                    type="lead"
                    props={this}
                    projectId={this.state.project_id}
                    close={this.onClose}
                    toast={this.Showtoast}
                  ></AddLead>
                )}
              </Card>

              <Card
                className={
                  this.state.isTimeLineOpen ? "exportSideBar" : "toggle-sidebar"
                }
              >
                {this.state.isTimeLineOpen && (
                  <TimeLine
                    id={this.state.timeline_estimate_id}
                    closeTimelineModal={this.closeTimeline}
                    from={"estimate"}
                  ></TimeLine>
                )}
              </Card>

              <Modal
                isOpen={this.state.cancelModal}
                centered="true"
                style={{ minWidth: "25rem", padding: "2rem" }}
              >
                <div style={{ padding: "24px" }}>
                  <div className="conCanMod">Cancel</div>
                  <div style={{ textAlign: "center", wordBreak: "break-word" }}>
                    Are you sure want to cancel {this.state.cancel_data.name} ?
                  </div>
                  <div
                    className="flexEnd"
                    style={{
                      marginTop: "16px",
                      justifyContent: "center",
                      gap: "5%",
                    }}
                  >
                    <div
                      className="point f14 button-secondary"
                      style={{ fontWeight: "500" }}
                      disabled={this.state.isApiCallPending}
                    >
                      {btnLoad ? (
                        <div className="spin-loading-save w100 h100">
                          <Spinner
                            style={{
                              height: "20px",
                              width: "20px",
                            }}
                            type="border"
                            color="#0A8080"
                          />
                        </div>
                      ) : (
                        <div onClick={this.cancelEstimate}>Yes</div>
                      )}
                    </div>

                    <Button
                      color="primary"
                      className="button-wrapping "
                      size="sm"
                      onClick={() => this.setState({ cancelModal: false })}
                    >
                      No
                    </Button>
                  </div>
                </div>
              </Modal>

              {deleteModal && (
                <DeleteModal
                  modalState={deleteModal}
                  name={estimate_id}
                  type="estimate"
                  props={this}
                  closeModal={this.closeDeleteModal}
                  id={estimate_id}
                />
              )}

              <Card
                className={
                  projectModuleOpen ? "exportSideBar" : "toggle-sidebar"
                }
              >
                {projectModuleOpen && Object.keys(lead).length && (
                  <AddProject
                    type="estimate"
                    props={this}
                    estimateId={estimate_id}
                    lead={lead}
                    close={() => this.onSuccessAddProject()}
                    cancel={() => {
                      this.setState({ projectModuleOpen: false });
                    }}
                  />
                )}
              </Card>
            </CustomListingContainer>
          </div>
        </div>
      </>
    );
  }
}
export default Estimates;
