import React, { Component } from "react";
import { Table, Spinner, Modal } from "reactstrap";
import ApiService from "constants/ApiService";
import "react-datepicker/dist/react-datepicker.css";
import ToastList from "components/Toast/ToastList";
import { returnSearchObject, showSuccess, showError } from "constants/utils";
import { CustomListingContainer } from "components/Container/CustomListingContainer";
import { ListingHeaderLayout } from "components/Container/CustomListingContainer";
import { ListingBodyLayout } from "components/Container/CustomListingContainer";
import { Button_without_Border } from "components/Buttons/Button";
import { roundOff } from "constants/utils";
import { withRouter } from "react-router-dom";
import { getCloudinaryImage } from "constants/utils";
import { showUtcDate } from "constants/utils";
import CloseIcon from "../../../assets/img/icons/closeIcon.svg";
import { remove_underscore_capitalize } from "constants/utils";
import { getUSFormat, appendCurrency } from "constants/utils";
import { NumberWithCurrency } from "constants/utils";

let overlap = document.querySelector(":root");

class PreviewProposal extends Component {
  constructor(props) {
    super(props);
    this.termsRef = React.createRef();
    this.subjectRef = React.createRef();
    this.state = {
      proposalSchema: {
        name: null,
        lead_id: null,
        email: null,
        phone: null,
        address: null,
        subject: null,
        items: [{ item_desc: "", item_name: "", rate: 0, quantity: 0 }],
        percentageSplitUp: 1,
        percentageValue: 0,
        dollarValue: 0,
        totalAmount: 0,
        splitups: [],
      },
      lead: {},
      remainingValue: 0,
      total: 0,
      emailModal: false,
      open: false,
      toast: false,
      response: "",
      contractor_details: JSON.parse(localStorage.getItem("contractor_details"))
        .contractor,
      toastType: "",
      isLoading: true,
      isOpenToggle2: true,
      isOpenToggle3: true,
      isOpenToggle: true,
      btnLoad: false,
      openPreview: false,
      addAddressBar: false,
      // estimate_lead_id: this.props?.location?.search?.split("=")[1] || "",
      estimate_lead_id: {},
      isOpenLead: false,
      lead_id: "",
      lead_List: [],
      openModal: false,
      cancelModal: false,
      address: "",
      estimate_id: "",
      items: [],
      itemList: [
        { item_name: "new_one", item_desc: "testing", quantity: 2, rate: 3 },
      ],
      listsLoad: false,
      listsBoxOpen: false,
      confirmation: false,
      apiData: {},
      subject: "",
      ConfirmationBtnLoad: false,
      content: "",
      tax: {},
      splitups: [],
      terms: "",
      showPreview: false,
      showDownloadIcon: false,
      showCopyLinkIcon: false,
      status: "",
      from: "",
      isWorkOrder: null,
      descriptionModal: false,
      description: null,
      descIndex: {
        index: "",
        type: "",
        value: "",
      },
      head: [],
    };
    this.styles = {
      item_name: {
        width: "200px",
        paddingLeft: "25px",
        backgroundColor: "#FAFAFA",
      },
      item_desc: {
        padding: "15px 22px",
        width: "400px",
        backgroundColor: "#FAFAFA",
      },
      quantity: {
        padding: "15px 10px",
        width: "120px",
        textAlign: "center",
        backgroundColor: "#FAFAFA",
      },
      labor: {
        padding: "25px",
        width: "120px",
        textAlign: "end",
        backgroundColor: "#FAFAFA",
      },
      material: {
        padding: "25px",
        width: "120px",
        textAlign: "end",
        backgroundColor: "#FAFAFA",
      },
      markup: {
        padding: "25px",
        width: "120px",
        textAlign: "end",
        backgroundColor: "#FAFAFA",
      },
      cost: {
        padding: "25px",
        width: "120px",
        textAlign: "end",
        backgroundColor: "#FAFAFA",
      },
      amount: {
        padding: "25px",
        width: "120px",
        textAlign: "right",
        paddingRight: "1.6rem",
        backgroundColor: "#F7F8FA",
      },
    };
  }

  componentDidMount = async () => {
    let queryStatus = this.props.location.search;
    let queryObj = returnSearchObject(queryStatus);
    const isEstimate = Object.keys(queryObj).includes("estimate_id");
    const id = queryObj[isEstimate ? "estimate_id" : "workOrder_id"];
    this.setState(
      {
        isWorkOrder: !isEstimate,
        estimate_id: id,
        projectId: this?.props?.location?.state?.projectId,
      },
      () => {
        overlap.style.setProperty("--width", "600px");
        this.getContractorDetails();
        if (this.props.location?.state?.from === "list") {
          this.setState(
            {
              from: this.props.location.state.from,
              isWorkOrder: this?.props?.location?.state?.isWorkOrder,
            },
            () => {
              const { isWorkOrder } = this.state;
              let id = isWorkOrder
                ? returnSearchObject(this.props.location.search).workOrder_id
                : returnSearchObject(this.props.location.search).estimate_id;
              // isEstimate ? this.getEstimateDetail(id) : this.getWorkOrderDetails(id)

              isWorkOrder
                ? this.getWorkOrderDetails(id)
                : this.getEstimateDetail(id);
              this.setTextAreaHeight();
            }
          );
        } else {
          // if (!this.props?.location?.state) return;
          isEstimate
            ? this.getEstimateDetail(id)
            : this.getWorkOrderDetails(id);
          // const {
          //   lead,
          //   items,
          //   tax,
          //   splitups,
          //   terms,
          //   subject,
          //   due_date,
          //   estimate_id,
          //   showPreview,
          //   showDownloadIcon,
          //   showCopyLinkIcon,
          //   status,
          //   from,
          //   isWorkOrder,
          //   projectId,
          // } = this.props?.location?.state ?? {};
          // const newState = {
          //   lead: lead,
          //   items: items?.length > 0 ? items : [],
          //   tax: tax,
          //   splitups: splitups?.length > 0 ? splitups : [],
          //   terms: terms,
          //   subject: subject,
          //   due_date: due_date,
          //   estimate_id: estimate_id,
          //   isWorkOrder: isWorkOrder,
          //   projectId: projectId,
          // };

          // this.setState(
          //   {
          //     estimate_id: estimate_id,
          //     showPreview: showPreview,
          //     showDownloadIcon: showDownloadIcon,
          //     showCopyLinkIcon: showCopyLinkIcon,
          //     status: status,
          //     from: from,
          //   },
          //   () => {
          //     this.setTextAreaHeight();
          //   }
          // );
          // this.setState(newState, () => {});
        }
      }
    );
  };
  getEstimateDetail = async (id) => {
    let response = await ApiService.getAPI(`contractor/estimate/${id}`);
    this.setState({ isLoading: false });
    if (response.success) {
      const { estimate } = response.data;
      const { splitup } = response.data;
      this.setState(
        {
          lead: estimate.lead,
          items: estimate.items,
          tax: estimate.tax,
          splitups: splitup,
          subject: estimate.subject,
          due_date: estimate.due_date,
          terms: estimate.terms,
          itemsVisibility: estimate.itemsVisibility,
          resultValues: estimate.itemsTotal,
        },
        () => {
          const items = this.state.itemsVisibility;
          const head = Object.keys(items).filter((obj) => {
            return items[obj].is_enabled;
          });
          this.setState({ head: head });
          this.setState({ headLength: head.length });
        }
      );
    }
  };

  getWorkOrderDetails = async (id) => {
    let response = await ApiService.getAPI(`contractor/workOrder/${id}`);
    if (response.success) {
      const { workOrder } = response.data;
      const { splitup } = response.data;
      this.setState(
        {
          lead: workOrder.sub_contractor,
          items: workOrder.items,
          tax: workOrder.tax,
          splitups: splitup,
          subject: workOrder.subject,
          due_date: workOrder.due_date,
          terms: workOrder.terms,
          itemsVisibility: workOrder.itemsVisibility,
          resultValues: workOrder.itemsTotal,
        },
        () => {
          this.setState({ isLoading: false });
          const items = this.state.itemsVisibility;
          const head = Object.keys(items).filter((obj) => {
            return items[obj].is_enabled;
          });
          this.setState({ head: head });
          this.setState({ headLength: head.length });
          this.setState({ isLoading: false });
        }
      );
    }
  };

  setTextAreaHeight = () => {
    this.state.items.map((item) => {
      const itemRef = item.itemRef;
      const itemDescRef = item.itemDescRef;
      if (
        itemDescRef?.current &&
        itemRef?.current &&
        // this.subjectRef?.current &&
        this.termsRef?.current
      ) {
        itemDescRef.current.style.height = "28px";
        itemDescRef.current.style.height = `${itemDescRef.current.scrollHeight}px`;
        itemRef.current.style.height = "25px";
        itemRef.current.style.height = `${itemRef.current.scrollHeight}px`;
        // this.subjectRef.current.style.height = "28px"; // Reset height
        // this.subjectRef.current.style.height = `${this.subjectRef.current.scrollHeight}px`;
        this.termsRef.current.style.height = "28px";
        this.termsRef.current.style.height = `${this.termsRef.current.scrollHeight}px`;
      }
    });
  };

  getContractorDetails = () => {
    const cont_details = JSON.parse(localStorage.getItem("contractor_details"));
    this.setState({
      contractor_name: cont_details.contractor.name,
      contractor_email: cont_details.contractor?.email || "",
      contractor_phone: cont_details.contractor?.phone?.national_number,
      contractor_address: cont_details.contractor?.address?.line_1,
      contractor_state: cont_details.contractor?.address?.state?.state,
      contractor_city: cont_details.contractor?.address?.city?.city,
      contractor_zip: cont_details.contractor?.address?.zip,
      contractor_image: cont_details.contractor?.image,
    });
  };

  taxCalculater = () => {
    let { tax, items } = this.state;
    let taxValue = tax.value || 0;
    if (items.length > 0 && tax && tax.type === "percentage" && tax.value) {
      let itemsCost = this.calculateTotalAmount(this.state.items);
      taxValue = itemsCost * (tax.value / 100);
    }
    return taxValue;
  };

  calculateTotalAmount = (items) => {
    let totalAmount = 0;
    items.forEach((item) => {
      let amount = item.quantity * item.material;
      let labor = (amount += item.labor);
      totalAmount += labor;
    });
    return roundOff(totalAmount);
  };

  componentWillUnmount() {
    overlap.style.setProperty("--width", "450px");
  }

  calculateTotal = () => {
    const { proposalSchema } = this.state;
    let baseAmount = this.calculateTotalAmount(proposalSchema.items);
    let percentageValue =
      this.calculateTotalAmount(proposalSchema.items) *
      (proposalSchema.percentageValue / 100);
    let total = baseAmount;
    if (!proposalSchema.percentageSplitUp) {
      total = +baseAmount + +proposalSchema.dollarValue;
    } else {
      total = +baseAmount + +percentageValue;
    }
    return total < 0 ? 0 : total;
  };

  capitalizeFirstLetter = (str) => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  getPath = () => {
    const { isWorkOrder } = this.state;
    let path;
    if (isWorkOrder) {
      this.state.from === "list"
        ? (path = `/admin/projects/detail/${this.state.projectId}`)
        : (path = `/admin/projects/detail/workOrder/${this.state.projectId}`);
    } else {
      this.state.from === "list"
        ? (path = `/admin/estimates`)
        : (path = `AddEstimates`);
    }
    return path;
    // return this.state.isWorkOrder
    //   ? this.state.from === "list"
    //     ? `/admin/projects/detail/${this.state.projectId}`
    //     : `/admin/projects/detail/workOrder/${this.state.projectId}`
    //   : this.state.from === "list"
    //   ? "estimates"
    //   : `AddEstimates`;
  };

  Description = (index, type, value) => {
    // setTempItemDesc(value.item_desc);
    // descIndex({ index: index, type: type, value: value.item_desc });
    this.setState({
      descriptionModal: true,
      descIndex: { index: index, type: type, value: value.item_desc },
    });
  };
  getSearch = () => {
    const { isWorkOrder } = this.state;
    let search;
    if (isWorkOrder) {
      this.state.from === "list" && (search = "");
      this.state.estimate_id &&
        (search = `?workOrder_id=${this.state.estimate_id}`);
    } else {
      if (this.state.from === "list") search = "";
      else search = `?estimate_id=${this.state.estimate_id}`;
    }
    return search;
    // return this.state.isWorkOrder
    // ? this.state.from === "list"
    //   ? ""
    //   : this.state.estimate_id
    //   ?  `?workOrderId=${this.state.estimate_id}`
    //   : ""
    // : this.state.from === "list"
    // ? ""
    // : this.state.estimate_id
    // ? `?estimate_id=${this.state.estimate_id}`
    // : ""
  };

  onClose = () => {
    this.setState({ open: false, addAddressBar: false, emailModal: false });
  };

  formatNumber = (num) => {
    if (num % 1 === 0) {
      return num.toFixed(0);
    } else {
      return num.toFixed(2);
    }
  };

  render() {
    const lastIndex = this.state.head.length - 1;
    const secondLastIndex = this.state.head.length - 2;
    const thirdLastIndex = this.state.head.length - 3;
    let {
      isLoading,
      toast,
      estimate_lead_id,
      response,
      toastType,
      estimate_id,
      btnLoad,
      confirmation,
      phone,
      email,
      isOpenToggle,
      isOpenToggle2,
      isOpenToggle3,
      lead_List,
      listsBoxOpen,
      listsLoad,
      proposalSchema,
      subject,
      ConfirmationBtnLoad,
      content,
      isOpenLead,
      itemList,
      lead,
      items,
      projectId,
      taxValue,
      due_date,
      isWorkOrder,
      descriptionModal,
      descIndex,
      resultValues,
    } = this.state;
    return isLoading ? (
      <div className="spin-loading">
        <Spinner type="border" color="#077e8b" />
      </div>
    ) : (
      <>
        {toast && <ToastList message={response} type={toastType} />}
        <div
          style={{
            minHeight: "71px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingLeft: "22px",
            paddingRight: "22px",
            backgroundColor: "#FFFFFF",
            boxShadow: "0px 4px 5px 0px #00000003",
          }}
        >
          <div
            xs="8"
            className="manage-con fw-500"
            style={{
              color: "#000000",
              fontSize: "16px",
              paddingLeft: "0.5rem",
            }}
          >
            {`Preview of your ${isWorkOrder ? "Work Order" : "Estimate"} - ${
              this.state.estimate_id
            }`}
          </div>
          <div style={{ display: "flex", gap: "1rem" }}>
            <Button_without_Border
              btnLabel={"Go Back"}
              click={() => {
                this.props.history.push({
                  pathname: this.getPath(),
                  search: this.getSearch(),
                  state: {
                    estimate_id: this.state.estimate_id,
                    preview: true,
                    lead: this.state.lead,
                    subject: this.state.subject,
                    due_date: this.state.due_date,
                    items: this.state.items,
                    splitups: this.state.splitups,
                    terms: this.state.terms,
                    tax: this.state.tax,
                    showPreview: this.state.showPreview,
                    showDownloadIcon: this.state.showDownloadIcon,
                    showCopyLinkIcon: this.state.showCopyLinkIcon,
                    status: this.state.status,
                    tab: 3,
                  },
                });
              }}
            />
            {/* <Custom_Button btnLabel={"Download"} /> */}
          </div>
        </div>
        <div style={{ padding: "20px", backgroundColor: "#fafafa" }}>
          <CustomListingContainer>
            <ListingHeaderLayout
              className="estimate_heading_font"
              header={this.state.subject}
            ></ListingHeaderLayout>
            <ListingBodyLayout
              style={{ margin: "0" }}
              insideStyle={{ marginBottom: "0", width: "100%" }}
            >
              <div style={{ display: "flex", padding: "1.5rem", gap: "15px" }}>
                {this.state.contractor_image && (
                  <img
                    alt="image"
                    src={getCloudinaryImage(
                      120,
                      130,
                      this.state.contractor_image,
                      "c_fill"
                    )}
                    style={{
                      height: "6rem",
                      width: "7rem",
                      border: "1px solid #D9D9D9",
                      objectFit: "contain",
                    }}
                  />
                )}
                <div
                  style={{
                    display: "flex",
                    gap: "12px",
                    alignItems: "flex-start",
                    width: "50%",
                  }}
                >
                  <div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      <div className="bold_standard fw-500">
                        {this.state.contractor_name}
                      </div>
                      <div className="font_standard fw-400">
                        {this.state.contractor_email}
                      </div>
                      <div className="font_standard fw-400">
                        {getUSFormat(this.state.contractor_phone)}
                      </div>
                      <div className="font_standard fw-400">
                        {this.state.contractor_address}
                      </div>
                      <div className="font_standard fw-400">
                        {this.state.contractor_state}
                      </div>
                      <div className="font_standard fw-400">
                        {this.state.contractor_city} -{" "}
                        {this.state.contractor_zip}
                      </div>
                    </div>
                  </div>
                </div>
                {lead && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      width: "50%",
                    }}
                  >
                    <div className="bold_standard fw-500">
                      {this.capitalizeFirstLetter(lead.name)}
                    </div>
                    <div className="font_standard fw-400">{lead.email}</div>
                    <div className="font_standard fw-400">
                      {isWorkOrder
                        ? getUSFormat(lead?.phone?.national_number) || "-"
                        : getUSFormat(lead?.phone) || "-"}
                    </div>
                    {isWorkOrder && lead.address ? (
                      <>
                        <div className="font_standard fw-400">
                          {lead?.address?.line_1}
                        </div>
                        <div className="font_standard fw-400">
                          {lead?.address?.state}
                        </div>
                        <div className="font_standard fw-400">
                          {`${lead?.address?.city} - ${lead?.address?.zip}`}
                        </div>
                      </>
                    ) : (
                      <div className="font_standard fw-400">
                        {lead?.address}
                      </div>
                    )}
                    {/* <div className="font_standard fw-400">
                      {isWorkOrder
                        ? lead?.address?.line1 || "-"
                        : lead?.address}
                    </div> */}
                  </div>
                )}
              </div>
              <div style={{ width: "100%" }}>
                <div
                  className="col new-table"
                  style={{ marginTop: "1rem", padding: "0" }}
                >
                  <div className="shadow estimate_card_with_paginator">
                    <div
                      className="flexy-content"
                      style={{
                        gap: "1rem",
                        padding: "1rem 1rem 1rem 1.5rem",
                        alignItems: "center",
                      }}
                    >
                      {" "}
                      <div className="preview_heading_font">Items</div>
                      <div style={{ cursor: "pointer" }}></div>
                    </div>
                    <Table
                      className="align-items-center table-flush"
                      style={{ padding: "0" }}
                      responsive
                    >
                      <thead className="thead-light bordered-table">
                        <tr style={{ backgroundColor: "#FAFAFA" }}>
                          {this.state.head.map((str, index) => (
                            <th
                              className="estimate_table_font"
                              scope="col"
                              id="border_none"
                              style={this.styles[str]}
                            >
                              {remove_underscore_capitalize(str)}
                            </th>
                          ))}
                        </tr>
                      </thead>

                      {items && items.length > 0 ? (
                        <tbody>
                          {items.map((item, i) => {
                            return (
                              <>
                                <tr
                                  key={i}
                                  style={{ borderBottom: "1px solid #f0f0f0" }}
                                >
                                  {this.state.head.map((str, index) => (
                                    <>
                                      {str === "item_desc" && (
                                        <td
                                          id="bor_right fw-500 fs_14"
                                          style={{
                                            padding: "25px",
                                            color: "#212121",
                                          }}
                                          className="vertical_align_top"
                                        >
                                          <div
                                            style={{
                                              wordBreak: "break-all",
                                            }}
                                            className="project_list_content"
                                          >
                                            {item.item_desc?.replace(
                                              /(<([^>]+)>)/gi,
                                              ""
                                            ) ? (
                                              <div className="item_description">
                                                <div
                                                  onClick={() =>
                                                    this.Description(
                                                      i,
                                                      "item_desc",
                                                      item
                                                    )
                                                  }
                                                  className="estimateClassName"
                                                  style={{
                                                    whiteSpace: "pre-wrap",

                                                    color: "black",
                                                    fontSize: "14px",
                                                  }}
                                                  dangerouslySetInnerHTML={{
                                                    __html: item.item_desc,
                                                  }}
                                                ></div>
                                              </div>
                                            ) : (
                                              <div style={{ color: "#8e8f99" }}>
                                                -
                                              </div>
                                            )}
                                          </div>
                                        </td>
                                      )}
                                      {str === "item_name" && (
                                        <td
                                          className="cursor-point capitalize vertical_align_top"
                                          id="bor_right"
                                          style={{ padding: "25px" }}
                                        >
                                          <div
                                            className="project_list_content fw-500 fs_13"
                                            style={{
                                              color: "#000000",
                                              display: "flex",
                                              alignItems: "center",
                                              color: "#212121",
                                              whiteSpace: "initial",
                                              wordBreak: "break-all",
                                              lineHeight: "23px",
                                            }}
                                          >
                                            {item.item_name}
                                          </div>
                                        </td>
                                      )}
                                      {str === "quantity" && (
                                        <td
                                          id="bor_right"
                                          style={{
                                            textAlign: "center",
                                            padding: "25px",
                                          }}
                                          className="vertical_align_top"
                                        >
                                          <div
                                            className="project_list_content  fw-600 fs_13"
                                            style={{ color: "#212121" }}
                                          >
                                            {item.quantity.toFixed(2)}
                                          </div>
                                        </td>
                                      )}
                                      {str === "labor" && (
                                        <td
                                          id="bor_right"
                                          style={{
                                            padding: "25px",
                                            textAlign: "right",
                                          }}
                                          className="vertical_align_top"
                                        >
                                          <div
                                            className="project_list_content  fw-500 fs_13"
                                            style={{
                                              position: "relative",
                                              display: "flex",
                                              justifyContent: "flex-end",
                                              alignItems: "center",
                                              color: "#212121",
                                            }}
                                          >
                                            <div
                                              style={{
                                                maxWidth: "100px",
                                                overflowX: "auto",
                                                whiteSpace: "nowrap",
                                                color: "#212121",
                                                fontWeight: "600",
                                                fontSize: "13px",
                                                marginLeft: "5px",
                                              }}
                                            >
                                              {item.labor
                                                ? NumberWithCurrency(item.labor)
                                                : "0"}
                                            </div>
                                          </div>
                                        </td>
                                      )}
                                      {str === "material" && (
                                        <td
                                          id="bor_right"
                                          style={{
                                            padding: "25px",
                                          }}
                                          className="vertical_align_top"
                                        >
                                          <div
                                            className="project_list_content  fw-500 fs_13"
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "flex-end",
                                              color: "#212121",
                                            }}
                                          >
                                            <div
                                              style={{
                                                maxWidth: "100px",
                                                overflowX: "auto",
                                                whiteSpace: "nowrap",
                                                color: "#212121",
                                                fontWeight: "600",
                                                fontSize: "13px",
                                                marginLeft: "5px",
                                              }}
                                            >
                                              {item.material
                                                ? NumberWithCurrency(
                                                    item.material
                                                  )
                                                : "0"}
                                            </div>
                                          </div>
                                        </td>
                                      )}
                                      {str === "cost" && (
                                        <td
                                          id="bor_right"
                                          style={{
                                            padding: "25px",
                                            textAlign: "right",
                                          }}
                                          className="vertical_align_top"
                                        >
                                          <div
                                            className="project_list_content  fw-500 fs_13"
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "flex-end",
                                              color: "#212121",
                                            }}
                                          >
                                            <div
                                              style={{
                                                maxWidth: "100px",
                                                overflowX: "auto",
                                                whiteSpace: "nowrap",
                                                color: "#212121",
                                                fontWeight: "600",
                                                fontSize: "13px",
                                                marginLeft: "5px",
                                              }}
                                            >
                                              {item.cost
                                                ? NumberWithCurrency(item.cost)
                                                : "0"}
                                            </div>
                                          </div>
                                        </td>
                                      )}
                                      {str === "markup" && (
                                        <td
                                          id="bor_right"
                                          style={{
                                            padding: "25px",
                                            textAlign: "right",
                                          }}
                                          className="vertical_align_top"
                                        >
                                          <div
                                            className="project_list_content  fw-500 fs_13"
                                            style={{
                                              color: "#212121",
                                              display: "flex",
                                              justifyContent: "flex-end",
                                              alignItems: "center",
                                            }}
                                          >
                                            <div
                                              style={{
                                                maxWidth: "100px",
                                                overflowX: "auto",
                                                whiteSpace: "nowrap",
                                                color: "#212121",
                                                fontWeight: "600",
                                                fontSize: "13px",
                                                marginLeft: "5px",
                                              }}
                                            >
                                              {item.markup
                                                ? NumberWithCurrency(
                                                    item?.markup ||
                                                      0 + item?.material ||
                                                      0 + item?.labor ||
                                                      0
                                                  )
                                                : "0"}
                                            </div>
                                          </div>
                                        </td>
                                      )}
                                      {str === "amount" && (
                                        <td
                                          id="bor_right"
                                          className="vertical_align_top"
                                          style={{
                                            border: "0",
                                            textAlign: "end",
                                            padding: "25px",
                                          }}
                                        >
                                          <div
                                            className="project_list_content fw-500 fs_13"
                                            style={{
                                              color: "#212121",
                                              display: "flex",
                                              justifyContent: "flex-end",
                                              alignItems: "center",
                                            }}
                                          >
                                            <div
                                              style={{
                                                maxWidth: "100px",
                                                overflowX: "auto",
                                                whiteSpace: "nowrap",
                                                color: "#212121",
                                                fontWeight: "600",
                                                fontSize: "13px",
                                                marginLeft: "5px",
                                              }}
                                            >
                                              {NumberWithCurrency(
                                                roundOff(item.amount).toFixed(
                                                  2
                                                ) || "0"
                                              )}
                                            </div>
                                          </div>
                                        </td>
                                      )}
                                    </>
                                  ))}
                                </tr>
                              </>
                            );
                          })}
                        </tbody>
                      ) : (
                        <tbody>
                          <tr>
                            <td></td>
                            <td
                              className="no-data"
                              style={{ textAlign: "right" }}
                            >
                              No Item Available
                            </td>
                            <td></td>

                            <td></td>
                            <td></td>
                          </tr>
                        </tbody>
                      )}
                    </Table>
                    <div className="previewBottomTable">
                      <table>
                        <thead>
                          <th style={this.styles[3]}></th>
                          <th style={this.styles[3]}></th>
                          <th style={this.styles[3]}></th>
                        </thead>
                        <tbody>
                          <tr style={{ border: "none" }}>
                            <td
                              style={{
                                border: "none",
                                textAlign: "center",
                                color: "#6A7785",
                                paddingRight: "1rem",
                              }}
                              className="fw-600 fs_13"
                            >
                              Subtotal
                            </td>
                            <td
                              className="fw-500 fs_13"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                height: "52px",
                                justifyContent: "end",
                                color: "#212121",
                                border: "none",
                                paddingRight: "1.6rem",
                                // gap: "5%",
                              }}
                            >
                              <div
                                style={{
                                  maxWidth: "100px",
                                  overflowX: "auto",
                                  whiteSpace: "nowrap",
                                  color: "#212121",
                                  fontWeight: "600",
                                  fontSize: "13px",
                                }}
                              >
                                {NumberWithCurrency(
                                  resultValues?.subTotalAmount.toFixed(2)
                                )}
                              </div>
                            </td>
                          </tr>
                          <tr style={{ border: "none" }}>
                            <td
                              style={{
                                border: "none",
                                textAlign: "center",
                                color: "#6A7785",
                              }}
                              className="fw-600 fs_13"
                            >
                              Tax
                              <span style={{ marginLeft: "10px" }}>
                                {this.state.tax.value &&
                                  this.state.tax.type === "fixed" &&
                                  "$"}
                                {this.state.tax.value}
                                {this.state.tax.value &&
                                  this.state.tax.type === "percentage" &&
                                  "%"}
                              </span>
                            </td>
                            <td
                              style={{
                                display: "flex",
                                alignItems: "center",
                                borderTop: "none",
                                height: "52px",
                                justifyContent: "end",
                                color: "#212121",
                                paddingRight: "1.6rem",
                                // gap: "5%",
                              }}
                              className="fw-500 fs_13"
                            >
                              <div
                                style={{
                                  maxWidth: "100px",
                                  overflowX: "auto",
                                  whiteSpace: "nowrap",
                                  color: "#212121",
                                  fontWeight: "600",
                                  fontSize: "13px",
                                }}
                              >
                                {resultValues?.taxAmount
                                  ? `${NumberWithCurrency(
                                      resultValues?.taxAmount.toFixed(2)
                                    )}`
                                  : "0"}
                              </div>
                            </td>
                          </tr>
                          <tr style={{ border: "none" }}>
                            <td
                              className="fw-600 fs_13 "
                              style={{
                                backgroundColor: "#F7F8FA",
                                border: "none",
                                textAlign: "center",
                                color: "#212121",
                                paddingRight: "2.3rem",
                              }}
                            >
                              Total
                            </td>
                            <td
                              className="fw-600 fs_13 "
                              style={{
                                display: "flex",
                                alignItems: "center",
                                borderTop: "none",
                                height: "52px",
                                justifyContent: "end",
                                backgroundColor: "#F7F8FA",
                                border: "none",
                                color: "#212121",
                                paddingRight: "1.6rem",
                                // gap: "5%",
                              }}
                            >
                              <div
                                style={{
                                  maxWidth: "100px",
                                  overflowX: "auto",
                                  whiteSpace: "nowrap",
                                  color: "#212121",
                                  fontWeight: "600",
                                  fontSize: "13px",
                                }}
                              >
                                {resultValues?.totalAmount
                                  ? `${NumberWithCurrency(
                                      resultValues?.totalAmount.toFixed(2)
                                    )}`
                                  : "-"}
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              {this.state.splitups.length > 0 ? (
                <>
                  <div style={{ width: "100%" }}>
                    <div
                      style={{
                        borderBottom: "1px solid #f5f5f5",
                        padding: "1rem 1rem 1rem 1.5rem",
                      }}
                      className="preview_heading_font"
                    >
                      Payments
                    </div>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "1rem",
                        flexWrap: "wrap",
                        padding: "0 2rem 2rem 1.5rem ",
                      }}
                    >
                      {this.state.splitups.length > 0 &&
                        this.state.splitups.map((splitup, index) => (
                          <div className="payment_box" key={index}>
                            <div
                              className="payment_box_head  preview_font"
                              style={{ color: "#212121" }}
                            >
                              {appendCurrency(splitup.amount.amount.toFixed(2))}
                            </div>
                            <div className="payment_box_body">
                              <span className="mt_1 preview_font_sub">
                                {this.capitalizeFirstLetter(splitup.name)}
                              </span>
                              <div
                                className="font_standard"
                                style={{
                                  fontWeight: "300",
                                  color: "#212112",
                                  marginTop: "8px",
                                }}
                              >
                                Due : {showUtcDate(splitup.due_date)}
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </>
              ) : (
                <>{}</>
              )}
              {this.state.terms ? (
                <div style={{ width: "100%", marginBottom: "4rem" }}>
                  <div
                    style={{
                      borderBottom: "1px solid #f5f5f5",
                      padding: "1rem 1rem 1rem 1.5rem",
                    }}
                    className="preview_heading_font"
                  >
                    Terms and Conditions
                  </div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: this.state.terms,
                    }}
                    className="popupTerms_preview"
                    style={{
                      padding: "1rem 1rem 1rem 1.5rem",
                      whiteSpace: "initial",
                      wordBreak: "break-all",
                      color: "rgb(33, 33, 33)",
                      fontSize: "13px",
                      lineHeight: "23px",
                      maxHeight: "650px",
                      overflowY: "auto",
                    }}
                  ></div>
                </div>
              ) : (
                <></>
              )}
            </ListingBodyLayout>
          </CustomListingContainer>

          {descriptionModal && (
            <Modal
              isOpen={descriptionModal}
              centered="true"
              style={{ minWidth: "50rem", padding: "2rem" }}
              toggle={() => {
                this.setState({ descriptionModal: false });
              }}
              backdrop={false}
            >
              <div style={{ padding: "24px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    marginBottom: "15px",
                  }}
                >
                  <div>
                    <span style={{ color: "#7045B0" }}>Description</span>
                  </div>
                  <div>
                    <img
                      width={20}
                      height={20}
                      onClick={() => this.setState({ descriptionModal: false })}
                      src={CloseIcon}
                      alt="closeIcon"
                    ></img>
                  </div>
                </div>

                <div className="popUpDescription descTextBox">
                  <div
                    className="estimateClassName scrollbar_design"
                    style={{
                      wordBreak: "break-all",
                      whiteSpace: "pre-wrap",
                      maxHeight: "650px",
                      overflowY: "scroll",
                      color: "black",
                      fontSize: "14px",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: descIndex.value,
                    }}
                  ></div>
                </div>
              </div>
            </Modal>
          )}
        </div>
      </>
    );
  }
}

export default withRouter(PreviewProposal);
