import { Component } from "react";
import {
  Card,
  Table,
  Container,
  Row,
  Breadcrumb,
  Col,
  Button,
  InputGroup,
  Spinner,
  BreadcrumbItem,
  DropdownMenu,
  DropdownItem,
  Media,
  UncontrolledDropdown,
  DropdownToggle,
  Dropdown,
  Progress,
  Input,
} from "reactstrap";
import { calculatePercentageNumber } from "constants/utils";
import moment from "moment";
import showMore from "../../assets/img/brand/showMore.svg";
import { CustomListingContainer } from "components/Container/CustomListingContainer";
import { ListingHeaderLayout } from "components/Container/CustomListingContainer";
import { ListingBodyLayout } from "components/Container/CustomListingContainer";
import { CustomSearch } from "components/FilterFields/Filterfields";
import { CustomDropDown } from "components/FilterFields/Filterfields";
import { returnSearchObject } from "constants/utils";
import { pushToFilteredPage } from "constants/utils";
import { Button_without_Border } from "components/Buttons/Button";
import { showUtcDate } from "constants/utils";
export default class PaymentOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payments_list: [],
      initPageLoading: true,
      filterOption: "name",
      inputSearch: "",
      payment_lists: [],
      statusSearch: "",
      input_value: false,
      page: 1,
      filters: ["name"],
    };
  }

  getPayments = () => {
    this.setState({ initPageLoading: false });
  };

  componentDidMount() {
    this.setQueryStatus();
  }

  returnCurrentFilterOption = (obj) => {
    return (
      this.state.filters.filter((ele) => Object.keys(obj).includes(ele))[0] ??
      ""
    );
  };

  initCheckClerFilters = () => {
    this.state.filters.includes(this.state.filterOption) ||
      this.state.statusSearch ||
      this.state.inputSearch ||
      this.state.page > 1
      ? this.setState({ input_value: true })
      : this.setState({ input_value: false });
  };

  setQueryStatus = () => {
    let queryStatus = this.props.location.search;
    let queryObj = returnSearchObject(queryStatus);
    if (queryObj) {
      this.setState(
        {
          inputSearch: queryObj[this.returnCurrentFilterOption(queryObj)] ?? "",
          filterOption: queryObj.name ? "name" : "name",
          statusSearch: queryObj.status ?? "",
          page: parseInt(queryObj.page) ?? "",
          query: returnSearchObject(queryStatus),
        },
        () => {
          this.initCheckClerFilters();
          pushToFilteredPage(this.props, { ...this.state.query });
          this.getPayments();
        }
      );
    } else {
      this.setState(
        {
          query: {
            ...queryObj,
            page: 1,
            limit: 10,
          },
        },
        () => {
          pushToFilteredPage(this.props, { ...this.state.query });
          this.getPayments();
        }
      );
    }
  };

  handleReset = () => {
    let self = this;
    self.setState(
      {
        from: "",
        to: "",
        statusSearch: "",
        filterOption: "name",
        inputSearch: "",
        page: 1,
        limit: 10,
        query: {
          page: 1,
          limit: 10,
        },
        input_value: false,
      },
      () => {
        pushToFilteredPage(this.props, { ...this.state.query });
        this.getPayments();
      }
    );
  };

  checkForClearFilter = () => {
    this.setState({
      input_value:
        !this.state.statusSearch &&
          (this.state.filterOption === "name" || !this.state.filterOption) &&
          !this.state.inputSearch
          ? false
          : true,
    });
  };

  handleFilterStatus = () => {
    let staticQuery = returnSearchObject(this.props.location.search);
    staticQuery.status = this.state.statusSearch ?? "";
    staticQuery.page = this.state.page ?? "";
    for (let key in staticQuery) {
      if (key === "email" || key === "name") {
        delete staticQuery[key];
      }
    }
    staticQuery[this.state.filterOption] = this.state.inputSearch;
    this.setState({ query: staticQuery }, () => {
      if (this.state.filterOption !== "select") {
        pushToFilteredPage(this.props, { ...this.state.query });
        this.getPayments();
      }
    });
  };

  handleIncrementPage = () => {
    if (this.state.task_list.length >= 10) {
      this.setState({ page: this.state.page + 1, input_value: true }, () =>
        this.handleFilterStatus()
      );
    }
  };
  handleDecrementPage = () => {
    if (this.state.page > 1) {
      this.setState(
        {
          page: this.state.page - 1,
          input_value: this.state.page - 1 === 1 ? false : true,
        },
        () => this.handleFilterStatus()
      );
    }
  };

  setStatus = (status) => {
    this.setState(
      { statusSearch: status === "all" ? "" : status, page: 1 },
      () => {
        this.checkForClearFilter();
        this.handleFilterStatus();
      }
    );
  };

  onChangeHandler = (e, from) => {
    this.setState({ [from]: e === "select" ? "" : e, input_value: true }, () =>
      this.checkForClearFilter()
    );
    clearTimeout(this.state.timoutId);
    const timoutId = setTimeout(() => {
      this.state.filterOption && this.handleSearch();
    }, 1000);
    this.setState({ timoutId });
  };

  handleSearch = () => {
    this.setState({ page: 1 }, () => {
      this.handleFilterStatus();
    });
  };

  render() {
    let {
      initPageLoading,
      filterOption,
      inputSearch,
      statusSearch,
      payments_list,
      input_value,
      page,
      filters,
    } = this.state;

    return initPageLoading ? (
      <>
        <div className="spin-loading mt-8">
          <Spinner type="border" color="#077e8b" />
        </div>
      </>
    ) : (
      <div style={{ padding: "20px", backgroundColor: "#fafafa" }}>
        <div className="maintainenceDetailHeader">
          <div className="manage-con">
            <Breadcrumb>
              <BreadcrumbItem
                onClick={() =>
                  this.props.history.push(
                    "/admin/project_overview?tab=overview"
                  )
                }
                className="bread-cursor"
              >
                Project
              </BreadcrumbItem>
              <BreadcrumbItem active style={{ color: "#72569c" }}>Payments</BreadcrumbItem>
            </Breadcrumb>
          </div>
        </div>
        <CustomListingContainer>
          <ListingHeaderLayout label="payment_lists" />
          <ListingBodyLayout>
            <div style={{ margin: "24px" }}>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "35% 65%",
                  marginBottom: "1.8rem",
                }}
              >
                <div
                  style={{ display: "grid", gridTemplateColumns: "65% 45%" }}
                >
                  <CustomSearch
                    style={{ marginRight: "20px" }}
                    options={filters}
                    change={this.onChangeHandler}
                    filterOption={filterOption}
                    inputSearch={inputSearch}
                  />
                  <CustomDropDown
                    width={"130px"}
                    setStatus={this.setStatus}
                    status={statusSearch}
                    statusList={[
                      "all",
                      "accepted",
                      "pending",
                      "inprogress",
                      "completed",
                      "cancelled",
                      "rejected",
                    ]}
                  />
                </div>
                {input_value && (
                  <div className="right_filter_container">
                    <Button_without_Border
                      click={this.handleReset}
                      btnLabel={"clear_filter"}
                    ></Button_without_Border>
                  </div>
                )}
              </div>
            </div>
          </ListingBodyLayout>
          <div>
            <Row>
              <div className="col new-table">
                <Card className="shadow card_with_paginator">
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th style={{ width: "15%" }} scope="col" className="">
                          Name
                        </th>
                        <th style={{ width: "15%" }} scope="col">
                          Client
                        </th>
                        {/* <th scope="col">Start</th> */}
                        {/* <th scope="col">Actual Start</th> */}

                        <th style={{ width: "15%" }} scope="col">
                          Due On
                        </th>
                        <th style={{ width: "15%" }} scope="col">
                          Progress
                        </th>
                        <th style={{ width: "15%" }} scope="col">
                          Status
                        </th>
                        <th
                          style={{ width: "8%", cursor: "pointer" }}
                          scope="col"
                        ></th>
                      </tr>
                    </thead>
                    {payments_list && payments_list.length !== 0 ? (
                      <tbody>
                        {payments_list.map((project, i) => (
                          <tr key={i}>
                            <td
                              className="cursor-point capitalize "
                              onClick={() =>
                                this.redirectDetailPage(project.project_id)
                              }
                              style={{ width: "100px" }}
                            >
                              <div className="project_list_content">
                                {this.capitalizeFirstLetter(project.name)}
                              </div>
                            </td>
                            <td>
                              <div className="project_list_content">
                                {" "}
                                {this.capitalizeFirstLetter(
                                  project.client.name
                                )}
                              </div>
                            </td>
                            {/* 
                          <td>
                            <div className="project_list_content">
                              {" "}
                              {moment(project.start_date).format(
                                "DD MMM, YYYY"
                              )}
                            </div>
                          </td> */}
                            {/* <td>
                            <div className="project_list_content">
                              {" "}
                              {moment(project.actual_start).format(
                                "DD MMM, YYYY"
                              )}
                            </div>
                          </td> */}

                            <td>
                              <div className="project_list_content">
                                {" "}
                                {showUtcDate(project.end_date)}
                              </div>
                            </td>
                            <td
                              className={
                                project?.invoice?.status === "paid" ||
                                  project?.invoice?.status === "assigned"
                                  ? "text-capitalize status_accepted general_status_style"
                                  : project?.invoice?.status === "cancelled" ||
                                    project?.invoice?.status === "rejected"
                                    ? "text-capitalize red general_status_style"
                                    : project?.invoice?.status === "created" ||
                                      project?.invoice?.status === "pending"
                                      ? "text-capitalize yellow general_status_style"
                                      : project?.invoice?.status === "completed"
                                        ? "text-capitalize status_completed general_status_style"
                                        : project?.invoice?.status === "closed"
                                          ? "text-capitalize primary general_status_style"
                                          : "text-capitalize"
                              }
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "4%",
                                }}
                              >
                                {" "}
                                <div>
                                  {" "}
                                  <Progress
                                    color="#0A8080"
                                    value={
                                      calculatePercentageNumber(
                                        project.completed_tasks,
                                        project.total_tasks
                                      ) ?? 0
                                    }
                                  />
                                </div>
                                <div className="project_list_percentage">
                                  {calculatePercentageNumber(
                                    project.completed_tasks,
                                    project.total_tasks
                                  ) ?? 0}{" "}
                                  %
                                </div>
                              </div>
                            </td>

                            <td>
                              <div
                                className="project_list_status"
                                style={{
                                  color: `${project.status === "Completed"
                                    ? "#07A84E"
                                    : project.status === "Pending"
                                      ? "FF4949"
                                      : "FFCC3D"
                                    }`,
                                }}
                              >
                                {" "}
                                {this.capitalizeFirstLetter(project.status)}
                              </div>
                            </td>

                            <td>
                              <div className="">
                                <UncontrolledDropdown
                                  nav
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <DropdownToggle
                                    className="pr-0 nav-notice"
                                    nav
                                  >
                                    <img src={showMore} alt="show more" />
                                  </DropdownToggle>
                                  <DropdownMenu
                                    className="dropdown-menu-arrow min-width-drop"
                                    right
                                  >
                                    <DropdownItem
                                      onClick={() => this.editProject(project)}
                                    >
                                      <span>Edit</span>
                                    </DropdownItem>

                                    <DropdownItem
                                      onClick={() =>
                                        this.setState({
                                          openModal: true,
                                          delete_data: project,
                                          project_id: project.project_id,
                                        })
                                      }
                                    >
                                      <span>Delete</span>
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td className="no-data">No Payments Available</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                    )}
                  </Table>
                </Card>
              </div>
            </Row>
          </div>
          <Row className="align-items-center header-div jus-space new-margin-page paginator_box">
            <div className="paginate-flex-box ">
              <div
                className="paginate-box"
                onClick={() => this.handleDecrementPage()}
              >
                <p
                  className={page !== 1 ? "page_available" : "page_unavailable"}
                >
                  Previous
                </p>
              </div>
              <div
                className="paginate-box"
                onClick={() => this.handleIncrementPage()}
              >
                <p
                  className={
                    payments_list.length < 10
                      ? "page_unavailable"
                      : "page_available"
                  }
                >
                  Next
                </p>
              </div>
            </div>
          </Row>
        </CustomListingContainer>
      </div>
    );
  }
}
