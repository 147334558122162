import {
  Card,
  Container,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  DropdownMenu,
  DropdownItem,
  Modal,
  Table,
} from "reactstrap";
import ApiService from "constants/ApiService";
import Chart from "react-apexcharts";
import { showMomentFormat } from "constants/utils";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import { returnFirstDegreeObjValue } from "constants/utils";
import Lottie from "react-lottie";
import NoData from "../../../src/assets/Lotties/noData.json";
import React, { Component } from "react";
import { calculatePercentage } from "constants/utils";
import { showUtcDate } from "constants/utils";
import { getCookie } from "constants/utils";
import { showLocaldate } from "constants/utils";
import PayoutSideBar from "views/Payout/PayoutSideBar";
import TotalEarnings from "../../assets/img/brand/total earnings.svg";
import pendingPayouts from "../../assets/img/brand/pending payouts.svg";
import PendingServices from "../../assets/img/brand/pending services.svg";
import CompletedServices from "../../assets/img/brand/completedServices.svg";
import inviteIcon from "../../assets/img/newIcons/newInviteIcon.svg";
import DashboardCalender from "./dashboardCalender";
import { getCloudinaryImage } from "constants/utils";
import defaultProfile from "../../assets/img/brand/defaultUser.svg";
import verify from "../../assets/img/brand/verify.svg";
import Refer from "./refer";
import ReactTooltip from "react-tooltip";
import { CustomDropDown } from "components/FilterFields/Filterfields";
import { Custom_Button } from "components/Buttons/Button";

export const options = {
  title: "Company Performance",
  curveType: "function",
  legend: { position: "bottom" },
};

let userName = JSON.parse(localStorage.getItem("contractor_details"));
let lastActivity = getCookie("lastActivity");

class DashboardDesign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      detail: {},
      request_list: [],
      isGraphData: false,
      totalEarnings: 0,
      currentMonthSalePercentage: "",
      payouts_history: [],
      graphFilter: "tm",
      profileImage: "",
      contractorName: "",
      ReferModal: false,
      contractorAll: [],
      LineGraphdata: {
        labels: [],
        datasets: [],
      },
      LineGraphOptions: {
        legendCallback: function (chart) {
          return <div>Legend</div>;
        },
        maintainAspectRatio: false,
        label: {
          display: false,
        },
        interaction: {
          // mode: "index",
          // intersect: false,
          // axis: "x",
        },
        legend: {
          display: false,
          align: "end",
          labels: {
            boxWidth: 10,
          },
          title: {
            padding: 100,
          },
        },
        plugins: {
          tooltip: { enabled: false },
          // legend: false,

          legend: {
            display: true,
            labels: {
              font: {
                family: "monserrat",
                size: 16,
                weight: 600,
                lineHeight: 1.2,
              },
              color: "#214757",
              // height: 100,
            },
          },
        },
        scales: {
          gridLines: {
            display: true,
          },
          xAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: true,
              },
            },
          ],
          x: {
            type: "date",
            grid: {
              display: true,
            },
            title: {
              display: true,

              color: "#911",
              font: {
                family: "Comic Sans MS",
                size: 20,
                weight: "bold",
                lineHeight: 1.2,
              },
              padding: { top: 10, left: 0, right: 0, bottom: 0 },
            },
            ticks: {
              font: {
                family: "monserrat",
                size: "16px",
                weight: "bold",
                lineHeight: 1.2,
              },
              color: "#214757",
              callback: (value) => showLocaldate(value),
            },
          },
          y: {
            beginAtZero: true,

            // min: 0,
            // max: 40,
            ticks: {
              // stepSize: 5,
              font: {
                family: "monserrat",
                size: "16px",
                weight: "bold",
                lineHeight: 1.2,
              },
              color: "#214757",

              callback: (value) => "₹" + value,
            },

            title: {
              display: false,
              text: "Amount",
              color: "#911",
              font: {
                family: "Comic Sans MS",
                size: 20,
                weight: "bold",
                lineHeight: 1.2,
              },
              padding: { top: 0, left: 0, right: 0, bottom: 10 },
            },
            lines: {
              show: true,
            },
            grid: {
              display: true,
              drawBorder: true,
            },
          },
        },
      },
      series: [],
      options: {
        colors: ["#405188"],
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        legend: {
          show: false,
          position: "bottom",
          floating: false,
          align: "center",
        },
        dataLabels: {
          enabled: false,
        },
        // stroke: {
        //   width: [5, 7, 5],
        //   curve: "straight",
        //   dashArray: [0, 8, 5],
        // },
        title: {
          text: "",
          align: "left",
        },

        xaxis: {
          categories: [],
        },

        grid: {
          borderColor: "#f1f1f1",
        },
      },
      donut_series: [0, 0],
      donut_options: {
        hover: { mode: null },
        states: {
          hover: {
            filter: {
              type: "none",
            },
          },
          active: {
            filter: {
              type: "none" /* none, lighten, darken */,
            },
          },
        },
        colors: ["#405188", "#FFBE0A"],
        events: {},
        chart: {
          width: "100%",
          type: "donut",
          height: "90%",
        },

        plotOptions: {
          pie: {
            startAngle: 0,
            endAngle: 360,
          },
        },
        dataLabels: {
          enabled: false,
        },

        legend: {
          show: false,
        },
        title: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },

        showTooltips: false,
      },
      invoiceStatusView: "",
      query: {
        key: "",
      },
      payoutData: "",
      payoutModal: false,
    };
  }
  componentDidMount() {
    this.getDashboard();
    this.dashboardGraph();
    this.getContractorDetails();
    this.lastActivity();
  }
  lastActivity = () => {
    return getCookie("lastActivity");
  };

  getContractorDetails = async () => {
    let response = await ApiService.getAPI("contractor");
    if (response.success) {
      let name = response.data?.contractor?.name;
      this.setState({ contractorName: name });
      let type = response.data?.contractor?.sub_contractor;
      localStorage.setItem("sub_contractor", type);
      this.setState({ profileImage: response.data?.contractor?.image });
      this.setState({ contractorAll: response.data?.contractor });
    }
  };

  getDashboard = async () => {
    let queryParams = "";

    if (this.state.invoiceStatusView !== "") {
      queryParams = `?key=${this.state.invoiceStatusView}`;
    }
    let response = await ApiService.getAPI(
      `contractor/dashboard${queryParams}`
    );
    if (response.success) {
      this.setState({
        detail: response.data,
        isLoading: false,
        request_list: response.data.requests,
      });

      if (this.state.detail.lm_earnings > 0) {
        calculatePercentage(
          this.state.detail.tm_earnings && this.state.detail.tm_earnings,
          this.state.detail.lm_earnings && this.state.detail.lm_earnings,
          "totalEarnings",
          this
        );
      }
      let donutvalueArray = [];
      donutvalueArray.push(this.state.detail.com_services);
      donutvalueArray.push(this.state.detail.pend_services);
      this.setState({
        payouts_history: response.data && response.data.payouts,
        donut_series: donutvalueArray,
      });
    }
  };

  dashboardGraph = async () => {
    let { invoiceStatusView } = this.state;

    let queryParams = "";

    if (invoiceStatusView !== "") {
      queryParams = `?key=${this.state.invoiceStatusView}`;
    }

    let response = await ApiService.getAPI(
      `contractor/dashboard/graph${queryParams}`
    );
    if (response.success) {
      this.state.isGraphData = true;
      let data = response.data.values.map((val) => {
        return {
          x: val.x,
          y: val.y,
        };
      });
      let labels = data.map((val) => {
        return val.x;
      });

      this.setState((prevState) => ({
        series: [
          ...prevState.series,
          { name: "Earnings", data: data.map((value) => value.y) },
        ],
      }));
      this.setState((prevState) => ({
        options: {
          ...prevState.options,
          xaxis: {
            ...prevState.options.xaxis,
            categories: data.map((value) => showMomentFormat(value.x)),
          },
        },
      }));
      this.setState({
        LineGraphdata: {
          labels: labels,
          datasets: [
            {
              label: "Sales",
              data: data,
              borderColor: "#405188",
              pointBackgroundColor: "#405188",
              borderWidth: 5,
              pointBorderWidth: 0,
              pointHoverBorderWidth: 4,
              pointRadius: 2,
              tension: 0.2,
              fill: false,
            },
          ],
        },
      });
    } else {
      this.state.isGraphData = false;
    }
  };
  RouteFunction = () => {
    let self = this;
    self.props.history.push(`/admin/requests`);
  };
  handleTypeInputChange = (e) => {
    this.setState({ graphFilter: e.target.value, series: [] }, () => {
      this.dashboardGraph();
    });
  };

  setInvoiceStatusView = (status) => {
    const stats =
      status === "this_month"
        ? "tm"
        : status === "last_month"
        ? "lm"
        : status === "this_year"
        ? "ty"
        : "";
    this.setState({ invoiceStatusView: stats, series: [] }, () => {
      this.getDashboard();
      this.dashboardGraph();
    });
  };

  openPayoutDetails = (data) => {
    this.setState({ payoutData: data, payoutModal: true });
  };

  closePayoutSideBar = () => {
    this.setState({ payoutModal: false });
  };
  closeReferPoppop = () => {
    this.setState({ ReferModal: false });
  };
  openReferModal = () => {
    this.setState({ ReferModal: true });
  };

  render() {
    const NodataFound = {
      loop: true,
      autoplay: true,
      animationData: NoData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    let {
      detail,
      payoutData,
      payoutModal,
      profileImage,
      contractorName,
      ReferModal,
      invoiceStatusView,
      contractorAll,
    } = this.state;
    return (
      <>
        <div
          style={{ padding: "20px", backgroundColor: "#fafafa" }}
          //   className="header pt-md-6 mt-6 equal_header_padding"
        >
          <div>
            <div className="flex mb-20 ">
              <div className="">
                <div className="f16 fw-600  mb-7p col_primary_2">
                  Welcome {userName?.contractor?.name}
                </div>
                <div className="f13 fw-400 clABABAB">
                  Last Activity: {this.lastActivity()}
                </div>
              </div>
              <CustomDropDown
                width={"126px"}
                setStatus={this.setInvoiceStatusView}
                status={
                  invoiceStatusView === "tm"
                    ? "this_month"
                    : invoiceStatusView === "lm"
                    ? "last_month"
                    : invoiceStatusView === "ty"
                    ? "this_year"
                    : "all"
                }
                statusList={["all", "this_month", "last_month", "this_year"]}
              />
            </div>
            <div className="dashboard_div">
              <div className="left_dashboard">
                <div
                  className="card_fullbody"
                  style={{
                    boxShadow: "0px 2px 20px 0px rgba(0, 0, 0, 0.0392156863)",
                    border: "1px solid #E1E8F0",
                  }}
                >
                  <div className="card_flex">
                    <Link
                      to={{
                        pathname: "/admin/invoices",
                        merge: "TransactionProp",
                      }}
                      className="cards flexy-content point"
                    >
                      <div>
                        <img
                          src={TotalEarnings}
                          alt="totalEarningIcon"
                          className="card_img"
                        />
                      </div>
                      <div className="cards_content">
                        <h4 className="card_head">TOTAL EARNINGS</h4>
                        <label className="card_counts">
                          <CountUp
                            start={0}
                            end={returnFirstDegreeObjValue(
                              detail,
                              "total_earning"
                            )}
                            separator=","
                            prefix="$ "
                          />
                        </label>
                      </div>
                    </Link>

                    <Link
                      to={{
                        pathname: "/admin/payouts",
                        merge: "TransactionProp",
                      }}
                      className="cards flexy-content point"
                    >
                      <div>
                        <img
                          src={pendingPayouts}
                          alt="totalEarningIcon"
                          className="card_img"
                        />
                      </div>
                      <div className="cards_content">
                        <h4 className="card_head">PAYOUTS</h4>
                        <label className="card_counts">
                          <CountUp
                            start={0}
                            end={returnFirstDegreeObjValue(
                              detail,
                              "pending_payouts"
                            )}
                            separator=","
                            prefix="$"
                          />
                        </label>
                      </div>
                    </Link>

                    <div className="cards flexy-content point">
                      <div>
                        <img
                          src={CompletedServices}
                          alt="totalEarningIcon"
                          className="card_img"
                        />
                      </div>
                      <div className="cards_content">
                        <h4 className="card_head">COMPLETED SERVICES</h4>
                        <label className="card_counts">
                          <CountUp
                            start={0}
                            end={returnFirstDegreeObjValue(
                              detail,
                              "com_services"
                            )}
                            separator=","
                            prefix=""
                          />
                        </label>
                      </div>
                    </div>
                    <div className="cards flexy-content point">
                      <div>
                        <img
                          src={PendingServices}
                          alt="totalEarningIcon"
                          className="card_img"
                        />
                      </div>
                      <div className="cards_content">
                        <h4 className="card_head">PENDING SERVICES</h4>
                        <label className="card_counts">
                          <CountUp
                            start={0}
                            end={detail?.pend_services + detail?.inpro_services}
                            separator=","
                            prefix=""
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="cardBody scroll_design"
                  style={{ overflow: "auto" }}
                >
                  <div className="graph_filter_div mb-30">
                    <p className="col_primary_2">SALE</p>
                  </div>

                  <Chart
                    options={this.state.options}
                    series={this.state.series}
                    type="line"
                    width="100%"
                    height="320px"
                  />
                  {/* <div style={{ height: "90%" }}>
                                     <Line
                                      data={this.state.LineGraphdata}
                                     options={this.state.LineGraphOptions}
                                     />
                                               </div> */}
                </div>
                <div className="invite_payout_feild mb-30">
                  <div
                    className="cardBody"
                    style={{
                      width: "49%",
                      padding: "30px",
                      textAlign: "center",
                    }}
                  >
                    <img
                      src={inviteIcon}
                      alt="invite picture"
                      className="mb-20"
                      style={{ width: "290px" }}
                    />
                    <h1 className="invite_feild_h1 col_primary_2">
                      Invite your Friends
                    </h1>
                    <p className="invite_p">
                      Just share the link with your friends and family and ask
                      <br />
                      them to signup and receive rewards for it.
                    </p>
                    <Custom_Button
                      style={{ marginTop: "20px" }}
                      isPrimary
                      btnLabel={"Invite"}
                      click={this.openReferModal}
                    />
                    {/* <button
                      className="pfl_share_btn f14 fw-500 invite_btn"
                      onClick={() => this.openReferModal()}
                    >
                      Invite Now
                    </button> */}
                  </div>
                  {/* <div className="payout_feild"> */}
                  <div className="payout_feild">
                    <Card className="dashboard_card">
                      <div className="header_with_button">
                        <p className="col_primary_2">Payouts</p>
                        <Link
                          to={{
                            pathname: "/admin/payouts",
                            merge: "TransactionProp",
                          }}
                        >
                          <button className="view_all point">View All</button>
                        </Link>
                      </div>
                      {this.state.payouts_history &&
                      this.state.payouts_history.length > 0 ? (
                        <Table className="table_container">
                          <thead className="payout_header">
                            <tr>
                              {/* <th>Payout Id</th> */}
                              <th>Created On</th>
                              <th>Amount</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          {this.state.payouts_history.map((item, i) => (
                            <tbody
                              className="grey_table_body"
                              key={item.payout_id}
                            >
                              <tr className="grey_data" key={i}>
                                {/* <td
                                                                    className="first_td point"
                                                                    onClick={() => this.openPayoutDetails(item)}
                                                                >
                                                                    {item.payout_id}
                                                                </td> */}
                                <td>{showUtcDate(item.createdAt)}</td>
                                <td className="ft_family">${item.amount}</td>
                                <td className="capitalize ft_family">
                                  {" "}
                                  <div
                                    style={{
                                      color:
                                        item.status === "paid" ||
                                        item.status === "processed"
                                          ? "#008000"
                                          : item.status === "pending" &&
                                            "#F7B84A",fontWeight:"500"
                                    }}
                                  >
                                    {item.status}
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          ))}
                        </Table>
                      ) : (
                        <div className="no_data_dashboard">
                          <Lottie
                            options={NodataFound}
                            height={100}
                            width={100}
                          />
                          <div className="mt_20">
                            <p>No Payouts Found</p>
                          </div>
                        </div>
                      )}
                    </Card>
                  </div>
                  {/* </div> */}
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "2%",
                }}
                className="right_dashboard"
              >
                <div
                  className="share_profile"
                  style={{
                    boxShadow: "0px 2px 20px 0px rgba(0, 0, 0, 0.0392156863)",
                    border: "1px solid #E1E8F0",
                  }}
                >
                  <div style={{ borderBottom: "1px solid #EEEDED" }}>
                    <div className="align-items-center">
                      {!profileImage ? (
                        <div className="shareProfilePicName">
                          <label
                            htmlFor="imageUpload"
                            style={{ marginBottom: "0px" }}
                            className="mb-remove"
                          >
                            <img
                              src={defaultProfile}
                              alt="profile image"
                              className="image_pxl mb-10"
                            />
                          </label>
                          <div className="col_primary_2 f20 fw-600 mt-7px">
                            {contractorName !== "" ? contractorName : ""}
                          </div>
                        </div>
                      ) : (
                        <div className="shareProfilePicName">
                          <label
                            htmlFor="imageUpload"
                            style={{
                              marginBottom: "0px",
                              position: "relative",
                            }}
                            className="mb-remove"
                          >
                            {profileImage && (
                              <div className="profile_img">
                                <img
                                  src={getCloudinaryImage(
                                    100,
                                    100,
                                    profileImage,
                                    "c_fill"
                                  )}
                                  alt="profile image"
                                  className="image_pxl mb-10"
                                />
                                <div
                                  className=""
                                  data-tip={
                                    "Your account is verified by Parknstays"
                                  }
                                  data-iscapture="true"
                                  data-for="workingInfo"
                                  data-place="bottom"
                                >
                                  <img
                                    src={verify}
                                    alt="verify img"
                                    className="verify"
                                  />
                                  <ReactTooltip
                                    place="left"
                                    type="info"
                                    effect="solid"
                                    id="workingInfo"
                                    html={true}
                                  />
                                </div>
                              </div>
                            )}
                          </label>
                          <div
                            className="col_primary_2 f20 fw-600"
                            style={{ padding: "21px 10px" }}
                          >
                            {contractorName !== "" ? contractorName : ""}
                          </div>
                        </div>
                      )}
                      {/* </span> */}
                      <Media className="ml-2 d-none d-lg-block"></Media>
                    </div>
                    {/* <img src={unsplash} alt="" className="image_pxl" />
                                        <h3 className="f20">Jack</h3> */}
                    {/* <p className="f12 basic-info-name-payment mt-10">Lorem ipsum dolor sit amet</p> */}
                  </div>

                  <div className="profile_review">
                    <div className="bd_rgt">
                      <h3 className="f18 fw-600">
                        {contractorAll?.ratings?.rating}
                      </h3>
                      <p className="f13">Rating</p>
                    </div>
                    <div className="bd_rgt">
                      <h3 className="f18 fw-600">
                        {contractorAll?.ratings?.count}
                      </h3>
                      <p className="f14">Reviews</p>
                    </div>
                    <div className="bd_rgt_last">
                      <h3 className="f18 fw-600">
                        {contractorAll?.recommendations}
                      </h3>
                      <p className="f14">Suggestions</p>
                    </div>
                  </div>
                </div>
                <div
                  className="upcoming_appointment"
                  style={{
                    boxShadow: "0px 2px 20px 0px rgba(0, 0, 0, 0.0392156863)",
                    border: "1px solid #E1E8F0",
                  }}
                >
                  <DashboardCalender />
                </div>
              </div>
            </div>
            <Card
              className={payoutModal ? "payoutSideWidth" : "toggle-sidebar"}
            >
              <PayoutSideBar
                data={payoutData}
                close={this.closePayoutSideBar}
                isOpen={payoutModal}
              />
            </Card>
            <Modal
              className="modal-dialog-centered modal-dialog-recommand-contractor"
              size="lg"
              aria-labelledby="contained-modal-title-vcenter "
              isOpen={ReferModal}
              toggle={this.closeReferPoppop}
            >
              <Refer close={this.closeReferPoppop} />
            </Modal>
          </div>
        </div>
      </>
    );
  }
}
export default DashboardDesign;
