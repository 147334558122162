import React, { Component } from "react";
import {
  Card,
  Table,
  Container,
  Row,
  Breadcrumb,
  Col,
  Button,
  InputGroup,
  Dropdown,
  Input,
  Spinner,
  Modal,
  CardBody,
  FormGroup,
  InputGroupText,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
} from "reactstrap";
// core components
import cookie from "react-cookies";
import ApiService from "constants/ApiService";
import { returnFirstDegreeObjValue } from "constants/utils";
import returnSecondDegreeObjValue, { getAuthToken } from "constants/utils";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Park from "../../assets/img/new Logo/pkLogoForWhiteBk.svg";
import { displayPrice } from "constants/utils";
import { showUtcDate } from "constants/utils";
import { getIsoString } from "constants/utils";
import ToastList from "components/Toast/ToastList";
import {
  buildQueryString,
  getCommissionAmount,
  isOutsideFlow,
  isSub_Contractor,
  pushToFilteredPage,
  redirectToBookings,
  returnSearchObject,
  showError,
  showSuccess,
} from "../../constants/utils";
import infoIcon from "../../assets/img/brand/infoIcon.svg";
import ReactTooltip from "react-tooltip";
import timelineIcon from "../../assets/img/brand/invoiceTimeline.svg";
import TimeLineSidebar from "./timeLineSideBar";
import showMore from "../../assets/img/brand/showMore.svg";
import CancelInvoiceModal from "./CancelInvoiceModal";
import MarkPaymentSideBar from "./MarkPaymentSideDragger";
import Env from "constants/Env";
import { CustomListingContainer } from "components/Container/CustomListingContainer";
import { ListingHeaderLayout } from "components/Container/CustomListingContainer";
import { CustomDropDown } from "components/FilterFields/Filterfields";
import { CustomDatePicker } from "components/FilterFields/Filterfields";
import { CustomSearch } from "components/FilterFields/Filterfields";
import { Button_without_Border } from "components/Buttons/Button";
import TimeLine from "components/Timeline/Timeline";
import CustomModal from "components/CustomModal/CustomModal";
import ClientPage from "views/externalPages/clientPage";
import ToolTip from "components/ToolTip/ToolTip";

class Invoices extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invoiceList: [],
      total: 0,
      filterToggle: false,
      from: "",
      to: "",
      page: 1,
      inputSearch: "",
      filterOption: "propname",
      // statusSearch: this.props?.location?.search ? "created" : "",
      statusSearch: "",
      modalOpen: false,
      isLoading: true,
      invoiceDetail: {},
      lateFee: 0,
      toast: false,
      response: "",
      statsData: {},
      totalInvoicePercentage: 0,
      paidInvoicePercentage: 0,
      toastType: "",
      invoiceStatusView: "",
      timeLineModal: false,
      invoiceId: "",
      variableUndefined: "",
      markPaymentModal: false,
      cancelInvoiceModal: false,
      invoiceDesc: "",
      timeoutId: null,
      input_value: false,
      filters: ["propname"],
    };
  }

  componentDidMount = () => {
    this.setQueryStatus();
  };

  componentWillUnmount = () => {
    clearTimeout(this.state.timeoutId);
  };

  returnCurrentFilterOption = (obj) => {
    return (
      this.state.filters.filter((ele) => Object.keys(obj).includes(ele))[0] ??
      ""
    );
  };

  initCheckClerFilters = () => {
    this.state.filters.includes(this.state.filterOption) ||
    this.state.statusSearch ||
    this.state.inputSearch ||
    this.state.from ||
    this.state.to ||
    this.state.page > 1
      ? this.setState({ input_value: true })
      : this.setState({ input_value: false });
  };

  setQueryStatus = () => {
    let queryStatus = this.props.location.search;
    let queryObj = returnSearchObject(queryStatus);
    if (queryStatus) {
      this.setState(
        {
          from: queryObj.from ? new Date(queryObj.from) : "",
          to: queryObj.to ? new Date(queryObj.to) : "",
          filterOption: queryObj.property ? "propname" : "propname",
          inputSearch: queryObj[this.returnCurrentFilterOption(queryObj)] ?? "",
          statusSearch: queryObj.status ?? "",
          page: parseInt(queryObj.page) ?? "",
          query: returnSearchObject(queryStatus),
        },
        () => {
          this.initCheckClerFilters();
          pushToFilteredPage(this.props, { ...this.state.query });
          this.getInvoiceList();
        }
      );
    } else {
      this.setState(
        {
          query: {
            ...this.state.query,
            page: 1,
            limit: 10,
          },
        },
        () => {
          pushToFilteredPage(this.props, this.state.query);
          this.getInvoiceList();
        }
      );
    }
  };

  viewInvoiceData = (invoice) => {
    let { main_contractor_amount, amount, sub_contractor } = invoice;
    let amt;

    if (!isSub_Contractor() && !isOutsideFlow(sub_contractor)) {
      if (sub_contractor?.id && main_contractor_amount?.type) {
        if (main_contractor_amount?.type === "percentage") {
          amt = displayPrice(
            amount * (main_contractor_amount?.commission / 100)
          );
        } else {
          amt = displayPrice(main_contractor_amount?.commission);
        }

        let final_val = [
          amt,
          getCommissionAmount(invoice, invoice.amount, isSub_Contractor()),
        ];

        return final_val;
      }
    }
  };

  handleFilterStatus = () => {
    let staticQuery = returnSearchObject(this.props.location.search);
    staticQuery.from = getIsoString(this.state.from) ?? "";
    staticQuery.to = getIsoString(this.state.to) ?? "";
    for (let key in staticQuery) {
      if (key === "propname") {
        delete staticQuery[key];
      }
    }
    staticQuery[this.state.filterOption] = this.state.inputSearch;
    staticQuery.status = this?.state?.statusSearch ?? "";
    staticQuery.page = this.state.page ?? "";
    this.setState({ query: staticQuery }, () => {
      if (this.state.filterOption !== "select") {
        pushToFilteredPage(this.props, { ...this.state.query });
        this.getInvoiceList();
      }
    });
  };

  onChangeHandler = (e) => {
    e.target.value === "" && this.handleReset();
    this.setState({ [e.target.name]: e.target.value, input_value: true });
    clearTimeout(this.state.timeoutId);
    const timeoutId = setTimeout(() => {
      this.state.filterOption && this.handleSearch();
    }, 1000);
    this.setState({ timeoutId });
  };
  onChangeSelectHandler = (status) => {
    this.setState({ filterOption: status, input_value: true }, () => {
      this.handleFilterStatus();
    });
  };

  setFilterSearch = (e) => {
    this.setState({ filterOption: "propname" });
  };
  getInvoiceList = async () => {
    let { page, filterOption, statusSearch, inputSearch, from, to } =
      this.state;
    let response = await ApiService.getAPI(
      `contractor/invoice${buildQueryString(this.state?.query ?? "")}`
    );
    if (response.success) {
      let { invoices } = response?.data || {};
      this.setState({
        invoiceList: invoices || [],
        isLoading: false,
      });
    }
  };

  updateStateValues = (key, value) => {
    const updatedValue = value == null ? "" : value;
    this.setState({ [key]: updatedValue, input_value: true }, () => {
      if (this.state.from && this.state.to) {
        this.handleFilterStatus();
      }
    });
  };

  handleOnToChange = (e) => {
    this.updateStateValues("to", e ? new Date(e) : "");
  };

  handleOnFromChange = (e) => {
    this.updateStateValues("from", e ? new Date(e) : "");
  };

  handleIncrementPage = () => {
    if (this.state.invoiceList.length >= 10) {
      this.setState(
        {
          page: this.state.page + 1,
          input_value: true,
        },
        () => {
          this.handleFilterStatus();
        }
      );
    }
  };

  handleDecrementPage = () => {
    if (this.state.page > 1) {
      this.setState(
        {
          page: this.state.page - 1,
          input_value: this.state.page - 1 === 1 ? false : true,
        },
        () => {
          this.handleFilterStatus();
        }
      );
    }
  };

  handleSearch = () => {
    this.setState({ page: 1 }, () => {
      this.handleFilterStatus();
    });
  };

  handleReset = () => {
    let self = this;
    self.setState(
      {
        from: "",
        to: "",
        statusSearch: "",
        filterOption: "propname",
        inputSearch: "",
        invoiceStatusView: "",
        page: 1,
        limit: 10,
        query: {
          page: 1,
          limit: 10,
        },
        input_value: false,
      },
      () => {
        pushToFilteredPage(this.props, { ...this.state.query });
        this.getInvoiceList();
      }
    );
  };

  toggleModal = (invoice_id) => {
    this.setState(
      {
        modalOpen: !this.state.modalOpen,
      },
      () => this.state.modalOpen && this.getInvoiceDetail(invoice_id)
    );
  };

  getInvoiceDetail = async (id) => {
    let response = await ApiService.getAPI(`contractor/invoice/${id}`);
    if (response.success) {
      this.setState({
        invoiceDetail: response.data.invoice,
        invoiceDesc: response.data.invoice.items[0].title,
      });
    }
  };
  checkForClearFilter = () => {
    this.setState({
      input_value:
        !this.state.statusSearch &&
        (this.state.filterOption === "select" || !this.state.filterOption) &&
        !this.state.from &&
        !this.state.to &&
        !this.state.invoiceStatusView &&
        !this.state.inputSearch
          ? false
          : true,
    });
  };
  handleCloseModal = () => {
    this.setState({ modalOpen: false });
  };
  handleSubLateFee = (amount, base_price) => {
    let lateFee = amount - base_price;
    this.setState({
      lateFee,
    });
  };

  setStatus = (status) => {
    this.setState(
      {
        statusSearch: status === "all" ? "" : status,
        page: 1,
      },
      () => {
        this.checkForClearFilter();
        this.handleFilterStatus();
      }
    );
  };

  returnCommissionTooltip = (invoice) => {
    let { main_contractor_amount, amount, sub_contractor } = invoice;
    let amt;

    if (!isSub_Contractor() && !isOutsideFlow(sub_contractor)) {
      if (sub_contractor?.id && main_contractor_amount?.type) {
        if (main_contractor_amount?.type === "percentage") {
          amt = displayPrice(
            amount * (main_contractor_amount?.commission / 100)
          );
        } else {
          amt = displayPrice(main_contractor_amount?.commission);
        }

        return (
          <>
            <img
              src={infoIcon}
              className="mr-10"
              data-tip={`Your Commission  ${amt} , Subcontractor earnings${getCommissionAmount(
                invoice,
                invoice.amount,
                isSub_Contractor()
              )} `}
              data-iscapture="true"
              data-for="syncInfo"
              data-place="top"
            />
            <ReactTooltip
              place="left"
              type="info"
              effect="solid"
              id="syncInfo"
              html={true}
            />
          </>
        );
      } else {
        return;
      }
    }
  };

  setInvoiceStatusView = (status) => {
    let from;
    let to;
    this.setState({ invoiceStatusView: status === "all" ? "" : status }, () =>
      this.checkForClearFilter()
    );
    if (status === "this_month") {
      from = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
      to = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
    } else if (status === "last_month") {
      from = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1);
      to = new Date(new Date().getFullYear(), new Date().getMonth(), 0);
    } else if (status === "this_year") {
      from = new Date(new Date().getFullYear(), 0, 1);
      to = new Date(new Date().getFullYear(), 11, 31);
    } else {
      from = "";
      to = "";
    }
    this.setState({ from, to, input_value: true }, () => {
      this.handleFilterStatus();
    });
  };
  openTimeLineModal = (id) => {
    this.setState({ invoiceId: id, timeLineModal: true });
  };

  closeTimeLineModal = () => {
    this.setState({ timeLineModal: false });
  };

  openCancelInvoicePopup = (id) => {
    this.setState({ cancelInvoiceModal: true, invoiceId: id });
  };

  toggleCancelInvoiceModal = (msg) => {
    this.setState({ cancelInvoiceModal: false });
    if (msg) {
      showSuccess(this, msg);
      this.getInvoiceList();
    }
  };

  openMarkPaymentSideDragger = (id) => {
    this.setState({ markPaymentModal: true, invoiceId: id });
  };

  closeMarkPaymentSideDraggger = (msg) => {
    if (msg) {
      showSuccess(this, msg);
      this.getInvoiceList();
    }
    this.setState({ markPaymentModal: false });
  };

  displayErrorMessage = (msg) => {
    if (msg) {
      showError(this, msg);
    }
  };
  downloadInvoice = async (id) => {
    fetch(Env.BASE_URL + `contractor/invoice/download/${id}`, {
      method: "GET",
      headers: { Authorization: "Bearer " + getAuthToken() },
    })
      .then((response) => {
        return response.blob();
      })
      .then((data) => {
        if (data.type === "application/json") {
          showError(this, "No records found to download");
        } else {
          const downloadLink = document.createElement("a");
          downloadLink.href = window.URL.createObjectURL(data);
          downloadLink.setAttribute("download", `${id}.pdf`);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }
      });
  };

  getInvoiceCommissionAmount = (invoice, amount, isSubContractor) => {
    let { main_contractor_amount, sub_contractor } = invoice;

    let amt = 0;
    if (
      isSubContractor ||
      isOutsideFlow(sub_contractor) ||
      invoice?.assign_type === "outside"
    ) {
      if (main_contractor_amount) {
        if (main_contractor_amount?.type === "percentage") {
          amt = amount - amount * (main_contractor_amount?.commission / 100);
        } else {
          amt = amount - main_contractor_amount?.commission;
        }
      } else {
        amt = amount;
      }
    } else if (!isSubContractor) {
      if (sub_contractor?.id && main_contractor_amount?.type) {
        if (main_contractor_amount?.type === "percentage") {
          let percent = amount * (main_contractor_amount?.commission / 100);
          amt = amount - percent;
        } else {
          amt = amount - main_contractor_amount?.commission;
        }
      } else {
        amt = amount;
      }
    }

    return amt;
  };
  render() {
    let {
      invoiceList,
      filterToggle,
      inputSearch,
      filterOption,
      statusSearch,
      isLoading,
      page,
      modalOpen,
      invoiceDetail,
      totalInvoicePercentage,
      paidInvoicePercentage,
      lateFee,
      toast,
      response,
      statsData,
      toastType,
      invoiceStatusView,
      timeLineModal,
      markPaymentModal,
      cancelInvoiceModal,
      invoiceId,
      invoiceDesc,
      from,
      to,
      input_value,
      filters,
    } = this.state;
    return isLoading ? (
      <div className="spin-loading">
        <Spinner type="border" color="#077e8b" />
      </div>
    ) : (
      <>
        {toast && <ToastList message={response} type={toastType} />}
        <div style={{ padding: "20px", backgroundColor: "#fafafa" }}>
          <CustomListingContainer>
            <ListingHeaderLayout label={"invoices"}>
              <CustomDropDown
                width={"126px"}
                statusList={["all", "this_month", "last_month", "this_year"]}
                status={invoiceStatusView}
                setStatus={this.setInvoiceStatusView}
              />
            </ListingHeaderLayout>
            <div style={{ margin: "24px" }}>
              <div className="d_flex_sb" style={{}}>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ display: "flex", width: "80%" }}>
                    <CustomDatePicker
                      from={from}
                      to={to}
                      fromChange={this.handleOnFromChange}
                      toChange={this.handleOnToChange}
                    ></CustomDatePicker>
                    <div
                      className="select_with_input status_select_input ml-10"
                      style={{ marginRight: "10px" }}
                    >
                      <UncontrolledDropdown
                        className="status_select_div "
                        style={{ minWidth: "115px" }}
                      >
                        <DropdownToggle className="pr-0 drop_down_container">
                          <Media
                            className="capitalize"
                            style={{ fontSize: "0.875rem !important" }}
                          >
                            {filterOption === "propname"
                              ? "Property"
                              : "Property"}
                            <i
                              className="fas fa-angle-down"
                              style={{ marginLeft: "20px" }}
                            ></i>
                          </Media>
                        </DropdownToggle>
                        <DropdownMenu
                          className="dropdown-menu-arrow"
                          bottom
                          style={{ minWidth: "130px" }}
                        >
                          {" "}
                          <DropdownItem
                            className="dropdown_item"
                            onClick={() =>
                              this.onChangeSelectHandler("propname")
                            }
                          >
                            Property
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                      <Input
                        aria-label="Text input with dropdown button"
                        value={inputSearch}
                        name="inputSearch"
                        placeholder="search by"
                        onChange={this.onChangeHandler}
                      />
                    </div>
                    {/* <CustomSearch
                      style={{ marginRight: "10px" }}
                      options={filters}
                      change={this.onChangeHandler}
                      filterOption={filterOption}
                      inputSearch={inputSearch}
                    /> */}
                    <CustomDropDown
                      maxwidth={"130px"}
                      statusList={["all", "created", "pending", "cancelled"]}
                      status={statusSearch}
                      setStatus={this.setStatus}
                    />
                  </div>
                  {input_value &&
                    (statusSearch || from || to || inputSearch) && (
                      <div className="right_filter_container">
                        <Button_without_Border
                          click={this.handleReset}
                          btnLabel={"clear_filter"}
                        ></Button_without_Border>
                      </div>
                    )}
                </div>
              </div>
            </div>
            <Row>
              <div className="col new-table">
                <Card className="shadow card_with_paginator">
                  {invoiceList && invoiceList?.length ? (
                    <Table
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead className="thead-light">
                        <tr>
                          <th scope="col" style={{ paddingLeft: "30px" }}>
                            Invoice ID
                          </th>
                          <th scope="col">Property</th>
                          <th scope="col">Created On</th>
                          <th scope="col">Type</th>
                          {!isSub_Contractor() && (
                            <th scope="col">Total Amount</th>
                          )}
                          <th scope="col">Earnings</th>
                          <th scope="col">Status</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {invoiceList.map((invoice, i) => {
                          return (
                            <tr key={i}>
                              <td
                                className="text-capitalize cursor-point"
                                style={{ paddingLeft: "30px" }}
                                onClick={() =>
                                  this.toggleModal(invoice.invoice_id)
                                }
                              >
                                {returnFirstDegreeObjValue(
                                  invoice,
                                  "invoice_id"
                                )}
                              </td>
                              <td style={{ width: "150px" }}>
                                <div className="add_eclipse">
                                  {returnSecondDegreeObjValue(
                                    invoice,
                                    "property",
                                    "name"
                                  )}
                                </div>
                              </td>

                              <td>
                                {" "}
                                {showUtcDate(
                                  returnFirstDegreeObjValue(
                                    invoice,
                                    "createdAt"
                                  )
                                )}
                              </td>

                              <td
                                className="text-capitalize cursor-point"
                                onClick={() =>
                                  redirectToBookings(this, invoice.booking_id)
                                }
                              >
                                {returnFirstDegreeObjValue(invoice, "type")}
                              </td>
                              {isOutsideFlow(invoice.sub_contractor) &&
                              !isSub_Contractor() ? (
                                <td>N/A</td>
                              ) : (
                                !isSub_Contractor() && (
                                  <td>{displayPrice(invoice?.amount)}</td>
                                )
                              )}

                              <td>
                                <div className="flexStart point">
                                  <div className="mr-10">
                                    {!invoice.main_contractor_amount ||
                                    Object.keys(invoice.main_contractor_amount)
                                      .length === 0
                                      ? displayPrice(invoice.amount)
                                      : !isOutsideFlow(
                                          invoice.sub_contractor
                                        ) && !isSub_Contractor()
                                      ? displayPrice(
                                          parseFloat(invoice?.amount) -
                                            this.getInvoiceCommissionAmount(
                                              invoice,
                                              invoice?.amount,
                                              isSub_Contractor()
                                            )
                                        )
                                      : getCommissionAmount(
                                          invoice,
                                          invoice?.amount,
                                          isSub_Contractor()
                                        )}
                                  </div>
                                  <div>
                                    {this.returnCommissionTooltip(invoice)}
                                  </div>
                                </div>
                              </td>
                              <td
                                className={
                                  invoice.status === "paid"
                                    ? "text-capitalize green"
                                    : invoice.status === "created"
                                    ? "text-capitalize yellow"
                                    : invoice.status === "cancelled"
                                    ? "text-capitalize red"
                                    : "text-capitalize"
                                }
                                style={{ fontWeight: "500" }}
                              >
                                {returnFirstDegreeObjValue(invoice, "status")}
                              </td>
                              <td style={{ padding: "10px" }}>
                                <div className="flexStart">
                                  <ToolTip label="Timeline">
                                    <img
                                      src={timelineIcon}
                                      style={{ marginRight: "30px" }}
                                      className="point"
                                      onClick={() =>
                                        this.openTimeLineModal(
                                          invoice.invoice_id
                                        )
                                      }
                                    />
                                  </ToolTip>

                                  {invoice.status === "created" ||
                                  invoice.status === "paid" ? (
                                    <UncontrolledDropdown
                                      nav
                                      className="flexy-content position_u"
                                    >
                                      <DropdownToggle
                                        className="pr-0 nav-notice"
                                        nav
                                      >
                                        <Media className="align-items-center">
                                          <img src={showMore} />
                                        </Media>
                                      </DropdownToggle>
                                      <DropdownMenu
                                        className="dropdown-menu-arrow min-width-drop"
                                        right
                                      >
                                        {invoice.status === "created" && (
                                          <>
                                            <DropdownItem
                                              onClick={() =>
                                                this.openCancelInvoicePopup(
                                                  invoice.invoice_id
                                                )
                                              }
                                            >
                                              <span>Cancel</span>
                                            </DropdownItem>
                                            <DropdownItem
                                              onClick={() =>
                                                this.openMarkPaymentSideDragger(
                                                  invoice.invoice_id
                                                )
                                              }
                                            >
                                              <span>Mark Payment</span>
                                            </DropdownItem>
                                          </>
                                        )}
                                        <DropdownItem
                                          onClick={() =>
                                            this.downloadInvoice(
                                              invoice.invoice_id
                                            )
                                          }
                                        >
                                          <span>Download Invoice</span>
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                  ) : invoice.status === "cancelled" ? (
                                    <div
                                      data-tip={invoice?.cancel_reason}
                                      data-iscapture="true"
                                      data-for="toolTip1"
                                      data-place="top"
                                      className="new-border-request no_border mr-10 cladb5bd"
                                    >
                                      <i className="fa fa-info-circle verticalMidAlign"></i>
                                      <ReactTooltip
                                        place="top"
                                        type="info"
                                        effect="solid"
                                        id="toolTip1"
                                        html={true}
                                      />
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  ) : (
                    <>
                      {" "}
                      <Table
                        className="align-items-center table-flush"
                        responsive
                      >
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">Invoice ID</th>
                            <th scope="col">Property</th>
                            <th scope="col">Created On</th>
                            <th scope="col">Type</th>
                            <th scope="col">Total Amount</th>
                            <th scope="col">Earnings</th>
                            <th scope="col">Status</th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                      </Table>
                      <Card className="card_with_paginator no_data_card">
                        {" "}
                        <div className="no-data">No Invoices Available</div>
                      </Card>
                    </>
                  )}
                </Card>
              </div>
            </Row>
            <Row className="align-items-center header-div jus-space new-margin-page paginator_box">
              <div className="paginate-flex-box ">
                <div
                  className="paginate-box"
                  onClick={() => this.handleDecrementPage()}
                >
                  <p
                    className={
                      page !== 1 ? "page_available" : "page_unavailable"
                    }
                  >
                    Previous
                  </p>
                </div>
                <div
                  className="paginate-box"
                  onClick={() => this.handleIncrementPage()}
                >
                  <p
                    className={
                      invoiceList.length < 10
                        ? "page_unavailable"
                        : "page_available"
                    }
                  >
                    Next
                  </p>
                </div>
              </div>
              <Modal
                className="modal-dialog-centered modal-dialog-invoice"
                isOpen={modalOpen}
                toggle={() => this.toggleModal()}
              >
                <div className="bottom-line-invoice">
                  <div className="modal-header invoice-header">
                    <h5
                      className="modal-title invoice-title"
                      id="exampleModalLabel"
                    >
                      INVOICE
                    </h5>
                    <div>
                      <img src={Park} className="invoice-park" />
                    </div>
                  </div>
                </div>
                <div className="modal-body profile-body invoice-body">
                  <Row>
                    <Col lg="6" className="pad-left-invoice">
                      <FormGroup>
                        <label
                          className="label-invoice-prop mb-0 label-invoice"
                          htmlFor="input-username"
                        >
                          Property Details
                        </label>
                        <p className="prop-statsData-para">
                          {returnSecondDegreeObjValue(
                            invoiceDetail,
                            "property",
                            "name"
                          )}
                        </p>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <div className="modal-body profile-body invoice-body">
                  <Row>
                    <Col lg="12" className="pad-left-invoice mb-10">
                      <FormGroup className="flex-invoice flex-secondary-invoice">
                        <label
                          className="label-invoice-prop mb-0 label-invoice"
                          htmlFor="input-username"
                        >
                          Invoice ID
                        </label>
                        <label
                          className="label-invoice-id mb-0 label-invoice"
                          htmlFor="input-username"
                        >
                          {returnFirstDegreeObjValue(
                            invoiceDetail,
                            "invoice_id"
                          )}
                        </label>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12" className="pad-left-invoice">
                      <FormGroup className="flex-invoice ">
                        <label
                          className="label-invoice-prop mb-0 "
                          htmlFor="input-username"
                        >
                          Created On
                        </label>
                        <label
                          className="label-invoice-id mb-0 "
                          htmlFor="input-username"
                        >
                          {showUtcDate(
                            returnFirstDegreeObjValue(
                              invoiceDetail,
                              "createdAt"
                            )
                          )}
                        </label>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <div className="modal-body profile-body invoice-body">
                  <Row>
                    <Col lg="12" className="pad-left-invoice mb-10">
                      <FormGroup className="flex-invoice flex-secondary-invoice">
                        <label
                          className="label-invoice-prop mb-0 label-invoice"
                          htmlFor="input-username"
                        >
                          Description
                        </label>
                        <label
                          className="label-invoice-prop mb-0 label-invoice"
                          htmlFor="input-username"
                        >
                          Amount
                        </label>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12" className="pad-left-invoice">
                      {(isOutsideFlow(invoiceDetail.sub_contractor) &&
                        !isSub_Contractor()) ||
                      isSub_Contractor() ? (
                        <FormGroup className="flex-invoice">
                          <label
                            className="label-invoice-name mb-0 capitalize"
                            htmlFor="input-username"
                          >
                            {invoiceDetail?.type}
                          </label>
                          <label
                            className="label-invoice-name mb-0"
                            htmlFor="input-username"
                          >
                            {getCommissionAmount(
                              invoiceDetail,
                              invoiceDetail?.amount,
                              isSub_Contractor()
                            )}
                          </label>
                        </FormGroup>
                      ) : (
                        <>
                          {/* <label
                         style={{marginTop:"0"}}
                        className="label-invoice-prop mb-0 label-invoice"
                        htmlFor="input-username"
                      >
                   
                      </label> */}

                          <FormGroup className="flex-invoice ">
                            <label
                              className="label-invoice-name mb-0 capitalize"
                              htmlFor="input-username"
                            >
                              {invoiceDesc}
                            </label>
                            <label
                              className="label-invoice-name mb-0 "
                              htmlFor="input-username"
                            >
                              {this.viewInvoiceData(invoiceDetail)
                                ? this.viewInvoiceData(invoiceDetail)[0]
                                : invoiceDetail.amount}
                            </label>
                          </FormGroup>
                          {invoiceDetail?.sub_contractor?.id &&
                            invoiceDetail.main_contractor_amount?.type && (
                              <FormGroup className="flex-invoice ">
                                <label
                                  className="label-invoice-name mb-0 capitalize"
                                  htmlFor="input-username"
                                >
                                  Sub Contractor Amount
                                </label>
                                <label
                                  className="label-invoice-name mb-0 "
                                  htmlFor="input-username"
                                >
                                  {invoiceDetail.main_contractor_amount.type ===
                                  "percentage"
                                    ? displayPrice(
                                        invoiceDetail.amount *
                                          (invoiceDetail.main_contractor_amount
                                            ?.commission /
                                            100)
                                      )
                                    : displayPrice(
                                        invoiceDetail.main_contractor_amount
                                          ?.commission
                                      )}
                                </label>
                              </FormGroup>
                            )}
                        </>
                      )}
                    </Col>
                  </Row>
                </div>
                <div className="modal-body profile-body invoice-body">
                  <Row>
                    <Col lg="12" className="pad-left-invoice mt-10">
                      <FormGroup className="flex-invoice nav-last-panel">
                        <label
                          className="label-invoice-name mb-0 "
                          htmlFor="input-username"
                        >
                          Total
                        </label>
                        <label
                          className="label-invoice-name primary-color mb-0 "
                          htmlFor="input-username"
                        >
                          {(isOutsideFlow(invoiceDetail.sub_contractor) &&
                            !isSub_Contractor()) ||
                          isSub_Contractor()
                            ? getCommissionAmount(
                                invoiceDetail,
                                invoiceDetail?.amount,
                                isSub_Contractor()
                              )
                            : displayPrice(invoiceDetail.amount)}
                        </label>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                {/* </div> */}
              </Modal>
            </Row>
          </CustomListingContainer>

          <Card className={timeLineModal ? "exportSideBar" : "toggle-sidebar"}>
            {timeLineModal && (
              <TimeLine
                from="invoices"
                closeTimelineModal={this.closeTimeLineModal}
                id={invoiceId}
              />
            )}
          </Card>

          <Card
            style={{ zIndex: "1000" }}
            className={markPaymentModal ? "exportSideBar" : "toggle-sidebar"}
          >
            {markPaymentModal && (
              <MarkPaymentSideBar
                close={this.closeMarkPaymentSideDraggger}
                isOpen={markPaymentModal}
                toast={this.displayErrorMessage}
                id={invoiceId}
              />
            )}
          </Card>

          <Modal
            isOpen={cancelInvoiceModal}
            toggle={() => this.toggleCancelInvoiceModal()}
            centered
          >
            <CancelInvoiceModal
              close={this.toggleCancelInvoiceModal}
              id={invoiceId}
            />
          </Modal>
          <Card
            className={markPaymentModal ? "exportSideBar" : "toggle-sidebar"}
          >
            <MarkPaymentSideBar
              close={this.closeMarkPaymentSideDraggger}
              isOpen={markPaymentModal}
              toast={this.displayErrorMessage}
              id={invoiceId}
            />
          </Card>
        </div>
      </>
    );
  }
}

export default Invoices;
