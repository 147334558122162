import React, { Component, useState } from "react";
import "./AcceptOrReject.scss";
// import pkslogo from "../../../src/assets/img/brand/pkslogo.svg";
// import editIcon from "../../assets/img/brand/edit.svg";
import { Button, Spinner, Card, Table, Modal } from "reactstrap";
import EmailModal from "views/Crm/emailInputModal";
import { Button_without_Border } from "components/Buttons/Button";
import ToastList from "components/Toast/ToastList";
import { Custom_Button } from "components/Buttons/Button";
import { ListingBodyLayout } from "components/Container/CustomListingContainer";
import { CustomListingContainer } from "components/Container/CustomListingContainer";
import { ListingHeaderLayout } from "components/Container/CustomListingContainer";
import moment from "moment";
import ApiService from "constants/ApiService";
import { withRouter } from "react-router-dom";
import { getCloudinaryImage } from "constants/utils";
import defaultProfile from "../../assets/img/brand/defaultUser.svg";
import { showMomentFormat } from "constants/utils";
import { formatAmountWithCommas } from "constants/utils";
import EstimateAccepRejecttModal from "./estimateAcceptModalReject";
import StatusShowModal from "./statusShowModal";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { showUtcDate } from "constants/utils";
import { fixedAmount } from "constants/utils";
import { getUSFormat } from "constants/utils";
import CloseIcon from "../../assets/img/icons/closeIcon.svg";
import { remove_underscore_capitalize } from "constants/utils";
import { roundOff } from "constants/utils";
import { NumberWithCurrency } from "constants/utils";

class AcceptOrRejectProposal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      proposalSchema: {
        name: null,
        lead_id: null,
        email: null,
        phone: null,
        address: null,
        subject: null,
        items: [{ item_desc: "", item_name: "", rate: 0, quantity: 0 }],
        percentageSplitUp: 1,
        percentageValue: 0,
        dollarValue: 0,
        totalAmount: 0,
        splitups: [],
      },
      lead: {},
      remainingValue: 0,
      total: 0,
      emailModal: false,
      open: false,
      toast: false,
      response: "",
      // contractor_details: JSON.parse(localStorage.getItem("contractor_details"))
      // .contractor,
      toastType: "",
      isLoading: true,
      isOpenToggle2: true,
      isOpenToggle3: true,
      isOpenToggle: true,
      btnLoad: false,
      openPreview: false,
      addAddressBar: false,
      // estimate_lead_id: this.props?.location?.search?.split("=")[1] || "",
      estimate_lead_id: {},
      isOpenLead: false,
      lead_id: "",
      lead_List: [],
      openModal: false,
      cancelModal: false,
      address: "",
      estimate_id: "",
      items: [],
      itemList: [
        { item_name: "new_one", item_desc: "testing", quantity: 2, rate: 3 },
      ],
      listsLoad: false,
      listsBoxOpen: false,
      confirmation: false,
      apiData: {},
      subject: "",
      ConfirmationBtnLoad: false,
      content: "",
      tax: {},
      splitups: [],
      terms: "",
      proposalId: "",
      contractor: {},
      status: "",
      type: "",
      isStatusPopup: false,
      popupStatus: "",
      draft: false,
      reason: "",
      client_id: "",
      subtotal: "",
      descriptionModal: false,
      descIndex: {
        index: "",
        type: "",
        value: "",
      },
      head: [],
    };
    this.styles = {
      item_name: {
        width: "200px",
        paddingLeft: "25px",
        backgroundColor: "#FAFAFA",
      },
      item_desc: {
        padding: "15px 22px",
        width: "400px",
        backgroundColor: "#FAFAFA",
      },
      quantity: {
        padding: "15px 10px",
        width: "120px",
        textAlign: "center",
        backgroundColor: "#FAFAFA",
      },
      labor: {
        padding: "25px",
        width: "120px",
        textAlign: "end",
        backgroundColor: "#FAFAFA",
      },
      material: {
        padding: "25px",
        width: "120px",
        textAlign: "end",
        backgroundColor: "#FAFAFA",
      },
      markup: {
        padding: "25px",
        width: "120px",
        textAlign: "end",
        backgroundColor: "#FAFAFA",
      },
      cost: {
        padding: "25px",
        width: "120px",
        textAlign: "end",
        backgroundColor: "#FAFAFA",
      },
      amount: {
        padding: "25px",
        width: "120px",
        textAlign: "right",
        paddingRight: "1.6rem",
        backgroundColor: "#F7F8FA",
      },
    };
  }

  componentDidMount = async () => {
    const { id } = this.props.match.params;
    if (id) {
      this.setState({ proposalId: id }, () => {
        this.getEstimateDetails();
      });
    }
  };

  getEstimateDetails = async () => {
    try {
      // const { id } = this.state;
      const response = await ApiService.getAPI(
        `auth/contractor/estimate/${this?.state?.proposalId}`
      );
      if (response.success) {
        const selectedLead = response.data.estimate;
        // if (selectedLead.status === "draft") {
        //   this.setState({
        //     draft: true,
        //     isStatusPopup: true,
        //     popupStatus: "draft_no_response",
        //   });
        // }

        if (
          selectedLead.status === "accepted" ||
          selectedLead.status === "rejected"
        ) {
          this.setState({
            isStatusPopup: true,
            popupStatus:
              selectedLead.status === "accepted"
                ? "alreadyAccept"
                : "alreadyReject",
            isLoading: false,
            client_id: response?.data?.estimate?.client_id,
          });
        } else {
          const contractorDetails = response.data?.contractor;
          this.setState(
            {
              estimate: selectedLead,
              lead: response.data.lead,
              items: selectedLead.items,
              contractor: contractorDetails,
              splitups: response.data.splitup,
              tax: selectedLead.tax,
              subject: selectedLead.subject,
              terms: selectedLead.terms,
              itemsVisibility: selectedLead.itemsVisibility,
              itemsTotal: selectedLead.itemsTotal,
            },
            () => {
              const items = this.state.itemsVisibility;
              const head = Object.keys(items).filter((obj) => {
                return items[obj].is_enabled;
              });
              this.setState({
                head: head,
                isLoading: false,
                headLength: head.length,
              });
            }
          );
        }
      } else {
        this.setState({
          draft: true,
          isStatusPopup: true,
          popupStatus: "draft_no_response",
          isLoading: false,
        });
      }
    } catch (error) {}
  };

  estimateAccepRejecttModal = (value, data) => {
    this.setState({ openModal: true, type: value, status: "the estimate" });
  };

  toggleModal = () => {
    this.setState({ openModal: false });
    this.getEstimateDetails();
  };

  AcceptRejectEstimate = async (status, reason) => {
    let stats;
    if (status === "accept") stats = "accepted";
    else stats = "rejected";
    try {
      let data = {};

      if (stats === "rejected") {
        data = { reason: reason };
      }
      const response = await ApiService.patchAPI(
        `auth/contractor/estimate/${this?.state?.proposalId}/${stats}`,
        data
      );
      if (response.success) {
        this.setState({
          openModal: false,
          isStatusPopup: true,
          popupStatus: stats,
          client_id: response?.data?.client_id,
        });
      } else {
      }
    } catch (error) {}
  };

  formatAmountWithCommas = (amount) => {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  capitalizeFirstLetter = (str) => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  taxCalculater = () => {
    let { tax, items } = this.state;
    let taxValue = tax?.value || 0;
    if (items.length > 0 && tax && tax.type === "percentage" && tax.value) {
      let itemsCost = this.calculateTotalAmount(this.state.items);
      taxValue = itemsCost * (tax.value / 100);
    }
    return fixedAmount(taxValue);
  };
  calculateTotalAmount = (items) => {
    let totalAmount = 0;
    items.forEach((item) => {
      let amount = item.quantity * item.material + item.labor;
      totalAmount += amount;
    });
    return totalAmount;
  };

  Description = (index, type, value) => {
    // setTempItemDesc(value.item_desc);
    // descIndex({ index: index, type: type, value: value.item_desc });
    this.setState({
      descriptionModal: true,
      descIndex: { index: index, type: type, value: value.item_desc },
    });
  };

  render() {
    const lastIndex = this.state.head.length - 1;
    const secondLastIndex = this.state.head.length - 2;
    const thirdLastIndex = this.state.head.length - 3;
    let {
      isLoading,
      toast,
      response,
      toastType,
      subject,
      lead,
      items,
      contractor,
      estimate,
      type,
      proposalId,
      descriptionModal,
      descIndex,
      itemsTotal,
    } = this.state;
    // const item = items[0]
    // const head = Object.keys(item)
    return isLoading ? (
      <div className="spin-loading">
        <Spinner type="border" color="#077e8b" />
      </div>
    ) : !this.state.isStatusPopup && !isLoading ? (
      <>
        {toast && <ToastList message={response} type={toastType} />}

        <div
          style={{
            display: "flex",
            position: "relative",
            height: "72px",
            zIndex: "1",
          }}
        >
          <div
            style={{
              padding: "17px 40px 17px 0px",
              width: "100%",
              display: "flex",
              gap: "10px",
              justifyContent: "flex-end",
              alignItems: "center",
              paddingLeft: "",
              backgroundColor: "white",
            }}
          >
            <Custom_Button
              btnLabel={"Reject"}
              click={() => this.estimateAccepRejecttModal("reject", "")}
            />

            <Custom_Button
              isPrimary
              btnLabel={"Accept"}
              click={() => this.estimateAccepRejecttModal("accept", "")}
            />
          </div>
        </div>
        <div
          style={{
            padding: "40px 20px 0px 20px ",
            backgroundColor: "#fafafa",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingBottom: "150px",
          }}
        >
          <CustomListingContainer style={{ width: "82.5%" }}>
            {subject !== "" && (
              <ListingHeaderLayout
                className=""
                label={`${proposalId + " - " + subject}`}
                endKey={"Due on"}
                endData={`${showUtcDate(estimate.due_date)}`}
              ></ListingHeaderLayout>
            )}
            <ListingBodyLayout
              style={{ margin: "0" }}
              insideStyle={{ marginBottom: "0", width: "100%" }}
            >
              <div style={{ display: "flex", padding: "22px 24px 36px 24px" }}>
                <div
                  style={{
                    display: "flex",
                    gap: "12px",
                    alignItems: "flex-start",
                    width: "55%",
                  }}
                >
                  {!contractor?.image?.public_id ? (
                    <>
                      {" "}
                      <div className="">
                        <img
                          src={defaultProfile}
                          style={{
                            height: "6rem",
                            width: "7rem",
                            border: "1px solid #D9D9D9",
                          }}
                          alt="profile image"
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <img
                        style={{
                          height: "6rem",
                          width: "7rem",
                          border: "1px solid #D9D9D9",
                          objectFit: "contain",
                        }}
                        src={getCloudinaryImage(
                          120,
                          130,
                          contractor.image,
                          "c_fill"
                        )}
                        alt="profile image"
                      />
                    </>
                  )}
                  <div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "0.8rem",
                        width: "80%",
                        lineHeight: "inherit",
                      }}
                    >
                      <div className="bold_standard fw-500">
                        {contractor?.name}
                      </div>
                      <div className="font_standard fw-400">
                        {contractor?.email}
                      </div>
                      <div className="font_standard fw-400">
                        {contractor?.phone?.national_number
                          ? getUSFormat(contractor?.phone?.national_number)
                          : ""}
                      </div>
                      <div
                        style={{ whiteSpace: "pre-wrap", lineHeight: "1.5" }}
                        className="font_standard fw-400"
                      >
                        {contractor?.address?.line_1}
                      </div>
                    </div>
                  </div>
                </div>

                {Object.keys(lead)?.length > 0 && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1rem",
                      width: "45%",
                    }}
                  >
                    <div className="bold_standard fw-500">
                      {this.capitalizeFirstLetter(lead.name)}
                    </div>
                    <div className="font_standard fw-400">{lead.email}</div>
                    <div className="font_standard fw-400">
                      {lead.phone ? getUSFormat(lead.phone) : ""}
                    </div>
                    <div className="font_standard fw-400">
                      {lead?.address?.line1}
                    </div>
                  </div>
                )}
              </div>
              <div style={{ width: "100%" }}>
                <div className="col new-table" style={{ padding: "0" }}>
                  <div className="shadow estimate_card_with_paginator">
                    <div
                      className="flexy-content"
                      style={{
                        gap: "1rem",
                        padding: "0rem 1rem 1rem 1.5rem",
                        alignItems: "center",
                      }}
                    >
                      <div className="preview_heading_font">Items</div>
                      <div
                        onClick={this.toggleCollapse}
                        style={{ cursor: "pointer" }}
                      ></div>
                    </div>
                    <Table
                      className="align-items-center table-flush"
                      style={{ padding: "0" }}
                      responsive
                    >
                      <thead className="thead-light bordered-table">
                        <tr style={{ backgroundColor: "#FAFAFA" }}>
                          {this.state.head.map((str, index) => (
                            <th
                              className="estimate_table_font"
                              scope="col"
                              id="border_none"
                              style={this.styles[str]}
                            >
                              {remove_underscore_capitalize(str)}
                            </th>
                          ))}
                          {/* <th
                            className="estimate_table_font"
                            scope="col"
                            id="border_none"
                            style={{
                              width: "25%",
                              paddingLeft: "25px",
                              backgroundColor: "#FAFAFA",
                            }}
                          >
                            Item Name
                          </th>
                          <th
                            scope="col"
                            className="estimate_table_font"
                            id="border_none"
                            style={{
                              padding: "15px 16px",
                              width: "35%",
                              backgroundColor: "#FAFAFA",
                            }}
                          >
                            Item Desc
                          </th>
                          <th
                            scope="col"
                            className="estimate_table_font"
                            id="border_none"
                            style={{
                              padding: "",
                              width: "10%",
                              textAlign: "center",
                              backgroundColor: "#FAFAFA",
                            }}
                          >
                            Quantity
                          </th>
                          <th
                            scope="col"
                            className="estimate_table_font"
                            id="border_none"
                            style={{
                              padding: "15px 10px",
                              width: "10%",
                              textAlign: "end",
                              backgroundColor: "#FAFAFA",
                            }}
                          >
                            Labor
                          </th>

                          <th
                            scope="col"
                            id="border_none"
                            style={{
                              padding: "15px 10px",
                              width: "10%",
                              textAlign: "right",
                              paddingRight: "18px",
                              backgroundColor: "#F7F8FA",
                            }}
                            className="estimate_table_font"
                          >
                            Material
                          </th>

                          <th
                            scope="col"
                            id="border_none"
                            style={{
                              padding: "15px 10px",
                              width: "10%",
                              textAlign: "right",
                              paddingRight: "1.6rem",
                              backgroundColor: "#F7F8FA",
                            }}
                            className="estimate_table_font"
                          >
                            Markup
                          </th>

                          <th
                            scope="col"
                            id="border_none"
                            style={{
                              padding: "15px 10px",
                              width: "10%",
                              textAlign: "right",
                              paddingRight: "1.6rem",
                              backgroundColor: "#F7F8FA",
                            }}
                            className="estimate_table_font"
                          >
                            Cost
                          </th>

                          <th
                            scope="col"
                            id="border_none"
                            style={{
                              padding: "15px 10px",
                              width: "10%",
                              textAlign: "right",
                              paddingRight: "18px",
                              backgroundColor: "#F7F8FA",
                            }}
                            className="estimate_table_font"
                          >
                            Amount
                          </th> */}
                        </tr>
                      </thead>

                      {items && items.length > 0 ? (
                        <tbody>
                          {items.map((item, i) => {
                            return (
                              <tr
                                key={i}
                                style={{ borderBottom: "1px solid #f0f0f0" }}
                              >
                                {this.state.head.map((str, index) => (
                                  <>
                                    {str === "item_desc" && (
                                      <td
                                        id="bor_right fw-500 fs_14"
                                        style={{
                                          padding: "25px",
                                          color: "#212121",
                                        }}
                                        className="vertical_align_top"
                                      >
                                        <div
                                          style={{
                                            wordBreak: "break-all",
                                          }}
                                          className="project_list_content"
                                        >
                                          {item.item_desc?.replace(
                                            /(<([^>]+)>)/gi,
                                            ""
                                          ) ? (
                                            <div className="item_description">
                                              <div
                                                onClick={() =>
                                                  this.Description(
                                                    i,
                                                    "item_desc",
                                                    item
                                                  )
                                                }
                                                className="estimateClassName"
                                                style={{
                                                  whiteSpace: "pre-wrap",

                                                  color: "black",
                                                  fontSize: "14px",
                                                }}
                                                dangerouslySetInnerHTML={{
                                                  __html: item.item_desc,
                                                }}
                                              ></div>
                                            </div>
                                          ) : (
                                            <div style={{ color: "#8e8f99" }}>
                                              -
                                            </div>
                                          )}
                                        </div>
                                      </td>
                                    )}
                                    {str === "item_name" && (
                                      <td
                                        className="cursor-point capitalize vertical_align_top"
                                        id="bor_right"
                                        style={{ padding: "25px" }}
                                      >
                                        <div
                                          className="project_list_content fw-500 fs_13"
                                          style={{
                                            color: "#000000",
                                            display: "flex",
                                            alignItems: "center",
                                            color: "#212121",
                                            whiteSpace: "initial",
                                            wordBreak: "break-all",
                                            lineHeight: "23px",
                                          }}
                                        >
                                          {item.item_name}
                                        </div>
                                      </td>
                                    )}
                                    {str === "quantity" && (
                                      <td
                                        id="bor_right"
                                        style={{
                                          textAlign: "center",
                                          padding: "25px",
                                        }}
                                        className="vertical_align_top"
                                      >
                                        <div
                                          className="project_list_content  fw-500 fs_13"
                                          style={{
                                            color: "#212121",
                                            padding: "5px 0px",
                                          }}
                                        >
                                          {this.formatAmountWithCommas(
                                            item.quantity
                                          )}
                                        </div>
                                      </td>
                                    )}
                                    {str === "labor" && (
                                      <td
                                        id="bor_right"
                                        style={{
                                          textAlign: "center",
                                          padding: "25px",
                                        }}
                                        className="vertical_align_top"
                                      >
                                        <div
                                          className="project_list_content  fw-500 fs_13"
                                          style={{
                                            textAlign: "center",
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            alignItems: "center",
                                            textAlign: "end",
                                            padding: "4px 0px",
                                          }}
                                        >
                                          <div
                                            style={{
                                              maxWidth: "100px",
                                              overflowX: "auto",
                                              whiteSpace: "nowrap",
                                              color: "#212121",
                                              fontWeight: "500",
                                              fontSize: "13px",
                                              marginLeft: "5px",
                                            }}
                                          >
                                            {item.labor
                                              ? NumberWithCurrency(+item.labor)
                                              : "0"}
                                          </div>
                                        </div>
                                      </td>
                                    )}
                                    {str === "material" && (
                                      <td
                                        id="bor_right"
                                        style={{
                                          padding: "25px",
                                        }}
                                        className="vertical_align_top"
                                      >
                                        <div
                                          className="project_list_content  fw-500 fs_13"
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "flex-end",
                                            color: "#212121",
                                            padding: "4px 0px",
                                          }}
                                        >
                                          <div
                                            style={{
                                              maxWidth: "100px",
                                              overflowX: "auto",
                                              whiteSpace: "nowrap",
                                              color: "#212121",
                                              fontWeight: "500",
                                              fontSize: "13px",
                                              marginLeft: "5px",
                                            }}
                                          >
                                            {item.material
                                              ? NumberWithCurrency(
                                                  +item.material
                                                )
                                              : "0"}
                                          </div>
                                        </div>
                                      </td>
                                    )}
                                    {str === "cost" && (
                                      <td
                                        id="bor_right"
                                        style={{
                                          padding: "25px",
                                          textAlign: "right",
                                        }}
                                        className="vertical_align_top"
                                      >
                                        <div
                                          className="project_list_content  fw-500 fs_13"
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "flex-end",
                                            color: "#212121",
                                            padding: "4px 0px",
                                          }}
                                        >
                                          <div
                                            style={{
                                              maxWidth: "100px",
                                              overflowX: "auto",
                                              whiteSpace: "nowrap",
                                              color: "#212121",
                                              fontWeight: "500",
                                              fontSize: "13px",
                                              marginLeft: "5px",
                                            }}
                                          >
                                            {item.cost
                                              ? NumberWithCurrency(+item.cost)
                                              : "0"}
                                          </div>
                                        </div>
                                      </td>
                                    )}
                                    {str === "markup" && (
                                      <td
                                        id="bor_right"
                                        style={{
                                          padding: "25px",
                                          textAlign: "right",
                                        }}
                                        className="vertical_align_top"
                                      >
                                        <div
                                          className="project_list_content  fw-500 fs_13"
                                          style={{
                                            color: "#212121",
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            alignItems: "center",
                                            padding: "4px 0px",
                                          }}
                                        >
                                          <div
                                            style={{
                                              maxWidth: "100px",
                                              overflowX: "auto",
                                              whiteSpace: "nowrap",
                                              color: "#212121",
                                              fontWeight: "500",
                                              fontSize: "13px",
                                              marginLeft: "5px",
                                            }}
                                          >
                                            {NumberWithCurrency(+item.markup)}
                                          </div>
                                        </div>
                                      </td>
                                    )}
                                    {str === "amount" && (
                                      <td
                                        id="bor_right"
                                        className="vertical_align_top"
                                        style={{
                                          border: "0",
                                          textAlign: "end",
                                          padding: "25px",
                                        }}
                                      >
                                        <div
                                          className="fw-500 fs_13"
                                          style={{
                                            color: "#212121",
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            alignItems: "center",
                                          }}
                                        >
                                          <div
                                            style={{
                                              maxWidth: "100px",
                                              overflowX: "auto",
                                              whiteSpace: "nowrap",
                                              color: "#212121",
                                              fontWeight: "500",
                                              fontSize: "13px",
                                              marginLeft: "5px",
                                            }}
                                          >
                                            {NumberWithCurrency(
                                              roundOff(item.amount).toFixed(
                                                2
                                              ) || "0"
                                            )}
                                          </div>
                                        </div>
                                      </td>
                                    )}
                                  </>
                                ))}
                              </tr>
                              // <tr
                              //   key={i}
                              //   style={{ borderBottom: "1px solid #E1E8F0" }}
                              // >
                              //   <td
                              //     className="cursor-point capitalize vertical_align_top"
                              //     id="bor_right"
                              //     style={{
                              //       paddingTop: "19px",
                              //       paddingBottom: "19px",
                              //       borderBottom: "1px solid #E1E8F0",
                              //     }}
                              //     // onClick={() =>
                              //     //     this.redirectDetailPage(project.project_id)
                              //     // }
                              //   >
                              //     <div
                              //       className=" fw-500 fs_13"
                              //       style={{
                              //         color: "#000000",
                              //         display: "flex",
                              //         alignItems: "center",
                              //         paddingLeft: "6px",
                              //         color: "#212121",
                              //         width: "100%",
                              //         wordBreak: "break-all",
                              //         whiteSpace: "initial",
                              //       }}
                              //     >
                              //       {this.capitalizeFirstLetter(
                              //         item?.item_name
                              //       )}
                              //     </div>
                              //   </td>
                              //   <td
                              //     id="bor_right fw-500 fs_13"
                              //     style={{
                              //       paddingTop: "19px",
                              //       paddingBottom: "19px",
                              //       color: "#212121",
                              //       borderBottom: "1px solid #E1E8F0",
                              //     }}
                              //     className="vertical_align_top"
                              //   >
                              //     {/* <div
                              //           className=" "
                              //           style={{
                              //             color: "#000000",
                              //             wordBreak: "break-all",
                              //             width: "100%",
                              //             whiteSpace: "initial",
                              //             paddingLeft: "6px",
                              //           }}
                              //         >
                              //           {this.capitalizeFirstLetter(item?.item_desc)}
                              //         </div> */}
                              //     <div
                              //       onClick={() =>
                              //         this.Description(i, "item_desc", item)
                              //       }
                              //       className="estimateClassName"
                              //       style={{
                              //         whiteSpace: "pre-wrap",
                              //         // maxHeight: "100px",
                              //         // overflowY: "scroll",
                              //       }}
                              //       dangerouslySetInnerHTML={{
                              //         __html: item.item_desc,
                              //       }}
                              //     ></div>
                              //   </td>
                              //   <td
                              //     id="bor_right"
                              //     style={{
                              //       borderBottom: "1px solid #E1E8F0",

                              //       paddingTop: "19px",
                              //       paddingBottom: "19px",
                              //       textAlign: "center",
                              //     }}
                              //     className="vertical_align_top"
                              //   >
                              //     <div
                              //       className="project_list_content  fw-500 fs_13"
                              //       style={{ color: "#212121" }}
                              //     >
                              //       {item?.quantity}
                              //     </div>
                              //   </td>

                              //   <td
                              //     id="bor_right"
                              //     style={{
                              //       borderBottom: "1px solid #E1E8F0",
                              //       paddingTop: "19px",
                              //       paddingBottom: "19px",
                              //     }}
                              //     className="vertical_align_top"
                              //   >
                              //     <div
                              //       className="  fw-500 fs_13"
                              //       style={{
                              //         display: "flex",
                              //         alignItems: "center",
                              //         justifyContent: "flex-end",

                              //         color: "#212121",
                              //       }}
                              //     >
                              //       {item?.labor
                              //         ? "$" +
                              //           formatAmountWithCommas(item?.labor)
                              //         : ""}
                              //     </div>
                              //   </td>

                              //   <td
                              //     id="bor_right"
                              //     className=" fw-500 fs_13 vertical_align_top"
                              //     style={{
                              //       // display: "flex",
                              //       // alignItems: "end",
                              //       // borderTop: "none",
                              //       // paddingTop: "19px", paddingBottom:'19px',
                              //       // border: "0",
                              //       // justifyContent: 'end',
                              //       // paddingRight: "22px",
                              //       // color: '#212121',

                              //       // gap: "5%",

                              //       border: "0",
                              //       textAlign: "end",
                              //       paddingRight: "22px",
                              //     }}
                              //   >
                              //     {item.material
                              //       ? "$" +
                              //         formatAmountWithCommas(item?.material)
                              //       : ""}
                              //   </td>
                              //   {items?.some((item) => item.markup) ? (
                              //     item?.markup ? (
                              //       <td
                              //         id="bor_right"
                              //         className=" fw-500 fs_13"
                              //         style={{
                              //           border: "0",
                              //           textAlign: "end",
                              //           paddingRight: "22px",
                              //         }}
                              //       >
                              //         {item?.cost
                              //           ? "$" +
                              //             formatAmountWithCommas(item?.cost)
                              //           : ""}
                              //       </td>
                              //     ) : (
                              //       <td
                              //         id="bor_right"
                              //         className=" fw-500 fs_13"
                              //         style={{
                              //           border: "0",
                              //           textAlign: "end",
                              //           paddingRight: "22px",
                              //         }}
                              //       >
                              //         <span style={{ marginTop: "0.88rem" }}>
                              //           $
                              //         </span>
                              //         0
                              //       </td>
                              //     )
                              //   ) : null}

                              //   <td
                              //     id="bor_right"
                              //     className=" fw-500 fs_13 vertical_align_top"
                              //     style={{
                              //       border: "0",
                              //       textAlign: "end",
                              //       paddingRight: "22px",
                              //     }}
                              //   >
                              //     <span style={{ marginTop: "0.88rem" }}>
                              //       $
                              //     </span>

                              //     {formatAmountWithCommas(
                              //       fixedAmount(
                              //         item?.quantity * item?.material +
                              //           item?.labor
                              //       )
                              //     )}
                              //   </td>
                              // </tr>
                            );
                          })}
                        </tbody>
                      ) : (
                        <tbody>
                          <tr>
                            <td></td>
                            <td
                              className="no-data"
                              style={{ textAlign: "right" }}
                            >
                              No Item Available
                            </td>
                            <td></td>

                            <td></td>
                            <td></td>
                          </tr>
                        </tbody>
                      )}
                    </Table>
                    <div className="previewBottomTable">
                      <table>
                        <thead>
                          <th style={this.styles[3]}></th>
                          <th style={this.styles[3]}></th>
                          <th style={this.styles[3]}></th>
                        </thead>
                        <tbody>
                          <tr style={{ border: "none" }}>
                            <td
                              style={{
                                border: "none",
                                textAlign: "center",
                                color: "#6A7785",
                                paddingRight: "1rem",
                              }}
                              className="fw-600 fs_13"
                            >
                              Subtotal
                            </td>
                            <td
                              className="fw-500 fs_13"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                height: "52px",
                                justifyContent: "end",
                                color: "#212121",
                                border: "none",
                                paddingRight: "1.6rem",
                                // gap: "5%",
                              }}
                            >
                              <div
                                style={{
                                  maxWidth: "100px",
                                  overflowX: "auto",
                                  whiteSpace: "nowrap",
                                  color: "#212121",
                                  fontWeight: "600",
                                  fontSize: "13px",
                                }}
                              >
                                {NumberWithCurrency(
                                  Number(itemsTotal.subTotalAmount).toFixed(2)
                                )}
                              </div>
                            </td>
                          </tr>
                          <tr style={{ border: "none" }}>
                            <td
                              style={{
                                border: "none",
                                textAlign: "center",
                                color: "#6A7785",
                              }}
                              className="fw-600 fs_13"
                            >
                              Tax
                              <span style={{ marginLeft: "10px" }}>
                                {this.state.tax.value &&
                                  this.state.tax.type === "fixed" &&
                                  "$"}
                                {this.state.tax.value}
                                {this.state.tax.value &&
                                  this.state.tax.type === "percentage" &&
                                  "%"}
                              </span>
                            </td>
                            <td
                              style={{
                                display: "flex",
                                alignItems: "center",
                                borderTop: "none",
                                height: "52px",
                                justifyContent: "end",
                                color: "#212121",
                                paddingRight: "1.6rem",
                                // gap: "5%",
                              }}
                              className="fw-500 fs_13"
                            >
                              <div
                                style={{
                                  maxWidth: "100px",
                                  overflowX: "auto",
                                  whiteSpace: "nowrap",
                                  color: "#212121",
                                  fontWeight: "600",
                                  fontSize: "13px",
                                }}
                              >
                                {NumberWithCurrency(
                                  +itemsTotal.taxAmount.toFixed(2)
                                )}
                              </div>
                            </td>
                          </tr>
                          <tr style={{ border: "none" }}>
                            <td
                              className="fw-600 fs_13 "
                              style={{
                                backgroundColor: "#F7F8FA",
                                border: "none",
                                textAlign: "center",
                                color: "#212121",
                                paddingRight: "2.3rem",
                              }}
                            >
                              Total
                            </td>
                            <td
                              className="fw-600 fs_13 "
                              style={{
                                display: "flex",
                                alignItems: "center",
                                borderTop: "none",
                                height: "52px",
                                justifyContent: "end",
                                backgroundColor: "#F7F8FA",
                                border: "none",
                                color: "#212121",
                                paddingRight: "1.6rem",
                                // gap: "5%",
                              }}
                            >
                              <div
                                style={{
                                  maxWidth: "100px",
                                  overflowX: "auto",
                                  whiteSpace: "nowrap",
                                  color: "#212121",
                                  fontWeight: "600",
                                  fontSize: "13px",
                                }}
                              >
                                {NumberWithCurrency(
                                  roundOff(+itemsTotal.totalAmount).toFixed(2)
                                )}
                              </div>
                            </td>
                            {/* {this.state.head.map((str, index) =>
                            index === thirdLastIndex ? (
                              <td
                                className="fw-600 fs_13 "
                                style={{
                                  backgroundColor: "#F7F8FA",
                                  border: "none",
                                  textAlign: "center",
                                  color: "#212121",
                                  paddingRight: "2.3rem",
                                }}
                              >
                                Total
                              </td>
                            ) : index === secondLastIndex ? (
                              <td
                                style={{
                                  backgroundColor: "#F7F8FA",
                                  border: "none",
                                }}
                              ></td>
                            ) : index === lastIndex ? (
                              <td
                                className="fw-600 fs_13 "
                                style={{
                                  display: "flex",
                                  alignItems: "end",
                                  borderTop: "none",
                                  height: "52px",
                                  justifyContent: "end",
                                  backgroundColor: "#F7F8FA",
                                  border: "none",
                                  color: "#212121",
                                  paddingRight: "1.6rem",
                                  // gap: "5%",
                                }}
                              >
                                $
                                {this.formatAmountWithCommas(
                                  roundOff(itemsTotal.totalAmount).toFixed(2)
                                )}
                              </td>
                            ) : (
                              <td style={{ border: "none" }}></td>
                            )
                          )} */}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              {this.state.splitups?.length > 0 && (
                <div>
                  <div
                    style={{
                      borderBottom: "1px solid #E1E8F0",
                      padding: "1rem 1rem 1rem 1.5rem",
                    }}
                    className="preview_heading_font"
                  >
                    Payments
                  </div>

                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns:
                        "repeat(auto-fill, minmax(200px, 1fr))", // Corrected syntax
                      padding: "20px 25px 70px 25px ",
                      gap: "16px",
                    }}
                  >
                    {this.state.splitups?.length > 0 &&
                      this.state.splitups.map((splitup, index) => (
                        <div className="payment_box" key={index}>
                          <div
                            className="payment_box_head1  preview_font"
                            style={{ color: "#212121" }}
                          >
                            {/* {Number.isInteger(splitup.amount.amount)
                              ? fixedAmount(splitup.amount.amount)
                              : parseFloat(splitup.amount.amount).toFixed(2)} */}
                              {NumberWithCurrency(splitup.amount.amount.toFixed(2))}
                          </div>
                          <div className="payment_box_body">
                            <span className="mt_1 preview_font_sub">
                              {this.capitalizeFirstLetter(splitup.name)}
                            </span>
                            <div
                              className="font_standard"
                              style={{
                                fontWeight: "300",
                                color: "#212112",
                                marginTop: "8px",
                              }}
                            >
                              Due : {showUtcDate(splitup.due_date)}
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              )}
              {this.state.terms && (
                <div style={{ width: "100%", marginBottom: "5rem" }}>
                  <div
                    style={{
                      borderBottom: "1px solid #E1E8F0",
                      padding: "0rem 0rem 19px 24px",
                    }}
                    className="preview_heading_font"
                  >
                    Terms and conditions
                  </div>
                  <div
                    style={{
                      padding: "25px",
                      fontSize: "13px",
                      fontWeight: "400",
                      color: "#212112",
                      lineHeight: "25px",
                      wordBreak: "break-all",
                    }}
                  >
                    <div
                      className="estimateClassName scrollbar_design"
                      style={{
                        whiteSpace: "pre-wrap",
                        maxHeight: "650px",
                        overflowY: "scroll",
                        color: "black",
                        fontSize: "14px",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: this.state.terms,
                      }}
                    ></div>
                  </div>
                </div>
              )}
            </ListingBodyLayout>
          </CustomListingContainer>
        </div>
        {descriptionModal && (
          <Modal
            isOpen={descriptionModal}
            centered="true"
            style={{ minWidth: "50rem", padding: "2rem" }}
            toggle={() => {
              this.setState({ descriptionModal: false });
            }}
            backdrop={false}
          >
            <div style={{ padding: "24px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  cursor: "pointer",
                  marginBottom: "15px",
                }}
              >
                <div>
                  <span style={{ color: "#7045B0" }}>Description</span>
                </div>
                <div>
                  <img
                    width={20}
                    height={20}
                    onClick={() => this.setState({ descriptionModal: false })}
                    src={CloseIcon}
                    alt="closeIcon"
                  ></img>
                </div>
              </div>

              <div className="popUpDescription descTextBox">
                <div
                  className="estimateClassName scrollbar_design"
                  style={{
                    whiteSpace: "pre-wrap",
                    maxHeight: "650px",
                    overflowY: "scroll",
                    color: "black",
                    fontSize: "14px",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: descIndex.value,
                  }}
                ></div>
              </div>
            </div>
          </Modal>
        )}
        <Card
          className={this.state.emailModal ? "exportSideBar" : "toggle-sidebar"}
        >
          {this.state.emailModal && (
            <>
              <EmailModal
                props={this}
                sendData={this.receivedData}
                close={this.onClose}
                cancel={() => this.setState({ emailModal: false })}
              ></EmailModal>
            </>
          )}

          {this.state.openModal && (
            <EstimateAccepRejecttModal
              props={this}
              toggle={() => this.toggleModal()}
              isOpen={this.state.openModal}
              subject={type}
              estimate={this?.state?.status}
              action={(reason) => {
                this.AcceptRejectEstimate(type, reason);
              }}
              // sendToParent={this.setState(reason)}
            ></EstimateAccepRejecttModal>
          )}
        </Card>
      </>
    ) : (
      <StatusShowModal
        props={this}
        // toggle={() => this.toggleModal()}
        isOpen={this?.state?.isStatusPopup}
        status={this?.state?.popupStatus}
        client_id={this?.state?.client_id}
      ></StatusShowModal>
    );
  }
}
export default AcceptOrRejectProposal;
