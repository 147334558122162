import React from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

function CustomSpinner({color = "#2e1a47"}) {
  return (
    <div>
      <Spin
        style={{ color: color }}
        indicator={
          <LoadingOutlined
            className="custom_antd_spinner"
            style={{
              fontSize: 26,
            }}
          />
        }
      />
    </div>
  );
}

export default CustomSpinner;
