//Constants
import Constants from "./Env";
import { redirectLoginPage, getAuthToken } from "./utils";

class APIService {
  signInUrl = Constants.BASE_URL + "host/img_sign";
  cloudinary_base_url = "https://api.cloudinary.com/v1_1/";
  response_url = "http://res.cloudinary.com/parknstays/image/upload";
  constructor() {
    let token = getAuthToken();
    this.headersAuthorization = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
      // "x-consumer-username": token,
    };
  }
  triggerResponse = async (response) => {
    if (response.status === 401) redirectLoginPage();
    return await response.json();
  };

  createInstance(method, data, signal = null) {
    return {
      method,
      headers: {
        ...this.headersAuthorization, 
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
      signal, // Include the signal in the request options
    };
  
  }

  uploadInstance(method, data) {
    return {
      method,
      headers: { Authorization: "Bearer " + getAuthToken() },
      body: data,
    };
  }

  uploadDoc = async (url, data) => {
    const response = await fetch(
      Constants.BASE_URL + url,
      this.uploadInstance("POST", data)
    );
    return await response.json();
  };

  getAPI = async (url) => {
    const response = await fetch(
      Constants.BASE_URL + url,
      this.createInstance("GET")
    );
    return this.triggerResponse(response);
  };

  dowloadImage = async (url) => {
    await fetch(Constants.BASE_URL + url, this.createInstance("GET"));
  };

  postAPI = async (url, data) => {
    const response = await fetch(
      Constants.BASE_URL + url,
      this.createInstance("POST", data)
    );
    return this.triggerResponse(response);
  };

  patchAPI = async (url, data, signal = null) => {
    const response = await fetch(
      Constants.BASE_URL + url,
      this.createInstance("PATCH", data)
    );
    return this.triggerResponse(response);
    // try {
    //   const options = this.createInstance("PATCH", data, signal);
    //   const response = await fetch(Constants.BASE_URL + url, options);
    //   return this.triggerResponse(response);
    // } catch (error) {
    //   if (error.name === "AbortError") {
    //   } else {
    //     console.error("Fetch error:", error);
    //   }
    //   throw error; // Re-throw the error if needed
    // }
  };

  deleteAPI = async (url) => {
    const response = await fetch(
      Constants.BASE_URL + url,
      this.createInstance("DELETE")
    );
    return this.triggerResponse(response);
  };
}

export default new APIService();
