import React, { Component } from "react";
import ApiService from "constants/ApiService";
import {
  filterQuery,
  returnThirdDegreeObjValue,
  returnFirstDegreeObjValue,
  showLocaldate,
  displayPrice,
} from "constants/utils";

import {
  Card,
  CardBody,
  CardHeader,
  Table,
  Spinner,
  Row,
  Col,
} from "reactstrap";
import { displayPercentage } from "constants/utils";
import closeIcon from "../../assets/img/icons/white_close_icon.svg";
import returnSecondDegreeObjValue from "constants/utils";
import { showUtcDate } from "constants/utils";

class PayoutSideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invoiceDetail: [],
      page: 1,
      invoiceStatus: "",
      tableLoad: false,
    };
  }

  componentDidUpdate(prevProps) {
    let { data, status } = this.props;
  }

  getInvoiceDetail = async () => {
    let { data } = this.props;
    let { page, invoiceStatus } = this.state;
    let id = data.property_id;
  };

  closeModal = () => {
    this.setState({ invoiceDetail: [], tableLoad: false });
    this.props.close();
  };

  setStatus = (status) => {
    this.setState({ invoiceStatus: status, tableLoad: true, page: 1 }, () => {
      this.getInvoiceDetail();
    });
  };

  handleIncrementPage = () => {
    if (this.state.invoiceDetail.length >= 10) {
      this.setState({ page: this.state.page + 1 }, () => {
        this.getInvoiceDetail();
      });
    }
  };

  handleDecrementPage = () => {
    if (this.state.page !== 1) {
      this.setState({ page: this.state.page - 1 }, () => {
        this.getInvoiceDetail();
      });
    }
  };

  render() {
    let { invoiceDetail, invoiceStatus, tableLoad, page } = this.state;
    let { data } = this.props;
    return (
      <>
        {this.props.isOpen && (
          <div>
            <Card>
              <CardHeader>
                <div className="flex">
                  <div className="fw-500 f16 clffffff">Details</div>
                  {/* <button
                onClick={() => this.closeModal()}
                className="close fw-500 cl000000"
              >
                X
              </button> */}
                  <img
                    src={closeIcon}
                    onClick={() => this.closeModal()}
                    className="point"
                  />
                </div>
              </CardHeader>
              <CardBody className="pad30p">
                <div className="gridThreeColumns grGap30">
                  <div className="flex-column">
                    <div className="f14 fw-400 cl757575 mb-15">Created On</div>
                    <div className="fw-500 cl303030 f15">
                      {showUtcDate(data?.createdAt)}
                    </div>
                  </div>

                  <div className="flex-column">
                    <div className="f14 fw-400 cl757575 mb-15">
                      Overall Amount
                    </div>
                    <div className="fw-500 cl303030 f15">
                      {displayPrice(data?.overall_amount)}
                    </div>
                  </div>
                  <div className="flex-column">
                    <div className="f14 fw-400 cl757575 mb-15">Commission</div>
                    <div className="fw-500 cl303030 f15">
                      {data?.commission?.type === "percentage" ? (
                        <>
                          {displayPrice(
                            returnSecondDegreeObjValue(
                              data,
                              "commission",
                              "amount"
                            )
                          )}{" "}
                          (
                          {displayPercentage(
                            returnSecondDegreeObjValue(
                              data,
                              "commission",
                              "value"
                            )
                          )}
                          )
                        </>
                      ) : (
                        displayPrice(
                          returnSecondDegreeObjValue(
                            data,
                            "commission",
                            "amount"
                          )
                        )
                      )}
                    </div>
                  </div>
                  <div className="flex-column">
                    <div className="f14 fw-400 cl757575 mb-15">Settlement</div>
                    <div className="fw-500 cl303030 f15">
                      {displayPrice(data?.amount)}
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
            <Card className="shadow card_with_paginator">
              {tableLoad ? (
                <div className="spin-loading3 mt-30">
                  <Spinner type="border" color="#0A8080" />
                </div>
              ) : (
                <div>
                  {data?.items?.length !== 0 ? (
                    <div>
                      <Table
                        className="align-items-center table-flush"
                        responsive
                      >
                        <thead className="thead-light">
                          <tr>
                            <th scope="col"></th>
                            <th scope="col">Type</th>
                            <th scope="col">Amount</th>
                          </tr>
                        </thead>

                        <tbody>
                          <>
                            {data?.items?.map((item, i) => (
                              <tr key={i}>
                                <td className="text-capitalize cursor-point">
                                  {returnFirstDegreeObjValue(
                                    item,
                                    "invoice_id"
                                  )}
                                </td>
                                <td className="text-capitalize cursor-point">
                                  {returnFirstDegreeObjValue(item, "type")}
                                </td>

                                <td>
                                  {" "}
                                  {returnFirstDegreeObjValue(item, "amount") !==
                                    "N/A"
                                    ? displayPrice(
                                      returnFirstDegreeObjValue(
                                        item,
                                        "amount"
                                      )
                                    )
                                    : 0}
                                </td>
                              </tr>
                            ))}
                          </>
                        </tbody>
                      </Table>
                    </div>
                  ) : (
                    <div>
                      {" "}
                      <Table
                        className="align-items-center table-flush"
                        responsive
                      >
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">Customer</th>
                            <th scope="col">Type</th>
                            <th scope="col">Amount</th>
                          </tr>
                        </thead>
                      </Table>
                      <Card className="card_with_paginator no_data_card">
                        {" "}
                        <div className="no-data">No Invoices Available</div>
                      </Card>
                    </div>
                  )}
                  <>
                    {data?.items && (
                      <div className="flexEnd paginator_box bbNone ">
                        <div
                          className="paginate-box"
                          onClick={() => this.handleDecrementPage()}
                        >
                          <p
                            className={
                              page !== 1 ? "page_available" : "page_unavailable"
                            }
                          >
                            Previous
                          </p>
                        </div>
                        <div
                          className="paginate-box"
                          onClick={() => this.handleIncrementPage()}
                        >
                          <p
                            className={
                              data?.items?.length < 10
                                ? "page_unavailable"
                                : "page_available"
                            }
                          >
                            Next
                          </p>
                        </div>
                      </div>
                    )}
                  </>
                </div>
              )}
            </Card>
          </div>
        )}
      </>
    );
  }
}

export default PayoutSideBar;
