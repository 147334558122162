import React, { Component, useEffect, useState } from "react";
import closeIcon from "../../../assets/img/icons/white_close_icon.svg";
import file from "../../../assets/img/brand/uploadfile.svg";
import Line from "../../../assets/img/brand/removeIcon.svg";
import docFile from "../../../assets/img/brand/docfile.svg";

import {
    Card,
    CardBody,
    CardHeader,
    Table,
    Spinner,
    Row,
    Col,
    UncontrolledDropdown,
    DropdownToggle,
    Media,
    DropdownMenu,
    DropdownItem,
    InputGroup,
    InputGroupText,
    Button,
    Input,
} from "reactstrap";
import { Button_without_Border } from "components/Buttons/Button";
import { CustomListingContainer } from "components/Container/CustomListingContainer";
import { Custom_Button } from "components/Buttons/Button";
import { getCloudinaryImage } from "constants/utils";
import { showError } from "constants/utils";
import { uploadCloudinary } from "constants/utils";
import ToastList from "components/Toast/ToastList";
import ApiService from "constants/ApiService";
import { showSuccess } from "constants/utils";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

export function AddDocumentSidebar({
    document,
    handleChange,
    title,
    fileSizeType,
    name,
    disabled,
    close,
    props,
    project_id,
    completed
}) {
    let checkboxes = [{ name: "Client", key: "client", isChecked: false }, { name: "Team", key: "team", isChecked: false },
    { name: "Other Pros", key: "otherPros", isChecked: false }]
    const [fileData, setFileData] = useState({});
    const [visibilities, setVisibilities] = useState([]);
    const [checkitems, setCheckitems] = useState(checkboxes);
    const [allChecked, setAllChecked] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [btnLoading, setBtnLoading] = useState(false)
    const [image, setImage] = useState({})

    useEffect(() => {
        if (!checkitems.some((x) => !x.isChecked)) {
            setAllChecked(true)
        }
    }, [checkitems])

    function handleAll(i, type, isAll) {
        if (type !== "all" && allChecked) {
            setAllChecked(!allChecked)
        }
        else if (type === "all" && allChecked) {
            setAllChecked(!allChecked)
        }
        type === 'all' && i === true ? checkitems.map((x) => x.isChecked = true) :
            type === 'all' && i === false ? checkitems.map((x) => x.isChecked = false) :
                checkitems[i].isChecked = !checkitems[i].isChecked
        setCheckitems([...checkitems])
        getVisibilities()
    }

    const onImageChangeHandler = async (e) => {
        let file = e.target.files[0];
        e.target.value = null;
        setFileData(file)
        if (!["image/jpg", "image/jpeg", "image/png", "application/pdf", "application/xlsx",
            "application/xlx", "application/doc", "application/docx", "application/docs"].includes(file?.type)
        ) {
            showError(props, "Please upload only jpg, jpeg, png, pdf, xlsx, xlx, doc, docx or docs files only");
        } else if (file.size > 10 * 1000000) {
            showError(props, "File should be less than 10 MB");
        } else {
            setIsLoading(true)
            let data = await uploadCloudinary(file, "addDocument")
            if (data.url) {
                setImage({
                    format: data.format,
                    public_id: data.public_id,
                    version: `v${data.version}`,
                    type: data.type,
                    name: data.original_filename
                })
            } else {
                showError(props, "Could not upload image");
            }
            setIsLoading(false)

        }
    };
    const removeFile = () => {
        setImage({})
    }
    const getVisibilities = () => {
        let array = [];
        checkitems.map((item) => {
            if (item.isChecked) array.push(item.key)
        })
        setVisibilities(array)
        return array;
    }
    const onSubmit = async () => {
        if (!image.format) {
            showError(props, "Please upload document");
        } else if (visibilities.length === 0) {
            showError(props, "Please select the visibility");
        } else {
            setBtnLoading(true)
            setFileData({})
            let data = {
                visible_to: allChecked ? "all" : visibilities,
                document:
                {
                    public_id: image.public_id,
                    version: image.version,
                    format: image.format,
                },
                name: image.name
            }

            let response = await ApiService.postAPI(`contractor/project/document/${project_id}`, data);
            if (response.success) {
                setVisibilities([])
                completed()
                showSuccess(props, response.message);
                setBtnLoading(false)
            } else {
                // close()
                showError(props, response.message);
                setBtnLoading(false)
            }
        }
    }

    return (
        <div>
            <Card className="bnone">
                <CardHeader>
                    <div className="flex">
                        <div className="fw-500 f16" style={{ color: "white" }}>
                            Add
                        </div>
                        <img
                            src={closeIcon}
                            onClick={() => close()}
                            className="point"
                            alt="close"
                        />
                    </div>
                </CardHeader>
                <CardBody className="pad24p ">
                    <div className="" style={{ padding: "10px", border: "1px dashed #CECECE", borderRadius: "8px" }}>
                        {!image.public_id && !isLoading ?
                            <>
                                <input
                                    type="file"
                                    name="uploadDoc"
                                    id="uploadDoc"
                                    multiple={true}
                                    className="visible_hidden"
                                    style={{ width: "0px", height: "0px" }}
                                    onChange={(e) => { onImageChangeHandler(e) }}
                                    accept=".pdf, .xlsx, .xlx, .doc, .docx, .docs, .jpg, .png, .jpeg"
                                />
                                <label htmlFor="uploadDoc" className="text-center point justCenterColoum">
                                    <p className="inline-block">
                                        <img src={file} className="" />
                                    </p>
                                    <p
                                        className={`cl72569C uploadFile`}
                                    >
                                        {title}

                                    </p>
                                    <p className="f13">
                                        {fileSizeType}
                                    </p>
                                </label>
                            </>
                            :
                            <div className="documentClassDiv" style={{ display: 'flex', justifyContent: 'center' }}>
                                {isLoading ?
                                    < div className=" image_spinner mr_20 ">
                                        <Spinner type="border" color="#077e8b" />
                                    </div>
                                    :
                                    <>
                                        {!["png", "jpg", "jpeg"].includes(image.format) ?
                                            <div htmlFor="uploadDoc" style={{position:'relative'}} className="text-center image_container point sideWithDoc m_0" >
                                                  <img src={Line} className="hideDelete point"
                                                    style={{ top: "-7%", position: "absolute", left: "85%", width: "22px" }}
                                                    onClick={() => removeFile()}
                                                />
                                                <p className="inline-block">
                                                    <img src={docFile} style={{ width: "50px" }} />
                                                </p>
                                                <p className={`cl72569C uploadFile`}> {fileData.name} </p>
                                            </div>
                                            :
                                            <div className="image_container relative">
                                                <img src={Line} className="hideDelete point"
                                                    style={{ top: "-7%", position: "absolute", left: "80%", width: "22px" }}
                                                    onClick={() => removeFile()}
                                                />
                                                <img
                                                    width={"40%"}
                                                    src={getCloudinaryImage(120, 130, image, "c_fill")}
                                                    alt="profile image"
                                                    className="documentClass"
                                                    style={{ marginRight: "10px" }}
                                                />
                                            </div>}
                                    </>
                                }
                            </div>
                        }
                    </div>
                    <CustomListingContainer style={{ marginTop: "20px" }}>
                        <div
                            className="d_flex_space_align"
                            style={{ borderBottom: "1px solid #E1E8F0" }}
                        >
                            <div className="inactive_profile_tab tab_border"
                                style={{ padding: "15px 20px", color: "#212121", fontWeight: "500" }}
                            >
                                Visibility
                            </div>
                            <div style={{ marginRight: "24px", display: "flex", alignItems: "center" }} >
                                <Input style={{ height: "18px", width: "18px" }} checked={allChecked} onChange={(e) => {
                                    setAllChecked(e.target.value);
                                    handleAll(e.target.checked, "all", allChecked)
                                }} type="checkbox"
                                    id="team" name="team" value="team" className="m_0 position_u" />
                                <label className="ml_8 m_0" for="all" style={{ fontSize: "14px", color: "#212121" }}>All</label>
                            </div>
                        </div>
                        <div style={{ margin: "20px 20px 5px 20px" }}>
                            <div className="" style={{ gridTemplateColumns: "auto auto", display: "grid" }}>
                                {checkitems && checkitems.map((checkbox, i) => (
                                    <div style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}>
                                        <Input style={{ height: "18px", width: "18px" }} checked={checkbox.isChecked} onChange={() => handleAll(i, checkbox.key)}
                                            type="checkbox" id="team" name="team" value="team" className="m_0 position_u" />
                                        <label className="ml_8 m_0" for="client" style={{ fontSize: "14px", color: "#212121" }}>{checkbox.name}</label>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </CustomListingContainer>
                    <div style={{ marginTop: "20px" }}>
                        <div className="flexEnd" style={{ marginTop: "30px" }}
                        >
                            <Custom_Button
                                btnLabel="Cancel"
                                click={() => close()}
                            />
                            <Custom_Button
                                isPrimary={true}
                                btnLabel="Save"
                                click={() => onSubmit()}
                                style={{ marginLeft: "15px" }}
                                isLoading={btnLoading}
                            />
                        </div>
                    </div>
                </CardBody>
            </Card>
        </div >
    );

}

export default AddDocumentSidebar;
