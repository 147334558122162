import React, { Component } from "react";
import {
  CardHeader,
  Input,
  DropdownToggle,
  UncontrolledDropdown,
  Media,
  DropdownMenu,
  DropdownItem,
  InputGroup,
  InputGroupText,
  Spinner,
  Button,
  Tag,
  FormGroup,
  Card,
  Dropdown,
} from "reactstrap";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import closeIcon from "../../assets/img/icons/closeIcon.svg"
import closeWhiteIcon from "../../assets/img/icons/white_close_icon.svg";
import dateLight from "../../assets/img/brand/datePicker.svg";
import moment from "moment";
import uploadIcon from "../../assets/img/icons/upload_icon.svg";
import { getCloudinaryImage } from "constants/utils";
import removeIcon from "../../assets/img/brand/removeIcon.svg";
import Tags from "components/Tags/Tags";
import ApiService from "constants/ApiService";
import Add from "../../assets/img/brand/addIcon.svg";
import {
  showSuccess,
  isInvalidName,
  showError,
  uploadCloudinary,
  Isvalidname,
} from "constants/utils";
import AddClient from "./addClient";
import TaskList from "views/Task/TaskList";
import Autocomplete from "components/AutoComplete/AutoComplete";
import { remove_underscore_capitalize } from "constants/utils";
import { CustomInputFields } from "components/FilterFields/Filterfields";
import { CustomDropDownSearch } from "components/FilterFields/Filterfields";
import { CustomDropDown } from "components/FilterFields/Filterfields";
import { CustomSingleDatePicker } from "components/FilterFields/Filterfields";
import { CustomTextArea } from "components/FilterFields/Filterfields";
import { Button_without_Border } from "components/Buttons/Button";
import { Custom_Button } from "components/Buttons/Button";
import { AmountInput } from "components/FilterFields/Filterfields";
import { buildQueryString } from "constants/utils";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { returnSearchObject } from "constants/utils";
import { showUtcDate } from "constants/utils";
let overlap = document.querySelector(":root");
class AddProject extends Component {
  constructor(props) {
    super(props);
    this.inputKey = Date.now();
    this.fileInputRef = React.createRef();
    this.myElementRef = React.createRef();
    this.myInputElementRef = React.createRef();
    this.state = {
      estimate_id: "",
      name: "",
      client_name: "",
      clientsList: [],
      estimateLists: [],
      subContractor_name: "",
      sub_contractorList: [],
      start_date: "",
      image: [],
      selected_team: "",
      actual_start: "",
      end_date: "",
      contractor_type: "",
      subContractor: {},
      imageLoading: false,
      desc: "",
      description:'',
      tags: [],
      contractorInput: "",
      btnLoad: false,
      edit: false,
      initAddProjectLoading: true,
      tag: "",
      subContractor_id: "",
      clientAdd: false,
      isSearchOpen: false,
      activeIndex: -1,
      disabled: false,
      isApiCallPending: false,
      file: null,
      projectStartDate: null,
      projectActualStarDate: null,
      projectEndDate: null,
      otherProObj: {},
      subject: "",
      listsBoxOpen: false,
      listsLoad: false,
      isEstimatePresent: true,
      members: "",
      membersList: [],
      amount: "",
      memberId: "",
      isFromWorkOrder:false
      // isDetailPage:null
    };
    this.debouncedApiCall = this.debounce(this.createProjectHandler, 1000);
  }

  debounce(func, delay) {
    let timeoutId;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(context, args);
      }, delay);
    };
  }


  componentDidMount = async () => {
    this.props.type === "project" && this.props.projectId && this.setState({ edit: true });
    this.props.type === "task" && this.props.taskId && this.setState({ edit: true });
      // this.setState({isDetailPage:this.props.isDetailPage})
    if (this.props.type === "project") {
      let estimateResponse = await ApiService.getAPI(
        `contractor/estimate/accepted`
      );
      this.setState({
        isEstimatePresent: estimateResponse.data.estimates.length
          ? true
          : false,
      });

      let response = await ApiService.getAPI(`contractor/client?status=active`);
      !this.state.edit && this.setState({ initAddProjectLoading: false });
      if (response.success) {
        let { clients } = response.data;
        this.setState({
          clientsList: clients,
        });
      }
      // if (this.state.edit) {
      //   let projectDetail = await ApiService.getAPI(
      //     `contractor/project/${this.props.projectId}`
      //   );

      //   if (projectDetail.success) {
      //     let { project } = projectDetail.data;
      //     this.setState({
      //       initAddProjectLoading: false,
      //       name: project.name,
      //       client_name: project.client.name,
      //       start_date: new Date(project.start_date),
      //       actual_start: new Date(project.actual_start),
      //       end_date: new Date(project.end_date),
      //       image: project.image,
      //       desc: project.desc,
      //       tags: project.tags,
      //     });
      //   }
      // }
    } else if (this.props.type === "task") {
      let response = await ApiService.getAPI(`contractor/all`);
      // let projectDetail = await ApiService.getAPI(
      //   `contractor/project/${this.props.projectId}`
      // );
      // if (projectDetail.success) {
      //   let { start_date, actual_start, end_date } = projectDetail.data.project;
      //   this.setState({
      //     projectStartDate: start_date,
      //     projectActualStarDate: actual_start,
      //     projectEndDate: end_date,
      //   });
      // }
      !this.state.edit && this.setState({ initAddProjectLoading: false });
      if (response.success) {
        let { contractors } = response.data;
        this.setState({ sub_contractorList: contractors });
      }
      if (this.state.edit) {
        let taskDetail = await ApiService.getAPI(
          `contractor/task/${this.props.taskId}`
        );
        if (taskDetail.success) {
          let { task } = taskDetail.data;
          this.setState({
            initAddProjectLoading: false,
            name: task.name,
            selected_team: task.assignType,
            otherProObj: task.sub_contractor,
            members: task.sub_contractor.name,
            memberId: task.sub_contractor.id,
            end_date: new Date(task.end_date),
            // // image: task.image,
            description: task.desc || "",
            tags: task.tags,
            amount: task.amount,
            status: task.status,
            isFromWorkOrder: task.workOrder_id || null
          });
        }
      }
    } else if (this.props.type === "estimate") {
      const { name, lead_id } = this.props.lead;
      const { estimateId } = this.props;

      this.setState({ initAddProjectLoading: false }, () => {
        this.setState(
          { client_name: name, estimate_id: estimateId, client_id: lead_id },
          () => { }
        );
      });
    }
  };

  componentWillUnmount() {
    overlap.style.setProperty("--width", "450px");
  }

  getClientLists = async (e) => {
    let searchTerm = e ?? "";
    let response = await ApiService.getAPI(`contractor/client?status=active`);
    !this.state.edit && this.setState({ initAddProjectLoading: false });
    if (response.success) {
      let { clients } = response.data;
      this.setState({
        clientsList: clients,
      });
    }
  };

  imageHandler = async (e) => {
    // this.setState({file:e.target.files[0]},() => {this.imageUploader(this.state.file)})
    let file = e.target.files[0];
    let { image } = this.state;
    if (file) {
      this.setState({ imageLoading: true });
    }
    if (
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/jpeg"
    ) {
      if (file.size < 2e6) {
        let data = await uploadCloudinary(file, "host");
        if (data.url) {
          let imgArr = [...image];
          let imageObj = {
            format: data.format,
            public_id: data.public_id,
            version: `v${data.version}`,
            type: "image",
          };
          imgArr.push(imageObj);
          this.setState({
            image: imgArr,
            imageLoading: false,
          });
        } else {
          this.setState({ imageLoading: false });
          e.target.value = null;
          return showError(this.props.props, "Could not upload image");
        }
      } else {
        this.setState({ imageLoading: false });
        e.target.value = null;
        return showError(this.props.props, "File size should not exceeds 2 MB");
      }
    } else {
      this.setState({ imageLoading: false });
      e.target.value = null;
      return showError(
        this.props.props,
        "Invalid Format , upload only .png, .jpg, .jpeg"
      );
    }
  };

  imageUploader = async (file) => {
    let { image } = this.state;
    if (this.fileInputRef.current) {
      this.fileInputRef.current.value = "";
    }
    if (file) {
      this.setState({ imageLoading: true });
    }
    if (
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/jpeg"
    ) {
      if (file.size < 2e6) {
        let data = await uploadCloudinary(file, "host");
        if (data.url) {
          let imgArr = [...image];
          let imageObj = {
            format: data.format,
            public_id: data.public_id,
            version: `v${data.version}`,
            type: "image",
          };
          imgArr.push(imageObj);
          this.setState({
            image: imgArr,
            imageLoading: false,
          });
        } else {
          this.setState({ imageLoading: false });
          return showError(this.props.props, "Could not upload image");
        }
      } else {
        this.setState({ imageLoading: false });
        return showError(this.props.props, "File size should not exceeds 2 MB");
      }
    } else {
      this.setState({ imageLoading: false });
      return showError(
        this.props.props,
        "Invalid Format , upload only .png, .jpg, .jpeg"
      );
    }
  };

  addClient = () => {
    this.setState({ clientAdd: true });
  };

  deleteImage = () => {
    this.setState({ image: [], image_name: "" });
  };

  closeModal = (data) => {
    if (data) this.props.close();
    else this.props.close();
  };

  getMembersList = async (e) => {
    let response;
    this.setState({
      listsLoad: true,
      isNameAlreadyPresent: false,
      email: "",
      phone: "",
    });
    let searchTerm = e || "";
    this.setState({ members: searchTerm });
    response = await ApiService.getAPI(`contractor/all?name=${searchTerm}`);
    this.setState({ initAddProjectLoading: false });
    if (response.success) {
      this.setState(
        {
          membersList: response.data.contractors,
          listsLoad: false,
        },
        () => {
          this.state.clientsList.length
            ? this.setState({ listsBoxOpen: true })
            : this.setState({ listsBoxOpen: false });
        }
      );
    }
  };

  getValue = (value) => {
    const pattern = /^\d+(\.\d+)?$/;
    if (pattern.test(value) || value === "") {
      this.setState({ amount: value });
    }
  };

  handleSelect = (contractor) => {
    this.setState({
      otherProObj: {
        id: contractor.contractor_id,
        name: contractor.name,
        phone: contractor.phone,
        email: contractor.email,
      },
      contractorInput: contractor.userInput,
    });
  };

  createProjectHandler = async () => {
    let {
      lead_id,
      name,
      client_name,
      isEstimatePresent,
      start_date,
      end_date,
      actual_start,
      client_id,
      description,
      subContractor_id,
      desc,
      edit,
      tags,
      image,
      subContractor_name,
      estimate_id,
      otherProObj,
      selected_team,
      members,
      amount,
      contractorInput,
      memberId,
      membersList
    } = this.state;
    let { type } = this.props;
    if (!name) return showError(this.props.props, "Please enter a task name");
    else if (!Isvalidname(name))
      return showError(this.props.props, "Please enter a valid name");
    else if (!members) {
      return showError(this.props.props, "Please select the pros");
    } else if (!end_date)
      return showError(this.props.props, "Please select end date");
    else if (
      type === "task" &&
      moment(end_date).isAfter(this.state.projectEndDate)
    ) {
      return showError(
        this.props.props,
        `End date should be less than ${showUtcDate(this.state.projectEndDate)}`
      );
    } else if (!(+amount)) {
      return showError(this.props.props, "Please enter a amount");
    }
    this.setState({ btnLoad: true, isApiCallPending: true });
    if (this.props.type === "project" || this.props.type === "estimate") {
      let data = {
        name: name,
        client: {
          id: client_id,
          name: client_name,
        },
        start_date: start_date,
        end_date: end_date,
        actual_start: actual_start,
        desc: desc,
        tags: tags,
        image: image,
      };
      if (this.props.type === "estimate") data["estimate_id"] = estimate_id;

      // let response = edit
      //   ? await ApiService.patchAPI(
      //       `contractor/project/${this.props.projectId}`,
      //       data
      //     )
      //   : await ApiService.postAPI("contractor/project", data);
      // this.setState({ disabled: false, isApiCallPending: false });
      // if (response.success) {
      //   showSuccess(this.props.props, response.message);
      //   this.setState({
      //     btnLoad: false,
      //     open: false,
      //     name: "",
      //     start_date: "",
      //     end_date: "",
      //     actual_start: "",
      //     desc: "",
      //     tags: [],
      //     client_name: "",
      //     client_id: "",
      //   });
      //   this.closeModal();
      // } else {
      // }

      // this.setState({ btnLoad: false });
      // showError(this.props.props, response.message);
    } else if (this.props.type === "task") {
      let sub_contractor = { ...otherProObj };

      let data = {
        name: name,
        sub_contractor: {
          name: members,
          id: memberId,
        },
        start_date: start_date,
        end_date: end_date,
        actual_start: actual_start,
        desc: description,
        tags: tags,
        image: image,
        assignType: selected_team,
        amount: amount,
      };
      let response = edit
        ? await ApiService.patchAPI(
          `contractor/task/${this.props.taskId}`,
          data
        )
        : await ApiService.postAPI(
          `contractor/task/${this.props.projectId}`,
          data
        );
      if (response.success) {
        showSuccess(this.props.props, response.message);
        this.setState({
          btnLoad: false,
          open: false,
          name: "",
          start_date: "",
          end_date: "",
          actual_start: "",
          description: "",
          tags: [],
          subContractor_name: "",
          subContractor_id: "",
        });
        this.closeModal();
      } else {
      }

      this.setState({ btnLoad: false });
    }
  };

  onInputSearch = (e) => {
    let tempLists = this.state.clientsList;
    let searchName = e.target.value;
    // if(!searchName.length) return tempLists = []
    const tempArray = tempLists.filter((obj) => {
      let name = obj.name;
      // return obj.name.trim().toLowercase().includes(searchName) && obj
    });
  };

  onTagChange = (e) => {
    let { tag, tags } = this.state;
    if (["Enter", "Tab"].includes(e.key)) {
      e.preventDefault();
      let value = tag.trim();
      if (value.length) {
        tags.push(value);
        this.setState({ tags: [...tags], tag: "" });
      }
    } else if (["Backspace"].includes(e.key) && !tag) {
      if (tags.length) {
        tags.pop();
        this.setState({ tags: [...tags], tag: "" });
      }
    }
  };

  getListDetail = async (data) => {
    this.setState(
      {
        members: data.name,
        memberId: data.contractor_id,
      },
      () => {
        this.setState({ listsBoxOpen: false, isNameAlreadyPresent: true });
      }
    );
  };

  getEstimateLists = async (e) => {
    this.setState({
      listsLoad: true,
      isNameAlreadyPresent: false,
      subject: "",
    });
    let searchTerm = e?.target?.value || "";
    this.setState({ subject: searchTerm });
    let response = await ApiService.getAPI(
      `contractor/estimate/accepted?subject=${searchTerm}`
    );
    this.setState({ initAddProjectLoading: false });
    if (response.success) {
      let { estimates } = response.data;
      this.setState(
        {
          estimateLists: estimates,
          listsLoad: false,
        },
        () => {
          this.state.estimateLists?.length
            ? this.setState({ listsBoxOpen: true })
            : this.setState({ listsBoxOpen: false });
        }
      );
    }
  };

  minDate = (current) => {
    return current && current < moment().add(-1, "days");
  };

  dateContion = (from, event) => {
    if (from === "start") {
      if (this.props.type === "project") {
        this.setState({ start_date: event });
      }
    }
  };

  handleInputChange = (e, from) => {
    this.setState({ [from]: e });
  };

  removeTag = (index) => {
    let { tag, tags } = this.state;
    let tempTags = tags;
    tempTags.splice(index, 1);
    this.setState({ tags: [...tempTags] });
  };

  onTagAreaClick = () => {
    this.myInputElementRef.current.focus();
  };

  onClientClose = () => {
    this.setState({ clientAdd: false }, () => {
      this.getClientLists();
    });
  };

  setSource = (value) => {
    this.setState({
      selected_team: value === "my_teams" ? "inside" : "outside",
    });
  };

  handleDueDate = (e) => {
    this.setState({ end_date: e ? new Date(e) : "" });
  };
  handleStartDate = (e) => {
    this.setState({ start_date: e ? new Date(e) : "" });
  };
  setDescritpion = (value) => {
    this.setState({ description: value });
  };

  render() {
    const {
      estimate_id,
      name,
      client_name,
      clientsList,
      start_date,
      actual_start,
      end_date,
      listsLoad,
      listsBoxOpen,
      image,
      imageLoading,
      estimateLists,
      desc,
      tags,
      btnLoad,
      edit,
      subContractor_name,
      sub_contractorList,
      otherProObj,
      initAddProjectLoading,
      selected_team,
      isSearchOpen,
      activeIndex,
      description,
      isEstimatePresent,
      subject,
      members,
      amount,
      memberId,
      status,
      isDetailPage,
      isFromWorkOrder
    } = this.state;
    return initAddProjectLoading ? (
      <div className="spin-loading">
        <Spinner
          style={{
            height: "30px",
            width: "30px",
          }}
          type="border"
          color="#012A4D"
        />
      </div>
    ) : (
      <>
        <div>
          <CardHeader>
            <div className="flex">
              <div className="fw-500 f16" style={{ color: "white" }}>
                { ["approved", "completed","cancelled"].includes(status) ? "Task Details" : edit ? "Update Task"  : "Add Task"}
              </div>
              <img
                src={closeWhiteIcon}
                onClick={() => this.props.close("close")}
                className="point"
              />
            </div>
          </CardHeader>
          <div className="cardContainer">
            <label className="customLabel">Name*</label>
            <CustomInputFields
              style={{ marginBottom: "20px" }}
              inputValue={name}
              type="text"
              change={this.handleInputChange}
              from="name"
              placeHolder="Enter the name"
              disabled={["approved", "completed","cancelled"].includes(status)}
            />

            {this.props.type === "project" && isEstimatePresent ? (
              <>
                <label className="customLabel">Estimates*</label>
                <CustomDropDownSearch
                  style={{ marginBottom: "20px", width: "100%" }}
                  className="customDropDownSearch"
                  toSearch="subject"
                  width={"100%"}
                  placeHolder="Enter the estimate"
                  filteredData={estimateLists}
                  onChange={this.getClientLists}
                  listClick={this.getListDetail}
                  value={subject}
                />
              </>
            ) : (
              <></>
            )}

            {this.props.type === "estimate" && (
              <>
                <label className="customLabel">Estimate Id*</label>
                <CustomInputFields
                  style={{ marginBottom: "20px" }}
                  inputValue={estimate_id}
                  type="text"
                  change={this.handleInputChange}
                  disabled
                  from="name"
                  placeHolder="Enter the estimate id"
                />
              </>
            )}

            <>
              <label className="customLabel">Select Pros*</label>
              <CustomDropDownSearch
                disabled={["approved", "completed","cancelled"].includes(status) || isFromWorkOrder}
                toSearch="name"
                style={{ marginBottom: "20px", width: "100%" }}
                className="customDropDownSearch"
                filteredData={this.state.membersList}
                onChange={this.getMembersList}
                width={"100%"}
                placeHolder="Enter the pros"
                listClick={this.getListDetail}
                value={members}
              />
            </>

            <label className="customLabel">Due on*</label>
            <CustomSingleDatePicker
              disabled={["approved", "completed" ,"cancelled"].includes(status)}
              style={{ marginBottom: "20px", width: "100%" }}
              value={end_date}
              change={this.handleDueDate}
              setMinMaxDate={this.minDate}
            />
            <label className="customLabel">Amount*</label>
            <AmountInput
              style={{ marginBottom: "20px" }}
              amount={amount}
              change={this.getValue}
              disabled={["approved", "completed" , "cancelled"].includes(status) || isFromWorkOrder}
            />
            <label className="customLabel">Description</label>

            <CustomTextArea
              disabled={["approved", "completed" , "cancelled"].includes(status)}
              inputvalue={description}
              change={this.setDescritpion}
            />
            <label
              className="customLabel"
              style={{
                marginTop: "15px",
              }}
            >
              Tags
            </label>
            <div
              onClick={this.onTagAreaClick}
              ref={this.myElementRef}
              className="drawer_desc"
              style={{
                padding: "2%",
                border: "1px solid #E6E6E6",
                borderRadius: "10px",
              }}
            >
              {tags?.map((string, i) => (
                <div className="tagChip">
                  {string}
                  <span>
                    <img
                      style={{
                        width: "16px",
                        height: "20px",
                        marginLeft: "10px",
                        cursor: "pointer",
                      }}
                      onClick={() => this.removeTag(i)}
                      src={closeIcon}
                    ></img>
                  </span>
                </div>
              ))}
              <input
                style={{ backgroundColor: "transparent" }}
                disabled={["approved", "completed" , "cancelled"].includes(status)}
                ref={this.myInputElementRef}
                onKeyDown={(e) => this.onTagChange(e)}
                onChange={(e) => this.setState({ tag: e.target.value })}
                className="tagInput"
                type="text"
                value={this.state.tag}
              ></input>
            </div>

            {!["approved", "completed" , "cancelled"].includes(status) && (
              <div style={{ marginTop: "30px" }} className="flexEnd">
                <Button_without_Border
                  style={{ marginRight: "20px" }}
                  btnLabel="cancel"
                  click={() => this.props.close("close")}
                />
                <Custom_Button
                  isPrimary
                  btnLabel={edit ? "Update" : "Save"}
                  click={() => this.createProjectHandler()}
                  isLoading={btnLoad}
                />
              </div>
            )}
          </div>
        </div>
        <Card
          className={this.state.clientAdd ? "exportSideBar" : "toggle-sidebar"}
        >
          {this.state.clientAdd && (
            <AddClient
              props={this}
              from="project"
              closeClientModal={this.onClientClose}
              cancel={() => this.setState({ clientAdd: false })}
            />
          )}
        </Card>
      </>
    );
  }
}
export default AddProject;
