import React, { useState, useEffect } from "react";
import closeToast from "../../assets/img/brand/closeToast.svg";
const Toast = (props) => {
  const { toastList, position, handleDelete } = props;
  const [list, setList] = useState(toastList);

  useEffect(() => {
    setList(toastList);
  }, [toastList, list]);

  return (
    <>
      <div className={`notification-container ${position}`}>
        {list.length &&
          <>
            {
              list.map((toast, i) => (
                <div
                  key={i}
                  className={`notification toast ${position}`}
                  style={{ backgroundColor: toast.backgroundColor }}
                >
                  <div className="notification-image">
                    <img src={toast.icon} alt="" className="toast-img" />
                  </div>
                  <div className="toast-flex">
                    <p className="notification-title mb-0">{toast.title}</p>
                    <p className="notification-message mb-0">
                      &nbsp;
                      {toast.description}
                    </p>
                  </div>
                  <div className="closeToast-img" onClick={handleDelete}>
                    <img src={closeToast} alt="close toast" />
                  </div>
                </div>
              ))
            }
          </>
        }
      </div>
    </>
  );
};
export default Toast;
