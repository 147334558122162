import React, { Component } from "react";
import { CardHeader, Spinner, Input, Button } from "reactstrap";
import closeIcon from "../../assets/img/brand/close.svg";
import UploadIcon from "../../assets/img/brand/uplaod.svg";
import { Switch } from "antd";
export default class AddPhotosDocuments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initPageLoading: false,
      btnLoad: false,
      edit: false,
      switchClient: false,
      switchTeam:false,
      switchPro:false
    };
  }

  componentDidMount() {
  }

  closeModal = () => {
    this.props.photDocClose();
  };
  toggleSwitch = (type) => {
    this.setState((prevState) => ({
      [type]: !prevState[type],
    }));
  };
  
  
  render() {
    let { initPageLoading, btnLoad, edit, switchTeam,switchClient,switchPro } = this.state;
    return (
      <>
        <CardHeader>
          <div className="flex">
            <div className="fw-500 f16 cl000000"></div>
            <img
              src={closeIcon}
              onClick={() => this.closeModal()}
              className="point"
            />
          </div>
        </CardHeader>
        {initPageLoading ? (
          <div className="spin-loading">
            <Spinner type="border" color="#012A4D" />
          </div>
        ) : (
          <>
            <div className="cardContainer">
              <div style={{ marginBottom: "20px" }}>
                <>
                  <div
                    className="cl2A1E17 mb-15 f14 fw-400 drawer_sub_heading"
                    style={{ marginTop: "15px",fontWeight: "400", fontSize: "16px"  }}
                  >
                    Name*
                  </div>
                  <Input
                    style={{
                      fontSize: "14px",
                      color: "#212529",
                      border: "1px solid #f0f0f0",
                    }}
                    type="text"
                    // value={name}
                    name="name"
                    onChange={(e) => {
                      this.setState({ name: e.target.value });
                    }}
                    className="mb-20"
                  />
                </>
                <>
                  <div
                    className="cl2A1E17 mb-15 f14 fw-400 drawer_sub_heading"
                    style={{ marginTop: "2rem",fontWeight: "400", fontSize: "16px"  }}
                  >
                    Description
                  </div>
                  <Input
                    style={{
                      fontSize: "14px",
                      color: "#212529",
                      border: "1px solid #f0f0f0",
                    }}
                    type="textarea"
                    max={500}
                    rows={3}
                    name="name"
                    onChange={(e) => {
                      this.setState({ name: e.target.value });
                    }}
                    className="mb-20"
                  />
                </>
                <div style={{ display: "flex", alignItems: "center",justifyContent:"space-between" }}>
                  <div
                    className="cl2A1E17 mb-15 f14 fw-400 drawer_sub_heading"
                    style={{ marginTop: "1.5rem", fontWeight: "400", fontSize: "18px"}}
                  >
                    Visible To Client*
                  </div>
                  <Switch
                    defaultChecked
                    checked={switchClient}
                    onChange={()=>this.toggleSwitch("switchClient")}
                    style={{ color: "black", width: "3rem" }}
                    size="big"
                  />
                </div>
                <div style={{ display: "flex", alignItems: "center",justifyContent:"space-between" }}>
                  <div
                    className="cl2A1E17 mb-15 f14 fw-400 drawer_sub_heading"
                    style={{ marginTop: "1.5rem", fontWeight: "400", fontSize: "18px" }}
                  >
                    Visible To Team*
                  </div>
                  <Switch
                    defaultChecked
                    checked={switchTeam}
                    onChange={()=>this.toggleSwitch("switchTeam")}
                    style={{ color: "black", width: "3rem" }}
                    size="big"
                  />
                </div>
                <div style={{ display: "flex", alignItems: "center",justifyContent:"space-between" }}>
                  <div
                    className="cl2A1E17 mb-15 f14 fw-400 drawer_sub_heading"
                    style={{ marginTop: "1.5rem", fontWeight: "400", fontSize: "18px" }}
                  >
                    Visible To Other Pros*
                  </div>
                  <Switch
                    defaultChecked
                    checked={switchPro}
                    onChange={()=>this.toggleSwitch("switchPro")}
                    style={{ color: "black", width: "3rem" }}
                    size="big"
                  />
                </div>
                <div>
                  <div
                    className="cl2A1E17 mb-15 f14 fw-400 drawer_sub_heading"
                    style={{ marginTop: "1.5rem", fontWeight: "400", fontSize: "16px" }}
                  >
                    Attachment here*
                  </div>
                  <div className="crm_uploadDocumentsComponent" style={{ padding: "2rem" }}>
                    <img width="40px" height="40px" src={UploadIcon}></img>
                    {/* <Button
                      color="primary"
                      className="button-wrapping "
                      size="sm"
                      onClick={() => this.createProjectHandler()}
                      disabled={this.state.isApiCallPending}
                    >Upload</Button> */}
                    <div style={{ color: "#75728f", fontWeight: "300" }}>Drag Here to Upload</div>
                    <div className="upload_btn" >Upload</div>

                  </div>
                </div>
              </div>
              <div className="flexEnd">
                <div
                  className="mr-20 point f14 button-secondary"
                  style={{ fontWeight: "500" }}
                  onClick={() => this.closeModal()}
                >
                  Cancel
                </div>
                <Button
                  color="primary"
                  className="button-wrapping "
                  size="sm"
                  onClick={() => this.createProjectHandler()}
                  disabled={this.state.isApiCallPending}
                >
                  {btnLoad ? (
                    <div className="spin-loading-save w100 h100">
                      <Spinner
                        style={{
                          height: "20px",
                          width: "20px",
                        }}
                        type="border"
                        color="#0A8080"
                      />
                    </div>
                  ) : edit ? (
                    "Update"
                  ) : (
                    "Save"
                  )}
                </Button>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}
