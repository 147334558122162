import React from "react";

function LineEllipsis({ content }) {
  return (
    <>
      <div>
        <div className="contentEllipsis">{content}</div>
      </div>
    </>
  );
}

export default LineEllipsis;
