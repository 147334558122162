import Index from "../src/views/Index";
import Invoices from "../src/views/Invoices/Invoices";
import Requests from "../src/views/Request/Requests";
import Payouts from "../src/views/Payout/Payouts";
import DashboarActive from "../src/assets/img/sideBar_icons/DashBoardNewActive.svg";
import DashboarInactive from "../src/assets/img/sideBar_icons/dashBoard_active.svg";
import RequestInactive from "../src/assets/img/sideBar_icons/RequestNewActive.svg";
import RequestActive from "../src/assets/img/sideBar_icons/requests_active.svg";
import InvoiceInactive from "./assets/img/brand/Invoice_active.svg";
import InvoiceActive from "./assets/img/brand/Invoice_active.svg";
import PayoutInactive from "./assets/img/brand/Payout_active.svg";
import PayoutActive from "./assets/img/brand/Payout_active.svg";
import Maintenance from "./views/Maintenance/Maintenance";
import CalenderInactive from "../src/assets/img/sideBar_icons/calender_active.svg";
import CalenderActive from "../src/assets/img/sideBar_icons/calender_inactive.svg";
import financeActive from "../src/assets/img/sideBar_icons/finance_active.svg"
import financeInactive from "../src/assets/img/sideBar_icons/FinanceNewActive.svg"
import teamActive from "../src/assets/img/sideBar_icons/teams_active.svg"
import teamInactive from "../src/assets/img/sideBar_icons/TeamsNewActive.svg"
import ProjectActive from "./assets/img/brand/projectActive.svg"
import CrmActive from "../src/assets/img/sideBar_icons/crm_active.svg"
import CrmInactive from "../src/assets/img/sideBar_icons/CRMNewActive.svg"
import TaskActive from "../src/assets/img/sideBar_icons/taskNewActive.svg"
import TaskInactive from "../src/assets/img/sideBar_icons/tasks_active.svg"
import Calender from "views/Calender/calender";
import MaintenanceActive from "./assets/img/brand/maintainence_active.svg";
import MaintenanceInactive from "./assets/img/brand/maintainence_active.svg";
import subContractorActive from "./assets/img/brand/managerActive.svg";
import subContractorInactive from "./assets/img/brand/managerActive.svg";
import ActiveContractors from "views/Subcontractor/activeContractors";
import InviteContractors from "views/Subcontractor/inviteContractors";
import { isSub_Contractor } from "constants/utils";
import OpenRequestActive from "./assets/img/brand/openRequest_active.svg";
import OpenRequestInactive from "./assets/img/brand/openRequest_active.svg";
import ProjectOverview from "views/Project/ProjectOverview";
import Task from "views/Task/Task";
import PreviewProposal from "views/Crm/EstimatePreview/PreviewProposal";
import Overview from "views/Crm/Overview/overview";
import Leads from "views/Crm/Leads/leads";
import Estimates from "views/Crm/Estimates/estimate";
import Client from "views/Project/Client";
import Project from "views/Project/Project";
import OtherProsContractors from "views/Subcontractor/otherProsContractors";


var RentalRoutes = [
  {
    path: "/index",
    name: "Dashboard",
    merge: "Dashboard",
    active: DashboarActive,
    isChildren: false,
    inactive: DashboarInactive,
    component: Index,
    layout: "/admin",
    toggleChild: false,
    hasChild: false,
    isActive: false,
    view: true,
  },
  {
    path: "/calender",
    name: "Calender",
    merge: "CalenderProp",
    active: CalenderActive,
    inactive: CalenderInactive,
    component: Calender,
    layout: "/admin",
    isChildren: false,
    hasChild: false,
    isActive: false,
    view: true,
  },
  {
    path: "/requests",
    name: "Requests",
    merge: "RequestProp",
    isChildren: false,
    active: RequestActive,
    inactive: RequestInactive,
    component: Requests,
    layout: "/admin",
    hasChild: true,
    isActive: false,
    view: true,
    subChild: [
      !isSub_Contractor() && {
        path: "/open_request",
        name: "Open Requests",
        merge: "RequestProp",
        isChildren: false,
        active: OpenRequestActive,
        inactive: OpenRequestInactive,
        component: Requests,
        layout: "/admin",
        hasChild: false,
        isActive: false,
        view: true,
      },
      {
        path: "/requests",
        name: "Service Requests",
        merge: "PayoutProp",
        active: MaintenanceActive,
        component: Requests,
        inactive: MaintenanceInactive,
        isChildren: false,
        layout: "/admin",
        hasChild: false,
        isActive: false,
        view: true,
      },
      {
        path: "/maintenance",
        name: "Maintenance",
        merge: "PayoutProp",
        active: MaintenanceActive,
        component: Maintenance,
        inactive: MaintenanceInactive,
        isChildren: false,
        layout: "/admin",
        hasChild: false,
        isActive: false,
        view: true,
      },
    ]
  },
  {
    name: "My Finance",
    active: financeActive,
    inactive: financeInactive,
    layout: "/admin",
    hasChild: true,
    isActive: false,
    view: true,
    subChild: [
      {
        path: "/invoices",
        name: "Invoices",
        merge: "PayoutProp",
        active: InvoiceActive,
        component: Invoices,
        inactive: InvoiceInactive,
        isChildren: false,
        layout: "/admin",
        hasChild: false,
        isActive: false,
        view: true,
      },

      {
        path: "/payouts",
        name: "Payouts",
        merge: "PayoutProp",
        active: PayoutActive,
        inactive: PayoutInactive,
        isChildren: false,
        component: Payouts,
        layout: "/admin",
        hasChild: false,
        isActive: false,
        view: true,
      },
    ]
  },
  // !isSub_Contractor() && {
  //   path: "/open_request",
  //   name: "Open Requests",
  //   merge: "RequestProp",
  //   isChildren: false,
  //   active: OpenRequestActive,
  //   inactive: OpenRequestInactive,
  //   component: Requests,
  //   layout: "/admin",
  //   hasChild: false,
  //   isActive: false,
  // },

  // {
  //   path: "/invoices",
  //   name: "Invoices",
  //   merge: "InvoiceProp",
  //   active: InvoiceActive,
  //   inactive: InvoiceInactive,
  //   component: Invoices,
  //   layout: "/admin",
  //   isActive: false,
  // },


  !isSub_Contractor() && {
    name: "Project Management",
    path: "/project_overview",
    layout: "/admin",
    isActive: false,
    active: CrmActive,
    inactive: CrmInactive,
    isChildren: false,
    hasChild: true,
    view: true,
    subChild: [
      {
        path: "/project_overview",
        name: "Overview",
        merge: "PayoutProp",
        active: InvoiceActive,
        component: Overview,
        inactive: InvoiceInactive,
        isChildren: false,
        layout: "/admin",
        hasChild: false,
        isActive: false,
        view: true,
      },

      {
        path: "/leads",
        name: "Leads",
        merge: "PayoutProp",
        active: PayoutActive,
        inactive: PayoutInactive,
        isChildren: false,
        component: Leads,
        layout: "/admin",
        hasChild: false,
        isActive: false,
        view: true,
      },

      {
        path: "/estimates",
        name: "Estimates",
        merge: "PayoutProp",
        active: PayoutActive,
        inactive: PayoutInactive,
        isChildren: false,
        component: Estimates,
        layout: "/admin",
        hasChild: false,
        isActive: false,
        view: true,
      },
      {
        path: "/projects",
        name: "Projects",
        merge: "PayoutProp",
        active: PayoutActive,
        inactive: PayoutInactive,
        isChildren: false,
        component: Project,
        layout: "/admin",
        hasChild: false,
        isActive: false,
        view: true,
      },

      {
        path: "/clients",
        name: "Clients",
        merge: "PayoutProp",
        active: PayoutActive,
        inactive: PayoutInactive,
        isChildren: false,
        component: Client,
        layout: "/admin",
        hasChild: false,
        isActive: false,
        view: true,
      },
    ]
  },



  // !isSub_Contractor() && {
  //   name: "My Clients",
  //   path: "/clients",
  //   layout: "/admin",
  //   isActive: false,
  //   active: subContractorActive,
  //   inactive: subContractorInactive,
  //   isChildren: false,
  //   hasChild: false,
  // },

  // !isSub_Contractor() && {
  //   path: "/project_overview",
  //   name: "Projects",
  //   merge: "PayoutProp",
  //   active: ProjectActive,
  //   inactive: ProjectActive,
  //   isChildren: false,
  //   component: ProjectOverview,
  //   layout: "/admin",
  //   hasChild: false,
  //   isActive: false,
  // },
  {
    path: "/tasks",
    name: "My Tasks",
    merge: "PayoutProp",
    active: TaskActive,
    component: Task,
    inactive: TaskInactive,
    isChildren: false,
    layout: "/admin",
    hasChild: false,
    isActive: false,
    view: true,
  },

  !isSub_Contractor() && {
    name: "My Team",
    layout: "/admin",
    isActive: false,
    active: teamActive,
    inactive: teamInactive,
    isChildren: false,
    hasChild: true,
    view: true,
    subChild: [
      {
        path: "/otherProsContractor",
        name: "Other Pros",
        // merge: "MaintenanceProp",
        active: subContractorActive,
        inactive: subContractorInactive,
        component: OtherProsContractors,
        layout: "/admin",
        isActive: false,
        view: true,
      },
      {
        path: "/activeContractors",
        name: "Sub Pros",
        merge: "RequestProp",
        active: subContractorActive,
        inactive: subContractorInactive,
        component: ActiveContractors,
        layout: "/admin",
        isActive: false,
        view: true,
      },
      {
        path: "/inviteContractor",
        name: "Invites",
        merge: "MaintenanceProp",
        active: subContractorActive,
        inactive: subContractorInactive,
        component: InviteContractors,
        layout: "/admin",
        isActive: false,
        view: true,
      },
    ],
  },
];
export default RentalRoutes;
