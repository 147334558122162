import { Component } from "react";
import ApiService from "constants/ApiService";
import {
  Card,
  Table,
  Container,
  Row,
  Breadcrumb,
  Col,
  Button,
  InputGroup,
  Spinner,
  DropdownMenu,
  DropdownItem,
  Media,
  UncontrolledDropdown,
  DropdownToggle,
  Dropdown,
  Input,
  Progress,
  BreadcrumbItem,
  Modal,
} from "reactstrap";
import ToastList from "components/Toast/ToastList";
import moment from "moment";
import projectTimeline from "../../../src/assets/img/brand/projectTimeline.svg";
import showMore from "../../assets/img/brand/showMore.svg";
import { capitalize } from "constants/utils";
import {
  returnSearchObject,
  pushToFilteredPage,
  filterQuery,
  buildQueryString,
  getIsoString,
} from "constants/utils";
import TimeLine from "components/Timeline/Timeline";
import AddProject from "./addProject";
import DeleteModal from "./DeleteModal";
import { showSuccess, showError } from "constants/utils";
import RejectNotes from "./rejectNotes";
import { showUtcDate } from "constants/utils";
import { CustomListingContainer } from "components/Container/CustomListingContainer";
import { ListingHeaderLayout } from "components/Container/CustomListingContainer";
import ToolTip from "components/ToolTip/ToolTip";
import { Button_without_Border } from "components/Buttons/Button";
import { Custom_Button } from "components/Buttons/Button";
import CancelModal from "../Project/cancelModal";
import UpdateTask from "views/Task/updateTask";

let overlap = document.querySelector(":root");
const styleForDropDown = { padding: "0.7rem 1rem" };

export default class TaskLists extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPageLoading: true,
      taskLists: [],
      toast: false,
      response: "",
      toastType: "",
      filterOption: "",
      inputSearch: "",
      statusSearch: "",
      page: 1,
      isTimeLineOpen: false,
      task_id: "",
      openModal: false,
      delete_data: {},
      project_id: "",
      notesOpen: false,
      type: "",
      project: {},
      openTask: false,
      status: null,
      reject: false,
      rejectReason: null,
      rejectBtnLoad: false,
      cancelTask: false,
      progressModal: false,
    };
  }

  componentDidMount = () => {
    const { params } = this.props.match;
    this.setState({ project_id: params.project_id }, async () => {
      await this.getProjectDetails();
      this.setQueryStatus();
    });
  };

  cancelTask = (id) => {
    this.setState({ cancelTask: true, task_id: id });
  };

  getProjectDetails = async () => {
    let response = await ApiService.getAPI(
      `contractor/project/${this.state.project_id}`
    );
    this.setState({ project: response.data.project });
  };
  onReasonOpen = (task, type) => {
    this.setState({
      notesOpen: true,
      task_id: task.task_id,
      type: type,
    });
  };

  closeRejectNotes = (boolean, notes) => {
    this.setState({ notesOpen: false });
    boolean && this.approveOrReject(this.state.type, this.state.task_id, notes);
  };

  taskClose = (val) => {
    this.setState({ task_id: false }, () => {
      if (val) this.setState({ openTask: false });
      else {
        this.setState({ openTask: false }, () => {
          this.getTaskLists();
        });
      }
    });
  };
  approveOrReject = async (status, id, notes) => {
    let data = {
      notes: notes,
    };
    let response = await ApiService.patchAPI(
      `contractor/task/${status}/${id}`,
      data
    );
    if (response.success) {
      showSuccess(this, response.message);
      this.getTaskLists();
    } else {
      showError(this, response.message);
    }
  };

  setQueryStatus = () => {
    let queryStatus = this.props.location.search;
    if (queryStatus) {
      let queryObj = returnSearchObject(queryStatus);
      this.setState(
        {
          project_id: this.state.project_id,
          // from: queryObj.from ? new Date(queryObj.from) : "",
          // to: queryObj.to ? new Date(queryObj.to) : "",
          statusSearch: queryObj.status ?? "",
          // page: queryObj.page ?? "",
          query: { ...returnSearchObject(queryStatus), limit: 10, page: 1 },
        },
        () => {
          pushToFilteredPage(this.props, { ...this.state.query });
          this.getTaskLists();
        }
      );
    } else {
      this.setState(
        {
          query: {
            ...this.state.query,
            page: 1,
            limit: 10,
          },
        },
        () => {
          pushToFilteredPage(this.props, this.state.query);
          this.getTaskLists();
        }
      );
    }
  };

  handleSearch = () => {
    this.setState({ page: 1 }, () => {
      this.handleFilterStatus();
    });
  };

  handleReset = () => {
    let queryStatus = this.props.location.search;
    let queryObj = returnSearchObject(queryStatus);
    let self = this;
    self.setState(
      {
        from: "",
        to: "",
        statusSearch: "",
        filterOption: "",
        inputSearch: "",
        page: 1,
        limit: 10,
        query: {
          project_id: queryObj.project_id ?? "",
          page: 1,
          limit: 10,
        },
      },
      () => {
        pushToFilteredPage(this.props, { ...this.state.query });
        this.getTaskLists();
      }
    );
  };

  getTaskLists = async () => {
    let { filterOption, statusSearch, inputSearch, project_id } = this.state;
    let queryParams = "";
    if (filterOption !== "" && inputSearch !== "")
      queryParams += filterQuery(filterOption, inputSearch);
    if (statusSearch !== "") {
      queryParams += filterQuery("status", statusSearch);
    }
    let taskListResponse = await ApiService.getAPI(
      `contractor/task${buildQueryString(this.state.query)}`
    );
    this.setState({ isPageLoading: false });
    if (taskListResponse.success) {
      let { tasks } = taskListResponse.data;
      this.setState({ taskLists: tasks });
    }
  };

  handleDecrementPage = () => {
    if (this.state.page > 1) {
      this.setState({ page: this.state.page - 1 }, () =>
        this.handleFilterStatus()
      );
    }
  };

  handleFilterStatus = () => {
    let staticQuery = returnSearchObject(this.props.location.search);
    // staticQuery.from = getIsoString(this.state.from) ?? "";
    // staticQuery.to = getIsoString(this.state.to) ?? "";
    staticQuery.status = this.state.statusSearch ?? "";
    staticQuery.page = this.state.page ?? "";
    if (this.state.inputSearch !== "" && this.state.filterOption !== "") {
      staticQuery[this.state.filterOption] = this.state.inputSearch;
    } else staticQuery[this.state.filterOption] = "";
    this.setState({ query: staticQuery }, () => {
      pushToFilteredPage(this.props, { ...this.state.query });
      this.getTaskLists();
    });
  };

  handleIncrementPage = () => {
    if (this.state.taskLists.length >= 10) {
      this.setState({ page: this.state.page + 1 }, () =>
        this.handleFilterStatus()
      );
    }
  };

  updateProgress = (id) => {
    this.setState({ progressModal: true, task_id: id });
  };

  handleDelete = async () => {
    let response = await ApiService.patchAPI(
      `contractor/task/delete/${this.state.task_id}`,
      {}
    );
    if (response.success) {
      showSuccess(this, response.message);
      this.setState({ delete_data: {} });
      this.closeModal();
      this.getProjectDetails();
    } else {
      showError(this, response.message);
    }
  };

  editTask = async (data) => {
    this.setState(
      {
        edit: true,
        // name: data?.name,
        // start_date: new Date(data?.start_date),
        // actual_start: new Date(data?.actual_start),
        // end_date: new Date(data?.end_date),

        // tags: data.tags,
        task_id: data.task_id,
        project_id: data.project_id,
        // desc: data.desc,
        // subContractor_name: data.sub_contractor.name,
        // subContractor_id: data.sub_contractor.id,
      },
      () => {
        this.setState({ openTask: true });
      }
    );
  };

  getTaskDetail(id, status) {
    this.setState({
      openTask: true,
      task_id: id,
      status: status,
    });
  }

  accept_reject = async (val, id) => {
    const { rejectReason } = this.state;
    if (val === "reject" && !rejectReason)
      showError(this, "Please enter the reason");
    else {
      this.setState({ rejectBtnLoad: true });
      let response = await ApiService.patchAPI(
        `contractor/task/${val === "reject" ? "rejected" : "approved"}/${id}`,
        {
          notes: rejectReason || "",
        }
      );
      this.setState({ rejectBtnLoad: false });
      if (response.success) {
        showSuccess(this, response.message);
        this.setState({ task_id: null , reject : false});
        this.getTaskLists();
      } else {
        showError(this, response.message);
      }
    }
  };

  reject = (id) => {
    this.setState({ reject: true, task_id: id });
  };

  onChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  setStatus = (status) => {
    this.setState({ statusSearch: status, page: 1 }, () => {
      this.handleFilterStatus();
    });
  };

  closeTimeline = () => {
    this.setState({ isTimeLineOpen: false });
  };

  closeProgressModal = (val) => {
    const {task_id} = this.state
    task_id && this.setState({task_id:null})
    this.setState({progressModal:false})
    val && task_id && this.getTaskLists()
  }

  closeModal = () => {
    this.setState({ openModal: false }, () => {
      this.getTaskLists();
    });
  };

  onClose = () => {
    this.setState(
      {
        openTask: false,
        edit_project: false,
        tags: [],
        desc: "",
        name: "",
        start_date: "",
        actual_start: "",
        subContractor_name: "",
        subContractor_id: "",
        end_date: "",
      },
      () => this.getTaskLists()
    );
  };

  render() {
    let {
      isPageLoading,
      toast,
      response,
      notesOpen,
      toastType,
      inputSearch,
      filterOption,
      statusSearch,
      taskLists,
      page,
      task_id,
      project_id,
      openModal,
      delete_data,
      openTask,
      rejectReason,
      reject,
      rejectBtnLoad,
      cancelTask,
      progressModal,
    } = this.state;
    return isPageLoading ? (
      <div className="spin-loading">
        <Spinner type="border" color="#077e8b" />
      </div>
    ) : (
      <>
        <div style={{ backgroundColor: "#FAFAFA" }}>
          {toast && <ToastList message={response} type={toastType} />}
          <div style={{ padding: "20px", backgroundColor: "#fafafa" }}>
            <div
              className="align-items-center"
              style={{ paddingBottom: "20px" }}
            >
              <div xs="8" className="manage-con">
                <div>
                  <Breadcrumb className="">
                    <BreadcrumbItem onClick={() => {
                      this.props.history.push({
                        pathname :`/admin/projects/detail/${project_id}`,
                        state: {tab:1}
                      })
                    }} className="bread-cursor">
                      {"Project"}
                    </BreadcrumbItem>
                    <BreadcrumbItem
                      style={{ color: "#72569c" }}
                      className="bread-cursor"
                    >
                      {"Tasks"}
                    </BreadcrumbItem>
                  </Breadcrumb>
                </div>
              </div>
            </div>
            <CustomListingContainer>
              <ListingHeaderLayout
                primary_buttonLabel={"Add Task"}
                isPrimary
                label={"Tasks"}
                click={() => {
                  this.setState({ openTask: true });
                }}
              ></ListingHeaderLayout>
              <Row>
                <div className="col new-table">
                  <Card className="shadow card_with_paginator">
                    <Table
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead className="thead-light">
                        <tr>
                          <th scope="col" style={{ paddingLeft: "25px" }}>
                            Name
                          </th>
                          <th scope="col">Assigned To</th>
                          <th scope="col">Due On</th>
                          <th scope="col">Completion</th>
                          <th scope="col">Status</th>
                          <th style={{ width: "5%" }} scope="col"></th>
                        </tr>
                      </thead>
                      {taskLists && taskLists.length ? (
                        <tbody>
                          {taskLists.map((task, i) => (
                            <tr
                              onClick={() =>
                                this.getTaskDetail(task.task_id, task.status)
                              }
                              className="cursor-point"
                              key={i}
                            >
                              <td
                                className="cursor-point capitalize"
                                style={{ width: "20%" }}
                              >
                                <div className="project_list_content">
                                  {capitalize(task.name)}
                                </div>
                              </td>
                              <td>
                                <div className="project_list_content">
                                  {" "}
                                  {capitalize(task.sub_contractor?.name)}{" "}
                                </div>
                              </td>
                              <td>
                                <div className="project_list_content">
                                  {" "}
                                  {showUtcDate(task.end_date)}
                                </div>
                              </td>

                              <td
                                className={
                                  task?.invoice?.status === "paid" ||
                                  task?.invoice?.status === "assigned"
                                    ? "text-capitalize status_accepted general_status_style"
                                    : task?.invoice?.status === "cancelled" ||
                                      task?.invoice?.status === "rejected"
                                    ? "text-capitalize red general_status_style"
                                    : task?.invoice?.status === "created" ||
                                      task?.invoice?.status === "pending"
                                    ? "text-capitalize yellow general_status_style"
                                    : task?.invoice?.status === "completed"
                                    ? "text-capitalize status_completed general_status_style"
                                    : task?.invoice?.status === "closed"
                                    ? "text-capitalize primary general_status_style"
                                    : "text-capitalize"
                                }
                                style={{ fontWeight: "500" }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "4%",
                                  }}
                                >
                                  {" "}
                                  <div>
                                    {" "}
                                    <Progress
                                      color="#0A8080"
                                      value={task.progress}
                                    />
                                  </div>
                                  <div className="project_list_percentage">
                                    {task.progress ?? 0}%
                                  </div>
                                </div>
                              </td>

                              <td>
                                <div
                                  className="project_list_status"
                                  style={{
                                    color: `${
                                      task.status === "completed" ||
                                      task.status === "approved"
                                        ? "#07A84E"
                                        : task.status === "pending"
                                        ? "#FFCC3D"
                                        : task.status === "due"
                                        ? "#0070FD"
                                        : task.status === "rejected" ||
                                          task.status === "cancelled"
                                        ? "#F70e0e"
                                        : "#FFCC3D"
                                    }`,
                                  }}
                                >
                                  {" "}
                                  {task.status === "in_progress"
                                    ? "In Progress"
                                    : capitalize(task.status)}
                                </div>
                              </td>

                              <td
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "flex-end",
                                  gap: "20px",
                                }}
                              >
                                <div
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                >
                                  <ToolTip label="Timeline">
                                    <img
                                      onClick={() =>
                                        this.setState({
                                          isTimeLineOpen: true,
                                          task_id: task.task_id,
                                          project_id: task.project_id,
                                        })
                                      }
                                      style={{ cursor: "pointer" }}
                                      src={projectTimeline}
                                      alt="timeline"
                                    ></img>
                                  </ToolTip>
                                </div>
                                <div
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                  style={{
                                    visibility: [
                                      "approved",
                                      "cancelled",
                                    ].includes(task.status)
                                      ? "hidden"
                                      : [
                                          "completed",
                                          "closed",
                                          "cancelled",
                                          "discontinued",
                                        ].includes(this.state.project.status)
                                      ? "hidden"
                                      : "",
                                  }}
                                  className="project_list_content"
                                >
                                  <UncontrolledDropdown
                                    nav
                                    className="flexy-content position_u"
                                  >
                                    <DropdownToggle
                                      className="pr-0 nav-notice"
                                      nav
                                    >
                                      <Media className="align-items-center">
                                        <img src={showMore} alt="show more" />
                                      </Media>
                                    </DropdownToggle>
                                    <DropdownMenu
                                      className="dropdown-menu-arrow min-width-drop"
                                      right
                                    >
                                      {task.status === "completed" ? (
                                        <>
                                          <DropdownItem
                                          style={styleForDropDown}
                                            onClick={() =>
                                              this.accept_reject(
                                                "accept",
                                                task.task_id
                                              )
                                            }
                                          >
                                            <span>Approve</span>
                                          </DropdownItem>
                                          <DropdownItem
                                          style={styleForDropDown}
                                            onClick={() =>
                                              this.reject(task.task_id)
                                            }
                                          >
                                            <span>Reject</span>
                                          </DropdownItem>
                                        </>
                                      ) : (
                                        <>
                                          <DropdownItem
                                          style={styleForDropDown}
                                            onClick={() =>
                                              this.editTask(task, task.task_id)
                                            }
                                          >
                                            <span>Edit</span>
                                          </DropdownItem>
                                          {task.status === "pending" && (
                                            <DropdownItem
                                            style={styleForDropDown}
                                              onClick={() =>
                                                this.cancelTask(task.task_id)
                                              }
                                            >
                                              <span>cancel</span>
                                            </DropdownItem>
                                          )}
                                          <DropdownItem
                                          style={styleForDropDown}
                                            onClick={() =>
                                              this.updateProgress(task.task_id)
                                            }
                                          >
                                            <span>Update Progress</span>
                                          </DropdownItem>
                                        </>
                                      )}
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      ) : (
                        <tbody>
                          <tr>
                            <td className="no-data">No Tasks Available</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        </tbody>
                      )}
                    </Table>
                  </Card>
                </div>
              </Row>
              <Row className="align-items-center header-div jus-space new-margin-page paginator_box">
                <div className="paginate-flex-box ">
                  <div
                    className="paginate-box"
                    onClick={() => this.handleDecrementPage()}
                  >
                    <p
                      className={
                        +this.state.page === 1
                          ? "page_unavailable"
                          : "page_available"
                      }
                    >
                      Previous
                    </p>
                  </div>
                  <div
                    className="paginate-box"
                    onClick={() => this.handleIncrementPage()}
                  >
                    <p
                      className={
                        taskLists.length < 10
                          ? "page_unavailable"
                          : "page_available"
                      }
                    >
                      Next
                    </p>
                  </div>
                </div>
              </Row>
            </CustomListingContainer>
          </div>

          <Card
            className={
              this.state.isTimeLineOpen ? "exportSideBar" : "toggle-sidebar"
            }
          >
            {this.state.isTimeLineOpen && (
              <TimeLine
                id={this.state.task_id}
                closeTimelineModal={this.closeTimeline}
                from={"task"}
              ></TimeLine>
            )}
          </Card>
          <Card className={notesOpen ? "exportSideBar" : "toggle-sidebar"}>
            {notesOpen && (
              <RejectNotes
                props={this}
                closeNotesModal={this.closeRejectNotes}
              ></RejectNotes>
            )}
          </Card>
          <Card className={openTask ? "exportSideBar" : "toggle-sidebar"}>
            {openTask && (
              <AddProject
                props={this}
                taskId={task_id}
                projectId={this.state.project_id}
                close={this.taskClose}
                type="task"
              />
            )}
          </Card>

          <Modal
            isOpen={reject}
            centered={true}
            style={{ minWidth: "35rem", padding: "2rem" }}
          >
            <div style={{ padding: "20px" }}>
              <div className="conCanMod" style={{ textAlign: "center" }}>
                Reason For Rejection
              </div>
              {/*  */}
              <textarea
                className="text-area-add-project"
                value={rejectReason}
                style={{
                  fontSize: "14px",
                  color: "#212529",
                  border: "1px solid #f0f0f0",
                  marginBottom: "16px",
                }}
                maxLength={500}
                name="notes"
                onChange={(e) => {
                  this.setState({ rejectReason: e.target.value });
                }}
              />
              <div style={{ justifyContent: "center" }} className="flexEnd">
                <Button_without_Border
                  btnLabel={"Cancel"}
                  style={{ marginRight: "20px" }}
                  click={() => {
                    this.setState({ reject: false });
                  }}
                />
                <Custom_Button
                  style={{ minWidth: "50px" }}
                  isPrimary={true}
                  isLoading={rejectBtnLoad}
                  btnLabel={"Reject"}
                  click={() => this.accept_reject("reject", this.state.task_id)}
                />
              </div>
            </div>
          </Modal>
          <Card
            className={progressModal ? "exportSideBar" : "toggle-sidebar"}
            style={{ zIndex: "1000" }}
          >
            {progressModal && (
              <UpdateTask
                closeUpdateModal={this.closeProgressModal}
                taskId={task_id}
                functional_component={true}
                props={this}
              ></UpdateTask>
            )}
          </Card>

          {cancelTask && (
            <CancelModal
              modalState={cancelTask}
              name={task_id}
              type={"task"}
              props={this}
              closeModal={() => {
                this.setState({ cancelTask: false }, () => {
                  this.getTaskLists();
                });
              }}
              id={task_id}
              width={200}
            />
          )}

          {this.state.openModal && (
            <DeleteModal
              modalState={openModal}
              type="task"
              id={this.state.task_id}
              name={delete_data.name}
              closeModal={this.closeModal}
              onSubmit={this.handleDelete}
              props={this}
            />
          )}
        </div>
      </>
    );
  }
}
