import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Col,
  Container,
  Spinner,
} from "reactstrap";
import React, { Component } from "react";
import Logo from "../../assets/img/new Logo/contractor_new_logo.svg";

import ApiService from "constants/ApiService";
import ToastList from "components/Toast/ToastList";
import { showSuccess } from "constants/utils";
import { showError } from "constants/utils";

let token;
class ResetPassword extends Component {
  constructor(props) {
    super(props);
    token = this.props.match.params.token;
    this.state = {
      pType: "password",
      nType: "password",
      password: "",
      conPassword: "",
      toast: false,
      response: "",
      toastType: "",
      isLoading: false,
    };
  }
  handleSignUp = async () => {
    let { password, conPassword } = this.state;
    if (password === "") {
      showError(this, "Please enter  new password");
    } else if (conPassword === "") {
      showError(this, "Please enter confirm password");
    } else if (password !== conPassword) {
      showError(this, "Password field does not match");
    } else {
      let data = {
        new_pass: conPassword,
        token: token,
      };
      let response = await ApiService.postAPI(
        "auth/contractor/reset_password",
        data
      );
      if (response.success) {
        showSuccess(this, response.message);
        this.setState({ isLoading: true });
        setTimeout(
          () =>
            this.props.history.push({
              pathname: "/reset_verify",
              signUp: "reset",
            }),
          1000
        );
      } else {
        showError(this, response.message);
      }
    }
  };
  toggleEyeIcon = (key) => {
    if (key === "pass") {
      this.setState({
        pType: this.state.pType === "password" ? "text" : "password",
      });
    } else if (key === "newPass") {
      this.setState({
        nType: this.state.nType === "password" ? "text" : "password",
      });
    }
  };
  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  toggleCancel = () => {
    this.props.history.push("/login");
  };
  // Toast Handler

  render() {
    let {
      password,
      conPassword,
      pType,
      nType,
      toast,
      response,
      toastType,
      isLoading,
    } = this.state;
    return (
      <>
        {" "}
        <div className="main_content_login new-pro-design">
          <Container className="new-max-width reset-push-top">
            {toast && <ToastList message={response} type={toastType} />}
            <Row className="justify-content-center">
              <Col lg="5" md="7" className="login-flexing">
                <Card className="bg-secondary  border-0 radius-log shadow_div">
                  <CardHeader className="bg-transparent border-0 pad-card-top mt-20">
                    <img
                      className="logo-image"
                      src={Logo}
                      alt="Parknstays Logo"
                    />
                  </CardHeader>
                  <CardBody className="px-lg-5 pad-left-right-2 pad-b2">
                    <div className="text-left text-muted mb-0">
                      <small className="signin-small">Reset Password</small>
                    </div>
                    <Form role="form">
                      <div className="flex-column">
                        <FormGroup className="mb-3 mt-4-5">
                          <label
                            className="form-control-label label-in"
                            htmlFor="input-email"
                          >
                            New Password*
                          </label>
                          <InputGroup className="input-group-alternative new-input-login">
                            <Input
                              type={pType}
                              name="password"
                              value={password}
                              autoComplete="new-password"
                              onChange={this.handleInputChange}
                              className="email-new-in pad-new-in"
                            />
                            <InputGroupAddon addonType="append">
                              <InputGroupText
                                onClick={() => this.toggleEyeIcon("pass")}
                                className="new-border"
                              >
                                <i
                                  className={
                                    pType === "password"
                                      ? "fa fa-eye-slash"
                                      : "fa fa-eye"
                                  }
                                ></i>
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        </FormGroup>
                        <FormGroup className="mb-3 mt-1">
                          <label
                            className="form-control-label label-in"
                            htmlFor="input-email"
                          >
                            Confirm Password*
                          </label>
                          <InputGroup className="input-group-alternative new-input-login">
                            <Input
                              type={nType}
                              name="conPassword"
                              value={conPassword}
                              autoComplete="new-confirm-password"
                              onChange={this.handleInputChange}
                              className="email-new-in pad-new-in"
                            />
                            <InputGroupAddon addonType="append">
                              <InputGroupText
                                onClick={() => this.toggleEyeIcon("newPass")}
                                className="new-border"
                              >
                                <i
                                  className={
                                    nType === "password"
                                      ? "fa fa-eye-slash"
                                      : "fa fa-eye"
                                  }
                                ></i>
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        </FormGroup>
                      </div>
                    </Form>
                    <div className="flexy-content1">
                      <Button
                        className="button-wrapping mt-3 reset-btn"
                        color="primary"
                        type="button"
                        onClick={() => this.handleSignUp()}
                      >
                        {isLoading ? (
                          <div className="spinner-btn">
                            <Spinner type="border" color="#077e8b" />
                          </div>
                        ) : (
                          "Reset Password"
                        )}
                      </Button>
                      <div className="mt-3">
                        <div
                          className="filter-wrap reset-prop change-reset-pass"
                          onClick={() => this.toggleCancel()}
                        >
                          <label className="profile-cancel">Cancel</label>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}
export default ResetPassword;
