import React, { Component } from "react";
import { Card, CardBody, Modal, Spinner } from "reactstrap";
import { returnSearchObject } from "constants/utils";
import "react-datepicker/dist/react-datepicker.css";
import ApiService from "constants/ApiService";
import {
    showSuccess,
    Isvalidname,
    showError,
    uploadCloudinary,
    isValidEmail,
    getUSFormat,
    cleanPhoneNumber
} from "constants/utils";

import CustomModal from "components/CustomModal/CustomModal";
import { CustomInputFields } from "components/FilterFields/Filterfields";
import { CustomDropDownSearch } from "components/FilterFields/Filterfields";
import closeIcon from "../../assets/img/icons/closeIcon.svg";
import ToastList from "components/Toast/ToastList";
import { Custom_Button } from "components/Buttons/Button";

class AddOtherPros extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            phone: "",
            contractor_id: "",
            client_name: "",
            clientsList: [],
            btnLoad: false,
            edit: false,
            initAddProjectLoading: true,
            clientAdd: false,
            disabled: false,
            isApiCallPending: false,
            listsLoad: false,
            listsBoxOpen: false,
            isNameAlreadyPresent: false,
            timeOut: "",
            addMemberModal: false,
            toast: false,
            toastType: "",
        };
    }

    componentDidMount = async () => {
        this.setState({ initAddProjectLoading: true });
        if (this.props.leadID) {
            this.setState({
                name: this.props.leadID.name,
                email: this.props.leadID.email,
                phone: this.props.leadID.phone,
            });
        } else {
            this.setState({
                name: "",
                email: "",
                phone: "",
                contractor_id: "",
            });
        }
        this.setState({ initAddProjectLoading: false });
    };

    closeModal = () => {
        this.props.close();
    };
    createPros = async () => {
        let {
            name,
            email,
            phone,
            clientsList,
            contractor_id
        } = this.state;
        if (!name) return showError(this.props.props, "Please enter a name");
        else if (!contractor_id || !name) return showError(this.props.props, "Please select the valid member");
        else if (!email) return showError(this.props.props, "Please enter a email");
        else if (!isValidEmail(email))
            return showError(this.props.props, "Please enter a valid email");
        else if (!phone)
            return showError(this.props.props, "Please enter a phone number");
        else if (cleanPhoneNumber(phone).length !== 10)
            return showError(this.props.props, "Please enter a valid phone number");
        else {
            let data = {};
            data.contractor = { id: contractor_id }
            this.props.complete(data);
        }
    };

    getListDetail = async (data) => {
        this.setState(
            {
                name: data.name,
                email: data.email,
                phone: data.phone.national_number,
                contractor_id: data.contractor_id
            },
            () => {
                this.setState({ listsBoxOpen: false, isNameAlreadyPresent: true });
            }
        );
    };

    handleInputChange = (e, from) => {
        this.setState({ [from]: e }, () => {
        });
    };

    getClientLists = async (e) => {
        this.setState({
            listsLoad: true,
            isNameAlreadyPresent: false,
            email: "",
            phone: "",
        });
        let searchTerm = e || "";
        clearTimeout(this.state.timeOut);
        this.setState({ name: searchTerm });
        const timeOut = setTimeout(async () => {
            let response = await ApiService.getAPI(
                `contractor/notActive?status=active&name=${searchTerm}`
            );
            this.setState({ initAddProjectLoading: false });
            if (response.success) {
                let { contractors } = response.data;
                this.setState(
                    {
                        clientsList: contractors,
                        listsLoad: false,
                    },
                    () => {
                        this.state?.clientsList?.length
                            ? this.setState({ listsBoxOpen: true })
                            : this.setState({ listsBoxOpen: false });
                    }
                );
            }
        }, 1000);
        this.setState({ timeOut: timeOut });
    };

    closeProsModal = () => {
        this.props.close();
    };
    closeAddMemberModal = () => {
        this.setState({ addMemberModal: false });
    }
    render() {
        const {
            name,
            btnLoad,
            email,
            phone,
            initAddProjectLoading,
            clientsList,
            isApiCallPending,
            addMemberModal,
            toast,
            response,
            toastType
        } = this.state;

        return initAddProjectLoading ? (

            <div className="spin-loading">
                <Spinner
                    style={{ height: "30px", width: "30px" }}
                    type="border"
                    color="#012A4D"
                />
            </div>
        ) : (
            <>
                <CustomModal
                    btnLoad={btnLoad}
                    type={"Add Pros"}
                    create={this.createPros}
                    close={this.closeProsModal}
                // closeOthers={this.closeOthers}
                >
                    <label className="customLabel">Name*</label>
                    <CustomDropDownSearch
                        style={{ marginBottom: "20px", width: "100%" }}
                        className="customDropDownSearch"
                        toSearch="name"
                        width={"100%"}
                        placeHolder="Enter the member name"
                        filteredData={clientsList}
                        onChange={this.getClientLists}
                        listClick={this.getListDetail}
                        value={name}
                    />
                    <>
                        <label className="customLabel">Email*</label>
                        <CustomInputFields
                            style={{ marginBottom: "20px" }}
                            inputValue={email}
                            type="text"
                            change={this.handleInputChange}
                            from="email"
                            disabled={this.state.isNameAlreadyPresent}
                            placeHolder="Enter the email"
                        />
                        <label className="customLabel">Phone Number*</label>
                        <CustomInputFields
                            style={{ marginBottom: "20px" }}
                            inputValue={getUSFormat(phone)}
                            type="text"
                            change={this.handleInputChange}
                            from="phone"
                            disabled={this.state.isNameAlreadyPresent}
                            placeHolder="Enter the phone number"
                        />
                    </>
                </CustomModal>
            </>
        );

    }
}
export default AddOtherPros;
