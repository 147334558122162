import React, { useEffect, useState } from "react";
import { Select, Input } from "antd";
import { ifDecimal } from "constants/utils";
function PreTabInputField(props) {
  const [type, setType] = useState(null);
  const [value, setValue] = useState(null);
  const [totalAmt, setTotalAmt] = useState(props.totalAmt);

  useEffect(() => {
    setType(props.isPercentage);
    setValue(props.value);
  }, [props.isPercentage, props.value]);

  useEffect(() => {
  }, [props.splitUp]);

  function onchangeValue(e) {
    const inputValue = e.target.value;
    const numericValue = parseFloat(inputValue);
    if (isNaN(numericValue)) {
      setValue(inputValue);
      return;
    }
    const percentageNumeric = totalAmt * (numericValue / 100);
    const splt = [...props.splitUp];
    const i = props.index;
    splt.splice(i, 1);
    const amt = splt
      .reduce((acc, obj) => acc + obj.amount.amount, 0)
      .toFixed(2);
    const rem = (totalAmt - +amt).toFixed(2);
    const previousValue = parseFloat(props.value);
    if (numericValue <= previousValue) {
      setValue(inputValue);
      props.onValueChange({
        value: inputValue,
        type: type,
        index: props.index,
      });
      return;
    }
    
    if (type === "dollar" && numericValue > props.remainingAmt && props.splitUp.length > 1 ) {
      props.errMsg(`Please enter the maximum dollar up to $${rem}`);
      return;
    }
    if( type === "dollar" && numericValue > totalAmt && props.splitUp.length === 1 ) {
      props.errMsg(
        `Please enter the maximum amount $${totalAmt}`
      );
      return
    }
    if(type === "percentage" && props.splitUp.length === 1 && (numericValue > 100)) {
      props.errMsg(
        `Please enter the maximum percentage up to 100`
      );
      return;
    }
    if (
      type === "percentage" &&
      percentageNumeric.toFixed(2) > props.remainingAmt && 
      props.splitUp.length > 1
    ) {
      props.errMsg(
        `Please enter the maximum percentage up to ${ifDecimal(
          (+rem / totalAmt) * 100
        )}`
      );
      return;
    }

    setValue(inputValue);
    props.onValueChange({
      value: inputValue,
      type: type,
      index: props.index,
    });
  }

  const handleChange = (val) => {
    props.onValueChange({
      value: value,
      type: val,
      index: props.index,
      changed: true,
    });
    setType(val);
  };

  return (
    <div className="inputTextPreTab">
      <Select
        value={type === "percentage" ? "%" : "$"}
        onChange={handleChange}
        options={[
          {
            value: "percentage",
            label: "%",
          },
          {
            value: "dollar",
            label: "$",
          },
        ]}
      />
      <Input className="preTabInput" value={value} onChange={onchangeValue} />
    </div>
  );
}

export default PreTabInputField;
