import React, { Component } from "react";
import pksLogo from "../../../assets/img/new Logo/contractor_new_logo.svg";
import {
  InputGroup,
  Dropdown,
  Input,
  UncontrolledDropdown,
  DropdownToggle,
  Button,
  Media,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import ProjectLists from "./projectLists";
import routes from "routes.js";
import AcceptOrRejectProposal from "views/externalPages/AcceptOrRejectProposal";
import ClientProjectDetail from "views/externalPages/client/clientProjectDetail";
import ClientPayment from "views/externalPages/client/clientPaymentPage";

import { Redirect, Route, Switch } from "react-router-dom";
import { HashRouter } from "react-router-dom/cjs/react-router-dom.min";

export default class ClientPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getRoutes = () =>
    this.props.Children?.map((child, key) => (
      <Route
        path={`${this.props.path}${child.path}`}
        component={child.component}
        key={key}
      />
    ));

  getProfile = () => {
    this.props.props.history.push("/#/client/123/profile");
    window.location.reload();
  };

  render() {
    return (
      <>
        <div className="clientContainer">
          <img style={{ borderRadius: "5px" }} src={pksLogo} alt="logo"></img>
          <div className="profileName" onClick={this.getProfile}>
            Profile
          </div>
        </div>
        <div>
          <HashRouter>
            <Switch>{this.getRoutes()}</Switch>
          </HashRouter>
        </div>
      </>
    );
  }
}
