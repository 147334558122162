import React, { Component } from "react";
import moment from "moment";
import { setCookie } from "../constants/utils";
import AdminLayout from "../layouts/Admin";

class PrivateRouter extends Component {
  componentDidMount() {
    let act = moment().format("DD MMM, YYYY") + " - " + moment().format("hh:mm  a");
    setCookie("lastActivity", act);
  }
  render = () => {
    return <AdminLayout />;
  };
}

export default PrivateRouter;
