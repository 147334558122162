import React, { Component } from "react";
import {
    Card,
    Table,
    Container,
    Row,
    Breadcrumb,
    Col,
    Button,
    InputGroup,
    Spinner,
    DropdownMenu,
    DropdownItem,
    Media,
    UncontrolledDropdown,
    DropdownToggle,
    Dropdown,
    Input,
} from "reactstrap";
import { Drawer } from "antd";

import projectTimeline from "../../../src/assets/img/brand/projectTimeline.svg";

import ApiService from "constants/ApiService";

import "react-datepicker/dist/react-datepicker.css";

import { getIsoString, filterQuery } from "constants/utils";

import ToastList from "components/Toast/ToastList";
import ControllableProgressBar from "components/Progress/ControllableProgressbar";
import { showError } from "constants/utils";
import { returnSearchObject } from "constants/utils";
import { pushToFilteredPage } from "constants/utils";
import { buildQueryString } from "constants/utils";
import moment from "moment";

import showMore from "../../assets/img/brand/showMore.svg";
import { showSuccess } from "constants/utils";
import { Progress } from "reactstrap";
import TimeLine from "components/Timeline/Timeline";
import { uploadCloudinary } from "constants/utils";
import Progressbar from "components/Progress/Progressbar";
import { CustomListingContainer } from "components/Container/CustomListingContainer";
import { ListingHeaderLayout } from "components/Container/CustomListingContainer";
import { CustomSearch } from "components/FilterFields/Filterfields";
import { CustomDropDown } from "components/FilterFields/Filterfields";
import { ListingBodyLayout } from "components/Container/CustomListingContainer";
import { Button_without_Border } from "components/Buttons/Button";
import CustomModal from "components/CustomModal/CustomModal";
import { showUtcDate } from "constants/utils";
let overlap = document.querySelector(":root");

class NewChangeOrder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isTimeLineOpen: false,
            requestList: [],
            project_list: [],
            total: 0,
            filterToggle: false,
            from: "",
            to: "",
            page: 1,
            open: false,
            client_id: "",
            inputSearch: "",
            filterOption: "",
            edit_detail: {},
            prevProgress: "0",
            task_detail: {},
            statusSearch: "",
            toast: false,
            response: "",
            image: [],
            iamge_name: "",
            toastType: "",
            isLoading: true,
            clientsList: [],
            btnLoad: false,
            task_list: [],
            workOrder_id:"",
            workOrdersList:[],
            query: {},
            name: "",
            notes: "",
            client: {},
            start_date: "",
            actual_start: "",
            imageLoading: false,
            client_name: "",
            inputText: "",
            items: [],
            openEditModal: false,
            desc: "",
            tags: [],
            end_date: "",
            requestId: "",
            openModal: false,
            delete_data: {},
            cancelModal: false,
            taskId: "",
            timoutId: "",
            input_value: false,
            filters: ["select", "name"],
        };
    }
    componentDidMount = async () => {
        // overlap.style.setProperty("--width", "600px");
        this.setQueryStatus();
    };

    componentWillUnmount() {
        overlap.style.setProperty("--width", "450px");
    }

    closeModal = () => {
        this.setState({ openModal: false });
    };


    returnCurrentFilterOption = (obj) => {
        return (
            this.state.filters.filter((ele) => Object.keys(obj).includes(ele))[0] ??
            ""
        );
    };

    initCheckClerFilters = () => {
        this.state.filters.includes(this.state.filterOption) ||
            this.state.statusSearch ||
            this.state.inputSearch ||
            this.state.page > 1
            ? this.setState({ input_value: true })
            : this.setState({ input_value: false });
    };

    setQueryStatus = () => {
        let queryStatus = this.props.location.search;
        let queryObj = returnSearchObject(queryStatus);
        if (queryObj) {
            this.setState(
                {
                    inputSearch: queryObj[this.returnCurrentFilterOption(queryObj)] ?? "",
                    filterOption: this.returnCurrentFilterOption(queryObj),
                    statusSearch: queryObj.status ?? "",
                    page: parseInt(queryObj.page) ?? "",
                    query: returnSearchObject(queryStatus),
                },
                () => {
                    this.initCheckClerFilters();
                    pushToFilteredPage(this.props, { ...this.state.query });
                    this.getProjectList();
                }
            );
        } else {
            this.setState(
                {
                    query: {
                        ...queryObj,
                        page: 1,
                        limit: 10,
                    },
                },
                () => {
                    pushToFilteredPage(this.props, { ...this.state.query });
                    this.getProjectList();
                }
            );
        }
    };
    redirectDetailPage = (id) => {
        this.props.history.push(`projects/detail/${id}`);
    };

    setPage = (page) => {
        this.props.history.push(page);
    };
    dateChangeHandler = (e, value) => {
        this.setState({ [value]: e });
    };

    closeTimeline = () => {
        this.setState({ isTimeLineOpen: false });
    };

    getProjectList = async () => {
        let { filterOption, statusSearch, inputSearch, from, to } = this.state;
        let queryParams = "";
        if (filterOption !== "" && inputSearch !== "")
            queryParams += filterQuery(filterOption, inputSearch);
        if (statusSearch !== "") {
            queryParams += filterQuery("status", statusSearch);
        }
        if (from !== "") queryParams += filterQuery("from", getIsoString(from));
        if (to !== "") queryParams += filterQuery("to", getIsoString(to));
        if (from > to) {
            showError(this, "Please provide valid From and To date");
        } else {

            let response = await ApiService.getAPI(
                `contractor/workOrder${buildQueryString(this.state.query)}`
            );

        
            if (response.success) {
         
                this.setState({
                    workOrdersList: response.data,
                    isLoading: false,
                });
            }
        }
    };
    createProjectHandler = async () => {
        let { edit_detail, notes, prevProgress } = this.state;

        this.setState({ btnLoad: true });
        let data = {
            notes: notes,
            progress: edit_detail.progress,
        };
        let response = await ApiService.patchAPI(
            `contractor/task/progress/${edit_detail.task_id}`,
            data
        );

        if (response.success) {
            showSuccess(this, response.message);
            this.setState({
                btnLoad: false,
                openEditModal: false,
                edit_detail: {},
            });
            this.getProjectList();
            this.kanbanBoardData();
        } else {
            this.setState({ btnLoad: false });
            showError(this, response.message);
        }

        // if (+edit_detail.progress > +prevProgress) {
        //   this.setState({ btnLoad: true });
        //   let data = {
        //     notes: notes,
        //     progress: edit_detail.progress,
        //   };
        //   let response = await ApiService.patchAPI(
        //     `contractor/task/progress/${edit_detail.task_id}`,
        //     data
        //   );

        //   if (response.success) {
        //     showSuccess(this, response.message);
        //     this.setState({
        //       btnLoad: false,
        //       openEditModal: false,
        //       edit_detail: {},
        //     });
        //     this.getProjectList();
        //     this.kanbanBoardData();
        //   } else {
        //     this.setState({ btnLoad: false });
        //     showError(this, response.message);
        //   }
        // } else  {
        //   showError(this, `${prevProgress}% of work is already done`);
        // }

        // if (!notes || !edit_detail.progress)
        //   return showError(this, "Please fill all the required fields");
    };

    handleTagsChange = (tags) => {
        this.setState({ tags: tags });
    };
    handleInputChange = (event) => {
        this.setState({ inputText: event.target.value });
    };

    handleEnterKey = (event) => {
        if (event.key === "Enter" && this.state.inputText.trim() !== "") {
            this.setState((prevState) => ({
                inputText: "",
                items: [...prevState.items, prevState.inputText],
            }));
        }
    };
    toggleFilterMenu = () => {
        this.setState({ filterToggle: !this.state.filterToggle });
    };

    onChangeHandler = (e, from) => {
        this.setState(
            { [from]: e === "select" ? "" : e, input_value: true },
            () => {
                this.checkForClearFilter();
            }
        );
        clearTimeout(this.state.timoutId);
        const timoutId = setTimeout(() => {
            this.state.filterOption && this.handleSearch();
        }, 1000);
        this.setState({ timoutId });
    };

    updateStateValues = (key, value) => {
        this.setState(
            {
                [key]: value,
            },
            function () {
                if (value === null) {
                    this.setState({
                        [key]: "",
                    });
                }
            }
        );
    };
    handleOnToChange = (e) => {
        this.updateStateValues("to", e ? new Date(e) : '');
    };
    handleOnFromChange = (e) => {
        this.updateStateValues("from", e ? new Date(e) : '');
    };
    handleIncrementPage = () => {
        if (this.state.task_list.length >= 10) {
            this.setState({ page: this.state.page + 1, input_value: true }, () =>
                this.handleFilterStatus()
            );
        }
    };
    handleDecrementPage = () => {
        if (this.state.page > 1) {
            this.setState(
                {
                    page: this.state.page - 1,
                    input_value: this.state.page - 1 === 1 ? false : true,
                },
                () => this.handleFilterStatus()
            );
        } else this.setState({ input_value: false });
    };
    updateProgress = (value) => {
        this.setState((prevState) => ({
            edit_detail: {
                ...prevState.edit_detail,
                progress: value,
            },
        }));
    };

    capitalizeFirstLetter = (str) => {
        if (!str) return "";
        return str.charAt(0).toUpperCase() + str.slice(1);
    };
    imageHandler = async (e, subStatus) => {
        let { image } = this.state;
        let file = e.target.files[0];

        if (e.target.files.length > 0) {
            this.setState({ imageLoading: true });
        }

        if (
            file.type === "image/png" ||
            file.type === "image/jpg" ||
            file.type === "image/jpeg" ||
            file.type === "application/pdf"
        ) {
            if (file.size < 1000000) {
                let data = await uploadCloudinary(file, "host");
                if (data.url) {
                    let imgArr = [...image];
                    let imageObj = {
                        format: data.format,
                        public_id: data.public_id,
                        version: `v${data.version}`,
                        type: "image",
                    };
                    imgArr.push(imageObj);
                    this.setState({
                        image: imgArr,
                        imageLoading: false,
                    });
                } else {
                    showError(this, "Could not upload image");
                    this.setState({ imageLoading: false });
                }
            } else {
                showError(this, "File size should not exceeds 1 MB");
                this.setState({ imageLoading: false });
            }
        } else {
            showError(this, "Invalid Format");
            this.setState({ imageLoading: false });
        }
    };

    deleteImage = () => {
        this.setState({ image: [], image_name: "" });
    };

    handleFilterStatus = () => {
        let staticQuery = returnSearchObject(this.props.location.search);
        staticQuery.from = getIsoString(this.state.from) ?? "";
        staticQuery.to = getIsoString(this.state.to) ?? "";
        staticQuery.status = this.state.statusSearch ?? "";
        staticQuery.page = this.state.page ?? "";
        for (let key in staticQuery) {
            if (key === "email" || key === "name" || key === "select") {
                delete staticQuery[key];
            }
        }
        this.setState({ query: staticQuery }, () => {
            if (this.state.filterOption !== "select") {
                pushToFilteredPage(this.props, { ...this.state.query });
                this.getProjectList();
            }
        });
    };

    handleSearch = () => {
        this.setState({ page: 1 }, () => {
            this.handleFilterStatus();
        });
    };

    closeUpdateModal = (value) => {
        this.setState({ openEditModal: false });
        this.getProjectList();
    };

    handleReset = () => {
        let self = this;
        self.setState(
            {
                from: "",
                to: "",
                statusSearch: "",
                filterOption: "select",
                inputSearch: "",
                page: 1,
                limit: 10,
                query: {
                    page: 1,
                    limit: 10,
                },
                input_value: false,
            },
            () => {
                pushToFilteredPage(this.props, { ...this.state.query });
                this.getProjectList();
            }
        );
    };

    checkForClearFilter = () => {
        this.setState({
            input_value:
                !this.state.statusSearch &&
                    (this.state.filterOption === "select" || !this.state.filterOption) &&
                    !this.state.inputSearch
                    ? false
                    : true,
        });
    };

    setStatus = (status) => {
        this.setState(
            { statusSearch: status === "all" ? "" : status, page: 1 },
            () => {
                this.checkForClearFilter();
                this.handleFilterStatus();
            }
        );
    };

    redirectToAddRequest = async () => {
        let response = await ApiService.getAPI(`contractor/client?status=active`);

        if (response.success) {
            let { clients } = response.data;
            this.setState({
                clientsList: clients,
                edit: false,
                open: true,
            });
        }
    };

    editRequest = (data) => {
        this.props.history.push(`/admin/request/update/${data?.open_request_id}`);
    };

    onClose = () => {
        overlap.style.setProperty("--width", "450px");
        this.setState(
            {
                tags: [],
                desc: "",
                name: "",
                client: {},
                start_date: "",
                actual_start: "",
                end_date: "",
                client_name: "",
            },
            () => {
                this.setState({ open: false });
            }
        );
    };
    taskDetail = async (data) => {
        this.setState(
            {
                task_detail: data,
            },
            () => {
                overlap.style.setProperty("--width", "600px");
                this.setState({ open: true });
            }
        );
    };
    editTask = async (data) => {
        this.setState({
            taskId: data.task_id,
            openEditModal: true,
        });
    };
    cancelRequest = async (request) => {
        this.setState({ cancelModal: true, requestId: request.open_request_id });
    };

    resendRequest = async (request) => {
        let response = await ApiService.patchAPI(
            `contractor/open_request/resend/${request.open_request_id}`
        );
        if (response.success) {
            showSuccess(this, response.message);
            this.getProjectList();
        } else {
            showError(this, response.message);
        }
    };
    resendInvoice = async (request) => {
        let response = await ApiService.patchAPI(
            `contractor/open_request/invoice/${request.open_request_id}`
        );
        if (response.success) {
            showSuccess(this, response.message);
            this.getProjectList();
        } else {
            showError(this, response.message);
        }
    };

    setPageValue = (value) => {
        value === "overview" && this.setPage("tasks");
    };

    render() {
        let {
            requestList,
            isLoading,
            notes,
            page,
            statusSearch,
            toast,
            response,
            toastType,
            isTimeLineOpen,
            task_list,
            task_detail,
            edit_detail,
            openEditModal,
            input_value,
            btnLoad,
            inputSearch,
            filterOption,
            filters,
            workOrdersList,
            open,
        } = this.state;
        return isLoading ? (
            <div className="spin-loading">
                <Spinner type="border" color="#077e8b" />
            </div>
        ) : (
            <>
                {/* <DeleteModal
          modalState={openModal}
          type="project"
          name={delete_data.name}
          closeModal={this.closeModal}
          onSubmit={this.handleDelete}
        /> */}
                <div style={{ padding: "20px", backgroundColor: "#fafafa" }}>
                    {toast && <ToastList message={response} type={toastType} />}
                    <CustomListingContainer>
                        <ListingHeaderLayout label={"Change Orders"}>
                            <CustomDropDown
                                width={"126px"}
                                setStatus={this.setPageValue}
                                status={"Change Orders"}
                                statusList={filters}
                            />
                        </ListingHeaderLayout>
                        <div style={{ margin: "24px" }}>
                            <div
                                style={{
                                    display: "grid",
                                    gridTemplateColumns: "35% 65%",
                                    marginBottom: "1.8rem",
                                }}
                            >
                                <div
                                    style={{ display: "grid", gridTemplateColumns: "65% 45%" }}
                                >
                                    <CustomSearch
                                        style={{ marginRight: "20px" }}
                                        options={filters}
                                        change={this.onChangeHandler}
                                        filterOption={filterOption || "Select"}
                                        inputSearch={inputSearch}
                                    />
                                    <CustomDropDown
                                        width={"130px"}
                                        setStatus={this.setStatus}
                                        status={statusSearch}
                                        statusList={[
                                            "all",
                                            "accepted",
                                            "cancelled",
                                            "created"

                                        ]}
                                    />
                                </div>
                                {input_value && (
                                    <div className="right_filter_container">
                                        <Button_without_Border
                                            click={this.handleReset}
                                            btnLabel={"clear_filter"}
                                        ></Button_without_Border>
                                    </div>
                                )}
                            </div>
                        </div>
                        <Row>
                            <div className="col new-table">
                                <Card className="shadow card_with_paginator">
                                    <Table className="align-items-center table-flush" responsive>
                                        <thead className="thead-light">
                                            <tr>
                                                <th scope="col">Title</th>
                                                <th scope="col">Assigned To</th>
                                                <th scope="col">CreatedAt</th>
                                                <th scope="col">Due On</th>
                                                <th scope="col">Amount</th>
                                                <th scope="col">Status</th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        {workOrdersList && workOrdersList.length !== 0 ? (
                                            <tbody>
                                                {workOrdersList.map((workOrder, i) => (
                                                    <tr key={i}>
                                                        <td
                                                            className="cursor-point capitalize"
                                                            style={{ width: "100px" }}
                                                        >
                                                            <div className="add_eclipse">
                                                                {this.capitalizeFirstLetter(workOrder.name)}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="add_eclipse">
                                                                {" "}
                                                                {this.capitalizeFirstLetter(
                                                                    workOrder.sub_contractor.name
                                                                )}
                                                            </div>
                                                        </td>

                                                        <td>
                                                            <div className="add_eclipse">
                                                                {" "}
                                                                {showUtcDate(workOrder.createdAt)}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="add_eclipse">
                                                                {" "}
                                                                {showUtcDate(workOrder.due_date)}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="add_eclipse">
                                                                {" "}
                                                                ${workOrder.amount}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div
                                                                className="project_list_status"
                                                                style={{
                                                                    color: `${workOrder.status === "completed" ||
                                                                            workOrder.status === "approved"
                                                                            ? "#07A84E"
                                                                            : workOrder.status === "pending"
                                                                                ? "#FF4949"
                                                                                : "#FFCC3D"
                                                                        }`,fontWeight:"500"
                                                                }}
                                                            >
                                                                {" "}
                                                                {this.capitalizeFirstLetter(
                                                                    workOrder.status === "in_progress"
                                                                        ? "in progress"
                                                                        : workOrder.status
                                                                )}
                                                            </div>
                                                        </td>

                                                        <td
                                                            style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "flex-end",
                                                                gap: "10px",
                                                            }}
                                                        >
                                                            <div>
                                                                {" "}
                                                                <img
                                                                    onClick={() =>
                                                                        this.setState({
                                                                            isTimeLineOpen: true,
                                                                            workOrder_id: workOrder.workOrder_id,
                                                                        })
                                                                    }
                                                                    style={{ cursor: "pointer" }}
                                                                    src={projectTimeline}
                                                                    alt="timeline"
                                                                ></img>
                                                            </div>
                                                            <div className="project_list_content">
                                                                <UncontrolledDropdown
                                                                    nav
                                                                    className="flexy-content position_u"
                                                                >
                                                                    <DropdownToggle
                                                                        className="pr-0 nav-notice"
                                                                        nav
                                                                    >
                                                                        <Media className="align-items-center">
                                                                            <img src={showMore} alt="show more" />
                                                                        </Media>
                                                                    </DropdownToggle>
                                                                    <DropdownMenu
                                                                        className="dropdown-menu-arrow min-width-drop"
                                                                        right
                                                                    >
                                                                        <DropdownItem
                                                                            onClick={() => this.taskDetail(workOrder)}
                                                                        >
                                                                            <span>Edit</span>
                                                                        </DropdownItem>
                                                                        {workOrder.status !== "approved" && (
                                                                            <DropdownItem
                                                                                onClick={() => this.editTask(workOrder)}
                                                                            >
                                                                                <span>Update</span>
                                                                            </DropdownItem>
                                                                        )}
                                                                    </DropdownMenu>
                                                                </UncontrolledDropdown>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        ) : (
                                            <tbody>
                                                <tr>
                                                    <td className="no-data">No Work Orders Available</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                  
                                                </tr>
                                            </tbody>
                                        )}
                                    </Table>
                                </Card>
                            </div>
                        </Row>
                        <Row className="align-items-center header-div jus-space new-margin-page paginator_box">
                            <div className="paginate-flex-box ">
                                <div
                                    className="paginate-box"
                                    onClick={() => this.handleDecrementPage()}
                                >
                                    <p
                                        className={
                                            page !== 1 ? "page_available" : "page_unavailable"
                                        }
                                    >
                                        Previous
                                    </p>
                                </div>
                                <div
                                    className="paginate-box"
                                    onClick={() => this.handleIncrementPage()}
                                >
                                    <p
                                        className={
                                            task_list.length < 10
                                                ? "page_unavailable"
                                                : "page_available"
                                        }
                                    >
                                        Next
                                    </p>
                                </div>
                            </div>
                        </Row>
                    </CustomListingContainer>
                    <Card
                        className={open ? "exportSideBar" : "toggle-sidebar"}
                        style={{ zIndex: "1000" }}
                    >
                        {open && (
                            <CustomModal onlyView close={this.onClose} type="Task Detail">
                                <div>
                                    <div style={{ display: "flex", gap: "3%" }}>
                                        <div>
                                            {" "}
                                            <Progressbar
                                                progress={task_detail.progress ?? 0}
                                                size={70}
                                            />
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                gap: "15%",
                                            }}
                                        >
                                            <div
                                                style={{}}
                                                onClick={() => this.tagClick(task_detail.status)}
                                                className="task_sub_drawer_title"
                                            >
                                                {this.capitalizeFirstLetter(task_detail.name)}
                                            </div>
                                            <div className="task_drawer_desc">
                                                {this.capitalizeFirstLetter(task_detail.desc)}
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        style={{
                                            display: "flex",
                                            marginTop: "1.5rem",
                                            gap: "2rem",
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "0.7rem",
                                            }}
                                        >
                                            <div className="task_drawer_data">Start Date</div>
                                            <div
                                                className="task_drawer_data"
                                                style={{ color: "#333333", weight: "500" }}
                                            >
                                                {showUtcDate(task_detail.start_date)}
                                            </div>
                                        </div>
                                        <div class="vertical-line"></div>

                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "0.7rem",
                                            }}
                                        >
                                            <div className="task_drawer_data">Actual Start</div>
                                            <div
                                                className="task_drawer_data"
                                                style={{ color: "#333333", weight: "500" }}
                                            >
                                                {showUtcDate(task_detail.actual_start)}
                                            </div>
                                        </div>
                                        <div class="vertical-line"></div>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "0.7rem",
                                            }}
                                        >
                                            <div className="task_drawer_data">End Date</div>
                                            <div
                                                className="task_drawer_data"
                                                style={{ color: "#333333", weight: "500" }}
                                            >
                                                {showUtcDate(task_detail.end_date)}
                                            </div>
                                        </div>
                                        <div class="vertical-line"></div>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "0.7rem",
                                            }}
                                        >
                                            <div className="task_drawer_data">Status</div>
                                            <div
                                                className="task_drawer_data"
                                                style={{
                                                    color: `${task_detail.status === "completed" ||
                                                            task_detail.status === "approved"
                                                            ? "#07A84E"
                                                            : task_detail.status === "pending"
                                                                ? "#FF4949"
                                                                : task_detail.status === "due"
                                                                    ? "#0070FD"
                                                                    : "#FFCC3D"
                                                        }`,
                                                    weight: "500",
                                                }}
                                            >
                                                {this.capitalizeFirstLetter(
                                                    task_detail?.status === "in_progress"
                                                        ? "In progress"
                                                        : task_detail.status
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <div
                                            className="desc_heading"
                                            style={{
                                                paddingBottom: "1rem",
                                                color: "#333333",
                                                paddingTop: "1.4rem",
                                            }}
                                        >
                                            Tags
                                        </div>

                                        <div
                                            style={{
                                                display: "flex",
                                                gap: "2%",
                                                marginBottom: "1%",
                                                marginTop: "0.7%",
                                            }}
                                            className="desc_sub_text"
                                        >
                                            {task_detail.tags && task_detail.tags?.length > 0 ? (
                                                task_detail.tags.map((val) => (
                                                    <div
                                                        style={{
                                                            borderRadius: "30px",
                                                            background: "#EFEFEF",
                                                            padding: "5px 10px 5px 13px",
                                                        }}
                                                    >
                                                        <span>{val}</span>
                                                    </div>
                                                ))
                                            ) : (
                                                <div></div>
                                            )}
                                        </div>
                                    </div>
                                    <hr />
                                </div>
                            </CustomModal>
                        )}
                    </Card>


                    <Card
                        className={
                            this.state.isTimeLineOpen ? "exportSideBar" : "toggle-sidebar"
                        }
                        style={{ zIndex: "1000" }}
                    >
                        {this.state.isTimeLineOpen && (
                            <TimeLine
                                from={"workOrder"}
                                id={this.state.workOrder_id}
                                closeTimelineModal={this.closeTimeline}
                            ></TimeLine>
                        )}
                    </Card>
                </div>
            </>
        );
    }
}

export default NewChangeOrder;
