import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Redirect, Route, Switch } from "react-router-dom";
//Utils
// import { isLoggedIn } from "../constants/Utils";
import { isLoggedIn } from "constants/utils";
//Routers
import PrivateRouter from "./PrivateRouter";
import PublicRouter from "./PublicRouter";
import downloadMobileApp from "views/infoScreen/downloadMobileApp";
import AcceptOrRejectProposal from "views/externalPages/AcceptOrRejectProposal";

// checkScreenSize() {
//   this.breakPointObserver.observe(['(max-width: 450px)'])
//     .subscribe(result => {
//       this.mobileView = result.matches
//     });
// }

class MainRouter extends Component {

  // constructor() {
  //   this.state = {
  //     mobileView: false,
  //     ref: createRef()
  //   }
  //   window.addEventListener("resize", this.getwidth);
  // }


  // getWidth() {
  //   this.setState({mobileView: createRef()})

  //   setTimeout(() => (this.state.mobileview), 3000)
  // }



  render = () => {

    return <>
      {/* <div ref={this.state.ref}> */}
      <div className='mobileAppSuggestion'>
        <Router>
          <Route path="*" component={downloadMobileApp} />
        </Router>
      </div>
      <div className='mainRouter'>

        {isLoggedIn() ? (
          <Router>
            <PrivateRouter />
          </Router>
        ) : (
          <Router>
            <PublicRouter />
          </Router>
        )}
      </div>
    </>

  };
}

export default MainRouter;
