import React, { Component } from "react";
import {
  Card,
  Table,
  Container,
  Row,
  Breadcrumb,
  Col,
  Button,
  InputGroup,
  CardBody,
  Spinner,
  DropdownMenu,
  DropdownItem,
  Media,
  UncontrolledDropdown,
  DropdownToggle,
} from "reactstrap";
import { Tooltip } from "antd";

// core components
import ApiService from "constants/ApiService";
import { returnFirstDegreeObjValue } from "constants/utils";
import returnSecondDegreeObjValue from "constants/utils";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CountUp from "react-countup";
import { getIsoString, filterQuery } from "constants/utils";
import { showUtcDate } from "constants/utils";
import ToastList from "components/Toast/ToastList";
import TotalInvoice from "../../../src/assets/img/brand/total_invoice.svg";
import completedInvoice from "../../../src/assets/img/brand/completedInvoice.svg";
import acceptedInvoice from "../../../src/assets/img/brand/acceptedInvoice.svg";
import totalEarnedInvoice from "../../../src/assets/img/brand/totalEarnedInvoice.svg";
import { showError } from "constants/utils";
import { returnSearchObject } from "constants/utils";
import { pushToFilteredPage } from "constants/utils";
import { buildQueryString } from "constants/utils";
import { returnCustomerFirsLastName } from "constants/utils";
import { isSub_Contractor } from "constants/utils";
import { displayPrice } from "constants/utils";
import { CustomListingContainer } from "components/Container/CustomListingContainer";
import { ListingBodyLayout } from "components/Container/CustomListingContainer";
import { ListingHeaderLayout } from "components/Container/CustomListingContainer";
import { CustomSearch } from "components/FilterFields/Filterfields";
import { CustomDropDown } from "components/FilterFields/Filterfields";
import { CustomDatePicker } from "components/FilterFields/Filterfields";
import { Button_without_Border } from "components/Buttons/Button";
import { remove_underscore_capitalize } from "constants/utils";
const cards = [
  {
    name: "total_requests",
    value: 0,
    image: TotalInvoice,
  },
  {
    name: "completed",
    value: 0,
    image: completedInvoice,
  },
  {
    name: "pending",
    value: 0,
    image: acceptedInvoice,
  },
  {
    name: "total_earnings",
    value: 0,
    image: totalEarnedInvoice,
  },
];
class Requests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requestList: [],
      total: 0,
      filterToggle: false,
      from: "",
      to: "",
      page: 1,
      inputSearch: "",
      filterOption: "",
      statusSearch: this.props.location?.search ? "pending" : "",
      toast: false,
      response: "",
      toastType: "",
      isLoading: true,
      statsData: {},
      contractor: {},
      query: {},
      toolTip: "",
      input_value: false,
    };
  }
  componentDidMount = async () => {
    this.setQueryStatus();
  };

  setQueryStatus = () => {
    let queryStatus = this.props.location.search;
    if (queryStatus) {
      let queryObj = returnSearchObject(queryStatus);

      this.setState(
        {
          from: queryObj.from ? new Date(queryObj.from) : "",
          to: queryObj.to ? new Date(queryObj.to) : "",
          statusSearch: queryObj.status ?? "",
          page: parseInt(queryObj.page) ?? "",
          query: returnSearchObject(queryStatus),
        },
        () => {
          this.state.statusSearch || this.state.from || this.state.to
            ? this.setState({ input_value: true })
            : this.setState({ input_value: false });
          pushToFilteredPage(this.props, { ...this.state.query });
          this.getRequestsList();
          this.getRequestStats();
        }
      );
    } else {
      this.setState(
        {
          query: {
            ...this.state.query,
            page: 1,
            limit: 10,
          },
        },
        () => {
          pushToFilteredPage(this.props, this.state.query);
          this.getRequestsList();
          this.getRequestStats();
        }
      );
    }
  };
  getSubContractorCommission = (requestData) => {
    let amt;
    let { sub_contractor, main_contractor_amount, amount } = requestData;
    if (sub_contractor?.id && main_contractor_amount?.type) {
      if (main_contractor_amount?.type === "percentage") {
        amt = amount * (main_contractor_amount?.commission / 100);
      } else {
        amt = main_contractor_amount?.commission;
      }
    }

    return amt ? amt : 0;
  };

  getRequestsList = async () => {
    let { page, filterOption, statusSearch, inputSearch, from, to } =
      this.state;
    let queryParams = "";
    if (filterOption !== "" && inputSearch !== "")
      queryParams += filterQuery(filterOption, inputSearch);
    if (statusSearch !== "") {
      queryParams += filterQuery("status", statusSearch);
    }
    if (from !== "") queryParams += filterQuery("from", getIsoString(from));
    if (to !== "") queryParams += filterQuery("to", getIsoString(to));
    if (from > to) {
      showError(this, "Please provide valid From and To date");
    } else {
      // let response = await ApiService.getAPI(
      //   `contractor/requests?page=${page}&limit=10${queryParams}`
      // );

      let response = await ApiService.getAPI(
        `contractor/requests${buildQueryString(this.state.query)}`
      );

      if (response.success) {
        let { requests, total } = response.data;
        this.setState({
          requestList: requests.map((obj) => {
            return { ...obj, ref: React.createRef() };
          }),
          total: total,
          contractor: JSON.parse(localStorage.getItem("contractor_details"))
            .contractor,
          isLoading: false,
        });
      }
    }
  };

  isMainContractor = (requestData) => {
    return (
      requestData?.sub_contractor?.id === this.state.contractor?.contractor_id
    );
  };

  isOutside = (requestData) => {
    return requestData?.assign_type === "outside";
  };
  getRequestStats = async () => {
    let response = await ApiService.getAPI(`contractor/requests/stats`);
    if (response.success) {
      let tempObj = {};
      let tempArray = [];
      let cardValues = response.data;
      cards.map((obj) => (tempObj[obj.name] = obj));
      Object.keys(cardValues).map((key) => {
        tempObj[
          key === "overall_requests"
            ? "total_requests"
            : key === "com_requests"
              ? "completed"
              : key === "pend_requests"
                ? "pending"
                : "total_earnings"
        ]["value"] = cardValues[key];
      });
      cards.forEach((value) => tempArray.push(tempObj[value.name]));
      this.setState({ statsData: response.data });
    }
  };

  toggleFilterMenu = () => {
    this.setState({ filterToggle: !this.state.filterToggle });
  };
  onChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  updateStateValues = (key, value) => {
    const updatedValue = value == null ? "" : value;
    this.setState({ [key]: updatedValue, input_value: true }, () => {
      if (this.state.from && this.state.to) {
        this.handleFilterStatus();
      }
    });
  };
  handleOnToChange = (e) => {
    this.updateStateValues("to", e ? new Date(e) : '');
  };
  handleOnFromChange = (e) => {
    this.updateStateValues("from", e ? new Date(e) : '');
  };
  handleIncrementPage = () => {
    if (this.state.requestList.length >= 10) {
      this.setState({ page: this.state.page + 1 }, () =>
        this.handleFilterStatus()
      );
    }
  };
  handleDecrementPage = () => {
    if (this.state.page > 1) {
      this.setState({ page: this.state.page - 1 }, () =>
        this.handleFilterStatus()
      );
    }
  };
  redirectDetailPage = (id) => {
    this.props.history.push(`requests/detail/${id}`);
  };
  // Toast Handler

  handleFilterStatus = () => {
    let staticQuery = returnSearchObject(this.props.location.search);
    staticQuery.from = getIsoString(this.state.from) ?? "";
    staticQuery.to = getIsoString(this.state.to) ?? "";
    staticQuery.status = this.state.statusSearch ?? "";
    staticQuery.page = this.state.page ?? "";
    this.setState({ query: staticQuery }, () => {
      pushToFilteredPage(this.props, { ...this.state.query });
      this.getRequestsList();
      this.getRequestStats();
    });
  };

  handleSearch = () => {
    this.setState({ page: 1 }, () => {
      this.handleFilterStatus();
    });
  };

  handleReset = () => {
    let self = this;
    self.setState(
      {
        from: "",
        to: "",
        statusSearch: "",
        filterOption: "",
        inputSearch: "",
        page: 1,
        limit: 10,
        query: {
          page: 1,
          limit: 10,
        },
        input_value: false,
      },
      () => {
        pushToFilteredPage(this.props, { ...this.state.query });
        this.getRequestsList();
      }
    );
  };

  checkForClearFilter = () => {
    this.setState({ input_value: !this.state.statusSearch ? false : true });
  };

  setStatus = (status) => {
    this.setState(
      {
        statusSearch:
          status === "all" ||
            status === "total_requests" ||
            status === "total_earnings"
            ? ""
            : status === "pending"
              ? "in_progress"
              : status,
        page: 1,
      },
      () => {
        this.checkForClearFilter();
        let staticQuery = returnSearchObject(this.props.location.search);
        staticQuery.from = getIsoString(this.state.from) ?? "";
        staticQuery.to = getIsoString(this.state.to) ?? "";
        staticQuery.status = this.state.statusSearch ?? "";
        staticQuery.page = this.state.page ?? "";

        this.setState({ query: staticQuery }, () => {
          pushToFilteredPage(this.props, { ...this.state.query });
          this.getRequestsList();
        });
      }
    );
  };

  isBig = (element) => {
    let text = "";
    setTimeout(() => {
      let childWidth =
        element?.current?.childNodes[0]?.childNodes[0]?.offsetWidth;
      let ptWidth = element?.current?.offsetWidth;
      if (childWidth > ptWidth) {
        this.setState({ toolTip: element?.current?.outerText });
      } else this.setState({ toolTip: text });
    }, 100);
  };

  redirectToAddRequest = () => {
    this.props.history.push(`/admin/request/add`);
  };

  render() {
    let {
      requestList,
      isLoading,
      page,
      statusSearch,
      toast,
      response,
      toastType,
      statsData,
      input_value,
      from,
      to,
    } = this.state;
    return isLoading ? (
      <div className="spin-loading">
        <Spinner type="border" color="#077e8b" />
      </div>
    ) : (
      <>
        {toast && <ToastList message={response} type={toastType} />}
        <div style={{ padding: "20px", backgroundColor: "#fafafa" }}>
          <div style={{ marginBottom: '20px' }} className="header-body">
            <Row className="row-center">
              {cards.map((card) => (
                <Col lg="6" xl="3" className="max-dash">
                  <div
                    className="cardBody"
                    style={{ cursor: "pointer", padding: "0px" }}
                    onClick={() => this.setStatus(card.name)}
                  >
                    <CardBody className="pad-dash" style={{ height: "95px" }}>
                      <div className="dash_content dash_content">
                        <div className="dash_left">
                          <div className="heading_with_badge">
                            <h4>{remove_underscore_capitalize(card.name)}</h4>
                          </div>
                          <label className="number-con point">
                            {card.name === "total_earnings" && "$ "}
                            <CountUp
                              start={0}
                              end={`${card.value}`}
                              separator=","
                            />
                          </label>
                        </div>
                        <div className="col dash-col dash_right">
                          <img
                            src={card.image}
                            className="img-dash"
                            alt="total sales"
                          />
                        </div>
                      </div>
                    </CardBody>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
          <CustomListingContainer>
            <ListingHeaderLayout label={"Service Requests"} />
            <ListingBodyLayout>
              <div style={{ margin: "24px" }}>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "35% 65%",
                  }}
                >
                  <div
                    style={{ display: "grid", gridTemplateColumns: "70% 45%" }}
                  >
                    <CustomDatePicker
                      from={from}
                      to={to}
                      fromChange={this.handleOnFromChange}
                      toChange={this.handleOnToChange}
                    ></CustomDatePicker>
                    <CustomDropDown
                      width={"130px"}
                      setStatus={this.setStatus}
                      status={statusSearch}
                      statusList={[
                        "all",
                        "assigned",
                        "accepted",
                        "scheduled",
                        "rescheduled",
                        "in_progress",
                        "completed",
                        "closed",
                      ]}
                    />
                  </div>
                  {input_value && (statusSearch || (from || to)) && (
                    <div className="right_filter_container">
                      <Button_without_Border
                        click={this.handleReset}
                        btnLabel={"clear_filter"}
                      ></Button_without_Border>
                    </div>
                  )}
                </div>
              </div>
              <Row>
                <div className="col new-table">
                  <Card className="shadow card_with_paginator">
                    {requestList && requestList.length !== 0 ? (
                      <Table
                        className="align-items-center table-flush"
                        responsive
                      >
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">ID</th>
                            <th scope="col">
                              Property
                            </th>
                            <th scope="col">Customer</th>
                            <th scope="col">Requested On</th>
                            <th scope="col">Scheduled On</th>
                            <th scope="col">Type</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {requestList.map((request, i) => (
                            <tr key={i}>
                              <td
                                className="text-capitalize cursor-point "
                                onClick={() =>
                                  this.redirectDetailPage(request.request_id)
                                }
                              >
                                {request?.request_id}
                              </td>

                              <td
                                ref={request.ref}
                                className="capitalize"
                                style={{ maxWidth: "0px" }}
                              >
                                <div
                                  onMouseEnter={() => {
                                    this.isBig(request.ref);
                                  }}
                                  className="add_eclipse"
                                >
                                  <Tooltip
                                    placement="topLeft"
                                    // title={this.state.toolTip}
                                    // title={'12'}
                                    title={this.state.toolTip}
                                  >
                                    {returnSecondDegreeObjValue(
                                      request,
                                      "property",
                                      "name"
                                    )}
                                  </Tooltip>
                                </div>
                              </td>
                              <td>
                                {returnCustomerFirsLastName(
                                  request,
                                  "user",
                                  "name",
                                  "first",
                                  "last"
                                )}
                              </td>
                              <td className="text-capitalize">
                                {showUtcDate(request?.createdAt)}
                              </td>
                              <td className="text-capitalize">
                                {showUtcDate(request?.schedule_date)}
                              </td>
                              <td className="text-capitalize">
                                {returnFirstDegreeObjValue(request, "type")}
                              </td>
                              <td>
                                {!request.main_contractor_amount ||
                                  Object.keys(request.main_contractor_amount)
                                    .length === 0
                                  ? displayPrice(request.amount)
                                  : isSub_Contractor() ||
                                    (this.isMainContractor(request) &&
                                      this.isOutside(request))
                                    ? request &&
                                    displayPrice(
                                      parseFloat(
                                        request?.main_contractor_amount?.amount
                                      ) -
                                      parseFloat(
                                        this.getSubContractorCommission(
                                          request
                                        )
                                      )
                                    )
                                    : displayPrice(
                                      this.getSubContractorCommission(request)
                                    )}
                              </td>

                              <td
                                className={
                                  request.status === "accepted" ||
                                    request.status === "assigned"
                                    ? "text-capitalize status_accepted"
                                    : request.status === "cancelled" ||
                                      request.status === "rejected"
                                      ? "text-capitalize red"
                                      : request.status === "in_progress" ||
                                        request.status === "pending" ||
                                        request.status === "scheduled" ||
                                        request.status === "rescheduled"
                                        ? "text-capitalize yellow"
                                        : request.status === "completed"
                                          ? "text-capitalize status_completed"
                                          : request.status === "closed"
                                            ? "text-capitalize primary"
                                            : "text-capitalize"
                                }
                                style={{fontWeight:"500"}}
                              >
                                {returnFirstDegreeObjValue(
                                  request,
                                  "status"
                                ) === "in_progress"
                                  ? "In Progress"
                                  : returnFirstDegreeObjValue(
                                    request,
                                    "status"
                                  )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    ) : (
                      <>
                        {" "}
                        <Table
                          className="align-items-center table-flush"
                          responsive
                        >
                          <thead className="thead-light">
                            <tr>
                              <th scope="col">Customer</th>
                              <th scope="col">Property</th>
                              <th scope="col">Type</th>
                              <th scope="col">Status</th>
                              <th scope="col">Date</th>
                            </tr>
                          </thead>
                        </Table>
                        <Card className="card_with_paginator no_data_card">
                          {" "}
                          <div className="no-data">No Requests Available</div>
                        </Card>
                      </>
                    )}
                  </Card>
                </div>
              </Row>
              <Row className="align-items-center header-div jus-space new-margin-page paginator_box">
                <div className="paginate-flex-box ">
                  <div
                    className="paginate-box"
                    onClick={() => this.handleDecrementPage()}
                  >
                    <p
                      className={
                        page !== 1 ? "page_available" : "page_unavailable"
                      }
                    >
                      Previous
                    </p>
                  </div>
                  <div
                    className="paginate-box"
                    onClick={() => this.handleIncrementPage()}
                  >
                    <p
                      className={
                        requestList.length < 10
                          ? "page_unavailable"
                          : "page_available"
                      }
                    >
                      Next
                    </p>
                  </div>
                </div>
              </Row>
            </ListingBodyLayout>
          </CustomListingContainer>
        </div>
      </>
    );
  }
}

export default Requests;
