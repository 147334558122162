import React, { Component } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Table,
  Spinner,
  Row,
  Col,
  Button,
  Input,
} from "reactstrap";
import ToastList from "components/Toast/ToastList";
import { showError, showSuccess } from "../../constants/utils";
import ApiService from "constants/ApiService";
import closeIcon from "../../assets/img/icons/closeIcon.svg";
import { Button_without_Border } from "components/Buttons/Button";
import { Custom_Button } from "components/Buttons/Button";

class CancelInvoiceModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reason: "",
      toast: false,
      toastType: "",
      response: "",
      btnLoad: false,
    };
  }

  handleInputChange = (e) => {
    this.setState({ reason: e.target.value });
  };
  confirmCancel = async () => {
    let { reason } = this.state;
    if (reason === "") {
      showError(this, "Please enter valid reason");
    } else {
      this.setState({ btnLoad: true });
      let { id } = this.props;
      let data = {
        cancel_reason: reason,
      };
      let response = await ApiService.patchAPI(
        `contractor/invoice/cancel/${id}`,
        data
      );
      if (response.success) {
        this.setState({ btnLoad: false });
        this.props.close(response.message);
      } else {
        this.setState({ btnLoad: false });
        showError(this, response.message);
      }
    }
  };

  render() {
    let { reason, toast, response, toastType, btnLoad } = this.state;
    return (
      <div>
        {toast && <ToastList message={response} type={toastType} />}

        <Card>
          <CardBody className="pad25">
            <div className="mb-30 flex">
              <div className="fw500 fc-b">Cancel Invoice</div>
              <div
                className="clpaid x_close flexmid point fw500 font-24"
                onClick={() => this.props.close()}
              >
                <img width={20} src={closeIcon} alt="close" />
              </div>
            </div>
            <label className="mb-10 cl555555 f14 fw-400" htmlFor="reason">
              Please mention the reason for Cancelling the invoice *
            </label>
            <Input
              type="textarea"
              onChange={this.handleInputChange}
              value={reason}
              placeholder="Reason"
              className="mb-30"
              rows={4}
            />

            <div className="flexEnd">
              {/* <Button_without_Border
                style={{ marginRight: "20px" }}
                btnLabel="no"
                click={() => this.props.close()}
              /> */}
              <Custom_Button
                style={{ marginRight: "10px" }}
                btnLabel="no"
                click={() => this.props.close()}
                isLoading={btnLoad}
              />
              <Custom_Button
                isPrimary
                btnLabel="yes"
                click={() => this.confirmCancel()}
                isLoading={btnLoad}
              />
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default CancelInvoiceModal;
