import React, { Component } from 'react';
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from 'reactstrap';
import ApiService from 'constants/ApiService';

class Autocomplete extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: props.value.name || ''
    };
  }
  fetchSuggestions = async (input) => {
    try {
      let response;
      const { type } = this.props;

      if (type === "inside_contractor") {
        response = await ApiService.getAPI(`contractor/sub_contractors?name=${input}`);
      } else if (type === "outside_contractor") {
        response = await ApiService.getAPI(`contractor/outside?name=${input}`);
      }

      this.setState({
        filteredSuggestions: response.data.contractors || response.data.contractor || [],
        showSuggestions: true
      });
    } catch (error) {

      this.setState({
        filteredSuggestions: [],
        showSuggestions: true
      });
    }
  };

  componentDidMount() {
    this.setState({ showSuggestions: false })
  }

  handleSelect = (suggestion) => {
    const { onSelect } = this.props;
    suggestion["userInput"] = this.state.userInput
    onSelect(suggestion);
    this.setState({
      userInput: suggestion.name,
      showSuggestions: false
    });
  };

  onChange = (e) => {
    this.setState({
      userInput: e.currentTarget.value
    }, () => {
      if (this.state.userInput !== '') {

        this.timeout = setTimeout(() => {
          this.fetchSuggestions(this.state.userInput);
        }, 500);
      } else {
        this.setState({
          showSuggestions: false
        });
      }
    });
  };

  onClick = (suggestion) => {
    this.handleSelect(suggestion);
  };

  onKeyDown = (e) => {
    const { activeSuggestion, filteredSuggestions } = this.state;

    if (e.keyCode === 13) { // Enter key
      this.handleSelect(filteredSuggestions[activeSuggestion]);
    } else if (e.keyCode === 38) { // Up arrow
      if (activeSuggestion === 0) {
        return;
      }
      this.setState(prevState => ({
        activeSuggestion: prevState.activeSuggestion - 1
      }));
    } else if (e.keyCode === 40) { // Down arrow
      if (activeSuggestion + 1 === filteredSuggestions.length) {
        return;
      }
      this.setState(prevState => ({
        activeSuggestion: prevState.activeSuggestion + 1
      }));
    }
  };

  render() {
    const { filteredSuggestions, showSuggestions, userInput, activeSuggestion } = this.state;

    let suggestionsDropdown;
    if (showSuggestions && userInput) {
      if (Array.isArray(filteredSuggestions) && filteredSuggestions.length > 0) {
        suggestionsDropdown = (
          <DropdownMenu className="dropdown-menu-arrow w100 show">
            {filteredSuggestions.map((suggestion, index) => {
              let className = index === activeSuggestion ? 'active' : '';
              return (
                <DropdownItem
                  key={suggestion.contractor_id}
                  onClick={() => this.onClick(suggestion)}
                  className={className}
                >
                  {suggestion.name}

                </DropdownItem>
              );
            })}
          </DropdownMenu>
        );
      } else {
        suggestionsDropdown = (
          <DropdownMenu className="dropdown-menu-arrow w100">
            <DropdownItem>No suggestions available</DropdownItem>
          </DropdownMenu>
        );
      }
    }

    return (
      <div className="status_select w100 mb-20">
        <div className="dropdown show" style={{ width: "100%" }}>
          <UncontrolledDropdown className="status_select_div show" style={{ border: "none" }}>
            <InputGroup>
              <Input
                type="text"
                onChange={this.onChange}
                onKeyDown={this.onKeyDown}
                value={userInput}
                style={{ color: "#212529" }}
              />
              <InputGroupAddon addonType="append">
                <InputGroupText>Search</InputGroupText>
              </InputGroupAddon>
            </InputGroup>
            {suggestionsDropdown}
          </UncontrolledDropdown>
        </div>
      </div>
    );
  }
}

export default Autocomplete;
