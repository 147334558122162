import React, { Component } from "react";
import {
  Card,
  Table,
  Container,
  Row,
  Breadcrumb,
  Col,
  Button,
  InputGroup,
  Spinner,
  DropdownMenu,
  DropdownItem,
  Media,
  UncontrolledDropdown,
  DropdownToggle,
  Modal,
  Dropdown,
  Input,
} from "reactstrap";
// core components
import ApiService from "constants/ApiService";
import {
  returnFirstDegreeObjValue,
  returnSecoundDegreeObjValue,
  getUSFormat,
} from "constants/utils";
import ToastList from "components/Toast/ToastList";

import { showError } from "constants/utils";
import { returnSearchObject } from "constants/utils";
import { pushToFilteredPage } from "constants/utils";
import { buildQueryString } from "constants/utils";
import InviteModal from "./inviteModal";
import moreIcon from "../../assets/img/brand/showMore.svg";
import { showSuccess } from "constants/utils";
import { showUtcDate } from "constants/utils";
import { CustomListingContainer } from "components/Container/CustomListingContainer";
import { ListingHeaderLayout } from "components/Container/CustomListingContainer";
import { CustomSearch } from "components/FilterFields/Filterfields";
import { Button_without_Border } from "components/Buttons/Button";

class InviteContractors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contractorList: [],
      page: 1,
      inputSearch: "",
      filterOption: "email",
      toast: false,
      response: "",
      toastType: "",
      isLoading: true,
      query: {},
      inviteModals: false,
      inviteData: "",
      input_value: false,
      timoutId: null,
      filters: ['email']
    };
  }
  componentDidMount = async () => {
    this.setQueryStatus();
  };

  componentWillUnmount = () => {
    clearTimeout(this.state.timoutId);
  };

  returnCurrentFilterOption = (obj) => {
    return (
      this.state.filters.filter((ele) => Object.keys(obj).includes(ele))[0] ??
      ""
    );
  };

  initCheckClerFilters = () => {
    this.state.filters.includes(this.state.filterOption) ||
      this.state.statusSearch ||
      this.state.inputSearch ||
      this.state.page > 1
      ? this.setState({ input_value: true })
      : this.setState({ input_value: false });
  };

  setQueryStatus = () => {
    let queryStatus = this.props.location.search;
    let queryObj = returnSearchObject(queryStatus);
    if (queryStatus) {
      this.setState(
        {
          inputSearch: queryObj[this.returnCurrentFilterOption(queryObj)] ?? "",
          filterOption: queryObj.email ? "email" : "email",
          page: parseInt(queryObj.page) ?? "",
          query: returnSearchObject(queryStatus),
        },
        () => {
          this.initCheckClerFilters();
          pushToFilteredPage(this.props, { ...this.state.query });
          this.getActiveContractors();
        }
      );
    } else {
      this.setState(
        {
          query: {
            ...this.state.query,
            page: 1,
            limit: 10,
          },
        },
        () => {
          pushToFilteredPage(this.props, this.state.query);
          this.getActiveContractors();
        }
      );
    }
  };

  getActiveContractors = async () => {
    let { from, to } = this.state;
    if (from > to) {
      showError(this, "Please provide valid From and To date");
    } else {
      let response = await ApiService.getAPI(
        `contractor/invite${buildQueryString(this.state.query)}&key=recent`
      );

      if (response.success) {
        let { invite } = response.data;
        this.setState({
          contractorList: invite,
          isLoading: false,
        });
      }
    }
  };

  checkForClearFilter = () => {
    this.setState({
      input_value:
        !this.state.statusSearch &&
          (this.state.filterOption === "email" || !this.state.filterOption) &&
          !this.state.inputSearch
          ? false
          : true,
    });
  };

  onChangeHandler = (e, from) => {
    this.setState(
      { [from]: e === "select" ? "" : e, input_value: true },
      () => {
        this.checkForClearFilter();
      }
    );
    clearTimeout(this.state.timoutId);
    const timoutId = setTimeout(() => {
      this.state.filterOption && this.handleSearch();
    }, 1000);
    this.setState({ timoutId });
  };

  handleIncrementPage = () => {
    if (this.state.contractorList.length >= 10) {
      this.setState({ page: this.state.page + 1, input_value: true }, () =>
        this.handleFilterStatus()
      );
    }
  };
  handleDecrementPage = () => {
    if (this.state.page > 1) {
      this.setState(
        {
          page: this.state.page - 1,
          input_value: this.state.page - 1 === 1 ? false : true,
        },
        () => this.handleFilterStatus()
      );
    }
  };

  handleFilterStatus = () => {
    let staticQuery = returnSearchObject(this.props.location.search);
    for (let key in staticQuery) {
      if (key === "email") {
        delete staticQuery[key];
      }
    }
    staticQuery[this.state.filterOption] = this.state.inputSearch;
    staticQuery.page = this.state.page ?? "";

    this.setState({ query: staticQuery }, () => {
      if (this.state.filterOption !== "select") {
        pushToFilteredPage(this.props, { ...this.state.query });
        this.getActiveContractors();
      }
    });
  };

  handleSearch = () => {
    this.setState({ page: 1 }, () => {
      this.handleFilterStatus();
    });
  };

  handleReset = () => {
    let self = this;
    self.setState(
      {
        filterOption: "email",
        inputSearch: "",
        page: 1,
        limit: 10,
        query: {
          page: 1,
          limit: 10,
        },
        input_value: false,
      },
      () => {
        pushToFilteredPage(this.props, { ...this.state.query });
        this.getActiveContractors();
      }
    );
  };

  openInviteModal = () => {
    this.setState({ inviteModal: true });
  };

  closeInviteModal = (msg) => {
    this.setState({ inviteModal: false, inviteData: "" });
    if (msg) {
      this.getActiveContractors();
    }
  };

  updateModal = () => {
    this.setState({ inviteModal: false, inviteData: "" });
    this.getActiveContractors();
  };

  resendInvite = async (contractor) => {
    this.setState({ inviteModal: true, inviteData: contractor });
  };

  cancelInvite = async (contractor) => {
    let id = contractor.invite_id;
    let data = {
      email: contractor.email,
      phone: contractor.phone,
    };
    let response = await ApiService.patchAPI(
      `contractor/invite/cancel/${id} `,
      data
    );
    if (response.success) {
      showSuccess(this, response.message);
      this.getActiveContractors();
    } else {
      showError(this, response.message);
    }
  };

  render() {
    let {
      contractorList,
      isLoading,
      page,
      toast,
      response,
      toastType,
      inviteModal,
      inviteData,
      filterOption,
      inputSearch,
      input_value,
      filters
    } = this.state;
    return isLoading ? (
      <div className="spin-loading">
        <Spinner type="border" color="#077e8b" />
      </div>
    ) : (
      <>
        {toast && <ToastList message={response} type={toastType} />}
        <div
          style={{
            height: "100vh",
            padding: "20px",
            backgroundColor: "#fafafa",
          }}
        >
          <CustomListingContainer>
            <ListingHeaderLayout
              primary_buttonLabel={"invite"}
              isPrimary
              label={"invite_members"}
              click={this.openInviteModal}
            />
            <div style={{ margin: "24px" }}>
              <div
                className="d_flex_sb"
                style={{
                }}
              >
                <div style={{ display: "flex", width: "80%" }}>
                  <CustomSearch
                    options={filters}
                    change={this.onChangeHandler}
                    filterOption={filterOption}
                    inputSearch={inputSearch}
                  />
                </div>
                {input_value && (
                  <div className="right_filter_container">
                    <Button_without_Border
                      click={this.handleReset}
                      btnLabel={"clear_filter"}
                    ></Button_without_Border>
                  </div>
                )}
              </div>
            </div>
            <Row>
              <div className="col new-table">
                <Card className="shadow card_with_paginator">
                  {contractorList && contractorList.length !== 0 ? (
                    <Table
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead className="thead-light">
                        <tr>
                          {/* <th scope="col">Name</th> */}
                          <th scope="col">Email</th>
                          <th scope="col">Mobile Number</th>
                          <th scope="col">Status</th>
                          <th scope="col">Date</th>
                          <th style={{ width: "5%" }} scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {contractorList.map((contractor, i) => (
                          <tr key={i}>
                            <td className="cursor-point">
                              {returnFirstDegreeObjValue(contractor, "email")}
                            </td>
                            <td className="cursor-point">
                              {getUSFormat(contractor?.phone?.national_number)}
                            </td>
                            <td
                              className={
                                contractor.status === "accepted" ||
                                  contractor.status === "assigned"
                                  ? "text-capitalize status_accepted"
                                  : contractor.status === "cancelled" ||
                                    contractor.status === "rejected"
                                    ? "text-capitalize red"
                                    : contractor.status === "in_progress" ||
                                      contractor.status === "pending"
                                      ? "text-capitalize yellow"
                                      : contractor.status === "completed"
                                        ? "text-capitalize status_completed"
                                        : contractor.status === "closed"
                                          ? "text-capitalize primary"
                                          : "text-capitalize"
                              }
                              style={{fontWeight:"500"}}
                            >
                              {returnFirstDegreeObjValue(
                                contractor,
                                "status"
                              ) === "in_progress"
                                ? "In Progress"
                                : returnFirstDegreeObjValue(
                                  contractor,
                                  "status"
                                )}
                            </td>
                            <td>{showUtcDate(contractor.createdAt)}</td>
                            <td>
                              {contractor.status !== "cancelled" && (
                                <UncontrolledDropdown
                                  nav
                                  className="flexy-content "
                                >
                                  <DropdownToggle
                                    className="pr-0 nav-notice"
                                    nav
                                  >
                                    <Media className="align-items-center">
                                      <img src={moreIcon} />
                                    </Media>
                                  </DropdownToggle>
                                  <DropdownMenu
                                    className="dropdown-menu-arrow min-width-drop"
                                    right
                                  >
                                    <DropdownItem
                                      onClick={() =>
                                        this.resendInvite(contractor)
                                      }
                                    >
                                      <span>Resend</span>
                                    </DropdownItem>

                                    <DropdownItem
                                      onClick={() =>
                                        this.cancelInvite(contractor)
                                      }
                                    >
                                      <span>Cancel</span>
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    <>
                      {" "}
                      <Table
                        className="align-items-center table-flush"
                        responsive
                      >
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Status</th>
                            <th scope="col">Date</th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                      </Table>
                      <Card className="card_with_paginator no_data_card">
                        {" "}
                        <div className="no-data">No Contractors Available</div>
                      </Card>
                    </>
                  )}
                </Card>
              </div>
            </Row>
            <Row className="align-items-center header-div jus-space new-margin-page paginator_box">
              <div className="paginate-flex-box ">
                <div
                  className="paginate-box"
                  onClick={() => this.handleDecrementPage()}
                >
                  <p
                    className={
                      page !== 1 ? "page_available" : "page_unavailable"
                    }
                  >
                    Previous
                  </p>
                </div>
                <div
                  className="paginate-box"
                  onClick={() => this.handleIncrementPage()}
                >
                  <p
                    className={
                      contractorList.length < 10
                        ? "page_unavailable"
                        : "page_available"
                    }
                  >
                    Next
                  </p>
                </div>
              </div>
            </Row>
          </CustomListingContainer>

          <Modal
            isOpen={inviteModal}
            toggle={() => this.closeInviteModal()}
            centered
            className="inviteModal"
          >
            <InviteModal
              close={() => this.closeInviteModal()}
              data={inviteData}
              link={this.state.link}
              update={this.updateModal}
            />
          </Modal>
        </div>
      </>
    );
  }
}

export default InviteContractors;
