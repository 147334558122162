import { Component } from "react";
import pksLogo from "../../../assets/img/new Logo/pkLogoForWhiteBk.svg";
import editIcon from "../../../assets/img/brand/edit.svg";
import {
  Breadcrumb,
  Row,
  Col,
  BreadcrumbItem,
  Card,
  Table,
  Spinner,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import moment from "moment";
import Lottie from "react-lottie";
import NoData from "../../../assets/Lotties/noData.json";
import CrmDocument from "../../../assets/img/icons/crm_documents.svg";
import CrmPhotos from "../../../assets/img/icons/crm_photo.svg";
import sendChat from "../../../assets/img/brand/send_chat.svg";
import AddPhotosDocuments from "views/Project/addPhotosOrDocuments";
import showMore from "../../../assets/img/brand/showMore.svg";
import { showUtcDate } from "constants/utils";

let overlap = document.querySelector(":root");

export default class ClientProjectDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedContent: "Documents",
      comments: [
        {
          content: "hi",
          date: new Date(),
        },
        {
          content: "hi",
          date: new Date(),
        },
        {
          content: "hi",
          date: new Date(),
        },
        {
          content: "hi",
          date: new Date(),
        },
        {
          content: "hi",
          date: new Date(),
        },
        {
          content: "hi",
          date: new Date(),
        },
      ],
      photos: [],
      content: [
        // {
        //   name: "Comments",
        //   img: CrmComment,
        //   selected: true,
        // },
        {
          name: "Documents",
          img: CrmDocument,
          selected: true,
        },
        {
          name: "Photos",
          img: CrmPhotos,
          selected: false,
        },
      ],
      openAddDocumentsOrPhotos: false,
      payment_list: [],
    };
  }

  componentDidMount() {
    overlap.style.setProperty("--width", "600px");
    overlap.style.setProperty("--backgroundColor", "transparent");
  }

  capitalizeFirstLetter = (str) => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  componentWillUnmount() {
    overlap.style.setProperty("--width", "450px");
    overlap.style.setProperty("--backgroundColor", "#f5f5f5");
  }

  selectContent = (index) => {
    let obj = this.state.content;
    obj.forEach((each) => {
      each.selected = false;
    });
    obj[index].selected = true;
    this.setState({ content: obj, selectedContent: obj[index].name });
  };

  viewAll = () => {
    this.props.history.push(
      `/client/12312/123/${this.state.selectedContent.toLowerCase()}`
    );
  };

  render() {
    const NodataFound = {
      loop: true,
      autoplay: true,
      animationData: NoData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    let {
      selectedContent,
      comments,
      photos,
      content,
      openAddDocumentsOrPhotos,
      payment_list,
    } = this.state;

    const EmptyMessage = (
      <>
        <div className="no_data_dashboard" style={{ height: "100%" }}>
          <Lottie options={NodataFound} height={100} width={100} />
          <div className="mt_20">
            <p>No {this.state.selectedContent} Found</p>
          </div>
        </div>
      </>
    );

    return (
      <>
        <div className="clientBody">
          <div>
            <Breadcrumb></Breadcrumb>
          </div>
          <Row style={{ padding: "1% 2%" }} className="">
            <Col xs="" className="manage-con">
              <Breadcrumb>
                <BreadcrumbItem
                  onClick={() => this.props.history.push(`/client/123`)}
                  className="bread-cursor"
                >
                  Project
                </BreadcrumbItem>
                <BreadcrumbItem active style={{ color: "#72569c" }}>Details</BreadcrumbItem>
              </Breadcrumb>
            </Col>
          </Row>
          <div style={{ width: "100%", padding: " 2% 1%" }}>
            <Card
              style={{
                width: "100%",
                padding: "1.5%",
                border: "1px solid #dadce0",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "4%",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "2%",
                    }}
                  >
                    <div className="title_projects">
                      {this.capitalizeFirstLetter("qwewqe")}
                    </div>

                    <div
                      style={{
                        display: "flex",
                        marginTop: "5%",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        <div className="project_detail_data">
                          <p
                            style={{ paddingRight: "10px" }}
                            className="project_detail_client mb-remove"
                          >
                            Client
                          </p>
                          <p
                            style={{ paddingRight: "10px" }}
                            className="project_detail_client mb-remove"
                          >
                            :
                          </p>
                          <p
                            style={{
                              paddingRight: "10px",
                              borderRight: "1px solid #E2E0E0",
                              marginRight: "20px",
                            }}
                            className="project_detail_value mb-remove"
                          >
                            {this.capitalizeFirstLetter("sdfsdf")}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="project_detail_data">
                          <p
                            style={{ paddingRight: "10px" }}
                            className="project_detail_client mb-remove"
                          >
                            End Date
                          </p>
                          <p
                            style={{ paddingRight: "10px" }}
                            className="project_detail_client mb-remove"
                          >
                            :
                          </p>
                          <p
                            style={{
                              paddingRight: "10px",
                              borderRight: "1px solid #E2E0E0",
                              marginRight: "20px",
                            }}
                            className="project_detail_value mb-remove"
                          >
                            {moment(new Date()).format("DD MMM, YYYY")}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="project_detail_data">
                          <p
                            style={{ paddingRight: "10px" }}
                            className="project_detail_client mb-remove"
                          >
                            Status
                          </p>
                          <p
                            style={{ paddingRight: "10px" }}
                            className="project_detail_client mb-remove"
                          >
                            : 
                          </p>
                          <p
                            className="status_change_value mb-remove"
                            // style={{
                            //   color: `${
                            //     project.status === "completed" ||
                            //     project.status === "active" ||
                            //     project.status === "closed"
                            //       ? "#07A84E"
                            //       : project.status === "discontinued" ||
                            //         project.status === "cancelled"
                            //       ? "#f75f18"
                            //       : project.status === "due"
                            //       ? "#0070FD"
                            //       : "#FFCC3D"
                            //   }`,
                            // }}
                          >
                            {/* {project.status === "on_hold"
                              ? "On Hold"
                              : this.capitalizeFirstLetter(project.status)} */}
                              {'Pending'}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div></div>
                </div>
                <div>
                  <img
                    style={{ borderRadius: "5px" }}
                    src={pksLogo}
                    alt="logo"
                  />
                </div>
              </div>
            </Card>
          </div>
          <div style={{ padding: "0% 1%" }} className="twoContainer">
            <div className="left">
              <div className="top_container_hero">
                <div className="title_projects">Payments</div>
                <div className="top_right">
                  <div
                    style={{
                      color: "#338180",
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                  >
                    Pay All
                  </div>
                  <div
                    style={{
                      color: "#338180",
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      this.props.history.push("/client/123/123/payments")
                    }
                  >
                    View All
                  </div>
                </div>
              </div>
              <div style={{ padding: " 0% 3%" }}>
                <Row>
                  <div className="col new-table">
                    <Card className="shadow card_with_crm">
                      <Table
                        className="align-items-center table-flush"
                        responsive
                      >
                        <thead className="thead-light">
                          <tr>
                            <th
                              style={{ width: "15%", borderColor: "#f5f5f5" }}
                              scope="col"
                              className="crm ptb20"
                            >
                              Name
                            </th>
                            <th
                              className="ptb20"
                              style={{ width: "15%", borderColor: "#f5f5f5" }}
                              scope="col"
                            >
                              Due On
                            </th>
                            <th
                              className="ptb20"
                              style={{ width: "15%", borderColor: "#f5f5f5" }}
                              scope="col"
                            >
                              Status
                            </th>
                            <th
                              className="ptb20"
                              style={{
                                width: "4%",
                                cursor: "pointer",
                                fontWeight: "500",
                                color: "#338180",
                                borderColor: "#f5f5f5",
                              }}
                              scope="col"
                              onClick={this.redirectToListWithQuery}
                            ></th>
                          </tr>
                        </thead>
                        {payment_list && payment_list.length !== 0 ? (
                          <tbody>
                            {payment_list.map((project, i) => (
                              <tr key={i}>
                                <td
                                  className="cursor-point capitalize pad20"
                                  onClick={() =>
                                    this.redirectDetailPage(project.project_id)
                                  }
                                  style={{ width: "100px" }}
                                >
                                  <div className="project_list_content">
                                    {this.capitalizeFirstLetter(project.name)}
                                  </div>
                                </td>
                                <td>
                                  <div className="project_list_content">
                                    {" "}
                                    {this.capitalizeFirstLetter(
                                      project.client.name
                                    )}
                                  </div>
                                </td>
                                <td>
                                  <div className="project_list_content">
                                    {" "}
                                    {showUtcDate(project.end_date)}
                                  </div>
                                </td>
                                <td
                                  className={
                                    project?.invoice?.status === "paid" ||
                                    project?.invoice?.status === "assigned"
                                      ? "text-capitalize status_accepted general_status_style"
                                      : project?.invoice?.status ===
                                          "cancelled" ||
                                        project?.invoice?.status === "rejected"
                                      ? "text-capitalize red general_status_style"
                                      : project?.invoice?.status ===
                                          "created" ||
                                        project?.invoice?.status === "pending"
                                      ? "text-capitalize yellow general_status_style"
                                      : project?.invoice?.status === "completed"
                                      ? "text-capitalize status_completed general_status_style"
                                      : project?.invoice?.status === "closed"
                                      ? "text-capitalize primary general_status_style"
                                      : "text-capitalize"
                                  }
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "4%",
                                    }}
                                  >
                                    <div className="project_list_percentage">
                                      {"asd"}
                                    </div>
                                  </div>
                                </td>

                                <td>
                                  <div
                                    className="project_list_status"
                                    style={{
                                      color: `${
                                        project.status === "Completed"
                                          ? "#07A84E"
                                          : project.status === "Pending"
                                          ? "FF4949"
                                          : "FFCC3D"
                                      }`,
                                    }}
                                  >
                                    {" "}
                                    {this.capitalizeFirstLetter(project.status)}
                                  </div>
                                </td>

                                <td>
                                  <div className="">
                                    <UncontrolledDropdown
                                      nav
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <DropdownToggle
                                        className="pr-0 nav-notice"
                                        nav
                                      >
                                        <img src={showMore} alt="show more" />
                                      </DropdownToggle>
                                      <DropdownMenu
                                        className="dropdown-menu-arrow min-width-drop"
                                        right
                                      >
                                        <DropdownItem
                                          onClick={() =>
                                            this.editProject(project)
                                          }
                                        >
                                          <span>Edit</span>
                                        </DropdownItem>

                                        <DropdownItem
                                          onClick={() =>
                                            this.setState({
                                              openModal: true,
                                              delete_data: project,
                                              project_id: project.project_id,
                                            })
                                          }
                                        >
                                          <span>Delete</span>
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        ) : (
                          <tbody>
                            <tr>
                              <td className="no-data">No Payments Available</td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                          </tbody>
                        )}
                      </Table>
                    </Card>
                  </div>
                </Row>
              </div>
            </div>
            <div className="right">
              <div className="top_container_hero">
                <div className="title_projects">Change Orders</div>
                <div className="top_right">
                  <div
                    style={{
                      color: "#338180",
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      this.props.history.push("/client/123/123/change_orders")
                    }
                  >
                    View All
                  </div>
                </div>
              </div>
              <div style={{ padding: " 0% 3%" }}>
                <Row>
                  <div className="col new-table">
                    <Card className="shadow card_with_crm">
                      <Table
                        className="align-items-center table-flush"
                        responsive
                      >
                        <thead className="thead-light">
                          <tr>
                            <th
                              style={{ width: "15%", borderColor: "#f5f5f5" }}
                              scope="col"
                              className="crm ptb20"
                            >
                              Title
                            </th>
                            <th
                              className="ptb20"
                              style={{ width: "15%", borderColor: "#f5f5f5" }}
                              scope="col"
                            >
                              Due On
                            </th>
                            <th
                              className="ptb20"
                              style={{ width: "15%", borderColor: "#f5f5f5" }}
                              scope="col"
                            >
                              Amount
                            </th>
                            <th
                              className="ptb20"
                              style={{ width: "15%", borderColor: "#f5f5f5" }}
                              scope="col"
                            >
                              Status
                            </th>
                            <th
                              className="ptb20"
                              style={{
                                width: "4%",
                                cursor: "pointer",
                                fontWeight: "500",
                                color: "#338180",
                                borderColor: "#f5f5f5",
                              }}
                              scope="col"
                              onClick={this.redirectToListWithQuery}
                            ></th>
                          </tr>
                        </thead>
                        {payment_list && payment_list.length !== 0 ? (
                          <tbody>
                            {payment_list.map((project, i) => (
                              <tr key={i}>
                                <td
                                  className="cursor-point capitalize pad20"
                                  onClick={() =>
                                    this.redirectDetailPage(project.project_id)
                                  }
                                  style={{ width: "100px" }}
                                >
                                  <div className="project_list_content">
                                    {this.capitalizeFirstLetter(project.name)}
                                  </div>
                                </td>
                                <td>
                                  <div className="project_list_content">
                                    {" "}
                                    {this.capitalizeFirstLetter(
                                      project.client.name
                                    )}
                                  </div>
                                </td>
                                <td>
                                  <div className="project_list_content">
                                    {" "}
                                    {showUtcDate(project.end_date)}
                                  </div>
                                </td>
                                <td
                                  className={
                                    project?.invoice?.status === "paid" ||
                                    project?.invoice?.status === "assigned"
                                      ? "text-capitalize status_accepted general_status_style"
                                      : project?.invoice?.status ===
                                          "cancelled" ||
                                        project?.invoice?.status === "rejected"
                                      ? "text-capitalize red general_status_style"
                                      : project?.invoice?.status ===
                                          "created" ||
                                        project?.invoice?.status === "pending"
                                      ? "text-capitalize yellow general_status_style"
                                      : project?.invoice?.status === "completed"
                                      ? "text-capitalize status_completed general_status_style"
                                      : project?.invoice?.status === "closed"
                                      ? "text-capitalize primary general_status_style"
                                      : "text-capitalize"
                                  }
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "4%",
                                    }}
                                  >
                                    <div className="project_list_percentage">
                                      {"asd"}
                                    </div>
                                  </div>
                                </td>

                                <td>
                                  <div
                                    className="project_list_status"
                                    style={{
                                      color: `${
                                        project.status === "Completed"
                                          ? "#07A84E"
                                          : project.status === "Pending"
                                          ? "FF4949"
                                          : "FFCC3D"
                                      }`,
                                    }}
                                  >
                                    {" "}
                                    {this.capitalizeFirstLetter(project.status)}
                                  </div>
                                </td>

                                <td>
                                  <div className="">
                                    <UncontrolledDropdown
                                      nav
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <DropdownToggle
                                        className="pr-0 nav-notice"
                                        nav
                                      >
                                        <img src={showMore} alt="show more" />
                                      </DropdownToggle>
                                      <DropdownMenu
                                        className="dropdown-menu-arrow min-width-drop"
                                        right
                                      >
                                        <DropdownItem
                                          onClick={() =>
                                            this.editProject(project)
                                          }
                                        >
                                          <span>Edit</span>
                                        </DropdownItem>

                                        <DropdownItem
                                          onClick={() =>
                                            this.setState({
                                              openModal: true,
                                              delete_data: project,
                                              project_id: project.project_id,
                                            })
                                          }
                                        >
                                          <span>Delete</span>
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        ) : (
                          <tbody>
                            <tr>
                              <td className="no-data">No Payments Available</td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                          </tbody>
                        )}
                      </Table>
                    </Card>
                  </div>
                </Row>
              </div>
            </div>
          </div>
          <div style={{ padding: "0% 1%" }} className="twoContainer">
            <div className="right">
              <div className="right_switchable ">
                <div className="switchable_slides">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "1%",
                    }}
                  >
                    <div className="title_projects">{selectedContent}</div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "20px",
                      }}
                    >
                      {(selectedContent === "Documents" ||
                        selectedContent === "Photos") && (
                        <div
                          style={{
                            color: "#338180",
                            fontSize: "14px",
                            cursor: "pointer",
                          }}
                        ></div>
                      )}
                      <div
                        style={{
                          color: "#338180",
                          fontSize: "14px",
                          cursor: "pointer",
                        }}
                        onClick={this.viewAll}
                      >
                        View All
                      </div>
                    </div>
                  </div>
                  {selectedContent === "Comments" && (
                    <>
                      <div
                        className="crm_comments"
                        style={{ marginTop: "20px" }}
                      >
                        {comments.length
                          ? comments.map((obj) => (
                              <div className="crm_individual_comments">
                                <div>
                                  <img
                                    width="50px"
                                    height="50px"
                                    src={
                                      // project?.image?.length > 0
                                      //   ? getCloudinaryImage(
                                      //       100,
                                      //       100,
                                      //       project.image[0],
                                      //       "c_fill"
                                      //     )
                                      //   :
                                      pksLogo
                                    }
                                    alt="logo"
                                  ></img>
                                </div>
                                <div className="crm_comment_content">
                                  <div className="crm_comment_date">
                                    {moment(obj.date)
                                      .startOf("day")
                                      .format("DD MMMM")}
                                  </div>
                                  <div className="crm_comment_date">
                                    {obj.content}
                                  </div>
                                </div>
                              </div>
                            ))
                          : EmptyMessage}
                      </div>
                      <div className="crm_comments_input">
                        <div>
                          <img
                            width="40px"
                            height="40px"
                            src={
                              // project?.image?.length > 0
                              //   ? getCloudinaryImage(
                              //       100,
                              //       100,
                              //       project.image[0],
                              //       "c_fill"
                              //     )
                              //   :
                              pksLogo
                            }
                            alt="logo"
                          ></img>
                        </div>
                        <div className=" comment_footer">
                          <div className="ft_1">
                            <textarea
                              ref={this.textareaRef}
                              style={{ height: this.state.textAreaHeight }}
                              onInput={this.update}
                              value={this.state.inputMessage}
                              onKeyDown={this.handleKeyDown}
                              placeholder="Type here..."
                            />
                            <div className="ft_img">
                              {this.state.messageSendloader ? (
                                // <Spinner color="#012a4d">Loading...</Spinner>
                                <div className="image-loading spinner-cut">
                                  <Spinner
                                    style={{ width: "30px", height: "30px" }}
                                    type="border"
                                    color="#012A4D"
                                  />
                                </div>
                              ) : (
                                <img
                                  style={{ cursor: "pointer" }}
                                  onClick={this.sendMessage}
                                  src={sendChat}
                                ></img>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {selectedContent === "Photos" &&
                    (photos.length ? <>sdsd</> : EmptyMessage)}
                  {selectedContent === "Documents" &&
                    (photos.length ? <>sdsd</> : EmptyMessage)}
                </div>
                <div className="contents_listing">
                  {content.map((obj, index) => (
                    <div
                      onClick={() => this.selectContent(index)}
                      style={{
                        backgroundColor: obj.selected
                          ? "rgb(250, 250, 250)"
                          : "",
                      }}
                      className="content_img"
                    >
                      <img src={obj.img}></img>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div style={{ border: "none" }} className="right">
              <div className="right_switchable "></div>
            </div>
          </div>
        </div>
        <Card
          className={
            openAddDocumentsOrPhotos ? "exportSideBar" : "toggle-sidebar"
          }
        >
          {openAddDocumentsOrPhotos && (
            <AddPhotosDocuments
              selectedPannel={selectedContent}
              photDocClose={() =>
                this.setState({ openAddDocumentsOrPhotos: false })
              }
            ></AddPhotosDocuments>
          )}
        </Card>
      </>
    );
  }
}
