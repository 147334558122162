import React, { Component } from "react";
import {
  CardHeader,
  Input,
  DropdownToggle,
  UncontrolledDropdown,
  Media,
  DropdownMenu,
  DropdownItem,
  InputGroup,
  InputGroupText,
  Spinner,
  Button,
  Tag,
  Card,
  Dropdown,
  FormGroup,
} from "reactstrap";
import { returnSearchObject } from "constants/utils";
import "react-datepicker/dist/react-datepicker.css";

import closeIcon from "../../assets/img/brand/close.svg";
import dateLight from "../../assets/img/brand/datePicker.svg";
import moment from "moment";
import uploadIcon from "../../assets/img/icons/upload_icon.svg";
import { getCloudinaryImage } from "constants/utils";
import removeIcon from "../../assets/img/brand/removeIcon.svg";
import Tags from "components/Tags/Tags";
import ApiService from "constants/ApiService";
import Add from "../../assets/img/brand/addIcon.svg";
import {
  showSuccess,
  Isvalidname,
  isInvalidName,
  showError,
  uploadCloudinary,
  isValidEmail,
  getUSFormat,
  cleanPhoneNumber,
} from "constants/utils";
// import AddClient from "./addClient";
import TaskList from "views/Task/TaskList";
import { buildQueryString } from "constants/utils";
import CustomModal from "components/CustomModal/CustomModal";
import { CustomInputFields } from "components/FilterFields/Filterfields";
import { CustomDropDownSearch } from "components/FilterFields/Filterfields";
import { CustomDropDown } from "components/FilterFields/Filterfields";
import { CustomTextArea } from "components/FilterFields/Filterfields";
import { allowOnlyText } from "constants/utils";
import { showToast } from "constants/utils";

class AddLead extends Component {
  constructor(props) {
    super(props);
    this.inputKey = Date.now();
    this.fileInputRef = React.createRef();
    this.myElementRef = React.createRef();
    this.myInputElementRef = React.createRef();
    this.state = {
      name: "",
      email: "",
      phone: "",
      lead_name: "",
      source: "",
      client_name: "",
      clientsList: [],
      subContractor_name: "",
      sub_contractorList: [],
      start_date: "",
      image: [],
      actual_start: "",
      end_date: "",
      imageLoading: false,
      desc: "",
      tags: [],
      btnLoad: false,
      edit: false,
      initAddProjectLoading: true,
      tag: "",
      subContractor_id: "",
      clientAdd: false,
      isSearchOpen: false,
      activeIndex: -1,
      disabled: false,
      isApiCallPending: false,
      file: null,
      projectStartDate: null,
      projectActualStarDate: null,
      projectEndDate: null,
      leadSource: ["Advertisement","Chat", "Call", "Email", "Referral","Website"].sort(),
      add_lead: {},
      showLeads: false,
      listsLoad: false,
      listsBoxOpen: false,
      isNameAlreadyPresent: false,
      title: "",
      timeOut: "",
      placeList: [],
      address_line: "",
      isShowPlaces: false,
      placeLoad: false,
      timoutId: null
    };
  }

  componentDidMount = async () => {
    this.setState({ initAddProjectLoading: true });
    if (this.props.leadID) {
      this.setState(
        {
          edit: true,
          lead_name: this.props.leadID.name,
          email: this.props.leadID.email,
          phone: this.props.leadID.phone,
          desc: this.props.leadID.description || "",
          address_line: this.props.leadID.address,
          source: this.props.leadID.source,
          title: this.props.leadID.title,
        },
        () => {
          this.setState({ initAddProjectLoading: false });
        }
      );
    } else {
      this.setState(
        {
          lead_name: "",
          email: "",
          phone: "",
          desc: "",
          source: "",
          title: "",
        },
        () => {
          this.getClientLists();
          this.setState({ initAddProjectLoading: false });
        }
      );
    }
  };

  imageHandler = async (e) => {
    let file = e.target.files[0];
    let { image } = this.state;
    if (file) {
      this.setState({ imageLoading: true });
    }
    if (
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/jpeg"
    ) {
      if (file.size < 2e6) {
        let data = await uploadCloudinary(file, "host");
        if (data.url) {
          let imgArr = [...image];
          let imageObj = {
            format: data.format,
            public_id: data.public_id,
            version: `v${data.version}`,
            type: "image",
          };
          imgArr.push(imageObj);
          this.setState({
            image: imgArr,
            imageLoading: false,
          });
        } else {
          this.setState({ imageLoading: false });
          e.target.value = null;
          return showError(this.props, "Could not upload image");
        }
      }
    }
  };

  addClient = () => {
    this.setState({ clientAdd: true });
  };

  deleteImage = () => {
    this.setState({ image: [], image_name: "" });
  };

  // handlePhoneChange = (e) => {
  //   const { name, value } = e.target;

  //   const numericValue = value.replace(/\D/g, "");

  //   const limitedValue = numericValue.slice(0, 10);

  //   let formattedPhone = "";

  //   if (limitedValue.length > 0) {
  //     formattedPhone = `(${limitedValue.slice(0, 3)}`;
  //     if (limitedValue.length > 3) {
  //       formattedPhone += `) ${limitedValue.slice(3, 6)}`;
  //       if (limitedValue.length > 6) {
  //         formattedPhone += ` - ${limitedValue.slice(6, 10)}`;
  //       }
  //     }
  //   }

  //   this.setState({ [name]: formattedPhone });
  // };

  addClient = () => {
    this.setState({ clientAdd: true });
  };

  closeModal = () => {
    this.setState({
      start_date: "",
      actual_start: "",
      client_name: "",
      name: "",
      end_date: "",
      tags: [],
      desc: "",
    });
    this.props.close(true);
  };
  createLead = async () => {
    let {
      source,
      client_name,
      start_date,
      end_date,
      actual_start,
      client_id,
      subContractor_id,
      desc,
      edit,
      tags,
      image,
      email,
      phone,
      lead_name,
      title,
      subContractor_name,
      clientsList,
      address_line
    } = this.state;
    let { type } = this.props;
    if (!title) return this.props.toast("danger", "Please enter the title");
    // showError(this.props.props, "Please enter a title");
    else if (!clientsList.length && !lead_name)
      return this.props.toast("danger", "Please enter the name");
    else if (isInvalidName(lead_name))
      return this.props.toast("danger", "Please enter the valid name");
    else if (!email) return this.props.toast("danger", "Please enter the email");
    else if (!isValidEmail(email))
      return this.props.toast("danger", "Please enter the valid email");
    else if (!phone)
      return this.props.toast("danger", "Please enter the phone number");
    else if (cleanPhoneNumber(phone).length !== 10)
      return this.props.toast("danger", "Please enter the valid phone number");
    else if (!address_line)
      return this.props.toast("danger", "Please select the valid address");
    else if (!source)
      return this.props.toast("danger", "Please select the lead source");
    let data = {};
    data.name = lead_name;
    data.email = email;
    data.phone = phone.replace(/\D/g, "");
    data.address = address_line
    // data.status = type === "estimate" ? "converted" : "pending";
    data.description = desc || "";
    data.source = source;
    data.title = title;
    this.setState({ btnLoad: true, isApiCallPending: true });
    let response
    if (edit) {
      response = await ApiService.patchAPI(
        `contractor/lead/${this.props.leadID.lead_id}`,
        data
      )
      if (this.props.from === "estimate") this.props.leadUpdate({ ...data, id: this.props.leadID.lead_id })

    }
    else response = await ApiService.postAPI(`contractor/lead`, data);

    this.setState({ isApiCallPending: false, btnLoad: false });

    if (response.success) {
      this.setState(
        { lead_name: "", email: "", phone: "", address: "", desc: "", source: "" },
        () => {
          this.props.toast("success", response.message);
          this.props.type === "estimate" && this.props?.dataFromParent();
          this.closeModal();
        }
      );
    } else {
      showError(this.props.props, response.message);
    }
  };

  onTagChange = (e) => {
    let { tag, tags } = this.state;
    if (["Enter", "Tab"].includes(e.key)) {
      e.preventDefault();
      let value = tag.trim();
      if (value.length) {
        tags.push(value);
        this.setState({ tags: [...tags], tag: "" });
      }
    } else if (["Backspace"].includes(e.key) && !tag) {
      if (tags.length) {
        tags.pop();
        this.setState({ tags: [...tags], tag: "" });
      }
    }
  };

  getListDetail = async (data) => {
    this.setState(
      {
        lead_name: data.name,
        email: data.email,
        phone: data.phone.national_number,
      },
      () => {
        this.setState({ listsBoxOpen: false, isNameAlreadyPresent: true });
      }
    );
  };

  dateContion = (from, event) => {
    if (from === "start") {
      if (this.props.type === "project") {
        this.setState({ start_date: event });
      }
    }
  };

  removeTag = (index) => {
    let { tag, tags } = this.state;
    let tempTags = tags;
    tempTags.splice(index, 1);
    this.setState({ tags: [...tempTags] });
  };

  onTagAreaClick = () => {
    this.myInputElementRef.current.focus();
  };

  onClientClose = () => {
    this.setState({ clientAdd: false }, () => {
      this.getClientLists();
    });
  };

  handleInputChange = (e, from) => {
    this.setState({ [from]: e }, () => { });
  };

  getClientLists = async (e) => {
    this.setState(
      {
        listsLoad: true,
        isNameAlreadyPresent: false,
        email: "",
        phone: "",
      },
      () => {
        let searchTerm = e || "";
        clearTimeout(this.state.timeOut);
        this.setState({ lead_name: searchTerm });
        const timeOut = setTimeout(async () => {
          let response = await ApiService.getAPI(
            `contractor/client?status=active&name=${searchTerm}`
          );
          this.setState({ initAddProjectLoading: false });
          if (response.success) {
            let { clients } = response.data;
            this.setState(
              {
                clientsList: clients,
                listsLoad: false,
              },
              () => {
                this.state.clientsList.length
                  ? this.setState({ listsBoxOpen: true })
                  : this.setState({ listsBoxOpen: false });
              }
            );
          }
        }, 1000);
        this.setState({ timeOut: timeOut });
      }
    );
  };

  closeLeadModal = () => {
    // if(this.props.leadID) {
    this.props.close(false);
    // }

    // this.props.cancel()
  };

  setSource = (source) => {
    this.setState({ source: source });
  };

  setDescritpion = (value) => {
    this.setState({ desc: value });
  };
  
  handleAddressChange = (event) => {
    let { timeoutId } = this.state
    const newValue = event;
    this.setState({ address_line: newValue });
    // Clear the existing timeout
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    // Set a new timeout
    const newTimeoutId = setTimeout(() => {
      this.getPlaces(newValue);
    }, 500); // 500ms delay

    this.setState({ timeoutId: newTimeoutId });
  };
  getPlaces = async (e) => {
    this.setState({ placeLoad: true, state_name: '', state_id: '', city_name: '', city_id: '' });
    let searchTerm = e;
    this.setState({ address_line: searchTerm });
    let response = await ApiService.getAPI(
      `contractor/maps/search?input=${searchTerm}`
    );
    if (response.success) {
      this.setState({
        isShowPlaces: true,
        placeLoad: false,
        placeList: response.data.place.predictions,
      });
    }
  };
  getPlaceData = async (place) => {
    this.setState({ placeLoad: true });
    let response = await ApiService.getAPI(
      `contractor/maps/place/${place.place_id}`
    );

    if (response.success) {
      let locData = response.data;
      let datas = locData;
      this.setState(
        {
          address_line: place.description,
          isShowPlaces: false,
          placeLoad: false,
          locationData: datas,
        },
      );
    } else {
      this.setState({
        isShowPlaces: false,
      });
    }
  };
  render() {
    const {
      name,
      desc,
      btnLoad,
      edit,
      email,
      lead_name,
      source,
      phone,
      listsLoad,
      initAddProjectLoading,
      leadSource,
      clientsList,
      listsBoxOpen,
      isApiCallPending,
      placeList,
      address_line,
    } = this.state;

    return initAddProjectLoading ? (
      <div className="spin-loading">
        <Spinner
          style={{
            height: "30px",
            width: "30px",
          }}
          type="border"
          color="#012A4D"
        />
      </div>
    ) : (
      <>
        <CustomModal
          btnLoad={btnLoad}
          type={edit ? "Update Lead" : "Add Lead"}
          edit={edit}
          isApiCallPending={isApiCallPending}
          create={this.createLead}
          close={this.closeLeadModal}
        >
          <label className="customLabel">Title*</label>
          <CustomInputFields
            style={{ marginBottom: "20px" }}
            inputValue={this.state.title}
            type="text"
            change={this.handleInputChange}
            from="title"
            placeHolder="Enter the lead"
          />

          <label className="customLabel">Name*</label>
          <CustomDropDownSearch
            disabled={this.props.from === 'estimate'}
            style={{ marginBottom: "20px", width: "100%" }}
            className="customDropDownSearch"
            toSearch="name"
            width={"100%"}
            placeHolder="Enter the client name"
            filteredData={clientsList}
            onChange={this.getClientLists}
            listClick={this.getListDetail}
            value={lead_name}
            type="text"
          />

          <label className="customLabel">Email*</label>
          <CustomInputFields
            style={{ marginBottom: "20px" }}
            inputValue={email}
            type="text"
            change={this.handleInputChange}
            from="email"
            disabled={this.state.isNameAlreadyPresent}
            placeHolder="Enter the email"
          />
          <label className="customLabel">Phone Number*</label>
          <CustomInputFields
            style={{ marginBottom: "20px" }}
            inputValue={getUSFormat(phone)}
            type="text"
            change={this.handleInputChange}
            from="phone"
            disabled={this.state.isNameAlreadyPresent}
            placeHolder="Enter the phone number"
          />
          <label className="customLabel">Address*</label>
          <CustomDropDownSearch
            style={{ marginBottom: "20px", width: "100%" }}
            className="customDropDownSearch"
            toSearch="description"
            width={"100%"}
            placeHolder="Enter the address"
            filteredData={placeList}
            onChange={this.handleAddressChange}
            listClick={this.getPlaceData}
            value={address_line}
          />
          <label className="customLabel">Lead Source*</label>
          <CustomDropDown
            style={{ marginBottom: "20px" }}
            width={"100%"}
            inputField
            innerStyle={{ width: "100%" }}
            status={source}
            setStatus={this.setSource}
            statusList={leadSource}
            placeHolder="Select the source"
          />

          <label className="customLabel">Description</label>
          <CustomTextArea
            inputvalue={desc}
            change={this.setDescritpion}
            from="notes"
            placeHolder="Enter the description"
            style={{ minHeight: "150px" }}
            maxLength="500"
          />
        </CustomModal>
      </>
    );
  }
}
export default AddLead;
