import React, { Component } from "react";
import {
  Card,
  Table,
  Container,
  Row,
  Breadcrumb,
  Col,
  Button,
  Spinner,
  DropdownMenu,
  DropdownItem,
  Media,
  UncontrolledDropdown,
  DropdownToggle,
  Input,
  Dropdown,
  Modal,
  InputGroup,
  Label,
  FormGroup,
  CustomInput,
} from "reactstrap";
import ConvertCancelModal from "./convertCancelModal";
import ApiService from "constants/ApiService";
import "react-datepicker/dist/react-datepicker.css";
import projectTimeline from "../../../src/assets/img/brand/projectTimeline.svg";
import TimeLine from "components/Timeline/Timeline";
import { Switch } from "antd";
import { getIsoString, filterQuery } from "constants/utils";

import ToastList from "components/Toast/ToastList";

import { showError } from "constants/utils";
import { returnSearchObject } from "constants/utils";
import { pushToFilteredPage } from "constants/utils";
import { buildQueryString } from "constants/utils";
import moment from "moment";

import showMore from "../../assets/img/brand/showMore.svg";

import pencil from "../../assets/img/icons/pencil_2.svg";
import deleteIcon from "../../assets/img/icons/delete_3.svg";
import { showSuccess } from "constants/utils";
import { BreadcrumbItem } from "reactstrap";

import { uploadCloudinary } from "constants/utils";
import searchIcon from "../../assets/img/brand/searchIcon.svg";
// import DeleteModal from "./DeleteModal";
import AddTax from "./addTax";

let overlap = document.querySelector(":root");

class Tax extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requestList: [],
      project_list: [],
      total: 0,
      filterToggle: false,
      from: "",
      to: "",
      page: 1,
      open: false,
      client_id: "",
      inputSearch: "",
      filterOption: "",
      status: this.props.location?.search ? "pending" : "",
      toast: false,
      response: "",
      image: [],
      iamge_name: "",
      toastType: "",
      switchState: false,
      isLoading: true,
      clientsList: [],
      btnLoad: false,
      query: {},
      name: "",
      client: {},
      start_date: "",
      actual_start: "",
      imageLoading: false,
      client_name: "",
      inputText: "",
      items: [],
      desc: "",
      notes: "",
      notesbtn: false,
      tags: [],
      end_date: "",
      requestId: "",
      openModal: false,
      delete_data: {},
      cancelModal: false,
      project_id: "",
      lead_id: "",
      all_projects: 0,
      active_projects: 0,
      completed_projects: 0,
      all_tasks: 0,
      isTimeLineOpen: false,
      leadList: [],
      type: "",
      notesAddToggle: false,
    };
  }
  componentDidMount = async () => {
    overlap.style.setProperty("--width", "600px");
    this.setQueryStatus();
  };

  componentWillUnmount() {
    overlap.style.setProperty("--width", "450px");
  }

  closeModal = () => {
    // this.setState({ openModal: false, project_id: "" });
    // this.getProjectList();
  };
  handleDelete = async () => {
    let response = await ApiService.patchAPI(
      `contractor/project/delete/${this.state.delete_data.project_id}`,
      {}
    );
    if (response.success) {
      showSuccess(this, response.message);
      this.setState({ delete_data: {} });
      this.closeModal();
      //   this.getProjectList();
    } else {
      showError(this, response.message);
    }
  };

  setQueryStatus = () => {
    let queryStatus = this.props.props.location?.search;
    if (queryStatus) {
      let queryObj = returnSearchObject(queryStatus);
      this.setState(
        {
          from: queryObj.from ? new Date(queryObj.from) : "",
          to: queryObj.to ? new Date(queryObj.to) : "",
          status: queryObj.status ?? "",
          page: parseInt(queryObj.page) ?? "",
          query: returnSearchObject(queryStatus),
        },
        () => {
          // pushToFilteredPage(this.props.props, { ...this.state.query });
          this.getLeadList();
        }
      );
    } else {
      this.setState(
        {
          query: {
            ...this.state.query,
            page: 1,
            limit: 10,
          },
        },
        () => {
          // pushToFilteredPage(this.props.props, this.state.query);
          this.getLeadList();
        }
      );
    }
  };

  getLeadList = async () => {
    let { filterOption, status, inputSearch, from, to } = this.state;
    let queryParams = "";
    if (filterOption !== "" && inputSearch !== "")
      queryParams += filterQuery(filterOption, inputSearch);
    if (status !== "") {
      queryParams += filterQuery("status", status);
    }
    if (from !== "") queryParams += filterQuery("from", getIsoString(from));
    if (to !== "") queryParams += filterQuery("to", getIsoString(to));
    if (from > to) {
      showError(this, "Please provide valid From and To date");
    } else {
      let response = await ApiService.getAPI(
        `contractor/lead${buildQueryString(this.state.query)}`
      );
      this.setState({ isLoading: false });
      if (response.success) {
        let { leads } = response.data;
        this.setState({ leadList: leads });
      }
    }
  };

  redirectDetailPage = (id) => {
    this.props.props.history.push(`projects/detail/${id}`);
  };

  setPage = (page) => {
    this.props.props.history.push(page);
  };
  dateChangeHandler = (e, value) => {
    this.setState({ [value]: e });
  };

  editProject = async (data) => {
    this.setState(
      {
        lead_id: data,
      },
      () => {
        this.setState({ open: true });
      }
    );
  };

  getRequestsList = async () => {
    let { filterOption, status, inputSearch, from, to } = this.state;
    let queryParams = "";
    if (filterOption !== "" && inputSearch !== "")
      queryParams += filterQuery(filterOption, inputSearch);
    if (status !== "") {
      queryParams += filterQuery("status", status);
    }
    if (from !== "") queryParams += filterQuery("from", getIsoString(from));
    if (to !== "") queryParams += filterQuery("to", getIsoString(to));
    if (from > to) {
      showError(this, "Please provide valid From and To date");
    } else {
      let response = await ApiService.getAPI(
        `contractor/open_request${buildQueryString(this.state.query)}`
      );

      if (response.success) {
        let { open_request, total } = response.data;
        this.setState({
          requestList: open_request,
          total: total,
          isLoading: false,
        });
      }
    }
  };

  getProjectList = async () => {
    let { filterOption, status, inputSearch, from, to } = this.state;
    let queryParams = "";
    if (filterOption !== "" && inputSearch !== "")
      queryParams += filterQuery(filterOption, inputSearch);
    if (status !== "") {
      queryParams += filterQuery("status", status);
    }

    if (from !== "") queryParams += filterQuery("from", getIsoString(from));
    if (to !== "") queryParams += filterQuery("to", getIsoString(to));
    if (from > to) {
      showError(this, "Please provide valid From and To date");
    } else {
      let response = await ApiService.getAPI(
        `contractor/project/${buildQueryString(this.state.query)}`
      );

      if (response.success) {
        this.setState({
          isLoading: false,
          project_list: response.data.projects,
        });
      }
    }
  };

  leadConversionUpdate = (value, data) => {
    this.setState({ openModal: true, type: value, lead_id: data });
  };

  createProjectHandler = async () => {
    let {
      name,
      client_name,
      start_date,
      end_date,
      actual_start,
      client_id,
      desc,
      project_id,
      edit,
      tags,
    } = this.state;
    if (!name || !client_name || !start_date || !end_date || !actual_start)
      return showError(this, "Please fill all the required fields");
    this.setState({ btnLoad: true });
    let data = {
      name: name,
      client: {
        id: client_id,
        name: client_name,
      },
      start_date: start_date,
      end_date: end_date,
      actual_start: actual_start,
      desc: desc,
      tags: tags,
    };
    let response = edit
      ? await ApiService.patchAPI(`contractor/project/${project_id}`, data)
      : await ApiService.postAPI("contractor/project", data);
    if (response.success) {
      showSuccess(this, response.message);
      this.setState({
        btnLoad: false,
        open: false,
        name: "",
        start_date: "",
        end_date: "",
        actual_start: "",
        desc: "",
        tags: [],
        client_name: "",
        client_id: "",
      });
      //   this.getProjectList();
    } else {
      this.setState({ btnLoad: false });
      showError(this, response.message);
    }
  };

  handleTagsChange = (tags) => {
    this.setState({ tags: tags });
  };
  handleInputChange = (event) => {
    this.setState({ inputText: event.target.value });
  };

  handleEnterKey = (event) => {
    if (event.key === "Enter" && this.state.inputText.trim() !== "") {
      this.setState((prevState) => ({
        inputText: "",
        items: [...prevState.items, prevState.inputText],
      }));
    }
  };
  toggleFilterMenu = () => {
    this.setState({ filterToggle: !this.state.filterToggle });
  };
  onChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  updateStateValues = (key, value) => {
    this.setState(
      {
        [key]: value,
      },
      function () {
        if (value === null) {
          this.setState({
            [key]: "",
          });
        }
      }
    );
  };
  handleOnToChange = (e) => {
    this.updateStateValues("to", e ? new Date(e) : '');
  };
  handleOnFromChange = (e) => {
    this.updateStateValues("from", e ? new Date(e) : '');
  };
  handleIncrementPage = () => {
    if (this.state.leadList.length >= 10) {
      this.setState({ page: this.state.page + 1 }, () =>
        this.handleFilterStatus()
      );
    }
  };
  handleDecrementPage = () => {
    if (this.state.page > 1) {
      this.setState({ page: this.state.page - 1 }, () =>
        this.handleFilterStatus()
      );
    }
  };
  capitalizeFirstLetter = (str) => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  imageHandler = async (e, subStatus) => {
    let { image } = this.state;
    let file = e.target.files[0];

    if (e.target.files.length > 0) {
      this.setState({ imageLoading: true });
    }

    if (
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/jpeg" ||
      file.type === "application/pdf"
    ) {
      if (file.size < 1000000) {
        let data = await uploadCloudinary(file, "host");
        if (data.url) {
          let imgArr = [...image];
          let imageObj = {
            format: data.format,
            public_id: data.public_id,
            version: `v${data.version}`,
            type: "image",
          };
          imgArr.push(imageObj);
          this.setState({
            image: imgArr,
            imageLoading: false,
          });
        } else {
          showError(this, "Could not upload image");
          this.setState({ imageLoading: false });
        }
      } else {
        showError(this, "File size should not exceeds 1 MB");
        this.setState({ imageLoading: false });
      }
    } else {
      showError(this, "Invalid Format");
      this.setState({ imageLoading: false });
    }
  };

  deleteImage = () => {
    this.setState({ image: [], image_name: "" });
  };

  closeTimeline = () => {
    this.setState({ isTimeLineOpen: false });
  };

  handleFilterStatus = () => {
    let staticQuery = returnSearchObject(this.props.props.location.search);
    staticQuery.status = this.state.status ?? "";
    staticQuery.page = this.state.page ?? "";
    if (this.state.inputSearch !== "" && this.state.filterOption !== "") {
      staticQuery[this.state.filterOption] = this.state.inputSearch;
    } else staticQuery[this.state.filterOption] = "";
    this.setState({ query: staticQuery }, () => {
      // pushToFilteredPage(this.props.props, { ...this.state.query });
      this.getLeadList();
    });
  };

  handleSearch = () => {
    this.setState({ page: 1 }, () => {
      this.handleFilterStatus();
    });
  };

  handleReset = () => {
    let self = this;
    self.setState(
      {
        from: "",
        to: "",
        status: "",
        filterOption: "",
        inputSearch: "",
        page: 1,
        limit: 10,
        query: {
          page: 1,
          limit: 10,
        },
      },
      () => {
        // pushToFilteredPage(this.props.props, { ...this.state.query });
        this.getLeadList();
      }
    );
  };

  setStatus = (status) => {
    this.setState({ status: status, page: 1 }, () => {
      this.handleFilterStatus();
    });
  };

  redirectToAddRequest = async () => {
    let response = await ApiService.getAPI(`contractor/client?status=active`);

    if (response.success) {
      let { clients } = response.data;
      this.setState({
        clientsList: clients,
        edit: false,
        open: true,
      });
    }
  };

  toggleModal = () => {
    this.setState({ openModal: false });
    this.getLeadList();
  };

  editRequest = (data) => {
    this.props.props.history.push(`/admin/request/update/${data?.open_request_id}`);
  };

  lead_accum = (data) => {
    let lists = this.state.leadList;
    lists.push(data);
    this.setState({ leadList: lists });
  };

  onClose = () => {
    this.setState({ open: false, project_id: "" });
    this.getLeadList();
  };

  cancelRequest = async (request) => {
    this.setState({ cancelModal: true, requestId: request.open_request_id });
  };
  toggleSwitch = () => {
    this.setState((prevState) => ({
      switchState: !prevState.switchState,
    }));
  };
  resendRequest = async (request) => {
    let response = await ApiService.patchAPI(
      `contractor/open_request/resend/${request.open_request_id}`
    );
    if (response.success) {
      showSuccess(this, response.message);
      //   this.getProjectList();
    } else {
      showError(this, response.message);
    }
  };
  resendInvoice = async (request) => {
    let response = await ApiService.patchAPI(
      `contractor/open_request/invoice/${request.open_request_id}`
    );
    if (response.success) {
      showSuccess(this, response.message);
      //   this.getProjectList();
    } else {
      showError(this, response.message);
    }
  };

  addNotes = async () => {
    this.setState({ notesbtn: true });
    if (!this.state.notes) {
      this.setState({ notesbtn: false });
      return showError(this, "Please add notes");
    } else {
      let response = await ApiService.patchAPI(
        `contractor/lead/notes/${this.state.lead_id}`,
        { notes: this.state.notes }
      );
      this.setState({ notesbtn: false });
      if (response.success) {
        showSuccess(this, response.message);
        this.setState({ notesAddToggle: false });
      } else {
        return showError(this, response.message);
      }
    }
  };

  render() {
    let {
      requestList,
      isLoading,
      project_id,
      lead_id,
      page,
      status,
      toast,
      response,
      toastType,
      notes,
      clientsList,
      type,
      project_list,
      leadList,
      name,
      imageLoading,
      start_date,
      actual_start,
      desc,
      end_date,
      tags,
      client_name,
      notesAddToggle,
      btnLoad,
      inputSearch,
      filterOption,
      image,
      openModal,
      delete_data,
      open,
      edit,
      all_projects,
      active_projects,
      completed_projects,
      all_tasks,
      switchState,
      notesbtn,
    } = this.state;
    return isLoading ? (
      <div className="spin-loading">
        <Spinner type="border" color="#077e8b" />
      </div>
    ) : (
      <>
        <Container style={{marginBottom:'30px'}} className="card-pad-wrapper" fluid>
          {toast && <ToastList message={response} type={toastType} />}
          <Row
            style={{
              justifyContent: "space-between",
              alignItems: "center",
              paddingLeft: "1.1rem",
              marginBottom: "2rem",
              width: "100.8%",
            }}
          >
            <div>Add all the Taxes to the Invoices Automatically</div>

            <Switch
              defaultChecked
              checked={switchState}
              onChange={this.toggleSwitch}
              style={{ color: "black", width: "3rem" }}
              size="big"
            />
          </Row>

          <Row>
            <div className="col new-table">
              <Card className="shadow card_with_paginator">
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      {/* <th scope="col" className="pad20">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "3%",
                          }}
                        >
                          <img src={searchIcon} alt="searchIcon" />
                          <input
                            style={{
                              border: "none",
                              backgroundColor: "#f5f5f5",
                            }}
                            placeholder="Search By Lead"
                          />
                        </div>
                      </th> */}
                      <th scope="col" className="pad20">
                        Name
                      </th>
                      <th scope="col">Percentage</th>
                      <th scope="col">Description</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  {leadList && leadList.length !== 0 ? (
                    <tbody>
                      {leadList.map((list, i) => (
                        <tr key={i}>
                          <td
                            className="capitalize pad20"
                            // onClick={() =>
                            //   this.redirectDetailPage(list.list_id)
                            // }
                          >
                            <div className="project_list_content">
                              {this.capitalizeFirstLetter(list.name)}
                            </div>
                          </td>
                          <td>
                            <div className="project_list_content">40%</div>
                          </td>

                          <td>
                            <div className="project_list_content">
                              {" "}
                              lorem o k jniasodfaf dafasdfadsf
                            </div>
                          </td>

                          <td>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end",
                                gap: "20px",
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: "#f4f4f4",
                                  padding: "2% 4%",
                                  borderRadius: "5px",
                                }}
                              >
                                <img src={pencil} alt="pencil" />
                              </div>
                              <div
                                style={{
                                  backgroundColor: "#f4f4f4",
                                  padding: "2% 4%",
                                  borderRadius: "5px",
                                }}
                              >
                                <img src={deleteIcon} alt="delete" />
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td className="no-data">No Leads Available</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        {/* <td></td> */}
                        {/* <td></td> */}
                        {/* <td></td> */}
                      </tr>
                    </tbody>
                  )}
                </Table>
              </Card>
            </div>
          </Row>

          <Card
            className={this.state.open ? "exportSideBar" : "toggle-sidebar"}
          >
            {this.state.open && (
              <AddTax
                type="tax"
                props={this}
                leadID={this?.state?.tax_id}
                close={this.onClose}
                addLead={this.lead_accum}
              ></AddTax>
            )}
          </Card>
          <Card
            className={
              this.state.isTimeLineOpen ? "exportSideBar" : "toggle-sidebar"
            }
          >
            {this.state.isTimeLineOpen && (
              <TimeLine
                id={this.state.lead_id}
                closeTimelineModal={this.closeTimeline}
                from={"lead"}
              ></TimeLine>
            )}
          </Card>

          <Modal
            isOpen={notesAddToggle}
            centered={true}
            style={{ minWidth: "35rem", padding: "2rem" }}
          >
            <div style={{ padding: "20px" }}>
              <div className="conCanMod" style={{ textAlign: "center" }}>
                Add Notes
              </div>
              {/*  */}
              <textarea
                className="text-area-add-project"
                value={notes}
                style={{
                  fontSize: "14px",
                  color: "#212529",
                  border: "1px solid #f0f0f0",
                  marginBottom: "16px",
                }}
                maxLength={500}
                name="notes"
                onChange={(e) => {
                  this.setState({ notes: e.target.value });
                }}
              />
              <div style={{ justifyContent: "center" }} className="flexEnd">
                <div
                  onClick={() =>
                    this.setState({
                      notesAddToggle: false,
                      notes: "",
                      lead_id: "",
                      notesbtn: false,
                    })
                  }
                  className="cl0A8080 mr-20 point fw-500"
                >
                  Cancel
                </div>
                <Button className="btn-info" onClick={() => this.addNotes()}>
                  {" "}
                  {notesbtn ? (
                    <div className="spin-loading-save w100 h100">
                      <Spinner
                        style={{
                          height: "20px",
                          width: "20px",
                        }}
                        type="border"
                        color="#0A8080"
                      />
                    </div>
                  ) : (
                    "Add"
                  )}
                </Button>
              </div>
            </div>
          </Modal>

          {this.state.lead_id && (
            <ConvertCancelModal
              props={this}
              toggle={() => this.toggleModal()}
              isOpen={openModal}
              subject={type}
              lead={this.state.lead_id}
            ></ConvertCancelModal>
          )}
        </Container>
      </>
    );
  }
}

export default Tax;
