/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import CustomSpinner from "../../components/CustomSpinner/CustomSpinner";
import { useLocation } from "react-router-dom";
import ApiService from "constants/ApiService";
import ToastList from "components/Toast/ToastList";
import {
  BreadcrumbItem,
  Breadcrumb,
  Button,
  Input,
  Row,
  Card,
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  DropdownMenu,
  DropdownItem,
  CardBody,
  Modal,
  Form,
  Col,
} from "reactstrap";
import { Spin, Select } from "antd";
import CloseIcon from "../../assets/img/icons/closeIcon.svg";
import {
  DatabaseOutlined,
  LoadingOutlined,
  ToolFilled,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import copylink from "../../assets/img/newIcons/copyLinkPrimary.svg";
import { CustomListingContainer } from "components/Container/CustomListingContainer";
import { ListingHeaderLayout } from "components/Container/CustomListingContainer";
import Arrow from "../../assets/img/icons/common/next.svg";
import DownlaodIcon from "../../assets/img/newIcons/newDownload.svg";
import showMore from "../../assets/img/brand/showMore.svg";
import editIcon from "../../assets/img/brand/edit.svg";
import { Tooltip } from "antd";
import LinkIcon from "../../assets/img/newIcons/newLink.svg";
import { Custom_Button } from "components/Buttons/Button";
import { ListingBodyLayout } from "components/Container/CustomListingContainer";
import {
  getUSFormat,
  roundOff,
  formatAmountWithCommas,
  showError,
  showSuccess,
  remove_underscore_capitalize,
  getAuthToken,
  downloadDocuments,
  linkGenerator,
} from "constants/utils";
import LeadSelect from "components/LeadSelect/LeadSelect";
import PreTabInputField from "components/Inputs/PreTabInputField";
import { CustomSingleDatePicker } from "components/FilterFields/Filterfields";
import TableFormatter from "components/Table/Table";
import removeList from "../../assets/img/icons/deleteTableIcon.svg";
import addList from "../../assets/img/icons/addTableIcon.svg";
import moment from "moment";
import AddSchedules from "./addSchedule";
import DeleteModal from "views/Project/DeleteModal";
import Env from "constants/Env";
import info from "../../assets/img/brand/infoIcon.svg";
import Terms from "views/TermsPolicy/Terms";
import { showToast } from "constants/utils";
import { CustomInputFields } from "components/FilterFields/Filterfields";
import { CustomTextArea } from "components/FilterFields/Filterfields";
import { showUtcDate } from "constants/utils";
import { CopyEstimateLink } from "constants/utils";
import ShortUniqueId from "short-unique-id";
import { numberRequired } from "constants/utils";
import { allowOnlyRoundNumber } from "constants/utils";
import AddClient from "views/Project/addClient";
import AddLead from "./addLead";
import TextEditor from "components/TextEditor/texteditor";
import { Button_without_Border } from "components/Buttons/Button";
import { isInvalidEmail } from "constants/utils";
import { SettingOutlined } from "@ant-design/icons";
import { CustomDropDown } from "components/FilterFields/Filterfields";
import { appendCurrency } from "constants/utils";
import { NumberWithCurrency } from "constants/utils";
const { Option } = Select;
function AddEstimates(props) {
  const user = useMemo(() => {
    return JSON.parse(localStorage.getItem("contractor_details"));
  }, []);
  const myInputElementRef = React.createRef();
  const myElementRef = React.createRef();
  const { randomUUID } = new ShortUniqueId({ length: 10 });
  const subjectRef = useRef(null);
  const timeoutRef = useRef(null);
  const rowCell = [
    {
      title: "Item",
      id: "border_bottom",
      className: "font_standard fw_500 pdl-2",
      style: {
        width: "20%",
        paddingLeft: "10px",
        color: "#555555",
        backgroundColor: "white",
      },
    },
    {
      title: "Description",
      id: "border_bottom",
      className: "font_standard fw_500 pdl-2",
      style: {
        padding: "15px 10px",
        width: "30%",
        color: "#555555",
        backgroundColor: "white",
      },
    },
    {
      title: "Qty",
      id: "border_bottom",
      is_enabled: true,
      className: "font_standard fw_500",
      style: {
        padding: "15px 10px",
        width: "10%",
        color: "#555555",
        backgroundColor: "white",
        textAlign: "center",
      },
    },
    {
      title: "Labor",
      id: "border_bottom",
      is_enabled: true,
      className: "font_standard fw_500",
      style: {
        padding: "15px 10px",
        width: "10%",
        color: "#555555",
        backgroundColor: "white",
        textAlign: "right",
        paddingRight: "20px",
      },
    },
    {
      title: "Material",
      id: "border_bottom",
      is_enabled: true,
      className: "font_standard fw_500 ",
      style: {
        width: "10%",
        color: "#555555",
        backgroundColor: "white",
        textAlign: "right",
        paddingRight: "20px",
      },
    },
    {
      title: "Mark Up",
      is_enabled: true,
      id: "border_bottom",
      className: "font_standard fw_500 ",
      style: {
        width: "10%",
        color: "#555555",
        backgroundColor: "white",
        textAlign: "right",
        paddingRight: "20px",
      },
    },
    {
      title: "Cost",
      id: "border_bottom",
      is_enabled: true,
      className: "font_standard fw_500 ",
      style: {
        width: "10%",
        color: "#555555",
        backgroundColor: "white",
        textAlign: "right",
        paddingRight: "20px",
      },
    },
    {
      title: "Amount",
      id: "border_bottom",
      is_enabled: true,
      className: "font_standard fw_500 pdl-2",
      style: {
        color: "#555555",
        width: "130px",
        textAlign: "right",
        paddingRight: "2.5rem",
        backgroundColor: "#F7F8FA",
      },
    },
  ];
  const termsRef = useRef(null);
  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);
  const estimateId = props?.props?.match?.params?.project_id
    ? queryParams.get("workOrder_id")
    : queryParams.get("estimate_id");
  const [estId, setEstId] = useState(estimateId);
  const [initPageLoading, setInitPageLoading] = useState(true);
  const [contractorDetails, setContractorDetails] = useState({});
  const [leadLists, setleadLists] = useState([]);
  const [toggleGeneralInfo, setToggleGeneralInfo] = useState(true);
  const [toggleItems, setToggleItems] = useState(true);
  const [togglePayments, setTogglePayments] = useState(true);
  const [toggleTermsandCondition, setToggleTermsandCondition] = useState(true);
  const [openLead, setOpenLead] = useState(false);
  const [draftLoad, setDraftLoad] = useState(false);
  const [status, setStatus] = useState();
  const [schedulePaymentOpen, setSchedulePaymentOpen] = useState(false);
  const [splitupEdit, setSplitUpEdit] = useState(false);
  const [emailModal, setEmailModal] = useState(false);
  const [splitUpDetail, setSplitUpDetail] = useState();
  const [splitIndex, setSplitIndex] = useState();
  const [emailSubject, setEmailSubject] = useState();
  const [emailContent, setEmailContent] = useState();
  const [deleteSplitUpModal, setDeleteSplitUpModal] = useState(false);
  const [workOrderId, setWorkOrderId] = useState(null);
  const [emailLoadBtn, setEmailLoadBtn] = useState(false);
  const [url, setUrl] = useState("");
  const [previousPage, setPreviousPage] = useState();
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [editClient, setEditClient] = useState(false);
  const [clientId, setClientId] = useState("");
  const [descIndex, setDescIndex] = useState({
    index: "",
    type: "",
    value: "",
  });
  const [toast, setToast] = useState({
    toast: false,
    toastType: "",
    response: "",
  });
  const [isFromWorkOrderList, setIsFromWorkOrderList] = useState(false);
  const [descValue, setDescValue] = useState("");
  const [qtyChange, setQtyChange] = useState(false);
  const [leadDetails, setLeadDetails] = useState("");
  const [estimateSchema, setEstimateSchema] = useState({
    lead: {},
    sub_contractor: {},
    subject: null,
    due_date: new Date(),
    items: [
      {
        item_name: "",
        item_desc: "",
        quantity: 1,
        labor: null,
        material: null,
        cost: null,
        markup: null,
        itemDescRef: React.createRef(),
        itemRef: React.createRef(),
        amount: null,
      },
    ],
    itemsTotal: {
      totalAmount: null,
      subTotalAmount: null,
      taxAmount: null,
    },
    isMarkupView: false,
    splitup: [],
    tax: {
      type: "percentage",
      value: "",
    },
    terms: null,
    itemsVisibility: {
      item_name: {
        name: "item_name",
        is_enabled: true,
        className: "name",
        style: {
          width: "250px",
          paddingLeft: "10px",
          color: "#555555",
          backgroundColor: "white",
        },
      },
      item_desc: {
        name: "item_desc",
        is_enabled: true,
        className: "desc",
        style: {
          width: "250px",
          padding: "15px 10px",
          color: "#555555",
          backgroundColor: "white",
        },
      },
      quantity: {
        name: "quantity",
        is_enabled: true,
        className: "font_standard fw_500 tableClass",
      },
      labor: {
        name: "labor",
        is_enabled: true,
        className: "font_standard fw_500 tableClass",
      },
      material: {
        name: "material",
        is_enabled: true,
        className: "font_standard fw_500 tableClass",
      },
      markup: {
        name: "markup",
        is_enabled: false,
        className: "font_standard fw_500 tableClass",
      },
      cost: {
        name: "cost",
        is_enabled: false,
        className: "font_standard fw_500 tableClass",
      },
      amount: {
        name: "amount",
        is_enabled: true,
        className: "font_standard fw_500 tableClass",
      },
    },
    search: "",
  });
  const [lastSplitDetail, setLastSplitDetail] = useState(null);
  const [initial, setInitial] = useState(false);
  const NumToWords = {
    1: "One",
    2: "Two",
    3: "Three",
    4: "Four",
    5: "Five",
    6: "Six",
    7: "Seven",
    8: "Eight",
    9: "Nine",
    10: "Ten",
  };
  const [remaining, setRemaining] = useState(null);
  const rowClasses = {
    item_name: {
      className: "font_standard fw_500 pdl-2",
      style: {
        width: "20%",
        paddingLeft: "10px",
        color: "#555555",
        backgroundColor: "white",
      },
    },
    item_desc: {
      className: "font_standard fw_500 pdl-2",
      style: {
        padding: "15px 10px",
        width: "30%",
        color: "#555555",
        backgroundColor: "white",
      },
    },
    quantity: {
      className: "font_standard fw_500",
      style: {
        padding: "15px 10px",
        width: "10%",
        color: "#555555",
        backgroundColor: "white",
        textAlign: "center",
      },
    },
    labor: {
      className: "font_standard fw_500",
      style: {
        padding: "15px 10px",
        width: "10%",
        color: "#555555",
        backgroundColor: "white",
        textAlign: "right",
        paddingRight: "20px",
      },
    },
    material: {
      className: "font_standard fw_500 ",
      style: {
        width: "10%",
        color: "#555555",
        backgroundColor: "white",
        textAlign: "right",
        paddingRight: "20px",
      },
    },
    markup: {
      className: "font_standard fw_500 ",
      style: {
        width: "10%",
        color: "#555555",
        backgroundColor: "white",
        textAlign: "right",
        paddingRight: "20px",
      },
    },
    cost: {
      className: "font_standard fw_500 ",
      style: {
        width: "10%",
        color: "#555555",
        backgroundColor: "white",
        textAlign: "right",
        paddingRight: "20px",
      },
    },
    amount: {
      className: "font_standard fw_500 pdl-2",
      style: {
        color: "#555555",
        width: "130px",
        textAlign: "right",
        paddingRight: "2.5rem",
        backgroundColor: "#F7F8FA",
      },
    },
  };
  const [downloadLoad, setDownloadLoad] = useState(false);
  const [previewBtn, setPreviewBtn] = useState(false);
  const dateFormat = "MM/DD/YYYY";
  const [isWorkOrder, setIsWorkOrder] = useState(null);
  const [tempItemDesc, setTempItemDesc] = useState(null);
  const [tempTerms, setTempTerms] = useState(null);
  const [mouseScroll, setMouseScroll] = useState(false);
  const [cc, setCc] = useState("");
  const [ccRecipients, setCcRecipients] = useState([]);
  const [defaultContent, setDefaultContent] = useState(false);
  const [splitupAmount, setSplitupAmount] = useState(null);
  const [edit, setEdit] = useState(false);
  const [index, setIndex] = useState(null);
  const [lastIndex, setLastIndex] = useState(null);
  const [timeoutId, setTimeoutId] = useState(null);
  const [newTimeOut, setNewTimeOut] = useState(null);
  const [updateItem, setupdateItem] = useState(false);
  const [updateSplitUpItem, setUpdateSplitUpItem] = useState(false);
  const [leadId, setleadId] = useState(null);
  const [disableAdd, setDisableAdd] = useState(false);
  const [changed, setChanged] = useState(false);
  const [spltlt, setSplTlt] = useState();

  let out = useRef(null);

  useEffect(() => {
    editClient &&
      getLeadDetails(estimateSchema?.lead?.lead_id || estimateSchema?.lead?.id);
  }, [editClient]);

  useEffect(() => {
    const asyncFunction = async () => {
      if (updateSplitUpItem) {
        await saveDraft();
        setupdateItem(true);
        // addNewSpiltUp();
      }
    };
    asyncFunction();
  }, [updateSplitUpItem]);

  useEffect(() => {
    if (emailModal) {
      setCcRecipients([user.contractor.email]);
    }
  }, [emailModal]);

  useEffect(() => {
    if (updateItem) {
      addNewSpiltUp();
    }
  }, [updateItem]);

  useEffect(() => {
    if (edit) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      const newTimeoutId = setTimeout(() => {
        const tempObj = { ...estimateSchema.splitup[index] };
        setSplitUpDetail(tempObj);
        setEdit(false);
      }, 2000);
      setTimeoutId(newTimeoutId);
      return () => clearTimeout(newTimeoutId);
    }
  }, [estimateSchema.splitup, edit]);

  useEffect(() => {
    const asyncFunction = () => {
      if (splitUpDetail && edit) {
        constuctSplitUp(splitUpDetail, true);
      }
    };

    asyncFunction();
  }, [splitUpDetail]);

  useEffect(() => {}, [remaining]);

  useEffect(() => {
    setIsFromWorkOrderList(props.props?.location?.state === "workOrdersList");
  }, []);

  useEffect(() => {
    estimateSchema.tax.value && calculateTaxAmount();
  }, [estimateSchema.tax.value, estimateSchema.tax.type]);

  useEffect(() => {
    if (changed) {
      saveDraft();
      setChanged(false);
    }
  }, [changed]);

  useEffect(() => {}, [deleteSplitUpModal]);

  useEffect(async () => {
    if (props?.location?.state?.type === "lead") {
      const { lead } = props?.location?.state;
      changeSchema("lead", {
        name: lead.name,
        email: lead.email,
        phone: lead.phone,
        lead_id: lead.lead_id,
        title: lead.title,
        source: lead.source,
        description: lead.description,
        address: lead.address,
      });
    }
    if (props?.props?.match?.params?.project_id) {
      setPreviousPage("Work Orders");
      setIsWorkOrder(true);
    } else {
      setPreviousPage("Estimates");
      setIsWorkOrder(false);
    }
    if (props?.location?.state?.preview) {
      afterFromPreview(props?.location?.state);
    }
    // else {
    //   addOrEdit();
    // }
  }, []);

  useEffect(() => {
    getContractorDetails();
    addOrEdit();
    isWorkOrder &&
      setTimeout(() => {
        props.props.location.state?.splitUp && onSchedulePayment();
      }, 1500);
  }, [isWorkOrder]);

  useEffect(() => {
    calculateSplitUpTotalAmount();
    setTextAreaHeight();
  }, [estimateSchema]);

  useEffect(() => {
    updateTotalSPlitUp();
  }, [estimateSchema.itemsTotal]);

  useEffect(() => {
    setTextAreaHeight();
  }, [initPageLoading]);

  useEffect(() => {
    currentBreadCrumbs();
  }, [workOrderId]);

  useEffect(() => {
    const itemsTotal = estimateSchema.itemsTotal;
    itemsTotal.totalAmount = +itemsTotal.subTotalAmount + +itemsTotal.taxAmount;
    changeSchema("itemsTotal", itemsTotal);
  }, [estimateSchema.items]);

  useEffect(() => {
    !isWorkOrder &&
      isWorkOrder === null &&
      setTimeout(() => {
        props.location?.state?.splitUp && onSchedulePayment();
      }, 1500);
  }, []);

  useEffect(() => {
    const isPercentage = estimateSchema.tax.type === "percentage";
    const itemsTotal = estimateSchema.itemsTotal;
    itemsTotal.taxAmount = isPercentage
      ? +itemsTotal.subTotalAmount * (+estimateSchema.tax.value / 100)
      : estimateSchema.tax.value;
    itemsTotal.totalAmount = +itemsTotal.subTotalAmount + +itemsTotal.taxAmount;
    changeSchema("itemsTotal", itemsTotal);
  }, [estimateSchema.tax]);

  const getLeadDetails = async (id) => {
    setleadId(id);
    let response = await ApiService.getAPI(`contractor/lead/${id}`);
    let lead = response.data.lead[0];
    setLeadDetails({
      name: lead?.name,
      email: lead?.email,
      phone: lead?.phone,
      lead_id: lead?.lead_id,
      title: lead?.title,
      source: lead?.source,
      description: lead?.description,
      address: lead?.address,
    });
  };

  function changeTaxType(type) {
    const tax = { ...estimateSchema.tax };
    tax.type = type;
    setEstimateSchema((prev) => ({
      ...prev,
      tax: tax,
    }));
  }

  function eye(str) {
    const items = estimateSchema.itemsVisibility;
    items[str].is_enabled = !items[str].is_enabled;
    changeSchema("itemsVisibility", items);
  }

  function calculateTaxAmount() {
    const { items } = estimateSchema;
    let totalTaxAmount = 0;
    const currentTotal = items.reduce(
      (acc, cur) => acc + +cur.quantity * +cur.material + +cur.labor,
      0
    );
    estimateSchema.tax.type === "percentage"
      ? (totalTaxAmount = (
          calculateTotalAmount(estimateSchema.items) *
          (estimateSchema.tax.value / 100)
        ).toFixed(2))
      : (totalTaxAmount = roundOff(+estimateSchema.tax.value));
    changeSchema("itemsTotal", {
      ...estimateSchema.itemsTotal,
      taxAmount: totalTaxAmount,
      totalAmount: +totalTaxAmount + +currentTotal,
    });
  }

  function afterFromPreview(state) {
    setInitPageLoading(true);
    const {
      lead,
      subject,
      due_date,
      items,
      splitups,
      tax,
      terms,
      status,
      estimate_id,
      isMarkupView,
    } = state;
    estimateSchema.lead = lead;
    estimateSchema.subject = subject;
    estimateSchema.due_date = due_date;
    estimateSchema.items = items;
    estimateSchema.splitup = splitups;
    estimateSchema.tax = tax;
    estimateSchema.terms = terms || null;
    estimateSchema.isMarkupView = isMarkupView;
    setEstId(estimate_id);
    setStatus(status);
  }

  function setTextAreaHeight() {
    estimateSchema.items.map((item) => {
      const itemRef = item.itemRef;
      const itemDescRef = item.itemDescRef;
      if (
        // itemDescRef?.current &&
        itemRef?.current &&
        subjectRef?.current
        // termsRef?.current
      ) {
        // itemDescRef.current.style.height = "26px";
        // itemDescRef.current.style.height = `${itemDescRef.current.scrollHeight}px`;

        itemRef.current.style.height = "25px";
        itemRef.current.style.height = `${itemRef.current.scrollHeight}px`;

        subjectRef.current.style.height = "28px"; // Reset height
        subjectRef.current.style.height = `${subjectRef.current.scrollHeight}px`;

        // termsRef.current.style.height = "28px";
        // termsRef.current.style.height = `${termsRef.current.scrollHeight}px`;
      }
    });
    if (estimateSchema?.splitup?.length) {
      estimateSchema.splitup.map((splitup) => {
        const splitupRef = splitup.splitup_ref;
        if (splitupRef) {
          splitupRef.current.style.height = "25px";
          splitupRef.current.style.height = `${splitupRef.current.scrollHeight}px`;
        }
      });
    }
  }

  const onLinkClick = (id) => {
    isWorkOrder
      ? CopyEstimateLink(id, "work_order")
      : CopyEstimateLink(id, "estimate");
    Showtoast("success", "Link Copied");
  };

  const onGenerateLink = (id) => {
    return isWorkOrder
      ? linkGenerator(id, "work_order")
      : linkGenerator(id, "estimate");
  };

  function onSubjectChange(e) {
    changeSchema("subject", e.target.value);
  }

  function onTermsChange(e) {
    changeSchema("terms", e);
    setTempTerms(e);
  }

  function changeDueDate(e) {
    changeSchema("due_date", e ? new Date(e) : "");
  }

  function changeSplitupDate(e, i) {
    const currentDate = moment().startOf("day");
    const tempArr = [...estimateSchema.splitup];
    const updatedItem = { ...tempArr[i] };
    const differenceInDays = moment(new Date(e)).diff(currentDate, "days");
    switch (differenceInDays) {
      case 15:
        updatedItem.payment_term = "Net 15";
        break;
      case 30:
        updatedItem.payment_term = "Net 30";
        break;
      case 60:
        updatedItem.payment_term = "Net 60";
        break;
      default:
        updatedItem.payment_term = "Custom";
    }
    updatedItem.due_date = e ? new Date(e) : "";
    tempArr[i] = updatedItem;
    setEstimateSchema((prev) => ({
      ...prev,
      splitup: tempArr,
    }));
    setEdit(true);
    setIndex(i);
    // changeSchema("splitup", newSplitUps);
  }

  function onLeadSelect(data) {
    isWorkOrder
      ? changeSchema("sub_contractor", data)
      : changeSchema("lead", data);
    setOpenLead(false);
  }

  function calculateTotalAmount() {
    let totalAmount = 0;
    const items = estimateSchema.items;
    items.forEach((item) => {
      let amount = item.quantity * item.material;
      let labor = (amount += item.labor);
      totalAmount += labor;
    });
    return roundOff(totalAmount);
  }

  function changeSchema(target, value) {
    setEstimateSchema((prev) => ({
      ...prev,
      [target]: value,
    }));
  }

  function Showtoast(type, message) {
    setToast({
      toast: true,
      toastType: type,
      response: message,
    });
    clearToast();
  }

  async function saveDraft(load = true, signal) {
    let response;
    if (!isWorkOrder && !Object.keys(estimateSchema.lead).length)
      return Showtoast("danger", "Please select the lead");
    else if (isWorkOrder && !Object.keys(estimateSchema.sub_contractor).length)
      return Showtoast("danger", "Please select the Pro");
    else if (!estimateSchema.subject)
      return Showtoast("danger", "Please enter the subject");
    else if (!estimateSchema.due_date)
      return Showtoast("danger", "Please enter the due date");
    else if (!estimateSchema.items.length)
      return Showtoast("danger", "Please enter the items");
    // else if (isItemsNotPresent()) return;
    // else if (checkAnyTotalMissing()) return;
    load && setDraftLoad(true);
    let draft = estimateSchema;

    if (isWorkOrder) {
      delete draft.leads;
    } else delete draft.sub_contractor;
    draft.items.map((item) => {
      delete item.itemDescRef;
      delete item.itemRef;
    });
    draft.splitup.map((item) => {
      delete item.splitup_ref;
    });
    if (draft.splitup.length) {
      const totalSplitAmt = draft.splitup.reduce(
        (acc, obj) => acc + obj.amount.amount,
        0
      );
      let last_splitup = {
        splitup_id: draft.splitup[draft.splitup.length - 1].splitup_id,
        remainingCost: +(draft.itemsTotal.totalAmount - totalSplitAmt).toFixed(
          2
        ),
      };
      draft.last_splitup = { ...last_splitup };
    }
    if (estId) {
      delete draft.createdAt;
      if (isWorkOrder) {
        response = await ApiService.patchAPI(
          `contractor/workOrder/${estId}`,
          draft,
          signal
        );
      } else {
        response = await ApiService.patchAPI(
          `contractor/estimate/${estId}`,
          draft,
          signal
        );
      }
      if (response.success) {
        load && setDraftLoad(false);
        Showtoast("success", response.message);
        // addOrEdit();
      } else {
        Showtoast("danger", response.message);
      }
      setDraftLoad(false);
      setQtyChange(false);
    } else {
      if (isWorkOrder) {
        response = await ApiService.postAPI(
          `contractor/workOrder/${props.props?.match?.params?.project_id}`,
          draft
        );
      } else {
        response = await ApiService.postAPI("contractor/estimate", draft);
      }
      setDraftLoad(false);
      if (response.success) {
        setEstId(
          response.data[`${isWorkOrder ? "workOrder_id" : "estimate_id"}`]
        );
        // addOrEdit();
        setStatus("draft");
        setToast({
          toast: true,
          toastType: "success",
          response: response.message,
        });
        clearToast();
        isWorkOrder
          ? props.props.history.push({
              pathname: `/admin/projects/detail/workOrder/${props?.props?.match?.params?.project_id}`,
              search: `?workOrder_id=${response.data["workOrder_id"]}`,
            })
          : props.history?.push({
              pathname: "/admin/estimates/AddEstimates",
              search: `?estimate_id=${response.data["estimate_id"]}`,
            });
        setTimeout(() => {
          window.location.reload();
        }, 100);
      } else {
        setToast({
          toast: true,
          toastType: "danger",
          response: response.message,
        });
        clearToast();
      }
      setQtyChange(false);
    }
  }

  function isItemsNotPresent() {
    let error = false;
    const lastItem = estimateSchema.items[estimateSchema.items.length - 1];
    if (!lastItem.item_name) {
      error = true;
      const errorMessage = `Please enter a item name field.`;
      setToast({
        toast: true,
        toastType: "danger",
        response: errorMessage,
      });
      clearToast();
    } else if (!lastItem.quantity) {
      error = true;
      const errorMessage = `Please enter a  quantity field.`;
      setToast({
        toast: true,
        toastType: "danger",
        response: errorMessage,
      });
      clearToast();
    } else if (!(lastItem.labor || lastItem.material)) {
      error = true;
      const errorMessage = `Please enter a labor or material field.`;
      setToast({
        toast: true,
        toastType: "danger",
        response: errorMessage,
      });
      clearToast();
    }
    return error;
  }

  function errMsg(msg) {
    setToast({
      toast: true,
      toastType: "danger",
      response: msg,
    });
    clearToast();
  }

  function checkAnyTotalMissing() {
    const checkError = ["item_name", "quantity", "labor", "material"];
    const tempObj = {
      quantity: "quantity",
      item_name: "item name",
      labor: "labor",
      material: "material",
    };
    let error = false;
    estimateSchema.items.map((item) => {
      const missing = checkError.find((item) => {});
      if (!item.item_name) {
        error = true;
        const errorMessage = `Please enter a item name field.`;
        setToast({
          toast: true,
          toastType: "danger",
          response: errorMessage,
        });
        clearToast();
      } else if (!item.quantity) {
        error = true;
        const errorMessage = `Please enter a quantity field.`;
        setToast({
          toast: true,
          toastType: "danger",
          response: errorMessage,
        });
        clearToast();
      } else if (!(item.labor || item.material)) {
        error = true;
        const errorMessage = `Please enter a material or labor field.`;
        setToast({
          toast: true,
          toastType: "danger",
          response: errorMessage,
        });
        clearToast();
      }
    });
    return error;
  }

  function returnValue(type, value) {
    if (["item_name", "item_desc"].includes(type)) {
      return value;
    } else return +value || null;
  }

  function updateListValues(index, type, value, ref) {
    let val = value;
    let tax;
    let totalValue;
    if (
      estId &&
      (type === "quantity" || type === "labor" || type === "material")
    ) {
      setQtyChange(true);
    }
    if (["quantity", "labor", "material"].includes(type)) {
      if (value && !+value[0]) {
        let tempVal = value.replace(/^0+/, "");
        val = tempVal;
      }
    }
    const taxs = { ...estimateSchema.tax };
    const items = [...estimateSchema.items];
    const itemsTotal = { ...estimateSchema.itemsTotal };
    const splitUp = [...estimateSchema.splitup];
    const splitUpAmt = splitUp.reduce((acc, obj) => acc + obj.amount.amount, 0);
    if (mouseScroll) return;
    else {
      items[index] = {
        ...items[index],
        [type]: returnValue(type, val),
      };
      items[index].cost =
        items[index].labor + items[index].material + items[index].markup;
      items[index].amount =
        (items[index].labor + items[index].material + items[index].markup) *
        items[index].quantity;
      const currentTotal = items.reduce(
        (acc, cur) => acc + +cur.quantity * +cur.material + +cur.labor,
        0
      );
      itemsTotal.subTotalAmount = items.reduce(
        (acc, obj) => acc + obj.amount,
        0
      );
      itemsTotal.taxAmount =
        +itemsTotal.subTotalAmount * (+taxs.value / 100).toFixed(2);
      itemsTotal.totalAmount = itemsTotal.taxAmount + itemsTotal.subTotalAmount;
      setSplTlt(splitUpAmt.toFixed());
      if (estimateSchema.tax.type === "percentage") {
        tax = (currentTotal * (estimateSchema.tax.value / 100)).toFixed(2);
      } else {
        tax = estimateSchema.tax.value;
      }
      changeSchema("items", items);
      changeSchema("itemsTotal", itemsTotal);
    }
    if (["quantity", "labor", "material", "markup"].includes(type) && estId) {
      clearTimeout(out.current);
      out.current = setTimeout(() => {
        setChanged(true);
      }, 1000);
    }
  }

  function onchangeItemDescription(index, type, value) {
    setDescIndex({ index: index, type: type, value: value });
  }

  function checkSplitupValueHighOrLow() {
    estimateSchema.splitup.map((obj) => {});
  }

  function getContractorDetails() {
    const cont_details = JSON.parse(localStorage.getItem("contractor_details"));
    const { name, email, phone, address } = cont_details?.contractor;
    setContractorDetails({
      contractor_name: name,
      contractor_email: email,
      contractor_phone: phone,
      contractor_address: address?.line_1,
      contractor_state: address?.state?.state,
      contractor_city: address?.city?.city,
      contractor_zip: address?.zip,
    });
  }

  async function getLeadList() {
    let response = await ApiService.getAPI(`contractor/lead/list`);
    setleadLists(response.data.leads);
  }

  function calculateTotal() {
    let totalItemAmount = 0;
    estimateSchema.items.map((item) => {
      totalItemAmount += item.quantity * item.material + item.labor;
    });
    const totalTaxAmount =
      estimateSchema.tax.type === "percentage"
        ? totalItemAmount * (estimateSchema.tax.value / 100)
        : estimateSchema.tax.value;
    return roundOff(+totalItemAmount + +totalTaxAmount);
  }

  async function addOrEdit() {
    if (isWorkOrder !== null) {
      if (estId) {
        let response = await ApiService.getAPI(
          `contractor/${isWorkOrder ? "workOrder" : "estimate"}/${estId}`
        );
        setInitPageLoading(false);
        if (response.success) {
          const estimate =
            response.data[`${isWorkOrder ? "workOrder" : "estimate"}`];
          const { splitup } = response.data;
          estimate.items.map((item) => {
            item.itemDescRef = React.createRef();
            item.itemRef = React.createRef();
          });
          estimate?.lead || (estimate.lead = {});
          estimate?.sub_contractor || (estimate.sub_contractor = {});
          const tempObj = {};
          const tempArr = Object.keys(estimate.itemsVisibility);
          tempArr.forEach((item) => {
            tempObj[item] = {
              ...estimate.itemsVisibility[item],
              ...rowClasses[item],
            };
          });
          estimate.itemsVisibility = tempObj;
          setEstimateSchema({ ...estimate, splitup: splitup });
          setStatus(estimate.status);
          setInitial(true);
          changeSchema("terms", estimate.terms);
          splitup.forEach((obj, i) => {
            if (!obj.name) {
              obj.name = `Payment ${i + 1}`;
            }
          });
          changeSchema("splitup", splitup);
        }
      } else {
        setInitPageLoading(false);
      }
    }
  }

  function closeSplitUpModal(con) {
    const splitDetail = splitUpDetail
    setSplitUpDetail(null);
    setDeleteSplitUpModal(false);
    setSplitUpEdit(false);
    if (con) {
      const split_up = [...estimateSchema.splitup]
      const filteredSplitUps = split_up.filter((obj) => obj.splitup_id !== splitDetail.splitup_id) 
      changeSchema('splitup',filteredSplitUps)
    }
  }

  function isEmailInvalid() {
    const err = ccRecipients.map((email) => isInvalidEmail(email));
    return err.some((cond) => cond);
  }

  function getTaxValue(e) {
    changeSchema("tax", {
      ...estimateSchema.tax,
      value: e.target.value,
    });
  }

  function clearToast() {
    setTimeout(
      () => setToast({ toast: false, toastType: "", response: "" }),
      4000
    );
  }

  // function changeTaxValue(e, type) {
  //   const itemsTotal = estimateSchema.itemsTotal;
  //   setQtyChange(true);
  //   if (type === "percentage" && e.target.value > 100)
  //     return Showtoast("danger", "Percentage should be less than 100");
  //   else {
  //     changeSchema("tax", {
  //       ...estimateSchema.tax,
  //       value: e.target.value,
  //     });
  //     calculateTotal();
  //   }
  // }

  function changeTaxValue(e, type) {
    const value = e.target.value;
    const itemsTotal = estimateSchema.itemsTotal;
    setQtyChange(true);
    // setEdit(true);
    if (type === "percentage" && value > 100) {
      return Showtoast("danger", "Percentage should be less than 100");
    } else {
      setEstimateSchema((prev) => ({
        ...prev,
        tax: {
          ...prev.tax,
          value,
        },
        itemsTotal: {
          ...itemsTotal,
          taxAmount: itemsTotal.subTotalAmount * (+value / 100).toFixed(2),
          totalAmount:
            itemsTotal.subTotalAmount +
            itemsTotal.subTotalAmount * (+value / 100).toFixed(2),
        },
      }));
      estId && setChanged(true);
      // calculateTotal();
    }
  }

  function calculateSplitUpTotalAmount() {
    const { splitup } = estimateSchema;
    const splitUpAmt = splitup.reduce(
      (acc, cur) => acc + +cur.amount.amount,
      0
    );
    setSplitupAmount(splitUpAmt);
  }

  function deleteLocalSplitUp() {
    const { splitup, itemsTotal } = estimateSchema;
    splitup.splice(splitIndex, 1);
    const SplitRemAmt = splitup.reduce(
      (acc, obj) => acc + obj.amount.amount,
      0
    );
    setDeleteSplitUpModal(false);
    if (splitIndex.length) {
      splitup.forEach((obj, i) => {
        obj.name = `Payment ${NumToWords[i + 1]}`;
        obj.amount.remainingCost = obj.amount.totalCost - obj.amount.amount;
      });
      splitup[splitup.length - 1].amount.remainingCost =
        itemsTotal.totalAmount - SplitRemAmt;
    }
    changeSchema("splitup", splitup);
    setSplitIndex(null);
    calculateSplitUpTotalAmount();
    // const splitUpAmt = splitup.reduce((acc, cur) => acc + cur.amount.amount, 0);
    // setSplitupAmount(splitUpAmt);
    Showtoast("success", "Splitup deleted Successfully");
  }

  async function constuctSplitUp(data, edit) {
    let response;
    // estId && qtyChange && (await saveDraft());
    let tempData = { ...data };
    // if (edit) {
    //   tempData = {
    //     ...data,
    //     last_splitup: {
    //       splitup_id: lastSplitDetail.splitup_id,
    //       remainingCost: lastSplitDetail.amount.remainingCost.toFixed(),
    //     },
    //   };
    // }
    if (estId) {
      if (isWorkOrder) {
        response = await ApiService[edit ? "patchAPI" : "postAPI"](
          `contractor/workOrder/splitup/${
            edit ? splitUpDetail.splitup_id : estId
          }`,
          { ...tempData }
        );
      } else {
        response = await ApiService[edit ? "patchAPI" : "postAPI"](
          `contractor/paymentSplitup/${
            edit ? splitUpDetail.splitup_id : estId
          }`,
          { ...tempData }
        );
      }
      if (response.success) {
        Showtoast("success", response.message);
        setDisableAdd(false);
        setSchedulePaymentOpen(false);
        // if (edit) {
        //   await saveDraft();  //Patch Estimate
        // }
        !edit && addOrEdit();          //GET Estimate
        setIndex(null);
        setEdit(false);
      } else {
        Showtoast("danger", response.message);
        setDisableAdd(false);
        setIndex(null);
        setEdit(false);
      }
    }
  }

  function calculateTotalSplitUps() {
    let totalSplitUpAmount = 0;
    estimateSchema.splitup.map((item) => {
      totalSplitUpAmount += item.amount.amount;
    });
    setSplitupAmount(totalSplitUpAmount);
    // return totalSplitUpAmount;
  }

  function calculateRemaingAmount(index) {
    let remainingAmount = 0;
    let splitUps = [...estimateSchema.splitup];
    const filtered = splitUps.filter((obj, i) => index !== i);
    filtered.forEach((obj) => {
      remainingAmount += obj.splitup_remainingAmount;
    });
    return remainingAmount;
  }

  function checkTableFields(fields) {
    for (const field of fields) {
      let valueToCheck = field.value;

      if (typeof valueToCheck === "string" && !isNaN(valueToCheck)) {
        valueToCheck = Number(valueToCheck);
      }

      if (
        valueToCheck === null ||
        valueToCheck === undefined ||
        (typeof valueToCheck === "string" &&
          valueToCheck.trim().length === 0) ||
        (typeof valueToCheck !== "string" &&
          (isNaN(valueToCheck) || valueToCheck <= 0))
      ) {
        return field.message;
      }
    }
    return null;
  }

  async function onEditSplitUp(splitUp, i) {
    setSchedulePaymentOpen(true);
    setSplitUpEdit(true);
    setSplitUpDetail(splitUp);
    setSplitIndex(i);
  }

  function onTagChange(e) {
    if (["Enter", "Tab"].includes(e.key)) {
      e.preventDefault();
      let value = cc.trim();
      if (value.length) {
        ccRecipients.push(value);
        // this.setState({ ccRecipients: [...ccRecipients], cc: "" });
        setCcRecipients([...ccRecipients]);
        setCc("");
      }
    } else if (["Backspace"].includes(e.key) && !cc) {
      if (ccRecipients.length) {
        ccRecipients.pop();
        // this.setState({ ccRecipients: [...ccRecipients], cc: "" });
        setCcRecipients([...ccRecipients]);
        setCc("");
      }
    }
  }

  async function sendEstimate(download) {
    const intialTempContent = `
    <p>Hi ${
      isWorkOrder
        ? estimateSchema.sub_contractor.name
        : estimateSchema.lead?.name
    } </p>
    <br />
    <p>Good Day, Please click the below ${
      isWorkOrder ? "Work Order" : "Estimate"
    } link to view in detail. If you have any questions, please don't hesitate to reach out.</p>
    <br />
    <p><a href=${onGenerateLink(estId)}>${onGenerateLink(estId)}</a></p>
    <br />
    <p>Thank You,</p>
    <p>${contractorDetails.contractor_name}</p>
    `;
    setEmailContent(intialTempContent);
    setDefaultContent(false);
    // if (checkAnyTotalMissing()) return;
    if (download) {
      setDownloadLoad(true);
      const downloadApi = await downloadDocuments(
        estId,
        isWorkOrder ? "workOrder" : "estimate"
      );
      setDownloadLoad(false);
    } else {
      setEmailModal(true);
      setEmailSubject(estimateSchema.subject);
    }
  }

  async function sendEmail() {
    if (!emailSubject) return Showtoast("danger", "Please enter the subject");
    else if (!emailContent.replace(/(<([^>]+)>)/gi, ""))
      return Showtoast("danger", "Please enter the content");
    else if (ccRecipients.length > 0 && isEmailInvalid())
      return Showtoast("danger", "Please enter a valid email");
    setEmailLoadBtn(true);
    estimateSchema.items.map((item) => {
      delete item.itemDescRef;
      delete item.itemRef;
    });
    let data = {
      // lead: estimateSchema.lead,
      subject: estimateSchema.subject,
      due_date: estimateSchema.due_date,
      items: estimateSchema.items,
      splitups: estimateSchema.splitup,
      terms: estimateSchema.terms,
      tax: estimateSchema.tax,
      isMarkupView: estimateSchema.isMarkupView,
      email: {
        subject: emailSubject,
        ccRecipients: [...ccRecipients],
        content: emailContent,
      },
    };
    isWorkOrder
      ? (data.sub_contractor = estimateSchema.sub_contractor)
      : (data.lead = estimateSchema.lead);
    try {
      let response = await ApiService.patchAPI(
        `contractor/${isWorkOrder ? "workOrder" : "estimate"}/send/${estId}`,
        data
      );
      setEmailLoadBtn(false);
      if (response.success) {
        Showtoast("success", response.message);
        setTimeout(() => {
          isWorkOrder
            ? history.push({
                pathname: isFromWorkOrderList
                  ? `/admin/projects/detail/${props?.props?.match?.params?.project_id}/project_work_order_lists`
                  : `/admin/projects/detail/${props?.props?.match?.params?.project_id}`,
                state: { tab: 3 },
              })
            : history.push("/admin/estimates");
        }, 1500);
      } else {
        Showtoast("danger", response.message);
      }
    } catch (error) {
      Showtoast("danger", "An error occurred while processing the request.");
      setEmailLoadBtn(false);
    }
  }

  function findError(obj) {
    const errorKeys = ["name", "due_date", "value"];
    let error = false;
    let fieldMissing = null;
    errorKeys.some(function (item) {
      if (!obj[item]) {
        error = true;
        fieldMissing = item;
        return true;
      }
    });
    return { error, fieldMissing };
  }

  function nextSplitUpValue(isPercentage) {
    let splitUpValue;
    const tempArr = estimateSchema.splitup;
    splitUpValue = tempArr.reduce((acc, sum) => acc + sum.splitup_value, 0);
    return isPercentage
      ? 100 - splitUpValue
      : calculateTotalAmount() - splitUpValue;
  }

  function previousSplitValues() {
    const tempArr = estimateSchema.splitup;
    return tempArr.reduce((acc, obj) => acc + obj.amount.value, 0);
  }

  function addNewSpiltUp() {
    const currentDate = moment();
    let newSplitUps;
    const { totalAmount } = estimateSchema.itemsTotal;
    const splitUps = estimateSchema?.splitup || [];
    const tempArr = estimateSchema?.splitup || [];
    const totalSplitAmt = splitUps.reduce(
      (acc, obj) => acc + obj.amount.amount,
      0
    );
    if (splitUps.length) {
      setRemaining(totalSplitAmt.toFixed(2));
    } else {
      setRemaining(totalAmount.toFixed(2));
    }

    const createNewSplitUp = (name, dueDate, type, value, amount) => ({
      name,
      due_date: dueDate,
      amount: {
        amount: +amount,
        type,
        value: +value,
      },
      payment_term: "net 15",
      createdAt: new Date(),
    });

    const showError = (fieldMissing) => {
      const errMessage = {
        name: "splitup name",
        due_date: "splitup due date",
        value: "splitup value",
      };
      Showtoast("danger", `Please enter a ${errMessage[fieldMissing]}`);
    };

    const validateSplitUps = (splitUps) => {
      for (let item of splitUps) {
        const tempData = {
          name: item.name,
          due_date: item.due_date,
          value: item.amount.value,
        };
        const err = findError(tempData);
        if (err.error) {
          showError(err.fieldMissing);
          return false;
        }
      }
      return true;
    };

    if (splitUps.length) {
      if (!validateSplitUps(splitUps)) return;
      const previousSplitUp = splitUps[splitUps.length - 1];
      const value =
        previousSplitUp.amount.type === "percentage"
          ? 100 - (totalSplitAmt / totalAmount) * 100
          : totalAmount - totalSplitAmt;
      const newSplitUp = createNewSplitUp(
        `Payment ${NumToWords[splitUps.length + 1]}`,
        currentDate.clone().add(15, "days"),
        previousSplitUp.amount.type,
        +value.toFixed(2),
        previousSplitUp.amount.type === "percentage"
          ? +(totalAmount * (value / 100)).toFixed(2)
          : value.toFixed(2)
      );
      splitUps.push(newSplitUp);
      setUpdateSplitUpItem(false);
      changeSchema("splitup", splitUps);
    } else {
      const newSplitUp = createNewSplitUp(
        "Payment One",
        currentDate.clone().add(15, "days"),
        "percentage",
        (100 - previousSplitValues()).toFixed(2),
        +totalAmount.toFixed(2)
      );
      splitUps.push(newSplitUp);
      setSplTlt(totalSplitAmt);
      setUpdateSplitUpItem(false);
      changeSchema("splitup", splitUps);
    }
    calculateSplitUpTotalAmount();
    constuctSplitUp(splitUps[splitUps.length - 1], false);
  }

  async function updateSplitUpWhenItemChanged() {
    setChanged(false);
    await saveDraft();
  }

  function calculateSubTotal(items) {
    return items.reduce((acc, obj) => acc + obj.amount, 0);
  }

  function calculateSplitUpAmount(totalAmt, value, ispercentage) {
    const totalAmount = totalAmt;
    return ispercentage === "percentage"
      ? +((+value / 100) * totalAmount).toFixed(2)
      : +value;
  }

  async function updateTotalSPlitUp() {
    const totalAmt = estimateSchema.itemsTotal.totalAmount;
    const splitups = [...estimateSchema.splitup];
    splitups.map((obj, i) => {
      if (obj.amount.type === "percentage") {
        obj.amount.amount = totalAmt * (+obj.amount.value / 100);
      } else {
        obj.amount.amount = obj.amount.value;
      }
    });
    changeSchema("splitup", splitups);
  }

  async function updateSplitUp({ value, index, type }) {
    const totalAmt = estimateSchema.itemsTotal.totalAmount;
    const splitUps = [...estimateSchema.splitup];
    const tltSplAmt = splitUps.reduce((acc, obj) => acc + obj.amount.amount, 0);
    if (+value && type === "percentage" && value > 100) {
      // Showtoast("danger", "Percentage should not be greater than 100");
      return;
    }
    if (+value && type === "dollar" && value > totalAmt) {
      // Showtoast("danger", `Please enter the dollar less than or equal to ${totalAmt}`);
      return;
    }
    setEdit(true);
    const individualSplitUp = (value, type) => ({
      amount: type === "percentage" ? +(totalAmt * (value / 100)) : +value,
      type: type,
      value: +value,
    });

    splitUps[index].amount = individualSplitUp(value, type);

    const slicedSplitUp = [...splitUps];
    slicedSplitUp.splice(index, 1);
    const totalSplitAmt = slicedSplitUp.reduce(
      (acc, obj) => acc + obj.amount.amount,
      0
    );
    const remainingAmount = +(totalAmt - totalSplitAmt).toFixed(2);
    if (remainingAmount < 0) {
      return;
    } else {
      setEstimateSchema((prev) => ({
        ...prev,
        splitup: splitUps,
      }));
      setIndex(index);
      setSplTlt(tltSplAmt.toFixed(2));
      setRemaining(remainingAmount);
    }
  }

  function calculateDueDate(i) {
    return estimateSchema.splitup[i].due_date;
  }

  function calculateTotalWithTax(data) {
    const items = data.items;
    // return items.reduce((acc,obj) => {acc+obj},0)
  }

  function currentBreadCrumbs() {
    let currentBreadCrumb;
    if (estId) {
      estId
        ? (currentBreadCrumb = estId)
        : (currentBreadCrumb = "New Estimate");
    } else currentBreadCrumb = "New Estimate";
    if (isWorkOrder) {
      estId
        ? (currentBreadCrumb = estId)
        : (currentBreadCrumb = "New Work Order");
    }
    return currentBreadCrumb;
  }

  function removeTag(index) {
    let tempTags = ccRecipients;
    tempTags.splice(index, 1);
    setCcRecipients([...ccRecipients]);
  }

  function handleEmailSubject(value) {
    setEmailSubject(value);
  }

  const getStringValue = (index) => {
    let splitup = estimateSchema.splitup;
    splitup[index].name = `Payment ${index + 1}`;
    changeSchema("splitup", splitup);
    // or any logic that returns a string
  };

  function setPaymentTerms(e, i) {
    const currentDate = moment();
    const tempArr = [...estimateSchema.splitup];
    const updatedItem = { ...tempArr[i] };
    switch (e) {
      case "net 15":
        updatedItem.due_date = currentDate.add(15, "days");
        break;
      case "net 30":
        updatedItem.due_date = currentDate.add(30, "days");
        break;
      case "net 60":
        updatedItem.due_date = currentDate.add(60, "days");
        break;
      case "custom":
        updatedItem.due_date = null;
    }
    updatedItem.payment_term = e;
    tempArr[i] = updatedItem;
    setEstimateSchema((prev) => ({
      ...prev,
      splitup: tempArr,
    }));
    setEdit(true);
    setIndex(i);
  }

  const minDate = (current) => {
    return current && current < moment().add(-1, "days");
  };

  function handleContentChange(value) {
    setEmailContent(value);
    setDefaultContent(true);
  }

  async function onSchedulePayment() {
    const newSplitups = estimateSchema.splitup;
    newSplitups.push();
  }

  async function handlePreview() {
    setPreviewBtn(true);
    await saveDraft(false);
    history.push({
      pathname: "/admin/estimates/preview",
      state: {
        lead: isWorkOrder ? estimateSchema.sub_contractor : estimateSchema.lead,
        subject: estimateSchema.subject,
        due_date: estimateSchema.due_date,
        items: estimateSchema.items,
        splitups: estimateSchema.splitup,
        terms: estimateSchema.terms,
        isMarkupView: estimateSchema.isMarkupView,
        tax: {
          type: estimateSchema.tax.type,
          value: estimateSchema.tax.value,
        },
        estimate_id: estimateSchema.estimate_id || estId,
        // workOrder_id:estimateSchema.worK
        status: status,
        isWorkOrder: isWorkOrder,
        projectId: isWorkOrder ? props?.props?.match?.params?.project_id : "",
      },
      search: isWorkOrder ? `?workOrder_id=${estId}` : `?estimate_id=${estId}`,
    });
  }

  function deleteSplitUps(splitup, i) {
    const newsplitUp = [...estimateSchema.splitup];
    newsplitUp.splice(i, 1);
    setDeleteSplitUpModal(true);
    setSplitUpDetail(splitup);
    setSplitIndex(i);
    setLastSplitDetail(newsplitUp[newsplitUp.length - 1]);
  }

  function updateDesc(data) {
    setTempItemDesc(data);
    onchangeItemDescription(
      descIndex.index,
      descIndex.type,
      data,
      "itemDescRef"
    );
  }

  function resetContent() {
    setEmailContent(`
    <p>Hi ${
      isWorkOrder
        ? estimateSchema.sub_contractor.name
        : estimateSchema.lead?.name
    } </p>
    <br />
    <p>Good Day, Please click the below ${
      isWorkOrder ? "Work Order" : "Estimate"
    } link to view in detail. If you have any questions, please don't hesitate to reach out.</p>
    <br />
    <p><a href=${onGenerateLink(estId)}>${onGenerateLink(estId)}</a></p>
    <br />
    <p>Thank You,</p>
    <p>${contractorDetails.contractor_name}</p>
    `);
    setDefaultContent(false);
  }

  function onTagAreaClick() {
    myInputElementRef.current.focus();
  }

  async function leadUpdate(data) {
    setEstimateSchema((prevState) => ({
      ...prevState,
      lead: data,
    }));
    if (estId) {
      await ApiService.patchAPI(`contractor/estimate/${estId}`, { lead: data });
    }
  }

  async function onClosePaymentSchedule(val) {
    setSchedulePaymentOpen(false);
    setSplitUpDetail(null);
  }

  function removeDescription(value, i) {
    const tempSchema = [...estimateSchema.items];
    tempSchema[i].item_desc = null;
    changeSchema("items", tempSchema);
  }

  function listChange(index, type) {
    const { items, tax, itemsTotal } = estimateSchema;
    const createNewItem = () => ({
      amount: 0,
      item_name: "",
      item_desc: "",
      quantity: 1,
      labor: 0,
      material: 0,
      markup: 0,
      itemDescRef: React.createRef(),
      itemRef: React.createRef(),
    });

    const updateTotals = (items) => {
      const totalAmt = items.reduce((acc, obj) => acc + obj.amount, 0);
      const taxAmt =
        tax.type === "percentage" ? totalAmt * (tax.value / 100) : tax.value;
      itemsTotal.totalAmount = totalAmt + taxAmt;
      itemsTotal.subTotalAmount = totalAmt;
      itemsTotal.taxAmount = taxAmt;
      changeSchema("itemsTotal", itemsTotal);
    };

    if (type === "add") {
      // if (checkAnyTotalMissing() || isItemsNotPresent()) return;
      const newList = [...items, createNewItem()];
      changeSchema("items", newList);
    } else if (type === "remove") {
      if (items.length > 1) {
        const list = [...items];
        list.splice(index, 1);
        changeSchema("items", [...list]);
        updateTotals(list);
        estId && setChanged(true);
      } else if (items.length === 1) {
        changeSchema("items", [
          {
            amount: 0,
            item_name: "",
            item_desc: "",
            quantity: 1,
            labor: 0,
            material: 0,
            markup: 0,
          },
        ]);
      }
    }
  }

  function updateSplitUpName(content, index) {
    const tempArr = [...estimateSchema.splitup];
    const updatedItem = { ...tempArr[index], name: content.target.value };
    tempArr[index] = updatedItem;
    setEstimateSchema((prev) => ({
      ...prev,
      splitup: tempArr,
    }));
    setEdit(true);
    setIndex(index);
  }

  function sendDescription() {
    const tempDesc = tempItemDesc
      ? tempItemDesc.replace(/(<([^>]+)>)/gi, "")
      : "";
    const items = [...estimateSchema.items];
    items[descIndex.index][descIndex.type] = descIndex.value;
    changeSchema("items", items);
    setDescriptionModal(false);
    setTempItemDesc(null);
  }

  function setDescription(index, type, value) {
    setTempItemDesc(value.item_desc);
    setDescIndex({ index: index, type: type, value: value.item_desc });
    setDescriptionModal(true);
  }

  function onClose(data) {
    setEditClient(false);
    setClientId("");
    getLeadDetails(leadId);
    addOrEdit();
  }

  return initPageLoading ? (
    <div className="spin-loading">
      <CustomSpinner />
    </div>
  ) : (
    <>
      <div style={{ backgroundColor: "#FAFAFA" }} fluid>
        {toast.toast && (
          <ToastList message={toast.response} type={toast.toastType} />
        )}
        <div style={{ padding: "20px", backgroundColor: "#fafafa" }}>
          <div className="align-items-center" style={{ paddingBottom: "20px" }}>
            <div xs="8" className="manage-con">
              <div>
                <Breadcrumb className="">
                  <BreadcrumbItem
                    onClick={() =>
                      history.push({
                        pathname:
                          isWorkOrder && isFromWorkOrderList
                            ? `/admin/projects/detail/${props?.props?.match?.params?.project_id}/project_work_order_lists`
                            : isWorkOrder && !isFromWorkOrderList
                            ? `/admin/projects/detail/${props?.props?.match?.params?.project_id}`
                            : `/admin/estimates`,
                        state: isWorkOrder ? { tab: 3 } : {},
                      })
                    }
                    className="bread-cursor"
                  >
                    {previousPage}
                  </BreadcrumbItem>
                  <BreadcrumbItem
                    style={{ color: "#72569c" }}
                    className="bread-cursor"
                  >
                    {currentBreadCrumbs()}
                  </BreadcrumbItem>
                </Breadcrumb>
              </div>
            </div>
          </div>
          <CustomListingContainer>
            <ListingHeaderLayout
              className="estimate_heading_font point"
              label={"General info"}
              image={
                toggleGeneralInfo ? (
                  <img
                    src={Arrow}
                    alt="up arrow"
                    style={{ transform: "rotate(90deg)" }}
                  />
                ) : (
                  <img
                    src={Arrow}
                    alt="up arrow"
                    style={{ transform: "rotate(270deg)" }}
                  />
                )
              }
              imageClick={() => {
                setToggleGeneralInfo(!toggleGeneralInfo);
              }}
            >
              <div className="sideView">
                {/* Download */}
                {["paid", "sent", "accepted", "rejected", "cancelled"].includes(
                  status
                ) && (
                  <div
                    onClick={() => sendEstimate(true)}
                    className="sideViewContainer"
                  >
                    {downloadLoad ? (
                      <Spin
                        style={{ color: "#2e1a47" }}
                        indicator={
                          <LoadingOutlined
                            className="custom_antd_spinner"
                            style={{
                              fontSize: 16,
                            }}
                          />
                        }
                      />
                    ) : (
                      <Tooltip title="Download Document">
                        <img src={DownlaodIcon} alt="linkIcon" />
                      </Tooltip>
                    )}
                  </div>
                )}

                {/* Link */}
                {["sent", "draft"].includes(status) && (
                  <div
                    onClick={() => onLinkClick(estId)}
                    className="sideViewContainer"
                  >
                    <Tooltip title="Copy Link">
                      <img src={LinkIcon} alt="linkIcon" />
                    </Tooltip>
                  </div>
                )}

                {/* Preview */}
                {[
                  "paid",
                  "draft",
                  "sent",
                  "accepted",
                  "rejected",
                  "cancelled",
                ].includes(status) && (
                  <Custom_Button
                    isLoading={previewBtn}
                    borderColor={"#2e1a47"}
                    btnLabel="preview"
                    click={handlePreview}
                  />
                )}

                {/* {draft} */}
                {(["draft", "sent"].includes(status) || !estId) && (
                  <Custom_Button
                    isPrimary
                    isLoading={draftLoad}
                    btnLabel={`${estId ? "save_changes" : "save_draft"} `}
                    click={() => saveDraft()}
                  />
                )}

                {/* send */}
                {["draft"].includes(status) && (
                  <Custom_Button
                    click={() => sendEstimate(false)}
                    isPrimary
                    btnLabel={`${
                      isWorkOrder ? "send_workOrder" : "send_estimate"
                    }`}
                  />
                )}
              </div>
            </ListingHeaderLayout>
            {toggleGeneralInfo && (
              <>
                <ListingBodyLayout
                  style={{ margin: "0 " }}
                  insideStyle={{
                    marginBottom: "0",
                    display: "flex",
                    justifyContent: "normal",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      borderRight: "0.5px solid #E1E8F0",
                      padding: "1.5rem",
                      width: "50%",
                    }}
                  >
                    <div className="bold_standard fw-500">
                      {contractorDetails.contractor_name}
                    </div>
                    <div className="font_standard fw-400">
                      {contractorDetails.contractor_email}
                    </div>
                    <div className="font_standard fw-400">
                      {contractorDetails.contractor_phone
                        ? getUSFormat(
                            contractorDetails.contractor_phone.national_number
                          )
                        : "-"}
                    </div>
                    <div className="font_standard fw-400">
                      {contractorDetails.contractor_address}
                    </div>
                    <div className="font_standard fw-400">
                      {contractorDetails.contractor_state}
                    </div>
                    <div className="font_standard fw-400">
                      {contractorDetails.contractor_city} -{" "}
                      {contractorDetails.contractor_zip}
                    </div>
                  </div>
                  {Object.keys(estimateSchema.lead).length === 0 &&
                  Object.keys(estimateSchema.sub_contractor).length === 0 ? (
                    <div className="flex_col" style={{ width: "50%" }}>
                      <Button
                        onClick={() => setOpenLead(true)}
                        size="sm"
                        className="button-wrapping"
                        style={{ backgroundColor: "#F7F8FA" }}
                      >
                        {isWorkOrder ? "Select Pro" : "Select Lead"}
                      </Button>
                      <div
                        className="font_standard"
                        style={{ color: "#838383" }}
                      >
                        {`No ${
                          isWorkOrder ? "pros" : "lead"
                        } selected yet. Select ${
                          isWorkOrder ? "pro" : "lead"
                        } to send ${isWorkOrder ? "work order" : "estimate"}`}
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "space-between",
                        width: "50%",
                        padding: "1.5rem",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "20px",
                          alignItems: "flex-start",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                            flexDirection: "column",
                          }}
                        >
                          <div className="bold_standard fw-500">
                            <div
                              className="align_center"
                              style={{ gap: "15px" }}
                            >
                              <div>
                                {isWorkOrder
                                  ? estimateSchema.sub_contractor.name
                                  : estimateSchema.lead.name}
                              </div>
                              <div>
                                {!isWorkOrder && (
                                  <img
                                    src={editIcon}
                                    className="point"
                                    style={{ width: "15px", height: "15px" }}
                                    onClick={() => {
                                      setEditClient(true);
                                      // getLeadDetails(
                                      //   estimateSchema?.lead?.lead_id ||
                                      //     estimateSchema?.lead?.id
                                      // );
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="font_standard fw-400">
                            {isWorkOrder
                              ? estimateSchema.sub_contractor.email
                              : estimateSchema.lead?.email}
                          </div>
                          <div className="font_standard fw-400">
                            {isWorkOrder
                              ? getUSFormat(
                                  estimateSchema?.sub_contractor?.phone
                                    ?.national_number
                                )
                              : getUSFormat(estimateSchema?.lead?.phone)}
                          </div>
                          <div className="font_standard fw-400">
                            {isWorkOrder
                              ? estimateSchema?.sub_contractor.address?.line_1
                              : estimateSchema?.lead.address}
                          </div>
                          <div className="font_standard fw-400">
                            {isWorkOrder &&
                            typeof estimateSchema?.sub_contractor?.address
                              ?.state === "object"
                              ? estimateSchema?.sub_contractor.address?.state
                                  ?.name
                              : estimateSchema?.sub_contractor?.address?.state}
                          </div>
                          <div className="font_standard fw-400">
                            {isWorkOrder &&
                              typeof estimateSchema?.sub_contractor?.address
                                ?.city === "object" &&
                              `${estimateSchema?.sub_contractor.address?.city?.name} - ${estimateSchema?.sub_contractor.address?.zip}`}
                          </div>
                        </div>
                      </div>
                      {["accepted", "rejected", "cancelled", "paid"].includes(
                        status
                      ) ? (
                        ""
                      ) : (
                        <div style={{ display: "flex" }}>
                          <div></div>
                          <div
                            style={{
                              cursor: "pointer",
                              lineHeight: "14px",
                              fontSize: "14px",
                              fontWeight: "500",
                              color: "#72569C",
                              marginRight: "15px",
                            }}
                            onClick={() => setOpenLead(true)}
                          >
                            Change
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </ListingBodyLayout>
                <div
                  style={{
                    borderTop: "1px solid #E1E8F0",
                    backgroundColor: "white",
                    width: "100%",
                    padding: "0.75rem 1rem",
                    borderRadius: "0px 0px 10px 10px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-end",
                      width: "50%",
                    }}
                  >
                    <textarea
                      disabled={[
                        "accepted",
                        "rejected",
                        "cancelled",
                        "paid",
                      ].includes(status)}
                      ref={subjectRef}
                      className="estimateClassName"
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "#212121",
                        backgroundColor: "transparent",
                        // minHeight: '50px', // Set a minimum height
                        overflowY: "hidden", // Hide vertical scrollbar
                      }}
                      value={estimateSchema.subject}
                      onChange={onSubjectChange}
                      placeholder="Enter your subject"
                      name="item"
                      maxLength={100}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "400",
                        fontSize: "15px",
                        color: "#757575",
                      }}
                    >
                      {" "}
                      Date:
                    </div>
                    <CustomSingleDatePicker
                      disabled={[
                        "accepted",
                        "rejected",
                        "cancelled",
                        "paid",
                      ].includes(status)}
                      className="without_borders"
                      value={estimateSchema.due_date}
                      change={changeDueDate}
                      setMinMaxDate={minDate}
                    />
                  </div>
                </div>
              </>
            )}
          </CustomListingContainer>
          <CustomListingContainer style={{ marginTop: "18px" }}>
            <ListingHeaderLayout
              className="estimate_heading_font point"
              label={"Items"}
              image={
                toggleItems ? (
                  <img
                    src={Arrow}
                    alt="up arrow"
                    style={{ transform: "rotate(90deg)" }}
                  />
                ) : (
                  <img
                    src={Arrow}
                    alt="up arrow"
                    style={{ transform: "rotate(270deg)" }}
                  />
                )
              }
              imageClick={() => {
                setToggleItems(!toggleItems);
              }}
            ></ListingHeaderLayout>
            {toggleItems && (
              <TableFormatter
                eyeFunction={eye}
                rows={estimateSchema.itemsVisibility}
              >
                <tbody className="estimate_table">
                  {estimateSchema?.items.map((list, i) => (
                    <tr key={i}>
                      <td
                        className="cursor-point capitalize bl_none pad_none"
                        style={{ height: "50px", padding: "0px 15px" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          {[
                            "accepted",
                            "rejected",
                            "cancelled",
                            "paid",
                          ].includes(status) ? (
                            <></>
                          ) : (
                            <img
                              style={{
                                cursor: "pointer",
                              }}
                              src={removeList}
                              onClick={() => listChange(i, "remove")}
                              alt="list"
                            />
                          )}
                          <div className="project_list_content">
                            <textarea
                              disabled={[
                                "accepted",
                                "rejected",
                                "cancelled",
                                "paid",
                              ].includes(status)}
                              className="estimateClassName"
                              ref={list.itemRef}
                              style={{
                                // height: this.state.itemTextAreaHeight,
                                lineHeight: "23px",
                                fontSize: "13px",
                                margin: "10px 0px",
                                color: "#212121",
                                overflowY: "hidden",
                                backgroundColor: "transparent",
                              }}
                              //   onInput={this.updateItems}
                              value={list.item_name}
                              onChange={(e) => {
                                updateListValues(
                                  i,
                                  "item_name",
                                  e.target.value,
                                  "itemRef"
                                );
                              }}
                              placeholder="Enter the item name"
                              name="item"
                              maxLength={60}
                            />
                          </div>
                        </div>
                      </td>
                      <td className="pad_none" style={{ padding: "0px 15px" }}>
                        <div
                          style={{ margin: "10px 0px", wordBreak: "break-all" }}
                          className="project_list_content"
                        >
                          {list.item_desc?.replace(/(<([^>]+)>)/gi, "") ? (
                            <div className="item_description">
                              <div
                                onClick={() =>
                                  setDescription(i, "item_desc", list)
                                }
                                className="estimateClassName"
                                style={{
                                  whiteSpace: "pre-wrap",
                                  maxHeight: "100px",
                                  overflowY: "scroll",
                                }}
                                dangerouslySetInnerHTML={{
                                  __html: list.item_desc,
                                }}
                              ></div>
                            </div>
                          ) : (
                            <div
                              onClick={() =>
                                setDescription(i, "item_desc", list)
                              }
                              style={{ color: "#8e8f99" }}
                            >
                              Enter the description
                            </div>
                          )}
                        </div>
                      </td>
                      <td className="pad_none">
                        <div className="project_list_content">
                          <Input
                            disabled={[
                              "accepted",
                              "rejected",
                              "cancelled",
                              "paid",
                            ].includes(status)}
                            className="crm-form-control table_font"
                            value={list.quantity || null}
                            name="quantity"
                            id="input-first-name"
                            style={{
                              color: "#000000",
                              textAlign: "center",
                              // paddingLeft: "1.5rem",
                            }}
                            onKeyDown={(e) => {
                              if (
                                e.key === "ArrowUp" ||
                                e.key === "ArrowDown"
                              ) {
                                e.preventDefault();
                              }
                            }}
                            onWheel={() => {
                              setMouseScroll(true);
                              setTimeout(() => {
                                setMouseScroll(false);
                              }, 1);
                            }}
                            onChange={(e) => {
                              updateListValues(i, "quantity", e.target.value);
                            }}
                            type="number"
                            maxLength="16"
                          />
                        </div>
                      </td>
                      <td className="pad_none">
                        <div
                          style={{
                            position: "relative",
                            display: "flex",
                            alignItems: "center",
                            padding: "15px 10px",
                          }}
                          className="project_list_content"
                        >
                          <span
                            style={{
                              color: "#000000",
                              fontWeight: "600",
                              fontSize: "13px",
                            }}
                          >
                            $
                          </span>

                          <div
                            style={{
                              maxWidth: "100px",
                              overflowX: "auto",
                              whiteSpace: "nowrap",
                              color: "#212121",
                              fontWeight: "600",
                              fontSize: "13px",
                              marginLeft: "5px",
                            }}
                          >
                            <Input
                              disabled={[
                                "accepted",
                                "rejected",
                                "cancelled",
                                "paid",
                              ].includes(status)}
                              className="crm-form-control table_font"
                              value={list.labor || null}
                              name="phone"
                              style={{
                                // width:'50%',
                                color: "#000000",
                                textAlign: "end",
                              }}
                              id="input-first-name"
                              onKeyDown={(e) => {
                                if (
                                  e.key === "ArrowUp" ||
                                  e.key === "ArrowDown"
                                ) {
                                  e.preventDefault();
                                }
                              }}
                              onWheel={() => {
                                setMouseScroll(true);
                                setTimeout(() => {
                                  setMouseScroll(false);
                                }, 1);
                              }}
                              onChange={(e) => {
                                updateListValues(i, "labor", e.target.value);
                              }}
                              type="number"
                              maxLength="16"
                            />
                          </div>
                        </div>
                      </td>
                      <td className="pad_none">
                        <div
                          style={{
                            position: "relative",
                            display: "flex",
                            alignItems: "center",
                            padding: "15px 10px",
                          }}
                          className="project_list_content"
                        >
                          <span
                            style={{
                              color: "#000000",
                              fontWeight: "600",
                              fontSize: "13px",
                            }}
                          >
                            $
                          </span>
                          <div
                            style={{
                              maxWidth: "100px",
                              overflowX: "auto",
                              whiteSpace: "nowrap",
                              color: "#212121",
                              fontWeight: "600",
                              fontSize: "13px",
                              marginLeft: "5px",
                            }}
                          >
                            <Input
                              disabled={[
                                "accepted",
                                "rejected",
                                "cancelled",
                                "paid",
                              ].includes(status)}
                              className="crm-form-control table_font"
                              value={list.material || null}
                              name="phone"
                              style={{
                                // width:'50%',
                                color: "#000000",
                                textAlign: "end",
                              }}
                              id="input-first-name"
                              onKeyDown={(e) => {
                                if (
                                  e.key === "ArrowUp" ||
                                  e.key === "ArrowDown"
                                ) {
                                  e.preventDefault();
                                }
                              }}
                              onWheel={() => {
                                setMouseScroll(true);
                                setTimeout(() => {
                                  setMouseScroll(false);
                                }, 100);
                              }}
                              onChange={(e) => {
                                updateListValues(i, "material", e.target.value);
                              }}
                              type="number"
                              maxLength="16"
                            />
                          </div>
                        </div>
                      </td>
                      <td className="pad_none">
                        <div
                          style={{
                            position: "relative",
                            display: "flex",
                            alignItems: "center",
                            padding: "15px 10px",
                          }}
                          className="project_list_content"
                        >
                          <span
                            style={{
                              color: "#000000",
                              fontWeight: "600",
                              fontSize: "13px",
                            }}
                          >
                            $
                          </span>
                          <div
                            style={{
                              maxWidth: "100px",
                              overflowX: "auto",
                              whiteSpace: "nowrap",
                              color: "#212121",
                              fontWeight: "600",
                              fontSize: "13px",
                              marginLeft: "5px",
                            }}
                          >
                            <Input
                              disabled={[
                                "accepted",
                                "rejected",
                                "cancelled",
                                "paid",
                              ].includes(status)}
                              className="crm-form-control table_font"
                              value={list.markup || null}
                              name="phone"
                              style={{
                                // width:'50%',
                                color: "#000000",
                                textAlign: "end",
                              }}
                              id="input-first-name"
                              onKeyDown={(e) => {
                                if (
                                  e.key === "ArrowUp" ||
                                  e.key === "ArrowDown"
                                ) {
                                  e.preventDefault();
                                }
                              }}
                              onWheel={() => {
                                setMouseScroll(true);
                                setTimeout(() => {
                                  setMouseScroll(false);
                                }, 100);
                              }}
                              onChange={(e) => {
                                updateListValues(i, "markup", e.target.value);
                              }}
                              type="number"
                              maxLength="16"
                            />
                          </div>
                        </div>
                      </td>
                      <td>
                        <div
                          className="project_list_content table_font"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            padding: "15px 10px",
                            color: "#000000",
                          }}
                        >
                          <div
                            style={{
                              maxWidth: "100px",
                              overflowX: "auto",
                              whiteSpace: "nowrap",
                              color: "#212121",
                              fontWeight: "600",
                              fontSize: "13px",
                            }}
                          >
                            {/* {appendCurrency(
                              roundOff(
                                list.markup + list.material + list.labor
                              ) || 0
                            )} */}
                            {NumberWithCurrency(
                              list.markup + list.material + list.labor
                            )}
                          </div>
                        </div>
                      </td>
                      <td
                        className="pad_none"
                        style={{ backgroundColor: "#F7F8FA" }}
                      >
                        <div
                          className="project_list_content table_font"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            padding: "15px 10px",
                            color: "#000000",
                          }}
                        >
                          <div
                            style={{
                              maxWidth: "100px",
                              overflowX: "auto",
                              whiteSpace: "nowrap",
                              color: "#212121",
                              fontWeight: "600",
                              fontSize: "13px",
                            }}
                          >
                            {/* {appendCurrency(
                              roundOff(
                                (list.material + list.markup + list.labor) *
                                  list.quantity
                              ).toFixed(2) || 0
                            )} */}
                            {NumberWithCurrency(
                              (
                                (list.material + list.markup + list.labor) *
                                list.quantity
                              ).toFixed(2) || 0
                            )}
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td className="bl_none pad_none" style={{ height: "50px" }}>
                      {!["accepted", "rejected", "cancelled", "paid"].includes(
                        status
                      ) && (
                        <img
                          style={{ marginLeft: "15px", cursor: "pointer" }}
                          src={addList}
                          onClick={() => listChange(2, "add")}
                          alt="add list"
                        />
                      )}
                    </td>
                    <td className="bl_none pad_none"></td>
                    <td className="pad_none"></td>
                    <td className="pad_none"></td>
                    <td className="pad_none"></td>
                    <td className="pad_none"></td>
                    <td className="pad_none"></td>
                    <td
                      className="pad_none"
                      style={{ backgroundColor: "#F7F8FA" }}
                    ></td>
                  </tr>
                  <tr>
                    <td
                      className="pad_none"
                      style={{ border: "1px solid white", height: "53px" }}
                    ></td>
                    <td className="pad_none" style={{ border: "none" }}></td>
                    <td className="pad_none" style={{ border: "none" }}></td>
                    <td className="pad_none" style={{ border: "none" }}></td>
                    <td className="pad_none" style={{ border: "none" }}></td>
                    <td
                      className="pad_none"
                      style={{
                        border: "none",
                        borderLeft: "1px solid #f0f0f0",
                        borderBottom: "1px solid #f5f5f5",
                        textAlign: "left",
                        fontSize: "13px",
                        fontWeight: "600",
                        color: "#6A7785",
                        paddingLeft: "2rem",
                      }}
                    >
                      Subtotal
                    </td>
                    <td
                      className="pad_none"
                      style={{
                        border: "none",
                        borderBottom: "1px solid #f5f5f5",
                      }}
                    ></td>
                    <td
                      className="pad_none"
                      style={{
                        borderBottom: "1px solid #f5f5f5",
                        backgroundColor: "#F7F8FA",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        height: "53px",
                        color: "#000000",
                        fontSize: "13px",
                        fontWeight: "500",
                        color: "#212121",
                        padding: "15px 10px",
                      }}
                    >
                      <div
                        style={{
                          maxWidth: "100px",
                          overflowX: "auto",
                          whiteSpace: "nowrap",
                          color: "#212121",
                          fontWeight: "600",
                          fontSize: "13px",
                        }}
                      >
                        {/* {appendCurrency(
                          calculateSubTotal(estimateSchema.items).toFixed(2)
                        )} */}
                        {NumberWithCurrency(
                          calculateSubTotal(estimateSchema.items).toFixed(2)
                        )}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: "none" }} className="pad_none"></td>
                    <td style={{ border: "none" }} className="pad_none"></td>
                    <td style={{ border: "none" }} className="pad_none"></td>
                    <td style={{ border: "none" }} className="pad_none"></td>
                    <td style={{ border: "none" }} className="pad_none"></td>
                    <td
                      className="pad_none"
                      style={{
                        border: "none",
                        borderLeft: "1px solid #f0f0f0",
                        borderBottom: "1px solid #f5f5f5",
                        paddingLeft: "2rem",
                        fontSize: "13px",
                        fontWeight: "600",
                        color: "#6A7785",
                      }}
                    >
                      {" "}
                      Tax
                    </td>
                    <td
                      className="pad_none"
                      style={{
                        border: "none",
                        borderBottom: "1px solid #f5f5f5",
                        padding: "0.5rem",
                      }}
                    >
                      <div className=" percentage_select ml-10 ">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div>
                            <Input
                              disabled={[
                                "accepted",
                                "rejected",
                                "cancelled",
                                "paid",
                              ].includes(status)}
                              className="percentageInputbar"
                              style={{
                                backgroundColor: "#F7F8FA",
                                borderRadius: "5px 0px 0px 5px",
                                borderRight: "none",
                              }}
                              value={estimateSchema.tax.value}
                              name="lead"
                              id="email"
                              type="number"
                              onChange={(e) => {
                                changeTaxValue(e, estimateSchema.tax.type);
                              }}
                              placeholder="0"
                            />
                          </div>
                          <div className="percentageModule">
                            <UncontrolledDropdown
                              disabled={[
                                "accepted",
                                "rejected",
                                "cancelled",
                                "paid",
                              ].includes(status)}
                              className="percentage_select_div"
                              style={{
                                padding: "0",
                                border: "1px solid #E6E6E6",
                                cursor: `${
                                  ["accepted", "rejected", "paid"].includes(
                                    status
                                  )
                                    ? ""
                                    : "pointer"
                                }`,
                              }}
                              group
                            >
                              <DropdownToggle
                                style={{
                                  marginRight: "0px",
                                  padding: "10px",
                                  borderRadius: "0",
                                  backgroundColor: "#F7F8FA",
                                  borderRadius: "0px 4px 4px 0px",
                                }}
                                className="drop_down_container"
                              >
                                <Media
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "10px",
                                  }}
                                  className="capitalize"
                                >
                                  {estimateSchema.tax.type === "percentage"
                                    ? "%"
                                    : "$"}
                                  <i className="fas fa-angle-down"></i>
                                </Media>
                              </DropdownToggle>
                              <DropdownMenu
                                style={{
                                  minWidth: "3rem",
                                  padding: "0",
                                  textAlign: "center",
                                  backgroundColor: "#F7F8FA",
                                  height: "38px",
                                }}
                              >
                                {estimateSchema.tax.type === "percentage" ? (
                                  <DropdownItem
                                    className="drop_estimate"
                                    onClick={() => changeTaxType("fixed")}
                                  >
                                    $
                                  </DropdownItem>
                                ) : (
                                  <DropdownItem
                                    className="drop_estimate"
                                    onClick={() => {
                                      changeTaxType("percentage");
                                    }}
                                  >
                                    %
                                  </DropdownItem>
                                )}
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td
                      className="pad_none"
                      style={{
                        borderBottom: "1px solid #f5f5f5",
                        backgroundColor: "#F7F8FA",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        height: "60px",
                        fontSize: "13px",
                        fontWeight: "500",
                        color: "#212121",
                        padding: "15px 10px",
                      }}
                    >
                      <div
                        style={{
                          maxWidth: "100px",
                          overflowX: "auto",
                          whiteSpace: "nowrap",
                          color: "#212121",
                          fontWeight: "600",
                          fontSize: "13px",
                        }}
                      >
                        {estimateSchema.tax.type === "percentage"
                          ? NumberWithCurrency(
                              (
                                calculateSubTotal(estimateSchema.items) *
                                  (estimateSchema.tax.value / 100) || 0
                              ).toFixed(2)
                            )
                          : estimateSchema.tax.type === "fixed"
                          ? NumberWithCurrency(
                              roundOff(+estimateSchema.tax.value) || 0
                            )
                          : "0"}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{ border: "none", paddingLeft: "2rem" }}
                      className="pad_none"
                    ></td>
                    <td
                      style={{ border: "none", paddingLeft: "2rem" }}
                      className="pad_none"
                    ></td>
                    <td style={{ border: "none" }} className="pad_none"></td>
                    <td style={{ border: "none" }} className="pad_none"></td>
                    <td
                      className="pad_none"
                      style={{ border: "none", borderLeft: "none" }}
                    ></td>
                    <td
                      style={{
                        border: "none",
                        // borderLeft: "1px solid #f0f0f0",
                        color: "#212121",
                        fontWeight: "500",
                        fontSize: "16px",
                        height: "46px",
                        paddingLeft: "2rem",
                        fontSize: "13px",
                        fontWeight: "600",
                      }}
                    >
                      {" "}
                      Total
                    </td>
                    <td style={{ border: "none" }}></td>
                    <td
                      style={{
                        backgroundColor: "#F7F8FA",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        height: "46px",
                        padding: "15px 10px",
                      }}
                    >
                      <div
                        style={{
                          maxWidth: "100px",
                          overflowX: "auto",
                          whiteSpace: "nowrap",
                          color: "#212121",
                          fontWeight: "600",
                          fontSize: "13px",
                        }}
                      >
                        {/* {appendCurrency(
                          estimateSchema?.itemsTotal?.totalAmount
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        )} */}
                        {NumberWithCurrency(
                          estimateSchema?.itemsTotal?.totalAmount.toFixed(2)
                        )}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </TableFormatter>
            )}
          </CustomListingContainer>
          <CustomListingContainer style={{ marginTop: "18px" }}>
            <ListingHeaderLayout
              className="estimate_heading_font point"
              buttonStyle={{ padding: "6px" }}
              label={"payments"}
              image={
                togglePayments ? (
                  <img
                    src={Arrow}
                    alt="up arrow"
                    style={{ transform: "rotate(90deg)" }}
                  />
                ) : (
                  <img
                    src={Arrow}
                    alt="up arrow"
                    style={{ transform: "rotate(270deg)" }}
                  />
                )
              }
              imageClick={() => {
                setTogglePayments(!togglePayments);
              }}
              // primary_buttonLabel={
              //   !["rejected", "cancelled", "paid"].includes(status) &&
              //   splitupAmount < calculateTotal() &&
              //   estimateSchema.newSplitUps.length
              //     ? "add new splitup"
              //     : ""
              // }
              primary_buttonLabel={
                +splitupAmount.toFixed(2) <
                estimateSchema.itemsTotal.totalAmount.toFixed(2)
                  ? "add new splitup"
                  : ""
              }
              btn_loading={disableAdd}
              isPrimary={false}
              click={addNewSpiltUp}
            ></ListingHeaderLayout>
            {togglePayments &&
              (estimateSchema.splitup && estimateSchema.splitup.length ? (
                <Row>
                  <div className="col new-table">
                    <Card className="shadow card_with_crm">
                      <Table
                        className="align-items-center table-flush"
                        responsive
                      >
                        <thead className="thead-light">
                          <tr className="split_up ">
                            <th
                              id="splitup_table_header"
                              style={{
                                width: "20%",
                                borderColor: "#f5f5f5",
                                padding: "0.7rem",
                              }}
                              scope="col"
                              className="crm splitup_table_header"
                            >
                              Name
                            </th>
                            <th
                              id="splitup_table_header"
                              style={{
                                width: "20%",
                                borderColor: "#f5f5f5",
                                paddingLeft: "0",
                              }}
                              scope="col splitup_table_header"
                            >
                              Value
                            </th>
                            <th
                              id="splitup_table_header"
                              style={{
                                width: "20%",
                                borderColor: "#f5f5f5",
                                paddingLeft: "0",
                              }}
                              scope="col splitup_table_header"
                            >
                              Amount
                            </th>
                            <th
                              id="splitup_table_header"
                              style={{
                                width: "20%",
                                borderColor: "#f5f5f5",
                                padding: "0.7rem",
                              }}
                              scope="col"
                              className="crm splitup_table_header"
                            >
                              Payment Terms
                            </th>
                            <th
                              id="splitup_table_header"
                              style={{
                                width: "20%",
                                borderColor: "#f5f5f5",
                                paddingLeft: "0",
                              }}
                              scope="col splitup_table_header"
                            >
                              Due on
                            </th>

                            <th
                              id="splitup_table_header"
                              style={{
                                width: "10%",
                                borderColor: "#f5f5f5",
                                paddingLeft: "0",
                              }}
                              scope="col splitup_table_header"
                            >
                              Status
                            </th>
                            <th
                              id="splitup_table_header"
                              style={{
                                width: "60%",
                                cursor: "pointer",
                                fontWeight: "500",
                                color: "#338180",
                                borderColor: "#f5f5f5",
                              }}
                              scope="col"
                            ></th>
                          </tr>
                        </thead>
                        {estimateSchema?.splitup &&
                        estimateSchema?.splitup.length !== 0 ? (
                          <tbody>
                            {estimateSchema.splitup.map((splitUp, i) => (
                              <tr key={i}>
                                {/* Name */}
                                <td
                                  className="cursor-point capitalize "
                                  style={{ paddingLeft: "1.5rem" }}
                                >
                                  <div className="splitup_cell word-break">
                                    {/* {splitUp.name} */}
                                    <input
                                      disabled={[
                                        "accepted",
                                        "rejected",
                                        "cancelled",
                                        "paid",
                                      ].includes(status)}
                                      className="estimateClassName"
                                      ref={splitUp.splitup_ref}
                                      style={{
                                        // height: this.state.itemTextAreaHeight,
                                        lineHeight: "23px",
                                        fontSize: "13px",
                                        margin: "10px 0px",
                                        color: "#212121",
                                        overflowY: "hidden",
                                        backgroundColor: "transparent",
                                      }}
                                      //   onInput={this.updateItems}
                                      value={splitUp.name}
                                      onChange={(e) => {
                                        updateSplitUpName(e, i);
                                      }}
                                      placeholder="Enter the splitup name"
                                      name="item"
                                      maxLength={60}
                                    />
                                  </div>
                                </td>
                                {/* Value */}
                                <td className="ptb20 pdl-2">
                                  {estimateSchema &&
                                    estimateSchema.splitup.length && (
                                      <div className="estimateValue">
                                        <PreTabInputField
                                          splitUp={estimateSchema.splitup}
                                          isPercentage={splitUp.amount.type}
                                          value={splitUp.amount.value}
                                          onValueChange={updateSplitUp}
                                          index={i}
                                          remainingAmt={remaining}
                                          totalAmt={
                                            estimateSchema.itemsTotal
                                              .totalAmount
                                          }
                                          errMsg={errMsg}
                                        />
                                      </div>
                                    )}
                                </td>
                                {/*Amount*/}
                                <td className="ptb20 pdl-2">
                                  <div className="splitup_cell">
                                    {/* $
                                    {formatAmountWithCommas(
                                      roundOff(+splitUp?.amount.amount).toFixed(
                                        2
                                      )
                                    )} */}
                                    {NumberWithCurrency(
                                      +splitUp?.amount.amount
                                    )}
                                  </div>
                                </td>
                                {/* terms */}
                                <td className="cursor-point capitalize pdl-2">
                                  <CustomDropDown
                                    statusList={[
                                      "due on receipt",
                                      "net 15",
                                      "net 30",
                                      "net 60",
                                      "custom",
                                    ]}
                                    style={{ width: "80%" }}
                                    placeHolder="Select the payment terms"
                                    status={splitUp.payment_term}
                                    setStatus={(e) => setPaymentTerms(e, i)}
                                    outerDivClass="outerDivDropDown"
                                  />
                                </td>
                                {/* due on */}
                                <td className="ptb20 pdl-2">
                                  <div className="splitup_cell">
                                    <CustomSingleDatePicker
                                      disabled={[
                                        "accepted",
                                        "rejected",
                                        "cancelled",
                                        "paid",
                                      ].includes(status)}
                                      externalParentDateClass="paymentDate"
                                      className="without_borders"
                                      value={splitUp.due_date}
                                      change={(e) => changeSplitupDate(e, i)}
                                      setMinMaxDate={minDate}
                                    />
                                  </div>
                                </td>
                                {/*Status*/}
                                <td className="ptb20">
                                  <div
                                    className="project_list_status"
                                    style={{
                                      paddingLeft: "1.5rem",
                                      color: `${
                                        splitUp.status === "completed" ||
                                        splitUp.status === "approved" ||
                                        splitUp.status === "paid"
                                          ? "#07A84E"
                                          : splitUp.status === "pending"
                                          ? "#FF4949"
                                          : splitUp.status === "due"
                                          ? "#0070FD"
                                          : splitUp.status === "rejected"
                                          ? "#F70e0e"
                                          : "#FFCC3D"
                                      }`,
                                    }}
                                  >
                                    {remove_underscore_capitalize(
                                      splitUp.status || "draft"
                                    )}
                                  </div>
                                </td>
                                {!["paid"].includes(splitUp.status) && (
                                  <td>
                                    <div
                                      style={{
                                        visibility: [
                                          "completed",
                                          "rejected",
                                          "cancelled",
                                          "paid",
                                        ].includes(status)
                                          ? "hidden"
                                          : "",
                                      }}
                                    >
                                      <UncontrolledDropdown
                                        nav
                                        className="flexy-content position_u"
                                      >
                                        <DropdownToggle
                                          className="pr-0 nav-notice"
                                          nav
                                        >
                                          <Media className="align-items-center">
                                            <img
                                              src={showMore}
                                              alt="show more"
                                            />
                                          </Media>
                                        </DropdownToggle>
                                        <DropdownMenu
                                          className="dropdown-menu-arrow min-width-drop"
                                          right
                                        >
                                          {/* <DropdownItem
                                            onClick={() =>
                                              onEditSplitUp(splitUp, i)
                                            }
                                          >
                                            <span>Edit</span>
                                          </DropdownItem> */}
                                          <DropdownItem
                                            onClick={() => {
                                              deleteSplitUps(splitUp, i);
                                            }}
                                          >
                                            <span>Delete</span>
                                          </DropdownItem>
                                        </DropdownMenu>
                                      </UncontrolledDropdown>
                                    </div>
                                  </td>
                                )}
                              </tr>
                            ))}
                          </tbody>
                        ) : (
                          <tbody>
                            <tr>
                              <td className="no-data">No splitups Available</td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                          </tbody>
                        )}
                      </Table>
                    </Card>
                  </div>
                </Row>
              ) : (
                <div className="emptySplitUp">
                  <div class="first">No Payment Schedule</div>
                  <div class="second">
                    Create a schedule of payments for one-time line items
                    totalling
                  </div>
                </div>
              ))}
          </CustomListingContainer>
          <CustomListingContainer style={{ marginTop: "18px" }}>
            <ListingHeaderLayout
              className="estimate_heading_font point"
              header={"Terms and Conditions"}
              image={
                toggleTermsandCondition ? (
                  <img
                    src={Arrow}
                    alt="up arrow"
                    style={{ transform: "rotate(90deg)" }}
                  />
                ) : (
                  <img
                    src={Arrow}
                    alt="up arrow"
                    style={{ transform: "rotate(270deg)" }}
                  />
                )
              }
              imageClick={() =>
                setToggleTermsandCondition(!toggleTermsandCondition)
              }
            ></ListingHeaderLayout>
            {toggleTermsandCondition && (
              <CardBody>
                <div
                  className="crm_comments_input"
                  style={{ marginTop: "0px", padding: "0px" }}
                >
                  <div
                    className="ft_1 termsPopDescription"
                    style={{
                      width: "100%",
                    }}
                  >
                    <TextEditor
                      placeholder="Enter the terms and conditions"
                      updateDescription={onTermsChange}
                      desc={estimateSchema.terms}
                    />
                    {/* <textarea
                      disabled={[
                        "accepted",
                        "rejected",
                        "cancelled",
                        "paid",
                      ].includes(status)}
                      ref={termsRef}
                      className="estimateClassName"
                      style={{
                        fontSize: "13px",
                        color: "#212121",
                        // minHeight: '50px', // Set a minimum height
                        overflowY: "hidden", // Hide vertical scrollbar
                        backgroundColor: "transparent",
                        lineHeight: "23px",
                      }}
                      value={estimateSchema.terms}
                      placeholder={
                        ["accepted", "rejected"].includes(status)
                          ? ""
                          : "Type here..."
                      }
                      onChange={onTermsChange}
                    /> */}
                  </div>
                </div>
              </CardBody>
            )}
          </CustomListingContainer>
        </div>
      </div>
      {openLead && (
        <LeadSelect
          isOpen={openLead}
          leadClose={() => setOpenLead(false)}
          selectlead={onLeadSelect}
          isWorkOrder={isWorkOrder}
        />
      )}
      {emailModal && (
        <Modal
          isOpen={emailModal}
          centered="true"
          style={{ minWidth: "697px", padding: "2rem" }}
          toggle={() => {
            setEmailModal(false);
            setEmailLoadBtn(false);
          }}
          backdrop={false}
        >
          <div style={{ padding: "24px" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ color: "#333333" }}>
                {isWorkOrder ? "Work Order to " : "Estimate to "}
                <span style={{ color: "#7045B0" }}>
                  {isWorkOrder
                    ? estimateSchema.sub_contractor.name
                    : estimateSchema.lead?.name}
                </span>{" "}
              </div>
              <div>
                <img
                  width={20}
                  height={20}
                  onClick={() => {
                    setEmailModal(false);
                    setCcRecipients([]);
                  }}
                  src={CloseIcon}
                  alt="closeIcon"
                  style={{ cursor: "pointer" }}
                ></img>
              </div>
            </div>

            <div style={{ marginTop: "24px" }}>
              <label className="customModalLabel">To</label>
              <CustomInputFields
                inputValue={
                  isWorkOrder
                    ? estimateSchema.sub_contractor.name
                    : estimateSchema.lead?.name
                }
                type="text"
                disabled
                style={{ marginBottom: "20px" }}
              />

              <label className="customModalLabel">CC</label>
              <div
                onClick={onTagAreaClick}
                ref={myElementRef}
                className="drawer_desc"
                style={{
                  padding: "2%",
                  border: "1px solid #E6E6E6",
                  borderRadius: "10px",
                  marginBottom: "20px",
                }}
              >
                {ccRecipients?.map((string, i) => (
                  <div className="tagChip">
                    {string}
                    <span>
                      <img
                        style={{
                          width: "16px",
                          height: "20px",
                          marginLeft: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() => removeTag(i)}
                        src={CloseIcon}
                      ></img>
                    </span>
                  </div>
                ))}
                <input
                  style={{ backgroundColor: "transparent", width: "200px" }}
                  ref={myInputElementRef}
                  onKeyDown={(e) => onTagChange(e)}
                  onChange={(e) => setCc(e.target.value)}
                  className="tagInput"
                  type="text"
                  value={cc}
                ></input>
              </div>
              <label className="customModalLabel">Subject*</label>
              <CustomInputFields
                style={{ marginBottom: "20px" }}
                inputValue={emailSubject}
                type="text"
                change={handleEmailSubject}
                from="subjectModal"
                placeHolder="Enter the subject"
              />
              <label className="customModalLabel">Content*</label>
              <div className="ft_1 senEmail">
                <TextEditor
                  placeholder="Enter the content"
                  updateDescription={handleContentChange}
                  desc={emailContent}
                  additionalContent={
                    defaultContent ? "Use Default Content" : ""
                  }
                  resetContent={resetContent}
                />
              </div>
            </div>
            <div className="emailModalFooter">
              <Button_without_Border
                btnLabel="Cancel"
                click={() => {
                  setEmailModal(false);
                  setCcRecipients([]);
                }}
                style={{ marginRight: "10px" }}
              />
              <div>
                <Custom_Button
                  isPrimary
                  btnLabel="send"
                  isLoading={emailLoadBtn}
                  click={sendEmail}
                />
              </div>
            </div>
          </div>
        </Modal>
      )}
      {descriptionModal && (
        <Modal
          isOpen={descriptionModal}
          centered="true"
          style={{ minWidth: "50rem", padding: "2rem" }}
          toggle={() => {
            setDescriptionModal(false);
            // setEmailLoadBtn(false);
          }}
          backdrop={false}
        >
          <div style={{ padding: "24px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
              }}
            >
              <div>
                <span style={{ color: "#7045B0" }}>Description</span>
              </div>
              <div>
                <img
                  width={20}
                  height={20}
                  onClick={() => setDescriptionModal(false)}
                  src={CloseIcon}
                  alt="closeIcon"
                ></img>
              </div>
            </div>
            <div className="popUpDescription">
              <TextEditor
                placeholder="Enter the description"
                updateDescription={updateDesc}
                desc={tempItemDesc}
              />
            </div>
            <div className="emailModalFooter">
              <div>
                <Button_without_Border
                  btnLabel="Cancel"
                  click={() => {
                    setDescriptionModal(false);
                  }}
                  style={{ marginRight: "10px" }}
                />
                <Custom_Button
                  isPrimary
                  btnLabel="save"
                  isLoading={emailLoadBtn}
                  click={sendDescription}
                  toast={Showtoast}
                />
              </div>
            </div>
          </div>
        </Modal>
      )}
      <Card
        className={schedulePaymentOpen ? "exportSideBar" : "toggle-sidebar"}
      >
        {schedulePaymentOpen && (
          <>
            <AddSchedules
              props={props}
              data={estimateSchema}
              close={onClosePaymentSchedule}
              amount={calculateTotal()}
              toast={Showtoast}
              populateObj={constuctSplitUp}
              editSplitup={{
                edit: splitupEdit,
                detail: splitUpDetail || {},
              }}
              index={splitIndex}
              //   remainingValue={this.state.remainingValue}
              //   cancel={() => this.setState({ open: false, editSplitup: false })}
              splitup={estimateSchema.splitup}
            ></AddSchedules>
          </>
        )}
      </Card>
      <Card className={editClient ? "exportSideBar" : "toggle-sidebar"}>
        {editClient && leadDetails && (
          <AddLead
            type="lead"
            leadUpdate={leadUpdate}
            from="estimate"
            leadID={leadDetails}
            close={() => onClose()}
            props={props}
            toast={Showtoast}
          />
        )}
      </Card>
      {deleteSplitUpModal && (
        <DeleteModal
          modalState={deleteSplitUpModal}
          name={splitUpDetail?.name}
          type="Splitup"
          lastSplitUpObj={lastSplitDetail}
          //   props={this}
          toast={Showtoast}
          closeModal={closeSplitUpModal}
          deleteSplitModal={deleteLocalSplitUp}
          id={estId ? splitUpDetail?.splitup_id : ""}
        />
      )}
    </>
  );
}

export default AddEstimates;
