// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Col,
  Container,
  Spinner,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
} from "reactstrap";
import React, { Component } from "react";
import newLogoText from "../../assets/img/new Logo/pkLogoForWhiteBk.svg";
import ApiService from "constants/ApiService";
import {
  getUSFormat,
  isInvalidEmail,
  isInvalidName,
  replaceToNormalNumber,
  showSuccess,
  saveToken,
  showError,
  returnSearchObject,
} from "../../constants/utils.js";
import { isEmailLowercaseOnly } from "../../constants/utils.js";
import ToastList from "components/Toast/ToastList";
import backButton from "../../assets/img/brand/leftArrow.svg";
import { Custom_Button } from "components/Buttons/Button";
const initialState = {
  name: "",
  email: "",
  phone: "",
  password: "",
  conPassword: "",
  toast: false,
  response: "",
  toastType: "",
};
const noOfOtpField = 4;
const arrayOfNumber = new Array(+noOfOtpField).fill("1");
let otpObj = {};
arrayOfNumber.map((obj, i) => {
  otpObj[`otp_${i + 1}`] = "";
});

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      forgotLink: false,
      signUpLink: false,
      signInLink: true,
      check: true,
      pType: "password",
      nType: "password",
      isLoading: false,
      initialState,
      businessType: "individual",
      companyName: "",
      referenceId: "",
      isFilled: false,
      isNumber: false,
      isEmail: false,
      otp: {
        ...otpObj,
      },
      OtpValidateSec: "",
      min: 1,
      sec: 30,
      otpSent: false,
      countdown: 60,
    };
  }

  componentDidMount() {
    if (localStorage.getItem("loginDetails")) {
      let obj = localStorage.getItem("loginDetails");
      let sub_contractor = JSON.parse(obj);
      this.setState({
        signUpLink: true,
        signInLink: false,
        extPhone: sub_contractor.phone ? sub_contractor.phone : "",
        phone: sub_contractor.phone ? getUSFormat(sub_contractor.phone) : "",
        extEmail: sub_contractor.email ? sub_contractor.email : "",
        email: sub_contractor.email ? sub_contractor.email : "",
        referenceId: sub_contractor.id,
      });
    }
    if (this.props.location.search) {
      let arr = this.props.location.pathname.split("/");
      let obj = returnSearchObject(this.props.location.search);
      if (obj.email || obj.phone) {
        let localObj = {
          phone: obj.phone,
          email: obj.email,
          id: obj.invite_id,
        };
        localStorage.setItem("loginDetails", JSON.stringify(localObj));
        this.props.history.push("/login");
        window.location.reload();
      }
    }
  }

  startCountdown(e) {
    this.setState({ countdown: 60 });
    let { email } = this.state;
    const data = {
      phone: {
        country_code: 1,
        national_number: email,
      },
    };
    ApiService.postAPI("/auth/contractor/send_otp", data).then(
      (response) => { }
    );
    this.countdownInterval = setInterval(() => {
      if (this.state.countdown > 0) {
        this.state.countdown -= 1;
        this.setState({ countdown: this.state.countdown, otpSent: true });
      } else {
        this.setState({ otpSent: false });
        clearInterval(this.countdownInterval);
      }
    }, 1000);
  }

  resetState = () => {
    this.onBack();
    this.setState(initialState);
  };

  handleLogin = async () => {
    let { email, password, check } = this.state;
    if (email === "" || isInvalidEmail(email)) {
      showError(this, "Please enter valid email");
    } else if (password === "" || password === undefined) {
      showError(this, "Please enter password");
    } else if (check === false) {
      showError(this, "Please accept the terms and conditions");
    } else {
      this.setState({ isLoading: true });
      let data = {
        email: email,
        password: password,
      };
      let response = await ApiService.postAPI("auth/contractor/signin", data);
      this.setState({ isLoading: false });
      if (response.success) {
        showSuccess(this, response.message);
        saveToken(response);
        localStorage.setItem(
          "contractor_details",
          JSON.stringify(response.data)
        );
        localStorage.setItem("user", "Rental");
        localStorage.setItem(
          "sub_contractor",
          response.data.contractor.sub_contractor
        );
        setTimeout(() => window.location.reload(), 500);
        localStorage.removeItem("loginDetails");
      } else {
        if (response.data?.error) {
          setTimeout(
            () =>
              this.props.history.push({
                pathname: "/verify_email",
                email_id: email,
                signUp: "signIn",
              }),
            500
          );
        } else {
          showError(this, response.message);
        }
      }
    }
  };

  handleReset = async () => {
    this.setState({ isLoading: true });
    let { email } = this.state;
    if (email === "" || isInvalidEmail(email)) {
      showError(this, "Please enter valid email");
      this.setState({ isLoading: false });
    } else {
      let data = {
        email: email,
      };
      let response = await ApiService.postAPI(
        "auth/contractor/forgot_password",
        data
      );
      this.setState({ isLoading: false });
      if (response.success) {
        showSuccess(this, response.message);
        setTimeout(() => this.toggleSignType("login"), 1000);
      } else {
        showError(this, response.message);
      }
    }
  };

  handleKeypress = (e) => {
    let { signInLink, signUpLink, isFilled } = this.state;
    //it triggers by pressing the enter key
    if (signUpLink && e.key === "Enter") {
      this.handleSignUp();
    } else if (signInLink && !isFilled && e.key === "Enter") {
      this.handleNextStep(e);
    } else if (signInLink && isFilled && e.key === "Enter") {
      this.handleLogin();
    }
  };

  handleSignUp = async () => {
    let {
      name,
      email,
      phone,
      password,
      conPassword,
      check,
      businessType,
      companyName,
    } = this.state;

    if (
      businessType === "company" &&
      (companyName === "" || companyName === null || companyName === undefined)
    ) {
      showError(this, "Please enter valid company name");
    } else if (
      name === "" ||
      isInvalidName(name) ||
      (name && name.length < 3) ||
      name === null ||
      name === undefined
    ) {
      showError(this, "Please enter valid name");
    } else if (
      email === "" ||
      isInvalidEmail(email) ||
      isEmailLowercaseOnly(email)
    ) {
      showError(this, "Please enter valid email");
    } else if (password === "" || password === undefined) {
      showError(this, "Please enter valid  password");
    } else if (conPassword === "" || conPassword === undefined) {
      showError(this, "Please enter confirm password");
    } else if (password !== conPassword) {
      showError(this, "Password field does not match");
    } else if (phone === "" || phone.length < 16) {
      showError(this, "Please enter valid phone number");
    } else if (check === false) {
      showError(this, "Please accept the terms and conditions");
    } else {
      this.setState({ isLoading: true });
      let data = {
        type: businessType,
        business_name: companyName,
        name: name,
        email: email,
        password: password,
        confirm_password: conPassword,
        phone: {
          national_number: replaceToNormalNumber(phone),
          country_code: "1",
        },
      };
      if (this.state.referenceId) {
        data["invite_id"] = this.state.referenceId;
      }
      let response = await ApiService.postAPI("auth/contractor/signup", data);
      if (response.success) {
        showSuccess(this, response.message);
        this.setState({ isLoading: false });
        setTimeout(
          () =>
            this.props.history.push({
              pathname: "/verify_email",
              email_id: email,
              signUp: "signUp",
            }),
          2000
        );
        localStorage.removeItem("loginDetails");
      } else {
        this.setState({ isLoading: false });
        showError(this, response.message);
      }
    }
  };

  handleToggle = () => {
    let self = this;
    self.setState(
      { forgotLink: true, signInLink: false, signUpLink: false }
      // () => this.resetState()
    );
  };

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  checkIsNumber(number) {
    let numberRegex = /^\d+$/;
    if (numberRegex.test(number)) {
      return true;
    } else return false;
  }

  handleOnPhoneNumberChange = (evt) => {
    this.setState({ phone: getUSFormat(evt.target.value) });
  };

  toggleSignType = (key) => {
    if (key === "login") {
      localStorage.removeItem("loginDetails");
    }
    key === "register"
      ? this.setState({
        forgotLink: false,
        signUpLink: true,
        signInLink: false,
        pType: "password",
        nType: "password",
        isLoading: false,
      })
      : this.setState({
        forgotLink: false,
        signUpLink: false,
        signInLink: true,
        pType: "password",
        nType: "password",
        isLoading: false,
      });
    return this.resetState();
  };

  handleOnCheckChange = () => {
    this.setState({ check: !this.state.check });
  };

  toggleEyeIcon = (key) => {
    if (key === "pass") {
      this.setState({
        pType: this.state.pType === "password" ? "text" : "password",
      });
    } else if (key === "newPass") {
      this.setState({
        nType: this.state.nType === "password" ? "text" : "password",
      });
    }
  };

  handleRedirect = (key) => {
    window.open(
      key === "terms"
        ? "https://www.parknstays.com/#/terms_condtions"
        : "https://www.parknstays.com/#/privacy_policy",
      "_blank"
    );
  };
  // Toast Handler

  setBusinessType = (status) => {
    this.setState({ businessType: status });
  };

  handleNextStep(e) {
    e.preventDefault();
    let { email, isNumber } = this.state;
    let isnumber = this.checkIsNumber(email);
    this.isAccountExists(isnumber, email);
  }

  verify() {
    this.setState({ isLoading: true });
    const { email, otp } = this.state;
    const tempArray = [];
    for (let value in otp) {
      tempArray.push(otp[value]);
    }
    const data = {
      phone: {
        country_code: 1,
        national_number: email,
      },
      otp: tempArray.join(""),
    };
    ApiService.postAPI("auth/contractor/verify_otp", data).then((response) => {
      this.setState({ isLoading: false });
      if (!response.success) {
        showError(this, response.message);
      } else {
        showSuccess(this, response.message);
        saveToken(response);
        localStorage.setItem(
          "contractor_details",
          JSON.stringify(response.data)
        );
        localStorage.setItem("user", "Rental");
        localStorage.setItem(
          "sub_contractor",
          response.data.contractor.sub_contractor
        );
        setTimeout(() => window.location.reload(), 500);
        localStorage.removeItem("loginDetails");
      }
    });
  }

  otpInputClick(from, e) {
    this.setState((prev) => ({
      otp: {
        ...prev.otp,
        [from]: e.target.value,
      },
    }));
  }

  toogleToNextField(e) {
    let check;
    if (e.key === "Delete" || e.key === "Backspace") {
      const next = e.target.tabIndex - 2;
      if (next > -1) {
        e.target.form.elements[next].focus();
      }
    } else {
      const next = e.target.tabIndex;
      if (next < noOfOtpField) {
        e.target.form.elements[next].focus();
      }
    }
    if (e.key === "Enter") {
      const otp = this.state.otp;
      Object.entries(otp).forEach(([key, value]) => {
        check = Boolean(value);
        if (!value) showError(this, "Please fill all blanks");
      });
      check && this.verify();
    }
  }

  onBack() {
    this.setState({
      isNumber: false,
      isEmail: false,
      isFilled: false,
      email: "",
      countdown: 0,
    });
  }

  isAccountExists(isNumber, string) {
    this.setState({ isLoading: true });
    let error = false;
    const obj = {
      phone: {
        country_code: 1,
        national_number: "",
      },
      email: "",
    };
    if (isNumber) {
      obj.phone.national_number = string;
      delete obj.email;
    } else {
      obj["email"] = string;
      delete obj.phone;
    }
    if (string === undefined || string === null || string === "") {
      showError(this, "Please enter mobile number or mail id");
      this.setState({ isLoading: false });
    } else if (isNumber && string.length < 10) {
      showError(this, "Please enter valid number");
      this.setState({ isLoading: false });
    } else if (!isNumber && isInvalidEmail(string)) {
      showError(this, "Please enter valid mail");
      this.setState({ isLoading: false });
    } else {
      ApiService.postAPI("auth/contractor/check_profile_exists", obj).then(
        (res) => {
          this.setState({ isLoading: false });
          if (res.success && isNumber) {
            this.setState({ isNumber: isNumber });
            this.setState({ isFilled: true });
            this.startCountdown();
            error = false;
          } else if (res.success && !isNumber) {
            this.setState({ isEmail: true });
            this.setState({ isFilled: true });
            error = false;
          } else {
            showError(this, res.message);
            error = true;
          }
        }
      );
    }

    return error;
  }

  render() {
    let {
      name,
      password,
      conPassword,
      email,
      phone,
      forgotLink,
      signUpLink,
      signInLink,
      check,
      pType,
      nType,
      toast,
      response,
      toastType,
      isLoading,
      businessType,
      companyName,
      isFilled,
      isNumber,
      isEmail,
    } = this.state;
    return (
      <>
        <div className="main_content_login new-pro-design">
          <Container className="new-max-width">
            {toast && <ToastList message={response} type={toastType} />}
            <Row className="justify-content-center">
              <Col
                lg="5"
                md="7"
                className={
                  signInLink
                    ? "login-flexing"
                    : signUpLink
                      ? "login-signup"
                      : "forgot-trans"
                }
              >
                <Card className="bg-secondary  border-0 radius-log shadow_div">
                  {signUpLink ? (
                    <div className="signup-nav">
                      <img
                        className="logo-sign-image"
                        src={newLogoText}
                        alt="Parknstays Logo"
                      />
                      {signUpLink && (
                        <div className="toggle-link">
                          Already have an account ?{" "}
                          <span onClick={() => this.toggleSignType("login")}>
                            Login Here
                          </span>
                        </div>
                      )}
                    </div>
                  ) : (
                    <CardHeader
                      className={
                        signUpLink
                          ? "bg-transparent border-0 jus-center mt-20"
                          : isEmail || isNumber
                            ? "withBackBtn"
                            : "login_new_page"
                      }
                    >
                      {(isEmail || isNumber) && (
                        <img
                          width="5%"
                          className="cursor-point"
                          style={{ marginRight: "20px" }}
                          src={backButton}
                          alt="Parknstays Logo"
                          onClick={() => this.onBack()}
                        />
                      )}
                      <img
                        className="logo-image"
                        src={newLogoText}
                        alt="Parknstays Logo"
                      />
                    </CardHeader>
                  )}

                  <CardBody
                    className={
                      signInLink
                        ? "px-lg-5 py-lg-5 pad-log-bot pad-left-right-2"
                        : "px-lg-5 py-lg-5 pad-log-bot pad-left-right-2"
                    }
                  >
                    {!isNumber ? (
                      <div
                        className={
                          signUpLink
                            ? "text-left text-muted mb-0 flex"
                            : "text-left text-muted mb-0"
                        }
                      >
                        <small className="signin-small form-group">
                          {!isNumber &&
                            (signInLink
                              ? "Log In"
                              : signUpLink
                                ? "Sign Up"
                                : "Forgot Password ?")}
                        </small>
                      </div>
                    ) : (
                      <div
                        style={{ marginTop: "15px" }}
                        className="signin-small align_midd"
                      >
                        OTP VERIFICATION
                      </div>
                    )}

                    {forgotLink && (
                      <label className="term-label mt--1-2">
                        Enter your email and we'll send you a link to reset your
                        password.
                      </label>
                    )}

                    <Form role="form">
                      <div
                        className={signUpLink ? "gridTwoColumns grGap20" : ""}
                      >
                        {signUpLink && (
                          <FormGroup>
                            <label className="form-control-label label-in mb-2">
                              Type *
                            </label>
                            <div className="status_select w100">
                              <UncontrolledDropdown className="status_select_div signup_dropDown">
                                <DropdownToggle className="pr-0 drop_down_container padl06">
                                  <Media className="align-items-center ">
                                    {businessType === "individual"
                                      ? "Individual"
                                      : "Company"}

                                    <i className="fas fa-angle-down"></i>
                                  </Media>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-arrow w100">
                                  <DropdownItem
                                    className="dropdown_item"
                                    onClick={() =>
                                      this.setBusinessType("individual")
                                    }
                                  >
                                    Individual
                                  </DropdownItem>
                                  <DropdownItem
                                    className="dropdown_item"
                                    onClick={() =>
                                      this.setBusinessType("company")
                                    }
                                  >
                                    Company
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>
                          </FormGroup>
                        )}
                        {signUpLink && businessType === "company" && (
                          <FormGroup>
                            <label className="form-control-label label-in mb-2">
                              Business Name
                            </label>
                            <Input
                              type="text"
                              name="companyName"
                              autoComplete="new-name"
                              value={companyName}
                              onChange={this.handleInputChange}
                              className="email-new-in new-login-in pad-new-in"
                              onKeyDown={(e) => this.handleKeypress(e)}
                            />
                          </FormGroup>
                        )}
                        <div
                          className={
                            signUpLink ? "signup-flexy" : "flex-reverse"
                          }
                        >
                          <div className={signUpLink ? "inherit-flexy" : ""}>
                            {signUpLink && (
                              <FormGroup className="">
                                <label
                                  className="form-control-label label-in mb-2"
                                  htmlFor="input-email"
                                >
                                  Name *
                                </label>
                                <InputGroup className="input-group-alternative new-input-login">
                                  <Input
                                    type="text"
                                    name="name"
                                    autoComplete="new-name"
                                    value={name}
                                    onChange={this.handleInputChange}
                                    className="email-new-in new-login-in pad-new-in"
                                    onKeyDown={(e) => this.handleKeypress(e)}
                                  />
                                </InputGroup>
                              </FormGroup>
                            )}

                            {!isNumber && (signInLink || forgotLink) && (
                              <FormGroup
                                className={forgotLink ? "mt-0-8" : "mt-4-5"}
                              >
                                <label
                                  className="form-control-label label-in mb-2"
                                  htmlFor="input-email"
                                >
                                  {/* Email or mobile number * */}
                                  {isEmail
                                    ? "Mail ID *"
                                    : forgotLink
                                      ? "Enter a mail id"
                                      : "Enter email or mobile number *"}
                                </label>
                                <InputGroup className="input-group-alternative new-input-login">
                                  <Input
                                    disabled={isEmail}
                                    type="email"
                                    name="email"
                                    value={email}
                                    autoComplete="new-email"
                                    onChange={this.handleInputChange}
                                    onKeyDown={(e) => this.handleKeypress(e)}
                                    className="email-new-in"
                                  />
                                </InputGroup>
                              </FormGroup>
                            )}
                          </div>
                        </div>
                        <div className="inherit-flexy">
                          {signUpLink && (
                            <FormGroup className="">
                              <label
                                className="form-control-label label-in mb-2"
                                htmlFor="input-email"
                              >
                                Email *
                              </label>
                              <InputGroup className="input-group-alternative new-input-login">
                                <Input
                                  type="email"
                                  name="email"
                                  value={email}
                                  autoComplete="new-email"
                                  onKeyDown={(e) => this.handleKeypress(e)}
                                  onChange={this.handleInputChange}
                                  className="email-new-in"
                                  readOnly={this.state.extEmail ? true : false}
                                />
                              </InputGroup>
                            </FormGroup>
                          )}
                        </div>
                        <div className={signUpLink ? "signup-flexy" : ""}>
                          <div className={signUpLink ? "inherit-flexy" : ""}>
                            {signUpLink && (
                              <FormGroup className="">
                                <label
                                  className="form-control-label label-in mb-2"
                                  htmlFor="input-email"
                                >
                                  Mobile Number *
                                </label>
                                <InputGroup className="input-group-alternative new-input-login">
                                  <Input
                                    type="text"
                                    name="phone"
                                    value={phone}
                                    autoComplete="new-phone"
                                    onChange={this.handleOnPhoneNumberChange}
                                    maxLength="16"
                                    className="email-new-in"
                                    readOnly={
                                      this.state.extPhone ? true : false
                                    }
                                    onKeyDown={(e) => this.handleKeypress(e)}
                                  />
                                </InputGroup>
                              </FormGroup>
                            )}
                          </div>
                        </div>
                        <div className={signUpLink ? "signup-flexy" : ""}>
                          <div className={signUpLink ? "inherit-flexy" : ""}>
                            {!isNumber &&
                              !forgotLink &&
                              (isFilled || signUpLink) && (
                                <FormGroup className={signInLink ? "mb-4" : ""}>
                                  <label
                                    className="form-control-label label-in mb-2"
                                    htmlFor="input-email"
                                  >
                                    Password*
                                  </label>
                                  <InputGroup className="input-group-alternative new-input-login">
                                    <Input
                                      type={pType}
                                      name="password"
                                      value={password}
                                      autoComplete="new-password"
                                      onKeyDown={(e) => this.handleKeypress(e)}
                                      onChange={this.handleInputChange}
                                      className="email-new-in pad-new-in"
                                    />
                                    <InputGroupAddon addonType="append">
                                      <InputGroupText
                                        onClick={() =>
                                          this.toggleEyeIcon("pass")
                                        }
                                        className="new-border"
                                      >
                                        <i
                                          className={
                                            pType === "password"
                                              ? "fa fa-eye-slash"
                                              : "fa fa-eye"
                                          }
                                        ></i>
                                      </InputGroupText>
                                    </InputGroupAddon>
                                  </InputGroup>
                                </FormGroup>
                              )}
                          </div>
                        </div>
                        <div className="inherit-flexy">
                          {signUpLink && (
                            <FormGroup className="">
                              <label
                                className="form-control-label label-in mb-2"
                                htmlFor="input-email"
                              >
                                Confirm Password *
                              </label>
                              <InputGroup className="input-group-alternative new-input-login">
                                <Input
                                  type={nType}
                                  name="conPassword"
                                  value={conPassword}
                                  autoComplete="new-confirm-password"
                                  onKeyDown={(e) => this.handleKeypress(e)}
                                  onChange={this.handleInputChange}
                                  className="email-new-in pad-new-in"
                                />
                                <InputGroupAddon addonType="append">
                                  <InputGroupText
                                    onClick={() =>
                                      this.toggleEyeIcon("newPass")
                                    }
                                    className="new-border"
                                  >
                                    <i
                                      className={
                                        nType === "password"
                                          ? "fa fa-eye-slash"
                                          : "fa fa-eye"
                                      }
                                    ></i>
                                  </InputGroupText>
                                </InputGroupAddon>
                              </InputGroup>
                            </FormGroup>
                          )}
                        </div>
                        {signInLink && isNumber && (
                          <div>
                            <div className="otpContainer">
                              {arrayOfNumber.map((obj, i) => (
                                <input
                                  key={i}
                                  maxLength="1"
                                  tabIndex={i + 1}
                                  onChange={(e) =>
                                    this.otpInputClick(`otp_${i + 1}`, e)
                                  }
                                  onKeyUp={(e) => this.toogleToNextField(e)}
                                  className="otpInput"
                                ></input>
                              ))}
                            </div>
                            <div className="otpResendContainer">
                              {/* {this.state.otpSent && ( */}
                              <div
                                style={{
                                  visibility: this.state.otpSent
                                    ? ""
                                    : "hidden",
                                }}
                                className="resendOTP"
                              >
                                {`Time Remaining ${this.state.countdown} seconds`}
                              </div>
                              {/* )} */}
                              <button
                                disabled={this.state.otpSent}
                                style={{ fontSize: "12px" }}
                                onClick={(e) => this.startCountdown(e)}
                                className="cursor-point resendOtpBtn"
                              >
                                Resend OTP
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                      {!isNumber && !forgotLink && isFilled && signInLink && (
                        <div className="custom-control custom-control-alternative custom-checkbox text-align">
                          <a
                            className="text-light"
                            onClick={(e) => e.preventDefault()}
                          >
                            <small
                              onClick={() => this.handleToggle()}
                              className="col_primary_1"
                            >
                              Forgot Password ?
                            </small>
                          </a>
                        </div>
                      )}
                      {!isNumber && !forgotLink && isFilled && (
                        <div className="terms-pad">
                          <FormGroup
                            className={signUpLink ? "mb-term" : "mb-1 mt-4"}
                            check
                          >
                            <Input
                              type="checkbox"
                              name="check"
                              checked={check}
                              autoComplete="new-phone"
                              onChange={this.handleOnCheckChange}
                              maxLength="10"
                              className="email-new-in term-in checking-in mt-13"
                            />
                            <label className="term-label">
                              By clicking{" "}
                              {signInLink ? "Log In" : "Create Account"} , you
                              agree to our{" "}
                              <span
                                className="term-color"
                                onClick={() => this.handleRedirect("terms")}
                              >
                                {" "}
                                Terms{" "}
                              </span>
                              <span> and </span>
                              <span
                                className="term-color"
                                onClick={() => this.handleRedirect("policy")}
                              >
                                {" "}
                                Policy
                              </span>
                            </label>
                          </FormGroup>
                        </div>
                      )}

                      {(forgotLink || signInLink) && (
                        <div
                          style={{
                            marginTop: this.state.isFilled ? "35px" : "",
                          }}
                          className={signUpLink ? "text-left" : "text-center"}
                        >
                          <Button
                            className={
                              isLoading
                                ? " sign-auth  pad-but loader-btn bg-shadowable"
                                : "sign-auth button-wrapping pad-but loader-btn"
                            }
                            color="primary"
                            type="button"
                            onClick={(e) =>
                              forgotLink
                                ? this.handleReset()
                                : signInLink && isFilled && !isNumber
                                  ? this.handleLogin()
                                  : signInLink && isNumber
                                    ? this.verify()
                                    : this.handleNextStep(e)
                            }
                          >
                            {forgotLink && !isLoading
                              ? "Submit"
                              : signInLink &&
                                !isLoading &&
                                isFilled &&
                                !isNumber
                                ? "Log In"
                                : signInLink && !isFilled && !isLoading
                                  ? "Next"
                                  : signInLink && isNumber && !isLoading
                                    ? "Verify & Proceed"
                                    : ""}
                            {isLoading && (
                              <div className="login_spinner">
                                <Spinner type="border" color="#2e1a47" />
                              </div>
                            )}
                          </Button>
                        </div>
                      )}
                      {signUpLink && (
                        <Custom_Button
                          isPrimary
                          click={() => this.handleSignUp()}
                          btnLabel="Create Account"
                          isLoading={isLoading}
                        />
                      )}
                    </Form>

                    {forgotLink && (
                      <div className="toggle-link">
                        Already have an account ? {"  "}
                        <span onClick={() => this.toggleSignType("login")}>
                          Login Here
                        </span>
                      </div>
                    )}

                    {!isNumber && signInLink && (
                      <div className="toggle-link">
                        Don't have an account ?{" "}
                        <span onClick={() => this.toggleSignType("register")}>
                          Register Here
                        </span>
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}
export default Login;
