import { useState, useEffect } from "react";
import closeIcon from "../../assets/img/brand/close.svg";
import { isInvalidEmail } from "constants/utils";

const TagInputs = (props) => {
  const [tags, setTags] = useState([]);

  useEffect(() => {
    if (props.assignees) {
      setTags(props.assignees);
    }
  }, [props.assignees]);

  const addAssignee = (event) => {
    if (event.key === "Enter" && event.target.value !== "") {
      if (isInvalidEmail(event.target.value)) {
        props.toast("Please enter valid email");
      } else {
        setTags([...tags, { email: event.target.value }]);
        props.selectedAssignee([...tags, { email: event.target.value }]);
        event.target.value = "";
      }
    }
  };

  const removeAssignee = (index) => {
    setTags([...tags.filter((tag) => tags.indexOf(tag) !== index)]);
    props.selectedAssignee([
      ...tags.filter((tag) => tags.indexOf(tag) !== index),
    ]);
  };

  return (
    <div className="tagInputDiv h100 mb-20">
      {tags.length > 0 && (
        <ul>
          {tags.map((tag, i) => (
            <li>
              <span>{tag.email}</span>
              <img
                src={closeIcon}
                onClick={() => removeAssignee(i)}
                className="point"
                width="15px"
                height="15px"
              />
            </li>
          ))}
        </ul>
      )}
      <input
        type="text"
        placeholder="Enter Guests"
        onKeyUp={(event) => addAssignee(event)}
        className="assigneeInput"
      />
    </div>
  );
};

export default TagInputs;
