import React, { Component } from "react";
import pksLogo from "../../assets/img/brand/logoPark.svg";
import newLogo from "../../assets/img/new Logo/contractor_new_logo.svg"
import {
  InputGroup,
  Dropdown,
  Input,
  UncontrolledDropdown,
  DropdownToggle,
  Button,
  Media,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import ProjectLists from "./projectLists"
export default class ClientPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterOption: "",
      inputSearch: "",
      statusSearch: "",
    };
  }


  render() {
    let { filterOption, inputSearch, statusSearch } = this.state;

    return (
      <>
        <div className="clientContainer">
          <img src={newLogo} style={{ borderRadius: "5px" }}  alt="logo"></img>
          <div className="profileName">Profile</div>
        </div>
        <div className="clientBody">
          <div className="BodyHeaderName">Projects</div>
          <div className="clientBody_body">
            <InputGroup className="mb-5 mb_mt_28 flex-content align-left">
              <div className="filters_container">
                <div className="left_filter_container">
                  {" "}
                  <div className="select_with_input mr-10">
                    <Dropdown
                      variant="input-group"
                      className="select_input_drop"
                    >
                      <select
                        id="inputGroupSelect01"
                        className="form_select fixed_select"
                        name="filterOption"
                        value={filterOption}
                        onChange={this.onChangeHandler}
                      >
                        <option value="">Select</option>
                        {/* <option value="username">Customer</option> */}
                        <option value="name">Name</option>
                        {/* <option value="property_id">Property Id</option> */}
                      </select>
                      <i className="fas fa-angle-down"></i>
                    </Dropdown>
                    <Input
                      style={{ fontSize: "14px", color: "#212529" }}
                      aria-label="Text input with dropdown button"
                      className="input-filter-toggle"
                      value={inputSearch}
                      name="inputSearch"
                      onChange={this.onChangeHandler}
                    />
                  </div>
                  <div className="status_select ml-10">
                    <UncontrolledDropdown className="status_select_div">
                      <DropdownToggle className="pr-0 drop_down_container">
                        <Media className="capitalize">
                          {statusSearch === "in_progress"
                            ? "In Progress"
                            : statusSearch === ""
                            ? "All"
                            : statusSearch}

                          <i className="fas fa-angle-down"></i>
                        </Media>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-arrow">
                        {" "}
                        <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.setStatus("")}
                        >
                          All
                        </DropdownItem>{" "}
                        <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.setStatus("active")}
                        >
                          Active
                        </DropdownItem>
                        {/* <DropdownItem
                        className="dropdown_item"
                        onClick={() => this.setStatus("terminated")}
                      >
                        Terminated
                      </DropdownItem> */}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </div>
                <div className="right_filter_container">
                  <Button
                    color="primary"
                    onClick={this.handleReset}
                    size="sm"
                    className="button-wrapping search-wrapper content-flex mr-4 button-secondary ml-4 reset-btn"
                  >
                    Reset
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => this.handleSearch()}
                    size="sm"
                    className="button-wrapping search-wrapper ml-0"
                  >
                    Search
                  </Button>
                </div>
              </div>
            </InputGroup>
          </div>
          <ProjectLists props={this}/>
          {/* <ClientProjectDetail/> */}
        </div>
      </>
    );
  }
}
