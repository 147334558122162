import { th } from "date-fns/esm/locale";
import React, { useEffect } from "react";
let tableArray = new Map();
function CustomTable({ thAndTd, responseData, thead, tbody }) {
  useEffect(() => {
    createSet();
  }, []);

  function createSet() {
    const newMap = new Map();
    const tempObj = {};
    thAndTd.forEach((headObj) => {
      const { head, body, render } = headObj;
      tempObj[head] = [];
      responseData.forEach((element) => {
        if (render) {
          tempObj[head].push(render);
        } else {
          tempObj[head].push(element[body]);
        }
        newMap.set(head, tempObj[head]);
      });
    });
    tableArray = [...newMap.entries()].map(([key, value]) => {
      return { key, value: value };
    });
  }

  return (
    //  <div className="big_container">
    //   {tableArray.length &&
    //     tableArray.map(({ key, value },index) => (
    //       <div>
    //         <div>{key}</div>
    //         {value.map((str,j) =>
    //           typeof(str) === 'function' ? (
    //             <div>
    //               <div>{str(j)}</div>
    //             </div>
    //           ) : (
    //             <div>
    //               <div>{str}</div>
    //             </div>
    //           )
    //         )}
    //       </div>
    //     ))}
    //   <div></div>
    // </div>
    <div className="custom_table">
      <table>
        <thead>
          <tr>
            {thead.map((str) => (
              <th>{str}</th>
            ))}
          </tr>
        </thead>
        <tbody>
            {responseData.map((indi,index) => (
          <tr> {
              tbody.map((obj) => (
                typeof(obj) === 'function' 
                ?
                <td>{obj(index)}</td>
                : 
              <td>{indi[obj]}</td>
              ))
          }
          </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}

export default CustomTable;
