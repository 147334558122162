import React, { Component, useLayoutEffect } from "react";
// import { toast, ToastContainer } from "react-toast";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Container,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Spinner,
  Modal,
  InputGroup,
  InputGroupText,
  CardHeader,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Media,
  UncontrolledDropdown,
} from "reactstrap";
import ReactTooltip from "react-tooltip";
import ApiService from "constants/ApiService";
import { returnFirstDegreeObjValue, showCalendarFormat } from "constants/utils";
import { getCloudinaryImage } from "constants/utils";
import { LightgalleryItem } from "react-lightgallery";
import ToastList from "components/Toast/ToastList";
import { showUtcDate } from "constants/utils";
import { showSuccess } from "constants/utils";
import { showError } from "constants/utils";
import building from "../../../src/assets/img/brand/dummyRequest.jpeg";
import requestCall from "../../../src/assets/img/brand/reqCall.svg";
import requestEmail from "../../../src/assets/img/brand/reqEmail.svg";
import requestMap from "../../../src/assets/img/brand/req_map.svg";
import circle from "../../../src/assets/img/brand/reqLineCircle.svg";
import CustomTimeline from "views/customtimeline";
import requestFirstStage from "../../../src/assets/img/brand/requestFirstStage.svg";
import requestSecondStage from "../../../src/assets/img/brand/secondStageReq.svg";
import { returnThirdDegreeObjValue } from "constants/utils";
import returnSecondDegreeObjValue from "constants/utils";
import RejectModal from "../../views/Request/rejectRequestModal";
import defaultUser from "../../../src/assets/img/brand/defaultUser.svg";
import horizontalView from "../../../src/assets/img/brand/grouping.svg";
import horizontalViewSelected from "../../../src/assets/img/brand/groupingSelected.svg";
import verticalView from "../../../src/assets/img/brand/invoiceView.svg";
import verticalViewSelected from "../../../src/assets/img/brand/invoiceViewSelected.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dateLight from "../../assets/img/brand/datePicker.svg";
import pdfImage from "../../assets/img/brand/pdf.png";
import removeIcon from "../../assets/img/brand/removeIcon.svg";
import Add from "../../assets/img/brand/addIcon.svg";
import { uploadCloudinary } from "constants/utils";
import AssignSubcontractors from "../Request/AssignSubContractor";
import { isSub_Contractor } from "constants/utils";
import { displayPrice } from "constants/utils";
import { getCommissionAmount } from "constants/utils";
import { getDateFormat } from "constants/utils";
import { maxLengthCheck } from "constants/utils";
import { showLocaldate } from "constants/utils";
import assignRequestImg from "../../assets/img/brand/requestFirstStaging.svg";
import MaintenanceHistoryDetail from "./maintainanceHitoryDetail";
import contractorImage from "../../assets/img/brand/contractorImage.svg";
import RatingStar from "../../assets/img/brand/ratingStar.svg";
import closeIcon from "../../assets/img/brand/close.svg";
import { isEmailLowercaseOnly } from "constants/utils";
import { getUSFormat } from "constants/utils";
import { replaceToNormalNumber } from "constants/utils";
import { isInvalidEmail } from "constants/utils";
import comment from "../../assets/img/icons/common/comment.svg";
import InviteModal from "views/Subcontractor/inviteModal";
import Chat from "../../variables/chat";
import { Button_without_Border } from "components/Buttons/Button";
import { Custom_Button } from "components/Buttons/Button";
import CustomModal from "components/CustomModal/CustomModal";
let user_mode = localStorage.getItem("user");
// core components
let maintenance_id;
class MaintenanceDetail extends Component {
  constructor(props) {
    super(props);
    maintenance_id = this.props.match.params.maintenance_id;
    this.state = {
      requestDetail: {},
      OpenPopUp: false,
      isLoading: true,
      reject_reason: "",
      isFirstStage: true,
      dummyRequestStages: [],
      maintenanceData: null,
      amount: "",
      assignToWhom: false,
      contractorAssignStage: "1",
      assignLoad: false,
      commentOpenUp: false,
      assignTo: "",
      btnLoading: false,
      pageLoading: false,
      subContractorList: [],
      subcontractor: "",
      amount: "",
      commissionMode: "fixed",
      commissionAmount: "",
      estimatedAmount: "",
      email: "",
      phoneNumber: "",
      step1: true,
      step2: false,
      inviteModal: false,
      link: "",
      inviteData: "",
      requestStatus: [
        {
          status: "pending",
          isStatus: false,
          boxText: "Request has been generated",
          date: "",
        },
        // {
        //   status: "assigned",
        //   isStatus: false,
        //   boxText: "This work has been assigned to me",
        //   date: "",
        // },
        {
          status: "accepted",
          isStatus: false,
          boxText: "Request has been accepted",
          date: "",
        },
        {
          status: "in_progress",
          isStatus: false,
          boxText: "Work Is under progress",
          date: "",
        },
        {
          status: "completed",
          isStatus: false,
          boxText: "Work has been successfully completed",
          date: "",
        },
        {
          status: "closed",
          isStatus: false,
          boxText: "Request has been Closed",
          date: "",
        },
      ],
      contractorDetails: null,
      contractor_id: "",
      rejectPopuP: false,
      horizontal: false,
      OpenSchedulePopUp: false,
      scheduleOn: "",
      image: [],
      imageLoading: false,
      subcontractorModal: false,
      scheduleLoad: false,
      response: "",
      toastType: "",
      contractor: null,
    };
  }

  componentDidMount() {
    if (maintenance_id) this.getRequestDetails();
    // this.getSubcontractorList();
  }

  commentOpenUp = (value) => {
    this.setState({ commentOpenUp: value });
  };

  toggleChatModal = () => {
    this.setState({ commentOpenUp: false });
  };

  getRequestDetails = async () => {
    this.setState({
      dummyRequestStages: this.state.requestStatus.map((x) => ({
        ...x,
      })),
    });
    let response = await ApiService.getAPI(
      `contractor/maintenance/${maintenance_id}`
    );

    if (!response.success) return this.setState({ isLoading: false });
    let data = response.data.maintenance;
    // let timeLine = response.data.maintenance?.timeline;

    // timeLine?.map((item) => {
    //   if (item.status === "sub_contractor_assigned") {
    //     let element = {
    //       status: "sub contractor assigned",
    //       isStatus: true,
    //       boxText: "Request has been assigned to Subcontractor",
    //       date: item.date && showUtcDate(item.date),
    //     };
    //     this.state.dummyRequestStages.splice(2, 0, element);
    //   }
    //   if (item.status === "scheduled") {
    //     let element = {
    //       status: "scheduled",
    //       isStatus: false,
    //       boxText: "Request has been scheduled",
    //       date: item.date && showUtcDate(item.date),
    //     };
    //     this.state.dummyRequestStages.splice(3, 0, element);
    //   }
    //   if (item.status === "rescheduled") {
    //     let element = {
    //       status: "rescheduled",
    //       isStatus: false,
    //       boxText: "Request has been rescheduled",
    //       date: item.date && showUtcDate(item.date),
    //     };
    //     let i = this.state.dummyRequestStages.findIndex((item) => {
    //       return item.status === "rescheduled";
    //     });
    //     if (i !== -1) {
    //       this.state.dummyRequestStages.splice(i + 1, 0, element);
    //     } else {
    //       this.state.dummyRequestStages.splice(4, 0, element);
    //     }
    //   }
    // });

    // this.state.dummyRequestStages.map((localItem) => {
    //   data.timeline.map((item) => {
    //     // change the status of the local array if the request is rejected from closed to reject
    //     if (item.status === "rejected") {
    //       this.state.dummyRequestStages[5].status = "rejected";
    //     }

    //     // setting the localarray to its respective data
    //     if (
    //       localItem.status === item.status &&
    //       localItem.status !== "rescheduled"
    //     ) {
    //       localItem.isStatus = true;
    //       localItem.date = item.date && showUtcDate(item.date);
    //     }
    //     if (localItem.status === item.status) {
    //       localItem.isStatus = true;
    //     }
    //   });
    // });

    // // splicing the object from local Array if the request is rejected or assigned to host`
    // if (data?.assigned_to === "host" || data?.assigned_to === "manager") {
    //   this.state.dummyRequestStages.splice(1, 1);
    //   this.state.dummyRequestStages[1].boxText =
    //     data.assigned_to === "host"
    //       ? "Host has accepted the request"
    //       : "Manager has accepted the request";
    // }

    // if (data?.timeline[2]?.status === "rejected") {
    //   this.state.dummyRequestStages.splice(1, 4);
    // }
    // end splicing the object from local Array if the request is rejected or assigned to host`

    this.setState(
      {
        // dummyRequestStages: this.state.dummyRequestStages,
        maintenanceData: data,
        image: data?.bills,
        contractor: JSON.parse(localStorage.getItem("contractor_details"))
          .contractor,
        amount: isSub_Contractor() ? data?.main_contractor_amount?.amount : "",
      },
      () => {
        this.setState({ isLoading: false });
        this.setTimelineData();
      }
    );

    this.getContractors(data);
  };

  getBoxText = (item) => {
    let txt = "";
    let status = item.status;
    if (status === "pending") txt = "Request has been generated";
    else if (status === "assigned") txt = "Request has been assigned to me";
    else if (status === "accepted") txt = "Request has been accepted";
    else if (status === "rejected") txt = "Request has been rejected";
    else if (status === "sub_contractor_rejected")
      txt = "Sub contractor has been rejected";
    else if (status === "in_progress") txt = "Work is under process";
    else if (status === "completed")
      txt = "Work has been successfully completed";
    else if (status === "closed") txt = "Request has been closed";
    else if (status === "cancelled") txt = "Request has been cancelled";
    else if (status === "scheduled") txt = "Request has been scheduled";
    else if (status === "rescheduled") txt = "Request has been rescheduled";
    else if (status === "sub_contractor_assigned")
      txt = "Sub contractor has been assigned";
    else txt = status;

    return txt;
  };

  setTimelineData = () => {
    let { timeline } = this.state.maintenanceData;
    const completedActions = [];
    const actions = [];
    const constantActions = [...this.state.requestStatus];
    if (isSub_Contractor()) {
      let lastIndex = timeline.lastIndexOf(
        (item) => item.status === "sub_contractor_assigned"
      );
    } else {
      timeline.map((item, index) => {
        if (item.status) {
          actions.push(item.status);
          completedActions.push({
            status: item?.status,
            isStatus: true,
            boxText: this.getBoxText(item),
            date: item?.date && showUtcDate(item.date),
          });
        }
      });
      if (!(actions.includes("cancelled") || actions.includes("rejected"))) {
        constantActions.map((item, index) => {
          if (!actions.includes(item.status)) {
            completedActions.push(item);
          }
        });
      }
    }

    this.setState({ dummyRequestStages: completedActions });
  };

  getContractors = async (request) => {
    let response;
    if (isSub_Contractor()) {
      response = await ApiService.getAPI(
        `contractor/basic_profile/${request?.contractor?.id}`
      );
    } else {
      if (request?.sub_contractor_assigned) {
        response = await ApiService.getAPI(
          `contractor/basic_profile/${request?.sub_contractor?.id}`
        );
      }
    }
    if (response?.success) {
      let data = response.data.contractor;
      this.setState({
        contractorDetails: data,
        contractor_id: request.contractor.id,
      });
    }
  };
  mapRedirect = () => {
    window.open(
      `http://www.google.com/maps/place/${this.state.maintenanceData.property.loc[1]},${this.state.maintenanceData.property.loc[0]}`,
      "_black"
    );
  };
  StatusSideBar = (value) => {
    this.setState({ OpenPopUp: value }, () => {
      this.setState({ contractorAssignStage: "1", step1: true, step2: false });
      // this.getContractorList();
      // if (this.state.requestData.assigned_to) {
      //   this.setcontractorDetail();
      // }
    });
  };

  goTostageOne = (value) => {
    this.setState({ isFirstStage: value });
  };
  changeRequestState = (requestType) => {
    let data = {};
    if (requestType === "complete") {
      data["amount"] = this.state.amount;
      data["bills"] = this.state.image;
      this.updateRequest(data, requestType);
    } else if (requestType === "accept") {
      if (this.state.scheduleOn === "") {
        showError(this, "please select schedule date");
      } else {
        data["service_date"] = this.state.scheduleOn;
        this.updateRequest(data, requestType);
      }
    } else {
      this.updateRequest(data, requestType);
    }
  };

  updateRequest = async (data, requestType) => {
    this.setState({ btnLoading: true, scheduleLoad: true });
    let response = await ApiService.patchAPI(
      `contractor/maintenance/${requestType}/${maintenance_id}`,
      data
    );
    if (response.success) {
      this.setState({ btnLoading: false, scheduleLoad: false });
      showSuccess(this, response.message);
      this.ScheduleSideBar(false);
      this.clearAssignStages();
      this.getRequestDetails();
    } else {
      this.setState({ btnLoading: false, scheduleLoad: false });
      showError(this, response.message);
    }
  };
  clearAssignStages = () => {
    this.setState({
      selectedContractor: {},
      OpenPopUp: false,
      step1: true,
      step2: false,
    });
  };
  handleInputChange = (e) => {
    this.setState({ amount: e.target.value });
  };

  completeRequest = () => {
    let numberRegex = /^[0-9]+$/;
    if (this.state.amount === "" || this.state.amount === undefined) {
      showError(this, "Please enter the amount you have spent");
    } else if (!numberRegex.test(this.state.amount)) {
      showError(this, "Field should contain only number");
    } else {
      this.changeRequestState("complete");
    }
  };
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleRejectReason = async () => {
    let { reject_reason } = this.state;
    if (reject_reason === "") {
      showError(this, "Please enter reason to reject this maintenance");
    } else {
      let data = { reject_reason };
      let response = await ApiService.patchAPI(
        `/contractor/maintenance/reject/${maintenance_id}`,
        data
      );
      if (response.success) {
        showSuccess(this, response.message);
        setTimeout(() => this.getRequestDetails(), 1200);
        this.rejectPopUp(false);
        this.props.history.goBack();
      } else {
        showError(this, response.message);
      }
    }
  };
  rejectPopUp = (value) => {
    this.setState({ rejectPopuP: value });
  };

  ScheduleSideBar = (value) => {
    this.setState({ OpenSchedulePopUp: value });
  };

  handleScheduleOnChange = (e) => {
    this.setState({ scheduleOn: e });
  };

  scheduleRequest = async () => {
    let { scheduleOn, maintenanceData } = this.state;
    if (scheduleOn === "" || scheduleOn === null || scheduleOn === undefined) {
      showError(this, "Please Select schedule Date");
    } else {
      this.setState({ scheduleLoad: true });

      let data = {
        date: scheduleOn,
      };

      let response = await ApiService.patchAPI(
        `contractor/maintenance/reschedule/${maintenance_id}`,
        data
      );
      if (response.success) {
        this.setState({ scheduleLoad: false });

        showSuccess(this, response.message);

        this.ScheduleSideBar(false);
        this.getRequestDetails();
      } else {
        this.setState({ scheduleLoad: false });

        showError(this, response.message);
      }
    }
  };

  onImageChangeHandler = async (e, subStatus) => {
    let { image, imageLoading } = this.state;
    let file = e.target.files[0];
    if (e.target.files.length > 0) {
      this.setState({ imageLoading: true });
    }
    if (
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/jpeg" ||
      file.type === "application/pdf"
    ) {
      if (file.size < 1000000) {
        let data = await uploadCloudinary(file, "host");
        if (data.url) {
          let imgArr = [...image];
          let imageObj = {
            format: data.format,
            public_id: data.public_id,
            version: `v${data.version}`,
            type: "image",
          };
          imgArr.push(imageObj);
          this.setState(
            {
              image: imgArr,
              imageLoading: !subStatus && false,
            },
            () => {
              subStatus && this.updateBills();
            }
          );
        } else {
          showError(this, "Could not upload image");
          this.setState({ imageLoading: false });
        }
      } else {
        showError(this, "File size should not exceeds 1 MB");
        this.setState({ imageLoading: false });
      }
    } else {
      showError(this, "Invalid Format");
      this.setState({ imageLoading: false });
    }
  };

  deleteImage = (i, subStatus) => {
    let { image } = this.state;
    let imgArr = [...image];
    imgArr.splice(i, 1);
    this.setState({ image: imgArr }, () => {
      subStatus && this.updateBills();
    });
  };

  updateBills = async () => {
    let { image } = this.state;
    let data = {
      bills: image,
    };
    let response = await ApiService.patchAPI(
      `contractor/maintenance/${maintenance_id}`,
      data
    );
    if (response.success) {
      this.setState({ imageLoading: false });
      showSuccess(this, response.message);
    } else {
      showError(this, response.message);
    }
  };

  openPdf = (data) => {
    window.open(getCloudinaryImage(100, 100, data, "c_fill"));
  };

  openAssignSubcontractor = () => {
    this.setState({ subcontractorModal: true });
    // this.setState({ assignToWhom: true });
  };
  closeInviteModal = () => {
    this.setState({ inviteModal: false });
  };
  showToastMessage = (msg) => {
    showError(this, msg);
  };

  getSubContractorPrice = (requestData) => {
    let amt;
    let { sub_contractor, main_contractor_amount, amount } = requestData;
    if (sub_contractor?.id && main_contractor_amount?.type) {
      if (main_contractor_amount?.type === "percentage") {
        amt = displayPrice(
          main_contractor_amount.amount *
          (main_contractor_amount?.commission / 100)
        );
      } else {
        amt = displayPrice(main_contractor_amount?.commission);
      }
    }
    return amt;
  };

  clearAssignStages = () => {
    if (user_mode === "Personal") {
      this.setState({
        // assignTo: "",
        // paidBy: "",
        selectedContractor: {},
        // contractorAssignStage: "1",
        OpenPopUp: false,
        completeRequestState: false,
        contractor_search: "",
      });
    } else {
      this.setState({
        assignTo: "",
        paidBy: "",
        selectedContractor: {},
        contractorAssignStage: "1",
        OpenPopUp: false,
        completeRequestState: false,
        contractor_search: "",
      });
    }
  };

  closeModal = () => {
    this.setState({
      amount: "",
      estimatedAmount: "",
      commissionAmount: "",
      commissionMode: "fixed",
      subcontractor: "",
      contractorAssignStage: "",
    });
    this.props.close(false);
  };
  setContractor = (data, i) => {
    this.setState({ subcontractor: data });
    this.state.subContractorList.map((contractor) => { });
  };
  closeAssignSubcontractor = (msg, url) => {
    this.setState({ subcontractorModal: false });
    if (msg) {
      showSuccess(this, msg);
      this.getRequestDetails();
    }
    if (url) {
      this.setState({ inviteData: { link: url } }, () => {
        this.setState({ inviteModal: true });
      });
    }
  };
  isOutside = () => {
    return this.state.maintenanceData?.assign_type === "outside";
  };

  isMainContractor() {
    return (
      this.state.maintenanceData?.sub_contractor?.id ===
      this.state.contractor?.contractor_id
    );
  }

  getSubContractorCommission = (maintenenaceData) => {
    let amt;
    let { sub_contractor, main_contractor_amount, service_charge } =
      maintenenaceData;
    if (sub_contractor?.id && main_contractor_amount?.type) {
      if (main_contractor_amount?.type === "percentage") {
        amt =
          main_contractor_amount?.amount *
          (main_contractor_amount?.commission / 100);
      } else {
        amt = main_contractor_amount?.commission;
      }
    }
    return amt ? amt : 0;
  };
  render() {
    let {
      isLoading,
      toast,
      response,
      commentOpenUp,
      toastType,
      OpenPopUp,
      isFirstStage,
      maintenanceData,
      horizontal,
      OpenSchedulePopUp,
      scheduleOn,
      image,
      imageLoading,
      subcontractorModal,
      scheduleLoad,
      contractorDetails,
      inviteModal,
      inviteData,
    } = this.state;

    return isLoading ? (
      <div className="spin-loading">
        <Spinner type="border" color="#077e8b" />
      </div>
    ) : (
      <>
        {toast && <ToastList message={response} type={toastType} />}
        {/* reject Modal */}
        <RejectModal
          open={this.state.rejectPopuP}
          close={() => this.rejectPopUp(false)}
          onInputChange={this.handleChange}
          onSubmit={() => this.handleRejectReason()}
          id="maintenance"
        />
        {/* end of reject Modal */}
        <div style={{ padding: "20px", backgroundColor: "#fafafa" }}>
          {toast && <ToastList message={response} type={toastType} />}
          <div className="maintainenceDetailHeader">
            <div className="manage-con">
              <Breadcrumb>
                <BreadcrumbItem
                  onClick={() => this.props.history.goBack()}
                  className="bread-cursor"
                >
                  Maintanance
                </BreadcrumbItem>
                <BreadcrumbItem style={{ color: '#72569C' }} active>Details</BreadcrumbItem>
              </Breadcrumb>
            </div>
            <div className="manage-con request_button_div">
              {maintenanceData &&
                maintenanceData?.status === "assigned" &&
                (isSub_Contractor() ||
                  (!isSub_Contractor() &&
                    !maintenanceData?.sub_contractor_assigned) ||
                  (this.isMainContractor() && this.isOutside())) && (
                  <>
                    {/* <Button_without_Border

                      style={{ marginRight: "20px" }}
                      btnLabel="reject"
                      click={() => this.rejectPopUp(true)}
                    /> */}
                    <Custom_Button
                      style={{ marginRight: "20px" }}
                      btnLabel="reject"
                      click={() => this.rejectPopUp(true)}
                    />
                    <Custom_Button
                      style={{ marginRight: "20px" }}
                      isPrimary
                      btnLabel="accept"
                      click={() => this.ScheduleSideBar(true)}
                    />
                    {!isSub_Contractor() && !this.isMainContractor() && (
                      <Custom_Button
                        btnLabel="assign"
                        click={() => this.openAssignSubcontractor()}
                      />
                    )}
                  </>
                )}

              <div className="flexmid">
                {maintenanceData &&
                  (maintenanceData?.status === "pending" ||
                    maintenanceData?.status === "accepted" ||
                    maintenanceData?.status === "scheduled" ||
                    maintenanceData?.status === "rescheduled") &&
                  (isSub_Contractor() ||
                    (!isSub_Contractor() &&
                      !maintenanceData.sub_contractor_assigned) ||
                    (this.isMainContractor() && this.isOutside())) && (
                    <Button_without_Border
                      style={{ marginRight: "20px" }}
                      click={() => this.ScheduleSideBar(true)}
                      btnLabel="Reschedule"
                    />
                  )}
                {maintenanceData &&
                  (maintenanceData?.status === "accepted" ||
                    maintenanceData?.status === "scheduled" ||
                    maintenanceData?.status === "rescheduled") &&
                  (isSub_Contractor() ||
                    (!isSub_Contractor() &&
                      !maintenanceData.sub_contractor_assigned) ||
                    (this.isMainContractor() && this.isOutside())) && (
                    <Custom_Button
                      isPrimary
                      btnLabel="Mark as in Progress"
                      click={() => this.changeRequestState("in_progress")}
                      isLoading={this.state.btnLoading}
                    />
                  )}
                {maintenanceData &&
                  maintenanceData?.status === "in_progress" &&
                  (isSub_Contractor() ||
                    (!isSub_Contractor() &&
                      !maintenanceData.sub_contractor_assigned) ||
                    (this.isMainContractor() && this.isOutside())) && (
                    <Custom_Button
                      isPrimary
                      btnLabel="complete_maintenance"
                      click={() =>
                        isSub_Contractor() ||
                          (this.isMainContractor() && this.isOutside())
                          ? this.changeRequestState("complete")
                          : this.StatusSideBar(true)
                      }
                      isLoading={this.state.btnLoading}
                    />
                  )}
              </div>

              {maintenanceData.status !== "pending" &&
                maintenanceData.status !== "rejected" &&
                maintenanceData.status !== "cancelled" &&
                maintenanceData.status !== "assigned" && (
                  <div
                    style={{ marginLeft: "24px" }}
                    onClick={() => this.commentOpenUp(true)}
                    className="comment_Bodering"
                  >

                   
                    <div
                        data-tip="Chat with Property Incharge"
                        data-iscapture="true"
                        data-for="toolTip1"
                        data-place="top"
                      >
                        <img style={{ padding: "10px" }} src={comment} />
                      </div>
                      <ReactTooltip
                        place="right"
                        type="info"
                        effect="solid"
                        id="toolTip1"
                        html={true}
                      />

                  </div>
                )}
            </div>
          </div>
          <div className="details_parent_card_holder">
            <Card
              style={{
                border: "1px solid #E1E8F0",
                boxShadow: "0px 2px 20px 0px #0000000A",
                borderRadius: "10px",
              }}
              className="request_left_card"
            >
              <div className="individual_details">
                <div className="content_div_request">
                  <div className="header_and_data">
                    <p className="header">Property</p>
                    <p className="colon_seperator">:</p>
                    <p className="header_data capitalize">
                      {" "}
                      {maintenanceData &&
                        returnSecondDegreeObjValue(
                          maintenanceData,
                          "property",
                          "name"
                        )}
                    </p>
                  </div>
                </div>
                <div className="content_div_request request_small_width_div">
                  <div className="header_and_data ft_family">
                    <p className="header">Scheduled On</p>
                    <p className="colon_seperator">:</p>
                    <p className="header_data">
                      {maintenanceData &&
                        showLocaldate(maintenanceData?.service_date)}
                    </p>
                  </div>
                </div>
              </div>
              <div className="individual_details">
                <div className="content_div_request">
                  <div className="header_and_data">
                    <p className="header">Service </p>
                    <p className="colon_seperator">:</p>
                    <p className="header_data capitalize">
                      {maintenanceData &&
                        returnFirstDegreeObjValue(
                          maintenanceData,
                          "service_name"
                        )}
                    </p>
                  </div>
                </div>
                <div className="content_div_request request_small_width_div">
                  <div className="header_and_data ft_family">
                    <p className="header">Status </p>
                    <p className="colon_seperator">:</p>
                    {maintenanceData && maintenanceData.status && (
                      <p
                        className={
                          maintenanceData.status === "pending" ||
                            maintenanceData.status === "in_progress" ||
                            maintenanceData.status === "scheduled" ||
                            maintenanceData.status === "rescheduled"
                            ? "status_yellow header_data capitalize"
                            : maintenanceData.status === "assigned" ||
                              maintenanceData.status === "accepted" ||
                              maintenanceData.status === "completed"
                              ? "status_completed header_data capitalize"
                              : "status_red header_data capitalize"
                        }
                      >
                        {maintenanceData.status === "in_progress"
                          ? "In Progress"
                          : maintenanceData.status}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="individual_details">
                <div className="content_div_request">
                  <div className="header_and_data">
                    <p className="header">Description </p>
                    <p className="colon_seperator">:</p>
                    <p className="header_data">
                      {maintenanceData &&
                        returnFirstDegreeObjValue(maintenanceData, "desc")}
                    </p>
                  </div>
                </div>

                {maintenanceData && (
                  <div className="content_div_request request_small_width_div">
                    <div className="header_and_data ft_family">
                      <p className="header">Amount</p>
                      <p className="colon_seperator">:</p>
                      <p className="header_data">
                        {isSub_Contractor() ||
                          (this.isMainContractor() && this.isOutside())
                          ? maintenanceData &&
                          displayPrice(
                            parseFloat(
                              maintenanceData?.main_contractor_amount?.amount
                            ) -
                            parseFloat(
                              this.getSubContractorCommission(
                                maintenanceData
                              )
                            )
                          )
                          : maintenanceData &&
                          displayPrice(
                            returnFirstDegreeObjValue(
                              maintenanceData,
                              "service_charge"
                            ) === "-"
                              ? maintenanceData?.main_contractor_amount
                                ?.amount
                              : returnFirstDegreeObjValue(
                                maintenanceData,
                                "service_charge"
                              )
                          )}
                      </p>
                    </div>
                  </div>
                )}
              </div>
              <div className="individual_details">
                <div className="content_div_request">
                  <div className="header_and_data">
                    <p className="header">Estimate Cost</p>
                    <p className="colon_seperator">:</p>
                    <p className="header_data capitalize">
                      {" "}
                    {displayPrice(maintenanceData?.approximate_cost)}
                    </p>
                  </div>
                </div>
                <div className="content_div_request request_small_width_div">
            
              </div>
              </div>
              {!(
                isSub_Contractor() ||
                (this.isMainContractor() && this.isOutside())
              ) &&
                maintenanceData &&
                maintenanceData?.sub_contractor_assigned && (
                  <div className="individual_details">
                    <div className="content_div_request">
                      <div className="header_and_data">
                        <p className="header">Subcontractor Amount</p>
                        <p className="colon_seperator">:</p>
                        <p className="header_data">
                          {displayPrice(
                            parseFloat(
                              maintenanceData?.main_contractor_amount?.amount
                            ) -
                            parseFloat(
                              this.getSubContractorCommission(maintenanceData)
                            )
                          )}
                        </p>
                      </div>
                    </div>

                    {maintenanceData && (
                      <div className="content_div_request request_small_width_div">
                        <div className="header_and_data ft_family">
                          <p className="header">Commission</p>
                          <p className="colon_seperator">:</p>
                          <p className="header_data">
                            {isSub_Contractor() ||
                              (this.isMainContractor() && this.isOutside())
                              ? maintenanceData &&
                              getCommissionAmount(
                                maintenanceData,
                                maintenanceData?.service_charge,
                                isSub_Contractor()
                              )
                              : maintenanceData &&
                              displayPrice(
                                this.getSubContractorCommission(
                                  maintenanceData
                                )
                              )}
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                )}

              {maintenanceData && maintenanceData.reject_reason && (
                <div className="individual_details mb_0">
                  <div className="content_div_request">
                    <div className="header_and_data">
                      <p className="header">Reject Reason </p>
                      <p className="colon_seperator">:</p>
                      <p className="header_data">
                        {returnFirstDegreeObjValue(
                          maintenanceData,
                          "reject_reason"
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              )}
              {maintenanceData?.property?.loc?.length > 0 && (
                <>
                  {" "}
                  <hr className="request_card_detail_divider"></hr>
                  <div className="request_image_map_div">
                    <div className="request_map w_100 relative_div">
                      <button
                        className="view_on_map ft_family"
                        onClick={() => this.mapRedirect()}
                      >
                        View on Map
                      </button>
                      <img src={requestMap} />
                    </div>
                  </div>
                </>
              )}
            </Card>
            <Card
              style={{
                border: "1px solid #E1E8F0",
                boxShadow: "0px 2px 20px 0px #0000000A",
                borderRadius: "10px",
              }}
              className="request_right_card"
            >
              <h2 className="ft_family">Host Details</h2>
              <div className="customer_req_detail_div">
                <div className="left_customer_req_div">
                  <img
                    src={
                      maintenanceData && maintenanceData.manager
                        ? maintenanceData.manager.image
                          ? getCloudinaryImage(
                            130,
                            110,
                            returnSecondDegreeObjValue(
                              maintenanceData,
                              "manager",
                              "image"
                            ),
                            "c_fill"
                          )
                          : defaultUser
                        : maintenanceData.host && maintenanceData.host.image
                          ? getCloudinaryImage(
                            130,
                            110,
                            returnSecondDegreeObjValue(
                              maintenanceData,
                              "host",
                              "image"
                            ),
                            "c_fill"
                          )
                          : defaultUser
                    }
                  />
                </div>
                <div className="right_customer_req_div">
                  <h3 className="ft_family">
                    {maintenanceData.manager
                      ? returnSecondDegreeObjValue(
                        maintenanceData,
                        "manager",
                        "name"
                      )
                      : returnSecondDegreeObjValue(
                        maintenanceData,
                        "host",
                        "name"
                      )}
                  </h3>
                  {maintenanceData && (
                    <div className="detail_with_icon">
                      <img className="detail_icon" src={requestCall}></img>
                      <p className="host_customer_detail ft_family">
                        {maintenanceData.manager
                          ? maintenanceData?.manager?.phone.national_number
                          : maintenanceData?.host?.phone?.national_number}
                      </p>
                    </div>
                  )}

                  {maintenanceData && (
                    <div className="detail_with_icon mb_0">
                      <img className="detail_icon" src={requestEmail}></img>
                      <p className="host_customer_detail ft_family">
                        {maintenanceData.manager
                          ? returnSecondDegreeObjValue(
                            maintenanceData,
                            "manager",
                            "email"
                          )
                          : returnSecondDegreeObjValue(
                            maintenanceData,
                            "host",
                            "email"
                          )}
                      </p>
                    </div>
                  )}
                </div>
              </div>
              {!isSub_Contractor() &&
                maintenanceData?.sub_contractor_assigned && (
                  <>
                    <hr className="request_card_detail_divider"></hr>
                    <h2 className="ft_family flex">
                      <div>Subcontractor Details</div>
                      {maintenanceData?.status !== "completed" &&
                        maintenanceData.sub_contractor.id !==
                        JSON.parse(localStorage.getItem("contractor_details"))
                          .contractor.contractor_id &&
                        (maintenanceData.assign_type === "inside" ||
                          !maintenanceData.assign_type ||
                          maintenanceData.assign_type === "outside") && (
                          <div
                            className="point col_primary_2"
                            onClick={() => this.openAssignSubcontractor()}
                          >
                            Reassign
                          </div>
                        )}
                    </h2>
                    <div className="customer_req_detail_div">
                      <div className="left_customer_req_div">
                        <img
                          src={
                            contractorDetails && contractorDetails.image
                              ? getCloudinaryImage(
                                130,
                                110,
                                returnFirstDegreeObjValue(
                                  contractorDetails,
                                  "image"
                                ),
                                "c_fill"
                              )
                              : defaultUser
                          }
                        />
                      </div>
                      <div className="right_customer_req_div">
                        <h3 className="ft_family">
                          {contractorDetails &&
                            returnFirstDegreeObjValue(
                              contractorDetails,
                              "name"
                            )}
                        </h3>
                        {contractorDetails?.phone?.national_number && (
                          <div className="detail_with_icon">
                            <img
                              className="detail_icon"
                              src={requestCall}
                            ></img>
                            <p className="host_customer_detail ft_family">
                              {contractorDetails?.phone?.national_number}
                            </p>
                          </div>
                        )}

                        {contractorDetails?.email && (
                          <div className="detail_with_icon mb_0">
                            <img
                              className="detail_icon"
                              src={requestEmail}
                            ></img>
                            <p className="host_customer_detail ft_family">
                              {returnFirstDegreeObjValue(
                                contractorDetails,
                                "email"
                              )}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}
              {isSub_Contractor() && (
                <>
                  <hr className="request_card_detail_divider"></hr>
                  <h2 className="ft_family flex">
                    <div>Contractor Details</div>
                  </h2>
                  <div className="customer_req_detail_div">
                    <div className="left_customer_req_div">
                      <img
                        src={
                          contractorDetails && contractorDetails.image
                            ? getCloudinaryImage(
                              130,
                              110,
                              returnFirstDegreeObjValue(
                                contractorDetails,
                                "image"
                              ),
                              "c_fill"
                            )
                            : defaultUser
                        }
                      />
                    </div>
                    <div className="right_customer_req_div">
                      <h3 className="ft_family">
                        {contractorDetails &&
                          returnFirstDegreeObjValue(contractorDetails, "name")}
                      </h3>

                      {contractorDetails?.phone?.national_number && (
                        <div className="detail_with_icon">
                          <img className="detail_icon" src={requestCall}></img>
                          <p className="host_customer_detail ft_family">
                            {contractorDetails?.phone?.national_number}
                          </p>
                        </div>
                      )}

                      {contractorDetails?.email && (
                        <div className="detail_with_icon mb_0">
                          <img className="detail_icon" src={requestEmail}></img>
                          <p className="host_customer_detail ft_family">
                            {returnFirstDegreeObjValue(
                              contractorDetails,
                              "email"
                            )}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
            </Card>
          </div>
          {maintenanceData &&
            maintenanceData?.status === "completed" &&
            (isSub_Contractor() ||
              (!isSub_Contractor() &&
                !maintenanceData.sub_contractor_assigned)) && (
              <Card style={{ marginTop: "15px" }} className="pad24p">
                <div className="f14 fw-600 cl374957 mb-20">Bills</div>
                <div className="gridAuto">
                  {image.length > 0 &&
                    image.map((img, i) => (
                      <div className="requestBillImage">
                        {img.format === "pdf" ? (
                          <img
                            src={pdfImage}
                            alt="PDF"
                            onClick={() => this.openPdf(img)}
                            className="point br-5"
                            width="50px"
                            height="70px"
                          />
                        ) : (
                          <LightgalleryItem
                            className="image_upload_display request_image"
                            src={getCloudinaryImage(100, 100, img, "c_fill")}
                          >
                            <img
                              src={getCloudinaryImage(100, 100, img, "c_fill")}
                              alt="profile image"
                              className="point imgFit br-5"
                              width="70px"
                              height="70px"
                            />
                          </LightgalleryItem>
                        )}

                        <img
                          src={removeIcon}
                          className="deleteIcon"
                          onClick={() => this.deleteImage(i, true)}
                        />
                      </div>
                    ))}
                  {image.length < 5 && (
                    <div
                      className="requestImageUploader point form-relative"
                      style={{ width: "70px", height: "70px" }}
                    >
                      {imageLoading ? (
                        <div className="spin-loading3">
                          <Spinner type="border" color="#0A8080" />
                        </div>
                      ) : (
                        <div>
                          <Input
                            type="file"
                            onChange={(event) =>
                              this.onImageChangeHandler(event, true)
                            }
                            className="form-control-alternative form-control opac-hide "
                            id="ImageUpload"
                          />
                          <label htmlFor="ImageUpload " className="mb-0">
                            <div className="flexmid point">
                              <img src={Add} className="point" alt="ADD" />
                            </div>
                          </label>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </Card>
            )}
          <Card
            style={{
              border: "1px solid #E1E8F0",
              boxShadow: "0px 2px 20px 0px #0000000A",
              borderRadius: "10px",
            }}
            className="timeline_card mt_10"
          >
            <div className="flex">
              <h2 className="ft_family">Timeline</h2>
              <div className="flex">
                <img
                  src={horizontal ? verticalView : verticalViewSelected}
                  className="mr-10 point"
                  onClick={() => this.setState({ horizontal: false })}
                />
                <img
                  src={horizontal ? horizontalViewSelected : horizontalView}
                  className=" point"
                  onClick={() => this.setState({ horizontal: true })}
                />
              </div>
            </div>
            <div
              className={
                horizontal ? "timeline_div horiOverflow" : "hori_timeline"
              }
            >
              {horizontal && <img src={circle} />}
              <div className={horizontal ? "timeline_common_line" : ""}>
                <CustomTimeline
                  className="absolute_div"
                  data={this.state.dummyRequestStages}
                  view={horizontal}
                />
              </div>
              {horizontal && <img src={circle} />}
            </div>
          </Card>
          {/* change status side bar design */}

          <Card className={OpenPopUp ? "exportSideBar" : "toggle-sidebar"}>
            {OpenPopUp && (
              <CustomModal
                onlyView
                type="mark_as_completed"
                close={() => this.StatusSideBar(false)}
              >
                <div className="request_complete_stages_div fade_effect">
                  <div className="request_complete_stage_one">
                  {(!isFirstStage && this.state.maintenanceData?.approximate_cost) &&<>  <h2
                      className={
                        isFirstStage ? "ft_family mb_40" : "ft_family mb-20"
                      }
                    >
                     
                        Estimate Cost
                    </h2> <div className="amount_input mb_20">
                        <FormGroup className="">
                          <InputGroup className="d_flex_align">
                            <Input
                              className="search_contractor price_input ft_15"
                              type="number"
                              name="amount"
                              disabled={true}
                              value={this.state.maintenanceData.approximate_cost}
                              
                              // onChange={this.handleInputChange}
                              // maxLength={6}
                              // onInput={maxLengthCheck}
                            />
                          </InputGroup>
                        </FormGroup>
                      </div></> }
                    <h2 className="ft_family mb_20">
                      {isFirstStage
                        ? " Are you sure you want to mark this request as completed?"
                        : "Please enter the Price Amount *"}
                    </h2>
                    {isFirstStage && (
                      <div className="illustration_image_div d_flex_align_justify mb_50">
                        <img src={requestFirstStage} />
                      </div>
                    )}
                    {!isFirstStage && (
                      <div className="amount_input mb_40">
                        <FormGroup className="">
                          <InputGroup className="d_flex_align">
                            <Input
                              className="search_contractor price_input ft_15"
                              type="number"
                              name="amount"
                              placeholder="Please enter the amount"
                              value={this.state.amount}
                              onChange={this.handleInputChange}
                              maxLength={5}
                              onInput={maxLengthCheck}
                            />
                          </InputGroup>
                        </FormGroup>
                      </div>
                    )}

                    {!isFirstStage && (
                      <div className="mb-40">
                        <div className="f14 fw-600 cl374957 mb-20">
                          Attach Bills if any
                        </div>
                        <div className="gridAuto65">
                          {image.length > 0 &&
                            image.map((img, i) => (
                              <div className="requestBillImage">
                                {img.format === "pdf" ? (
                                  <img
                                    src={pdfImage}
                                    alt="PDF"
                                    onClick={() => this.openPdf(img)}
                                    className="point br-5"
                                    width="50px"
                                    height="70px"
                                  />
                                ) : (
                                  <LightgalleryItem
                                    className="image_upload_display request_image "
                                    src={getCloudinaryImage(
                                      100,
                                      100,
                                      img,
                                      "c_fill"
                                    )}
                                  >
                                    <img
                                      src={getCloudinaryImage(
                                        70,
                                        70,
                                        img,
                                        "c_fill"
                                      )}
                                      alt="profile image br-5"
                                      className="point"
                                    />
                                  </LightgalleryItem>
                                )}
                                <img
                                  src={removeIcon}
                                  className="deleteIcon"
                                  onClick={() => this.deleteImage(i, false)}
                                />
                              </div>
                            ))}
                          {image.length < 5 && (
                            <div className="requestImageUploader point form-relative">
                              {imageLoading ? (
                                <div className="spin-loading3">
                                  <Spinner type="border" color="#0A8080" />
                                </div>
                              ) : (
                                <div>
                                  <Input
                                    type="file"
                                    onChange={(event) =>
                                      this.onImageChangeHandler(event, false)
                                    }
                                    className="form-control-alternative form-control opac-hide "
                                    id="ImageUpload"
                                  />
                                  <label
                                    htmlFor="ImageUpload "
                                    className="mb-0"
                                  >
                                    <div className="flexmid point">
                                      <img
                                        src={Add}
                                        className="point"
                                        alt="ADD"
                                      />
                                    </div>
                                  </label>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    <div className="buttons_div">
                      {isFirstStage ? (
                        <div className="stage_buttons">
                          <Custom_Button
                            hardText
                            isPrimary
                            btnLabel="Yes, Enter the Total Price"
                            click={() => this.goTostageOne(false)}
                            style={{ marginBottom: "20px" }}
                          />
                          <Button_without_Border
                            hardText
                            btnLabel="No, Go back"
                            click={() => this.StatusSideBar(false)}
                          />
                        </div>
                      ) : (
                        <div className="stage_buttons">
                          <Custom_Button
                            hardText
                            style={{ marginBottom: "20px" }}
                            isPrimary
                            btnLabel="Yes, Mark as complete"
                            click={() => this.completeRequest()}
                            isLoading={this.state.btnLoading}
                          />
                          <Button_without_Border
                            hardText
                            btnLabel=" No, Go back"
                            click={() => this.goTostageOne(true)}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>{" "}
              </CustomModal>
            )}
          </Card>

          {/* end of status side bar design */}

          <Modal
            isOpen={OpenSchedulePopUp}
            toggle={() => this.ScheduleSideBar(false)}
            centered
          >
            <Card>
              <CardBody className="pad25">
                <div className="mb-20 flex">
                  <div className="fw500 fc-b">
                    {maintenanceData && maintenanceData.status === "assigned"
                      ? "Schedule Request"
                      : "Reschedule Request"}
                  </div>
                  <div
                    className="clpaid x_close flexmid point fw500 font-24"
                    onClick={() => this.ScheduleSideBar(false)}
                  >
                    <img width={20} src={closeIcon} alt="close" />
                  </div>
                </div>
                <label
                  className="f14 fw-400 cl555555 mb-10"
                  htmlFor="scheduleOn"
                >
                  {maintenanceData && maintenanceData?.status === "assigned"
                    ? "Schedule Date"
                    : "Reschedule Date"}
                </label>

                <InputGroup className="mb-0  flex-content notice-date point">
                  <DatePicker
                    selected={this.state.scheduleOn}
                    minDate={new Date()}
                    maxDate={new Date().setDate(new Date().getDate() + 14)}
                    dateFormat="MM-dd-yyyy"
                    placeholderText=""
                    className="checkOutDatePick point mb-30"
                    onChange={this.handleScheduleOnChange}
                    name="scheduleOn"
                    id="scheduleOn"
                    value={this.state.scheduleOn}
                    style={{ fontWeight: "500" }}
                    showYearDropdown
                    showTimeSelect
                    showMonthDropdown
                    ariaDescribedBy="basic-addon2"
                  />
                  <InputGroupText id="basic-addon2" className=" back-unset1">
                    <label
                      className="mb-remove cursor-point"
                      htmlFor="scheduleOn"
                    >
                      <img
                        src={dateLight}
                        alt="Date-Picker Icon"
                        className="date-svg"
                      // onClick={() => this.openDatePicker()}
                      />
                    </label>
                  </InputGroupText>
                </InputGroup>
                <div className="flexEnd">
                  <Button_without_Border
                    btnLabel="cancel"
                    click={() => this.ScheduleSideBar(false)}
                    style={{ marginRight: "20px" }}
                  />
                  <Custom_Button
                    isPrimary
                    btnLabel={
                      scheduleLoad ? (
                        <div className="spin-loading-save w100 h100">
                          <Spinner type="border" color="#0A8080" />
                        </div>
                      ) : maintenanceData &&
                        maintenanceData?.status === "assigned" ? (
                        "Accept"
                      ) : (
                        "Save"
                      )
                    }
                    click={() =>
                      maintenanceData?.status === "assigned"
                        ? this.changeRequestState("accept")
                        : this.scheduleRequest()
                    }
                    isLoading={scheduleLoad}
                  />
                </div>
              </CardBody>
            </Card>
          </Modal>

          <Card
            className={subcontractorModal ? "exportSideBar" : "toggle-sidebar"}
          >
            <AssignSubcontractors
              open={subcontractorModal}
              close={this.closeAssignSubcontractor}
              toast={this.showToastMessage}
              maintenanceId={maintenance_id}
            />
          </Card>

          <Modal
            isOpen={inviteModal}
            toggle={() => this.closeInviteModal()}
            centered
            className="inviteModal"
          >
            <InviteModal
              close={() => this.closeInviteModal()}
              linkData={inviteData}
              // link={this.state.link}
              update={() => this.closeInviteModal()}
            />
          </Modal>

          <Card className={commentOpenUp ? "exportSideBar" : "toggle-sidebar"}>
            {commentOpenUp && (
              <Chat
                close={this.toggleChatModal}
                props={this.props}
                data={this.state.maintenanceData}
                status={maintenanceData.status}
                requestId={maintenance_id}
              ></Chat>
            )}
          </Card>
        </div>
      </>
    );
  }
}

export default MaintenanceDetail;
