import { Component } from "react";
import moment from "moment";
import {
  Spinner,
  Container,
  Breadcrumb,
  Row,
  Col,
  Card,
  BreadcrumbItem,
  Button,
  InputGroup,
  Dropdown,
  Input,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  DropdownMenu,
  DropdownItem,
  Table,
} from "reactstrap";
import { showUtcDate } from "constants/utils";
let projectLists = [
  { name: "Project 1", due: new Date(), status: "active", id: "123" },
  { name: "Project 2", due: new Date(), status: "progress", id: "12234" },
  { name: "Project 3", due: new Date(), status: "on Hold", id: "123441" },
  { name: "Project 4", due: new Date(), status: "started", id: "92034" },
  { name: "Project 5", due: new Date(), status: "accepted", id: "123" },
  { name: "Project 6", due: new Date(), status: "completed", id: "90980" },
  { name: "Project 7", due: new Date(), status: "cancelled", id: "809182309" },
  { name: "Project 8", due: new Date(), status: "pending", id: "93091283" },
  { name: "Project 9", due: new Date(), status: "pending", id: "8329893" },
];
export default class ProjectLists extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectLists: projectLists,
      filterOption: "",
      inputSearch: "",
      statusSearch: "",
    };
  }

  capitalizeFirstLetter = (str) => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  redirectToClientProjectDetail(obj) {
    this.props.history.push(`/client/123/${obj.id}`);
  }

  render() {
    let { projectLists, filterOption, inputSearch, statusSearch } = this.state;
    return (
      <div className="clientBody">
        <div className="BodyHeaderName">Projects</div>
        <div style={{ marginTop: "20px" }}>
          <InputGroup className="mb-5 flex-content align-left">
            <div className="filters_container">
              <div className="left_filter_container">
                {" "}
                <div className="select_with_input mr-10">
                  <Dropdown variant="input-group" className="select_input_drop">
                    <select
                      id="inputGroupSelect01"
                      className="form_select fixed_select"
                      name="filterOption"
                      value={filterOption}
                      onChange={this.onChangeHandler}
                    >
                      <option value="">Search</option>

                      <option value="name">Name</option>
                    </select>
                    <i className="fas fa-angle-down"></i>
                  </Dropdown>
                  <Input
                    aria-label="Text input with dropdown button"
                    className="input-filter-toggle"
                    value={inputSearch}
                    name="inputSearch"
                    onChange={this.onChangeHandler}
                  />
                </div>
                <div className="status_select ml-10">
                  <UncontrolledDropdown className="status_select_div">
                    <DropdownToggle className="pr-0 drop_down_container">
                      <Media className="capitalize">
                        {statusSearch === "in_progress"
                          ? "In Progress"
                          : statusSearch === ""
                          ? "All"
                          : statusSearch}

                        <i className="fas fa-angle-down"></i>
                      </Media>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-arrow" bottom>
                      {" "}
                      <DropdownItem
                        className="dropdown_item"
                        onClick={() => this.setStatus("")}
                      >
                        All
                      </DropdownItem>{" "}
                      <DropdownItem
                        className="dropdown_item"
                        onClick={() => this.setStatus("accepted")}
                      >
                        Accepted
                      </DropdownItem>
                      <DropdownItem
                        className="dropdown_item"
                        onClick={() => this.setStatus("pending")}
                      >
                        Pending
                      </DropdownItem>
                      <DropdownItem
                        className="dropdown_item"
                        onClick={() => this.setStatus("in_progress")}
                      >
                        Inprogress
                      </DropdownItem>
                      <DropdownItem
                        className="dropdown_item"
                        onClick={() => this.setStatus("completed")}
                      >
                        Completed
                      </DropdownItem>
                      <DropdownItem
                        className="dropdown_item"
                        onClick={() => this.setStatus("cancelled")}
                      >
                        Cancelled
                      </DropdownItem>
                      <DropdownItem
                        className="dropdown_item"
                        onClick={() => this.setStatus("rejected")}
                      >
                        Rejected
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              </div>
              <div className="right_filter_container">
                <Button
                  color="primary"
                  onClick={this.handleReset}
                  size="sm"
                  className="button-wrapping search-wrapper content-flex mr-4 button-secondary ml-4 reset-btn"
                >
                  Reset
                </Button>
                <Button
                  color="primary"
                  onClick={() => this.handleSearch()}
                  size="sm"
                  className="button-wrapping search-wrapper ml-0"
                >
                  Search
                </Button>
              </div>
            </div>
          </InputGroup>
        </div>
        <div className="projectLists">
          {projectLists.map((obj, i) => (
            <div
              key={i}
              onClick={() => this.redirectToClientProjectDetail(obj)}
              className="ProjectCard"
            >
              <div className="projectName">{obj.name}</div>
              <div className="project_bottom">
                <div className="project_left">
                  <div className="due_date">Due Date</div>
                  <div className="">
                    {showUtcDate(obj.due)}
                  </div>
                </div>
                <div className="project_right">
                  {this.capitalizeFirstLetter(obj.status)}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
