import ToastList from "components/Toast/ToastList";
import ApiService from "constants/ApiService";
import React, { Component } from "react";
import { Button, Input, CardHeader, Spinner } from "reactstrap";
import Copy from "../../assets/img/brand/copy.svg";
import {
  getUSFormat,
  isInvalidEmail,
  showError,
  showSuccess,
} from "../../constants/utils";
import closeIcon from "../../assets/img/icons/closeIcon.svg";
import { Button_without_Border } from "components/Buttons/Button";
import { Custom_Button } from "components/Buttons/Button";

class InviteModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      btn_loader: false,
      toast: "",
      toastType: "",
      response: "",
      inviteData: "",
      link: false,
      url: "",
      phoneNumber: "",
    };
  }

  //   componentDidUpdate(prevProps) {
  //     if (prevProps.data !== this.props.data) {
  //       this.setState({
  //         inviteData: this.props.data,
  //         email: this.props.data.sub_contractor.email,
  //       });
  //     }
  //   }

  componentDidMount() {
    if (this.props.data || this.props.linkData) {
      this.setState(
        {
          inviteData: this.props.data,
          email: this.props.data?.email ?? "",
          phoneNumber: this.props.data?.phone?.national_number ?? "",
          url: this.props.data?.link,
          link_url: this.props.linkData?.link ?? "",
          link: true,
        },
        () => {
          if (this.state.inviteData) {
            this.callApi();
          }
        }
      );
    }
  }

  handleEmailChange = (e) => {
    this.setState({ email: e.target.value });
  };
  handlePhoneNumberChange = (e) => {
    this.setState({ phoneNumber: e.target.value });
  };

  sendInvite = async () => {
    let { email, phoneNumber } = this.state;
    if (!email && !phoneNumber) {
      showError(this, "Please enter email or phone number");
    } else if (email && isInvalidEmail(email)) {
      showError(this, "Please enter valid email");
    } else if (phoneNumber && phoneNumber.length < 10) {
      showError(this, "Please enter valid phone number");
    } else {
      this.callApi();
    }
  };

  callApi = async () => {
    this.setState({ btn_loader: true });
    let data = {
      email: this.state.email,
      phone: {
        country_code: "1",
        national_number: this.state.phoneNumber,
      },
    };
    let response;
    if (this.props.data) {
      let id = this.props.data.invite_id;
      response = await ApiService.patchAPI(
        `contractor/invite/resend/${id}`,
        data
      );
    } else {
      response = await ApiService.postAPI(`contractor/invite`, data);
    }
    this.setState({ btn_loader: false });
    if (response.success) {
      showSuccess(this, response.message);
      if (!this.props.data) {
        this.setState({ url: response?.data?.link });
      }
      this.setState({ link: true });
    } else {
      showError(this, response.message);
    }
  };
  copyLink = (url) => {
    navigator.clipboard.writeText(url);
    showSuccess(this, "Invite Link Copied");
  };

  render() {
    let {
      email,
      toast,
      response,
      toastType,
      inviteData,
      link,
      btn_loader,
      url,
      phoneNumber,
      link_url,
    } = this.state;
    return (
      <div>
        {toast && <ToastList message={response} type={toastType} />}
        {link ? (
          <div>
            <div className="modal-header modal-invite success-invite">
              <h3 className="green text-center font-24"> Success</h3>
              <span className="text-center fade-color">
                Your invite has been sent to receipient mail. Still you can copy
                the link and share it.
              </span>
              <div className="copy-navigator mt-4">
                <p className="mb-0" style={{ overflow: "hidden" }}>
                  {" "}
                  {url}
                </p>
                <div
                  className="width-invite"
                  onClick={() => this.copyLink(url)}
                >
                  <img className="cursor-point" src={Copy} alt="copy image" />
                </div>
              </div>
              <Custom_Button
                style={{marginTop:'20px'}}
                btnLabel="ok"
                isPrimary
                click={() => this.props.update()}
              />
            </div>
          </div>
        ) : (
          <div className="pad25">
            <div className="flex mb-20">
              <div className="fw500 fc-b">Invite</div>
              <div
                className="clpaid x_close flexmid point fw500 font-24"
                onClick={() => this.props.close()}
              >
                <img width={20} src={closeIcon} alt="close" />
              </div>
            </div>

            <div className="mb-20 cl00000080">
              Please enter the details to complete the Invite
            </div>

            <div className="mb-20">
              <label className="f14 label-in">Email</label>
              <Input value={email} onChange={this.handleEmailChange} />
            </div>

            <div className="mb-20">
              <label className="f14 label-in">Mobile Number</label>
              <Input
                value={getUSFormat(phoneNumber)}
                onChange={this.handlePhoneNumberChange}
                name="phone"
                placeholder=""
                type="text"
                maxLength="16"
              />
            </div>

            <div className="flexEnd">
              <Button_without_Border
                style={{ marginRight: "20px" }}
                btnLabel="cancel"
                click={() => this.props.close()}
              />
              <Custom_Button
                btnLabel="update"
                isPrimary
                isLoading={btn_loader}
                click={this.sendInvite}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default InviteModal;
