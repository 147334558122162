import React, { Component } from "react";
import {
  Card,
  Table,
  Container,
  Row,
  Breadcrumb,
  Col,
  Button,
  InputGroup,
  CardBody,
  Spinner,
  DropdownMenu,
  BreadcrumbItem,
  DropdownItem,
  Media,
  UncontrolledDropdown,
  DropdownToggle,
} from "reactstrap";
import ToastList from "components/Toast/ToastList";
import AddPhotosDocuments from "./addPhotosOrDocuments";
import Lottie from "react-lottie";
import NoData from "../../assets/Lotties/noData.json";

let overlap = document.querySelector(":root");

export default class DocumentsModule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initPageLoading: false,
      toast: false,
      response: "",
      toastType: "",
      addDocuments: false,
      documents: [],
    };
  }

  componentDidMount = () => {
    overlap.style.setProperty("--width", "600px");
  };
  componentWillUnmount() {
    overlap.style.setProperty("--width", "450px");
  }

  render() {
    const NodataFound = {
        loop: true,
        autoplay: true,
        animationData: NoData,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      };
    let {
      initPageloading,
      toast,
      response,
      toastType,
      addDocuments,
      documents,
    } = this.state;
    return initPageloading ? (
      <>
        <div className="spin-loading">
          <Spinner type="border" color="#077e8b" />
        </div>
      </>
    ) : (
      <>
        <Container className="mt-8 card-pad-wrapper" fluid>
          {toast && <ToastList message={response} type={toastType} />}
          <div>
            <Breadcrumb></Breadcrumb>
          </div>
          <Row className="align-items-center header-div">
            <Col xs="8" className="manage-con">
              <Breadcrumb>
                <BreadcrumbItem
                  onClick={() => this.props.history.push("/admin/projects")}
                  className="bread-cursor"
                >
                  Project
                </BreadcrumbItem>
                <BreadcrumbItem
                  className="bread-cursor"
                  onClick={() => this.props.history.goBack()}
                  active
                >
                  Details
                </BreadcrumbItem>
                <BreadcrumbItem active style={{ color: "#72569c" }}>Documents</BreadcrumbItem>
              </Breadcrumb>
              <h3 className="mb-0 managers-crumb"></h3>
            </Col>
            <Col
              xs="4"
              className="manage-con flexy-content flexEnd"
              style={{ gap: "5%" }}
            >
                    <div className="status_select ml-10" style={{ width: "29%" }}>
                <UncontrolledDropdown className="status_select_div">
                  <DropdownToggle className="pr-0 drop_down_container">
                    <Media className="capitalize">
                      Documents
                      <i className="fas fa-angle-down"></i>
                    </Media>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-arrow" bottom>
                    {" "}
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() =>
                        this.props.history.push(
                          "/admin/projects/detail/UVSkm0ZUMY"
                        )
                      }
                    >
                      Overview
                    </DropdownItem>{" "}
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() => {this.props.history.push("/admin/tasks_lists")}}
                    >
                      Tasks
                    </DropdownItem>{" "}
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() => this.props.history.push("/admin/payments")}
                    >
                      Payments
                    </DropdownItem>
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() =>
                        this.props.history.push("/admin/purchase_orders")
                      }
                      // onClick={() => this.setPage("clients")}
                    >
                      Purchase Orders
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => this.props.history.push("/admin/comments")}
                      className="dropdown_item"
                      // onClick={() => this.setPage("proposal")}
                    >
                      Comments
                    </DropdownItem>
                    <DropdownItem
                      onClick={() =>
                        this.props.history.push("/admin/documents")
                      }
                      className="dropdown_item"
                      // onClick={() => this.setPage("projects")}
                    >
                      Documents
                    </DropdownItem>
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() => this.props.history.push("/admin/photos")}
                      // onClick={() => this.setPage("clients")}
                    >
                      Photos
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
              <div>
                <Button
                  color="primary"
                  size="sm"
                  className="button-wrapping"
                  onClick={() => this.setState({ addDocuments: true })}
                >
                  Add Documents
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
        {documents.length ? (
          <></>
        ) : (
          <>
            <div className="no_data_dashboard" >
              <Lottie options={NodataFound} height={100} width={100} />
              <div className="mt_20">
                <p>No Documents Found</p>
              </div>
            </div>
          </>
        )}
        <Card className={addDocuments ? "exportSideBar" : "toggle-sidebar"}>
          {addDocuments && (
            <AddPhotosDocuments
              selectedPannel="Photos"
              photDocClose={() => this.setState({ addDocuments: false })}
            ></AddPhotosDocuments>
          )}
        </Card>
      </>
    );
  }
}
