import React, { Component } from "react";
import {
  Card,
  Table,
  Row,
  Breadcrumb,
  Col,
  Button,
  Spinner,
  DropdownMenu,
  DropdownItem,
  Media,
  UncontrolledDropdown,
  DropdownToggle,
  Input,
  CardBody,
  Form,
  CardHeader,
  InputGroup,
  InputGroupText,
  Modal,
} from "reactstrap";
import copylink from "../../assets/img/newIcons/copyLinkPrimary.svg";
import ApiService from "constants/ApiService";
import returnSecondDegreeObjValue, { getAuthToken } from "constants/utils";
import Env from "constants/Env";
import { Tooltip } from "antd";
import "react-datepicker/dist/react-datepicker.css";
import ToastList from "components/Toast/ToastList";
import { BreadcrumbItem } from "reactstrap";
import Arrow from "../../assets/img/icons/common/next.svg";
import addList from "../../assets/img/icons/addTableIcon.svg";
import removeList from "../../assets/img/icons/deleteTableIcon.svg";
import { returnSearchObject, showSuccess, showError } from "constants/utils";
import closeIcon from "../../assets/img/brand/close.svg";
import AddSchedules from "./addSchedule";
import LeadSelect from "components/LeadSelect/LeadSelect";
import tagTick from "../../assets/img/icons/tagTick.svg";
import dateLight from "../../assets/img/brand/datePicker.svg";
import moment from "moment";
import { DatePicker } from "antd";
import EmailModal from "./emailInputModal";
import showMore from "../../assets/img/brand/showMore.svg";
import { CustomListingContainer } from "components/Container/CustomListingContainer";
import { ListingHeaderLayout } from "components/Container/CustomListingContainer";
import { ListingBodyLayout } from "components/Container/CustomListingContainer";
import TableFormatter from "components/Table/Table";
import LinkIcon from "../../assets/img/newIcons/newLink.svg";
import DownlaodIcon from "../../assets/img/newIcons/newDownload.svg";
import { Custom_Button } from "components/Buttons/Button";
import { CustomInputFields } from "components/FilterFields/Filterfields";
import { getUSFormat, truncate } from "constants/utils";
import { CustomTextArea } from "components/FilterFields/Filterfields";
import { CustomSingleDatePicker } from "components/FilterFields/Filterfields";
import { roundOff } from "constants/utils";
import { remove_underscore_capitalize } from "constants/utils";
import { showUtcDate } from "constants/utils";
let overlap = document.querySelector(":root");
let status = false;
class AddEstimate extends Component {
  constructor(props) {
    super(props);
    this.textareaRef = React.createRef();
    this.textareaRefItem = React.createRef();
    this.textareaRefDesc = React.createRef();
    this.subjectRef = React.createRef();
    this.state = {
      proposalSchema: {
        name: null,
        lead: null,
        email: null,
        phone: null,
        address: null,
        subject: null,
        items: [{ item_desc: "", item_name: "", rate: null, quantity: null }],
        percentageSplitUp: 1,
        percentageValue: "",
        dollarValue: "s",
        totalAmount: 0,
        splitup: [],
      },
      downloadLinkbtn: false,
      copyLinkbtn: false,
      previewbtn: false,
      sendEstimateLinkbtn: false,
      due_date: null,
      leads: [
        {
          name: "john wilson",
          phone: "(480) 555-0103",
          email: "john@gmail.com",
        },
        {
          name: "john wilson",
          phone: "(480) 555-0103",
          email: "john@gmail.com",
        },
        {
          name: "john wilson",
          phone: "(480) 555-0103",
          email: "john@gmail.com",
        },
      ],
      remainingValue: 0,
      total: 0,
      open: false,
      toast: false,
      response: "",
      contractor_details: JSON.parse(localStorage.getItem("contractor_details"))
        .contractor,
      toastType: "",
      subject: "",
      isLoading: true,
      isOpenToggle1: true,
      isOpenToggle2: true,
      isOpenToggle3: true,
      isOpenToggle: true,
      btnLoad: false,
      openPreview: false,
      addAddressBar: false,
      editSplitup: false,
      editSplitupDetail: {},
      totalAmount: 0,
      emailModal: false,
      // estimate_lead_id: this.props?.location?.search?.split("=")[1] || "",
      estimate_lead_id: {},
      isOpenLead: false,
      lead_id: "",
      lead_List: [],
      openModal: false,
      cancelModal: false,
      address: "",
      estimate_id: "",
      items: [],
      listsLoad: false,
      listsBoxOpen: false,
      confirmation: false,
      apiData: {},
      subject: "",
      subjectModal: "",
      ConfirmationBtnLoad: false,
      content: "",
      selectedLead: {},
      totalAmount: 0,
      isInputFocused: false,
      inputMessage: "",
      data: [
        {
          id: 1,
          name: "Item 1",
          description: "Description 1",
          quantity: 5,
          rate: 10,
        },
        {
          id: 2,
          name: "Item 2",
          description: "Description 2",
          quantity: 3,
          rate: 15,
        },
      ],
      openCalendar: false,
      emailLoadbtn: false,
      itemTextAreaHeight: "20px",
      itemTextAreaDescHeight: "20px",
      subjectRefHeight: "32px",
      saveDraft: false,
      draftBtn: false,
      contractor_name: "",
      contractor_email: "",
      contractor_phone: "",
      contractor_address: "",
      contractor_state: "",
      contractor_city: "",
      contractor_zip: "",
      tempSplitups: [],
      dummyIndex: 0,
    };
    this.handleNewInputChange = this.handleNewInputChange.bind(this);
    this.handleInputFocus = this.handleInputFocus.bind(this);
    this.handleInputBlur = this.handleInputBlur.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    let totalSplitUpAmount;
  }

  handleNewInputChange(e) {
    this.setState({ subject: e.target.value });
  }

  handleInputFocus() {
    this.setState({ isInputFocused: true });
  }

  handleInputBlur() {
    this.setState({ isInputFocused: false });
  }

  handleKeyDown(e) {
    if (e.key === "Enter" || e.keyCode === 13) {
      e.preventDefault();
      this.setState({ isInputFocused: false });
    }
  }

  getLineHeights = (ref) => {
    return ref?.current?.scrollHeight;
  };

  componentDidMount = async () => {
    document.addEventListener("keydown", this.handleKeyDown);
    this.getContractorDetails();
    status = this.props?.location?.state?.from === "sent";
    if (this.props.location?.state?.preview) {
      const {
        lead,
        items,
        tax,
        splitups,
        terms,
        subject,
        due_date,
        estimate_id,
        showPreview,
        showDownloadIcon,
        showCopyLinkIcon,
        status
      } = this.props?.location?.state ?? {};
      this.setState({ estimate_id: this.props.location.state.estimate_id , 
        previewbtn : showPreview ,
        downloadLinkbtn:showDownloadIcon,
        copyLinkbtn:showCopyLinkIcon,
        status:status
        });
      let finalData = {
        selectedLead: lead,
        subject: subject,
        due_date: due_date,
        inputMessage: terms,
      };
      let leadObj = {
        items:
          items && items.length > 0
            ? items
            : [
                {
                  item_desc: "",
                  item_name: "",
                  rate: 0,
                  quantity: 0,
                },
              ],
        splitups: splitups,
        terms: terms,
        percentageSplitUp: tax.type === "percentage" ? 1 : 0,
        percentageValue: tax.type === "percentage" ? tax.value : 0,
        dollarValue: tax.type === "fixed" ? tax.value : 0,
        estimate_id: estimate_id,
      };
      this.updateProposalDetails(leadObj);
      this.setState(finalData);
      this.setState({
        isLoading: false,
        saveDraft: true,
      });
      return;
    } else if (this.props?.location?.state?.type === "lead") {
      const { lead } = this.props?.location?.state ?? {};
      this.setState({ selectedLead: lead, isLoading: false }, () => {});
    } else {
      this.setState(
        {
          estimate_lead_id: returnSearchObject(this.props.location.search),
          estimate_id: returnSearchObject(this.props.location.search)
            .estimate_id,
        },
        () => {
          this.getProjectList();
        }
      );
    }
  };

  handleKeyDown(event) {
    if (event.key === "." && event.target.name === "quantity") {
      event.preventDefault();
    }
  }

  getContractorDetails = () => {
    const cont_details = JSON.parse(localStorage.getItem("contractor_details"));
    this.setState({
      contractor_name: cont_details.contractor.name,
      contractor_email: cont_details.contractor?.email || "",
      contractor_phone: cont_details.contractor?.phone?.national_number,
      contractor_address: cont_details.contractor?.address?.line_1,
      contractor_state: cont_details.contractor?.address?.state?.state,
      contractor_city: cont_details.contractor?.address?.city?.city,
      contractor_zip: cont_details.contractor?.address?.zip,
    });
  };

  componentWillUnmount = () => {
    localStorage.removeItem("draft");
    document.removeEventListener("keydown", this.handleKeyDown);
  };

  updateContent = () => {
    const { subject, content } = this.state;
    if (!subject) showError(this.props.props, "Please enter the subject");
    else if (!content) showError(this.props.props, "Please enter the content");
    else {
      let email = {
        subject: subject,
        content: content,
      };
      this.postOrPatch(this.state.apiData, email);
    }
  };

  componentWillUnmount() {
    overlap.style.setProperty("--width", "450px");
  }

  closeModal = () => {
    this.setState({ openModal: false });
    this.getProjectList();
  };

  formatAmountWithCommas = (amount) => {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  calculateTotalAmount = (items) => {
    let totalAmount = 0;
    items.forEach((item) => {
      let amount = item.quantity * item.rate;
      totalAmount += amount;
    });
    return roundOff(totalAmount);
  };

  calculateTotal = () => {
    const { proposalSchema } = this.state;
    let baseAmount = this.calculateTotalAmount(proposalSchema.items);
    let percentageValue =
      this.calculateTotalAmount(proposalSchema.items) *
      (proposalSchema.percentageValue / 100);
    let total = baseAmount;
    if (!proposalSchema.percentageSplitUp) {
      total = +baseAmount + +proposalSchema.dollarValue;
    } else {
      total = +baseAmount + +percentageValue;
    }
    return total < 0 ? 0 : total;
  };

  getProjectList = async () => {
    let selectedLead;
    let response;
    let { estimate_lead_id, estimate_id } = this.state;
    try {
      if (estimate_lead_id.lead_id) {
        response = await ApiService.getAPI(`contractor/lead/list`);
        if (estimate_lead_id) {
          let index = response.data.leads.findIndex(
            (item) => item.lead_id === estimate_lead_id?.lead_id
          );
          selectedLead = response.data.leads[index];
        }
        if (response.success) {
          this.setState({
            isLoading: false,
            lead_List: response.data.leads,
          });
        }
        let leadObj = {
          name: selectedLead.name,
          lead_id: estimate_lead_id,
          email: selectedLead.email,
          phone: selectedLead.phone,
          address: selectedLead.address,
        };
        this.updateProposalDetails(leadObj);
      } else if (estimate_lead_id?.estimate_id || estimate_id) {
        response = await ApiService.getAPI(
          `contractor/estimate/${estimate_lead_id?.estimate_id || estimate_id}`
        );
        selectedLead = response.data.estimate;
        if (response.success) {
          this.setState(
            {
              isLoading: false,
              saveDraft:
                response.data.estimate.status === "accepted" ||
                response.data.estimate.status === "sent"
                  ? false
                  : true,
              downloadLinkbtn: ["accepted", "draft", "sent"].includes(
                response.data.estimate.status
              ),
              copyLinkbtn: ["sent"].includes(response.data.estimate.status),
              previewbtn: ["draft", "sent"].includes(
                response.data.estimate.status
              ),
              sendEstimateLinkbtn: ["draft"].includes(
                response.data.estimate.status
              ),
            },
            () => {
              let leadObj = {
                name: selectedLead?.lead?.name,
                lead_id: selectedLead?.lead?.lead_id,
                subject: selectedLead?.subject,
                email: selectedLead.lead?.email,
                phone: selectedLead.lead?.phone,
                address: selectedLead.lead?.address ?? "",
                items: selectedLead?.items,
                splitup: response.data?.splitup,
                percentageSplitUp:
                  selectedLead?.tax?.type === "percentage" ? 1 : 0,
                percentageValue:
                  selectedLead?.tax?.type === "percentage"
                    ? selectedLead?.tax?.value
                    : 0,
                dollarValue:
                  selectedLead?.tax?.type === "fixed"
                    ? selectedLead?.tax?.value
                    : 0,
                totalAmount: selectedLead?.totalAmount,
                status: selectedLead?.status,
              };
              this.setState({
                selectedLead: selectedLead?.lead,
                subject: selectedLead?.subject,
                due_date: selectedLead?.due_date
                  ? new Date(selectedLead?.due_date)
                  : null,
                remainingValue: this.addTotalAmount(response.data?.splitup),
                inputMessage: selectedLead?.terms,
              });
              this.calculateTotal(selectedLead.items);
              this.updateProposalDetails(leadObj);
            }
          );
        }
      } else {
        response = await ApiService.getAPI(`contractor/lead/list`);
        if (response.success) {
          this.setState({
            isLoading: false,
            lead_List: response.data.leads,
          });
        }
      }
    } catch (error) {
      // showError(this, "Something went wrong");
    }
  };

  addTotalAmount = (data) => {
    let total = 0;

    if (data.length > 0) {
      data.forEach((val) => {
        total += val.amount.amount;
      });
    }

    return total;
  };

  overallCost = () => {
    let total = this.calculateTotal();
    const { proposalSchema } = this.state;
    for (let splitup of proposalSchema.splitups) {
      // splitup.amount
    }
  };

  selectLeadFunction = (data) => {
    this.setState({ isOpenLead: false, selectedLead: data });
  };

  closeLeadModal = () => {
    this.setState({ isOpenLead: false });
  };

  onLeadSelect = (lead) => {
    this.updateProposalDetails(lead);
    this.setState({ listsBoxOpen: false });
  };

  updateProposalDetails = (leadData) => {
    const requiredFields = [
      "lead_id",
      "name",
      "email",
      "phone",
      "address",
      "subject",
      "items",
      "splitup",
      "percentageValue",
      "percentageSplitUp",
      "dollarValue",
      "totalAmount",
      "status",
    ];
    let leadObj = {};
    for (let i = 0; i < requiredFields.length; i++) {
      if (
        leadData[requiredFields[i]] ||
        leadData[requiredFields[i]] === "" ||
        leadData[requiredFields[i]] === 0
      ) {
        leadObj[requiredFields[i]] = leadData[requiredFields[i]];
      }
    }
    this.setState(
      (prev) => ({
        proposalSchema: {
          ...prev.proposalSchema,
          ...leadObj,
        },
      }),
      () => {
      }
    );
  };

  handleInputChange = (e, from) => {
    this.setState({ [from]: e }, () => {});
  };

  capitalizeFirstLetter = (str) => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  onClose = () => {
    this.setState({
      open: false,
      addAddressBar: false,
      emailModal: false,
      editSplitup: false,
    });
    // this.getProjectList();
  };

  listChange = (index, type) => {
    const { proposalSchema } = this.state;
    if (type === "remove") {
      proposalSchema.items.splice(1, index);
      this.updateProposalDetails({ items: proposalSchema.items });
    } else {
      const checkValues = ["item_name", "item_desc", "quantity", "rate"];
      let errorCheck = {
        item_name: "name",
        item_desc: "description",
        quantity: "quantity",
        rate: "rate",
      };
      const lastItem = proposalSchema.items[proposalSchema.items.length - 1];
      const missingField = checkValues.find(
        (key) => !lastItem || !lastItem[key]
      );
      if (missingField) {
        const errorMessage = `Please enter a ${errorCheck[missingField]} field.`;
        return showError(this, errorMessage);
      }
      this.updateProposalDetails({
        items: [
          ...proposalSchema.items,
          {
            item_name: null,
            item_desc: null,
            quantity: 0,
            rate: 0,
          },
        ],
      });
    }
  };

  checkRequiredFields(fields) {
    for (const field of fields) {
      if (!field.value) {
        return field.message;
      } else if (
        field?.name !== "date" &&
        typeof field.value === "object" &&
        (Object.keys(field.value).length === 0 || !field.value)
      ) {
        return field.message;
      }
    }
    return null;
  }

  checkTableFields = (fields) => {
    for (const field of fields) {
      let valueToCheck = field.value;

      if (typeof valueToCheck === "string" && !isNaN(valueToCheck)) {
        valueToCheck = Number(valueToCheck);
      }

      if (
        valueToCheck === null ||
        valueToCheck === undefined ||
        (typeof valueToCheck === "string" &&
          valueToCheck.trim().length === 0) ||
        (typeof valueToCheck !== "string" &&
          (isNaN(valueToCheck) || valueToCheck <= 0))
      ) {
        return field.message;
      }
    }
    return null;
  };

  leadClose = () => {
    this.setState({ isOpenLead: false });
  };

  constructObject = async (obj) => {
    this.totalSplitUpAmount = 0;
    let { edit } = obj;
    let { estimate_lead_id, tempSplitups, dummyIndex, estimate_id } =
      this.state;
    if (estimate_id) {
      let response = await ApiService[edit ? "patchAPI" : "postAPI"](
        `contractor/paymentSplitup/${estimate_id}`,
        { ...obj }
      );
      if (response.success) {
        showSuccess(this, response.message);
        this.setState({ open: false });
        this.getProjectList();
      }
    } else if (edit) {
      const { splitup } = this.state.proposalSchema;
      splitup[dummyIndex] = obj;
      this.setState((prevState) => ({
        proposalSchema: {
          ...prevState.proposalSchema,
          splitup: splitup,
        },
      }));
      showSuccess(this, "Splitup edited successfully");
      this.setState({ open: false });
    } else {
      this.setState(
        (prevState) => ({
          proposalSchema: {
            ...prevState.proposalSchema,
            splitup: [...prevState.proposalSchema.splitup, obj],
          },
        }),
        () => {
          this.setState({ estimate_id: this.state.estimate_id }, () => {
            this.state.proposalSchema.splitup.map((item) => {
              this.totalSplitUpAmount += item.amount.amount;
            });
          });
        }
      );
      showSuccess(this, "New splitup inserted");
      this.setState({ open: false });
    }
  };

  sendEstimate = async (download = false) => {
    const {
      estimate_id,
      proposalSchema,
      estimate_lead_id,
      subject,
      selectedLead,
      due_date,
    } = this.state;
    const totalAmount = this.calculateTotal();
    const requiredFields = [
      {
        value: selectedLead,
        message: "Please select the lead",
      },
      { value: subject, message: "Please enter the subject" },
      { value: due_date, message: "Please enter the due date", name: "date" },
    ];

    const missingFieldMessage = this.checkRequiredFields(requiredFields);

    if (missingFieldMessage) return showError(this, missingFieldMessage);

    for (const checkItem of proposalSchema.items) {
      let { item_name, item_desc, quantity, rate } = checkItem;

      const itemFields = [
        { value: item_name, message: "Please fill the item name" },
        { value: item_desc, message: "Please fill the item description" },
        { value: quantity, message: "Please provide a valid item quantity" },
        { value: rate, message: "Please provide a valid item rate" },
      ];

      const missingItemFieldMessage = this.checkTableFields(itemFields);

      if (missingItemFieldMessage) {
        return showError(this, missingItemFieldMessage);
      } else if (download) {
        fetch(Env.BASE_URL + `contractor/estimate/download/${estimate_id}`, {
          method: "GET",
          headers: { Authorization: "Bearer " + getAuthToken() },
        })
          .then((response) => {
            return response.blob();
          })
          .then((data) => {
            if (data.type === "application/json") {
              showError(this, "No records found to download");
            } else {
              const downloadLink = document.createElement("a");
              downloadLink.href = window.URL.createObjectURL(data);
              downloadLink.setAttribute("download", `${estimate_id}.pdf`);
              document.body.appendChild(downloadLink);
              downloadLink.click();
              downloadLink.remove();
            }
          });
      } else {
        this.setState({ emailModal: true, subjectModal: subject });
      }
    }
  };

  saveDraft = async () => {
    let response;

    const {
      estimate_id,
      proposalSchema,
      estimate_lead_id,
      subject,
      selectedLead,
      due_date,
      inputMessage,
      tax,
    } = this.state;


    let draft = {
      lead: selectedLead ?? {},
      subject: subject,
      due_date: due_date,
      items: proposalSchema.items,
      splitup: proposalSchema.splitup,
      terms: inputMessage,
      tax: {
        type: proposalSchema.percentageSplitUp ? "percentage" : "fixed",
        value: proposalSchema.percentageSplitUp
          ? proposalSchema.percentageValue
          : proposalSchema.dollarValue,
      },
    };


    this.setState({
      draftBtn: true,
      previewbtn: true,
      downloadLinkbtn: true,
      sendEstimateLinkbtn: true,
    });
    if (estimate_id || localStorage.getItem("draft")) {
      response = await ApiService.patchAPI(
        `contractor/estimate/${estimate_id}`,
        draft
      );
      this.setState({
        draftBtn: false,
      });
    } else {
      // localStorage.setItem("draft", JSON.stringify(draft));
      response = await ApiService.postAPI("contractor/estimate", draft);
      this.setState({
        draftBtn: false,
      });
      if (response.success) {
        this.setState({
          estimate_id: response.data.estimate_id,
          saveDraft: true,
        });
      }
    }
    if (response.success) {
      showSuccess(this, response.message);
    }
  };

  postOrPatch = async (bodayData, email) => {
    this.setState({ ConfirmationBtnLoad: true });
    const { estimate_id, proposalSchema, estimate_lead_id } = this.state;
    let data = {
      subject: proposalSchema.subject,
      address: proposalSchema.address,
      items: proposalSchema.items,
      totalAmount: proposalSchema.totalAmount,
      tax: {
        type: this.state.proposalSchema.percentageSplitUp
          ? "percentage"
          : "fixed", // fixed , percentage
        value: this.state.proposalSchema.percentageSplitUp
          ? this.state.proposalSchema.percentageValue
          : this.state.proposalSchema.dollarValue,
      },
      lead: {
        name: proposalSchema.name,
        id: proposalSchema.lead_id,
        phone: proposalSchema.phone,
        email: proposalSchema.email,
      },
    };

    try {
      let response;
      if (estimate_lead_id.estimate_id)
        response = await ApiService.patchAPI(
          `contractor/estimate/${estimate_lead_id.estimate_id}`,
          { data: data }
        );
      else response = await ApiService.postAPI("contractor/estimate", data);
      this.setState({ btnLoad: false, ConfirmationBtnLoad: false });
      if (response.success) {
        showSuccess(this, response.message);

        setTimeout(
          () =>
            this.props.history.push("/admin/project_overview?tab=estimates"),
          1500
        );
      } else {
        showError(this, response.message);
      }
    } catch (error) {
      this.setState({ ConfirmationBtnLoad: false });
      showError(this, "An error occurred while processing the request.");
    }
  };

  updateListValues = (index, type, value, e) => {
    let { items, proposalSchema } = this.state;
    const up = [...proposalSchema.items];
    up[index][type] = value;
    this.updateProposalDetails({ items: proposalSchema.items });
  };

  deleteEstimate = async (index, id) => {
    if (this.state.estimate_id) {
      try {
        const response = await ApiService.deleteAPI(
          `contractor/paymentSplitup/${id}`
        );
        if (response.success) {
          showSuccess(this, response.message);

          this.getProjectList();
        } else throw new Error(response.message);
      } catch (err) {
        showError(this, err.message);
      }
    } else {
      const { splitup } = this.state.proposalSchema;
      splitup.splice(index, 1);
      this.setState(
        (prevState) => ({
          proposalSchema: {
            ...prevState.proposalSchema,
            splitup: splitup,
          },
        }),
        () => {
          this.state.proposalSchema.splitup.map((item) => {
            this.totalSplitUpAmount -= item.amount.amount;
          });
          showSuccess(this, "Estimate deleted successfully");
        }
      );
    }
  };

  update = (e) => {
    const textarea = this.textareaRef.current;
    textarea.style.height = "28px";
    textarea.style.height = `${textarea.scrollHeight}px`;
    this.setState({
      inputMessage: e.target.value,
    });
  };

  updateItems = (e) => {
    const textarea = this.textareaRefItem.current;
    textarea.style.height = "24px";
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  updateSubject = (e) => {
    const textarea = this.subjectRef.current;
    textarea.style.height = "32px";
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  updateDescItems = (list) => {
    // const textarea = list.descriptionHeightRef;
    // if(textarea.current) {
    //   textarea.style.height = "24px";
    //   textarea.style.height = `${textarea.scrollHeight}px`;
    // }
   
  };

  handleKeyDown1 = (e) => {
    if (e.key === "Enter" && !e.shiftKey && !e.ctrlKey) {
      e.preventDefault();
    } else if (e.key === "Enter" && (e.shiftKey || e.ctrlKey) && !e.repeat) {
      this.insertLineBreak();
      e.preventDefault();
    }
    e.key === "Enter" &&
      !e.repeat &&
      !e.shiftKey &&
      !e.ctrlKey &&
      this.sendMessage();
    this.date = [];
  };

  sendMessage = () => {
    const textarea = this.textareaRef.current;
    textarea.style.height = "28px";
  };
  insertLineBreak = () => {
    const textarea = this.textareaRef.current;
    const { selectionStart, selectionEnd, value } = textarea;
    const newValue =
      value.substring(0, selectionStart) + "\n" + value.substring(selectionEnd);
    textarea.value = newValue;
    textarea.setSelectionRange(selectionStart + 1, selectionStart + 1);
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  toggleCollapse = () => {
    this.setState((prevState) => ({
      isOpenToggle: !prevState.isOpenToggle,
    }));
  };

  getLeadList = async (e) => {
    let { value, name } = e.target;
    this.updateProposalDetails({ [name]: value });
    this.setState({
      listsLoad: true,
    });
    let searchTerm = value || "";
    let response = await ApiService.getAPI(
      `contractor/lead?name=${searchTerm}`
    );
    if (response.success) {
      let { leads } = response.data;
      this.setState(
        {
          lead_List: leads,
          listsLoad: false,
        },
        () => {
          this.state.lead_List.length
            ? this.setState({ listsBoxOpen: true })
            : this.setState({ listsBoxOpen: false });
        }
      );
    }
  };

  handleRowUpdate = (index, rowData) => {
    const newData = [...this.state.data];
    newData[index] = rowData;
    this.setState({ data: newData });
  };

  addTaxInValue = (e) => {
    this.setState({ dollarValue: e.target.value });
    this.updateProposalDetails({ dollarValue: e.target.value });
    this.calculateTotalAmount(this.state.proposalSchema.items);
  };

  getTaxPercentage = (e) => {
    this.updateProposalDetails({
      percentageValue: e.target.value,
      dollarValue: e.target.value,
    });
  };

  handleRowUpdate = (index, rowData) => {
    const newData = [...this.state.data];
    newData[index] = rowData;
    this.setState({ data: newData });
  };

  onDownload = () => {
    this.sendEstimate(true);
  };

  rowCell = () => {
    return [
      {
        title: "Item",
        id: "border_bottom",
        className: "font_standard fw_500 pdl-2",
        style: {
          width: "305px",
          paddingLeft: "10px",
          color: "#555555",
          backgroundColor: "white",
        },
      },
      {
        title: "Item Description",
        id: "border_bottom",
        className: "font_standard fw_500 pdl-2",
        style: {
          padding: "15px 10px",
          width: "404px",
          color: "#555555",
          backgroundColor: "white",
        },
      },
      {
        title: "Qty",
        id: "border_bottom",
        className: "font_standard fw_500",
        style: {
          padding: "15px 10px",
          width: "100px",
          color: "#555555",
          backgroundColor: "white",
          textAlign: "center",
        },
      },
      {
        title: "Rate",
        id: "border_bottom",
        className: "font_standard fw_500 ",
        style: {
          width: "60px",
          color: "#555555",
          backgroundColor: "white",
          textAlign: "end",
          paddingRight: "1.6rem",
        },
      },

      {
        title: "Amount",
        id: "border_bottom",
        className: "font_standard fw_500 pdl-2",
        style: {
          color: "#555555",
          width: "280px",
          textAlign: "right",
          paddingRight: "2.5rem",
          backgroundColor: "#F7F8FA",
        },
      },
    ];
  };

  changeDueDate = (e) => {
    this.setState({ due_date: e ? new Date(e) : "" });
  };

  sendEmail = async (data) => {
    let { subjectModal, content, emailLoadbtn } = this.state;
    this.setState({ emailLoadbtn: true });
    const {
      estimate_id,
      proposalSchema,
      estimate_lead_id,
      selectedLead,
      due_date,
      subject,
      inputMessage,
      dollarValue,
    } = this.state;
    let tax = {
      type: proposalSchema.percentageSplitUp ? "percentage" : "fixed",
      value: proposalSchema.percentageSplitUp
        ? proposalSchema.percentageValue
        : proposalSchema.dollarValue,
    };
    if (!subject) return showError(this, "Please enter the subject");
    // else if (!content) return showError(this, "Please provide the content");
    else {
      let finalData = {
        lead:  selectedLead,
        subject: subject,
        due_date: due_date,
        items: proposalSchema.items,
        splitups: proposalSchema.splitups,
        terms: inputMessage,
        tax: tax,
        email: {
          subject: subject,
          content: content,
        },
      };
      try {
        let response;
        response = await ApiService.patchAPI(
          `contractor/estimate/send/${estimate_id}`,
          finalData
        );
        this.setState({ emailLoadbtn: false, ConfirmationBtnLoad: false });
        if (response.success) {
          showSuccess(this, response.message);
          setTimeout(() => this.props.history.push("/admin/estimates"), 1500);
        } else {
          showError(this, response.message);
        }
      } catch (error) {
        this.setState({ ConfirmationBtnLoad: false });
        showError(this, "An error occurred while processing the request.");
      }
    }
  };

  handlePreview = () => {
    this.props.history.push({
      pathname: "estimates/preview",
      state: {
        lead: this.state.selectedLead,
        subject: this.state.subject,
        due_date: this.state.due_date,
        items: this.state.proposalSchema.items,
        splitups: this.state.proposalSchema.splitup,
        terms: this.state.inputMessage,
        tax: {
          type: this.state.proposalSchema.percentageSplitUp
            ? "percentage"
            : "fixed",
          value: this.state.proposalSchema.percentageSplitUp
            ? this.state.proposalSchema.percentageValue
            : this.state.proposalSchema.dollarValue,
        },
        estimate_id: this.state.estimate_id,
        showPreview:this.state.previewbtn,
        showDownloadIcon:this.state.downloadLinkbtn,
        showCopyLinkIcon:this.state.copyLinkbtn,
        status:this.state.proposalSchema.status
      },
    });
  };

  onLinkClick = () => {
    navigator.clipboard.writeText(
      `${Env.BASE_URL}/accept_reject_proposal/${this.state.estimate_id}`
    );
    showSuccess(this, "Link Copied");
  };

  handleButtonClick = (buttonLabel) => {
    if (buttonLabel === "preview") {
      this.props.history.push({
        pathname: "estimates/preview",
        state: {
          lead: this.state.selectedLead,
          subject: this.state.subject,
          due_date: this.state.due_date,
          items: this.state.proposalSchema.items,
          splitups: this.state.proposalSchema.splitups,
          terms: this.state.inputMessage,
          tax: {
            type: this.state.proposalSchema.percentageSplitUp
              ? "percentage"
              : "fixed",
            value: this.state.proposalSchema.percentageSplitUp
              ? this.state.proposalSchema.percentageValue
              : this.state.proposalSchema.dollarValue,
          },
        },
      });
    } else if (buttonLabel === "save_draft") {
      this.saveDraft();
    } else if (buttonLabel === "send_estimate") {
      this.addEstimate();
    }
  };

  checkForFullSpitUp = () => {
    let splitAmounts = 0;
    this.state.proposalSchema.splitup.map((item) => {
      splitAmounts = +item.amount.amount;
    });
    // return splitAmounts
  };

  capitalizeFirstLetter = (str) => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  render() {
    let {
      isLoading,
      toast,
      estimate_lead_id,
      response,
      toastType,
      estimate_id,
      btnLoad,
      due_date,
      confirmation,
      phone,
      email,
      isOpenToggle,
      isOpenToggle1,
      isOpenToggle2,
      isOpenToggle3,
      lead_List,
      listsBoxOpen,
      listsLoad,
      proposalSchema,
      subject,
      ConfirmationBtnLoad,
      content,
      isOpenLead,
      selectedLead,
      isInputFocused,
      subjectModal,
      saveDraft,
    } = this.state;
    return isLoading ? (
      <div className="spin-loading">
        <Spinner type="border" color="#077e8b" />
      </div>
    ) : (
      <>
        <div style={{ backgroundColor: "#FAFAFA" }} fluid>
          {toast && <ToastList message={response} type={toastType} />}
          <div>
            <Breadcrumb></Breadcrumb>
          </div>
          {isOpenLead && (
            <LeadSelect
              isOpen={isOpenLead}
              leadClose={this.leadClose}
              selectlead={this.selectLeadFunction}
            />
          )}

          <div style={{ padding: "20px", backgroundColor: "#fafafa" }}>
            <div
              className="align-items-center"
              style={{ paddingBottom: "20px" }}
            >
              <div xs="8" className="manage-con">
                <div>
                  <Breadcrumb className="">
                    <BreadcrumbItem
                      onClick={() =>
                        this.props.history.push({
                          pathname: "estimates",
                          state: {
                            selectedTab: this.props.location?.state?.from,
                          },
                        })
                      }
                      className="bread-cursor"
                    >
                      Estimates
                    </BreadcrumbItem>
                    <BreadcrumbItem
                      style={{ color: "#72569c" }}
                      className="bread-cursor"
                    >
                      {estimate_id ? `${estimate_id}` : "New Estimate"}
                    </BreadcrumbItem>
                    {/* <BreadcrumbItem active>List</BreadcrumbItem> */}
                  </Breadcrumb>
                </div>
              </div>
              {/* <Col
              xs="4"
              className="manage-con flexy-content flexEnd"
              style={{ gap: "5%" }}
            >
              <div
                className="status_select ml-10"
                style={{ width: "29%" }}
              ></div>

            </Col> */}
            </div>
            <CustomListingContainer>
              <ListingHeaderLayout
                className="estimate_heading_font"
                label={"General info"}
                image={
                  isOpenToggle ? (
                    <img
                      src={Arrow}
                      alt="up arrow"
                      style={{ transform: "rotate(90deg)" }}
                    />
                  ) : (
                    <img
                      src={Arrow}
                      alt="up arrow"
                      style={{ transform: "rotate(270deg)" }}
                    />
                  )
                }
                imageClick={this.toggleCollapse}
              >
                <div className="sideView">
                  {/* DwonloAad */}
                  {this.state.downloadLinkbtn && (
                    <div
                      onClick={() => this.onDownload()}
                      className="sideViewContainer"
                    >
                      <Tooltip title="Download Document">
                        <img src={DownlaodIcon} alt="linkIcon" />
                      </Tooltip>
                    </div>
                  )}
                  {/* Link */}
                  {this.state.copyLinkbtn && (
                    <div
                      onClick={() => this.onLinkClick()}
                      className="sideViewContainer"
                    >
                      <Tooltip title="Copy Link">
                        <img src={LinkIcon} alt="linkIcon" />
                      </Tooltip>
                    </div>
                  )}

                  {/* Preview */}
                  {this.state.previewbtn && (
                    <Custom_Button
                      btnLabel="preview"
                      click={this.handlePreview}
                    />
                  )}
                  {/* {draft} */}
                  { (this.state.proposalSchema.status !== "sent") && (this.state.proposalSchema.status !=="accepted") && (this.state.status !== 'sent') &&  (
                    <>
                      <Custom_Button
                        isPrimary
                        isLoading={this.state.draftBtn}
                        btnLabel={
                          this.state.estimate_id ? "save_changes" : "save_draft"
                        }
                        click={this.saveDraft}
                      />
                    </>
                  )}

                  {/* send */}
                  {this.state.sendEstimateLinkbtn && (
                    <Custom_Button
                      isPrimary
                      btnLabel="send_estimate"
                      click={this.sendEmail}
                    />
                  )}
                </div>
              </ListingHeaderLayout>
              {isOpenToggle ? (
                <ListingBodyLayout
                  style={{ margin: "0 " }}
                  insideStyle={{
                    marginBottom: "0",
                    display: "flex",
                    justifyContent: "normal",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      borderRight: "0.5px solid #E1E8F0",
                      padding: "1.5rem",
                      width: "50%",
                    }}
                  >
                    <div className="bold_standard fw-500">
                      {this.state.contractor_name}
                    </div>
                    <div className="font_standard fw-400">
                      {this.state.contractor_email}
                    </div>
                    <div className="font_standard fw-400">
                      {this.state.contractor_phone
                        ? getUSFormat(this.state.contractor_phone)
                        : "-"}
                    </div>
                    <div className="font_standard fw-400">
                      {this.state.contractor_address}
                    </div>
                    <div className="font_standard fw-400">
                      {this.state.contractor_state}
                    </div>
                    <div className="font_standard fw-400">
                      {this.state.contractor_city} - {this.state.contractor_zip}
                    </div>
                  </div>
                  {!selectedLead || Object.keys(selectedLead).length === 0 ? (
                    <div className="flex_col" style={{ width: "50%" }}>
                      <Button
                        onClick={() => this.setState({ isOpenLead: true })}
                        size="sm"
                        className="button-wrapping"
                        style={{ backgroundColor: "#F7F8FA" }}
                      >
                        Select Lead
                      </Button>
                      <div
                        className="font_standard"
                        style={{ color: "#838383" }}
                      >
                        No leads selected yet. Select Lead to send estimate
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "space-between",
                        width: "50%",
                        padding: "1.5rem",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                        }}
                      >
                        {" "}
                        <div className="bold_standard fw-500">
                          {this.capitalizeFirstLetter(selectedLead?.name)}
                        </div>
                        <div className="font_standard fw-400">
                          {selectedLead?.email}
                        </div>
                        <div className="font_standard fw-400">
                          {selectedLead?.phone
                            ? getUSFormat(selectedLead?.phone)
                            : "-"}
                        </div>
                        <div className="font_standard fw-400">
                          {selectedLead?.address?.line1}
                        </div>
                      </div>
                      <div
                        style={{
                          cursor: "pointer",
                          lineHeight: "14px",
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "#72569C",
                        }}
                        onClick={() => this.setState({ isOpenLead: true })}
                      >
                        Change
                      </div>
                    </div>
                  )}
                </ListingBodyLayout>
              ) : (
                <></>
              )}
              {isOpenToggle && (
                <div
                  style={{
                    borderTop: "1px solid #E1E8F0",
                    backgroundColor: "white",
                    width: "100%",
                    padding: "0.75rem",
                    borderRadius: "0px 0px 10px 10px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-end",
                      width: "50%",
                    }}
                  >
                    {" "}
                    <textarea
                      className="estimateClassName"
                      ref={this.subjectRef}
                      style={{
                        height: this.state.subjectRefHeight,
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "#212121",
                      }}
                      onInput={this.updateSubject}
                      value={subject || ""}
                      onKeyDown={this.handleKeyDown}
                      onChange={this.handleNewInputChange}
                      placeholder="Enter your subject"
                      name="item"
                      maxLength={100}
                    />
                    {/* <Input
                      className="crm-form-control"
                      value={subject}
                      name="item_name"
                      id="input-first-name"
                      style={{ color: "#000000" }}
                      onChange={this.handleNewInputChange}
                      onFocus={this.handleInputFocus}
                      onBlur={this.handleInputBlur}
                      onKeyDown={(e) => {
                        this.handleKeyDown(e);
                      }}
                      placeholder="Enter Your Subject..."
                      type="text"
                    /> */}
                    {isInputFocused && <img src={tagTick} alt="tick" />}
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "400",
                        fontSize: "15px",
                        color: "#757575",
                      }}
                    >
                      {" "}
                      Due Date:
                    </div>
                    <CustomSingleDatePicker
                      className="without_borders"
                      value={due_date}
                      change={this.changeDueDate}
                    />
                  </div>
                </div>
              )}
            </CustomListingContainer>

            <CustomListingContainer style={{ marginTop: "18px" }}>
              <ListingHeaderLayout
                className="estimate_heading_font"
                label={"Items"}
                image={
                  isOpenToggle1 ? (
                    <img
                      src={Arrow}
                      alt="up arrow"
                      style={{ transform: "rotate(90deg)" }}
                    />
                  ) : (
                    <img
                      src={Arrow}
                      alt="up arrow"
                      style={{ transform: "rotate(270deg)" }}
                    />
                  )
                }
                imageClick={() => {
                  this.setState((prevState) => ({
                    isOpenToggle1: !prevState.isOpenToggle1,
                  }));
                }}
              ></ListingHeaderLayout>

              {isOpenToggle1 && (
                <TableFormatter rows={this.rowCell()}>
                  <tbody className="estimate_table">
                    {
                      // this.state.items.map((list, i) => (
                      proposalSchema.items.map((list, i) => (
                        <tr key={i}>
                          <td
                            className="cursor-point capitalize bl_none pad_none"
                            style={{ height: "50px" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              <img
                                style={{
                                  marginLeft: "24px",
                                  cursor: "pointer",
                                }}
                                src={removeList}
                                onClick={() => this.listChange(i, "remove")}
                                alt="list"
                              />
                              <div className="project_list_content">
                                <textarea
                                  className="estimateClassName"
                                  ref={this.textareaRefItem}
                                  style={{
                                    height: this.state.itemTextAreaHeight,
                                    lineHeight: "20px",
                                    fontSize: "13px",
                                    color: "#212121",
                                  }}
                                  onInput={this.updateItems}
                                  value={list.item_name || ""}
                                  onKeyDown={this.handleKeyDown}
                                  onChange={(e) => {
                                    this.updateListValues(
                                      i,
                                      "item_name",
                                      e.target.value
                                    );
                                  }}
                                  placeholder="Enter the item name"
                                  name="item"
                                  maxLength={60}
                                />
                              
                              </div>
                            </div>
                          </td>
                          <td
                            className="pad_none"
                            style={{ padding: "0px 23px" }}
                          >
                            <div className="project_list_content">
                              <textarea
                                className="estimateClassName"
                                ref={list.descriptionHeightRef}
                                style={{
                                  height: this.state.itemTextAreaDescHeight,
                                  lineHeight: "20px",
                                  fontSize: "13px",
                                  color: "#212121",
                                }}
                                onInput={() => this.updateDescItems(list)}
                                value={list.item_desc || ""}
                                onKeyDown={this.handleKeyDown}
                                onChange={(e) => {
                                  this.updateListValues(
                                    i,
                                    "item_desc",
                                    e.target.value
                                  );
                                }}
                                placeholder="Enter the description"
                                name="item"
                                maxLength={400}
                              />

                              {/* <Input
                                className="crm-form-control pdl-2 table_font"
                                value={list.item_desc || ""}
                                name="phone"
                                id="input-first-name"
                                style={{ fontWeight: "400", marginLeft: "7px" }}
                                onChange={(e) => {
                                  this.updateListValues(
                                    i,
                                    "item_desc",
                                    e.target.value
                                  );
                                }}
                                placeholder="Enter the description"
                                type="text"
                                maxLength="200"
                              /> */}
                            </div>
                          </td>

                          <td className="pad_none">
                            <div className="project_list_content">
                              <Input
                                className="crm-form-control table_font"
                                value={list.quantity}
                                name="quantity"
                                id="input-first-name"
                                style={{
                                  color: "#000000",
                                  textAlign: "center",
                                  paddingLeft: "1.5rem",
                                }}
                                onChange={(e) => {
                                  this.updateListValues(
                                    i,
                                    "quantity",
                                    e.target.value
                                  );
                                }}
                                placeholder="0"
                                type="number"
                                maxLength="16"
                              />
                            </div>
                          </td>

                          <td className="pad_none">
                            <div
                              style={{
                                position: "relative",
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="project_list_content pdl-2"
                            >
                              <div
                                style={{
                                  color: "#000000",
                                }}
                              >
                                $
                              </div>
                              <Input
                                className="crm-form-control table_font"
                                value={list.rate}
                                name="phone"
                                style={{
                                  // width:'50%',
                                  color: "#000000",
                                  paddingRight: "0.5rem",
                                  textAlign: "end",
                                  padding: "0px 20px 0px 0px",
                                }}
                                id="input-first-name"
                                onChange={(e) => {
                                  this.updateListValues(
                                    i,
                                    "rate",
                                    e.target.value
                                  );
                                }}
                                placeholder="0"
                                type="number"
                                maxLength="16"
                              />
                            </div>
                          </td>

                          <td
                            className="pad_none"
                            style={{ backgroundColor: "#F7F8FA" }}
                          >
                            <div
                              className="project_list_content table_font"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end",
                                paddingRight: "2.5rem",
                                color: "#000000",
                              }}
                            >
                              <span
                                style={{
                                  color: "#000000",
                                }}
                              >
                                $
                              </span>
                              {this.formatAmountWithCommas(
                                roundOff(list.rate * list.quantity).toFixed(
                                  2
                                ) || 0
                              )}
                            </div>
                          </td>
                        </tr>
                      ))
                    }
                    <tr>
                      <td
                        className="bl_none pad_none"
                        style={{ height: "50px" }}
                      >
                        <img
                          style={{ marginLeft: "24px", cursor: "pointer" }}
                          src={addList}
                          onClick={() => this.listChange(2, "add")}
                          alt="add list"
                        />
                      </td>
                      <td className="bl_none pad_none"></td>
                      <td className="pad_none"></td>
                      <td className="pad_none"></td>
                      <td
                        className="pad_none"
                        style={{ backgroundColor: "#F7F8FA" }}
                      ></td>
                    </tr>
                    <tr>
                      <td
                        className="pad_none"
                        style={{ border: "1px solid white", height: "53px" }}
                      ></td>
                      <td className="pad_none" style={{ border: "none" }}></td>
                      <td
                        className="pad_none"
                        style={{
                          border: "none",
                          borderLeft: "1px solid #f0f0f0",
                          borderBottom: "1px solid #f5f5f5",
                          paddingLeft: "3rem",
                          fontSize: "13px",
                          fontWeight: "600",
                          color: "#6A7785",
                        }}
                      >
                        Subtotal
                      </td>
                      <td
                        className="pad_none"
                        style={{
                          border: "none",
                          borderBottom: "1px solid #f5f5f5",
                        }}
                      ></td>
                      <td
                        className="pad_none"
                        style={{
                          borderBottom: "1px solid #f5f5f5",
                          backgroundColor: "#F7F8FA",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",

                          height: "53px",
                          padding: "1rem",
                          color: "#000000",
                          fontSize: "13px",
                          fontWeight: "500",
                          color: "#212121",
                          paddingRight: "2.5rem",
                        }}
                      >
                        {" "}
                        $
                        {this.formatAmountWithCommas(
                          this.calculateTotalAmount(
                            proposalSchema.items
                          ).toFixed(2)
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ border: "none" }} className="pad_none"></td>
                      <td style={{ border: "none" }} className="pad_none"></td>
                      <td
                        className="pad_none"
                        style={{
                          border: "none",
                          borderLeft: "1px solid #f0f0f0",
                          borderBottom: "1px solid #f5f5f5",
                          paddingLeft: "3rem",
                          fontSize: "13px",
                          fontWeight: "600",
                          color: "#6A7785",
                        }}
                      >
                        {" "}
                        Tax
                      </td>
                      <td
                        className="pad_none"
                        style={{
                          border: "none",
                          borderBottom: "1px solid #f5f5f5",
                          padding: "0.5rem",
                        }}
                      >
                        <div className=" percentage_select ml-10 ">
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div>
                              <Input
                                className="percentageInputbar"
                                style={{
                                  backgroundColor: "#F7F8FA",
                                  borderRadius: "5px 0px 0px 5px",
                                  borderRight: "none",
                                }}
                                value={
                                  proposalSchema.percentageValue ||
                                  proposalSchema.dollarValue
                                }
                                name="lead"
                                id="email"
                                type="number"
                                onChange={(e) => this.getTaxPercentage(e)}
                              />
                            </div>
                            <div className="percentageModule">
                              <UncontrolledDropdown
                                className="percentage_select_div"
                                style={{
                                  padding: "0",
                                  border: "1px solid #E6E6E6",
                                }}
                                group
                              >
                                <DropdownToggle
                                  style={{
                                    marginRight: "0px",
                                    padding: "10px",
                                    borderRadius: "0",
                                    backgroundColor: "#F7F8FA",
                                    borderRadius: "0px 4px 4px 0px",
                                  }}
                                  className="drop_down_container"
                                >
                                  <Media
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "10px",
                                    }}
                                    className="capitalize"
                                  >
                                    {proposalSchema.percentageSplitUp
                                      ? "%"
                                      : "$"}
                                    <i className="fas fa-angle-down"></i>
                                  </Media>
                                </DropdownToggle>
                                <DropdownMenu
                                  style={{
                                    minWidth: "3rem",
                                    padding: "0",
                                    textAlign: "center",
                                    backgroundColor: "#F7F8FA",
                                    height: "38px",
                                  }}
                                >
                                  {proposalSchema.percentageSplitUp ? (
                                    <DropdownItem
                                      className="drop_estimate"
                                      onClick={() =>
                                        this.updateProposalDetails({
                                          percentageSplitUp: 0,
                                        })
                                      }
                                    >
                                      $
                                    </DropdownItem>
                                  ) : (
                                    <DropdownItem
                                      className="drop_estimate"
                                      onClick={() =>
                                        this.updateProposalDetails({
                                          percentageSplitUp: 1,
                                        })
                                      }
                                    >
                                      %
                                    </DropdownItem>
                                  )}
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td
                        className="pad_none"
                        style={{
                          borderBottom: "1px solid #f5f5f5",
                          backgroundColor: "#F7F8FA",
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                          paddingRight: "2.5rem",
                          height: "60px",
                          fontSize: "13px",
                          fontWeight: "500",
                          color: "#212121",
                        }}
                      >
                        $
                        {proposalSchema.percentageSplitUp
                          ? this.formatAmountWithCommas(
                              (
                                this.calculateTotalAmount(
                                  proposalSchema.items
                                ) *
                                (proposalSchema.percentageValue / 100)
                              ).toFixed(2)
                            )
                          : this.formatAmountWithCommas(
                              roundOff(+proposalSchema.dollarValue)
                            )
                          ? this.formatAmountWithCommas(
                              roundOff(+proposalSchema.dollarValue)
                            )
                          : 0}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{ border: "none", paddingLeft: "2rem" }}
                        className="pad_none"
                      ></td>
                      <td style={{ border: "none" }} className="pad_none"></td>
                      <td
                        style={{
                          border: "none",
                          borderLeft: "1px solid #f0f0f0",
                          color: "#212121",
                          fontWeight: "500",
                          fontSize: "16px",
                          height: "46px",
                          paddingLeft: "3rem",
                          fontSize: "13px",
                          fontWeight: "600",
                        }}
                      >
                        {" "}
                        Total
                      </td>
                      <td style={{ border: "none" }}></td>
                      <td
                        style={{
                          backgroundColor: "#F7F8FA",
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                          paddingRight: "2.5rem",
                          color: "black",
                          fontWeight: "500",
                          fontSize: "16px",
                          height: "46px",
                          fontSize: "13px",
                          fontWeight: "600",
                          color: "#212121",
                        }}
                      >
                        {" "}
                        <span style={{ color: "#000000" }}>$</span>
                        {this.formatAmountWithCommas(
                          roundOff(this.calculateTotal())
                        )}
                      </td>
                    </tr>
                  </tbody>
                </TableFormatter>
              )}
            </CustomListingContainer>

            <CustomListingContainer style={{ marginTop: "18px" }}>
              <ListingHeaderLayout
                className="estimate_heading_font"
                label={"payments"}
                image={
                  isOpenToggle2 ? (
                    <img
                      src={Arrow}
                      alt="up arrow"
                      style={{ transform: "rotate(90deg)" }}
                    />
                  ) : (
                    <img
                      src={Arrow}
                      alt="up arrow"
                      style={{ transform: "rotate(270deg)" }}
                    />
                  )
                }
                imageClick={() =>
                  this.setState((prevState) => ({
                    isOpenToggle2: !prevState.isOpenToggle2,
                  }))
                }
                value={this.calculateTotal()}
                primary_buttonLabel={
                  this.totalSplitUpAmount === this.calculateTotal()
                    ? ""
                    : "schedule_payment"
                }
                isPrimary={false}
                click={() => this.setState({ open: true })}
              ></ListingHeaderLayout>
              {isOpenToggle2 &&
                (proposalSchema.splitup &&
                proposalSchema?.splitup.length > 0 ? (
                  <Row>
                    <div className="col new-table">
                      <Card className="shadow card_with_crm">
                        <Table
                          className="align-items-center table-flush"
                          responsive
                        >
                          <thead className="thead-light">
                            <tr className="split_up ">
                              <th
                                style={{
                                  width: "3%",
                                  borderColor: "#f5f5f5",
                                }}
                                id="splitup_table_header"
                                scope="col"
                                className="crm "
                              >
                                S.No
                              </th>
                              <th
                                id="splitup_table_header"
                                style={{
                                  width: "20%",
                                  borderColor: "#f5f5f5",
                                  padding: "0.7rem",
                                }}
                                scope="col"
                                className="crm splitup_table_header"
                              >
                                Name
                              </th>

                              <th
                                id="splitup_table_header"
                                style={{
                                  width: "20%",
                                  borderColor: "#f5f5f5",
                                  paddingLeft: "0",
                                }}
                                scope="col splitup_table_header"
                              >
                                Created At
                              </th>
                              <th
                                id="splitup_table_header"
                                style={{
                                  width: "20%",
                                  borderColor: "#f5f5f5",
                                  paddingLeft: "0",
                                }}
                                scope="col splitup_table_header"
                              >
                                Due Date
                              </th>
                              <th
                                id="splitup_table_header"
                                style={{
                                  width: "20%",
                                  borderColor: "#f5f5f5",
                                  paddingLeft: "0",
                                }}
                                scope="col splitup_table_header"
                              >
                                Amount
                              </th>
                              <th
                                id="splitup_table_header"
                                style={{
                                  width: "10%",
                                  borderColor: "#f5f5f5",
                                  paddingLeft: "0",
                                }}
                                scope="col splitup_table_header"
                              >
                                Status
                              </th>
                              <th
                                id="splitup_table_header"
                                style={{
                                  width: "60%",
                                  cursor: "pointer",
                                  fontWeight: "500",
                                  color: "#338180",
                                  borderColor: "#f5f5f5",
                                }}
                                scope="col"
                                onClick={this.redirectToListWithQuery}
                              >
                                {this.state.items.length ? <div></div> : <></>}
                              </th>
                            </tr>
                          </thead>
                          {proposalSchema.splitup &&
                          proposalSchema.splitup.length !== 0 ? (
                            <tbody>
                              {proposalSchema.splitup.map((splitUp, i) => (
                                <tr key={i}>
                                  <td
                                    style={{ paddingLeft: "2rem" }}
                                    className="cursor-point capitalize"
                                  >
                                    <div className="splitup_cell">{i + 1}</div>
                                  </td>
                                  <td
                                    className="cursor-point capitalize "
                                    style={{ paddingLeft: "1.5rem" }}
                                  >
                                    <div className="splitup_cell">
                                      {splitUp.name}
                                    </div>
                                  </td>
                                  <td className="ptb20">
                                    <div
                                      className="splitup_cell"
                                      style={{ paddingLeft: "1.3rem" }}
                                    >
                                      {" "}
                                      {showUtcDate(splitUp.createdAt)}
                                    </div>
                                  </td>

                                  <td className="ptb20">
                                    <div
                                      className="splitup_cell"
                                      style={{ paddingLeft: "1.3rem" }}
                                    >
                                      {" "}
                                      {showUtcDate(splitUp.due_date)}
                                    </div>
                                  </td>

                                  <td className="ptb20">
                                    <div
                                      className="splitup_cell"
                                      style={{ paddingLeft: "1.5rem" }}
                                    >
                                      {" "}
                                      $
                                      {this.formatAmountWithCommas(
                                        roundOff(splitUp?.amount?.amount)
                                      )}
                                    </div>
                                  </td>
                                  <td className="ptb20">
                                    <div
                                      className="project_list_status"
                                      style={{
                                        paddingLeft: "1.4rem",
                                        color: `${
                                          splitUp.status === "completed" ||
                                          splitUp.status === "approved"
                                            ? "#07A84E"
                                            : splitUp.status === "pending"
                                            ? "#FF4949"
                                            : splitUp.status === "due"
                                            ? "#0070FD"
                                            : splitUp.status === "rejected"
                                            ? "#F70e0e"
                                            : "#FFCC3D"
                                        }`,
                                      }}
                                    >
                                      {remove_underscore_capitalize(
                                        splitUp.status || "pending"
                                      )}
                                    </div>
                                  </td>

                                  <td
                                  // style={{
                                  //   display: "flex",
                                  //   justifyContent: "flex-end",
                                  //   borderTop: "none",
                                  //   borderBottom: "1px solid #f5f5f5",
                                  //   width: "100%",
                                  // }}
                                  >
                                    <div>
                                      <UncontrolledDropdown
                                        nav
                                        className="flexy-content position_u"
                                      >
                                        <DropdownToggle
                                          className="pr-0 nav-notice"
                                          nav
                                        >
                                          <Media className="align-items-center">
                                            <img
                                              src={showMore}
                                              alt="show more"
                                            />
                                          </Media>
                                        </DropdownToggle>
                                        <DropdownMenu
                                          className="dropdown-menu-arrow min-width-drop"
                                          right
                                        >
                                          <DropdownItem
                                            onClick={() =>
                                              this.setState({
                                                open: true,
                                                editSplitup: true,
                                                editSplitupDetail: splitUp,
                                                dummyIndex: i,
                                              })
                                            }
                                          >
                                            <span>Edit</span>
                                          </DropdownItem>
                                          <DropdownItem
                                            onClick={() =>
                                              this.deleteEstimate(
                                                i,
                                                splitUp.splitup_id
                                              )
                                            }
                                          >
                                            <span>Delete</span>
                                          </DropdownItem>

                                          {/* 
                                <DropdownItem
                                  onClick={() =>
                                    this.setState({
                                      openModal: true,
                                      delete_data: project,
                                      project_id: project.project_id,
                                    })
                                  }
                                >
                                  <span>Delete</span>
                                </DropdownItem> */}
                                        </DropdownMenu>
                                      </UncontrolledDropdown>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          ) : (
                            <tbody>
                              <tr>
                                <td className="no-data">
                                  No splitups Available
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                            </tbody>
                          )}
                        </Table>
                      </Card>
                    </div>
                  </Row>
                ) : (
                  <>
                    <div className="emptySplitUp">
                      <div class="first">No Payment Schedule</div>
                      <div class="second">
                        Create a schedule of payments for one-time line items
                        totalling
                      </div>
                    </div>
                  </>
                ))}
            </CustomListingContainer>
            <CustomListingContainer style={{ marginTop: "18px" }}>
              <ListingHeaderLayout
                className="estimate_heading_font"
                label={"Terms and Conditions"}
                image={
                  isOpenToggle3 ? (
                    <img
                      src={Arrow}
                      alt="up arrow"
                      style={{ transform: "rotate(90deg)" }}
                    />
                  ) : (
                    <img
                      src={Arrow}
                      alt="up arrow"
                      style={{ transform: "rotate(270deg)" }}
                    />
                  )
                }
                imageClick={() => {
                  this.setState((prevState) => ({
                    isOpenToggle3: !prevState.isOpenToggle3,
                  }));
                }}
              ></ListingHeaderLayout>
              <ListingBodyLayout
                style={{ margin: "0 " }}
                insideStyle={{
                  marginBottom: "0",
                  display: "flex",
                  justifyContent: "normal",
                  width: "100%",
                }}
              >
                {isOpenToggle3 && (
                  <CardBody style={{ paddingLeft: "1.4rem" }}>
                    <Form>
                      <div>
                        <Row>
                          <Col lg="12">
                            <div
                              className="crm_comments_input"
                              style={{ marginTop: "0px", padding: "0px" }}
                            >
                              <div className=" comment_footer">
                                <div
                                  className="ft_1"
                                  style={{ backgroundColor: "transparent" }}
                                >
                                  <textarea
                                    ref={this.textareaRef}
                                    style={{
                                      backgroundColor: "transparent",
                                      fontSize: "13px",
                                      color: "#212121",
                                    }}
                                    onInput={this.update}
                                    value={this.state.inputMessage}
                                    onKeyDown={this.handleKeyDown1}
                                    placeholder="Type here..."
                                  />
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Form>
                  </CardBody>
                )}
              </ListingBodyLayout>
            </CustomListingContainer>
          </div>

          <Card
            className={this.state.open ? "exportSideBar" : "toggle-sidebar"}
          >
            {this.state.open && (
              <>
                <AddSchedules
                  props={this}
                  data={proposalSchema}
                  close={this.onClose}
                  amount={this.calculateTotal()}
                  populateObj={this.constructObject}
                  editSplitup={{
                    edit: this.state.editSplitup,
                    detail: this.state.editSplitupDetail,
                  }}
                  remainingValue={this.state.remainingValue}
                  cancel={() =>
                    this.setState({ open: false, editSplitup: false })
                  }
                  splitup={this.state.proposalSchema.splitup}
                ></AddSchedules>
              </>
            )}
          </Card>
          <Modal
            isOpen={this.state.emailModal}
            centered="true"
            style={{ minWidth: "25rem", padding: "2rem" }}
            toggle={() => this.setState({ emailModal: false })}
          >
            <div style={{ padding: "24px" }}>
              <div style={{ color: "#333333" }}>
                Estimate to{" "}
                <span style={{ color: "#7045B0" }}>
                  {this.state.selectedLead?.name}
                </span>{" "}
              </div>
              <div style={{ marginTop: "24px" }}>
                <label className="customModalLabel">Subject*</label>
                <CustomInputFields
                  style={{ marginBottom: "20px" }}
                  inputValue={subjectModal}
                  type="text"
                  change={this.handleInputChange}
                  from="subjectModal"
                  disabled={this.state.isNameAlreadyPresent}
                  placeHolder="Enter the subject"
                />
                <label className="customModalLabel">Content*</label>
                <CustomTextArea
                  inputValue={content}
                  change={this.handleInputChange}
                  from="content"
                  placeHolder="Enter the content"
                />
              </div>
              <div className="emailModalFooter">
                <div className="copyLink">
                  <img src={copylink} alt="cpoylink" />
                  <div
                    style={{
                      color: "#72569C",
                      fontSize: "13px",
                      marginLeft: "5px",
                    }}
                  >
                    Copy link
                  </div>
                </div>
                <div>
                  <Custom_Button
                    isPrimary
                    btnLabel="send"
                    isLoading={this.state.emailLoadbtn}
                    click={this.sendEmail}
                  />
                </div>
              </div>
            </div>
          </Modal>
          {/* <Card
            className={
              this.state.emailModal ? "exportSideBar" : "toggle-sidebar"
            }
          >
            {this.state.emailModal && (
              <>
                <EmailModal
                  props={this}
                  sendData={this.receivedData}
                  close={this.onClose}
                  cancel={() => this.setState({ emailModal: false })}
                ></EmailModal>
              </>
            )}
          </Card> */}

          <Card className={confirmation ? "exportSideBar" : "toggle-sidebar"}>
            {confirmation && (
              <>
                <CardHeader>
                  <div className="flex">
                    <div className="fw-500 f16 cl000000"></div>
                    <img
                      src={closeIcon}
                      onClick={() => this.props.close()}
                      className="point"
                    />
                  </div>
                </CardHeader>
                <div className="cardContainer">
                  <div>
                    <div
                      className="cl2A1E17 mb-15 f14 fw-400 drawer_sub_heading"
                      style={{ marginTop: "15px" }}
                    >
                      Subject*
                    </div>
                    <Input
                      style={{
                        fontSize: "14px",
                        color: "#212529",
                        border: "1px solid #f0f0f0",
                      }}
                      type="text"
                      value={subject}
                      name="name"
                      onChange={(e) => {
                        this.setState({ subject: e.target.value });
                      }}
                    />
                  </div>
                  <div>
                    <div
                      className="cl2A1E17 mb-15 f14 fw-400 drawer_sub_heading"
                      style={{ marginTop: "15px" }}
                    >
                      Content*
                    </div>
                    <div>
                      {" "}
                      <textarea
                        className="text-area-add-project"
                        value={content}
                        style={{
                          fontSize: "14px",
                          color: "#212529",
                          border: "1px solid #f0f0f0",
                          color: "#212121",
                        }}
                        maxLength={500}
                        name="notes"
                        onChange={(e) => {
                          this.setState({ content: e.target.value });
                        }}
                      />
                    </div>
                  </div>
                  <div style={{ marginTop: "30px" }} className="flexEnd">
                    <div
                      className="mr-20 point f14 button-secondary"
                      style={{ fontWeight: "500" }}
                      onClick={() => this.props.close()}
                    >
                      Cancel
                    </div>
                    <Button
                      color="primary"
                      className="button-wrapping "
                      size="sm"
                      onClick={() => this.updateContent()}
                      disabled={this.state.isApiCallPending}
                    >
                      {ConfirmationBtnLoad ? (
                        <div className="spin-loading-save w100 h100">
                          <Spinner
                            style={{
                              height: "20px",
                              width: "20px",
                            }}
                            type="border"
                            color="#0A8080"
                          />
                        </div>
                      ) : (
                        "Send"
                      )}
                    </Button>
                  </div>
                </div>
              </>
            )}
          </Card>
        </div>
      </>
    );
  }
}

export default AddEstimate;
