import Line from "../../assets/img/brand/removeIcon.svg";
import { useState } from "react";
import { getCloudinaryImage } from "constants/utils";
import ApiService from "constants/ApiService";
import { showSuccess } from "constants/utils";
import { showError } from "constants/utils";
import docFile from "../../assets/img/brand/docfile.svg";
import { Modal } from "reactstrap";
import { Button_without_Border } from "components/Buttons/Button";
import { Custom_Button } from "components/Buttons/Button";

export function AddDocument({
    document,
    completed,
    props,
}) {
    const [deleteDocModal, setDeleteDocModal] = useState(false);
    const [deleteDocId, setDeleteDocId] = useState("");
    const [btnLoad, setBtnLoad] = useState(false);

    const deleteConfirmation = (e, id) => {
        setDeleteDocModal(true);
        setDeleteDocId(id)
    }
    const remove = async (document_id) => {
        setBtnLoad(true)
        let response = await ApiService.deleteAPI(`contractor/project/document/${document_id}`);
        if (response.success) {
            setBtnLoad(false)
            setDeleteDocModal(false)
            setDeleteDocId("")
            showSuccess(props, response.message);
            completed()
        } else {
            showError(props, response.message);
            setBtnLoad(false)
        }
    }
    return (
        <div className="border-dashed border-[1px] rounded-[10px] px-[10px] py-[20px]">
            {document && document.length > 0 ? (
                <div className="flex p-[0px] relative">
                    <div className="mr-[15px] flex-wrap" style={{ display: "flex" }}>
                        {document &&
                            document.map((item, i) => (
                                <div key={i} className="relative" style={{ marginBottom: "10px" }} >
                                    {!["png", "jpg", "jpeg"].includes(item?.document?.format)?
                                        <div className="text-center point sideWithDoc m_0 image_container" style={{ height: "130px", marginRight: "10px" }}>
                                            <img src={Line} className="hideDelete point"
                                                style={{ top: "-7%", position: "absolute", left: "80%", width: "22px" }}
                                                onClick={() => deleteConfirmation(i, item.document_id)}
                                            />
                                            <a target="_blank" href={getCloudinaryImage(120, 130, item?.document, "c_fill")}>
                                                <img src={docFile} style={{ width: "50px", marginTop: "7px" }} />
                                                <p className={`cl72569C uploadFile`} style={{ wordWrap: "break-word" }}> {item?.name}</p>
                                            </a>
                                        </div>
                                        :
                                        <div className="image_container">
                                            <img src={Line} className="w-[10px] h-[10px] hideDelete point"
                                                style={{ top: "-7%", position: "absolute", left: "80%", width: "22px" }}
                                                onClick={() => deleteConfirmation(i, item.document_id)}
                                            />
                                            <a target="_blank" href={getCloudinaryImage(120, 130, item?.document, "c_fill")}>
                                                <img
                                                    src={getCloudinaryImage(120, 130, item?.document, "c_fill")}
                                                    className="preview-img w-[100px] h-[120px] rounded-[5px] object-fill cursor-pointer"
                                                    style={{ marginRight: "10px", borderRadius: "7px", border: "1px solid #CECECE" }}
                                                />
                                            </a>
                                        </div>
                                    }
                                </div>
                            ))}
                    </div>
                </div>
            ) : (
                "Neither documents nor photos found"
            )
            }

            <Modal
                isOpen={deleteDocModal}
                centered={true}
                style={{ minWidth: "35rem", padding: "2rem" }}
            >
                <div style={{ padding: "20px", height: "210px" }}>
                    <div className="f20" style={{ color: "black" }}>
                        File Delete
                    </div>
                    {/*  */}
                    <p style={{ marginTop: "8px", fontSize: "15px" }}> Are you sure you want to delete this file?</p>
                    <div style={{ marginTop: "60px" }} className="flexEnd">
                        <Button_without_Border
                            btnLabel={"Cancel"}
                            style={{ marginRight: "20px" }}
                            click={() => setDeleteDocModal(false)}
                        />
                        <Custom_Button
                            style={{ minWidth: "50px" }}
                            isPrimary={true}
                            isLoading={btnLoad}
                            btnLabel={"Ok"}
                            click={() => remove(deleteDocId)}
                        />
                    </div>
                </div>
            </Modal>
        </div >
    );
};
