import React from "react";
import { CardHeader } from "reactstrap";

import { Custom_Button } from "components/Buttons/Button";

import white_close_icon from "../../assets/img/icons/white_close_icon.svg";
import { remove_underscore_capitalize } from "constants/utils";
import { Button_without_Border } from "components/Buttons/Button";

const CustomModal = ({
  type,
  children,
  create,
  edit,
  close,
  isApiCallPending,
  onlyView = false,
}) => (
  <>
    <CardHeader style={{ backgroundColor: "#72569C" }}>
      <div className="flex">
        <div className="fw-500 f16" style={{color:'white'}}>{remove_underscore_capitalize(type)}</div>
        <img
          src={white_close_icon}
          onClick={close}
          className="point"
          alt="Close"
        />
      </div>
    </CardHeader>

    <div style={{ padding: "20px" }}>
      {children}
      {!onlyView && (
        <div className="flexEnd" style={{ marginTop: "40px" }}>
          <Button_without_Border style={{marginRight:'20px'}} btnLabel={"Cancel"} click={close} />
          <Custom_Button
            isPrimary={true}
            isLoading={isApiCallPending}
            btnLabel={edit ? "Update" : "Save"}
            click={create}
          />
        </div>
      )}
    </div>
  </>
);

export default CustomModal;
