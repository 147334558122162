import React, { Component } from "react";

import {
  Card,
  Table,
  Container,
  Row,
  Breadcrumb,
  Col,
  Button,
  InputGroup,
  Spinner,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
// core components
import ApiService from "constants/ApiService";
import { returnFirstDegreeObjValue } from "constants/utils";
import returnSecondDegreeObjValue from "constants/utils";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { filterQuery } from "constants/utils";
import { displayPrice } from "constants/utils";
import { showUtcDate } from "constants/utils";
import { getIsoString } from "constants/utils";
import { showError } from "constants/utils";
import { displayPercentage } from "constants/utils";
import { pushToFilteredPage } from "constants/utils";
import { returnSearchObject } from "constants/utils";
import { buildQueryString } from "constants/utils";
import ToastList from "components/Toast/ToastList";
import showMore from "../../assets/img/brand/showMore.svg";
import projectTimeline from "../../../src/assets/img/brand/projectTimeline.svg";

let manager_id;
class ProjectTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payoutList: [],
      response: "",
      total: 0,
      filterToggle: false,
      from: "",
      to: "",
      page: 1,
      inputSearch: "",
      filterOption: "",
      statusSearch: "",
      modalOpen: false,
      isLoading: true,
      invoiceDetail: {},
      lateFee: 0,
      payoutModal: false,
      payoutData: "",
      query: {},
      toast: false,
      toastType: "",
    };
  }

  componentDidMount() {
    this.getpayoutList();
  }
  onChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  getpayoutList = async () => {
    let response = await ApiService.getAPI("contractor/payout?page=1&&limit=8");

    if (response.success) {
      let { payouts } = response.data;
      this.setState({ payoutList: payouts, isLoading: false });
    }
  };
  updateStateValues = (key, value) => {
    this.setState(
      {
        [key]: value,
      },
      function () {
        if (value === null) {
          this.setState({
            [key]: "",
          });
        }
      }
    );
  };
  handleOnToChange = (e) => {
    this.updateStateValues("to", e ? new Date(e) : '');
  };
  handleOnFromChange = (e) => {
    this.updateStateValues("from", e ? new Date(e) : '');
  };
  handleIncrementPage = () => {
    if (this.state.payoutList.length >= 10) {
      this.setState({ page: this.state.page + 1 }, () =>
        this.handleFilterStatus()
      );
    }
  };

  capitalizeFirstLetter = (str) => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  handleDecrementPage = () => {
    if (this.state.page > 1) {
      this.setState({ page: this.state.page - 1 }, () =>
        this.handleFilterStatus()
      );
    }
  };

  handleFilterStatus = () => {
    let staticQuery = returnSearchObject(this.props);
    staticQuery.from = getIsoString(this.state.from) ?? "";
    staticQuery.to = getIsoString(this.state.to) ?? "";
    staticQuery.status = this.state.statusSearch ?? "";
    staticQuery.page = this.state.page ?? "";
    // if(!(staticQuery.from || staticQuery.to)) return showError(this,'Please select from and to dates')
    if (!(staticQuery.from || staticQuery.to))
      return showError(this, "Please select from and to dates");
    this.setState({ query: staticQuery }, () => {
      pushToFilteredPage(this.props, { ...this.state.query });
      this.getpayoutList();
    });
  };

  handleSearch = () => {
    this.setState({ page: 1 }, () => {
      this.handleFilterStatus();
    });
  };

  handleReset = () => {
    let self = this;
    self.setState(
      {
        from: "",
        to: "",
        page: 1,
        limit: 10,
        query: {
          page: 1,
          limit: 10,
        },
      },
      () => {
        pushToFilteredPage(this.props, { ...this.state.query });
        this.getpayoutList();
      }
    );
  };
  toggleModal = (invoice_id) => {
    this.setState(
      {
        modalOpen: !this.state.modalOpen,
      },
      () => this.state.modalOpen && this.getInvoiceDetail(invoice_id)
    );
  };
  getInvoiceDetail = async (id) => {
    let response = await ApiService.getAPI(`host/invoice/${id}`);
    if (response.success) {
      this.setState({
        invoiceDetail: response.data.invoices,
      });
    }
  };
  handleCloseModal = () => {
    this.setState({ modalOpen: false });
  };
  handleSubLateFee = (amount, base_price) => {
    let lateFee = amount - base_price;
    this.setState({
      lateFee,
    });
  };

  setStatus = (status) => {
    this.setState({ statusSearch: status });
  };

  openPayoutDetails = (data) => {
    this.setState({ payoutData: data, payoutModal: true });
  };
  closePayoutSideBar = () => {
    this.setState({ payoutData: "", payoutModal: false });
  };

  render() {
    let { toast, response, toastType } = this.state;
    const selectionRange = {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    };
    let { payoutList, isLoading, page, payoutModal, payoutData } = this.state;
    return isLoading ? (
      <div className="spin-loading">
        <Spinner type="border" color="#2e1a47" />
      </div>
    ) : (
      <>
        <div
          className="col new-table"
          style={{ paddingLeft: "0", paddingRight: "0" }}
        >
          <Card className="shadow card_with_crm">
            {payoutList.length !== 0 ? (
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th  scope="col" style={{paddingLeft:"1.5rem"}}>
                      Client
                    </th>
                    <th  scope="col" className="">
                      Due On
                    </th>
                    <th scope="col" className="">
                      Amount
                    </th>
                    <th scope="col">
                      Status
                    </th>
                    <th className="" style={{width:'6.2%'}} scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {payoutList.map((payout, i) => (
                    <tr
                      key={i}
                      className="point "
                      onClick={() =>
                        payout.items.length > 1 &&
                        this.openPayoutDetails(payout)
                      }
                    >
                      {/* <td>
                            {returnFirstDegreeObjValue(payout, "payout_id")}
                          </td> */}
                      <td  style={{paddingLeft:"1.5rem"}}>Joseph kuruvilla</td>
                      <td className="">
                        {" "}
                        {showUtcDate(
                          returnFirstDegreeObjValue(payout, "createdAt")
                        )}
                      </td>

                      <td className="">
                        {displayPrice(
                          returnFirstDegreeObjValue(payout, "overall_amount")
                        )}
                      </td>
                      <td
                        style={{
                          color:
                            payout.status === "processed" ? "#429441" : "black",fontWeight:"500"
                        }}
                      >
                        {this.capitalizeFirstLetter(payout.status)}
                      </td>

                      <td
                     
                        style={{ borderBottom: "1px solid #f5f5f5" }}
                      >
                        <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',gap:"10px"}}>
                          <img
                            alt="timeline"
                            style={{ cursor: "pointer" }}
                            src={projectTimeline}
                          ></img>
                          <div className="">
                            <UncontrolledDropdown
                              nav
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <DropdownToggle className="pr-0 nav-notice" nav>
                                <img src={showMore} alt="show more" />
                              </DropdownToggle>
                              <DropdownMenu
                                className="dropdown-menu-arrow min-width-drop"
                                right
                              >
                                <DropdownItem
                                // onClick={() => this.editProject(project)}
                                >
                                  <span>Timeline</span>
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <>
                {" "}
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Title</th>
                      <th scope="col">Paid On</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Status</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                </Table>
                <Card className="card_with_paginator no_data_card">
                  {" "}
                  <div className="no-data">No Payments Available</div>
                </Card>
              </>
            )}
          </Card>
        </div>
      </>
    );
  }
}

export default ProjectTable;
