import React, { Component } from "react";
import { Button, Modal } from "reactstrap";

import "react-datepicker/dist/react-datepicker.css";

export default class ClientStatusModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  capitalizeFirstLetter = (str) => {
    if(!str) return ""
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  render() {
    let { toggleModal, closeModal, modalState, name, onSubmit } = this.props;
    return (
      <Modal
        className="modal-dialog-centered"
        isOpen={modalState}
        toggle={toggleModal}
      >
        <>
          <div className="modal-header modal-invite">
            <h5
              className="modal-title"
              id="exampleModalLabel"
              style={{ marginLeft: "8rem" }}
            >
              {`Terminate  Client?`}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={closeModal}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <span className="pad-invite" style={{ textAlign: "center" }}>
            {`Are you sure you want to terminate the Client - ${
              name
            } ?`}
          </span>

          <div className="modal-footer" style={{ marginRight: "9rem" }}>
            <div className="add-filter-wrap ">
              <div
                className="filter-wrap mr-3 edit-prop change-profile-pass "
                onClick={closeModal}
              >
                <label className="profile-cancel">Cancel</label>
              </div>
            </div>
            <Button
              color="primary"
              type="button"
              onClick={onSubmit}
              className="button-wrapping change-profile-button"
            >
              Yes
            </Button>
          </div>
        </>
      </Modal>
    );
  }
}
