import React, { Component } from "react";
import {
  Badge,
  Card,
  Table,
  Container,
  Row,
  Breadcrumb,
  Col,
  Button,
  InputGroup,
  InputGroupText,
  CardBody,
  Spinner,
  DropdownMenu,
  DropdownItem,
  Media,
  UncontrolledDropdown,
  DropdownToggle,
  Dropdown,
  Input,
  Modal,
} from "reactstrap";
// core components
import ApiService from "constants/ApiService";
import { returnFirstDegreeObjValue } from "constants/utils";
import { showUtcDate } from "constants/utils";
import ToastList from "components/Toast/ToastList";
import { showError } from "constants/utils";
import { returnSearchObject } from "constants/utils";
import { pushToFilteredPage } from "constants/utils";
import { buildQueryString } from "constants/utils";
import { CustomListingContainer } from "components/Container/CustomListingContainer";
import { ListingHeaderLayout } from "components/Container/CustomListingContainer";
import { CustomSearch } from "components/FilterFields/Filterfields";
import { Button_without_Border } from "components/Buttons/Button";
import AddOtherPros from "./addOtherpros"
import projectTimeline from "../../assets/img/brand/projectTimeline.svg";
import showMore from "../../assets/img/brand/showMore.svg";
import { showSuccess } from "constants/utils";
import closeIcon from "../../assets/img/icons/closeIcon.svg";
import { Custom_Button } from "components/Buttons/Button";
class OtherProsContractors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contractorList: [],
      page: 1,
      inputSearch: "",
      filterOption: "name",
      toast: false,
      response: "",
      toastType: "",
      isLoading: true,
      query: {},
      input_value: false,
      timoutId: null,
      filters: ["name", "email"],
      openOtherModel: false,
      addMemberModal: false,
      toast: false,
      toastType: "",
      setData: "",
      isApiCallPending: false,
    };
  }
  componentDidMount = async () => {
    this.setQueryStatus();
  };
  componentWillUnmount = () => {
    clearTimeout(this.state.timoutId);
  };

  returnCurrentFilterOption = (obj) => {
    return (
      this.state.filters.filter((ele) => Object.keys(obj).includes(ele))[0] ??
      ""
    );
  };

  initCheckClerFilters = () => {
    this.state.filters.includes(this.state.filterOption) ||
      this.state.statusSearch ||
      this.state.inputSearch ||
      this.state.page > 1
      ? this.setState({ input_value: true })
      : this.setState({ input_value: false });
  };

  setQueryStatus = () => {
    let queryStatus = this.props.location.search;
    let queryObj = returnSearchObject(queryStatus);
    if (queryStatus) {
      this.setState(
        {
          inputSearch: queryObj[this.returnCurrentFilterOption(queryObj)] ?? "",
          filterOption: queryObj.name ? "name" : queryObj.email ? "email" : "name",
          page: parseInt(queryObj.page) ?? "",
          query: returnSearchObject(queryStatus),
        },
        () => {
          this.initCheckClerFilters();
          pushToFilteredPage(this.props, { ...this.state.query });
          this.getActiveContractors();
        }
      );
    } else {
      this.setState(
        {
          query: {
            ...this.state.query,
            page: 1,
            limit: 10,
          },
        },
        () => {
          pushToFilteredPage(this.props, this.state.query);
          this.getActiveContractors();
        }
      );
    }
  };

  getActiveContractors = async () => {
    let response = await ApiService.getAPI(
      `contractor/active${buildQueryString(this.state.query)}`
    );

    if (response.success) {
      let { data } = response;
      this.setState({
        contractorList: data,
        isLoading: false,
      });
    } else {
      this.setState({ isLoading: false })
    }
  };

  checkForClearFilter = () => {
    this.setState({
      input_value:
        !this.state.statusSearch &&
          (this.state.filterOption === "name" || !this.state.filterOption) &&
          !this.state.inputSearch
          ? false
          : true,
    });
  };

  onChangeHandler = (e, from) => {
    this.setState({ [from]: e === "select" ? "" : e, input_value: true },
      () => {
        this.checkForClearFilter();
      });
    clearTimeout(this.state.timoutId);
    const timoutId = setTimeout(() => {
      this.state.filterOption && this.handleSearch();
    }, 1000);
    this.setState({ timoutId });
  };

  handleIncrementPage = () => {
    if (this.state.contractorList?.length >= 10) {
      this.setState({ page: this.state.page + 1, input_value: true }, () =>
        this.handleFilterStatus()
      );
    }
  };
  handleDecrementPage = () => {
    if (this.state.page > 1) {
      this.setState(
        {
          page: this.state.page - 1,
          input_value: this.state.page - 1 === 1 ? false : true,
        },
        () => this.handleFilterStatus()
      );
    }
  };

  handleFilterStatus = () => {
    let staticQuery = returnSearchObject(this.props.location.search);
    // if (this.state.inputSearch !== "" && this.state.filterOption !== "") {
    for (let key in staticQuery) {
      if (key === "email" || key === "name") {
        delete staticQuery[key];
      }
    }
    staticQuery[this.state.filterOption] = this.state.inputSearch;
    // }
    staticQuery.page = this.state.page ?? "";
    this.setState({ query: staticQuery }, () => {
      if (this.state.filterOption !== "select") {
        pushToFilteredPage(this.props, { ...this.state.query });
        this.getActiveContractors();
      }
    });
  };

  handleSearch = () => {
    this.setState({ page: 1 }, () => {
      this.handleFilterStatus();
    });
  };

  handleReset = () => {
    let self = this;
    self.setState(
      {
        filterOption: "name",
        inputSearch: "",
        page: 1,
        limit: 10,
        query: {
          page: 1,
          limit: 10,
        },
        input_value: false,
      },
      () => {
        pushToFilteredPage(this.props, { ...this.state.query });
        this.getActiveContractors();
      }
    );
  };
  openOtherProsModal = async () => {
    this.setState({
      openOtherModel: true,
    });
  }
  onClose = () => {
    this.setState({ addMemberModal: false, openOtherModel: false });
  };
  handleClear = () => {
    this.setState({ setData: "", addMemberModal: false, openOtherModel: false });
    this.getActiveContractors()
  }
  completed = (e) => {
    this.setState({ setData: e })
    this.setState({ addMemberModal: true, openOtherModel: false });
  }
  deleteContractor = async (contractor_id) => {
    let response = await ApiService.deleteAPI(
      `contractor/team/${contractor_id}`
    );
    if (response.success) {
      showSuccess(this, response.message);
      this.getActiveContractors()
    } else {
      showError(this, response.message);
    }
  }
  closeAddMemberModal = () => {
    this.setState({ addMemberModal: false });
  }
  AddMember = async () => {
    this.setState({ btnLoad: true, isApiCallPending: true });
    let response = await ApiService.postAPI(`contractor/team/add_member `, this.state.setData);
    this.setState({ isApiCallPending: false, btnLoad: false });
    if (response.success) {
      this.setState(
        { name: "", email: "", phone: "" },
        () => {
          showSuccess(this, response.message);
          this.handleClear();
        }
      );
    } else {
      showError(this, response.message);
      this.handleClear();
    }
  }
  render() {
    let {
      contractorList,
      isLoading,
      page,
      toast,
      response,
      toastType,
      filterOption,
      inputSearch,
      input_value,
      filters,
      addMemberModal,
      isApiCallPending
    } = this.state;

    return isLoading ? (
      <div className="spin-loading">
        <Spinner type="border" color="#077e8b" />
      </div>
    ) : (
      <div
        style={{ height: "100vh", padding: "20px", backgroundColor: "#fafafa" }}
      >
        {toast && <ToastList message={response} type={toastType} />}
        <CustomListingContainer>
          <ListingHeaderLayout label={"other pros"}
            primary_buttonLabel={"Add"}
            isPrimary
            click={this.openOtherProsModal} />
          <div style={{ margin: "24px" }}>
            <div
              className="d_flex_sb"
              style={{
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "80%",
                  justifyContent: "space-between",
                }}
              >
                <CustomSearch
                  options={filters}
                  change={this.onChangeHandler}
                  filterOption={filterOption}
                  inputSearch={inputSearch}
                  from="filterOption"
                />
              </div>
              {(input_value && inputSearch) && (
                <div className="right_filter_container">
                  <Button_without_Border
                    click={this.handleReset}
                    btnLabel={"clear_filter"}
                  ></Button_without_Border>
                </div>
              )}
            </div>
          </div>
          <div>
            <Row>
              <div className="col new-table">
                <Card className="shadow card_with_paginator">
                  {contractorList &&
                    contractorList?.length !== 0 ? (
                    <Table
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Email</th>
                          <th style={{ width: "10%" }} scope="col">
                            Phone Number
                          </th>
                          <th scope="col"></th>

                        </tr>
                      </thead>
                      <tbody>
                        {contractorList.map((contractor, i) => {
                          return (
                            <tr key={i}>
                              <td className="text-capitalize" style={{ width: "25%" }}>
                                {returnFirstDegreeObjValue(contractor, "name")}
                              </td>
                              <td className="" style={{ width: "30%" }}>
                                {returnFirstDegreeObjValue(contractor, "email")}
                              </td>
                              <td className="text-capitalize" style={{ width: "40%" }}>
                                {`${contractor.phone.country_code}` + " " + contractor.phone.national_number}
                              </td>
                              <td>
                                {contractor?.team_id &&
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      gap: "20px",
                                    }}
                                  >
                                    <UncontrolledDropdown
                                      nav
                                      className="flexy-content position_u"
                                    >
                                      <DropdownToggle
                                        className="pr-0 nav-notice"
                                        nav
                                      >
                                        <Media className="align-items-center">
                                          <img src={showMore} alt="show more" />
                                        </Media>
                                      </DropdownToggle>
                                      <DropdownMenu
                                        className="dropdown-menu-arrow min-width-drop"
                                        right
                                      >
                                        <DropdownItem
                                          onClick={() => this.deleteContractor(contractor.contractor_id)}
                                        >
                                          <span>Delete</span>
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                  </div>
                                }
                              </td>
                            </tr>)
                        })}
                      </tbody>
                    </Table>
                  ) : (
                    <>
                      <Table
                        className="align-items-center table-flush"
                        responsive
                      >
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Email</th>
                            <th style={{ width: "10%" }} scope="col"> Date</th>
                            <th style={{ width: "10%" }} scope="col"></th>
                          </tr>
                        </thead>
                      </Table>
                      <Card className="card_with_paginator no_data_card">
                        {" "}
                        <div className="no-data">No Contractors Available</div>
                      </Card>
                    </>
                  )}
                </Card>
              </div>
            </Row>
            <Row className="align-items-center header-div jus-space new-margin-page paginator_box">
              <div className="paginate-flex-box ">
                <div
                  className="paginate-box"
                  onClick={() => this.handleDecrementPage()}
                >
                  <p
                    className={
                      page !== 1 ? "page_available" : "page_unavailable"
                    }
                  >
                    Previous
                  </p>
                </div>
                <div
                  className="paginate-box"
                  onClick={() => this.handleIncrementPage()}
                >
                  <p
                    className={
                      contractorList?.length < 10
                        ? "page_unavailable"
                        : "page_available"
                    }
                  >
                    Next
                  </p>
                </div>
              </div>
            </Row>
          </div>
        </CustomListingContainer>
        <Card
          className={this.state.openOtherModel ? "exportSideBar" : "toggle-sidebar"}
        >
          {this.state.openOtherModel && (
            <AddOtherPros
              type="otherPros"
              props={this}
              leadID={this.state.contractor_id}
              close={this.onClose}
              complete={this.completed}
            ></AddOtherPros>
          )}
        </Card>
        <Modal
          className="modal-dialog-centered map-marker"
          isOpen={addMemberModal}
          style={{ width: "25%" }}
        // toggle={() => this.toggleMapModal()}
        >
          {/* <div> */}
          {toast && <ToastList message={response} type={toastType} />}

          <Card>
            <CardBody className="pad25">
              <div className="mb-10 flex">
                <div className="fw500 fc-b">Add Other Pros</div>
                <div
                  className="clpaid x_close flexmid point fw500 font-24"
                  onClick={() => this.onClose()}
                >
                  <img width={20} src={closeIcon} alt="close" />
                </div>
              </div>
              <label className="mb-30 cl555555 f14 fw-400" htmlFor="reason">
                Are you sure you want to add this member ?
              </label>

              <div className="flexEnd">
                <Custom_Button
                  style={{ marginRight: "10px" }}
                  btnLabel="no"
                  click={() => this.onClose()}
                // isLoading={btnLoad}
                />
                <Custom_Button
                  isPrimary
                  btnLabel="yes"
                  click={() => this.AddMember()}
                  isLoading={isApiCallPending}
                // complete={this.completed}

                />
              </div>
            </CardBody>
          </Card>
          {/* </div> */}
        </Modal>
      </div>
    );
  }
}

export default OtherProsContractors;
