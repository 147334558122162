import React, { Component } from "react";
import {
  Card,
  Table,
  Container,
  Row,
  Breadcrumb,
  Col,
  Button,
  InputGroup,
  InputGroupText,
  Spinner,
  DropdownMenu,
  DropdownItem,
  Media,
  UncontrolledDropdown,
  DropdownToggle,
  Dropdown,
  Modal,
  CardHeader,
  CardBody,
  Input,
} from "reactstrap";
import projectTimeline from "../../../src/assets/img/brand/projectTimeline.svg";
import TimeLine from "components/Timeline/Timeline";
import ApiService from "constants/ApiService";
import "react-datepicker/dist/react-datepicker.css";
import ToastList from "components/Toast/ToastList";
import { showError, uploadCloudinary , showSuccess , getIsoString ,filterQuery, returnSearchObject , ProjectLink , pushToFilteredPage , buildQueryString} from "constants/utils";
import showMore from "../../assets/img/brand/showMore.svg";
import { Progress, BreadcrumbItem } from "reactstrap";
import DeleteModal from "./DeleteModal";
import { CustomListingContainer } from "components/Container/CustomListingContainer";
import { ListingHeaderLayout } from "components/Container/CustomListingContainer";
import { CustomDropDown } from "components/FilterFields/Filterfields";
import { CustomSearch } from "components/FilterFields/Filterfields";
import { Button_without_Border } from "components/Buttons/Button";
import { showUtcDate } from "constants/utils";
import AddProject from "views/Crm/addProject";
import ToolTip from "components/ToolTip/ToolTip";
let overlap = document.querySelector(":root");

class Project extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requestList: [],
      project_list: [],
      total: 0,
      filterToggle: false,
      from: "",
      to: "",
      page: 1,
      open: false,
      client_id: "",
      inputSearch: "",
      filterOption: "name",
      filters: ["name"],
      status: this.props.location?.search ? "pending" : "",
      toast: false,
      response: "",
      image: [],
      iamge_name: "",
      toastType: "",
      isLoading: true,
      clientsList: [],
      btnLoad: false,
      query: {},
      name: "",
      client: {},
      start_date: "",
      actual_start: "",
      imageLoading: false,
      client_name: "",
      inputText: "",
      items: [],
      desc: "",
      tags: [],
      end_date: "",
      requestId: "",
      openModal: false,
      delete_data: {},
      cancelModal: false,
      project_id: "",
      statusSearch: "",
      input_value: false,
      all_projects: 0,
      active_projects: 0,
      completed_projects: 0,
      all_tasks: 0,
      isTimeLineOpen: false,
      filter: "",
      timoutId: "",
    };
  }
  componentDidMount = async () => {
    this.setQueryStatus();
  };

  closeModal = () => {
    this.setState({ openModal: false, project_id: "" });
    this.getProjectList();
  };

  handleDelete = async () => {
    let response = await ApiService.patchAPI(
      `contractor/project/delete/${this.state.delete_data.project_id}`,
      {}
    );
    if (response.success) {
      showSuccess(this, response.message);
      this.setState({ delete_data: {} });
      this.closeModal();
      this.getProjectList();
    } else {
      showError(this, response.message);
    }
  };

  initCheckClerFilters = () => {
    this.state.filters.includes(this.state.filterOption) ||
    this.state.statusSearch ||
    this.state.inputSearch ||
    this.state.page > 1
      ? this.setState({ input_value: true })
      : this.setState({ input_value: false });
  };

  setQueryStatus = () => {
    let queryStatus = this.props.location?.search;
    let queryObj = returnSearchObject(queryStatus);
    if (queryStatus) {
      this.setState(
        {
          filterOption: queryObj.name ? "name" : "name",
          inputSearch: queryObj.name ? queryObj.name : "",
          statusSearch: queryObj.status ?? "",
          page: parseInt(queryObj.page) ?? "",
          query: returnSearchObject(queryStatus),
        },
        () => {
          // this.initCheckClerFilters();
          pushToFilteredPage(this.props, { ...this.state.query });
          this.getProjectList();
        }
      );
    } else {
      this.setState(
        {
          query: {
            ...queryObj,
            page: 1,
            limit: 10,
          },
        },
        () => {
          pushToFilteredPage(this.props, { ...this.state.query });
          this.getProjectList();
        }
      );
    }
  };

  redirectDetailPage = (id) => {
    this.props.history.push({
      pathname: `/admin/projects/detail/${id}`,
      state: { from: "projects" },
    });
  };

  setPage = (page) => {
    this.props.history.push(page);
  };
  dateChangeHandler = (e, value) => {
    this.setState({ [value]: e });
  };

  // pathname: "add_project",
  // editProject = async (data) => {
  //   this.props.history.push({
  //     pathname: `/admin/project/update/${data?.project_id}`,
  //     state: {
  //       type: "edit_project",
  //       project_id: data.project_id,
  //       estimate_id: data.estimate_id,
  //       name: data.name,
  //       start_date: data.start_date,
  //       end_date: data.end_date,
  //       desc: data.desc,
  //       tags: data.tags,
  //       client: data.client,
  //     },
  //   });
  // };
  editProject = (data) => {
    this.props.history.push(`/admin/project/update/${data?.project_id}`);
  };
  getRequestsList = async () => {
    let { filterOption, status, inputSearch, from, to } = this.state;
    let queryParams = "";
    if (filterOption !== "" && inputSearch !== "")
      queryParams += filterQuery(filterOption, inputSearch);
    if (status !== "") {
      queryParams += filterQuery("status", status);
    }
    if (from !== "") queryParams += filterQuery("from", getIsoString(from));
    if (to !== "") queryParams += filterQuery("to", getIsoString(to));
    if (from > to) {
      showError(this, "Please provide valid From and To date");
    } else {
      let response = await ApiService.getAPI(
        `contractor/open_request${buildQueryString(this.state.query)}`
      );

      if (response.success) {
        let { open_request, total } = response.data;
        this.setState({
          requestList: open_request,
          total: total,
          isLoading: false,
        });
      }
    }
  };

  getProjectList = async () => {
    let response = await ApiService.getAPI(
      `contractor/project${buildQueryString(this.state.query)}`
    );
    if (response.success) {
      this.setState({
        isLoading: false,
        project_list: response.data.projects,
      });
    }
  };

  createProjectHandler = async () => {
    let {
      name,
      client_name,
      start_date,
      end_date,
      actual_start,
      client_id,
      desc,
      project_id,
      edit,
      tags,
    } = this.state;
    if (!name || !client_name || !start_date || !end_date || !actual_start)
      return showError(this, "Please fill all the required fields");
    this.setState({ btnLoad: true });
    let data = {
      name: name,
      client: {
        id: client_id,
        name: client_name,
      },
      start_date: start_date,
      end_date: end_date,
      actual_start: actual_start,
      desc: desc,
      tags: tags,
    };
    let response = edit
      ? await ApiService.patchAPI(`contractor/project/${project_id}`, data)
      : await ApiService.postAPI("contractor/project", data);
    if (response.success) {
      showSuccess(this, response.message);
      this.setState({
        btnLoad: false,
        open: false,
        name: "",
        start_date: "",
        end_date: "",
        actual_start: "",
        desc: "",
        tags: [],
        client_name: "",
        client_id: "",
      });
      this.getProjectList();
    } else {
      this.setState({ btnLoad: false });
      showError(this, response.message);
    }
  };

  handleTagsChange = (tags) => {
    this.setState({ tags: tags });
  };
  handleInputChange = (event) => {
    this.setState({ inputText: event.target.value });
  };

  handleEnterKey = (event) => {
    if (event.key === "Enter" && this.state.inputText.trim() !== "") {
      this.setState((prevState) => ({
        inputText: "",
        items: [...prevState.items, prevState.inputText],
      }));
    }
  };
  toggleFilterMenu = () => {
    this.setState({ filterToggle: !this.state.filterToggle });
  };
  onChangeHandler = (e, from) => {
    e === "" && this.handleReset();
    this.setState(
      { [from]: e === "select" ? "" : e, input_value: true },
      () => {
        this.checkForClearFilter();
      }
    );
    clearTimeout(this.state.timoutId);
    const timoutId = setTimeout(() => {
      this.state.filterOption && this.handleSearch();
    }, 1000);
    this.setState({ timoutId });
  };
  updateStateValues = (key, value) => {
    this.setState(
      {
        [key]: value,
      },
      function () {
        if (value === null) {
          this.setState({
            [key]: "",
          });
        }
      }
    );
  };
  handleOnToChange = (e) => {
    this.updateStateValues("to", e ? new Date(e) : "");
  };
  handleOnFromChange = (e) => {
    this.updateStateValues("from", e ? new Date(e) : "");
  };
  handleIncrementPage = () => {
    if (this.state.project_list.length >= 10) {
      this.setState(
        {
          page: +this.state.page + 1,
          input_value: true,
        },
        () => {
          this.handleFilterStatus();
        }
      );
    }
  };
  handleDecrementPage = () => {
    if (this.state.page > 1) {
      this.setState(
        {
          page: this.state.page - 1,
          input_value: this.state.page - 1 === 1 ? false : true,
        },
        () => this.handleFilterStatus()
      );
    } else {
    }
  };
  capitalizeFirstLetter = (str) => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  imageHandler = async (e, subStatus) => {
    let { image } = this.state;
    let file = e.target.files[0];

    if (e.target.files.length > 0) {
      this.setState({ imageLoading: true });
    }

    if (
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/jpeg" ||
      file.type === "application/pdf"
    ) {
      if (file.size < 1000000) {
        let data = await uploadCloudinary(file, "host");
        if (data.url) {
          let imgArr = [...image];
          let imageObj = {
            format: data.format,
            public_id: data.public_id,
            version: `v${data.version}`,
            type: "image",
          };
          imgArr.push(imageObj);
          this.setState({
            image: imgArr,
            imageLoading: false,
          });
        } else {
          showError(this, "Could not upload image");
          this.setState({ imageLoading: false });
        }
      } else {
        showError(this, "File size should not exceeds 1 MB");
        this.setState({ imageLoading: false });
      }
    } else {
      showError(this, "Invalid Format");
      this.setState({ imageLoading: false });
    }
  };

  deleteImage = () => {
    this.setState({ image: [], image_name: "" });
  };

  closeTimeline = () => {
    this.setState({ isTimeLineOpen: false });
  };

  handleFilterStatus = () => {
    let staticQuery = returnSearchObject(this.props.location.search);
    staticQuery.status = this.state.statusSearch ?? "";
    staticQuery.page = this.state.page ?? "";
    if (this.state.inputSearch !== "" && this.state.filterOption !== "") {
      for (let key in staticQuery) {
        if (key === "name") {
          delete staticQuery[key];
        }
      }
    }
    staticQuery[this.state.filterOption] = this.state.inputSearch;
    this.setState({ query: staticQuery }, () => {
      if (this.state.filterOption !== "select") {
        pushToFilteredPage(this.props, { ...this.state.query });
        this.getProjectList();
      }
    });
  };

  handleSearch = () => {
    if (this.state.filterOption === "name") {
      this.setState(
        {
          page: 1,
        },
        () => {
          this.handleFilterStatus();
        }
      );
    }
  };

  checkForClearFilter = () => {
    this.setState({
      input_value:
        !this.state.statusSearch &&
        (this.state.filterOption === "name" || !this.state.filterOption) &&
        !this.state.inputSearch
          ? false
          : true,
    });
  };
  handleReset = () => {
    let self = this;
    self.setState(
      {
        from: "",
        to: "",
        statusSearch: "",
        filterOption: "name",
        inputSearch: "",
        page: 1,
        limit: 10,
        query: {
          page: 1,
          limit: 10,
        },
        input_value: false,
      },
      () => {
        pushToFilteredPage(this.props, { ...this.state.query });
        this.getProjectList();
      }
    );
  };

  setStatus = (status) => {
    this.setState(
      {
        statusSearch: status === "all" ? "" : status,
        page: 1,
      },
      () => {
        this.checkForClearFilter();
        this.handleFilterStatus();
      }
    );
  };

  redirectToAddRequest = async () => {
    let response = await ApiService.getAPI(`contractor/client?status=active`);

    if (response.success) {
      let { clients } = response.data;
      this.setState({
        clientsList: clients,
        edit: false,
        open: true,
      });
    }
  };

  editRequest = (data) => {
    this.props.history.push(`/admin/request/update/${data?.open_request_id}`);
  };

  onClose = () => {
    this.setState({ open: false, project_id: "" });
    this.getProjectList();
  };

  cancelRequest = async (request) => {
    this.setState({ cancelModal: true, requestId: request.open_request_id });
  };

  resendRequest = async (request) => {
    let response = await ApiService.patchAPI(
      `contractor/open_request/resend/${request.open_request_id}`
    );
    if (response.success) {
      showSuccess(this, response.message);
      this.getProjectList();
    } else {
      showError(this, response.message);
    }
  };
  resendInvoice = async (request) => {
    let response = await ApiService.patchAPI(
      `contractor/open_request/invoice/${request.open_request_id}`
    );
    if (response.success) {
      showSuccess(this, response.message);
      this.getProjectList();
    } else {
      showError(this, response.message);
    }
  };

  render() {
    let {
      requestList,
      isLoading,
      project_id,
      page,
      status,
      toast,
      response,
      toastType,
      clientsList,
      input_value,
      project_list,

      name,
      imageLoading,
      start_date,
      actual_start,
      desc,
      end_date,
      tags,
      client_name,

      btnLoad,
      inputSearch,
      filterOption,
      image,
      openModal,
      delete_data,
      open,
      edit,
      all_projects,
      active_projects,
      completed_projects,
      statusSearch,
      all_tasks,
      filters,
    } = this.state;
    return isLoading ? (
      <div className="spin-loading">
        <Spinner type="border" color="#077e8b" />
      </div>
    ) : (
      <>
        {openModal && (
          <DeleteModal
            modalState={openModal}
            type="project"
            name={delete_data.name}
            closeModal={this.closeModal}
            props={this}
            id={this.state.project_id}
          />
        )}
        <div style={{ padding: "20px", backgroundColor: "#fafafa" }}>
          {toast && <ToastList message={response} type={toastType} />}
          <CustomListingContainer>
            <ListingHeaderLayout
              primary_buttonLabel={"Add Project"}
              isPrimary
              label={"Projects"}
              click={() => {
                this.props.history.push("projects/add_project");
              }}
            ></ListingHeaderLayout>
            <div style={{ margin: "20px" }}>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "65% 35%",
                }}
              >
                <div style={{ display: "flex" }}>
                  <CustomSearch
                    style={{ marginRight: "20px" }}
                    options={filters}
                    change={this.onChangeHandler}
                    filterOption={filterOption}
                    inputSearch={inputSearch}
                  />
                  <CustomDropDown
                    width={"126px"}
                    setStatus={this.setStatus}
                    status={statusSearch}
                    statusList={[
                      "all",
                      "active",
                      "completed",
                      "in_progress",
                      "on_hold",
                      "discontinued",
                      "cancelled",
                    ]}
                  ></CustomDropDown>
                </div>
                {input_value && (inputSearch || statusSearch) && (
                  <div className="right_filter_container">
                    <Button_without_Border
                      click={this.handleReset}
                      btnLabel={"clear_filter"}
                    ></Button_without_Border>
                  </div>
                )}
              </div>
            </div>

            <Row>
              <div className="col new-table">
                <Card className="shadow card_with_paginator">
                  <Table className="align-items-center table-flush" responsive>
                    <thead
                      className="thead-light"
                      style={{ borderBottom: "1px solid #f5f5f5" }}
                    >
                      <tr>
                        <th style={{ width: "20%" }} scope="col">
                          ID
                        </th>
                        <th style={{ width: "20%" }} scope="col">
                          Name
                        </th>
                        <th style={{ width: "20%" }} cope="col">
                          Client
                        </th>
                        {/* <th className="w_13" scope="col">
                        Start
                      </th>
                      <th className="w_13" scope="col">
                        Actual Start
                      </th> */}

                        <th style={{ width: "20%" }} scope="col">
                          End Date
                        </th>
                        <th style={{ width: "20%" }} scope="col">
                          Progress
                        </th>
                        <th style={{ width: "20%" }} scope="col">
                          Status
                        </th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    {project_list && project_list.length !== 0 ? (
                      <tbody>
                        {project_list.map((project, i) => (
                          <tr key={i}>
                            <td>
                              <div
                                className="cursor-point"
                                onClick={() =>
                                  this.redirectDetailPage(project.project_id)
                                }
                              >
                                {project.project_id}
                              </div>
                            </td>
                            <td
                              className="cursor-point capitalize "
                              onClick={() =>
                                this.redirectDetailPage(project.project_id)
                              }
                            >
                              <div>
                                {this.capitalizeFirstLetter(project.name)}
                              </div>
                            </td>
                            <td>
                              <div>
                                {" "}
                                {this.capitalizeFirstLetter(
                                  project?.client?.name
                                )}
                              </div>
                            </td>
                            <td>
                              <div>{showUtcDate(project.end_date)}</div>
                            </td>
                            <td
                              className={
                                project?.invoice?.status === "paid" ||
                                project?.invoice?.status === "assigned"
                                  ? "text-capitalize status_accepted general_status_style"
                                  : project?.invoice?.status === "cancelled" ||
                                    project?.invoice?.status === "rejected"
                                  ? "text-capitalize red general_status_style"
                                  : project?.invoice?.status === "created" ||
                                    project?.invoice?.status === "pending"
                                  ? "text-capitalize yellow general_status_style"
                                  : project?.invoice?.status === "completed"
                                  ? "text-capitalize status_completed general_status_style"
                                  : project?.invoice?.status === "closed"
                                  ? "text-capitalize primary general_status_style"
                                  : "text-capitalize"
                              }
                              style={{ fontWeight: "500" }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "4%",
                                }}
                              >
                                {" "}
                                <div>
                                  {" "}
                                  <Progress
                                    color="#0A8080"
                                    value={Math.round(project.progress ?? 0)}
                                  />
                                </div>
                                <div className="project_list_percentage">
                                  {Math.round(project.progress ?? 0)} %
                                </div>
                              </div>
                            </td>

                            <td>
                              <div
                                className="project_list_status"
                                style={{
                                  color: `${
                                    project.status === "completed" ||
                                    project.status === "active" ||
                                    project.status === "closed"
                                      ? "#07A84E"
                                      : project.status === "discontinued" ||
                                        project.status === "cancelled"
                                      ? "#f75f18"
                                      : project.status === "due"
                                      ? "#0070FD"
                                      : "#FFCC3D"
                                  }`,
                                  fontWeight: "500",
                                }}
                              >
                                {" "}
                                {this.capitalizeFirstLetter(
                                  project.status === "on_hold"
                                    ? "On Hold"
                                    : project.status
                                )}
                              </div>
                            </td>

                            <td
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "20px",
                              }}
                            >
                              <div>
                                <ToolTip label="Timeline">
                                  <img
                                    onClick={() =>
                                      this.setState({
                                        isTimeLineOpen: true,
                                        project_id: project.project_id,
                                      })
                                    }
                                    style={{ cursor: "pointer" }}
                                    src={projectTimeline}
                                    alt="timeline"
                                  ></img>
                                </ToolTip>
                              </div>
                              <UncontrolledDropdown
                                nav
                                className="flexy-content position_u"
                              >
                                <DropdownToggle className="pr-0 nav-notice" nav>
                                  <Media className="align-items-center">
                                    <img src={showMore} alt="show more" />
                                  </Media>
                                </DropdownToggle>
                                <DropdownMenu
                                  className="dropdown-menu-arrow min-width-drop"
                                  right
                                >
                                  <DropdownItem
                                    onClick={() => this.editProject(project)}
                                  >
                                    <span>Edit</span>
                                  </DropdownItem>
                                  <DropdownItem
                                  onClick={() => {
                                    ProjectLink(project.client.id ,project.project_id)
                                    showSuccess(this,"Link Copied")
                                  }}
                                  >
                                    <span>Copy Link</span>
                                  </DropdownItem>
                                  {/* 
                                <DropdownItem
                                  onClick={() =>
                                    this.setState({
                                      openModal: true,
                                      delete_data: project,
                                      project_id: project.project_id,
                                    })
                                  }
                                >
                                  <span>Delete</span>
                                </DropdownItem> */}
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td className="no-data">No Project Available</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                    )}
                  </Table>
                </Card>
              </div>
            </Row>
            <Row className="align-items-center header-div jus-space new-margin-page paginator_box">
              <div className="paginate-flex-box ">
                <div
                  className="paginate-box"
                  onClick={() => this.handleDecrementPage()}
                >
                  <p
                    className={
                      page !== 1 ? "page_available" : "page_unavailable"
                    }
                  >
                    Previous
                  </p>
                </div>
                <div
                  className="paginate-box"
                  onClick={() => this.handleIncrementPage()}
                >
                  <p
                    className={
                      project_list.length < 10
                        ? "page_unavailable"
                        : "page_available"
                    }
                  >
                    Next
                  </p>
                </div>
              </div>
            </Row>

            <Card
              className={this.state.open ? "exportSideBar" : "toggle-sidebar"}
            >
              {this.state.open && (
                <AddProject
                  type="project"
                  props={this}
                  projectId={this.state.project_id}
                  close={this.onClose}
                  cancel={() => this.setState({ open: false })}
                ></AddProject>
              )}
            </Card>

            <Card
              className={
                this.state.isTimeLineOpen ? "exportSideBar" : "toggle-sidebar"
              }
            >
              {this.state.isTimeLineOpen && (
                <TimeLine
                  from={"project"}
                  id={this.state.project_id}
                  closeTimelineModal={this.closeTimeline}
                ></TimeLine>
              )}
            </Card>
          </CustomListingContainer>
        </div>
      </>
    );
  }
}

export default Project;
