import React, { Component } from "react";
import { Modal, Button, Spinner } from "reactstrap";
import ApiService from "constants/ApiService";
import { showError, showSuccess } from "constants/utils";
import { Custom_Button } from "components/Buttons/Button";
import { Button_without_Border } from "components/Buttons/Button";
class ConvertCancelModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      btnLoad: false,
    };
  }
  componentDidMount() {}

  convert = async () => {
    this.setState({ btnLoad: true });
    let response = await ApiService.patchAPI(
      `contractor/lead/${this.props.lead.lead_id}/${
        this.props.subject === "convert" ? "converted" : "cancelled"
      }`
    );
    this.setState({ btnLoad: false });
    if (response.success) {
      showSuccess(this.props.props, response.message);
      this.props.toggle();
    } else {
      showError(this.props.props, response.message);
    }
  };

  render() {
    let { btnLoad } = this.state;
    return (
      <div>
        <Modal
          isOpen={this.props.isOpen}
          centered="true"
          style={{ minWidth: "25rem", padding: "2rem" }}
        >
          <div style={{ padding: "24px" }}>
            <div className="conCanMod">
              {this.props.subject[0]?.toUpperCase() +
                this.props.subject?.slice(1)}
            </div>
            <div style={{ textAlign: "center", wordBreak: "break-word" }}>
              Are you sure want to {this.props.subject} {this.props.lead.name}
            </div>
            <div
              className="flexEnd"
              style={{ marginTop: "16px", justifyContent: "center", gap: "5%" }}
            >
              <Button_without_Border
                btnLabel={"No"}
                click={() => this.props.toggle()}
              />
              <Custom_Button
                isLoading={btnLoad}
                isPrimary={true}
                btnLabel={"Yes"}
                style={{minWidth:'50px'}}  
                click={() => this.convert()}
              />
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default ConvertCancelModal;
