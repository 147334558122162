const stageConfig = {
  BASE_URL: "http://3.16.194.5:8000/api/v1/",
  // BASE_URL: "http://192.168.0.183:1403/api/",
  // BASE_URL: "http://192.168.0.231:1403/api/", 
  // BASE_URL: "http://localhost:1403/api/",
  // BASE_URL: "http://localhost:1403/api/",
  PANEL_LINK: "http://3.16.194.5:4321/#/",
  CLIENT_PANEL_LINK: "http://3.16.194.5:3001/client/"

};

const prodConfig = {
  BASE_URL: "https://api.parknstays.com/api/v1/",
  PANEL_LINK: "https://pro.parknstays.com/#/",
  CLIENT_PANEL_LINK: "https://client.parknstays.com/client/"
};
// export default "production" === process.env.REACT_APP_ENV
//   ? prodConfig
//   : stageConfig;

export default prodConfig