import React, { Component } from "react";
import { Container } from "reactstrap";
import { Link } from "react-router-dom";
import AddProperty from "../../../src/assets/img/brand/addProperty.png";
class PersonalDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <div className="header pt-md-6 mt-6 equal_header_padding">
          <Container fluid className="genaral_div_padding">
            <div className="add_property_container">
              <img src={AddProperty} alt="Add Property Img"></img>
              <h2 className="ft_family ft_600 ft_24 mt_30">
                Welcome Aravind Karthik
              </h2>
              <p className="ft_16 ft_400 w_50 align_center mt_20 ">
                Aliqua id fugiat nostrud irure ex duis ea quis id quis ad et.
                Sunt qui esse pariatur duis deserunt mollit dolore cillum minim
                tempor enim. Elit aute irure tempor cupidatat{" "}
              </p>
              <div className="mt_20">
                <Link
                  to={{
                    pathname: "/admin/properties",
                    merge: "TransactionProp",
                  }}
                >
                  <button className="button-wrapping new-profile-btn btn btn-primary btn-sm">
                    Add Property
                  </button>
                </Link>
              </div>
            </div>
          </Container>
        </div>
      </>
    );
  }
}
export default PersonalDashboard;
