import React, { Component } from "react";
import closeIcon from "../../assets/img/brand/close.svg";
import white_close_icon from "../../assets/img/icons/white_close_icon.svg";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Input,
  InputGroupText,
  Spinner,
} from "reactstrap";
import DateSvg from "../../assets/img/brand/date-pick.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Add from "../../assets/img/brand/addIcon.svg";
import removeIcon from "../../assets/img/brand/removeIcon.svg";
import pdfImage from "../../assets/img/brand/pdf.png";
import { LightgalleryItem } from "react-lightgallery";
import { getCloudinaryImage } from "constants/utils";
import { uploadCloudinary } from "constants/utils";
import { showError } from "constants/utils";
import ApiService from "constants/ApiService";
import moment from "moment";
import Geo from "../../assets/img/brand/geo.svg";
import TagInputs from "./TagInput";
import timeIcon from "../../assets/img/brand/invoiceTimeline.svg";

class EventSideDragger extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventDate: "",
      title: "",
      description: "",
      from: "",
      to: "",
      btnLoading: false,
      pageLoading: false,
      geo: "",
      isShowPlaces: false,
      assignees: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.eventDetails !== this.props.eventDetails) {
      this.setState({ pageLoading: true });
      this.getEventDetails();
    }
  }
  getEventDetails = async () => {
    let { eventDetails } = this.props;
    this.setState(
      {
        title: eventDetails?.name ?? "",
        description: eventDetails?.description ?? "",
        eventDate: eventDetails?.createdAt
          ? new Date(eventDetails?.createdAt)
          : "",
        geo: eventDetails?.location ?? "",
        from: eventDetails?.time?.from
          ? new Date().setHours(
            moment(eventDetails?.time?.from).format("HH"),
            moment(eventDetails?.time?.from).format("mm")
          )
          : "",
        to: eventDetails?.time?.to
          ? new Date().setHours(
            moment(eventDetails?.time?.to).format("HH"),
            moment(eventDetails?.time?.to).format("mm")
          )
          : "",
        assignees: eventDetails?.attendees ?? [],
      },
      () => {
        this.setState({ pageLoading: false });
      }
    );
  };
  handleTitleChange = (e) => {
    this.setState({ title: e.target.value });
  };
  handleDateChange = (e) => {
    this.setState({ eventDate: e });
  };

  handleFromTime = (e) => {
    this.setState({ from: e });
  };

  handleToTime = (e) => {
    this.setState({ to: e });
  };

  handleDescriptionChange = (e) => {
    this.setState({ description: e.target.value });
  };

  displayPlaces = async (e) => {
    let searchTerm = e.target.value;
    this.setState({ geo: searchTerm });
    let response = await ApiService.getAPI(`common/search?input=${searchTerm}`);
    if (response.success) {
      this.setState({
        isShowPlaces: true,
        places: response.data.place.predictions,
      });
    }
  };

  getPlaceData = async (place) => {
    let response = await ApiService.getAPI(
      `contractor/maps/place/${place.place_id}`
    );
    if (response.success) {
      if (response.success) {
        let locData = response.data;
        this.setState({
          geo: place.description,
          isShowPlaces: false,
          locationData: locData,
        });
      } else {
        // toast.error(response.message);
        this.setState({
          isLoading: false,
          isShowPlaces: false,
        });
      }
    }
  };

  selectedTags = (tags) => {
    this.setState({ assignees: tags });
  };

  closeModal = () => {
    this.props.close(false);
  };

  createEvent = async () => {
    let {
      title,
      amount,
      eventDate,
      from,
      to,
      image,
      description,
      geo,
      assignees,
    } = this.state;

    if (title === "") {
      this.props.toast("Please enter  a title");
    } else if (
      eventDate === undefined ||
      eventDate === null ||
      eventDate === ""
    ) {
      this.props.toast("Please select a date");
    } else if (from === undefined || from === null || from === "") {
      this.props.toast("Please Select From Time");
    } else if (to === undefined || to === null || to === "") {
      this.props.toast("Please Select To Time");
    } else if (from > to) {
      this.props.toast("From time should be lesser than to time");
    } else {
      this.setState({ btnLoading: true });
      let response;
      let data = {
        summary: title,
        from: moment(
          `${moment(eventDate).format("YYYY-MM-DD")}T${moment(from).format(
            "HH:mm:ss"
          )}`
        ).format(),
        to: moment(
          `${moment(eventDate).format("YYYY-MM-DD")}T${moment(to).format(
            "HH:mm:ss"
          )}`
        ).format(),
        // to: "2023-02-25T19:00:00+05:30",

        // from: "2023-02-25T17:00:00+05:30",
        location: geo,
        description: description,
        attendees: assignees,
      };
      if (this.props.eventDetails) {
        let id = this.props.eventDetails.id;
        response = await ApiService.patchAPI(`contractor/google/${id}`, data);
      } else {
        response = await ApiService.postAPI(`contractor/google`, data);
      }

      if (response.success) {
        this.setState({ btnLoading: false });

        this.props.close(false, response.message);
      } else {
        this.setState({ btnLoading: false });

        this.props.toast(response.message);
      }
    }
  };
  showToast = (msg) => {
    this.props.toast(msg);
  };

  render() {
    let {
      description,
      eventDate,
      btnLoading,
      pageLoading,
      title,
      from,
      to,
      geo,
      assignees,
    } = this.state;
    return (
      <div>
        {this.props.isOpen && (
          <Card className="bnone">
            <CardHeader>
              <div className="flex">
                <div className="fw-500 f16 clffffff">Google Calender Event</div>

                <img
                  src={white_close_icon}
                  onClick={() => this.closeModal()}
                  className="point"
                />
              </div>
            </CardHeader>
            <CardBody className="pad30p">
              {pageLoading ? (
                <div className="spin-loading3 w100 h100">
                  <Spinner type="border" color="#0A8080" />
                </div>
              ) : (
                <>
                  <div className="cl2A1E17 mb-15 f14 fw-400">Title *</div>
                  <Input
                    type="text"
                    value={title}
                    name="title"
                    onChange={this.handleTitleChange}
                    className="mb-20"
                    maxLength={150}
                  />

                  <div className="cl2A1E17 mb-15 f14 fw-400">Description</div>
                  <Input
                    type="textarea"
                    value={description}
                    name="Amount"
                    onChange={this.handleDescriptionChange}
                    className="mb-20"
                    rows={4}
                    maxLength={500}
                  />
                  <div className="geoLocation">
                    <div className="cl2A1E17 mb-15 f14 fw-400">Location</div>
                    <Input
                      className="mb-20"
                      value={geo}
                      name="geo"
                      id="input-first-name"
                      onChange={this.displayPlaces}
                      placeholder="Location"
                      type="text"
                    />
                    <img
                      src={Geo}
                      className="loc_abs point"
                    // onClick={() => this.onMapIconClick()}
                    />
                    {this.state.isShowPlaces && this.state.geo && (
                      <ul>
                        {this.state.places.map((place) => (
                          <li onClick={() => this.getPlaceData(place)}>
                            {place.description}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>

                  <div className="cl2A1E17 mb-15 f14 fw-400">Guests</div>
                  <TagInputs
                    selectedAssignee={this.selectedTags}
                    assignees={assignees}
                    toast={this.showToast}
                  />
                  <div className="cl2A1E17 mb-15 f14 fw-400">Date *</div>

                  <div className="form-relative flexStart mb-20 ">
                    <DatePicker
                      selected={eventDate}
                      dateFormat="MM-dd-yyyy"
                      placeholderText=""
                      className="mark_payment_date  point"
                      ariaDescribedBy="basic-addon2"
                      onChange={this.handleDateChange}
                      minDate={new Date()}
                      id="expenseDate"
                    />

                    <label className="mb-remove" htmlFor="expenseDate">
                      <img
                        src={DateSvg}
                        alt="Date-Picker Icon"
                        className="dateIcon point"
                      />
                    </label>
                  </div>
                  <div className="flex mb-30">
                    <div className="mr-10">
                      <div className="cl2A1E17 mb-15 f14 fw-400">
                        From Time*
                      </div>

                      <div className="form-relative flexStart mb-20 eventTime">
                        <DatePicker
                          selected={from}
                          showTimeSelect
                          showTimeSelectOnly
                          dateFormat="HH:mm"
                          timeIntervals={15}
                          className="mark_payment_date  point "
                          ariaDescribedBy="basic-addon2"
                          onChange={this.handleFromTime}
                          id="fromTime"
                        />

                        <label className="mb-remove" htmlFor="fromTime">
                          <img
                            src={timeIcon}
                            alt="Date-Picker Icon"
                            className="dateIcon point"
                          />
                        </label>
                      </div>
                    </div>
                    <div>
                      <div className="cl2A1E17 mb-15 f14 fw-400">To Time*</div>

                      <div className="form-relative flexStart mb-20 eventTime ">
                        <DatePicker
                          selected={to}
                          showTimeSelect
                          showTimeSelectOnly
                          dateFormat="HH:mm"
                          timeIntervals={15}
                          className="mark_payment_date  point"
                          ariaDescribedBy="basic-addon2"
                          onChange={this.handleToTime}
                          id="toTime"
                        />

                        <label className="mb-remove" htmlFor="toTime">
                          <img
                            src={timeIcon}
                            alt="Date-Picker Icon"
                            className="dateIcon point"
                          />
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="flexEnd">
                    <div
                      className="mr-20 point f14 button-secondary"
                      onClick={() => this.closeModal()}
                    >
                      Cancel
                    </div>
                    <Button
                      color="primary"
                      className="button-wrapping "
                      size="sm"
                      onClick={() => this.createEvent()}
                    >
                      {btnLoading ? (
                        <div className="spin-loading-save w100 h40">
                          <Spinner type="border" color="#0A8080" />
                        </div>
                      ) : (
                        <div>{this.props.eventDetails ? "Edit" : "Create"}</div>
                      )}
                    </Button>
                  </div>
                </>
              )}
            </CardBody>
          </Card>
        )}
      </div>
    );
  }
}

export default EventSideDragger;
