import React, { Component } from "react";
import {
  Card,
  Table,
  Container,
  Row,
  Breadcrumb,
  Col,
  Button,
  Spinner,
  DropdownMenu,
  DropdownItem,
  Media,
  UncontrolledDropdown,
  DropdownToggle,
  Input,
  Dropdown,
  Modal,
  InputGroup,
} from "reactstrap";
import ConvertCancelModal from "../convertCancelModal";
import ApiService from "constants/ApiService";
import "react-datepicker/dist/react-datepicker.css";
import projectTimeline from "../../../assets/img/brand/projectTimeline.svg";
import TimeLine from "components/Timeline/Timeline";
import SendEstimate from "../../../assets/img/new Logo/send_estimate.svg";
import { getIsoString, filterQuery } from "constants/utils";

import ToastList from "components/Toast/ToastList";

import { showError } from "constants/utils";
import { returnSearchObject } from "constants/utils";
import { buildQueryString } from "constants/utils";

import showMore from "../../../assets/img/brand/showMore.svg";
import { showSuccess } from "constants/utils";

import { uploadCloudinary } from "constants/utils";
// import DeleteModal from "./DeleteModal";
import AddLead from "../addLead";
import { CustomListingContainer } from "components/Container/CustomListingContainer";
import { ListingHeaderLayout } from "components/Container/CustomListingContainer";
import { Button_without_Border } from "components/Buttons/Button";
import { CustomDropDown } from "components/FilterFields/Filterfields";
import { CustomSearch } from "components/FilterFields/Filterfields";
import { Custom_Button } from "components/Buttons/Button";
import { getUSFormat } from "constants/utils";
import { pushToFilteredPage } from "constants/utils";
import ReactTooltip from "react-tooltip";
import ToolTip from "components/ToolTip/ToolTip";

let overlap = document.querySelector(":root");

class Leads extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requestList: [],
      project_list: [],
      total: 0,
      filterToggle: false,
      from: "",
      to: "",
      page: 1,
      open: false,
      client_id: "",
      inputSearch: "",
      filterOption: "name",
      status: "",
      toast: false,
      response: "",
      image: [],
      iamge_name: "",
      toastType: "",
      isLoading: true,
      clientsList: [],
      btnLoad: false,
      query: {},
      name: "",
      client: {},
      start_date: "",
      actual_start: "",
      imageLoading: false,
      client_name: "",
      inputText: "",
      items: [],
      desc: "",
      notes: "",
      statusSearch: "",
      notesbtn: false,
      tags: [],
      end_date: "",
      requestId: "",
      openModal: false,
      delete_data: {},
      cancelModal: false,
      project_id: "",
      lead_id: "",
      all_projects: 0,
      active_projects: 0,
      completed_projects: 0,
      all_tasks: 0,
      isTimeLineOpen: false,
      leadList: [],
      type: "",
      input_value: false,
      notesAddToggle: false,
      filters: ["name", "title"],
    };
  }
  componentDidMount = async () => {
    // overlap.style.setProperty("--width", "600px");
    this.setQueryStatus();
    // this.getLeadList();
  };

  componentWillUnmount() {
    // overlap.style.setProperty("--width", "450px");
  }

  closeModal = () => {
    // this.setState({ openModal: false, project_id: "" });
    // this.getProjectList();
  };
  handleDelete = async () => {
    let response = await ApiService.patchAPI(
      `contractor/project/delete/${this.state.delete_data.project_id}`,
      {}
    );
    if (response.success) {
      showSuccess(this, response.message);
      this.setState({ delete_data: {} });
      this.closeModal();
      //   this.getProjectList();
    } else {
      showError(this, response.message);
    }
  };

  returnCurrentFilterOption = (obj) => {
    return (
      this.state.filters.filter((ele) => Object.keys(obj).includes(ele))[0] ??
      ""
    );
  };

  initCheckClerFilters = () => {
    this.state.filters.includes(this.state.filterOption) ||
      this.state.statusSearch ||
      this.state.inputSearch ||
      this.state.page > 1
      ? this.setState({ input_value: true })
      : this.setState({ input_value: false });
  };

  setQueryStatus = () => {
    let queryStatus = this.props.location?.search;
    let queryObj = returnSearchObject(queryStatus);
    if (queryStatus) {
      this.setState(
        {
          inputSearch: queryObj[this.returnCurrentFilterOption(queryObj)] ?? "",
          filterOption: queryObj.name
            ? "name"
            : queryObj.title
              ? "title" : "name",
          //  this.returnCurrentFilterOption(queryObj),
          statusSearch: queryObj.status ?? "",
          page: parseInt(queryObj.page) ?? "",
          query: returnSearchObject(queryStatus),
        },
        () => {
          this.initCheckClerFilters();
          pushToFilteredPage(this.props, { ...this.state.query });
          this.getLeadList();
        }
      );
    } else {
      this.setState(
        {
          query: {
            ...queryObj,
            page: 1,
            limit: 10,
          },
        },
        () => {
          pushToFilteredPage(this.props, { ...this.state.query });
          this.getLeadList();
        }
      );
    }
  };

  getLeadList = async () => {
    let { filterOption, status, inputSearch, from, to, query } = this.state;

    const queryObj = { ...query };
    if (filterOption && inputSearch) queryObj[filterOption] = inputSearch;

    let response = await ApiService.getAPI(
      `contractor/lead${buildQueryString(queryObj)}`
    );
    this.setState({ isLoading: false });
    if (response.success) {
      let { leads } = response.data;
      this.setState({ leadList: leads });
    }
  };

  redirectDetailPage = (id) => {
    this.props.history.push(`projects/detail/${id}`);
  };

  setPage = (page) => {
    this.props.history.push(page);
  };
  dateChangeHandler = (e, value) => {
    this.setState({ [value]: e });
  };

  editProject = async (data) => {
    this.setState(
      {
        lead_id: data,
      },
      () => {
        this.setState({ open: true });
      }
    );
  };

  getRequestsList = async () => {
    let { filterOption, status, inputSearch, from, to } = this.state;
    let queryParams = "";
    if (filterOption !== "" && inputSearch !== "")
      queryParams += filterQuery(filterOption, inputSearch);
    if (status !== "") {
      queryParams += filterQuery("status", status);
    }
    if (from !== "") queryParams += filterQuery("from", getIsoString(from));
    if (to !== "") queryParams += filterQuery("to", getIsoString(to));
    if (from > to) {
      showError(this, "Please provide valid From and To date");
    } else {
      let response = await ApiService.getAPI(
        `contractor/open_request${buildQueryString(this.state.query)}`
      );

      if (response.success) {
        let { open_request, total } = response.data;
        this.setState({
          requestList: open_request,
          total: total,
          isLoading: false,
        });
      }
    }
  };

  getProjectList = async () => {
    let { filterOption, status, inputSearch, from, to } = this.state;
    let queryParams = "";
    if (filterOption !== "" && inputSearch !== "")
      queryParams += filterQuery(filterOption, inputSearch);
    if (status !== "") {
      queryParams += filterQuery("status", status);
    }

    if (from !== "") queryParams += filterQuery("from", getIsoString(from));
    if (to !== "") queryParams += filterQuery("to", getIsoString(to));
    if (from > to) {
      showError(this, "Please provide valid From and To date");
    } else {
      let response = await ApiService.getAPI(
        `contractor/project/${buildQueryString(this.state.query)}`
      );

      if (response.success) {
        this.setState({
          isLoading: false,
          project_list: response.data.projects,
        });
      }
    }
  };

  leadConversionUpdate = (value, data) => {
    this.setState({ openModal: true, type: value, lead_id: data });
  };

  createProjectHandler = async () => {
    let {
      name,
      client_name,
      start_date,
      end_date,
      actual_start,
      client_id,
      desc,
      project_id,
      edit,
      tags,
    } = this.state;
    if (!name || !client_name || !start_date || !end_date || !actual_start)
      return showError(this, "Please fill all the required fields");
    this.setState({ btnLoad: true });
    let data = {
      name: name,
      client: {
        id: client_id,
        name: client_name,
      },
      start_date: start_date,
      end_date: end_date,
      actual_start: actual_start,
      desc: desc,
      tags: tags,
    };
    let response = edit
      ? await ApiService.patchAPI(`contractor/project/${project_id}`, data)
      : await ApiService.postAPI("contractor/project", data);
    if (response.success) {
      showSuccess(this, response.message);
      this.setState({
        btnLoad: false,
        open: false,
        name: "",
        start_date: "",
        end_date: "",
        actual_start: "",
        desc: "",
        tags: [],
        client_name: "",
        client_id: "",
      });
      //   this.getProjectList();
    } else {
      this.setState({ btnLoad: false });
      showError(this, response.message);
    }
  };

  handleTagsChange = (tags) => {
    this.setState({ tags: tags });
  };
  handleInputChange = (event) => {
    this.setState({ inputText: event.target.value });
  };

  handleEnterKey = (event) => {
    if (event.key === "Enter" && this.state.inputText.trim() !== "") {
      this.setState((prevState) => ({
        inputText: "",
        items: [...prevState.items, prevState.inputText],
      }));
    }
  };
  toggleFilterMenu = () => {
    this.setState({ filterToggle: !this.state.filterToggle });
  };

  checkForClearFilter = () => {
    this.setState({
      input_value:
        !this.state.statusSearch &&
          (this.state.filterOption === "name" || !this.state.filterOption) &&
          !this.state.inputSearch
          ? false
          : true,
    });
  };

  onChangeHandler = (e, from) => {
    this.setState(
      { [from]: e === "select" ? "" : e, input_value: true },
      () => {
        this.checkForClearFilter();
      }
    );
    clearTimeout(this.state.timoutId);
    const timoutId = setTimeout(() => {
      this.state.filterOption && this.handleSearch();
    }, 1000);
    this.setState({ timoutId });
  };
  // onChangeHandler = (e) => {
  //   e.target.value === "" && this.handleReset();
  //   this.setState({ [e.target.name]: e.target.value, input_value: true });
  //   clearTimeout(this.state.timeoutId);
  //   const timeoutId = setTimeout(() => {
  //     this.state.filterOption && this.handleSearch();
  //   }, 1000);
  //   this.setState({ timeoutId });
  // };
  // onChangeSelectHandler = (status) => {
  //   this.setState({ filterOption: status, input_value: true }, () => {
  //     this.handleFilterStatus();
  //   });
  // }
  setFilterSearch = (e) => {
    this.setState({ filterOption: "name" });
  };
  updateStateValues = (key, value) => {
    this.setState(
      {
        [key]: value,
      },
      function () {
        if (value === null) {
          this.setState({
            [key]: "",
          });
        }
      }
    );
  };
  handleOnToChange = (e) => {
    this.updateStateValues("to", e ? new Date(e) : '');
  };
  handleOnFromChange = (e) => {
    this.updateStateValues("from", e ? new Date(e) : '');
  };
  handleIncrementPage = () => {
    if (this.state.leadList.length >= 10) {
      this.setState(
        {
          page: this.state.page + 1,
          input_value: true,
        },
        () => {
          this.handleFilterStatus();
        }
      );
    }
  };

  handleDecrementPage = () => {
    if (this.state.page > 1) {
      this.setState(
        {
          page: this.state.page - 1,
          input_value: this.state.page - 1 === 1 ? false : true,
        },
        () => {
          this.handleFilterStatus();
        }
      );
    }
  };

  capitalizeFirstLetter = (str) => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  closeTimeline = () => {
    this.setState({ isTimeLineOpen: false });
  };

  handleFilterStatus = () => {
    let staticQuery = returnSearchObject(this.props.location.search);
    staticQuery.status = this.state.statusSearch ?? "";
    staticQuery.page = this.state.page ?? "";
    if (this.state.inputSearch !== "" && this.state.filterOption !== "") {
      for (let key in staticQuery) {
        if (key === "title" || key === "name") {
          delete staticQuery[key];
        }
      }
    }
    staticQuery[this.state.filterOption] = this.state.inputSearch;
    this.setState({ query: staticQuery }, () => {
      if (this.state.filterOption !== "select") {
        pushToFilteredPage(this.props, { ...this.state.query });
        this.getLeadList();
      }
    });
  };

  handleSearch = () => {
    // if (this.state.filterOption) {
    this.setState({ page: 1 }, () => {
      this.handleFilterStatus();
    });
    // }
  };

  handleReset = () => {
    let self = this;
    self.setState(
      {
        from: "",
        to: "",
        statusSearch: "",
        filterOption: "name",
        inputSearch: "",
        page: 1,
        limit: 10,
        query: {
          page: 1,
          limit: 10,
        },
        input_value: false,
      },
      () => {
        pushToFilteredPage(this.props, { ...this.state.query });
        this.getLeadList();
      }
    );
  };

  setStatus = (status) => {
    this.setState(
      {
        statusSearch: status === "all" ? "" : status,
        page: 1,
      },
      () => {
        this.checkForClearFilter();
        this.handleFilterStatus();
      }
    );
  };

  redirectToAddRequest = async () => {
    this.setState({
      edit: false,
      open: true,
      lead_id: "",
    });
  };

  toggleModal = () => {
    this.setState({ openModal: false, lead_id: "" });
    this.getLeadList();
  };

  editRequest = (data) => {
    this.props.history.push(`/admin/request/update/${data?.open_request_id}`);
  };

  lead_accum = (data) => {
    let lists = this.state.leadList;
    lists.push(data);
    this.setState({ leadList: lists });
  };

  onClose = (cond) => {
    this.setState({ open: false, leadID: "" });
    cond && this.getLeadList();
  };

  cancelRequest = async (request) => {
    this.setState({ cancelModal: true, requestId: request.open_request_id });
  };

  resendRequest = async (request) => {
    let response = await ApiService.patchAPI(
      `contractor/open_request/resend/${request.open_request_id}`
    );
    if (response.success) {
      showSuccess(this, response.message);
      //   this.getProjectList();
    } else {
      showError(this, response.message);
    }
  };
  resendInvoice = async (request) => {
    let response = await ApiService.patchAPI(
      `contractor/open_request/invoice/${request.open_request_id}`
    );
    if (response.success) {
      showSuccess(this, response.message);
      //   this.getProjectList();
    } else {
      showError(this, response.message);
    }
  };

  addNotes = async () => {
    this.setState({ notesbtn: true });
    if (!this.state.notes) {
      this.setState({ notesbtn: false });
      return showError(this, "Please add notes");
    } else {
      let response = await ApiService.patchAPI(
        `contractor/lead/notes/${this.state.lead_id}`,
        { notes: this.state.notes }
      );
      this.setState({ notesbtn: false });
      if (response.success) {
        showSuccess(this, response.message);
        this.setState({ notesAddToggle: false });
      } else {
        return showError(this, response.message);
      }
    }
  };
  Showtoast = (type, message) => {
    this.setState({
      toast: true,
      toastType: type,
      response: message,
    });
    this.clearToast();
  }
  clearToast = () => {
    setTimeout(
      () => this.setState({ toast: false, toastType: "", response: "" }),
      2000
    );
  }
  render() {
    let {
      requestList,
      isLoading,
      project_id,
      lead_id,
      page,
      status,
      toast,
      response,
      toastType,
      notes,
      clientsList,
      type,
      project_list,
      leadList,
      name,
      imageLoading,
      start_date,
      actual_start,
      desc,
      end_date,
      tags,
      client_name,
      notesAddToggle,
      btnLoad,
      inputSearch,
      filterOption,
      image,
      openModal,
      delete_data,
      open,
      edit,
      all_projects,
      active_projects,
      completed_projects,
      all_tasks,
      statusSearch,
      input_value,
      notesbtn,
      filters,
    } = this.state;
    return isLoading ? (
      <div className="spin-loading">
        <Spinner type="border" color="#077e8b" />
      </div>
    ) : (
      <>
        <div style={{ padding: "20px", backgroundColor: "#fafafa" }}>
          {toast && <ToastList message={response} type={toastType} />}
          <CustomListingContainer>
            <ListingHeaderLayout
              primary_buttonLabel={"Add Lead"}
              isPrimary
              label={"Leads"}
              click={this.redirectToAddRequest}
            ></ListingHeaderLayout>
            <div style={{ margin: "20px" }}>
              <div
                // className="d_flex_sb"
                style={{
                  display: "grid",
                  gridTemplateColumns: "65% 35%",
                }}
              >
                <div style={{ display: "flex" }}>

                  <CustomSearch
                    style={{ marginRight: "20px" }}
                    options={filters}
                    change={this.onChangeHandler}
                    filterOption={filterOption}
                    inputSearch={inputSearch}
                  />
                  {/* <div className="select_with_input status_select_input ml-10" style={{ marginRight: "10px" }}>
                    <UncontrolledDropdown className="status_select_div " style={{ minWidth: "115px" }}>
                      <DropdownToggle className="pr-0 drop_down_container">
                        <Media className="capitalize" style={{ fontSize: "0.875rem !important" }}>
                          {filterOption === "name"
                            ? "Client"
                            : filterOption === "title"
                              ? "Title" : "Client"
                          }
                          <i className="fas fa-angle-down" style={{ marginLeft: "20px" }}></i>
                        </Media>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-arrow" bottom style={{ minWidth: "130px" }}>
                        {" "}
                        <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.onChangeSelectHandler("name")}
                        >
                          Client
                        </DropdownItem>
                      </DropdownMenu>
                      <DropdownMenu className="dropdown-menu-arrow" bottom style={{ minWidth: "130px" }}>
                        {" "}
                        <DropdownItem
                          className="dropdown_item"
                          onClick={() => this.onChangeSelectHandler("title")}
                        >
                          Title
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    <Input
                      aria-label="Text input with dropdown button"
                      value={inputSearch}
                      name="inputSearch"
                      placeholder="search by"
                      onChange={this.onChangeHandler}
                    />
                  </div> */}
                  <CustomDropDown
                    width={"126px"}
                    setStatus={this.setStatus}
                    status={statusSearch}
                    statusList={["all", "created", "converted", "cancelled"]}
                  ></CustomDropDown>
                </div>
                {input_value && (inputSearch || statusSearch) && (
                  <div className="right_filter_container">
                    <Button_without_Border
                      click={this.handleReset}
                      btnLabel={"clear_filter"}
                    ></Button_without_Border>
                  </div>
                )}
              </div>
            </div>

            <Row>
              <div className="col new-table">
                <Card className="shadow card_with_paginator">
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col" style={{ width: '26%' }}>
                          Title
                        </th>
                        <th scope="col" style={{ width: '26%' }}>Client</th>
                        <th scope="col" style={{ width: '26%' }}>Email</th>
                        <th scope="col" style={{ width: '10%' }}></th>
                        <th scope="col" style={{ width: "0%" }}></th>
                      </tr>
                    </thead>
                    {leadList && leadList.length !== 0 ? (
                      <tbody>
                        {leadList.map((list, i) => (
                          <tr key={i}>
                            <td className="capitalize ">
                              <div>
                                {list.title
                                  ? this.capitalizeFirstLetter(list.title)
                                  : "-"}
                              </div>
                            </td>
                            <td>
                              <div>{`${list.name}`}</div>
                            </td>

                            <td>
                              <div
                                // style={{
                                //   color: `${list.status === "converted"
                                //     ? "#07A84E"
                                //     : list.status === "cancelled"
                                //       ? "#F75F18"
                                //       : "#FFCC3D"
                                //     }`, fontWeight: "500"
                                // }}
                              >
                                {list.email}
                              </div>
                            </td>
                            <td>
                              {list.status === "converted" ? (
                                <div>
                                  <img
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      this.props.history.push({
                                        pathname: `estimates/AddEstimates`,
                                        state: { lead: list, type: "lead" },
                                      });
                                    }}
                                    src={SendEstimate}
                                    alt="sendEstimate"
                                    data-tip="Create Estimate"
                                    data-iscapture="true"
                                    data-for="mainIconInfo"
                                    data-place="top"
                                  />
                                  <ReactTooltip
                                    place="top"
                                    type="info"
                                    effect="solid"
                                    id="mainIconInfo"
                                    html={true} />
                                </div>
                              ) : (
                                // <div
                                //   style={{
                                //     border: "1px solid #72569C ",
                                //     textAlign: "center",
                                //     padding: "10px",
                                //     borderRadius: "3px",
                                //     color: "#72569C",
                                //     fontWeight: "500",
                                //     cursor: "pointer",
                                //   }}
                                //   onClick={() => {
                                //     this.props.history.push({
                                //       pathname: `add_estimates`,
                                //       state: { lead: list, type: "lead" },
                                //     });
                                //   }}
                                // >
                                //   Send Estimate
                                // </div>
                                <div></div>
                              )}
                            </td>

                            <td>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  gap: "20px",
                                }}
                              >
                                <div>
                                  <ToolTip label="Timeline">
                                    <img
                                      onClick={() =>
                                        this.setState({
                                          isTimeLineOpen: true,
                                          lead_id: list.lead_id,
                                        })
                                      }
                                      style={{ cursor: "pointer" }}
                                      src={projectTimeline}
                                    ></img>
                                  </ToolTip>
                                </div>
                                {list.status !== "converted" && (
                                  <UncontrolledDropdown
                                    nav
                                    className="flexy-content position_u"
                                  >
                                    <DropdownToggle
                                      className="pr-0 nav-notice"
                                      nav
                                    >
                                      <Media className="align-items-center">
                                        <img src={showMore} alt="show more" />
                                      </Media>
                                    </DropdownToggle>
                                    <DropdownMenu
                                      className="dropdown-menu-arrow min-width-drop"
                                      right
                                    >
                                      <DropdownItem
                                        onClick={() => this.editProject(list)}
                                      >
                                        <span>Edit</span>
                                      </DropdownItem>
                                      {list.status !== "cancelled" &&
                                        <DropdownItem
                                          onClick={() =>
                                            this.leadConversionUpdate(
                                              "cancel",
                                              list
                                            )
                                          }
                                        >
                                          <span>Cancel</span>
                                        </DropdownItem>}
                                      <DropdownItem
                                        onClick={() =>
                                          this.leadConversionUpdate(
                                            "convert",
                                            list
                                          )
                                        }
                                      >
                                        <span>Convert</span>
                                      </DropdownItem>

                                      <DropdownItem
                                        onClick={() =>
                                          this.setState({
                                            notesAddToggle: true,
                                            lead_id: list.lead_id,
                                          })
                                        }
                                      >
                                        <span>Add Notes</span>
                                      </DropdownItem>

                                      {/* <DropdownItem
                                  onClick={() => this.editProject(list)}
                                >
                                  <span>Edit</span>
                                </DropdownItem> */}
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                )}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td className="no-data">No Leads Available</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          {/* <td></td> */}
                          {/* <td></td> */}
                          {/* <td></td> */}
                        </tr>
                      </tbody>
                    )}
                  </Table>
                </Card>
              </div>
            </Row>

            <Row className="align-items-center header-div jus-space new-margin-page paginator_box">
              <div className="paginate-flex-box ">
                <div
                  className="paginate-box"
                  onClick={() => this.handleDecrementPage()}
                >
                  <p
                    className={
                      page !== 1 ? "page_available" : "page_unavailable"
                    }
                  >
                    Previous
                  </p>
                </div>
                <div
                  className="paginate-box"
                  onClick={() => this.handleIncrementPage()}
                >
                  <p
                    className={
                      leadList.length < 10
                        ? "page_unavailable"
                        : "page_available"
                    }
                  >
                    Next
                  </p>
                </div>
              </div>
            </Row>
          </CustomListingContainer>

          <Card
            className={open ? "exportSideBar" : "toggle-sidebar"}
          >
            {open && (
              <AddLead
                type="lead"
                props={this}
                leadID={this.state.lead_id}
                close={this.onClose}
                addLead={this.lead_accum}
                toast={this.Showtoast}
              />
            )}
          </Card>

          <Card
            className={
              this.state.isTimeLineOpen ? "exportSideBar" : "toggle-sidebar"
            }
          >
            {this.state.isTimeLineOpen && (
              <TimeLine
                id={this.state.lead_id}
                closeTimelineModal={this.closeTimeline}
                from={"lead"}
              ></TimeLine>
            )}
          </Card>

          <Modal
            isOpen={notesAddToggle}
            centered={true}
            style={{ minWidth: "35rem", padding: "2rem" }}
          >
            <div style={{ padding: "20px" }}>
              <div className="conCanMod" style={{ textAlign: "center" }}>
                Add Notes
              </div>
              {/*  */}
              <textarea
                className="text-area-add-project"
                value={notes}
                style={{
                  fontSize: "14px",
                  color: "#212529",
                  border: "1px solid #f0f0f0",
                  marginBottom: "16px",
                }}
                maxLength={500}
                name="notes"
                onChange={(e) => {
                  this.setState({ notes: e.target.value });
                }}
              />
              <div style={{ justifyContent: "center" }} className="flexEnd">
                <Button_without_Border
                  btnLabel={"Cancel"}
                  style={{ marginRight: "20px" }}
                  click={() =>
                    this.setState({
                      notesAddToggle: false,
                      notes: "",
                      lead_id: "",
                      notesbtn: false,
                    })
                  }
                />
                <Custom_Button
                  style={{ minWidth: "50px" }}
                  isPrimary={true}
                  isLoading={notesbtn}
                  btnLabel={"Add"}
                  click={() => this.addNotes()}
                />
              </div>
            </div>
          </Modal>

          {this.state.lead_id && (
            <ConvertCancelModal
              props={this}
              toggle={() => this.toggleModal()}
              isOpen={openModal}
              subject={type}
              lead={this.state.lead_id}
            ></ConvertCancelModal>
          )}
        </div>
      </>
    );
  }
}

export default Leads;
