import Index from "views/Index.js";
import Profile from "views/examples/Profile.js";
import Login from "views/examples/Login.js";
import Invoices from "views/Invoices/Invoices";
import Requests from "views/Request/Requests";
import ResetPassword from "views/examples/Reset_password";
import RequestDetail from "views/Request/RequestDetail";
import Terms from "views/TermsPolicy/Terms";
import AcceptOrRejectProposal from "views/externalPages/AcceptOrRejectProposal";
import ClientPage from "views/externalPages/clientPage";
import Policy from "views/TermsPolicy/Policy";
import Payouts from "views/Payout/Payouts";
import VerifyEmail from "views/examples/VerifyEmail";
import ResetVerify from "views/examples/ResetVerify";
import DashboarInactive from "./assets/img/brand/dashboardInactive.svg";
import DashboarActive from "./assets/img/brand/dashboardActive.svg";
import RequestInactive from "./assets/img/brand/RequestInactive.svg";
import RequestActive from "./assets/img/brand/RequestActive.svg";
import InvoiceInactive from "./assets/img/brand/InvoiceInactive.svg";
import InvoiceActive from "./assets/img/brand/InvoiceActive.svg";
import PayoutInactive from "./assets/img/brand/PayoutInactive.svg";
import PayoutActive from "./assets/img/brand/PayoutActive.svg";
import PersonalDashboard from "views/dashboardDesign/PersonalDashboard";
import Maintenance from "views/Maintenance/Maintenance";
import MaintenanceDetail from "./views/Maintenance/maintenanceDetail";
import Calender from "./views/Calender/calender.js";
import ActiveContractors from "views/Subcontractor/activeContractors";
import InviteContractors from "views/Subcontractor/inviteContractors";
import { isSub_Contractor } from "constants/utils";
import AddRequest from "views/Open Request/AddRequest";
import OpenRequest from "views/Open Request/openRequest";
import downloadMobileApp from "views/infoScreen/downloadMobileApp";
import Project from "views/Project/Project";
import Client from "views/Project/Client";
import ProjectDetail from "views/Project/ProjectDetail";
import ProjectOverview from "views/Project/ProjectOverview";
import Task from "views/Task/Task";
import ProjectList from "views/Project/project_list";
import TaskList from "views/Task/TaskList";
import workOrderList from "views/Project/workOrderList";
import changeOrderList from "views/Project/changeOrderList";
import TaskLists from "views/Project/AllTaskList";
import Leads from "views/Crm/Leads/leads";
import Proposal from "views/Crm/Proposal";
import AddEstimate from "views/Crm/addProposal";
import Photos from "views/Project/photos";
import DocumentsModule from "views/Project/documents";
import Comments from "views/Project/comments";
import payments from "views/Project/payments";
import Tax from "views/Crm/Tax";
import ClientProjectDetail from "views/externalPages/client/clientProjectDetail";
import ClientPayment from "views/externalPages/client/clientPaymentPage";
import ClientDocument from "views/externalPages/client/clientDocuments";
import ClientPhoto from "views/externalPages/client/clientPhotos";
import ProjectLists from "views/externalPages/client/projectLists";
import PurchaseOrders from "views/Project/PurchaseOrders";
import ClientProfile from "views/externalPages/client/clientProfile";
import ChangeOrders from "views/externalPages/client/clientChangeOrdersLists";
import PaymentOverview from "views/Project/paymentOverview";
import projectChangeOrder from "views/Project/projectChangeOrders";
import newProjectDetail from "views/Project/newProjectDetail";
import projectWorkOrder from "views/Project/projectWorkOrder";
import PreviewProposal from "views/Crm/EstimatePreview/PreviewProposal";
import AddProject from "views/Crm/addProject";
import Overview from "views/Crm/Overview/overview";
import Estimates from "views/Crm/Estimates/estimate";
import NewWorkOrder from "views/Project/newWorkOrder";
import NewChangeOrder from "views/Project/newChangeOrder";
import AddEstimates from "views/Crm/addEstimate";
import ProjectDetailTaskList from "views/Project/ProjectDetailTaskList";
// import PurchaseOrders from "views/Project/PurchaseOrders";
// import ClientProjectDetail from "views/externalPages/clientProjectDetail";
import WorkOrder from "views/externalPages/workOrder";
import OtherProsContractors from "views/Subcontractor/otherProsContractors";
import AddWorkOrder from "views/Project/addWorkOrder";
import InvoiceList from "views/Project/invoiceList";
import PaymentList from "views/Project/paymentList";
import paymentList from "views/Project/paymentList";

var routes = [
  {
    path: "/user-profile",
    name: "",
    icon: null,
    component: Profile,
    layout: "/admin",
  },
  {
    path: "/password",
    name: "",
    icon: null,
    component: Profile,
    layout: "/admin",
  },
  {
    path: "/calender",
    name: "Calendar",
    icon: null,
    component: Calender,
    layout: "/admin",
  },

  {
    path: "/add_estimates",
    name: "Estimates",
    icon: null,
    component: AddEstimate,
    layout: "/admin",
  },
  {
    path: "/estimates/AddEstimates",
    name: "Estimates",
    icon: null,
    component: AddEstimates,
    layout: "/admin",
  },
  // !isSub_Contractor() && {
  //   path: "/leads",
  //   name: "Leads",
  //   icon: null,
  //   component: Leads,
  //   layout: "/admin",
  // },
  {
    path: "/tasks",
    name: "",
    icon: null,
    component: Task,
    layout: "/admin",
  },
  {
    path: "/tasks_list",
    name: "",
    icon: "",
    component: TaskList,
    layout: "/admin",
  },
  {
    path: "/projects/detail/projectTasks/:id",
    name: "",
    icon: "",
    component: ProjectDetailTaskList,
    layout: "/admin",
  },
  !isSub_Contractor() && {
    path: "/projects",
    name: "",
    icon: null,
    component: Project,
    layout: "/admin",
  },
  !isSub_Contractor() && {
    path: "/taxes",
    name: "",
    icon: null,
    component: Tax,
    layout: "/admin",
  },
  !isSub_Contractor() && {
    path: "/photos",
    name: "",
    icon: null,
    component: Photos,
    layout: "/admin",
  },

  !isSub_Contractor() && {
    path: "/documents",
    name: "",
    icon: null,
    component: DocumentsModule,
    layout: "/admin",
  },

  !isSub_Contractor() && {
    path: "/comments",
    name: "",
    icon: null,
    component: Comments,
    layout: "/admin",
  },

  !isSub_Contractor() && {
    path: "/payments",
    name: "",
    icon: null,
    component: payments,
    layout: "/admin",
  },

  !isSub_Contractor() && {
    path: "/purchase_orders",
    name: "",
    icon: null,
    component: PurchaseOrders,
    layout: "/admin",
  },

  // !isSub_Contractor() && {
  //   path: "/project_overview",
  //   name: "",
  //   icon: null,
  //   component: ProjectOverview,
  //   layout: "/admin",
  // },
  !isSub_Contractor() && {
    path: "/project_overview",
    name: "",
    icon: null,
    component: Overview,
    layout: "/admin",
  },
  !isSub_Contractor() && {
    path: "/leads",
    name: "",
    icon: null,
    component: Leads,
    layout: "/admin",
  },
  {
    path: "/estimates",
    name: "Estimates",
    icon: null,
    component: Estimates,
    layout: "/admin",
  },

  {
    path: "/tasks_lists/:id",
    name: "",
    icon: null,
    component: TaskLists,
    layout: "/admin",
  },

  {
    path: "/tasks_lists",
    name: "",
    icon: null,
    component: TaskLists,
    layout: "/admin",
  },
  {
    path: "/proposal",
    name: "Proposal",
    icon: null,
    component: Proposal,
    layout: "/admin",
  },
  !isSub_Contractor() && {
    path: "/projects/detail/:project_id",
    name: "",
    icon: null,
    component: newProjectDetail,
    layout: "/admin",
  },
  !isSub_Contractor() && {
    path: "/projects/detail/:project_id/project_task_lists",
    name: "",
    icon: null,
    component: TaskLists,
    layout: "/admin",
  },
  !isSub_Contractor() && {
    path: "/projects/detail/:project_id/project_change_order_lists",
    name: "",
    icon: null,
    component: changeOrderList,
    layout: "/admin",
  },
  !isSub_Contractor() && {
    path: "/projects/detail/:project_id/project_work_order_lists",
    name: "",
    icon: null,
    component: workOrderList,
    layout: "/admin",
  },
  !isSub_Contractor() && {
    path: "/projects/detail/:project_id/project_invoice_lists",
    name: "",
    icon: null,
    component: InvoiceList,
    layout: "/admin",
  },
  !isSub_Contractor() && {
    path: "/projects/detail/:project_id/project_payment_lists",
    name: "",
    icon: null,
    component: paymentList,
    layout: "/admin",
  },

  !isSub_Contractor() && {
    path: "/projects/detail/workOrder/:project_id",
    name: "",
    icon: null,
    component: AddWorkOrder,
    layout: "/admin",
  },

  !isSub_Contractor() && {
    path: "/project_list",
    name: "",
    icon: null,
    component: ProjectList,
    layout: "/admin",
  },
  !isSub_Contractor() && {
    path: "/overview_payment",
    name: "",
    icon: null,
    component: PaymentOverview,
    layout: "/admin",
  },
  !isSub_Contractor() && {
    path: "/changeOrders",
    name: "",
    icon: null,
    component: projectChangeOrder,
    layout: "/admin",
  },
  !isSub_Contractor() && {
    path: "/workOrders",
    name: "",
    icon: null,
    component: NewWorkOrder,
    layout: "/admin",
  },
  !isSub_Contractor() && {
    path: "/new/changeOrders",
    name: "",
    icon: null,
    component: NewChangeOrder,
    layout: "/admin",
  },
  // !isSub_Contractor() && {
  //   path: "/new/workOrders",
  //   name: "",
  //   icon: null,
  //   component: NewWorkOrder,
  //   layout: "/admin",
  // },
  !isSub_Contractor() && {
    path: "/projectDetail",
    name: "",
    icon: null,
    component: ProjectDetail,
    layout: "/admin",
  },
  !isSub_Contractor() && {
    path: "/clients",
    name: "",
    icon: null,
    component: Client,
    layout: "/admin",
  },
  !isSub_Contractor() && {
    path: "/request/add",
    name: "AddRequest",
    icon: null,
    component: AddRequest,
    layout: "/admin",
  },
  {
    path: "/request/update/:id",
    name: "AddRequest",
    icon: null,
    component: AddRequest,
    layout: "/admin",
  },
  {
    path: "/maintenance",
    name: "Maintenance",
    merge: "",
    icon: null,
    component: Maintenance,
    layout: "/admin",
  },
  {
    path: "/projects/add_project",
    name: "Add Project",
    merge: "",
    icon: null,
    component: AddProject,
    layout: "/admin",
  },
  {
    path: "/project/update/:project_id",
    name: "Add Project",
    merge: "",
    icon: null,
    component: AddProject,
    layout: "/admin",
  },
  {
    path: "/estimates/preview",
    name: "preview",
    merge: "",
    icon: null,
    component: PreviewProposal,
    layout: "/admin",
  },
  !isSub_Contractor() && {
    path: "/activeContractors",
    name: "Active",
    merge: "",
    icon: null,
    component: ActiveContractors,
    layout: "/admin",
  },
  !isSub_Contractor() && {
    path: "/inviteContractor",
    name: "Invites",
    merge: "",
    icon: null,
    component: InviteContractors,
    layout: "/admin",
  },
  !isSub_Contractor() && {
    path: "/otherProsContractor",
    name: "Other Pros",
    merge: "",
    icon: null,
    component: OtherProsContractors,
    layout: "/admin",
  },
  {
    path: "/index",
    name: "Dashboard",
    merge: "",
    icon: DashboarActive,
    image: DashboarInactive,
    component: Index,
    layout: "/admin",
  },
  {
    path: "/personal_dashboard",
    name: "Dashboard",
    merge: "",
    icon: DashboarActive,
    image: DashboarInactive,
    component: PersonalDashboard,
    layout: "/admin",
  },

  {
    path: "/requests",
    name: "Requests",
    merge: "RequestProp",
    icon: RequestActive,
    image: RequestInactive,
    component: Requests,
    layout: "/admin",
  },
  !isSub_Contractor() && {
    path: "/open_request",
    name: "Open Requests",
    merge: "RequestProp",
    icon: RequestActive,
    image: RequestInactive,
    component: OpenRequest,
    layout: "/admin",
  },

  {
    path: "/invoices",
    name: "Invoices",
    merge: "InvoiceProp",
    icon: InvoiceActive,
    image: InvoiceInactive,
    component: Invoices,
    layout: "/admin",
  },

  {
    path: "/reset_password/:token",
    name: "",
    merge: "",
    icon: "",
    component: ResetPassword,
    layout: "/auth",
  },
  {
    path: "/requests/detail/:request_id",
    name: "",
    merge: "",
    icon: "",
    component: RequestDetail,
    layout: "/admin",
  },

  {
    path: "/maintenances/detail/:maintenance_id",
    name: "",
    merge: "",
    icon: null,
    component: MaintenanceDetail,
    layout: "/admin",
    exact: true,
  },
  {
    path: "/terms",
    name: "",
    merge: "",
    icon: "",
    component: Terms,
    layout: "/auth",
  },
  {
    path: "/estimate/:id",
    name: "",
    merge: "",
    icon: "",
    component: AcceptOrRejectProposal,
    layout: "/auth",
  },
  {
    path: "/work_order/:id",
    name: "",
    merge: "",
    icon: "",
    component: WorkOrder,
    layout: "/auth",
  },

  // {
  //   path: "/client/:id",
  //   name: "",
  //   merge: "",
  //   icon: "",
  //   component: ClientPage,
  //   layout: "/auth",
  //   Children: [
  //     {
  //       path: "/profile",
  //       name: "",
  //       merge: "",
  //       icon: "",
  //       component: ClientProfile,
  //       layout: "/auth",
  //     },

  //     {
  //       path: "/:id/payments",
  //       name: "",
  //       merge: "",
  //       icon: "",
  //       component: ClientPayment,
  //       layout: "/auth",
  //     },
  //     {
  //       path: "/:id/change_orders",
  //       name: "",
  //       merge: "",
  //       icon: "",
  //       component: ChangeOrders,
  //       layout: "/auth",
  //     },
  //     {
  //       path: "/:id/documents",
  //       name: "",
  //       merge: "",
  //       icon: "",
  //       component: ClientDocument,
  //       layout: "/auth",
  //     },
  //     {
  //       path: "/:id/photos",
  //       name: "",
  //       merge: "",
  //       icon: "",
  //       component: ClientPhoto,
  //       layout: "/auth",
  //     },

  //     {
  //       path: "/:id",
  //       name: "",
  //       merge: "",
  //       icon: "",
  //       component: ClientProjectDetail,
  //       layout: "/auth",
  //     },
  //     {
  //       path: "/",
  //       name: "",
  //       merge: "",
  //       icon: "",
  //       component: ProjectLists,
  //       layout: "/auth",
  //     },
  //   ],
  // },
  // {
  //   path: "/client/:id/:id/payments",
  //   name: "",
  //   merge: "",
  //   icon: "",
  //   component: ClientPayment,
  //   layout: "/auth",
  // },
  // {
  //   path: "/client/:id/:id/documents",
  //   name: "",
  //   merge: "",
  //   icon: "",
  //   component: ClientDocument,
  //   layout: "/auth",
  // },
  // {
  //   path: "/client/:id/:id/photos",
  //   name: "",
  //   merge: "",
  //   icon: "",
  //   component: ClientPhoto,
  //   layout: "/auth",
  // },
  // {
  //   path: "/client/:id/:id",
  //   name: "",
  //   merge: "",
  //   icon: "",
  //   component: ClientProjectDetail,
  //   layout: "/auth",
  // },
  // {
  //   path: "/client/:id",
  //   name: "",
  //   merge: "",
  //   icon: "",
  //   component: ClientPage,
  //   layout: "/auth",
  // },

  {
    path: "/policy",
    name: "",
    merge: "",
    icon: "",
    component: Policy,
    layout: "/auth",
  },
  {
    path: "/payouts/:manager_id",
    name: "Payouts",
    merge: "PayoutProp",
    icon: "",
    image: "",
    component: Payouts,
    layout: "/admin",
  },
  {
    path: "/payouts",
    name: "Payouts",
    merge: "PayoutProp",
    icon: PayoutActive,
    image: PayoutInactive,
    component: Payouts,
    layout: "/admin",
  },

  {
    path: "/verify_email",
    name: "",
    merge: "",
    icon: "",
    image: "",
    component: VerifyEmail,
    layout: "/auth",
  },
  {
    path: "/reset_verify",
    name: "",
    merge: "",
    icon: "",
    image: "",
    component: ResetVerify,
    layout: "/auth",
  },
  {
    path: "/invoices?status=created",
    name: "",
    merge: "",
    icon: "",
    image: "",
    component: Invoices,
    layout: "/admin",
  },

  {
    path: "/requests?status=pending",
    name: "Requests",
    merge: "RequestProp",
    icon: "",
    image: "",
    component: Requests,
    layout: "/admin",
  },

  {
    path: "/login",
    name: "",
    merge: "",
    icon: null,
    component: Login,
    layout: "/auth",
  },
  {
    path: "/mobileApp",
    name: "",
    merge: "",
    icon: null,
    component: downloadMobileApp,
    layout: "/auth",
  },
];
export default routes;
