import React, { Component } from "react";
import {
  Input,
  DropdownToggle,
  UncontrolledDropdown,
  Media,
  InputGroup,
  InputGroupText,
  Spinner,
  FormGroup,
  Card,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import closeIcon from "../../assets/img/brand/close.svg";
import dateLight from "../../assets/img/brand/datePicker.svg";
import moment from "moment";
import ApiService from "constants/ApiService";
import {
  showSuccess,
  showError,
  uploadCloudinary,
  Isvalidname,
} from "constants/utils";
import AddClient from "views/Project/addClient";
import { CustomListingContainer } from "components/Container/CustomListingContainer";
import { ListingHeaderLayout } from "components/Container/CustomListingContainer";
import { ListingBodyLayout } from "components/Container/CustomListingContainer";
import ToastList from "components/Toast/ToastList";
import { remove_underscore_capitalize } from "constants/utils";
import { CustomInputFields } from "components/FilterFields/Filterfields";
import { CustomDropDownSearch } from "components/FilterFields/Filterfields";
import { CustomSingleDatePicker } from "components/FilterFields/Filterfields";
import { CustomTextArea } from "components/FilterFields/Filterfields";
let parentBreadCrumb;
let project_id;
class AddProject extends Component {
  constructor(props) {
    super(props);
    project_id = this.props.match.params.project_id;
    this.inputKey = Date.now();
    this.fileInputRef = React.createRef();
    this.myElementRef = React.createRef();
    this.myInputElementRef = React.createRef();
    this.state = {
      isLoading: true,
      estimate_id: "",
      name: "",
      response: "",
      client_name: "",
      clientsList: [],
      estimateLists: [],
      subContractor_name: "",
      sub_contractorList: [],
      start_date: "",
      image: [],
      toast: false,
      showErrorBorder: false,
      toastType: "",
      selected_team: "",
      actual_start: "",
      end_date: "",
      contractor_type: "",
      subContractor: {},
      imageLoading: false,
      desc: "",
      tags: [],
      contractorInput: "",
      btnLoad: false,
      edit: false,
      initAddProjectLoading: true,
      tag: "",
      subContractor_id: "",
      clientAdd: false,
      isSearchOpen: false,
      activeIndex: -1,
      disabled: false,
      isApiCallPending: false,
      file: null,
      projectStartDate: null,
      projectActualStarDate: null,
      projectEndDate: null,
      otherProObj: {},
      project_id: "",
      subject: "",
      listsBoxOpen: false,
      populate: false,
      listsLoad: false,
      isEstimatePresent: true,
      edit_project: false,
      estimate: false,
      estid_sub: null,
      estimate_present: true,
    };
    this.debouncedApiCall = this.debounce(this.createProjectHandler, 1000);
  }

  debounce(func, delay) {
    let timeoutId;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(context, args);
      }, delay);
    };
  }

  minMaxStartDate = (current) => {
    const today = moment().startOf("day");
    const minDate = moment().subtract(30, "days").startOf("day");
    const maxDate = this.state.end_date
      ? moment(this.state.end_date)
      : moment().add(30, "days").startOf("day");
    return current && (current < minDate || current > maxDate);
  };

  minMaxEndDate = (current) => {
    const today = moment().startOf("day");
    const minDate = this.state.start_date
      ? moment(this.state.start_date)
      : moment().subtract(14, "days").startOf("day");
    const maxDate = moment().add(1, "year");
    return current && (current < minDate || current > maxDate);
  };

  componentDidMount = async () => {
    parentBreadCrumb = this.props?.location?.state?.type ?? "projects";
    if (project_id) {
      this.setState({ isLoading: true });
      let response = await ApiService.getAPI(
        `contractor/project/detail/${project_id}`
      );
      let project = response?.data?.project;
      if (response.success) {
        this.setState({
          edit_project: true,
          name: project.name,
          client_name: project.client.name,
          client_id: project.client.id,
          start_date: new Date(project.start_date),
          end_date: new Date(project.end_date),
          desc: project.desc,
          tags: project.tags,
          estid_sub: project?.estimate_id + "/" + project?.subject,
          project_id: project.project_id,
          initAddProjectLoading: false,
          isLoading: false,
          subject: project.estimate_id,
          estimate_id: project.estimate_id,
        });
      } else {
        showError(this, response.message);
        this.setState({ isLoading: false });
      }
    } else if (this.props?.location?.state?.type === "estimates") {
      const { lead, estimate_id, subject } = this.props?.location?.state ?? {};
      this.setState({ initAddProjectLoading: false, isLoading: false }, () => {
        this.setState(
          {
            client_name: lead.name,
            estimate_id: estimate_id,
            subject: subject,
            client_id: lead.lead_id || lead.id,
            estimate: true,
            estid_sub: estimate_id + (subject ? " / " + subject : ""),
            edit: true,
          },
          () => {}
        );
      });
    } else {
      this.getEstimateDetails();
      this.getEstimateLists()
      this.setState({ isLoading: false });
    }
  };

  getClientLists = async () => {
    let response = await ApiService.getAPI(`contractor/client?status=active`);
    !this.state.edit && this.setState({ initAddProjectLoading: false });
    if (response.success) {
      let { clients } = response.data;
      this.setState({
        clientsList: clients,
      });
    }
  };

  imageHandler = async (e) => {
    // this.setState({file:e.target.files[0]},() => {this.imageUploader(this.state.file)})
    let file = e.target.files[0];
    let { image } = this.state;
    if (file) {
      this.setState({ imageLoading: true });
    }
    if (
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/jpeg"
    ) {
      if (file.size < 2e6) {
        let data = await uploadCloudinary(file, "host");
        if (data.url) {
          let imgArr = [...image];
          let imageObj = {
            format: data.format,
            public_id: data.public_id,
            version: `v${data.version}`,
            type: "image",
          };
          imgArr.push(imageObj);
          this.setState({
            image: imgArr,
            imageLoading: false,
          });
        } else {
          this.setState({ imageLoading: false });
          e.target.value = null;
          return showError(this.props, "Could not upload image");
        }
      } else {
        this.setState({ imageLoading: false });
        e.target.value = null;
        return showError(this.props, "File size should not exceeds 2 MB");
      }
    } else {
      this.setState({ imageLoading: false });
      e.target.value = null;
      return showError(
        this.props,
        "Invalid Format , upload only .png, .jpg, .jpeg"
      );
    }
  };

  getEstimateDetails = async () => {
    let response = await ApiService.getAPI(
      `contractor/estimate?limit=10&page=1&status=accepted`
    );
    this.setState({
      estimate_present: response.data.estimates.length ? true : false,
    });
  };

  imageUploader = async (file) => {
    let { image } = this.state;
    if (this.fileInputRef.current) {
      this.fileInputRef.current.value = "";
    }
    if (file) {
      this.setState({ imageLoading: true });
    }
    if (
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/jpeg"
    ) {
      if (file.size < 2e6) {
        let data = await uploadCloudinary(file, "host");
        if (data.url) {
          let imgArr = [...image];
          let imageObj = {
            format: data.format,
            public_id: data.public_id,
            version: `v${data.version}`,
            type: "image",
          };
          imgArr.push(imageObj);
          this.setState({
            image: imgArr,
            imageLoading: false,
          });
        } else {
          this.setState({ imageLoading: false });
          return showError(this.props, "Could not upload image");
        }
      } else {
        this.setState({ imageLoading: false });
        return showError(this.props, "File size should not exceeds 2 MB");
      }
    } else {
      this.setState({ imageLoading: false });
      return showError(
        this.props,
        "Invalid Format , upload only .png, .jpg, .jpeg"
      );
    }
  };

  addClient = () => {
    this.setState({ clientAdd: true });
  };

  deleteImage = () => {
    this.setState({ image: [], image_name: "" });
  };

  closeModal = (data) => {
    if (data) this.props.close();
    else this.props.close();
  };

  handleSelect = (contractor) => {
    this.setState({
      otherProObj: {
        id: contractor.contractor_id,
        name: contractor.name,
        phone: contractor.phone,
        email: contractor.email,
      },
      contractorInput: contractor.userInput,
    });
  };
  createProjectHandler = async () => {
    let {
      name,
      subject,
      client_name,
      isEstimatePresent,
      start_date,
      end_date,
      client_id,
      edit_project,
      desc,
      project_id,
      tags,
      image,
      estimate_id,
      otherProObj,
      contractorInput,
      estimateLists,
    } = this.state;
    let { type = "project" } = this.props;
    if (!name) return showError(this, "Please enter a name");
    else if (!Isvalidname(name))
      return showError(this, "Please enter a valid name");
    else if (isEstimatePresent && !client_name && !estimateLists.length) {
      return showError(this, "Please select the estimate");
    } else if (
      type === "project" || type === "estimate"
        ? !client_name
        : Object.keys(otherProObj).length === 0 || !contractorInput
    )
      return showError(
        this,
        `Please select a ${type === "project" ? "estimate" : "sub contractor"}`
      );
    else if (!start_date)
      return showError(this, "Please select the start date");
    else if (!end_date) return showError(this, "Please select the end date");
    // else if ( (start_date > actual_start )) return showError(this.props.props,"Actual start could not be lesser than start date")
    this.setState({ btnLoad: true, isApiCallPending: true });
    let data = {
      name: name,
      client: {
        id: client_id,
        name: client_name,
      },
      subject: subject,
      start_date: start_date,
      end_date: end_date,
      desc: desc,
      tags: tags,
      image: image,
      estimate_id: estimate_id,
    };
    let response = edit_project
      ? await ApiService.patchAPI(`contractor/project/${project_id}`, data)
      : await ApiService.postAPI("contractor/project", data);
    this.setState({ disabled: false, isApiCallPending: false });
    if (response.success) {
      this.setState({
        btnLoad: false,
        open: false,
        name: "",
        start_date: "",
        end_date: "",
        actual_start: "",
        desc: "",
        tags: [],
        client_name: "",
        client_id: "",
      });
      showSuccess(this, response.message);
      setTimeout(() => {
        this.props.history.push(`/admin/projects/detail/${response.data.project_id}`);
      }, [1500]);
    } else {
      this.setState({ btnLoad: false });
      showError(this, response.message);
    }
  };

  onInputSearch = (e) => {
    let tempLists = this.state.clientsList;
    let searchName = e.target.value;
    // if(!searchName.length) return tempLists = []
    const tempArray = tempLists.filter((obj) => {
      let name = obj.name;
      // return obj.name.trim().toLowercase().includes(searchName) && obj
    });
  };

  onTagChange = (e) => {
    let { tag, tags } = this.state;
    if (["Enter", "Tab"].includes(e.key)) {
      e.preventDefault();
      let value = tag.trim();
      if (value.length) {
        tags.push(value);
        this.setState({ tags: [...tags], tag: "" });
      }
    } else if (["Backspace"].includes(e.key) && !tag) {
      if (tags.length) {
        tags.pop();
        this.setState({ tags: [...tags], tag: "" });
      }
    }
  };

  getListDetail = async (data) => {
    this.setState(
      {
        subject: `${data.subject}`,
        client_name: data.lead.name,
        client_id: data.lead.id,
        estimate_id: data.estimate_id,
        estid_sub: `${data.estimate_id} / ${data.subject}`,
      },
      () => {
        this.setState({ listsBoxOpen: false, isNameAlreadyPresent: true });
      }
    );
  };

  getEstimateLists = async (e) => {
    this.setState({
      listsLoad: true,
      isNameAlreadyPresent: false,
      subject: "",
      client_name:""
    });
    let searchTerm = e || "";
    this.setState({ subject: searchTerm, estid_sub: searchTerm });
    let response = await ApiService.getAPI(
      `contractor/estimate/accepted?subject=${searchTerm}`
    );
    this.setState({ initAddProjectLoading: false });
    if (response.success) {
      let { estimates } = response.data;
      this.setState(
        {
          estimateLists: estimates,
          listsLoad: false,
        },
        () => {
          if (this.state.estimateLists?.length > 0) {
            this.setState({ listsBoxOpen: true });
          } else {
            this.setState({ showErrorBorder: true });
            // Remove showErrorBorder after 3 seconds
            setTimeout(() => {
              this.setState({ showErrorBorder: false });
            }, 3000);

            this.setState({ listsBoxOpen: false });
          }
        }
      );
    }
  };

  dateContion = (from, event) => {
    if (from === "start") {
      if (this.props.type === "project") {
        this.setState({ start_date: event });
      }
    }
  };

  showToastMessage = (msg) => {
    showError(this, msg);
  };

  removeTag = (index) => {
    let { tag, tags } = this.state;
    let tempTags = tags;
    tempTags.splice(index, 1);
    this.setState({ tags: [...tempTags] });
  };

  onTagAreaClick = () => {
    this.myInputElementRef.current.focus();
  };

  onClientClose = () => {
    this.setState({ clientAdd: false }, () => {
      this.getClientLists();
    });
  };

  handleInputChange = (e, from) => {
    this.setState({ [from]: e }, () => {});
  };

  handleStartDate = (e, date) => {
    this.setState({ start_date: e ? new Date(e) : "" });
  };

  handleEndDate = (e, date) => {
    this.setState({ end_date: e ? new Date(e) : "" });
  };
  // setDescription = (value) => {
  //   this.setState({ desc: value });
  // };
  render() {
    const {
      estimate_id,
      name,
      client_name,
      start_date,
      end_date,
      estimateLists,
      desc,
      tags,
      isLoading,
      response,
      edit,
      toast,
      toastType,
      edit_project,
      estid_sub,
      estimate_present,
    } = this.state;

    return isLoading ? (
      <div className="spin-loading">
        <Spinner type="border" color="#077e8b" />
      </div>
    ) : (
      <>
        <div style={{ padding: "20px", backgroundColor: "#fafafa" }} fluid>
          {toast && <ToastList message={response} type={toastType} />}
          <div>
            <div className="maintainenceDetailHeader">
              <div className="manage-con">
                <Breadcrumb>
                  {/* {parentBreadCrumb !== "overview" && (
                    <BreadcrumbItem
                      onClick={() =>
                        this.props.history.push("project_overview")
                      }
                      className="bread-cursor"
                    >
                      CRM
                    </BreadcrumbItem>
                  )} */}
                  <BreadcrumbItem
                    onClick={() => this.props.history.goBack()}
                    className="bread-cursor"
                  >
                    {edit_project
                      ? "Project"
                      : `${remove_underscore_capitalize(parentBreadCrumb)}`}
                  </BreadcrumbItem>
                  <BreadcrumbItem active style={{ color: "#72569c" }}>
                    {edit_project ? "Edit Project" : "New Project"}
                  </BreadcrumbItem>
                </Breadcrumb>
              </div>
            </div>
            <CustomListingContainer>
              <ListingHeaderLayout
                className="estimate_heading_font"
                label={"Create New Project"}
                click={this.createProjectHandler}
                btn_loading={this.state.isApiCallPending}
                primary_buttonLabel={
                  edit_project ? "Save Changes" : "Create Project"
                }
                isPrimary
                // sec_buttonLabel={'preview'}
              ></ListingHeaderLayout>
              <ListingBodyLayout
                style={{ margin: "0 " }}
                insideStyle={{
                  marginBottom: "0",
                  display: "flex",
                  justifyContent: "normal",
                  width: "100%",
                }}
              >
                <div className="cardContainer" style={{ width: "100%" }}>
                  <div style={{ width: "30%" }}>
                    {" "}
                    <label className="customLabel">Name*</label>
                    <CustomInputFields
                      style={{ marginBottom: "10px" }}
                      inputValue={name}
                      type="text"
                      change={this.handleInputChange}
                      from="name"
                      placeHolder="Enter the project name"
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      gap: "5%",
                      marginTop: "8px",
                    }}
                  >
                    <div style={{ width: "30%" }}>
                      {" "}
                      <label className="customLabel">Estimate*</label>
                      <CustomDropDownSearch
                        style={{ marginBottom: "10px", width: "100%" }}
                        className="customDropDownSearch"
                        toSearch="subject"
                        anotherSearch="estimate_id"
                        width={"100%"}
                        placeHolder="Enter the estimate"
                        filteredData={estimateLists}
                        onChange={this.getEstimateLists}
                        listClick={this.getListDetail}
                        value={estid_sub}
                        disabled={edit_project || edit}
                      />
                      {!estimate_present && (
                        <div style={{ fontSize: "12px", color: "red" }}>
                          * No accepted estimates are found
                        </div>
                      )}
                    </div>

                    <div style={{ width: "30%" }}>
                      {" "}
                      <label className="customLabel">Client*</label>
                      <CustomInputFields
                        style={{ marginBottom: "10px" }}
                        inputValue={client_name}
                        type="text"
                        from="email"
                        disabled
                        // placeHolder="Enter the client"
                      />
                    </div>
                  </div>

                  {this.props.type === "estimate" && (
                    <div style={{ marginBottom: "25px" }}>
                      {" "}
                      <label className="customLabel">Estimate Id*</label>
                      <CustomInputFields
                        style={{ marginBottom: "10px" }}
                        inputValue={estimate_id}
                        type="text"
                        from="email"
                        disabled
                        placeHolder="Enter the estimate id"
                      />
                    </div>
                  )}

                  <div
                    style={{
                      marginBottom: "25px",
                      display: "flex",
                      gap: "5%",
                      marginTop: "8px",
                    }}
                  >
                    <div
                      style={{
                        width: "30%",
                      }}
                    >
                      <label className="customLabel">Start Date*</label>
                      <CustomSingleDatePicker
                        value={start_date}
                        change={this.handleStartDate}
                        setMinMaxDate={this.minMaxStartDate}
                        // disabled={edit_project}
                      />
                    </div>
                    <div
                      style={{
                        width: "30%",
                      }}
                    >
                      <label className="customLabel">End Date*</label>
                      <CustomSingleDatePicker
                        value={end_date}
                        change={this.handleEndDate}
                        setMinMaxDate={this.minMaxEndDate}
                        // disabled={edit_project}
                      />
                    </div>
                  </div>

                  <div style={{ marginBottom: "25px", width: "65%" }}>
                    <label className="customLabel">Description</label>
                    <CustomTextArea
                      inputvalue={desc}
                      change={this.handleInputChange}
                      from="desc"
                    />
                  </div>

                  <div style={{ marginBottom: "20px", width: "65%" }}>
                    <label className="customLabel">Tags</label>
                    <div
                      onClick={this.onTagAreaClick}
                      ref={this.myElementRef}
                      className="drawer_desc"
                      style={{
                        padding: "20px",
                        border: "1px solid #E6E6E6",
                        borderRadius: "10px",
                      }}
                    >
                      {tags?.map((string, i) => (
                        <div className="tagChip">
                          {string}
                          <span>
                            <img
                              style={{
                                width: "16px",
                                height: "20px",
                                marginLeft: "10px",
                                cursor: "pointer",
                              }}
                              onClick={() => this.removeTag(i)}
                              src={closeIcon}
                            ></img>
                          </span>
                        </div>
                      ))}
                      <input
                        ref={this.myInputElementRef}
                        onKeyDown={(e) => this.onTagChange(e)}
                        onChange={(e) => this.setState({ tag: e.target.value })}
                        className="tagInput"
                        type="text"
                        value={this.state.tag}
                      ></input>
                    </div>
                  </div>
                </div>
              </ListingBodyLayout>
            </CustomListingContainer>
          </div>
        </div>
        <Card
          className={this.state.clientAdd ? "exportSideBar" : "toggle-sidebar"}
        >
          {this.state.clientAdd && (
            <AddClient
              props={this}
              from="project"
              closeClientModal={this.onClientClose}
              cancel={() => this.setState({ clientAdd: false })}
            />
          )}
        </Card>
      </>
    );
  }
}

export default AddProject;
