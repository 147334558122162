import React, { Component } from "react";
import { Modal, Input, Button, Spinner } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import ApiService from "constants/ApiService";
import closeIcon from "../../assets/img/icons/modalCloseIcon.svg";
import { Custom_Button } from "components/Buttons/Button";
import { getUSFormat } from "constants/utils";
export default class LeadSelect extends Component {
  constructor(props) {
    super(props);
    this.textareaRef = React.createRef();
    this.state = {
      isLoading: true,
      leads: [],
      search_term: "",
      timoutId: "",
      isWorkOrder: false,
    };
  }
  componentDidMount = async () => {
    let response;
    let filteredLeads;
    this.setState(
      { isWorkOrder: this.props?.isWorkOrder || false },
      async () => {
        if (this.state.isWorkOrder) {
          response = await ApiService.getAPI(`contractor/allContractors`);
          filteredLeads = response.data.contractors.splice(0, 5);
        } else {
          response = await ApiService.getAPI(`contractor/lead/list`);
          filteredLeads = response.data.leads.splice(0,5)
        }
        if (response.success) {
          this.setState({
            isLoading: false,
            leads: filteredLeads,
          });
        }
      }
    );
  };

  leadSearch = async () => {
    let name = this.state.search_term;
    let response = await ApiService.getAPI(
      `contractor/lead/list?name=${name}`
    );
    this.setState({ isLoading: false });
    let filteredLeads = response.data.leads;
    if (response.success) {
      this.setState({
        leads: filteredLeads,
      });
    }
  };

  prosSearch = async () => {
    let name = this.state.search_term;
    let response = await ApiService.getAPI(
      `contractor/allContractors${name ? `?name=${name}` : ''}`
    );
    this.setState({ isLoading: false });
    let filteredLeads = response.data.contractors.splice(0, 5);
    if (response.success) {
      this.setState({
        leads: filteredLeads,
      });
    }
  };
  handleInputChange = (event) => {
    this.setState({ search_term: event.target.value }, () => {
      this.setState({ isLoading: true });
      clearTimeout(this.state.timoutId);
      const setTimeOutId = setTimeout(() => {
        if (this.state.isWorkOrder) this.prosSearch();
        else this.leadSearch();
      }, 1000);
      this.setState({ timoutId: setTimeOutId });
    });
  };

  triggerLead = (val) => {
    this.props.selectlead(val);
  };
  capitalizeFirstLetter = (str) => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  render() {
    let { search_term, leads, isLoading, isWorkOrder } = this.state;
    const { toggleModal, closeModal, isOpen, onSubmit, vals } = this.props;

    return (
      <Modal
        className="modal-dialog-centered lead_modal"
        style={{ minWidth: "830px", maxHeight: "361px" }}
        isOpen={isOpen}
        toggle={toggleModal}
      >
        <div style={{ paddingBottom: "15px" }}>
          <div className="leadSelectContainer">
            <div className="selectLead">
              <div
                className="modal-title"
                id="exampleModalLabel"
                style={{ color: "#303030", padding: "0rem" }}
              >
                {isWorkOrder ? "Select Pro" : "Select Lead"}
              </div>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => this.props.leadClose()}
              >
                <img src={closeIcon} />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: "20px",
              }}
            >
              <Input
                style={{
                  fontSize: "14px",
                  color: "#212529",
                  border: "1px solid #f0f0f0",
                }}
                value={search_term}
                type="name"
                // placeholder={`Search For the ${isWorkOrder ? "pros" : "lead"}`}
                placeholder="Search name"
                name="text"
                onChange={this.handleInputChange}
              />
            </div>
          </div>
          <div className="selectLeadFooter leadsList">
            {isLoading ? (
              <div className="spin-loading" style={{ height: "100%" }}>
                <Spinner type="border" color="#2e1a47 spinner-borders" />
              </div>
            ) : leads.length > 0 ? (
              leads.map((val, index) => {
                const isLastIndex = index === leads.length - 1;
                return (
                  <div
                    key={index}
                    style={{
                      display: "grid",
                      gridTemplateColumns:
                        "repeat(auto-fill, minmax(150px, 1fr))",
                      gap: "10px",
                      alignItems: "center",
                      borderTop: "1px solid #E1E8F0",
                      borderRadius: "0",
                      padding: "10px 24px",
                    }}
                  >
                    <div className="fw-500 font_standard wordBreakAll">
                      {this.capitalizeFirstLetter(val.name)}
                    </div>
                    <div className="fw-400 font_standard wordBreakAll">
                      { !isWorkOrder ?   getUSFormat(val.phone) : val?.phone ? getUSFormat(val.phone.national_number) : '-'}
                    </div>
                    <div className="fw-400 font_standard wordBreakAll">
                      {val.email}
                    </div>
                    <Custom_Button
                      btnLabel={"Select"}
                      click={() => this.triggerLead(val)}
                    />
                  </div>
                );
              })
            ) : (
              <div className="spin-loading" style={{ maxHeight: "100%" }}>
                No { isWorkOrder ? 'pros' : 'leads'} available
              </div>
            )}
          </div>
        </div>
      </Modal>
    );
  }
}
