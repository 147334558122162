import moment from "moment";
import cookie from "react-cookies";
import ApiService from "./ApiService";
import queryString from "query-string";
import * as QS from "query-string";
import Env from "constants/Env";

export const remove_underscore_capitalize = (string) => {
  if (!string) return "";
  if (string.includes("_") || string.includes(" ")) {
    return string
      .split(string.includes("_") ? "_" : " ")
      .map(
        (str) =>
          (typeof str === "string" ? str[0].toUpperCase() : str[0]) +
          str.slice(1)
      )
      .join(" ");
  } else if (string?.length) return string[0]?.toUpperCase() + string?.slice(1);
  else return string;
};

export function ifDecimal(amount) {
  return amount % 1 !== 0 ? +amount.toFixed(2) : amount.toFixed(2);
}
export function NumberWithCurrency(amount) {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount)
}
export function formatAmountWithCommas(amount) {
  return amount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
//
export function appendCurrency(amount) {
  return "$" + formatAmountWithCommas(amount);
}

export const CopyEstimateLink = (id, path) => {
  ClipBoardCopy(`${Env.PANEL_LINK}${path}/${id}`);
};
export const linkGenerator = (id, path) => {
  return `${Env.PANEL_LINK}${path}/${id}`;
};
export const downloadDocuments = (estId, from) => {
  return fetch(
    Env.BASE_URL +
      `contractor/${
        // isWorkOrder ? "workOrder" : "estimate"
        from
      }/download/${estId}`,
    {
      method: "GET",
      headers: { Authorization: "Bearer " + getAuthToken() },
    }
  )
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${estId}`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch((error) => {});
};

export const ProjectLink = (client_id, project_id) => {
  ClipBoardCopy(`${Env.CLIENT_PANEL_LINK}${client_id}/${project_id}`);
};
export const ClipBoardCopy = (content) => {
  navigator?.clipboard?.writeText(content);
};

export const isInvalidEmail = (email) => {
  let eRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!eRegex.test(email)) return true;
  else return false;
};

export const IsClientPannel = (props) => {};

export const roundOff = (number) =>
  Math.round((number + Number.EPSILON) * 100) / 100;

export function cleanPhoneNumber(phoneNumber) {
  let cleanedNumber = phoneNumber.replace(/\D/g, "");
  return cleanedNumber.slice(0, 10);
}

export const isInvalidName = (value) => {
  return /^[0-9]+[a-zA-Z]*|[a-zA-Z]+[0-9]+$/.test(value);
};

export const Isvalidname = (value) => {
  return /^[a-zA-Z]+[a-zA-Z0-9 ]*$/.test(value);
};

export function isValidEmail(email) {
  const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
  return emailRegex.test(email);
}

export function returnFirstDegreeObjValue(obj, key) {
  return obj && (obj[key] || obj[key] === 0) ? obj[key] : "-";
}

export function returnPositiveNumber(number) {
  return Math.abs(number);
}

export function redirectToBookings(self, id) {
  self.props.history.push(`/admin/booking/detail/${id}`);
}

export function returnThirdDegreeObjValue(masterobj, obj, key, postion) {
  let result =
    masterobj &&
    masterobj[obj] &&
    masterobj[obj][key] &&
    (masterobj[obj][key][postion] || masterobj[obj][key][postion] === 0);
  return result ? masterobj[obj][key][postion] : "-";
}

export function returnCustomerFirsLastName(
  masterobj,
  obj,
  key,
  position1,
  position2
) {
  let first = returnThirdDegreeObjValue(masterobj, obj, key, position1);
  let last = returnThirdDegreeObjValue(masterobj, obj, key, position2);
  let result =
    first !== "-" && last !== "-"
      ? first + " " + last
      : first !== "-"
      ? first
      : last !== "-"
      ? last
      : "-";
  return result;
}

export function addCommasToNumber(number) {
  if (!number) return 0;
  const numberString = String(number);
  const [integerPart, decimalPart] = numberString.split(".");
  const integerWithCommas = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  if (decimalPart) {
    return integerWithCommas + "." + decimalPart;
  } else {
    return integerWithCommas;
  }
}

export default function returnSecondDegreeObjValue(obj, key, postion) {
  return obj && obj[key] && (obj[key][postion] || obj[key][postion] === 0)
    ? obj[key][postion]
    : "-";
}
export function returnSecoundDegreeObjValue(obj, key, postion) {
  return obj && obj[key] && (obj[key][postion] || obj[key][postion] === 0)
    ? obj[key][postion]
    : "-";
}

export const showMomentFormat = (date) => {
  return moment(date).format("DD-MM-YYYY");
};

export const showCalendarFormat = (date) => {
  return moment(date).format("YYYY-MM-DD");
};

export const isLoggedIn = () => {
  return getAuthToken() ? true : false;
};

export function getAuthToken() {
  return getCookie("contractor_token");
}

export const saveToken = (response) => {
  setCookie("contractor_token", response.data.token);
};

export function getCookie(key) {
  return cookie.load(key);
}

export const setCookie = (key, value) => {
  cookie.save(key, value, {
    path: "/",
    maxAge: 604800,
    secure: false,
  });
};

export const replaceToNormalNumber = (number) => {
  return number.replace(/[- )(]/g, "");
};

export function returnSimpleFormatedDateTime(dateString) {
  return dateString === ""
    ? ""
    : moment(dateString).format("DD MMM YYYY h:mm a");
}

export const uploadCloudinary = async (file, path) => {
  const signResponse = await fetch(ApiService.signInUrl, {
    method: "POST",
    body: JSON.stringify({ path }),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getAuthToken(),
    },
  });

  const signData = await signResponse.json();
  const url =
    ApiService.cloudinary_base_url + signData.data.cloudname + "/auto/upload";
  const formData = new FormData();
  formData.append("file", file);
  formData.append("api_key", signData.data.apikey);
  formData.append("timestamp", signData.data.timestamp);
  formData.append("signature", signData.data.signature);
  formData.append("eager", "c_pad,h_300,w_400|c_crop,h_200,w_260");
  formData.append("folder", signData.data.folder);
  let response;
  await fetch(url, {
    method: "POST",
    body: formData,
  })
    .then((response) => {
      return response.text();
    })
    .then((data) => {
      return (response = JSON.parse(data));
    });
  return response;
};

export const numberRequired = (data) => {
  // only numbers
  return !/^[\+\-]?\d*\.?\d+(?:[Ee][\+\-]?\d+)?$/.test(data);
};
export const allowOnlyRoundNumber = (event) => {
  let data = event.charCode >= 48 && event.charCode <= 57;
  // return
};
export const getUSFormat = (number) => {
  const input = number?.replace(/\D/g, "").substring(0, 10); // First ten digits of input only
  const areaCode = input?.substring(0, 3);
  const middle = input?.substring(3, 6);
  const last = input?.substring(6, 10);
  let newNumber = "";
  if (input?.length > 6) {
    newNumber = `(${areaCode}) ${middle} - ${last}`;
  } else if (input?.length > 3) {
    newNumber = `(${areaCode}) ${middle}`;
  } else if (input?.length > 0) {
    newNumber = `(${areaCode}`;
  }
  return newNumber;
};

export const filterQuery = (query, data) => {
  let filter = query;
  if (filter === query) {
    return `&${query}=${data}`;
  }
};

export const dropDownToggle = (event, open, close) => {
  if (event.target) {
    return event.target.classList.contains("status_select_div")
      ? open()
      : close();
  }
};

export function getIsoString(date) {
  return date !== "" && date !== isNaN ? moment(date).format("YYYY-MM-DD") : "";
}
export function getDateString(date) {
  return date !== "" && date !== isNaN ? moment(date).toISOString() : "";
}
export function getCloudinaryImage(width, height, image, c_type) {
  return (
    ApiService.response_url +
    `/fl_lossy,w_${width},h_${height},${c_type}/${image?.version}/${image?.public_id}.${image?.format}`
  );
}
export function AddClassToBody() {
  let screen = localStorage.getItem("screen");
  let body = document.querySelector("body");
  if (screen === "full") {
    body.classList.add("small_side_bar");
  } else {
    body.classList.remove("small_side_bar");
  }
  return screen;
}

export function getMonthFilter() {
  var thirtyDaysAgo = new Date(new Date().setDate(new Date().getDate() - 30));
  let newDate = thirtyDaysAgo.toISOString();
  return newDate;
}
export function getFinalMonthFilter() {
  return new Date().toISOString();
}
export function returnDateTxt(utcDate) {
  var now = new Date(utcDate);
  var utc = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
  if (utcDate === "") return new Date();
  else return new Date(utc);
}
export function showUtcDate(utcDate) {
  var now = new Date(utcDate);
  var utc = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
  if (utcDate === "-" || utcDate == null || utcDate === undefined) {
    return "-";
  } else {
    return utcDate === ""
      ? new Date()
      : moment(new Date(utc)).format("MMM DD, YYYY");
  }
}

export function showLocaldate(utcDate) {
  if (utcDate === "-" || utcDate === null || utcDate === undefined) {
    return "-";
  } else {
    return moment.utc(utcDate).format("MMM DD, YYYY");
  }
}

export function getStartOfDay(date) {
  return date !== "" && date !== isNaN
    ? moment.utc(moment(date).startOf("day")).local().toISOString()
    : "";
}
export function getDateFormat(dateString) {
  return dateString === "" ||
    dateString === "-" ||
    dateString === null ||
    dateString === undefined
    ? "-"
    : moment(dateString).format("MMM DD,YYYY");
}
export function getDateRequestFormat(dateString) {
  return dateString !== "" ? moment(dateString).format("MMM DD,YYYY") : null;
}
export function displayPrice(price) {
  return price && price !== "" && price !== "-"
    ? "$" + price.toLocaleString()
    : "-";
}

export function displayPercentage(value) {
  return value ? value + "% " : "-";
}
export function calculatePercentageNumber(partial, total) {
  if (total === 0) {
    return 0;
  }
  return (partial / total) * 100;
}

export function getMonthFromDate(due) {
  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const d = new Date(due);
  let monthhRender = month[d.getMonth()];
  return monthhRender;
}

export function removeCookie(key) {
  cookie.remove(key);
}

export function redirectLoginPage() {
  removeCookie("contractor_token");
  window.location.reload();
}

export function handleGoBack(props) {
  setTimeout(() => props.history.goBack(), 1200);
}

export function getPDFCloudinary(docFields) {
  return (
    ApiService.response_url +
    `/${docFields.version}/${docFields.public_id}.${docFields.type}`
  );
}

export const showAPIMsg = (self, response) => {
  showToast(self, response.success ? "success" : "danger", response.message);
};

export const showSuccess = (self, message) => {
  showToast(self, "success", message);
};

export const showError = (self, message) => {
  showToast(self, "danger", message);
};

// Toast Handler
export const showToast = (self, type, message) => {
  let data = {
    toast: true,
    response: message,
    toastType: type,
  };
  self.setState(data, () => handleToastMessage(self, type));
};
export const TimelineMessage = (status) => {
  let filtered = status.split(" ");
  switch (filtered[1]) {
    case "Created":
      return <span style={{ color: "yellow" }}>{filtered[1]}</span>;
    case "Cancelled":
      return <span style={{ color: "red" }}>{filtered[1]}</span>;
    case "Paid":
      return <span style={{ color: "#07A84E" }}>{filtered[1]}</span>;
    case "Processing":
      return <span style={{ color: "#FF4949" }}>{filtered[1]}</span>;
    default:
      return filtered[1];
  }
};

export const handleToastMessage = (self, type) => {
  let { toast } = self.state;
  if (toast) {
    setTimeout(
      () => self.setState({ toast: false, response: "", toastType: "" }),
      type === "success" ? 3000 : 3000
    );
  }
};
export const calculatePercentage = (thisMonth, lastMonth, setValue, self) => {
  let newDay = new Date().getDate();
  let currentMonthValue = (thisMonth / newDay) * 30;
  let totalPercentageValue = ((thisMonth - lastMonth) / lastMonth) * 100;
  self.setState({
    [setValue]: totalPercentageValue.toFixed(0),
  });
};

export const capitalizeFirstLetter = (str) => {
  if (!str) return "";
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const parseQueryStrings = (qs) => {
  return queryString.parse(qs);
};

export const returnSearchObject = (search) => {
  return parseQueryStrings(search);
};
export const pushToFilteredPage = (props, query) => {
  for (let key in query) {
    if (query[key] === "") {
      delete query[key];
    }
  }
  props.history.push({
    search: QS.stringify(query),
    pathname: props.location.pathname,
  });
};

export const buildQueryString = (params, type) => {
  let newParams = null;
  if (params) {
    newParams = [];
    for (let key in params) {
      if (params[key] === "") {
        delete params[key];
      } else {
        newParams.push(`${key}=${params[key]}`);
      }
    }
    if (type) {
      return (newParams = "&" + newParams.join("&"));
    }
    return (newParams = "?" + newParams.join("&"));
  }
};

// export const truncate = (element, input) => {
//   let parentDivWidth;
//   let childDivWidth;
//   setTimeout(() => {
//     parentDivWidth = element?.current?.clientWidth
//     childDivWidth = element?.current?.children[0]?.clientWidth
//   }, [1000]);
//   return input;
// };

export const isSub_Contractor = () => {
  let sub_contractor = localStorage.getItem("sub_contractor");

  return sub_contractor === "true" ? true : false;
};

export const isOutsideFlow = (sub_contractor) => {
  return (
    JSON.parse(localStorage.getItem("contractor_details")).contractor
      ?.contractor_id === sub_contractor?.id
  );
};

export const getCommissionAmount = (invoice, amount, isSubContractor) => {
  let { main_contractor_amount, sub_contractor } = invoice;

  let amt = 0;
  if (
    isSubContractor ||
    isOutsideFlow(sub_contractor) ||
    invoice?.assign_type === "outside"
  ) {
    if (main_contractor_amount) {
      if (main_contractor_amount?.type === "percentage") {
        amt = amount - amount * (main_contractor_amount?.commission / 100);
      } else {
        amt = amount - main_contractor_amount?.commission;
      }
    } else {
      amt = amount;
    }
  } else if (!isSubContractor) {
    if (sub_contractor?.id && main_contractor_amount?.type) {
      if (main_contractor_amount?.type === "percentage") {
        let percent = amount * (main_contractor_amount?.commission / 100);
        amt = amount - percent;
      } else {
        amt = amount - main_contractor_amount?.commission;
      }
    } else {
      amt = amount;
    }
  }

  return displayPrice(amt);
};
export const capitalize = (str) => {
  return [...str][0].toUpperCase() + [...str].slice(1).join("");
};
export const showDatenTime = (date) => {
  return date ? moment(date).format("MMM DD,YYYY , H:mm a") : "-";
};

export const maxLengthCheck = (object) => {
  if (object.target.value.length > object.target.maxLength) {
    object.target.value = object.target.value.slice(0, object.target.maxLength);
  }
};

export const allowOnlyText = (obj) => {
  return obj.replace(/[^a-z. ]/gi, "");
};

export const isEmailLowercaseOnly = (string) => {
  const nonLowercaseLetters = /[^a-z]/g;
  return nonLowercaseLetters.test(string.charAt(0));
};

export const getKeyValuesFromArray = (array, toKey) => {
  let tempObj = {};
  array.map((obj) => {
    tempObj[obj[toKey]] = { ...obj };
  });
  return tempObj;
};

export const invoicePay = (amount, commission) => {
  let amt = amount + (amount * commission) / 100 + 0.3;
  return amt ? amt.toFixed(2) : "-";
};

export const taxCalculater = () => {
  let { tax, items } = this.state;
  let taxValue = tax?.value || 0;
  if (items.length > 0 && tax && tax.type === "percentage" && tax.value) {
    let itemsCost = this.calculateTotalAmount(this.state.items);
    taxValue = itemsCost * (tax.value / 100);
  }
  return parseFloat(taxValue)?.toFixed(2);
};
export const fixedAmount = (amount) => {
  const parsedAmount = parseFloat(amount);
  const separator =
    parsedAmount % 1 !== 0 ? parsedAmount.toFixed(2) : parsedAmount;
  return formatAmountWithCommas(separator);
};
