import React, { useEffect, useState } from "react";
import { Spinner } from "reactstrap";
import {
  useLocation,
  Route,
  Switch,
  Redirect,
  HashRouter,
} from "react-router-dom";
import routes from "routes.js";
import downloadMobileApp from "views/infoScreen/downloadMobileApp";
import ClientPage from "views/externalPages/client/clientPage";
import { Router } from "react-router-dom/cjs/react-router-dom.min";
import ClientPhoto from "views/externalPages/client/clientPhotos";
const Auth = (props) => {
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        if (prop.Children) return <Route path={prop.path} render={() => <ClientPage  {...prop} props={props} />} />
        else return <Route path={prop.path} component={prop.component} key={key} />;
      } else {
        return null;
      }
    });
  };

  const loading = (
    <div className="spin-loading">
      <Spinner type="border" color="#077e8b" />
    </div>
  );
  return (
    <>
      <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            {getRoutes(routes)}
            <Redirect from="*" to="/login" />
            <Redirect from="/argon-dashboard-react" to="/login" />
            <Redirect from="/login" to="/reset_password" />
          </Switch>
        </React.Suspense>
      </HashRouter>
    </>
  );

};

export default Auth;
