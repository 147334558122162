import React, { useState, useEffect } from 'react';
import "./ControllableDragger.css"

const ProgressBar = (props) => {

  useEffect(() => {
    updateSlider(props.value)
  }, [props.value])

  const updateSlider = (value) => {
    const progressColor = `linear-gradient(to right, #2e1a47 0%, #2e1a47 ${value}%, #C7D3EB ${value}%, #C7D3EB 100%)`;
    const slider = document.getElementById("myRange");
    slider.style.background = progressColor;
  }


  return (
    <div className="slidecontainer">
      <input step={5} type="range" min="0" max="100" value={props.value} className="slider" id="myRange" onChange={(e) => props.editProgress(e.target.value)} />
    </div>
  );
};

export default ProgressBar;
