import React, { Component } from "react";
import {
  Badge,
  Card,
  Table,
  Container,
  Row,
  Breadcrumb,
  Col,
  Button,
  InputGroup,
  InputGroupText,
  CardBody,
  Spinner,
  DropdownMenu,
  DropdownItem,
  Media,
  UncontrolledDropdown,
  DropdownToggle,
  Dropdown,
  Input,
} from "reactstrap";
// core components
import ApiService from "constants/ApiService";
import { returnFirstDegreeObjValue } from "constants/utils";
import { showUtcDate } from "constants/utils";
import ToastList from "components/Toast/ToastList";
import { showError } from "constants/utils";
import { returnSearchObject } from "constants/utils";
import { pushToFilteredPage } from "constants/utils";
import { buildQueryString } from "constants/utils";
import { CustomListingContainer } from "components/Container/CustomListingContainer";
import { ListingHeaderLayout } from "components/Container/CustomListingContainer";
import { CustomSearch } from "components/FilterFields/Filterfields";
import { Button_without_Border } from "components/Buttons/Button";

class ActiveContractors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contractorList: [],
      page: 1,
      inputSearch: "",
      filterOption: "name",
      toast: false,
      response: "",
      toastType: "",
      isLoading: true,
      query: {},
      input_value: false,
      timoutId: null,
      filters: ["name", "email"],
    };
  }
  componentDidMount = async () => {
    this.setQueryStatus();
  };
  componentWillUnmount = () => {
    clearTimeout(this.state.timoutId);
  };

  returnCurrentFilterOption = (obj) => {
    return (
      this.state.filters.filter((ele) => Object.keys(obj).includes(ele))[0] ??
      ""
    );
  };

  initCheckClerFilters = () => {
    this.state.filters.includes(this.state.filterOption) ||
      this.state.statusSearch ||
      this.state.inputSearch ||
      this.state.page > 1
      ? this.setState({ input_value: true })
      : this.setState({ input_value: false });
  };

  setQueryStatus = () => {
    let queryStatus = this.props.location.search;
    let queryObj = returnSearchObject(queryStatus);
    if (queryStatus) {
      this.setState(
        {
          inputSearch: queryObj[this.returnCurrentFilterOption(queryObj)] ?? "",
          filterOption: queryObj.name ? "name" : queryObj.email ? "email" : "name",
          page: parseInt(queryObj.page) ?? "",
          query: returnSearchObject(queryStatus),
        },
        () => {
          this.initCheckClerFilters();
          pushToFilteredPage(this.props, { ...this.state.query });
          this.getActiveContractors();
        }
      );
    } else {
      this.setState(
        {
          query: {
            ...this.state.query,
            page: 1,
            limit: 10,
          },
        },
        () => {
          pushToFilteredPage(this.props, this.state.query);
          this.getActiveContractors();
        }
      );
    }
  };

  getActiveContractors = async () => {
    let response = await ApiService.getAPI(
      `contractor/sub_contractors${buildQueryString(this.state.query)}`
    );
    if (response.success) {
      let { contractor } = response.data;
      this.setState({
        contractorList: contractor,
        isLoading: false,
      });
    }
  };

  checkForClearFilter = () => {
    this.setState({
      input_value:
        !this.state.statusSearch &&
          (this.state.filterOption === "name" || !this.state.filterOption) &&
          !this.state.inputSearch
          ? false
          : true,
    });
  };

  onChangeHandler = (e, from) => {
    this.setState(
      { [from]: e === "select" ? "" : e, input_value: true },
      () => {
        this.checkForClearFilter();
      }
    );
    clearTimeout(this.state.timoutId);
    const timoutId = setTimeout(() => {
      this.state.filterOption && this.handleSearch();
    }, 1000);
    this.setState({ timoutId });
  };

  handleIncrementPage = () => {
    if (this.state.contractorList.length >= 10) {
      this.setState({ page: this.state.page + 1, input_value: true }, () =>
        this.handleFilterStatus()
      );
    }
  };
  handleDecrementPage = () => {
    if (this.state.page > 1) {
      this.setState(
        {
          page: this.state.page - 1,
          input_value: this.state.page - 1 === 1 ? false : true,
        },
        () => this.handleFilterStatus()
      );
    }
  };

  handleFilterStatus = () => {
    let staticQuery = returnSearchObject(this.props.location.search);
    // if (this.state.inputSearch !== "" && this.state.filterOption !== "") {
    for (let key in staticQuery) {
      if (key === "email" || key === "name") {
        delete staticQuery[key];
      }
    }
    staticQuery[this.state.filterOption] = this.state.inputSearch;
    // }
    staticQuery.page = this.state.page ?? "";
    this.setState({ query: staticQuery }, () => {
      if (this.state.filterOption !== "select") {
        pushToFilteredPage(this.props, { ...this.state.query });
        this.getActiveContractors();
      }
    });
  };

  handleSearch = () => {
    this.setState({ page: 1 }, () => {
      this.handleFilterStatus();
    });
  };

  handleReset = () => {
    let self = this;
    self.setState(
      {
        filterOption: "name",
        inputSearch: "",
        page: 1,
        limit: 10,
        query: {
          page: 1,
          limit: 10,
        },
        input_value: false,
      },
      () => {
        pushToFilteredPage(this.props, { ...this.state.query });
        this.getActiveContractors();
      }
    );
  };

  render() {
    let {
      contractorList,
      isLoading,
      page,
      toast,
      response,
      toastType,
      filterOption,
      inputSearch,
      input_value,
      filters,
    } = this.state;
    return isLoading ? (
      <div className="spin-loading">
        <Spinner type="border" color="#077e8b" />
      </div>
    ) : (
      <div
        style={{ height: "100vh", padding: "20px", backgroundColor: "#fafafa" }}
      >
        {toast && <ToastList message={response} type={toastType} />}
        <CustomListingContainer>
          <ListingHeaderLayout label={"sub pros"} />
          <div style={{ margin: "24px" }}>
            <div
              className="d_flex_sb"
              style={{
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "80%",
                  justifyContent: "space-between",
                }}
              >
                <CustomSearch
                  options={filters}
                  change={this.onChangeHandler}
                  filterOption={filterOption}
                  inputSearch={inputSearch}
                  from="filterOption"
                />
              </div>
              {input_value && (
                <div className="right_filter_container">
                  <Button_without_Border
                    click={this.handleReset}
                    btnLabel={"clear_filter"}
                  ></Button_without_Border>
                </div>
              )}
            </div>
          </div>
          <div>
            <Row>
              <div className="col new-table">
                <Card className="shadow card_with_paginator">
                  {contractorList && contractorList.length !== 0 ? (
                    <Table
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Email</th>
                          <th style={{ width: "10%" }} scope="col">
                            Date
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {contractorList.map((contractor, i) => (
                          <tr key={i}>
                            <td className="text-capitalize">
                              {returnFirstDegreeObjValue(contractor, "name")}
                            </td>
                            <td className="">
                              {returnFirstDegreeObjValue(contractor, "email")}
                            </td>
                            <td className="text-capitalize">
                              {showUtcDate(contractor.createdAt)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    <>
                      {" "}
                      <Table
                        className="align-items-center table-flush"
                        responsive
                      >
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Email</th>
                            <th style={{ width: "10%" }} scope="col">
                              Date
                            </th>
                          </tr>
                        </thead>
                      </Table>
                      <Card className="card_with_paginator no_data_card">
                        {" "}
                        <div className="no-data">No Contractors Available</div>
                      </Card>
                    </>
                  )}
                </Card>
              </div>
            </Row>
            <Row className="align-items-center header-div jus-space new-margin-page paginator_box">
              <div className="paginate-flex-box ">
                <div
                  className="paginate-box"
                  onClick={() => this.handleDecrementPage()}
                >
                  <p
                    className={
                      page !== 1 ? "page_available" : "page_unavailable"
                    }
                  >
                    Previous
                  </p>
                </div>
                <div
                  className="paginate-box"
                  onClick={() => this.handleIncrementPage()}
                >
                  <p
                    className={
                      contractorList.length < 10
                        ? "page_unavailable"
                        : "page_available"
                    }
                  >
                    Next
                  </p>
                </div>
              </div>
            </Row>
          </div>
        </CustomListingContainer>
      </div>
    );
  }
}

export default ActiveContractors;
