import React, { Component } from "react";
import {
  Card,
  Table,
  Container,
  Row,
  Breadcrumb,
  Col,
  Button,
  InputGroup,
  CardBody,
  Spinner,
  DropdownMenu,
  BreadcrumbItem,
  DropdownItem,
  Media,
  UncontrolledDropdown,
  DropdownToggle,
} from "reactstrap";
import ToastList from "components/Toast/ToastList";
import OrangeLine from "../../assets/img/icons/orange_line.svg";
import GreenLine from "../../assets/img/icons/green_line.svg";
import RedLine from "../../assets/img/icons/red_line.svg";
import YellowLine from "../../assets/img/icons/yellow_line.svg";
import BriefCase from "../../assets/img/icons/briefcase.svg";
import TimeCheck from "../../assets/img/icons/time-check.svg";
import InvoicePaymentExpenses from "../../assets/img/icons/invoice_payment_expense.svg";
import InvoicePaymentPaid from "../../assets/img/icons/invoice_payment_paid.svg";
export default class payments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initPageLoading: false,
      toast: false,
      response: "",
      toastType: "",
      cards: [
        { name: "total", value: 0 },
        { name: "pending", value: 0 },
        { name: "paid", value: 0 },
        { name: "expenses", value: 0 },
      ],
    };
  }
  render() {
    let { initPageLoading, toast, response, toastType, cards } = this.state;
    return initPageLoading ? (
      <>
        <div className="spin-loading">
          <Spinner type="border" color="#077e8b" />
        </div>
      </>
    ) : (
      <>
        <Container className="mt-8 card-pad-wrapper" fluid>
          {toast && <ToastList message={response} type={toastType} />}
          <div>
            <Breadcrumb></Breadcrumb>
          </div>
          <Row className="align-items-center header-div">
            <Col xs="8" className="manage-con">
              <Breadcrumb>
                <BreadcrumbItem
                  onClick={() => this.props.history.push("/admin/projects")}
                  className="bread-cursor"
                >
                  Project
                </BreadcrumbItem>
                <BreadcrumbItem
                  className="bread-cursor"
                  onClick={() => this.props.history.goBack()}
                  active
                >
                  Details
                </BreadcrumbItem>
                <BreadcrumbItem active style={{ color: "#72569c" }}>Payments</BreadcrumbItem>
              </Breadcrumb>
              <h3 className="mb-0 managers-crumb"></h3>
            </Col>
            <Col
              xs="4"
              className="manage-con flexy-content flexEnd"
              style={{ gap: "5%" }}
            >
              <div className="status_select ml-10" style={{ width: "29%" }}>
                <UncontrolledDropdown className="status_select_div">
                  <DropdownToggle className="pr-0 drop_down_container">
                    <Media className="capitalize">
                      Payments
                      <i className="fas fa-angle-down"></i>
                    </Media>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-arrow" bottom>
                    {" "}
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() =>
                        this.props.history.push(
                          "/admin/projects/detail/UVSkm0ZUMY"
                        )
                      }
                    >
                      Overview
                    </DropdownItem>{" "}
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() => {this.props.history.push("/admin/tasks_lists")}}
                    >
                      Tasks
                    </DropdownItem>{" "}
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() => this.props.history.push("/admin/payments")}
                    >
                      Payments
                    </DropdownItem>
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() =>
                        this.props.history.push("/admin/purchase_orders")
                      }
                      // onClick={() => this.setPage("clients")}
                    >
                      Purchase Orders
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => this.props.history.push("/admin/comments")}
                      className="dropdown_item"
                      // onClick={() => this.setPage("proposal")}
                    >
                      Comments
                    </DropdownItem>
                    <DropdownItem
                      onClick={() =>
                        this.props.history.push("/admin/documents")
                      }
                      className="dropdown_item"
                      // onClick={() => this.setPage("projects")}
                    >
                      Documents
                    </DropdownItem>
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() => this.props.history.push("/admin/photos")}
                      // onClick={() => this.setPage("clients")}
                    >
                      Photos
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
              {/* <h3 className="mb-0 managers-crumb"></h3> */}
            </Col>
          </Row>
          <div className="payments_top_header">
            <div className="paymentTitle">You have a Overall Net Profit</div>
            <div className="paymentPara">
              Incididunt occaecat et minim consectetur fugiat tempor Lorem
              incididunt cillum quis. Non ea mollit culpa quis consequat quis
              exercitation tempor labore. Cupidatat ad culpa ex et laboris eu
              commodo magna. Pariatur ut labore amet irure ipsum fugiat
              voluptate nostrud.
            </div>
            <div className="payments_card">
              {cards.map((obj) => (
                <>
                  <div className="card">
                    <div className="card_hero">
                      <div className="card_top_left">
                        <div className="f13">{obj.name}</div>
                        <div className="card_top_left_value">${obj.value}</div>
                      </div>
                      <div>
                        <img
                          src={
                            obj.name === "total"
                              ? OrangeLine
                              : obj.name === "pending"
                              ? GreenLine
                              : obj.name === "paid"
                              ? YellowLine
                              : RedLine
                          }
                        ></img>
                      </div>
                    </div>
                    <div className="card_footer">
                      <div>{obj.value}</div>
                      <div className="img_background">
                        <img
                          src={
                            obj.name === "total"
                              ? BriefCase
                              : obj.name === "pending"
                              ? TimeCheck
                              : obj.name === "paid"
                              ? InvoicePaymentPaid
                              : InvoicePaymentExpenses
                          }
                        ></img>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
        </Container>
      </>
    );
  }
}
