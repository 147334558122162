import React, { Component } from "react";
import {
  Card,
  Table,
  Container,
  Row,
  Breadcrumb,
  Col,
  FormGroup,
  Button,
  InputGroup,
  Spinner,
  CardHeader,
  Input,
} from "reactstrap";
import closeIcon from "../../assets/img/brand/close.svg";
import ApiService from "constants/ApiService";
import {
  showSuccess,
  showError,
  isInvalidEmail,
  getUSFormat,
  isInvalidName,
  isValidEmail,
} from "constants/utils";
import { maxLengthCheck, Isvalidname } from "constants/utils";
import CustomModal from "components/CustomModal/CustomModal";
import { CustomInputFields } from "components/FilterFields/Filterfields";
import { CustomDropDownSearch } from "components/FilterFields/Filterfields";
class AddClient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      phone: "",
      btnLoad: false,
      edit: false,
      client_id: "",
      stateList: [""],
      cityList: [""],
      placeList: [],
      isShowPlaces: false,
      city: [],
      state: [],
      state_id: "",
      state_name: "",
      address_line: "",
      city_id: "",
      placeLoad: false,
      city_name: "",
      zip: "",
      initPageLoading: true,
      isApiCallPending: false,
      timeoutId:null,
    };
  }

  componentDidMount = () => {
    !this.props.id && this.setState({ initPageLoading: false });
    this.props.id &&
      this.setState({ edit: true, client_id: this.props.id }, () =>
        this.clientDetails()
      );
    // this.getStates();
  };

  clientDetails = async () => {
    let clientDetail = await ApiService.getAPI(
      `contractor/client/${this.state.client_id}`
    );
    this.setState({ initPageLoading: false });
    let { client } = clientDetail.data;
    if (clientDetail.success) {
      this.setState({
        name: client.name,
        email: client.email,
        phone: getUSFormat(client?.phone?.national_number),
        address_line: client?.address?.line_1,
        state_name: client.address?.state?.name,
        city_name: client.address?.city?.name,
        zip: client.address?.zip,
      });
    }
  };

  getCityList = async () => {
    let { state_id, locationData } = this.state;

    const id = typeof state_id === "string" ? state_id : state_id.state_id;

    let response = await ApiService.getAPI(`contractor/states/cities/${id}`);
    if (response.success) {
      this.setState({
        cityList: response.data.cities,
        city: locationData?.city
          ? JSON.stringify(locationData.city)
          : this.state.city,
      });
    }
  };

  handleCityInputChange = (e) => {
    var self = this;
    let city = JSON.parse(e.target.value);
    self.setState({ city_name: e.target.value, city_id: city.city_id });
  };

  handleStateInputChange = (e) => {
    let id = JSON.parse(e.target.value).state_id;
    var self = this;
    self.setState({ state_name: e.target.value, state_id: id }, () => {
      self.getCityList();
    });
  };

  getStates = async () => {
    let { state_id } = this.state;
    let response = await ApiService.getAPI("contractor/states");
    if (response.success) {
      this.setState(
        { stateList: response.data.states },
        () => state_id && Object.keys(state_id).length > 0 && this.getCityList()
      );
    }
  };

  getPlaceData = async (place) => {
    this.setState({ placeLoad: true });
    let response = await ApiService.getAPI(
      `contractor/maps/place/${place.place_id}`
    );

    if (response.success) {
      let locData = response.data;
      let datas = locData;
      datas.state["state_id"] = datas.state["id"];
      datas.city["city_id"] = datas.city["id"];
      delete datas.state["id"];
      delete datas.city["id"];
      this.setState(
        {
          address_line: place.description,
          isShowPlaces: false,
          placeLoad: false,
          locationData: datas,
          state: datas?.state ? JSON.stringify(datas.state) : "",
          state_name: datas?.state ? datas.state.name : "",
          state_id: datas?.state ? datas.state.state_id : "",
          city_name: datas?.city ? datas.city.name : "",
          city_id: datas?.city ? datas.city.city_id : "",
          zip: datas?.zip_code ? datas.zip_code : "",
        },
        () => {
          datas?.state && this.getStates();
        }
      );
    } else {
      this.setState({
        isShowPlaces: false,
      });
    }
  };

  handleAddressChange = (event) => {
    let { timeoutId } = this.state
    const newValue = event;
    this.setState({ address_line: newValue });
    // Clear the existing timeout
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    // Set a new timeout
    const newTimeoutId = setTimeout(() => {
      this.getPlaces(newValue);
    }, 500); // 500ms delay

    this.setState({ timeoutId: newTimeoutId });
  };

  getPlaces = async (e) => {
    this.setState({ placeLoad: true, state_name: '', state_id: '', city_name: '', city_id: '' });
    let searchTerm = e;
    this.setState({ address_line: searchTerm });
    let response = await ApiService.getAPI(
      `contractor/maps/search?input=${searchTerm}`
    );
    if (response.success) {
      this.setState({
        isShowPlaces: true,
        placeLoad: false,
        placeList: response.data.place.predictions,
      });
    }
  };

  handlePhoneChange = (e) => {
    const { name, value } = e.target;
    const numericValue = value.replace(/\D/g, "");
    const limitedValue = numericValue.slice(0, 10);
    let formattedPhone = "";
    if (limitedValue.length > 0) {
      formattedPhone = `(${limitedValue.slice(0, 3)}`;
      if (limitedValue.length > 3) {
        formattedPhone += `) ${limitedValue.slice(3, 6)}`;
        if (limitedValue.length > 6) {
          formattedPhone += ` - ${limitedValue.slice(6, 10)}`;
        }
      }
    }
    this.setState({ [name]: formattedPhone });
  };

  createClient = async () => {
    let {
      name,
      email,
      phone,
      edit,
      client_id,
      address_line,
      city_id,
      state_id,
      city,
      state,
      zip,
      city_name,
      state_name,
      locationData
    } = this.state;
    if (!name) return showError(this.props.props, "Please enter the name");
    else if (!Isvalidname(name))
      return showError(this.props.props, "Please enter a valid name");
    else if (!email)
      return showError(this.props.props, "Please enter the email");
    else if (!isValidEmail(email))
      return showError(this.props.props, "Please enter a valid email");
    else if (!phone)
      return showError(this.props.props, "Please enter a phone number");
    else if (phone.length < 16)
      return showError(this.props.props, "Please enter a valid phone number");
    else if (!(client_id && address_line) && (!locationData))
      return showError(this.props.props, "Please enter a address");
    this.setState({ btnLoad: true, isApiCallPending: true });

    let data = {
      name: name,
      phone: phone.replace(/\D/g, ""),
      email: email,
      address: {
        line_1: address_line,
        city: {
          city_id: city_id,
          name: city_name
        },
        state: {
          state_id: state_id,
          name: state_name
        },
        zip: zip,
      },
    };

    let response = edit
      ? await ApiService.patchAPI(`contractor/client/${client_id}`, data)
      : await ApiService.postAPI("contractor/client", data);
    this.setState({ btnLoad: false, isApiCallPending: false });
    if (response.success) {
      this.props.close();
      return showSuccess(this.props.props, response.message);
    } else {
      return showError(this.props.props, response.message);
    }
  };

  handleInputChange = (e, from) => {
    if (from === "einId") {
      this.setState({
        [from]: e.replace(/[^\w\s]/gi, "").toUpperCase(),
      });
    } else {
      this.setState({ [from]: e });
    }
  };
  render() {
    let {
      name,
      email,
      phone,
      btnLoad,
      edit,
      initPageLoading,
      props,
      state_name,
      city_name,
      city,
      state,
      placeLoad,
      address_line,
      zip,
      placeList,
      stateList,
      cityList,
      isShowPlaces,
      isApiCallPending,
    } = this.state;
    return initPageLoading ? (
      <div className="spin-loading">
        <Spinner type="border" color="#077e8b" />
      </div>
    ) : (
      <>
        <CustomModal
          btnLoad={btnLoad}
          type={edit ? "Update Client" : "Add Client"}
          edit={edit}
          isApiCallPending={isApiCallPending}
          create={this.createClient}
          close={() => this.props.close()}
        >
          <label className="customLabel">Name*</label>
          <CustomInputFields
            placeHolder="Enter the name"
            style={{ marginBottom: "20px" }}
            inputValue={name}
            type="text"
            change={this.handleInputChange}
            from="name"
          />
          <label className="customLabel">Email*</label>
          <CustomInputFields
            style={{ marginBottom: "20px" }}
            inputValue={email}
            type="text"
            change={this.handleInputChange}
            from="email"
            placeHolder="Enter the mail"
          />
          <label className="customLabel">Phone Number*</label>
          <CustomInputFields
            style={{ marginBottom: "20px" }}
            inputValue={phone}
            type="text"
            change={this.handleInputChange}
            from="phone"
            placeHolder="Enter the phone number"
          />
          <label className="customLabel">Address*</label>
          <CustomDropDownSearch
            style={{ marginBottom: "20px", width: "100%" }}
            className="customDropDownSearch"
            toSearch="description"
            width={"100%"}
            placeHolder="Enter the address"
            filteredData={placeList}
            onChange={this.handleAddressChange}
            listClick={this.getPlaceData}
            value={address_line}
          />

          <label className="customLabel">State*</label>
          <CustomInputFields
            style={{ marginBottom: "20px" }}
            inputValue={state_name}
            type="text"
            disabled
            placeHolder="Enter the state"
          />
          <label className="customLabel">City*</label>
          <CustomInputFields
            style={{ marginBottom: "20px" }}
            inputValue={city_name}
            type="text"
            placeHolder="Enter the city"
            disabled
          />
          <label className="customLabel">Zip code*</label>
          <CustomInputFields
            style={{ marginBottom: "20px" }}
            inputValue={zip}
            type="text"
            placeHolder="Enter the Zip Code"
            disabled
          />
        </CustomModal>
      </>
    );
  }
}
export default AddClient;
