import {
  Badge,
  Card,
  Table,
  Container,
  Row,
  Breadcrumb,
  Col,
  Button,
  InputGroup,
  InputGroupText,
  CardBody,
  Spinner,
  DropdownMenu,
  DropdownItem,
  Media,
  UncontrolledDropdown,
  DropdownToggle,
  Dropdown,
  Input,
  Modal,
  BreadcrumbItem,
} from "reactstrap";
// core components
import ApiService from "constants/ApiService";
import { returnFirstDegreeObjValue } from "constants/utils";
import { showUtcDate } from "constants/utils";
import ToastList from "components/Toast/ToastList";
import { CustomListingContainer } from "components/Container/CustomListingContainer";
import { ListingHeaderLayout } from "components/Container/CustomListingContainer";
import { CustomSearch } from "components/FilterFields/Filterfields";
import { Button_without_Border } from "components/Buttons/Button";
import projectTimeline from "../../assets/img/brand/projectTimeline.svg";
import showMore from "../../assets/img/brand/showMore.svg";
import { showSuccess } from "constants/utils";
import closeIcon from "../../assets/img/icons/closeIcon.svg";
import { Custom_Button } from "components/Buttons/Button";
import { Component } from "react";
import { formatAmountWithCommas } from "constants/utils";
import ToolTip from "components/ToolTip/ToolTip";
import AddChangeOrder from "./addChangeOrder";
import timeLineSideBar from "views/Invoices/timeLineSideBar";
import TimeLine from "components/Timeline/Timeline";
import { returnSearchObject } from "constants/utils";
import { pushToFilteredPage } from "constants/utils";
import CancelModal from "./cancelModal";
import { filterQuery } from "constants/utils";
import { buildQueryString } from "constants/utils";
let ID;
const styleForDropDown = { padding: "0.7rem 1rem" };

class ChangeOrderList extends Component {
  constructor(props) {
    super(props);
    ID = this.props.match.params.project_id;
    this.state = {
      orderLists: [],
      isLoading: false,
      toast: "",
      response: "",
      toastType: "",
      input_value: false,
      inputSearch: "",
      total: 0,
      page: 1,
      ID: "",
      openChangeOrder: false,
      orderId: "",
      timeLine: {},
      timeLineOpen: false,
      filterOption: "",
      inputSearch: "",
      statusSearch: "",
      cancelModal: false,
      changeOrderId: "",
      query: {},
    };
  }
  componentDidMount = async () => {
    this.setQueryStatus();
  };
  setQueryStatus = () => {
    let queryStatus = this.props.location.search;
    let queryObj = returnSearchObject(queryStatus);
    if (queryStatus) {
      this.setState(
        {
          // inputSearch: queryObj[this.returnCurrentFilterOption(queryObj)] ?? "",
          // filterOption: queryObj.name ? "name" : "name",
          statusSearch: queryObj.status ?? "",
          page: parseInt(queryObj.page) ?? "",
          query: returnSearchObject(queryStatus),
        },
        () => {
          pushToFilteredPage(this.props, { ...this.state.query });
          this.getChangeOrderList();
        }
      );
    } else {
      this.setState(
        {
          query: {
            ...queryObj,
            page: 1,
            limit: 10,
          },
        },
        () => {
          // this.setStatus(queryObj.status);
          pushToFilteredPage(this.props, { ...this.state.query });
          this.getChangeOrderList();
        }
      );
    }
  };
  getChangeOrderList = async () => {
    let { filterOption, inputSearch, statusSearch } = this.state;
    let queryParams = "";
    if (filterOption !== "" && inputSearch !== "")
      queryParams += filterQuery(filterOption, inputSearch);
    if (statusSearch !== "") {
      queryParams += filterQuery("status", statusSearch);
    } else this.setState({ isLoading: true });
    let response = await ApiService.getAPI(
      `contractor/changeOrder/${ID}${buildQueryString(this.state.query)}`
    );
    this.setState({ isLoading: false });
    if (response.success) {
      this.setState({ orderLists: response.data });
    }
  };

  openChangeOrderDetail = (e, id) => {
    this.setState({ orderId: id, openChangeOrder: true });
  };
  closeChangeOrder = (val) => {
    this.setState({ orderId: "", openChangeOrder: false });
    val && this.getChangeOrderList();
  };
  handleIncrementPage = () => {
    if (this.state.orderLists.length >= 10) {
      this.setState({ page: this.state.page + 1, input_value: true }, () =>
        this.handleFilterStatus()
      );
    }
  };

  handleDecrementPage = () => {
    if (this.state.page > 1) {
      this.setState(
        {
          page: this.state.page - 1,
          input_value: this.state.page - 1 === 1 ? false : true,
        },
        () => this.handleFilterStatus()
      );
    } else this.setState({ input_value: false });
  };
  handleFilterStatus = () => {
    let staticQuery = returnSearchObject(this.props.location.search);
    staticQuery.status = this.state.statusSearch ?? "";
    staticQuery.page = this.state.page ?? "";
    if (this.state.inputSearch !== "" && this.state.filterOption !== "") {
      staticQuery[this.state.filterOption] = this.state.inputSearch;
    } else staticQuery[this.state.filterOption] = "";
    this.setState({ query: staticQuery }, () => {
      pushToFilteredPage(this.props, { ...this.state.query });
      this.getChangeOrderList();
    });
  };
  openChangeOrders = () => {
    this.setState({ openChangeOrder: true });
  };
  openChangeOrderDetail = (e, id) => {
    this.setState({ orderId: id, openChangeOrder: true });
  };
  cancelWorkOrder = (id) => {
    this.setState({ cancelModal: true, changeOrderId: id });
  };
  onTimeLineClick = (obj, from) => {
    this.setState({
      timeLineOpen: true,
      timeLine: {
        ...this.state.timeLine,
        id: obj.changeOrder_id,
        from: from,
      },
    });
  };
  closeTimeline = () => {
    this.setState({
      timeLineOpen: false,
      timeLine: {
        ...this.state.timeLine,
        id: "",
        from: "",
      },
    });
  };

  closeCancelModal = (val) => {
    this.setState({ cancelModal: false, changeOrderId: "" });
    val && this.getChangeOrderList();
  };

  render() {
    let {
      orderLists,
      isLoading,
      toast,
      response,
      toastType,
      page,
      openChangeOrder,
      orderId,
      timeLineOpen,
      cancelModal,
      changeOrderId,
    } = this.state;
    return isLoading ? (
      <div className="spin-loading">
        <Spinner type="border" color="#077e8b" />
      </div>
    ) : (
      <div
        style={{ height: "100vh", padding: "20px", backgroundColor: "#fafafa" }}
      >
        {toast && <ToastList message={response} type={toastType} />}
        <div className="align-items-center" style={{ paddingBottom: "20px" }}>
          <div xs="8" className="manage-con">
            <div>
              <Breadcrumb className="">
                <BreadcrumbItem
                  onClick={() => {
                    this.props.history.push({
                      pathname: `/admin/projects/detail/${ID}`,
                    });
                  }}
                  className="bread-cursor"
                >
                  {"Project"}
                </BreadcrumbItem>
                {/* <BreadcrumbItem
                                    style={{ color: "#72569c" }}
                                    className="bread-cursor"
                                >
                                    {this.props.name}
                                </BreadcrumbItem> */}
                <BreadcrumbItem
                  style={{ color: "#72569c" }}
                  className="bread-cursor"
                >
                  {"Change Order"}
                </BreadcrumbItem>
              </Breadcrumb>
            </div>
          </div>
        </div>
        <CustomListingContainer>
          <ListingHeaderLayout
            label={"Change Order"}
            primary_buttonLabel={"Add"}
            isPrimary
            click={() => this.openChangeOrders()}
          />
          {/* <div style={{ margin: "24px" }}>
                        <div
                            className="d_flex_sb"
                            style={{
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    width: "80%",
                                    justifyContent: "space-between",
                                }}
                            >
                                <CustomSearch
                                    options={filters}
                                    change={this.onChangeHandler}
                                    filterOption={filterOption}
                                    inputSearch={inputSearch}
                                    from="filterOption"
                                />
                            </div>
                            {(input_value && inputSearch) && (
                                <div className="right_filter_container">
                                    <Button_without_Border
                                        click={this.handleReset}
                                        btnLabel={"clear_filter"}
                                    ></Button_without_Border>
                                </div>
                            )}
                        </div>
                    </div> */}
          <div>
            <Row>
              <div className="col new-table">
                <Card className="shadow card_with_paginator">
                  {orderLists && orderLists?.length !== 0 ? (
                    <Table
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead className="thead-light">
                        <tr>
                          <th scope="col" style={{ paddingLeft: "25px" }}>
                            ID
                          </th>
                          <th>Title</th>
                          <th scope="col">Created On</th>
                          <th scope="col">Due On</th>
                          <th scope="col">Amount</th>
                          <th scope="col">Status</th>
                          <th style={{ width: "5%" }} scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {orderLists.map((order, i) => (
                          <tr
                            className="cursor-point"
                            onClick={(e) =>
                              this.openChangeOrderDetail(
                                e,
                                order.changeOrder_id
                              )
                            }
                            key={i}
                          >
                            <td
                              className="text-capitalize"
                              style={{ paddingLeft: "25px" }}
                            >
                              {order.changeOrder_id}
                            </td>

                            <td className="text-capitalize">{order.title}</td>
                            <td className="">{showUtcDate(order.createdAt)}</td>
                            <td className="text-capitalize">
                              {showUtcDate(order.due_date)}
                            </td>
                            <td className="text-capitalize">
                              {"$" +
                                formatAmountWithCommas(order.amount.toFixed(2))}
                            </td>
                            <td
                              style={{
                                color: `${
                                  order.status === "approved" ||
                                  order.status === "accepted" ||
                                  order.status === "completed"
                                    ? "#07A84E"
                                    : order.status === "pending"
                                    ? "#FF4949"
                                    : order.status === "paid"
                                    ? "#0070FD"
                                    : order.status === "rejected" ||
                                      order.status === "cancelled"
                                    ? "#F70e0e"
                                    : "#FFCC3D"
                                }`,
                                fontWeight: "500",
                              }}
                              className="text-capitalize"
                            >
                              {order.status}
                            </td>
                            <td
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end",
                                gap: "20px",
                                paddingLeft: "20px",
                              }}
                            >
                              <div
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                              >
                                <ToolTip label="Timeline">
                                  <img
                                    onClick={
                                      () =>
                                        this.onTimeLineClick(
                                          order,
                                          "changeOrder"
                                        )
                                      //   this.setState({
                                      //     isTimeLineOpen: true,
                                      //     task_id: task.task_id,
                                      //     project_id: task.project_id,
                                      //   })
                                    }
                                    style={{ cursor: "pointer" }}
                                    src={projectTimeline}
                                    alt="timeline"
                                  ></img>
                                </ToolTip>
                              </div>
                              <div
                                style={{
                                  visibility: [
                                    "accepted",
                                    "cancelled",
                                  ].includes(order.status)
                                    ? "hidden"
                                    : "",
                                }}
                                className="project_list_content"
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                              >
                                {order.status === "created" && (
                                  <UncontrolledDropdown
                                    nav
                                    className="flexy-content position_u"
                                  >
                                    <DropdownToggle
                                      className="pr-0 nav-notice"
                                      nav
                                    >
                                      <Media className="align-items-center">
                                        <img src={showMore} alt="show more" />
                                      </Media>
                                    </DropdownToggle>
                                    <DropdownMenu
                                      className="dropdown-menu-arrow min-width-drop"
                                      right
                                    >
                                      <>
                                        <DropdownItem
                                          style={styleForDropDown}
                                          onClick={(e) =>
                                            this.openChangeOrderDetail(
                                              e,
                                              order.changeOrder_id
                                            )
                                          }
                                        >
                                          <span>Edit</span>
                                        </DropdownItem>
                                        <DropdownItem
                                          style={styleForDropDown}
                                          onClick={() =>
                                            this.cancelWorkOrder(
                                              order.changeOrder_id
                                            )
                                          }
                                        >
                                          <span>Cancel</span>
                                        </DropdownItem>
                                      </>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                )}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    <>
                      <Table
                        className="align-items-center table-flush"
                        responsive
                      >
                        <thead className="thead-light">
                          <tr>
                            <th scope="col" style={{ paddingLeft: "25px" }}>
                              ID
                            </th>
                            <th>Title</th>
                            <th scope="col">Created On</th>
                            <th scope="col">Due On</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Status</th>
                            <th style={{ width: "5%" }} scope="col"></th>
                          </tr>
                        </thead>
                      </Table>
                      <Card className="card_with_paginator no_data_card">
                        {" "}
                        <div className="no-data">No Contractors Available</div>
                      </Card>
                    </>
                  )}
                </Card>
              </div>
            </Row>
            <Row className="align-items-center header-div jus-space new-margin-page paginator_box">
              <div className="paginate-flex-box ">
                <div
                  className="paginate-box"
                  onClick={() => this.handleDecrementPage()}
                >
                  <p
                    className={
                      page !== 1 ? "page_available" : "page_unavailable"
                    }
                  >
                    Previous
                  </p>
                </div>
                <div
                  className="paginate-box"
                  onClick={() => this.handleIncrementPage()}
                >
                  <p
                    className={
                      orderLists?.length < 10
                        ? "page_unavailable"
                        : "page_available"
                    }
                  >
                    Next
                  </p>
                </div>
              </div>
            </Row>
          </div>
        </CustomListingContainer>
        <Card className={openChangeOrder ? "exportSideBar" : "toggle-sidebar"}>
          {openChangeOrder && (
            <AddChangeOrder
              props={this.props}
              projectId={ID}
              orderId={orderId ?? ""}
              close={this.closeChangeOrder}
            ></AddChangeOrder>
          )}
        </Card>
        <Card className={timeLineOpen ? "exportSideBar" : "toggle-sidebar"}>
          {timeLineOpen && (
            <TimeLine
              id={this.state.timeLine.id}
              closeTimelineModal={this.closeTimeline}
              from={this.state.timeLine.from}
            ></TimeLine>
          )}
        </Card>
        {cancelModal && (
          <CancelModal
            modalState={cancelModal}
            name={changeOrderId}
            type={"change_order"}
            props={this}
            // toast={Showtoast}
            closeModal={this.closeCancelModal}
            id={changeOrderId}
            width={200}
          />
        )}
      </div>
    );
  }
}

export default ChangeOrderList;
