import { useState, useEffect, useContext, useRef } from "react";
import { NavLink as NavLinkRRD, Link, useLocation } from "react-router-dom";
import { PropTypes } from "prop-types";
import { getCookie } from "../../constants/utils";
import { useHistory } from "react-router-dom";
// reactstrap components

import { Collapse, Navbar, NavItem, NavLink, Nav, Row, Col } from "reactstrap";
import newLogo from "../../assets/img/new Logo/contractor_new_logo.svg";
import SmallLogo from "../../assets/img/sideBar_icons/small_logo_icon.svg";
import closeArrowInactve from "../../assets/img/brand/InactiveSideArrow.svg";
import closeArrowActive from "../../assets/img/brand/ActiveSideArrow.svg";
import openArrowActive from "../../assets/img/brand/activeDownArrow.svg";
import openArrowInactive from "../../assets/img/brand/InactiveDownArrow.svg";
import { PageTitle } from "layouts/Admin";

const Sidebar = (props) => {
  const sideNavChildren = useRef();
  const history = useHistory();
  const location = useLocation();
  const { routes, logo } = props;
  const [collapseOpen, setCollapseOpen] = useState(true);
  // const [tabValue, setTabValue] = useState();
  const [screenValue, setScreen] = useState("half");
  let screen = localStorage.getItem("screen");
  const [localRoutes, setRoutes] = useState(routes);
  const [lastActivity, setLastActivity] = useState("");
  const { upDateTitle } = useContext(PageTitle);
  const [tabValue, setTabValue] = useState("");
  const [sideNavBarToggle, setSideNavBarToggle] = useState();
  // setScreen(screen);
  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };

  useEffect(() => {
    routes.map((route, i) => {
      location.pathname.includes(route.path) && upDateTitle(route.name);
    });
  }, []);

  useEffect(() => {
    setSideNavBarToggle(props.collapse);
  }, [props.collapse]);

  const capitalizeFirstLetter = (str) => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  useEffect(() => {
    let screen = localStorage.getItem("screen");
    setScreen(screen);
    // if (location.merge) {
    //   setTabValue(location);
    // }
    let path = "/" + window.location.href.split("/")[5];
    let routeArray = [...routes];

    setRoutes(routeArray);
    getLastActivity();
  }, []);

  useEffect(() => {
    setSidebar();
  }, [location.pathname]);

  const setSidebar = () => {
    // let path = location.pathname;
    let routeArray = [...routes];
    for (let route of routeArray) {
      if (route.hasChild) {
        for (let subroute of route.subChild) {
          if (typeof subroute === "object") {
            if (window.location.href.includes(subroute.path)) {
              subroute.isActive = true;
              route.isChildren = true;
              route.isActive = true;
            } else {
              subroute.isActive = false;
            }
          }
        }
      } else {
        if (window.location.href.includes(route?.path)) {
          route && (route.isActive = true);
        } else {
          route && (route.isActive = false);
        }
      }
    }
    setRoutes(routeArray);
  };

  const getLastActivity = () => {
    let active = getCookie("lastActivity");
    setLastActivity(active);
  };

  const tabChange = (value, index, childIndex) => {
    setTabValue(value.name);
    let routeArray = [...routes];
    if (value) {
      sideNavChildren.current.className = "side-sub-menu";
    }
    if (!["Requests", "My Finance", "My Team", "CRM"].includes(value.name)) {
      upDateTitle(value.name);
    } else if (location.pathname.includes("/user-profile")) {
      upDateTitle("Profile");
    } else {
      const tempArray = routeArray[index]?.subChild.filter((obj) => {
        return obj.isActive
      });
      if (tempArray.length) upDateTitle(tempArray[0].name);
      // history.push(`/admin${tempArray.path}`);
    }

    routeArray.map((route) => {
      if (route) {
        route.isActive = false;
        if (route.hasChild && (childIndex !== null && childIndex !== undefined)) {
          route.subChild.map((item) => {
            // typeof item === "object" && 
            item.isActive = false;
          });
        }
      }
    });

    routeArray[index].isActive = true;
    // expand collapse
    if (childIndex === null || childIndex === undefined) {
      routeArray.map((route) => {
        if (route && route !== value) {
          route.isChildren = false;
        }
      });
      routeArray[index].isChildren = !routeArray[index].isChildren;
    }

    if (childIndex >= 0 && childIndex !== null) {
      routeArray[index].subChild[childIndex].isActive = true;
    } else if (routeArray[index].hasChild && !routeArray[index].subChild.some(child => child.isActive)) {
      routeArray[index].subChild[0].isActive = true
      upDateTitle(routeArray[index].subChild[0].name)
      history.push(`/admin${routeArray[index]?.subChild[0].path}`)
    }
    // if (value.hasChild) {
    //   routeArray[index].subChild[0].isActive = true
    // }
    setRoutes([...routeArray]);
  };

  const createLinks = (localRoutes, screen) => {
    return (
      <>
        {localRoutes.map((routeItem, i) => {
          return (
            routeItem && (
              <>
                {screen === "half" && routeItem.view ? (
                  <NavItem key={i} className="side_bar_data">
                    {routeItem?.hasChild ? (
                      <div className="flex-column">
                        <div
                          style={{ position: "relative", height: '45px' }}
                          className={
                            routeItem.isActive
                              ? "flex pad092  bortrans point nav-SubChildActive"
                              : "nav-inactive flex pad092 bortrans  point"
                          }
                          onClick={() => {
                            tabChange(routeItem, i, null)
                          }}
                        >
                          <div className="input-document-flex ">
                            {routeItem.isActive ? (
                              <div className="sideBar_icon ">
                                <img
                                  src={routeItem.active}
                                  alt="route item active"
                                />
                              </div>
                            ) : (
                              
                              <div className="sideBar_icon">
                                <img
                                  src={routeItem.active}
                                  alt="route item inactive"
                                />
                              </div>
                            )}
                            <div className="side_name">{routeItem.name}</div>
                          </div>
                          <img
                            src={
                              routeItem.isChildren
                                ? routeItem.isActive
                                  ? openArrowActive
                                  : openArrowInactive
                                : routeItem.isActive
                                  ? closeArrowActive
                                  : closeArrowInactve
                            }
                            alt="route children"
                          />
                        </div>
                        {routeItem.isChildren ? (
                          <div className="h100">
                            {routeItem.subChild.map((item, indexs) =>
                              typeof item === "object" && (
                                <NavLink
                                  key={indexs}
                                  to={item.layout + item.path}
                                  tag={NavLinkRRD}
                                  onClick={() => tabChange(item, i, indexs)}
                                  activeClassName={
                                    item.isActive
                                      ? "cl0A8080 subNav subChildActive"
                                      : "cl939393 subNav"
                                  }
                                  className={item.isActive ? "subChildActive" : "subChildInActive"}
                                  style={{ position: "relative" }}
                                >
                                  <div
                                    style={{ marginLeft: "30px" }}
                                    className={
                                      item.isActive
                                        ? "col_primary_1 f14 fw-600 ff-pop"
                                        : "f14 fw-400 ff-pop side_name"
                                    }
                                  >
                                    {capitalizeFirstLetter(item.name)}
                                  </div>
                                </NavLink>
                              )
                            )}
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    ) : (
                      <NavLink
                        key={i}
                        to={routeItem.layout + routeItem.path}
                        tag={NavLinkRRD}
                        onClick={() => tabChange(routeItem, i)}
                        // activeClassName={
                        //   routeItem.isActive ? "active" : "inactive"
                        // }
                        className={
                          routeItem.isActive ? "nav-active" : "nav-inactive"
                        }
                      >
                        {routeItem.isActive ? (
                          <div className="sideBar_icon">
                            <img
                              src={routeItem.active}
                              alt="route item"
                              className="icon_side verticalUnset"
                            />
                          </div>
                        ) : (
                          <div className="sideBar_icon">
                            <img
                              src={routeItem.inactive}
                              alt="route item"
                              className="icon_side verticalUnset"
                            />
                          </div>
                        )}
                        <div style={{color:routeItem.isActive ?'#2e1a47' :'white'}} className="side_name">{routeItem.name}</div>
                      </NavLink>
                    )}
                  </NavItem>
                ) : (routeItem.view && (
                  <NavItem
                    key={i}
                    className="no_side_bar_data unsetAlign"
                    style={{ position: "relative", marginBottom: "0px" }}
                  >
                    {routeItem.hasChild ? (
                      <div
                        className={routeItem.hasChild ? "sideNavContainer" : ""}
                      >
                        <div
                          style={{ position: "relative" }}
                          className={
                            routeItem.isActive
                              ? "flexmid pad_sideBar_collapse active_collapse_sidebar h100 point"
                              : "nav-inactive flexmid  pad_sideBar_collapse bortrans h100 point"
                          }
                          onClick={() => {
                            tabChange(routeItem, i, null);
                          }}
                        >
                          <img
                            src={
                              routeItem.isActive
                                ? routeItem.inactive
                                : routeItem.active
                            }
                            alt="route children"
                          />
                          {/* {routeItem.isActive && (
                            <div className="active_parent_side_sub_menu"></div>
                          )} */}
                        </div>
                        {
                          <div className="side-sub-menu">
                            {routeItem.subChild.map((item, index) => (
                              (typeof (item) === 'object') && (
                                <div>
                                  <NavLink
                                    ref={sideNavChildren}
                                    key={index}
                                    to={item.layout + item.path}
                                    tag={NavLinkRRD}
                                    onClick={() => tabChange(item, i, index)}
                                    activeClassName="sideNavBar"
                                    className="sideNavBar_active"
                                  >
                                    <p
                                      style={{ fontSize: "14px" }}
                                      className={"sidebarWhite"}
                                    >
                                      {capitalizeFirstLetter(item.name)}
                                    </p>
                                  </NavLink>
                                </div>
                              )

                            ))}
                          </div>
                        }
                      </div>
                    ) : (
                      <NavLink
                        key={i}
                        to={routeItem.layout + routeItem.path}
                        tag={NavLinkRRD}
                        onClick={() => tabChange(routeItem.name, i)}
                        activeClassName={routeItem.isActive ? "" : ""}
                        style={{
                          backgroundColor: routeItem.isActive
                            ? "white"
                            : "#2e1a47",
                        }}
                      // className={
                      //   routeItem.isActive
                      //     ? "nav-active flexmid"
                      //     : "nav-inactive flexmid"
                      // }
                      >
                        {routeItem.isActive ? (
                          <>
                            <img
                              src={routeItem.active}
                              alt="route active"
                              className="icon_side"
                            />
                          </>
                        ) : (
                          <img
                            src={routeItem.inactive}
                            alt="route active"
                            className="icon_side"
                          />
                        )}
                        {/* {routeItem.isActive && (
                          <div className="active_parent_side_sub_menu"></div>
                        )} */}
                      </NavLink>
                    )}
                  </NavItem>
                )
                )}
              </>
            )
          );
        })}
      </>
    );
  };

  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }

  return (
    <Navbar
      className={" navbar-vertical fixed-left navbar-light"}
      expand="md"
      id="sidenav-main"
      style={{ backgroundColor: "#2E1A47", width: "4vw", paddingTop: "23px" }}
    >
      <div
        // style={{ width: sideNavBarToggle ? "4vw" : ""  }}
        className="container_fixed_div"
      >
        {/* Collapse */}
        <Collapse navbar isOpen={collapseOpen} className="collapse_navbar h100">
          {/* Collapse header */}
          <div className="navbar-collapse-header d-md-none">
            <Row>
              {logo ? (
                <Col className="collapse-brand" xs="6">
                  {logo.innerLink ? (
                    <Link to={logo.innerLink}>
                      <img alt={logo.imgAlt} src={logo.imgSrc} />
                    </Link>
                  ) : (
                    <a href={logo.outterLink}>
                      <img alt={logo.imgAlt} src={logo.imgSrc} />
                    </a>
                  )}
                </Col>
              ) : null}
              <Col className="collapse-close" xs="6">
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={toggleCollapse}
                >
                  <span />
                  <span />
                </button>
              </Col>
            </Row>
          </div>
          {/* Form */}
          <div className="side_bar_data h100">
            <div className="sideBarFlex h100">
              <div style={{ marginTop: '20px' }}>
                <div className="side_bar_logo ">
                  <Link
                    to={{
                      pathname: "/admin/index",
                    }}
                  >
                    {" "}
                    <img
                      style={{ marginRight: "10px 0px 0px 25px" }}
                      alt="logo"
                      src={newLogo}
                    ></img>
                  </Link>
                </div>
                <Nav
                  style={{ marginTop: "35px" }}
                  className="hide-profile side_bar_data"
                  navbar
                >
                  {createLinks(routes, "half")}
                </Nav>
              </div>
              <div className="flex-column clABABAB fw-400 f14  ml-32 "></div>
            </div>
          </div>
          <div style={{ marginTop: '27px' }} className="no_side_bar_data">
            <div className="side_bar_small_logo">
              <img width={18} height={18} src={SmallLogo} alt="logo"></img>
            </div>
            <Nav style={{ marginTop: "27px" }} className="hide-profile" navbar>
              {createLinks(routes, "full")}
            </Nav>
          </div>
        </Collapse>
      </div>
    </Navbar>
  );
};

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    innerLink: PropTypes.string,
    outterLink: PropTypes.string,
    imgSrc: PropTypes.string.isRequired,
    imgAlt: PropTypes.string.isRequired,
  }),
};

export default Sidebar;
