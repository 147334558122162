import {
    Badge,
    Card,
    Table,
    Container,
    Row,
    Breadcrumb,
    Col,
    Button,
    InputGroup,
    InputGroupText,
    CardBody,
    Spinner,
    DropdownMenu,
    DropdownItem,
    Media,
    UncontrolledDropdown,
    DropdownToggle,
    Dropdown,
    Input,
    Modal,
    BreadcrumbItem,
    ModalBody,
} from "reactstrap";
// core components
import ApiService from "constants/ApiService";
import { returnFirstDegreeObjValue } from "constants/utils";
import { showUtcDate } from "constants/utils";
import ToastList from "components/Toast/ToastList";
import { CustomListingContainer } from "components/Container/CustomListingContainer";
import { ListingHeaderLayout } from "components/Container/CustomListingContainer";
import { CustomSearch } from "components/FilterFields/Filterfields";
import { Button_without_Border } from "components/Buttons/Button";
import projectTimeline from "../../assets/img/brand/projectTimeline.svg";
import showMore from "../../assets/img/brand/showMore.svg";
import { showSuccess } from "constants/utils";
import closeIcon from "../../assets/img/icons/closeIcon.svg";
import { Custom_Button } from "components/Buttons/Button";
import { Component } from "react";
import { formatAmountWithCommas } from "constants/utils";
import ToolTip from "components/ToolTip/ToolTip";
import AddChangeOrder from "./addChangeOrder";
import timeLineSideBar from "views/Invoices/timeLineSideBar";
import TimeLine from "components/Timeline/Timeline";
import { returnSearchObject } from "constants/utils";
import { pushToFilteredPage } from "constants/utils";
import { remove_underscore_capitalize } from "constants/utils";
import ReactTooltip from "react-tooltip";
import CancelInvoiceModal from "views/Invoices/CancelInvoiceModal";
import PayMethod from "./projectDetails/payMethod";
import { filterQuery } from "constants/utils";
import { buildQueryString } from "constants/utils";
let ID;

class InvoiceList extends Component {
    constructor(props) {
        super(props);
        ID = this.props?.match?.params?.project_id
        this.state = {
            invoiceList: [],
            isLoading: false,
            toast: "",
            response: "",
            toastType: "",
            input_value: false,
            inputSearch: "",
            total: 0,
            page: 1,
            ID: "",
            timeLine: {},
            timeLineOpen: false,
            filterOption: "",
            inputSearch: "",
            statusSearch: "",
            cancelModal: false,
            changeOrderId: "",
            invoiceList: {},
            invoiceId: null,
            cancelInvoiceModal: false,
            openPaymentModal: false,
            invoiceAmount: "",
            query: {},
        };
    }
    componentDidMount = async () => {
        this.setQueryStatus()
    };
    setQueryStatus = () => {
        let queryStatus = this.props.location.search;
        let queryObj = returnSearchObject(queryStatus);
        if (queryStatus) {
            this.setState(
                {
                    // inputSearch: queryObj[this.returnCurrentFilterOption(queryObj)] ?? "",
                    // filterOption: queryObj.name ? "name" : "name",
                    statusSearch: queryObj.status ?? "",
                    page: parseInt(queryObj.page) ?? "",
                    query: returnSearchObject(queryStatus),
                },
                () => {
                    pushToFilteredPage(this.props, { ...this.state.query });
                    this.getInvoiceList();
                }
            );
        } else {
            this.setState(
                {
                    query: {
                        ...queryObj,
                        page: 1,
                        limit: 10,
                    },
                },
                () => {
                    // this.setStatus(queryObj.status);
                    pushToFilteredPage(this.props, { ...this.state.query });
                    this.getInvoiceList();
                }
            );
        }
    };
    getInvoiceList = async () => {
        let { filterOption, inputSearch, statusSearch } = this.state;
        let queryParams = "";
        if (filterOption !== "" && inputSearch !== "")
            queryParams += filterQuery(filterOption, inputSearch);
        if (statusSearch !== "") {
            queryParams += filterQuery("status", statusSearch);
        } else
            this.setState({ isLoading: true })
        let response = await ApiService.getAPI(`contractor/crmPayment/invoices/${ID}/${buildQueryString(this.state.query)}`);
        this.setState({ isLoading: false })
        if (response.success) {
            this.setState({ invoiceList: response.data.invoices })

        }
    }

    handleIncrementPage = () => {
        if (this.state.invoiceList.length >= 10) {
            this.setState({ page: this.state.page + 1, input_value: true }
                , () => this.handleFilterStatus()
            );
        }
    };

    handleDecrementPage = () => {
        if (this.state.page > 1) {
            this.setState(
                {
                    page: this.state.page - 1,
                    input_value: this.state.page - 1 === 1 ? false : true,
                },
                () => this.handleFilterStatus()
            );
        } else this.setState({ input_value: false });
    };

    handleFilterStatus = () => {
        let staticQuery = returnSearchObject(this.props.location.search);
        staticQuery.status = this.state.statusSearch ?? "";
        staticQuery.page = this.state.page ?? "";
        if (this.state.inputSearch !== "" && this.state.filterOption !== "") {
            staticQuery[this.state.filterOption] = this.state.inputSearch;
        } else staticQuery[this.state.filterOption] = "";
        this.setState({ query: staticQuery }, () => {
            pushToFilteredPage(this.props, { ...this.state.query });
            this.getInvoiceList();
        });
    };

    openCancelInvoicePopup = (id) => {
        this.setState({ cancelInvoiceModal: true, invoiceId: id })
    };
    toggleCancelInvoiceModal = (msg) => {
        this.setState({ cancelInvoiceModal: false, invoiceId: "" })
        if (msg) {
            showSuccess(this, msg);
            this.getInvoiceList();
        }
    };
    onTimeLineClick = (obj, from) => {
        this.setState({
            timeLineOpen: true,
            timeLine: {
                ...this.state.timeLine,
                id: obj.invoice_id,
                from: from
            }
        })
    }
    closeTimeline = () => {
        this.setState({
            timeLineOpen: false,
            timeLine: {
                ...this.state.timeLine,
                id: "",
                from: ""
            }
        })
    }

    closeCancelModal = (val) => {
        this.setState({ cancelModal: false, changeOrderId: "" })
        val && this.getChangeOrderList();
    }

    render() {
        let {
            invoiceList,
            isLoading,
            toast,
            response,
            toastType,
            page,
            cancelInvoiceModal,
            invoiceId,
            timeLineOpen,
            openPaymentModal,
            invoiceAmount,
        } = this.state;

        return isLoading ? (
            <div className="spin-loading">
                <Spinner type="border" color="#077e8b" />
            </div>
        ) : (
            <div
                style={{ height: "100vh", padding: "20px", backgroundColor: "#fafafa" }}
            >
                {toast && <ToastList message={response} type={toastType} />}
                <div
                    className="align-items-center"
                    style={{ paddingBottom: "20px" }}
                >
                    <div xs="8" className="manage-con">
                        <div>
                            <Breadcrumb className="">
                                <BreadcrumbItem onClick={() => {
                                    this.props.history.push({
                                        pathname: `/admin/projects/detail/${ID}`,
                                    })
                                }} className="bread-cursor">
                                    {"Project"}
                                </BreadcrumbItem>
                                <BreadcrumbItem
                                    style={{ color: "#72569c" }}
                                    className="bread-cursor"
                                >
                                    {"Invoices"}
                                </BreadcrumbItem>
                            </Breadcrumb>
                        </div>
                    </div>
                </div>
                <CustomListingContainer>
                    <ListingHeaderLayout label={"Invoices"}
                    // primary_buttonLabel={"Add"}
                    // isPrimary
                    // click={() => this.openChangeOrders()}
                    />
                    {/* <div style={{ margin: "24px" }}>
                        <div
                            className="d_flex_sb"
                            style={{
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    width: "80%",
                                    justifyContent: "space-between",
                                }}
                            >
                                <CustomSearch
                                    options={filters}
                                    change={this.onChangeHandler}
                                    filterOption={filterOption}
                                    inputSearch={inputSearch}
                                    from="filterOption"
                                />
                            </div>
                            {(input_value && inputSearch) && (
                                <div className="right_filter_container">
                                    <Button_without_Border
                                        click={this.handleReset}
                                        btnLabel={"clear_filter"}
                                    ></Button_without_Border>
                                </div>
                            )}
                        </div>
                    </div> */}
                    <div>
                        <Row>
                            <div className="col new-table">
                                <Card className="shadow card_with_paginator">
                                    {invoiceList &&
                                        invoiceList?.length !== 0 ? (
                                        <Table
                                            className="align-items-center table-flush"
                                            responsive
                                        >
                                            <thead className="thead-light">
                                                <tr>
                                                    <th
                                                        style={{ paddingLeft: "25px", width: "20%" }}
                                                        scope="col"
                                                    >
                                                        ID
                                                    </th>
                                                    <th scope="col">Title</th>
                                                    <th scope="col">Invoice To</th>
                                                    <th scope="col">Created On</th>
                                                    <th scope="col">Amount</th>
                                                    <th scope="col">Status</th>
                                                    <th style={{ width: "5%" }} scope="col"></th>
                                                    <th style={{ width: "5%" }} scope="col"></th>
                                                </tr>
                                            </thead>
                                            {invoiceList.length > 0 && (
                                                <tbody>
                                                    {invoiceList.map((invoice, i) => (
                                                        <tr key={i}>
                                                            <td
                                                                style={{ paddingLeft: "25px" }}
                                                                className="text-capitalize"
                                                            >
                                                                {invoice.invoice_id}
                                                            </td>
                                                            <td>
                                                                {invoice.workOrder_id
                                                                    ? invoice.workOrder_id
                                                                    : invoice.title}
                                                            </td>
                                                            <td className="text-capitalize">
                                                                {invoice.sub_contractor?.name}
                                                            </td>
                                                            <td>{showUtcDate(invoice.createdAt)}</td>
                                                            <td className="text-capitalize">
                                                                ${invoice.amount.toFixed(2)}
                                                            </td>
                                                            <td
                                                                style={{
                                                                    color: `${invoice.status === "processing"
                                                                        ? "#FF4949"
                                                                        : invoice.status === "paid"
                                                                            ? "#07A84E"
                                                                            : invoice.status === "created"
                                                                                ? "#FFCC3D"
                                                                                : invoice.status === "cancelled"
                                                                                    ? "red"
                                                                                    : "#FFCC3D"
                                                                        }`,
                                                                    fontWeight: "500",
                                                                }}
                                                                className="text-capitalize"
                                                            >
                                                                {remove_underscore_capitalize(invoice.status)}
                                                            </td>
                                                            <td>
                                                                {![
                                                                    "paid",
                                                                    "processing",
                                                                    "cancelled",
                                                                ].includes(invoice.status) && (
                                                                        <Custom_Button
                                                                            isPrimary
                                                                            btnLabel={"pay_now"}
                                                                            click={() => {
                                                                                this.setState({
                                                                                    openPaymentModal: true, invoiceAmount: invoice.amount,
                                                                                    invoiceId: invoice.invoice_id
                                                                                })
                                                                                // setOpenPaymentModal(true);
                                                                                // setInvoiceAmount(invoice.amount);
                                                                                // setInvoiceId(invoice.invoice_id);
                                                                            }}
                                                                            style={{
                                                                                padding: "8px 15px",
                                                                                fontSize: "12px",
                                                                                minWidth: "50px",
                                                                            }}
                                                                        />
                                                                    )}
                                                            </td>
                                                            <td>
                                                                <div
                                                                    style={{ gap: "20px" }}
                                                                    className="flexStart"
                                                                >
                                                                    <ToolTip label="Timeline">
                                                                        <img
                                                                            onClick={() =>
                                                                                this.onTimeLineClick(invoice, "invoice")
                                                                            }
                                                                            className="point"
                                                                            src={projectTimeline}
                                                                            alt="timeline"
                                                                        />
                                                                    </ToolTip>
                                                                    {invoice.status === "created" ||
                                                                        invoice.status === "paid" ? (
                                                                        <UncontrolledDropdown
                                                                            nav
                                                                            className="flexy-content position_u"
                                                                        >
                                                                            <DropdownToggle
                                                                                className="pr-0 nav-notice"
                                                                                nav
                                                                            >
                                                                                <Media className="align-items-center">
                                                                                    <img src={showMore} />
                                                                                </Media>
                                                                            </DropdownToggle>
                                                                            <DropdownMenu
                                                                                className="dropdown-menu-arrow min-width-drop"
                                                                                right
                                                                            >
                                                                                {invoice.status === "created" && (
                                                                                    <DropdownItem
                                                                                        onClick={() =>
                                                                                            this.openCancelInvoicePopup(
                                                                                                invoice.invoice_id
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        <span>Cancel</span>
                                                                                    </DropdownItem>
                                                                                )}
                                                                                <DropdownItem
                                                                                    onClick={() => this.downloadInvoice(invoice.invoice_id)}
                                                                                >
                                                                                    <span>Download Invoice</span>
                                                                                </DropdownItem>
                                                                            </DropdownMenu>
                                                                        </UncontrolledDropdown>
                                                                    ) : invoice.status === "cancelled" ? (
                                                                        <div
                                                                            data-tip={invoice?.cancel_reason}
                                                                            data-iscapture="true"
                                                                            data-for="toolTip1"
                                                                            data-place="top"
                                                                            className="new-border-request no_border mr-10 cladb5bd"
                                                                        >
                                                                            <i className="fa fa-info-circle verticalMidAlign"></i>
                                                                            <ReactTooltip
                                                                                place="top"
                                                                                type="info"
                                                                                effect="solid"
                                                                                id="toolTip1"
                                                                                html={true}
                                                                            />
                                                                        </div>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            )}
                                        </Table>
                                    ) : (
                                        <>
                                            <Table
                                                className="align-items-center table-flush"
                                                responsive
                                            >
                                                <thead className="thead-light">
                                                    <tr>
                                                        <th style={{ paddingLeft: "25px" }} scope="col">
                                                            ID
                                                        </th>
                                                        <th scope="col">Title</th>
                                                        <th scope="col">Invoice To</th>
                                                        <th scope="col">Amount</th>
                                                        <th scope="col">Status</th>
                                                        <th style={{ width: "5%" }} scope="col"></th>
                                                        <th style={{ width: "5%" }} scope="col"></th>
                                                    </tr>
                                                </thead>
                                            </Table>
                                            <Card className="card_with_paginator no_data_card">
                                                {" "}
                                                <div className="no-data">No Invoices Available</div>
                                            </Card>
                                        </>
                                    )}
                                </Card>
                            </div>
                        </Row>
                        <Row className="align-items-center header-div jus-space new-margin-page paginator_box">
                            <div className="paginate-flex-box ">
                                <div
                                    className="paginate-box"
                                    onClick={() => this.handleDecrementPage()}
                                >
                                    <p
                                        className={
                                            page !== 1 ? "page_available" : "page_unavailable"
                                        }
                                    >
                                        Previous
                                    </p>
                                </div>
                                <div
                                    className="paginate-box"
                                    onClick={() => this.handleIncrementPage()}
                                >
                                    <p
                                        className={
                                            invoiceList?.length < 10
                                                ? "page_unavailable"
                                                : "page_available"
                                        }
                                    >
                                        Next
                                    </p>
                                </div>
                            </div>
                        </Row>
                    </div>
                </CustomListingContainer>
                <Card className={timeLineOpen ? "exportSideBar" : "toggle-sidebar"}>
                    {timeLineOpen && (
                        <TimeLine
                            id={this.state.timeLine.id}
                            closeTimelineModal={this.closeTimeline}
                            from={this.state.timeLine.from}
                        ></TimeLine>
                    )}
                </Card>
                <Modal
                    isOpen={cancelInvoiceModal}
                    toggle={() => this.toggleCancelInvoiceModal()}
                    centered
                >
                    <CancelInvoiceModal close={this.toggleCancelInvoiceModal} id={invoiceId} />
                </Modal>
                <Modal
                    isOpen={openPaymentModal}
                    centered={true}
                    style={{ minWidth: "650px", padding: "2rem" }}
                >
                    {openPaymentModal && (
                        <PayMethod
                            // props={this.props}
                            invoice_id={invoiceId}
                            close={() => { this.setState({ openPaymentModal: false }); }}
                            amount={invoiceAmount}
                        />
                    )}
                </Modal>
            </div>
        );
    }
}

export default InvoiceList;
