import React, { Component } from "react";
import ApiService from "constants/ApiService";
import {
  CardHeader,
  Input,
  DropdownToggle,
  UncontrolledDropdown,
  Media,
  DropdownMenu,
  DropdownItem,
  InputGroup,
  InputGroupText,
  Spinner,
  Button,
  Tag,
} from "reactstrap";
import closeIcon from "../../assets/img/icons/white_close_icon.svg";
import { showError, isInvalidName, showSuccess } from "constants/utils";
import { Button_without_Border } from "components/Buttons/Button";
import { Custom_Button } from "components/Buttons/Button";
import { CustomDropDown } from "components/FilterFields/Filterfields";
import { CustomTextArea } from "components/FilterFields/Filterfields";
import { remove_underscore_capitalize } from "constants/utils";

class UpdateStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initPageLoading: true,
      status: "",
      title: "",
      statusArr: [
        "active",
        "completed",
        "on Hold",
        "discontinued",
        "cancelled",
        "closed",
      ],
      btnLoad: false,
      statusChange: false,
    };
  }
  componentDidMount = () => {
    this.getProjectDetials();
  };

  getProjectDetials = async () => {
    let response = await ApiService.getAPI(
      `contractor/project/${this.props.id}`
    );
    this.setState({ initPageLoading: false });
    if (response.success) {
      let data = response.data.project;
      this.setState({ status: data.status, title: data.name });
    }
  };

  setTitle = (value) => {
    this.setState({ title: value });
  };

  closeModal = () => {
    this.props.closeUpdateModal();
  };

  capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  updateStatus = async () => {
    if (!this.state.title)
      return showError(this.props.props, "Please enter the project title");
    else if (isInvalidName(this.state.title))
      return showError(this.props.props, "Enter a valid Name");
    this.setState({ btnLoad: true });
    const body = {
      name: this.state.title,
    };
    let updateResponse = await ApiService.patchAPI(
      `contractor/project/status/${this.props.id}${
        this.state.statusChange
          ? `?status=${
              this.state.status === "on Hold" ? "on_hold" : this.state.status
            }`
          : ""
      }`,
      body
    );
    this.setState({ btnLoad: false });
    if (updateResponse.success) {
      showSuccess(this.props.props, updateResponse.message);
      this.setState({ btnLoad: false }, () => {
        this.props.close();
      });
    } else {
      showError(this.props.props, updateResponse.message);
    }
  };

  setSource = (value) => {
    this.setState({
      status: value,
      statusChange: true,
    });
  };

  render() {
    let { initPageLoading, title, status, statusArr, btnLoad } = this.state;
    return (
      <div>
        {initPageLoading ? (
          <div className="spin-loading">
            <Spinner
              style={{
                height: "30px",
                width: "30px",
              }}
              type="border"
              color="#012A4D"
            />
          </div>
        ) : (
          <>
            <CardHeader>
              <div className="flex">
                <div className="fw-500 f16" style={{ color: "white" }}>
                  Update Status
                </div>
                <img
                  src={closeIcon}
                  onClick={() => this.props.close("close")}
                  className="point"
                />
              </div>
            </CardHeader>
            <div className="cardContainer">
              <label className="customLabel">Status*</label>
              <CustomDropDown
                style={{ marginBottom: "10px" }}
                width={"100%"}
                inputField
                innerStyle={{ width: "100%" }}
                status={remove_underscore_capitalize(status)}
                setStatus={this.setSource}
                statusList={statusArr}
                placeHolder="Select the source"
              />
              <label className="customLabel">Title</label>
              <CustomTextArea
                style={{marginBottom:'20px'}}
                inputvalue={title}
                change={this.setTitle}
                from="notes"
              />
              <div className="flexEnd">
                <Button_without_Border
                  style={{ marginRight: "20px" }}
                  btnLabel="cancel"
                  click={() => this.props.close("close")}
                />
                <Custom_Button
                  isPrimary
                  click={() => this.updateStatus()}
                  btnLabel={"update"}
                  isLoading={btnLoad}
                />
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}
export default UpdateStatus;
