import React, { useEffect, useState } from "react";
import { Spinner } from "reactstrap";
import {
  useLocation,
  Route,
  Switch,
  Redirect,
  HashRouter,
} from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import RentalRoutes from "../RentalRoutes";
import routes from "routes.js";
let screen = localStorage.getItem("screen");
export const PageTitle = React.createContext();
const Admin = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();
  let user = localStorage.getItem("user");
  const [title, setTitle] = useState("");
  const [breadcrumb, setBreadcrumb] = useState();
  const [collapse, setCollapse] = useState();

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  useEffect(() => {
  }, [collapse]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
            exact
          />
        );
      } else {
        return null;
      }
    });
  };

  // useEffect(()=>{
  //   this.props.history.push("/admin/index")
  // },[])
  const upDateTitle = (value) => {
    setTitle(value);
  };

  const setIsBreadcrumb = (value) => {
    setBreadcrumb(value);
  };

  function checkCollapse(value) {
    setCollapse(value);
  }

  const loading = (
    <div className="spin-loading">
      <Spinner type="border" color="#077e8b" />
    </div>
  );

  const getRentalRoutes = () => {
    RentalRoutes.map((item, i) => {
      item === false && RentalRoutes.splice(i, 1);
    });
    return RentalRoutes;
  };

  return (
    <>
      <HashRouter>
        <React.Suspense fallback={loading}>
          {
            // title &&
            <PageTitle.Provider
              value={{ title, upDateTitle, breadcrumb, setIsBreadcrumb }}
            >
              <div className="big_container">
                <Sidebar
                  {...props}
                  routes={getRentalRoutes()}
                  logo={{
                    innerLink: "/admin/index",
                    imgSrc:
                      require("../assets/img/new Logo/contractor_new_logo.svg")
                        .default,
                    imgAlt: "...",
                  }}
                  screenProps={screen}
                  collapse={collapse}
                />
                <div className="main-content" ref={mainContent}>
                  <AdminNavbar collapse={checkCollapse} />
                  <Switch>
                    {getRoutes(routes)}

                    <Route
                      path="/login"
                      exact
                      render={() => <Redirect to={"/admin/index"} />}
                    />
                    <Route
                      path="*"
                      exact
                      render={() => <Redirect to={"/admin/index"} />}
                    />
                  </Switch>
                  <Container fluid>
                    <AdminFooter />
                  </Container>
                </div>
              </div>
            </PageTitle.Provider>
          }
        </React.Suspense>
      </HashRouter>
    </>
  );
};

export default Admin;
