import React, { Component, useContext } from "react";
// import { toast, ToastContainer } from "react-toast";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Container,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Spinner,
  Modal,
  InputGroup,
  Input,
  InputGroupText,
} from "reactstrap";
import ApiService from "constants/ApiService";
import { returnFirstDegreeObjValue } from "constants/utils";
import { getCloudinaryImage } from "constants/utils";
import { LightgalleryItem } from "react-lightgallery";
import ToastList from "components/Toast/ToastList";
import { showUtcDate } from "constants/utils";
import { showSuccess } from "constants/utils";
import { showError } from "constants/utils";
import requestCall from "../../../src/assets/img/brand/reqCall.svg";
import requestEmail from "../../../src/assets/img/brand/reqEmail.svg";
import requestMap from "../../../src/assets/img/brand/req_map.svg";
import circle from "../../../src/assets/img/brand/reqLineCircle.svg";
import CustomTimeline from "views/customtimeline";
import requestFirstStage from "../../../src/assets/img/brand/requestFirstStage.svg";
import { returnThirdDegreeObjValue } from "constants/utils";
import returnSecondDegreeObjValue from "constants/utils";
import RejectModal from "./rejectRequestModal";
import defaultUser from "../../../src/assets/img/brand/defaultUser.svg";
import horizontalView from "../../../src/assets/img/brand/grouping.svg";
import horizontalViewSelected from "../../../src/assets/img/brand/groupingSelected.svg";
import verticalView from "../../../src/assets/img/brand/invoiceView.svg";
import verticalViewSelected from "../../../src/assets/img/brand/invoiceViewSelected.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dateLight from "../../assets/img/brand/datePicker.svg";
import removeIcon from "../../assets/img/brand/removeIcon.svg";
import pdfImage from "../../assets/img/brand/pdf.png";
import Add from "../../assets/img/brand/addIcon.svg";
import { uploadCloudinary } from "constants/utils";
import AssignSubcontractors from "./AssignSubContractor";
import { isSub_Contractor } from "constants/utils";
import { returnCustomerFirsLastName } from "constants/utils";
import { displayPrice } from "constants/utils";
import { getCommissionAmount } from "constants/utils";
import { maxLengthCheck } from "constants/utils";
import { showLocaldate } from "constants/utils";
import InviteModal from "views/Subcontractor/inviteModal";
import comment from "../../assets/img/icons/common/comment.svg";
import Chat from "../../variables/chat";
import CustomModal from "components/CustomModal/CustomModal";
import ReactTooltip from "react-tooltip";

// core components
let request_id;
class RequestDetail extends Component {
  constructor(props) {
    super(props);
    // this.otpHandleChange = this.handleChange.bind(this);
    // this.handleSubmit = this.handleSubmit.bind(this);
    request_id = this.props.match.params.request_id;
    this.state = {
      OpenPopUp: false,
      isLoading: true,
      reject_reason: "",
      isFirstStage: true,
      requestData: null,
      dummyRequestStages: [],
      inviteModal: false,
      inviteData: "",
      amount: "",
      otp: "",
      commentOpenUp: false,
      otpType: "pin",
      requestStatus: [
        {
          status: "pending",
          isStatus: false,
          boxText: "Request has been generated",
          date: "",
        },
        // {
        //   status: "assigned",
        //   isStatus: false,
        //   boxText: "This work has been assigned to me",
        //   date: "",
        // },
        {
          status: "accepted",
          isStatus: false,
          boxText: "Request has been accepted",
          date: "",
        },
        {
          status: "in_progress",
          isStatus: false,
          boxText: "Work Is under progress",
          date: "",
        },
        {
          status: "completed",
          isStatus: false,
          boxText: "Work has been successfully completed",
          date: "",
        },
        {
          status: "closed",
          isStatus: false,
          boxText: "Request has been Closed",
          date: "",
        },
      ],
      contractorDetails: null,
      contractor_id: "",
      rejectPopuP: false,
      horizontal: false,
      OpenSchedulePopUp: false,
      scheduleOn: "",
      otpPopUp: false,
      image: [],
      imageLoading: false,
      scheduleModal: false,
      btnLoading: false,
      scheduleLoad: false,
      openRequestId: "",
      contractor: null,
    };
  }
  componentDidMount() {
    const { title, upDateTitle, breadcrumb, setIsBreadcrumb } = this.context;
    if (request_id) this.getRequestDetails();
  }

  getBoxText = (item) => {
    let txt = "";
    let status = item.status;
    if (status === "pending") txt = "Request has been generated";
    else if (status === "assigned") txt = "Request has been assigned to me";
    else if (status === "accepted") txt = "Request has been accepted";
    else if (status === "rejected") txt = "Request has been rejected";
    else if (status === "in_progress") txt = "Work is under process";
    else if (status === "sub_contractor_rejected")
      txt = "Sub contractor has been rejected";
    else if (status === "completed")
      txt = "Work has been successfully completed";
    else if (status === "closed") txt = "Request has been closed";
    else if (status === "cancelled") txt = "Request has been cancelled";
    else if (status === "scheduled") txt = "Request has been scheduled";
    else if (status === "rescheduled") txt = "Request has been rescheduled";
    else if (status === "sub_contractor_assigned")
      txt = isSub_Contractor()
        ? "Request has been assigned"
        : "Sub contractor has been assigned";
    else txt = status;

    return txt;
  };

  getRequestDetails = async () => {
    this.setState({
      dummyRequestStages: this.state.requestStatus.map((x) => ({
        ...x,
      })),
    });
    let response = await ApiService.getAPI(`contractor/requests/${request_id}`);
    if (!response.success) return this.setState({ isLoading: false });
    let data = response.data.request;
    this.setState({ openRequestId: data.open_request_id });

    // end splicing the object from local Array if the request is rejected or assigned to host`
    this.setState(
      {
        // dummyRequestStages: [...arr, ...dummyArray],
        requestData: data,
        image: data?.bills,
        contractor: JSON.parse(localStorage.getItem("contractor_details"))
          .contractor,
        amount:
          isSub_Contractor() || (this.isOutside && this.isMainContractor())
            ? data?.main_contractor_amount?.amount
            : "",
      },
      () => {
        this.setState({ isLoading: false });
        this.setTimelineData();
      }
    );
    this.getContractors(data);
  };

  commentOpenUp = (value) => {
    this.setState({ commentOpenUp: value });
  };

  toggleChatModal = () => {
    this.setState({ commentOpenUp: false });
  };

  setTimelineData = () => {
    let { timeline } = this.state.requestData;
    const completedActions = [];
    const actions = [];
    const constantActions = [...this.state.requestStatus];
    if (isSub_Contractor()) {
      let arr = timeline.map((item) => item.status);
      let lastIndex = arr.lastIndexOf("sub_contractor_assigned");
      timeline.map((item, i) => {
        if (i >= lastIndex) {
          actions.push(item.status);
          completedActions.push({
            status: item?.status,
            isStatus: true,
            boxText: this.getBoxText(item),
            date: item?.date && showUtcDate(item.date),
          });
        }
      });
      if (!(actions.includes("cancelled") || actions.includes("rejected"))) {
        constantActions.map((item, index) => {
          if (index > 0) {
            if (!actions.includes(item.status)) {
              completedActions.push(item);
            }
          }
        });
      }
    } else {
      timeline.map((item, index) => {
        if (item.status) {
          actions.push(item.status);
          completedActions.push({
            status: item?.status,
            isStatus: true,
            boxText: this.getBoxText(item),
            date: item?.date && showUtcDate(item.date),
          });
        }
      });
      if (!(actions.includes("cancelled") || actions.includes("rejected"))) {
        constantActions.map((item, index) => {
          if (!actions.includes(item.status)) {
            completedActions.push(item);
          }
        });
      }
    }

    this.setState({ dummyRequestStages: completedActions });
  };

  getContractors = async (request) => {
    let response;
    if (isSub_Contractor()) {
      response = await ApiService.getAPI(
        `contractor/basic_profile/${request?.contractor?.id}`
      );
    } else {
      if (request.sub_contractor_assigned) {
        response = await ApiService.getAPI(
          `contractor/basic_profile/${request?.sub_contractor?.id}`
        );
      }
    }
    if (response) {
      if (response?.success) {
        let data = response.data.contractor;
        this.setState({
          contractorDetails: data,
          contractor_id: request.contractor.id,
        });
      } else {
        showError(this, response?.message);
      }
    }
  };

  StatusSideBar = (value) => {
    this.setState({ OpenPopUp: value });
  };

  goTostageOne = (value) => {
    this.setState({ isFirstStage: value });
  };

  otpModal = (value, requestType) => {
    if (
      this.state.openRequestId ||
      this.state.requestData.type === "Supply Request"
    ) {
      this.changeRequestState(requestType);
    } else {
      this.setState({ otpPopUp: value });
    }
  };

  otpSubmit = (requestType) => {
    let otp = this.state.otp;
    if (otp === "") {
      showError(this, "Please enter the OTP");
    } else if (requestType === "in_progress") {
      this.changeRequestState(requestType);
    }
  };

  changeRequestState = (requestType) => {
    let data = {};
    if (requestType === "complete") {
      data["amount"] = this.state.amount;
      data["bills"] = this.state.image;
      this.updateRequest(data, requestType);
    } else if (requestType === "in_progress") {
      if (this.state.requestData.type != "Supply Request")
        data["otp"] = this.state.otp;
      this.updateRequest(data, requestType);
    } else if (requestType === "accept") {
      if (this.state.scheduleOn === "") {
        showError(this, "please select schedule date");
      } else {
        data["schedule_date"] = this.state.scheduleOn;
        this.updateRequest(data, requestType);
      }
    } else {
      this.updateRequest(data, requestType);
    }
  };

  updateRequest = async (data, requestType) => {
    this.setState({ btnLoading: true, scheduleLoad: true });
    let response;
    if (requestType === "complete" && this.state.requestData?.open_request_id) {
      response = await ApiService.patchAPI(
        `contractor/open_request/${request_id}`,
        data
      );
    } else {
      response = await ApiService.patchAPI(
        `contractor/requests/${requestType}/${request_id}`,
        data
      );
    }
    if (response.success) {
      this.setState({
        btnLoading: false,
        scheduleLoad: false,
      });

      showSuccess(this, response.message);
      this.ScheduleSideBar(false);

      this.clearAssignStages();
      this.getRequestDetails();
    } else {
      this.setState({
        btnLoading: false,
        scheduleLoad: false,
      });

      showError(this, response.message);
    }
  };

  clearAssignStages = () => {
    this.setState({
      oedContractor: {},
      OpenPopUp: false,
      otp: "",
      otpPopUp: false,
    });
  };

  handleInputChange = (e) => {
    this.setState({ amount: e.target.value });
  };

  completeRequest = () => {
    let numberRegex = /^[0-9]+$/;
    if (this.state.amount === "" || this.state.amount === undefined) {
      showError(this, "Please enter the amount you have spent");
    } else if (!numberRegex.test(this.state.amount)) {
      showError(this, "Field should contain only number");
    } else if (this.state.amount <= 0) {
      showError(this, "Please enter a valid amount");
    } else {
      this.changeRequestState("complete");
    }
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleRejectReason = async () => {
    let { reject_reason } = this.state;
    if (reject_reason === "") {
      showError(this, "Please enter reason to reject this request");
    } else {
      let data = { reject_reason };
      let response = await ApiService.patchAPI(
        `/contractor/requests/reject/${request_id}`,
        data
      );
      if (response.success) {
        showSuccess(this, response.message);
        setTimeout(() => this.getRequestDetails(), 1200);
        this.rejectPopUp(false);
        this.props.history.goBack();
      } else {
        showError(this, response.message);
      }
    }
  };

  rejectPopUp = (value) => {
    this.setState({ rejectPopuP: value });
  };

  mapRedirect = () => {
    window.open(
      `http://www.google.com/maps/place/${this.state.requestData?.property.loc[1]},${this.state.requestData?.property.loc[0]}`,
      "_black"
    );
  };

  ScheduleSideBar = (value) => {
    this.setState({ OpenSchedulePopUp: value });
  };

  handleScheduleOnChange = (e) => {
    this.setState({ scheduleOn: e });
  };

  scheduleRequest = async () => {
    let { scheduleOn } = this.state;
    if (scheduleOn === "" || scheduleOn === null || scheduleOn === undefined) {
      showError(this, "Please Select schedule Date");
    } else {
      this.setState({ scheduleLoad: true });
      let data = {
        date: scheduleOn,
      };

      let response = await ApiService.patchAPI(
        `contractor/requests/reschedule/${request_id}`,
        data
      );
      if (response.success) {
        this.setState({ scheduleLoad: false });

        showSuccess(this, response.message);

        this.ScheduleSideBar(false);
        this.getRequestDetails();
      } else {
        this.setState({ scheduleLoad: false });

        showError(this, response.message);
      }
    }
  };

  onImageChangeHandler = async (e, subStatus) => {
    let { image } = this.state;
    let file = e.target.files[0];
    if (e.target.files.length > 0) {
      this.setState({ imageLoading: true });
    }
    if (
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/jpeg" ||
      file.type === "application/pdf"
    ) {
      if (file.size < 1000000) {
        let data = await uploadCloudinary(file, "host");
        if (data.url) {
          let imgArr = [...image];
          let imageObj = {
            format: data.format,
            public_id: data.public_id,
            version: `v${data.version}`,
            type: "image",
          };
          imgArr.push(imageObj);
          this.setState(
            {
              image: imgArr,
              imageLoading: !subStatus && false,
            },
            () => {
              subStatus && this.updateBills();
            }
          );
        } else {
          showError(this, "Could not upload image");
          this.setState({ imageLoading: false });
        }
      } else {
        showError(this, "File size should not exceeds 1 MB");
        this.setState({ imageLoading: false });
      }
    } else {
      showError(this, "Invalid Format");
      this.setState({ imageLoading: false });
    }
  };

  deleteImage = (i, subStatus) => {
    let { image } = this.state;
    let imgArr = [...image];
    imgArr.splice(i, 1);
    this.setState({ image: imgArr }, () => {
      subStatus && this.updateBills();
    });
  };

  updateBills = async () => {
    let { image } = this.state;
    let data = {
      bills: image,
    };
    let response = await ApiService.patchAPI(
      `contractor/requests/${request_id}`,
      data
    );
    if (response.success) {
      this.setState({ imageLoading: false });
      showSuccess(this, response.message);
    } else {
      showError(this, response.message);
    }
  };

  openPdf = (data) => {
    window.open(getCloudinaryImage(100, 100, data, "c_fill"));
  };

  openAssignSubcontractor = () => {
    this.setState({ subcontractorModal: true });
  };

  closeAssignSubcontractor = (msg, url) => {
    this.setState({ subcontractorModal: false });
    if (msg) {
      showSuccess(this, msg);
      this.getRequestDetails();
    }
    if (url) {
      this.setState({ inviteData: { link: url } }, () => {
        this.setState({ inviteModal: true });
      });
    }
  };

  showToastMessage = (msg) => {
    showError(this, msg);
  };

  getScheduleDate = () => {
    this.setState({ scheduleModal: true });
  };

  getSubContractorPrice = (requestData) => {
    let amt;
    let { sub_contractor, main_contractor_amount, amount } = requestData;
    if (sub_contractor?.id && main_contractor_amount?.type) {
      if (main_contractor_amount?.type === "percentage") {
        amt = displayPrice(amount * (main_contractor_amount?.commission / 100));
      } else {
        amt = displayPrice(main_contractor_amount?.commission);
      }
    }

    return amt ? amt : "-";
  };

  getSubContractorCommission = (requestData) => {
    let amt;
    let { sub_contractor, main_contractor_amount, amount } = requestData;
    if (sub_contractor?.id && main_contractor_amount?.type) {
      if (main_contractor_amount?.type === "percentage") {
        amt = amount * (main_contractor_amount?.commission / 100);
      } else {
        amt = main_contractor_amount?.commission;
      }
    }

    return amt ? amt : 0;
  };
  closeInviteModal = () => {
    this.setState({ inviteModal: false });
  };
  showToastMessage = (msg) => {
    showError(this, msg);
  };
  otpHandleChange = (e) => {
    this.setState({ otp: e.target.value.replace(/\D/g, "") });
  };
  handleSubmit(event) {
    const data = new FormData(event.target);
    event.preventDefault();
  }
  isOutside = () => {
    return this.state.requestData?.assign_type === "outside";
  };

  isMainContractor() {
    return (
      this.state.requestData?.sub_contractor?.id ===
      this.state.contractor?.contractor_id
    );
  }

  render() {
    let {
      isLoading,
      toast,
      response,
      toastType,

      OpenPopUp,
      isFirstStage,
      requestData,
      OpenSchedulePopUp,
      otpPopUp,
      horizontal,
      commentOpenUp,
      scheduleOn,
      image,
      imageLoading,
      subcontractorModal,

      scheduleLoad,
      contractorDetails,
      inviteModal,
      inviteData,
      otp,
      otpType,
    } = this.state;

    return isLoading ? (
      <div className="spin-loading">
        <Spinner type="border" color="#077e8b" />
      </div>
    ) : (
      <>
        {/* reject Modal */}
        <RejectModal
          open={this.state.rejectPopuP}
          close={() => this.rejectPopUp(false)}
          onInputChange={this.handleChange}
          onSubmit={() => this.handleRejectReason()}
          id="request"
        />
        {/* end of reject Modal */}

        <Container className="pad24p" fluid>
          {toast && <ToastList message={response} type={toastType} />}

          <div className="maintainenceDetailHeader">
            <div xs="6" className="manage-con">
              <Breadcrumb>
                <BreadcrumbItem
                  onClick={() => this.props.history.goBack()}
                  className="bread-cursor"
                >
                  Requests
                </BreadcrumbItem>
                <BreadcrumbItem style={{ color: '#72569C' }} active>Details</BreadcrumbItem>
              </Breadcrumb>
            </div>
            <Col xs="6" className="manage-con request_button_div">
              {requestData &&
                requestData.status === "assigned" &&
                ((!isSub_Contractor() &&
                  !requestData.sub_contractor_assigned) ||
                  isSub_Contractor() ||
                  (this.isMainContractor() && this.isOutside())) && (
                  <>
                    <Button
                      className="button_without_border  no_shadow"
                      onClick={() => this.rejectPopUp(true)}
                    >
                      Reject
                    </Button>

                    <Button
                      className="button-wrapping ft_white"
                      onClick={() => this.ScheduleSideBar(true)}

                    // this.changeRequestState("accept")
                    >
                      Accept
                    </Button>
                    {!isSub_Contractor() && !this.isMainContractor() && (
                      <Button
                        className="button-wrapping ft_white"
                        onClick={() => this.openAssignSubcontractor()}
                      >
                        Assign
                      </Button>
                    )}
                  </>
                )}
              <div className="flexmid">
                {requestData &&
                  (requestData.status === "pending" ||
                    requestData.status === "accepted" ||
                    requestData.status === "scheduled" ||
                    requestData.status === "rescheduled") &&
                  (isSub_Contractor() ||
                    (!isSub_Contractor() &&
                      !requestData.sub_contractor_assigned) ||
                    (this.isMainContractor() && this.isOutside())) && (
                    <Button
                      className="button_without_border  no_shadow p_0 mr-2r"
                      onClick={() => this.ScheduleSideBar(true)}
                    >
                      Reschedule
                    </Button>
                  )}
                {requestData &&
                  (requestData.status === "accepted" ||
                    requestData.status === "scheduled" ||
                    requestData.status === "rescheduled") &&
                  (isSub_Contractor() ||
                    (!isSub_Contractor() &&
                      !requestData.sub_contractor_assigned) ||
                    (this.isMainContractor() && this.isOutside())) && (
                    <Button
                      className="button-wrapping ft_white"
                      onClick={() => this.otpModal(true, "in_progress")}
                      style={{ minWidth: "170px" }}
                    >
                      {this.state.btnLoading ? (
                        <div className="spin-loading-save w100 h100">
                          <Spinner type="border" color="#72569c spinner-borders" />
                        </div>
                      ) : (
                        "Mark as in Progress"
                      )}
                    </Button>
                  )}
                {requestData &&
                  requestData.status === "in_progress" &&
                  (isSub_Contractor() ||
                    (!isSub_Contractor() &&
                      !requestData.sub_contractor_assigned) ||
                    (this.isMainContractor() && this.isOutside())) && (
                    <Button
                      className="button-wrapping ft_white"
                      // onClick={() => this.changeRequestState("complete")}
                      onClick={() =>
                        isSub_Contractor() ||
                          (this.isMainContractor() && this.isOutside())
                          ? this.changeRequestState("complete")
                          : this.StatusSideBar(true)
                      }
                    >
                      {this.state.btnLoading ? (
                        <div className="spin-loading-save w100 h100">
                          <Spinner type="border" color="#0A8080" />
                        </div>
                      ) : (
                        "Complete Request"
                      )}
                    </Button>
                  )}
              </div>
              {requestData.status !== "pending" &&
                requestData.status !== "rejected" &&
                requestData.status !== "cancelled" &&
                requestData.status !== "assigned" && (
                  <div
                    style={{ marginLeft: "24px" }}
                    onClick={() => this.commentOpenUp(true)}
                    className="comment_Bodering"
                  >
             
                    <div
                        data-tip="Chat with Property Incharge"
                        data-iscapture="true"
                        data-for="toolTip1"
                        data-place="top"
                      >
                        <img style={{ padding: "10px" }} src={comment} />
                      </div>
                      <ReactTooltip
                        place="right"
                        type="info"
                        effect="solid"
                        id="toolTip1"
                        html={true}
                      />

            
                  </div>
                )}
            </Col>
          </div>
          {requestData && (
            <div className="details_parent_card_holder">
              <Card
                style={{
                  border: "1px solid #E1E8F0",
                  boxShadow: "0px 2px 20px 0px #0000000A",
                  borderRadius: "10px",
                }}
                className="request_left_card"
              >
                <div className="individual_details">
                  <div className="content_div_request">
                    <div className="header_and_data">
                      <p className="header">Customer</p>
                      <p className="colon_seperator">:</p>
                      <p className="header_data capitalize">
                        {/* {returnThirdDegreeObjValue(
                          requestData,
                          "user",
                          "name",
                          "first"
                        )}
                        {returnThirdDegreeObjValue(
                          requestData,
                          "user",
                          "name",
                          "last"
                        )} */}
                        {requestData?.user &&
                          returnCustomerFirsLastName(
                            requestData,
                            "user",
                            "name",
                            "first",
                            "last"
                          )}
                      </p>
                    </div>
                  </div>
                  <div className="content_div_request request_small_width_div">
                    <div className="header_and_data ft_family">
                      <p className="header">Priority</p>
                      <p className="colon_seperator">:</p>
                      <p
                        className={
                          requestData?.priority_level === "high"
                            ? "status_red header_data capitalize"
                            : requestData?.priority_level === "Medium"
                              ? "status_yellow header_data capitalize"
                              : "status_accepted header_data capitalize"
                        }
                      >
                        {requestData &&
                          returnFirstDegreeObjValue(
                            requestData,
                            "priority_level"
                          )}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="individual_details">
                  <div className="content_div_request">
                    <div className="header_and_data">
                      <p className="header">Email</p>
                      <p className="colon_seperator">:</p>
                      <p className="header_data ">
                        {" "}
                        {requestData?.user &&
                          returnSecondDegreeObjValue(
                            requestData,
                            "user",
                            "email"
                          )}
                      </p>
                    </div>
                  </div>
                  <div className="content_div_request request_small_width_div">
                    <div className="header_and_data ft_family">
                      <p className="header">Phone</p>
                      <p className="colon_seperator">:</p>
                      <p className="header_data">
                        {requestData?.user &&
                          returnThirdDegreeObjValue(
                            requestData,
                            "user",
                            "phone",
                            "national_number"
                          )}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="individual_details">
                  <div className="content_div_request">
                    <div className="header_and_data">
                      <p className="header">Property</p>
                      <p className="colon_seperator">:</p>
                      <p className="header_data capitalize">
                        {" "}
                        {requestData?.property &&
                          returnSecondDegreeObjValue(
                            requestData,
                            "property",
                            "name"
                          )}
                      </p>
                    </div>
                  </div>
                  <div className="content_div_request request_small_width_div">
                    <div className="header_and_data ft_family">
                      <p className="header">Type </p>
                      <p className="colon_seperator">:</p>
                      <p className="header_data">
                        {" "}
                        {requestData?.type &&
                          returnFirstDegreeObjValue(requestData, "type")}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="individual_details">
                  <div className="content_div_request ">
                    <div className="header_and_data">
                      <p className="header">Requested On</p>
                      <p className="colon_seperator">:</p>
                      <p className="header_data">
                        {requestData &&
                          requestData?.createdAt &&
                          showUtcDate(requestData.createdAt)}
                      </p>
                    </div>
                  </div>
                  <div className="content_div_request request_small_width_div">
                    <div className="header_and_data ft_family">
                      <p className="header">Issues from</p>
                      <p className="colon_seperator">:</p>
                      <p className="header_data">
                        {showUtcDate(requestData && requestData.from)}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="individual_details">
                  <div className="content_div_request ">
                    <div className="header_and_data">
                      <p className="header">Issues</p>
                      <p className="colon_seperator">:</p>
                      <p className="header_data">
                        {requestData &&
                          requestData.issues &&
                          requestData.issues.map((item, i) => (
                            <span key={i}>
                              {" "}
                              <span>{item}</span>
                              {i < requestData.issues.length - 1 && ", "}
                            </span>
                          ))}
                      </p>
                    </div>
                  </div>
                  <div className="content_div_request request_small_width_div">
                    <div className="header_and_data ft_family">
                      <p className="header">Status</p>
                      <p className="colon_seperator">:</p>
                      <p
                        className={
                          requestData.status === "pending" ||
                            requestData.status === "in_progress" ||
                            requestData.status === "scheduled" ||
                            requestData.status === "rescheduled"
                            ? "status_yellow header_data capitalize"
                            : requestData.status === "assigned" ||
                              requestData.status === "accepted" ||
                              requestData.status === "completed"
                              ? "status_completed header_data capitalize"
                              : "status_red header_data capitalize"
                        }
                      >
                        {requestData && requestData.status === "in_progress"
                          ? "In Progress"
                          : requestData.status}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="individual_details">
                  <div className="content_div_request ">
                    <div className="header_and_data">
                      <p className="header">Invoice </p>
                      <p className="colon_seperator">:</p>
                      <p className="header_data">
                        {requestData &&
                          returnFirstDegreeObjValue(requestData, "invoice_id")}
                      </p>
                    </div>
                  </div>
                  <div className="content_div_request request_small_width_div">
                    <div className="header_and_data ft_family">
                      <p className="header">Schedule Date</p>
                      <p className="colon_seperator">:</p>
                      <p className="header_data">
                        {requestData &&
                          showLocaldate(
                            returnFirstDegreeObjValue(
                              requestData,
                              "schedule_date"
                            )
                          )}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="individual_details">
                  <div className="content_div_request">
                    <div className="header_and_data">
                      <p className="header">Description </p>
                      <p className="colon_seperator">:</p>
                      <p className="header_data">
                        {returnFirstDegreeObjValue(requestData, "desc")}
                      </p>
                    </div>
                  </div>
                  {requestData && (
                    <div className="content_div_request request_small_width_div">
                      <div className="header_and_data ft_family">
                        <p className="header">Amount</p>
                        <p className="colon_seperator">:</p>
                        <p className="header_data">
                          {isSub_Contractor() ||
                            (this.isMainContractor() && this.isOutside())
                            ? requestData &&
                            displayPrice(
                              parseFloat(
                                requestData?.main_contractor_amount?.amount
                              ) -
                              parseFloat(
                                this.getSubContractorCommission(requestData)
                              )
                            )
                            : requestData &&
                            displayPrice(
                              returnFirstDegreeObjValue(requestData, "amount")
                            )}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
                {!(
                  isSub_Contractor() ||
                  (this.isMainContractor() && this.isOutside())
                ) &&
                  requestData &&
                  requestData?.sub_contractor_assigned && (
                    <div className="individual_details">
                      <div className="content_div_request">
                        <div className="header_and_data">
                          <p className="header">Subcontractor Amount</p>
                          <p className="colon_seperator">:</p>
                          <p className="header_data">
                            {displayPrice(
                              parseFloat(
                                returnFirstDegreeObjValue(requestData, "amount")
                              ) -
                              parseFloat(
                                this.getSubContractorCommission(requestData)
                              )
                            )}
                          </p>
                        </div>
                      </div>
                      {requestData && (
                        <div className="content_div_request request_small_width_div">
                          <div className="header_and_data ft_family">
                            <p className="header">Commission</p>
                            <p className="colon_seperator">:</p>
                            <p className="header_data">
                              {isSub_Contractor() ||
                                (this.isMainContractor() && this.isOutside())
                                ? requestData &&
                                getCommissionAmount(
                                  requestData,
                                  requestData?.amount,
                                  isSub_Contractor()
                                )
                                : requestData &&
                                displayPrice(
                                  this.getSubContractorCommission(requestData)
                                )}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                {requestData && requestData.reject_reason && (
                  <div className="individual_details mb_0">
                    <div className="content_div_request">
                      <div className="header_and_data">
                        <p className="header">Reject Reason </p>
                        <p className="colon_seperator">:</p>
                        <p className="header_data">
                          {returnFirstDegreeObjValue(
                            requestData,
                            "reject_reason"
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
     <div className="individual_details">
                  <div className="content_div_request ">
                    <div className="header_and_data">
                      <p className="header">Estimate Cost </p>
                      <p className="colon_seperator">:</p>
                      <p className="header_data">
                        {requestData &&
                        displayPrice(requestData?.approximate_cost)}
                      </p>
                    </div>
                  </div>
                  <div className="content_div_request request_small_width_div">
                  
                  </div>
                </div>
                <hr className="request_card_detail_divider"></hr>
                <div className="request_image_map_div">
                  <div className="request_images">
                    <p className="photo_header">Photos</p>
                    <div className="request_image_container">
                      {requestData &&
                        requestData.images &&
                        requestData.images.length > 0 ? (
                        requestData.images.map((item, i) => (
                          <>
                            <LightgalleryItem
                              className="image_upload_display request_image"
                              src={getCloudinaryImage(330, 330, item, "c_fill")}
                            >
                              <div className="mr_10">
                                {i <= 3 && (
                                  <div className="request_image relative cursor_pointer ">
                                    <img
                                      key={i}
                                      className="request_detail_image "
                                      src={getCloudinaryImage(
                                        130,
                                        110,
                                        item,
                                        "c_fill"
                                      )}
                                    />
                                    {requestData.images.length > 4 &&
                                      i === 3 && (
                                        <>
                                          <div className="shadow_view_more cursor_pointer"></div>
                                          <p className="view_more mb_0 cursor_pointer">
                                            <p className="f13 fw-400 ">View</p>

                                            <p className="f13 fw-400">more +</p>
                                          </p>
                                        </>
                                      )}
                                  </div>
                                )}
                              </div>
                            </LightgalleryItem>
                          </>
                        ))
                      ) : (
                        <p>No photos available</p>
                      )}
                    </div>
                  </div>

                  {requestData?.property?.loc?.length > 0 && (
                    <div
                      className="request_map cursor_pointer"
                      onClick={() => this.mapRedirect()}
                    >
                      <img src={requestMap} />
                    </div>
                  )}
                </div>
              </Card>
              <Card
                style={{
                  border: "1px solid #E1E8F0",
                  boxShadow: "0px 2px 20px 0px #0000000A",
                  borderRadius: "10px",
                }}
                className="request_right_card"
              >
                <h2 className="ft_family">Customer Details</h2>
                <div className="customer_req_detail_div">
                  <div className="left_customer_req_div">
                    <img
                      src={
                        requestData?.user?.image
                          ? getCloudinaryImage(
                            120,
                            120,
                            requestData.user.image,
                            "c_fill"
                          )
                          : defaultUser
                      }
                    />
                  </div>
                  <div className="right_customer_req_div">
                    <h3 className="ft_family">
                      {" "}
                      {requestData.user &&
                        returnThirdDegreeObjValue(
                          requestData,
                          "user",
                          "name",
                          "first"
                        )}{" "}
                      &nbsp;&nbsp;
                      {requestData.user &&
                        returnThirdDegreeObjValue(
                          requestData,
                          "user",
                          "name",
                          "last"
                        )}
                    </h3>
                    {requestData?.user?.phone?.national_number && (
                      <div className="detail_with_icon">
                        <img className="detail_icon" src={requestCall}></img>
                        <p className="host_customer_detail ft_family">
                          {requestData?.user?.phone?.national_number}
                        </p>
                      </div>
                    )}

                    {requestData?.user?.email && (
                      <div className="detail_with_icon mb_0">
                        <img className="detail_icon" src={requestEmail}></img>
                        <p className="host_customer_detail ft_family">
                          {returnSecondDegreeObjValue(
                            requestData,
                            "user",
                            "email"
                          )}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                <hr className="request_card_detail_divider"></hr>
                {requestData && !requestData.open_request_id && (
                  <>
                    <h2 className="ft_family">Host Details</h2>
                    <div className="customer_req_detail_div">
                      <div className="left_customer_req_div">
                        <img
                          src={
                            requestData && requestData.manager
                              ? requestData.manager.image
                                ? getCloudinaryImage(
                                  130,
                                  110,
                                  returnSecondDegreeObjValue(
                                    requestData,
                                    "manager",
                                    "image"
                                  ),
                                  "c_fill"
                                )
                                : defaultUser
                              : requestData.host && requestData.host.image
                                ? getCloudinaryImage(
                                  130,
                                  110,
                                  returnSecondDegreeObjValue(
                                    requestData,
                                    "host",
                                    "image"
                                  ),
                                  "c_fill"
                                )
                                : defaultUser
                          }
                        />
                      </div>
                      <div className="right_customer_req_div">
                        <h3 className="ft_family">
                          {requestData.manager
                            ? returnSecondDegreeObjValue(
                              requestData,
                              "manager",
                              "name"
                            )
                            : returnSecondDegreeObjValue(
                              requestData,
                              "host",
                              "name"
                            )}
                        </h3>
                        {requestData && (
                          <div className="detail_with_icon">
                            <img
                              className="detail_icon"
                              src={requestCall}
                            ></img>
                            <p className="host_customer_detail ft_family">
                              {requestData.manager
                                ? requestData?.manager?.phone.national_number
                                : requestData?.host?.phone?.national_number}
                            </p>
                          </div>
                        )}

                        {requestData && (
                          <div className="detail_with_icon mb_0">
                            <img
                              className="detail_icon"
                              src={requestEmail}
                            ></img>
                            <p className="host_customer_detail ft_family">
                              {requestData.manager
                                ? returnSecondDegreeObjValue(
                                  requestData,
                                  "manager",
                                  "email"
                                )
                                : returnSecondDegreeObjValue(
                                  requestData,
                                  "host",
                                  "email"
                                )}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}

                {!isSub_Contractor() &&
                  requestData?.sub_contractor_assigned && (
                    <>
                      <hr className="request_card_detail_divider"></hr>
                      <h2 className="ft_family flex">
                        <div>Subcontractor Details</div>
                        {requestData?.status !== "completed" &&
                          !(this.isMainContractor() && this.isOutside()) && (
                            <div
                              className="cl0A8080 point"
                              onClick={() => this.openAssignSubcontractor()}
                            >
                              Reassign
                            </div>
                          )}
                      </h2>
                      <div className="customer_req_detail_div">
                        <div className="left_customer_req_div">
                          <img
                            src={
                              contractorDetails && contractorDetails.image
                                ? getCloudinaryImage(
                                  130,
                                  110,
                                  returnFirstDegreeObjValue(
                                    contractorDetails,
                                    "image"
                                  ),
                                  "c_fill"
                                )
                                : defaultUser
                            }
                          />
                        </div>
                        <div className="right_customer_req_div">
                          <h3 className="ft_family">
                            {contractorDetails &&
                              returnFirstDegreeObjValue(
                                contractorDetails,
                                "name"
                              )}
                          </h3>
                          {contractorDetails?.phone?.national_number && (
                            <div className="detail_with_icon">
                              <img
                                className="detail_icon"
                                src={requestCall}
                              ></img>
                              <p className="host_customer_detail ft_family">
                                {contractorDetails?.phone?.national_number}
                              </p>
                            </div>
                          )}

                          {contractorDetails?.email && (
                            <div className="detail_with_icon mb_0">
                              <img
                                className="detail_icon"
                                src={requestEmail}
                              ></img>
                              <p className="host_customer_detail ft_family">
                                {returnFirstDegreeObjValue(
                                  contractorDetails,
                                  "email"
                                )}
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                {isSub_Contractor() && (
                  <>
                    <hr className="request_card_detail_divider"></hr>
                    <h2 className="ft_family flex">
                      <div>Contractor Details</div>
                    </h2>
                    <div className="customer_req_detail_div">
                      <div className="left_customer_req_div">
                        <img
                          src={
                            contractorDetails && contractorDetails.image
                              ? getCloudinaryImage(
                                130,
                                110,
                                returnFirstDegreeObjValue(
                                  contractorDetails,
                                  "image"
                                ),
                                "c_fill"
                              )
                              : defaultUser
                          }
                        />
                      </div>
                      <div className="right_customer_req_div">
                        <h3 className="ft_family">
                          {contractorDetails &&
                            returnFirstDegreeObjValue(
                              contractorDetails,
                              "name"
                            )}
                        </h3>

                        {contractorDetails?.phone?.national_number && (
                          <div className="detail_with_icon">
                            <img
                              className="detail_icon"
                              src={requestCall}
                            ></img>
                            <p className="host_customer_detail ft_family">
                              {contractorDetails?.phone?.national_number}
                            </p>
                          </div>
                        )}

                        {contractorDetails?.email && (
                          <div className="detail_with_icon mb_0">
                            <img
                              className="detail_icon"
                              src={requestEmail}
                            ></img>
                            <p className="host_customer_detail ft_family">
                              {returnFirstDegreeObjValue(
                                contractorDetails,
                                "email"
                              )}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </Card>
            </div>
          )}
          {requestData &&
            requestData?.status === "completed" &&
            (isSub_Contractor() ||
              (!isSub_Contractor() &&
                !requestData.sub_contractor_assigned)) && (
              <Card className="mt-10 pad24p">
                <div className="f14 fw-600 cl374957 mb-20">Bills</div>
                <div className="gridAuto">
                  {image.length > 0 &&
                    image.map((img, i) => (
                      <div className="requestBillImage">
                        {img.format === "pdf" ? (
                          <img
                            src={pdfImage}
                            alt="PDF"
                            onClick={() => this.openPdf(img)}
                            className="point br-5"
                            width="50px"
                            height="70px"
                          />
                        ) : (
                          <LightgalleryItem
                            className="image_upload_display request_image"
                            src={getCloudinaryImage(100, 100, img, "c_fill")}
                          >
                            <img
                              src={getCloudinaryImage(100, 100, img, "c_fill")}
                              alt="profile image"
                              className="point imgFit br-5"
                              width="70px"
                              height="70px"
                            />
                          </LightgalleryItem>
                        )}

                        <img
                          src={removeIcon}
                          className="deleteIcon"
                          onClick={() => this.deleteImage(i, true)}
                        />
                      </div>
                    ))}
                  {image.length < 5 && (
                    <div
                      className="requestImageUploader point form-relative"
                      style={{ width: "70px", height: "70px" }}
                    >
                      {imageLoading ? (
                        <div className="spin-loading3">
                          <Spinner type="border" color="#0A8080" />
                        </div>
                      ) : (
                        <div>
                          <Input
                            type="file"
                            onChange={(event) =>
                              this.onImageChangeHandler(event, true)
                            }
                            className="form-control-alternative form-control opac-hide "
                            id="ImageUpload"
                          />
                          <label htmlFor="ImageUpload " className="mb-0">
                            <div className="flexmid point">
                              <img src={Add} className="point" alt="ADD" />
                            </div>
                          </label>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </Card>
            )}

          <Card
            style={{
              border: "1px solid #E1E8F0",
              boxShadow: "0px 2px 20px 0px #0000000A",
              borderRadius: "10px",
            }}
            className="timeline_card mt_10"
          >
            <div className="flex">
              <h2 className="ft_family">Timeline</h2>
              <div className="flex">
                <img
                  src={horizontal ? verticalView : verticalViewSelected}
                  className="mr-10 point"
                  onClick={() => this.setState({ horizontal: false })}
                />
                <img
                  src={horizontal ? horizontalViewSelected : horizontalView}
                  className=" point"
                  onClick={() => this.setState({ horizontal: true })}
                />
              </div>
            </div>
            <div
              className={
                horizontal ? "timeline_div horiOverflow" : "hori_timeline"
              }
            >
              {horizontal && <img src={circle} />}
              <div className={horizontal ? "timeline_common_line" : ""}>
                <CustomTimeline
                  className="absolute_div"
                  data={this.state.dummyRequestStages}
                  view={horizontal}
                />
              </div>
              {horizontal && <img src={circle} />}
            </div>
          </Card>
          {/* change status side bar design */}
        
            <Card className={ OpenPopUp ? "exportSideBar" : "toggle-sidebar"}>
            {OpenPopUp && (
         
                  <CustomModal
                onlyView
                type="mark_as_completed"
                close={() => this.StatusSideBar(false)}
              >
               
                <div className="request_complete_stages_div fade_effect">
                  <div className="request_complete_stage_one">
                  {(!isFirstStage && this.state.requestData?.approximate_cost) &&<>  <h2
                      className={
                        isFirstStage ? "ft_family mb_40" : "ft_family mb-20"
                      }
                    >
                     
                        Estimate Cost
                    </h2> <div className="amount_input mb_20">
                        <FormGroup className="">
                          <InputGroup className="d_flex_align">
                            <Input
                              className="search_contractor price_input ft_15"
                              type="number"
                              name="amount"
                              disabled={true}
                              value={this.state.requestData.approximate_cost}
                              
                              // onChange={this.handleInputChange}
                              // maxLength={6}
                              // onInput={maxLengthCheck}
                            />
                          </InputGroup>
                        </FormGroup>
                      </div></> }
                      <h2
                      className={
                        isFirstStage ? "ft_family mb_40" : "ft_family mb-20"
                      }
                    >
                      {isFirstStage
                        ? " Are you sure you want to mark this request as completed?"
                        : "Please enter the Price Amount *"}
                    </h2>
                    {isFirstStage && (
                      <div className="illustration_image_div d_flex_align_justify mb_50">
                        <img src={requestFirstStage} />
                      </div>
                    )}
                    {!isFirstStage && (
                      <div className="amount_input mb_20">
                        <FormGroup className="">
                          <InputGroup className="d_flex_align">
                            <Input
                              className="search_contractor price_input ft_15"
                              type="number"
                              name="amount"
                              placeholder="Please enter the amount"
                              value={this.state.amount}
                              onChange={this.handleInputChange}
                              maxLength={6}
                              onInput={maxLengthCheck}
                            />
                          </InputGroup>
                        </FormGroup>
                      </div>
                    )}
                    {!isFirstStage && (
                      <div className="mb-40">
                        <div className="f14 fw-600 cl374957 mb-20">
                          Attach Bills if any
                        </div>
                        <div className="gridAuto65">
                          {image.length > 0 &&
                            image.map((img, i) => (
                              <div className="requestBillImage">
                                {img.format === "pdf" ? (
                                  <img
                                    src={pdfImage}
                                    alt="PDF"
                                    onClick={() => this.openPdf(img)}
                                    className="point br-5"
                                    width="50px"
                                    height="70px"
                                  />
                                ) : (
                                  <LightgalleryItem
                                    className="image_upload_display request_image "
                                    src={getCloudinaryImage(
                                      100,
                                      100,
                                      img,
                                      "c_fill"
                                    )}
                                  >
                                    <img
                                      src={getCloudinaryImage(
                                        70,
                                        70,
                                        img,
                                        "c_fill"
                                      )}
                                      alt="profile image br-5"
                                      className="point"
                                    />
                                  </LightgalleryItem>
                                )}
                                <img
                                  src={removeIcon}
                                  className="deleteIcon"
                                  onClick={() => this.deleteImage(i, false)}
                                />
                              </div>
                            ))}
                          {image.length < 5 && (
                            <div className="requestImageUploader point form-relative">
                              {imageLoading ? (
                                <div className="spin-loading3">
                                  <Spinner type="border" color="#0A8080" />
                                </div>
                              ) : (
                                <div>
                                  <Input
                                    type="file"
                                    onChange={(event) =>
                                      this.onImageChangeHandler(event, false)
                                    }
                                    className="form-control-alternative form-control opac-hide "
                                    id="ImageUpload"
                                  />
                                  <label
                                    htmlFor="ImageUpload "
                                    className="mb-0"
                                  >
                                    <div className="flexmid point">
                                      <img
                                        src={Add}
                                        className="point"
                                        alt="ADD"
                                      />
                                    </div>
                                  </label>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    <div className="buttons_div ">
                      {isFirstStage ? (
                        <div className="stage_buttons">
                          <Button
                            className="button-wrapping ft_white mr_0"
                            onClick={() => this.goTostageOne(false)}
                          >
                            Yes, Enter the Total Price
                          </Button>
                          <Button
                            className="button_without_border  no_shadow mr_0"
                            onClick={() => this.StatusSideBar(false)}
                          >
                            No, Go back
                          </Button>
                        </div>
                      ) : (
                        <div className="stage_buttons">
                          <Button
                            className="button-wrapping ft_white mr_0"
                            onClick={() => this.completeRequest()}
                          >
                            {this.state.btnLoading ? (
                              <div className="spin-loading-save w100 h100">
                                <Spinner type="border" color="#0A8080" />
                              </div>
                            ) : (
                              " Yes, Mark as complete"
                            )}
                          </Button>
                          <Button
                            className="button_without_border  no_shadow mr_0"
                            onClick={() => this.goTostageOne(true)}
                          >
                            No, Go back
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>{" "}
                </CustomModal>
        
            )}
          </Card>
          {/* end of status side bar design */}

          <Modal
            isOpen={OpenSchedulePopUp}
            toggle={() => this.ScheduleSideBar(false)}
            centered
          >
            <Card>
              <CardBody className="pad25">
                <div className="mb-30 flex">
                  <div className=" cl262626 fw-600 f16">
                    {requestData?.status === "assigned"
                      ? "Schedule Request"
                      : "Reschedule Request"}
                  </div>
                  <div
                    className=" closeModal fw-500 point"
                    onClick={() => this.ScheduleSideBar(false)}
                  >
                    x
                  </div>
                </div>
                <label
                  className="f14 fw-400 cl555555 mb-10"
                  htmlFor="scheduleOn"
                >
                  {requestData?.status === "assigned"
                    ? "Schedule Date"
                    : "Reschedule Date"}
                </label>

                <InputGroup className="mb-0  flex-content notice-date point">
                  <DatePicker
                    selected={this.state.scheduleOn}
                    minDate={new Date()}
                    maxDate={new Date().setDate(new Date().getDate() + 14)}
                    dateFormat="MM-dd-yyyy"
                    placeholderText=""
                    className="checkOutDatePick point mb-30"
                    onChange={this.handleScheduleOnChange}
                    name="scheduleOn"
                    id="scheduleOn"
                    value={this.state.scheduleOn}
                    style={{ fontWeight: "500" }}
                    showYearDropdown
                    showTimeSelect
                    showMonthDropdown
                    ariaDescribedBy="basic-addon2"
                  />
                  <InputGroupText id="basic-addon2" className=" back-unset1">
                    <label
                      className="mb-remove cursor-point"
                      htmlFor="scheduleOn"
                    >
                      <img
                        src={dateLight}
                        alt="Date-Picker Icon"
                        className="date-svg"
                      // onClick={() => this.openDatePicker()}
                      />
                    </label>
                  </InputGroupText>
                </InputGroup>
                <div className="flexEnd">
                  <Button
                    className="button-wrapping search-wrapper  button-secondary cancelbtn button_without_border no_shadow"
                    onClick={() => this.ScheduleSideBar(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="button-wrapping search-wrapper checkoutSubmit"
                    onClick={() =>
                      requestData?.status === "assigned"
                        ? this.changeRequestState("accept")
                        : this.scheduleRequest()
                    }
                  >
                    {scheduleLoad ? (
                      <div className="spin-loading-save w100 h100">
                        <Spinner type="border" color="#72569c" />
                      </div>
                    ) : requestData?.status === "assigned" ? (
                      "Accept"
                    ) : (
                      "Save"
                    )}
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Modal>

          <Modal isOpen={otpPopUp} toggle={() => this.otpModal(false)} centered>
            <Card>
              <CardBody className="pad25">
                <div className="mb-30 flex">
                  <div className=" cl262626 fw-600 f16">Mark As Inprogress</div>
                  <div
                    className="cl0A8080 closeModal fw-500 point"
                    onClick={() => this.otpModal(false)}
                  >
                    x
                  </div>
                </div>
                <div>
                  <p>
                    Please enter the OTP sent to the customer to start this work
                  </p>
                </div>
                <input
                  className="otpInput"
                  value={otp}
                  name="pin"
                  placeholder=""
                  type={otpType}
                  onChange={this.otpHandleChange}
                  maxLength={4}
                />
                <div className="flexEnd">
                  <Button
                    className="button-wrapping f14 button-secondary cancelbtn button_without_border no_shadow"
                    onClick={() => this.otpModal(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="button-wrapping search-wrapper checkoutSubmit"
                    onClick={() => this.otpSubmit("in_progress")}
                  >
                    Mark As Inprogress
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Modal>

          <Card
            className={subcontractorModal ? "exportSideBar" : "toggle-sidebar"}
          >
            <AssignSubcontractors
              open={subcontractorModal}
              close={this.closeAssignSubcontractor}
              toast={this.showToastMessage}
              requestId={request_id}
            />
          </Card>

          <Card className={commentOpenUp ? "exportSideBar" : "toggle-sidebar"}>
            {commentOpenUp && (
              <Chat
                close={this.toggleChatModal}
                props={this.props}
                data={this.state.requestData}
                status={requestData.status}
                requestId={request_id}
              ></Chat>
            )}
          </Card>

          <Modal
            isOpen={inviteModal}
            toggle={() => this.closeInviteModal()}
            centered
            className="inviteModal"
          >
            <InviteModal
              close={() => this.closeInviteModal()}
              linkData={inviteData}
              // link={this.state.link}
              update={() => this.closeInviteModal()}
            />
          </Modal>
        </Container>
      </>
    );
  }
}

export default RequestDetail;
