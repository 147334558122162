import ToastList from "components/Toast/ToastList";
import React, { Component } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupText,
  Row,
  Spinner,
} from "reactstrap";
import Home from "../../assets/img/brand/home.svg";
import propTick from "../../assets/img/brand/propTick.svg";
import loc from "../../assets/img/brand/profile_marker.svg";
import profile from "../../assets/img/brand/userImg.svg";
import call from "../../assets/img/brand/phoneLogo.svg";
import emailLogo from "../../assets/img/brand/emailLogo.svg";
import defaultprp from "../../assets/img/brand/default-amenity.svg";
import tick from "../../assets/img/brand/tickA.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dateLight from "../../assets/img/brand/datePicker.svg";
import { showError } from "constants/utils";
import samplePic from "../../assets/img/brand/samplePic.svg";
import ApiService from "constants/ApiService";
import { isInvalidEmail } from "constants/utils";
import TagInputs from "../Request/TagInput";
import { showSuccess } from "constants/utils";
import moment from "moment";
import { getUSFormat } from "constants/utils";
import { replaceToNormalNumber } from "constants/utils";
import { maxLengthCheck } from "constants/utils";
import { Custom_Button } from "components/Buttons/Button";
import { Button_without_Border } from "components/Buttons/Button";
import { CustomDropDownSearch } from "components/FilterFields/Filterfields";
import { CustomListingContainer } from "components/Container/CustomListingContainer";
import { ListingHeaderLayout } from "components/Container/CustomListingContainer";
import { ListingBodyLayout } from "components/Container/CustomListingContainer";
import { CustomSearch } from "components/FilterFields/Filterfields";
import { CustomInputFields } from "components/FilterFields/Filterfields";
import { CustomSingleDatePicker } from "components/FilterFields/Filterfields";

let request_id;

class AddRequest extends Component {
  constructor(props) {
    super(props);
    request_id = this.props.match.params.id;
    this.state = {
      isLoading: false,
      toast: "",
      response: "",
      toastType: "",
      step_1: true,
      step_2: false,
      step_3: false,
      propListInitial: [
        {
          property: "The holten wise",
          user: "James Anderson",
          email: "sdksadk@gmail.com",
          phone: "2131232131",
          selected: false,
          address: "132, My Street,Kingston, New York 12401, United States",
        },
        {
          property: "The Grand Valley Hotel",
          user: "James Anderson",
          email: "sdksadk@gmail.com",
          phone: "2131232131",
          selected: false,
          address: "132, My Street,Bigtown BG23 4YZ,England",
        },
        {
          property: "sky color",
          user: "James Anderson",
          email: "sdksadk@gmail.com",
          phone: "2131232131",
          selected: false,
          address: "8, My Street, Ilassan Lekki, Lagos 105102, Nigeria.",
        },
        {
          property: "Home Sweet Home",
          user: "James Anderson",
          email: "sdksadk@gmail.com",
          phone: "2131232131",
          selected: false,
          address: "132, My Street,Bigtown BG23 4YZ,England",
        },
      ],
      propList: [
        {
          property: "The holten wise",
          user: "James Anderson",
          email: "sdksadk@gmail.com",
          phone: "2131232131",
          selected: false,
          address: "132, My Street,Kingston, New York 12401, United States",
        },
        {
          property: "The Grand Valley Hotel",
          user: "James Anderson",
          email: "sdksadk@gmail.com",
          phone: "2131232131",
          selected: false,
          address: "132, My Street,Bigtown BG23 4YZ,England",
        },
        {
          property: "sky color",
          user: "James Anderson",
          email: "sdksadk@gmail.com",
          phone: "2131232131",
          selected: false,
          address: "8, My Street, Ilassan Lekki, Lagos 105102, Nigeria.",
        },
        {
          property: "Home Sweet Home",
          user: "James Anderson",
          email: "sdksadk@gmail.com",
          phone: "2131232131",
          selected: false,
          address: "132, My Street,Bigtown BG23 4YZ,England",
        },
      ],
      address_line: "",
      issue: [],
      scheduleDate: "",
      cost: "",
      searchFilter: "",
      issueDescription: "",
      selectedProp: [],
      propertyName: "",
      propAddress: "",
      customerName: "",
      email: "",
      phone: "",
      btnLoad: false,
      isShowPlaces: false,
      placeList: [],
      location: [],
      error: false,
      place_id: "",
      dateError: false,
      tagError: false,
    };
  }

  componentDidMount() {
    if (request_id) this.getRequetDetails();
  }

  getRequetDetails = async () => {
    this.setState({ isLoading: true });
    let response = await ApiService.getAPI(
      `contractor/open_request/${request_id}`
    );
    if (response.success) {
      let requestData = response.data.open_request;
      this.setState(
        {
          address_line: requestData?.property?.name ?? "",
          issue: requestData?.issues ?? [],
          email: requestData?.email ?? "",
          phone: getUSFormat(requestData?.phone?.national_number) ?? "",
          issueDescription: requestData?.desc ?? "",
          scheduleDate: new Date(requestData?.schedule_date) ?? "",
          cost: requestData?.approximate_amount ?? "",
          location: requestData?.property?.loc ?? [],
        },
        () => {
          this.setState({ isLoading: false });
        }
      );
    } else {
      showError(this, response.message);
      this.setState({ isLoading: false });
    }
  };

  handlePropChange = async (e) => {
    let searchTerm = e;
    this.setState({ address_line: searchTerm });
    let response = await ApiService.getAPI(`common/search?input=${searchTerm}`);
    if (response.success) {
      this.setState({
        isShowPlaces: true,
        placeList: response.data.place.predictions,
      });
    }
    // let arr = [];
    // propListInitial.filter((prop) =>
    //   prop.address.toLocaleLowerCase().match(e.target.value.toLocaleLowerCase())
    //     ? arr.push(prop)
    //     : []
    // );
    // this.setState({ propList: arr }, () => {
    //   this.selectProperty();
    // });
  };

  getPlaceData = async (place) => {
    this.setState({ btnLoad: true, place_id: place.place_id });
    let response = await ApiService.getAPI(
      `contractor/maps/place/${place.place_id}`
    );
    this.setState({ btnLoad: false });
    if (response.success) {
      let locData = response.data;
      this.setState({
        address_line: place.description,
        isShowPlaces: false,
        location: locData.loc,
      });
    } else {
      this.setState({
        isShowPlaces: false,
      });
    }
  };
  selectProperty = (property) => {
    let { propList } = this.state;
    let arr = [...propList];
    arr.map((prop) => {
      prop?.property === property?.property
        ? (prop.selected = true)
        : (prop.selected = false);
    });
    this.setState({
      propList: arr,
      selectedProp: property,
    });
  };

  issuesTags = (tags) => {
    this.setState({ issue: tags ,  }, () => {
    });
  };

  checkError = () => {};

  goToNext = async () => {
    this.setState({ error: false, dateError: false, tagError: false });
    let {
      step_1,
      step_2,
      step_3,
      selectedProp,
      issue,
      issueDescription,
      cost,
      scheduleDate,
      address_line,
      email,
      phone,
      location,
      place_id,
      dateError,
      tagError,
    } = this.state;
    if (step_1) {
      if (!place_id && (!request_id || !address_line)) {
        showError(this, "Please enter a valid address");
        this.setState({ error: true });
      } else {
        this.setState({ step_1: false, step_2: true, error: false });
      }
    } else if (step_2) {
      if (
        email === undefined ||
        email === null ||
        email === "" ||
        isInvalidEmail(email)
      ) {
        showError(this, "Please enter a valid email");
        this.setState({
          error: true,
          dateError: !this.state.scheduleDate ? true : false,
          tagError: this.state.issue.length === 0 ? true : false,
        });
      } else if (
        phone === undefined ||
        phone === null ||
        phone === "" ||
        phone.length !== 16
      ) {
        showError(this, "Please enter a valid phone number");
        this.setState({
          error: true,
          dateError: !this.state.scheduleDate ? true : false,
          tagError: this.state.issue.length === 0 ? true : false,
        });
      } else if (
        scheduleDate === undefined ||
        scheduleDate === null ||
        scheduleDate === ""
      ) {
        showError(this, "Please select schedule date");
        this.setState({
          error: true,
          dateError: !this.state.scheduleDate ? true : false,
          tagError: this.state.issue.length === 0 ? true : false,
        });
      } else if (cost === null || cost === undefined || cost === "") {
        showError(this, "Please enter a cost");
        this.setState({
          error: true,
          dateError: !this.state.scheduleDate ? true : false,
          tagError: this.state.issue.length === 0 ? true : false,
        });
      } else if (issue === undefined || issue === null || issue.length <= 0) {
        showError(this, "Please enter a valid issue");
        this.setState({
          error: true,
          dateError: !this.state.scheduleDate ? true : false,
          tagError: this.state.issue.length === 0 ? true : false,
        });
      } else {
        this.setState({
          step_2: false,
          step_3: true,
          error: false,
          dateError: false,
          tagError: false,
        });
      }
    } else {
      this.setState({
        btnLoad: true,
        error: false,
        dateError: false,
        tagError: false,
      });
      let data = {
        type: "0",
        email: email,
        approximate_amount: cost,
        desc: issueDescription,
        phone: {
          country_code: "91",
          national_number: replaceToNormalNumber(phone),
        },
        schedule_date: moment(scheduleDate),
        issues: issue,
        property: {
          name: address_line,
          loc: location,
        },
      };

      let response;
      if (request_id) {
        response = await ApiService.patchAPI(
          `contractor/open_request/edit/${request_id}`,
          data
        );
      } else {
        response = await ApiService.postAPI(`contractor/open_request`, data);
      }
      if (response.success) {
        this.setState({ btnLoad: false });
        showSuccess(this, response.message);
        setTimeout(() => this.props.history.push(`/admin/open_request`), 3000);
      } else {
        this.setState({ btnLoad: false });
        showError(this, response.message);
      }
    }
  };

  goToPrevious = () => {
    let { step_1, step_2, step_3 } = this.state;
    if (step_1) {
    } else if (step_2) {
      this.setState({ step_2: false, step_1: true });
    } else if (step_3) {
      this.setState({ step_3: false, step_2: true });
    }
  };

  onDropDownSearch = (value) => {
    value.current.classList.add("onActive");
  };

  onFieldChange = (e, from) => {
    this.setState({ [from]: e });
    if(this.state.error) {
      if(!this.state.issue.length) {
        this.setState({tagError:true},() => {
        })
      }
    }
    // if (this.state.error) {
    //   if (!this.state.scheduleDate) {
    //     this.setState({ dateError: true });
    //   } else if (this.state.issue.length === 0) {
    //     this.setState({ tagError: true });
    //   }
    // }
    if (e) {
      this.setState({ error: false });
      if (from === "phone") {
        this.setState({
          [from]: getUSFormat(e),
        });
      }
    } else {
      this.state.error && this.setState({ error: true });
    }
  };

  customSingleDateHandler = (e, from) => {
    this.setState({ error: false, scheduleDate: e });
  };

  handlechange = (e) => {
    this.setState({ scheduleDate: e, dateError: false });
  };

  render() {
    let {
      isLoading,
      toast,
      response,
      toastType,
      step_1,
      step_2,
      step_3,
      address_line,
      propList,
      issue,
      issueDescription,
      cost,
      scheduleDate,
      propertyName,
      email,
      phone,
      propAddress,
      customerName,
      selectedProp,
      btnLoad,
      isShowPlaces,
      location,
      error,
      placeList,
      tagError,
    } = this.state;

    return isLoading ? (
      <div className="spin-loading">
        <Spinner type="border" color="#077e8b" />
      </div>
    ) : (
      <>
        <div
          style={{
            height: "100vh",
            padding: "20px",
            backgroundColor: "#fafafa",
          }}
        >
          {toast && <ToastList message={response} type={toastType} />}
          <div>
            <Breadcrumb></Breadcrumb>
          </div>
          <div style={{ display: "flex" }} className="align-items-center">
            <Col xs="8" className="manage-con">
              <Breadcrumb>
                <BreadcrumbItem
                  onClick={() => this.props.history.goBack()}
                  className="bread-cursor"
                >
                  Requests
                </BreadcrumbItem>
                <BreadcrumbItem active style={{ color: "#72569c" }}>Add Request</BreadcrumbItem>
              </Breadcrumb>
            </Col>
            <Col xs="4" className="manage-con">
              <div className="flexEnd">
                {!step_1 && (
                  <>
                    <Button_without_Border
                      style={{ marginRight: "10px" }}
                      btnLabel="previous"
                      click={this.goToPrevious}
                    />
                  </>
                )}
                <Custom_Button
                  isPrimary
                  btnLabel={step_3 ? "create" : "next"}
                  isLoading={btnLoad}
                  click={this.goToNext}
                />
              </div>
            </Col>
          </div>

          <div className="progress-all mb-70">
            <div className="progress-div">
              <div
                className={
                  step_1
                    ? "progress-back-toggle"
                    : step_2
                    ? "progress-back-toggle w_3_rooms"
                    : step_3
                    ? "progress-back-toggle w_6_rooms"
                    : "progress-back-toggle"
                }
              >
                {step_1 ? (
                  <div className="img-black image_one_rooms">
                    <img src={Home} className="img-abs" />
                  </div>
                ) : (!step_1 && step_2) || (!step_1 && !step_2 && step_3) ? (
                  <div className="steps-1-rooms back">
                    <img src={propTick} className="img-abs-prop" />
                  </div>
                ) : (
                  <div className="steps-1-rooms"></div>
                )}
                {step_2 ? (
                  <div className="img-black image_two_rooms">
                    <img src={Home} className="img-abs" />
                  </div>
                ) : !step_1 && !step_2 && step_3 ? (
                  <div className="steps-2-rooms back">
                    <img src={propTick} className="img-abs-prop" />
                  </div>
                ) : (
                  <div className="steps-2-rooms"></div>
                )}
                {step_3 ? (
                  <div className="img-black image_three_rooms">
                    <img src={Home} className="img-abs" />
                  </div>
                ) : (
                  <div className="steps-3-rooms"></div>
                )}
              </div>
            </div>
          </div>
          <CustomListingContainer>
            {step_1 && (
              <>
                <ListingHeaderLayout
                  label={
                    "Step 1 : Search & Select the property you would like to service ?"
                  }
                />
                <div className="addRequest_1" style={{ padding: "20px" }}>
                  <ListingBodyLayout>
                    <CustomDropDownSearch
                      toSearch="description"
                      filteredData={placeList}
                      rightCornerImage={
                        <img src={loc} alt="locImage" class="input-icon" />
                      }
                      onChange={this.handlePropChange}
                      click={this.onDropDownSearch}
                      listClick={this.getPlaceData}
                      error={error}
                      value={address_line}
                      placeHolder="Enter the address"
                      style={{width:'100%'}}
                    />
                  </ListingBodyLayout>
                </div>
              </>
            )}
            {step_2 && (
              <div>
                <ListingHeaderLayout
                  label={"Step 2 : Details about the Issue"}
                />
                <ListingBodyLayout>
                  <div
                    style={{ padding: "20px" }}
                    className="issueConfirmDetails mb-50"
                  >
                    <div className="issuedetail mb-30">
                      <div>
                        <label className="customLabel">Email *</label>
                        <CustomInputFields
                          inputValue={email}
                          type="text"
                          change={this.onFieldChange}
                          error={error}
                          from="email"
                        />
                      </div>
                      <div>
                        <label className="customLabel">Phone *</label>
                        <CustomInputFields
                          inputValue={phone}
                          type="text"
                          change={this.onFieldChange}
                          error={error}
                          from="phone"
                        />
                      </div>

                      <div>
                        <label className="customLabel">Schedule On *</label>
                        <CustomSingleDatePicker
                          value={scheduleDate}
                          change={this.handlechange}
                          // error={error && !scheduleDate ? true : false}
                          error={this.state.dateError}
                        />
                      </div>
                      <div>
                        <label className="customLabel">
                          Approximate Cost *
                        </label>
                        <CustomInputFields
                          inputValue={cost}
                          type="text"
                          change={this.onFieldChange}
                          error={error}
                          from="cost"
                        />
                      </div>
                      <div>
                        <label className="customLabel">
                          Issue
                          <span
                            style={{
                              fontSize: "10px",
                              fontStyle: "italic",
                              color: "rgb(119 116 116)",
                            }}
                          >
                            {" "}
                            (Type issue and press enter)
                          </span>{" "}
                          *
                        </label>
                        <TagInputs
                          error={tagError}
                          issues={this.issuesTags}
                          issueList={issue}
                        />
                      </div>
                    </div>
                    <div>
                      <label className="customLabel">Issue Description</label>
                      <Input
                        placeholder="Enter description"
                        type="textarea"
                        value={issueDescription}
                        onChange={(e) =>
                          this.setState({ issueDescription: e.target.value })
                        }
                        className="issue-description"
                        maxLength={500}
                      />
                    </div>
                  </div>
                </ListingBodyLayout>
              </div>
            )}
            {step_3 && (
              <>
                <div>
                  <ListingHeaderLayout
                    label={"Step 3 :Verify and confirm the details"}
                  />
                  <ListingBodyLayout>
                    <div
                      style={{ padding: "20px" }}
                      className="issueConfirmDetails mb-50"
                    >
                      <div className="issuedetail mb-30">
                        <div>
                          <label className="customLabel">Email *</label>
                          <CustomInputFields
                            inputValue={email}
                            type="text"
                            change={this.onFieldChange}
                            error={error}
                            from="email"
                            disabled
                          />
                        </div>
                        <div>
                          <label className="customLabel">Phone *</label>
                          <CustomInputFields
                            inputValue={phone}
                            type="text"
                            change={this.onFieldChange}
                            error={error}
                            from="phone"
                            disabled
                          />
                        </div>
                        <div>
                          <label className="customLabel">Schedule On *</label>
                          <CustomSingleDatePicker
                            value={scheduleDate}
                            change={this.handlechange}
                            // error={error && !scheduleDate ? true : false}
                            error={this.state.dateError}
                            disabled
                          />
                        </div>
                        <div>
                          <label className="customLabel">
                            Approximate Cost *
                          </label>
                          <CustomInputFields
                            inputValue={cost}
                            type="text"
                            change={this.onFieldChange}
                            error={error}
                            from="cost"
                            disabled
                          />
                        </div>
                        <div>
                          <label className="customLabel">
                            Issue
                            <span
                              style={{
                                fontSize: "10px",
                                fontStyle: "italic",
                                color: "rgb(119 116 116)",
                              }}
                            >
                              {" "}
                              (Type issue and press enter)
                            </span>{" "}
                            *
                          </label>
                          <TagInputs
                            error={tagError}
                            issues={this.issuesTags}
                            issueList={issue}
                            disabled
                          />
                        </div>
                      </div>
                      <div>
                        <label className="customLabel">Issue Description</label>
                        <Input
                          placeholder="Enter description"
                          type="textarea"
                          value={issueDescription}
                          onChange={(e) =>
                            this.setState({ issueDescription: e.target.value })
                          }
                          className="issue-description"
                          maxLength={500}
                          disabled
                        />
                      </div>
                    </div>
                  </ListingBodyLayout>
                </div>
              </>
            )}{" "}
          </CustomListingContainer>
        </div>
      </>
    );
  }
}

export default AddRequest;
