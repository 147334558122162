import React, { Component } from "react";
import {
  Card,
  Table,
  Container,
  Row,
  Breadcrumb,
  Col,
  Button,
  Spinner,
  DropdownMenu,
  DropdownItem,
  Media,
  UncontrolledDropdown,
  DropdownToggle,
  Input,
  Dropdown,
  Modal,
  InputGroup,
} from "reactstrap";
import ConvertCancelModal from "./convertCancelModal";
import ApiService from "constants/ApiService";
import "react-datepicker/dist/react-datepicker.css";
import projectTimeline from "../../../src/assets/img/brand/projectTimeline.svg";
import TimeLine from "components/Timeline/Timeline";

import { getIsoString, filterQuery } from "constants/utils";

import ToastList from "components/Toast/ToastList";

import { showError } from "constants/utils";
import { returnSearchObject } from "constants/utils";
import { pushToFilteredPage } from "constants/utils";
import { buildQueryString } from "constants/utils";
import moment from "moment";

import showMore from "../../assets/img/brand/showMore.svg";
import { showSuccess } from "constants/utils";
import { BreadcrumbItem } from "reactstrap";

import { uploadCloudinary } from "constants/utils";
import searchIcon from "../../assets/img/brand/searchIcon.svg";
// import DeleteModal from "./DeleteModal";
import AddLead from "./addLead";

let overlap = document.querySelector(":root");

class Leads extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requestList: [],
      project_list: [],
      total: 0,
      filterToggle: false,
      from: "",
      to: "",
      page: 1,
      open: false,
      client_id: "",
      inputSearch: "",
      filterOption: "",
      status: this.props.location?.search ? "pending" : "",
      toast: false,
      response: "",
      image: [],
      iamge_name: "",
      toastType: "",
      isLoading: true,
      clientsList: [],
      btnLoad: false,
      query: {},
      name: "",
      client: {},
      start_date: "",
      actual_start: "",
      imageLoading: false,
      client_name: "",
      inputText: "",
      items: [],
      desc: "",
      notes: "",
      notesbtn: false,
      tags: [],
      end_date: "",
      requestId: "",
      openModal: false,
      delete_data: {},
      cancelModal: false,
      project_id: "",
      lead_id: "",
      all_projects: 0,
      active_projects: 0,
      completed_projects: 0,
      all_tasks: 0,
      isTimeLineOpen: false,
      leadList: [],
      type: "",
      notesAddToggle: false,
    };
  }
  componentDidMount = async () => {
    overlap.style.setProperty("--width", "600px");
    this.setQueryStatus();
  };

  componentWillUnmount() {
    overlap.style.setProperty("--width", "450px");
  }

  closeModal = () => {
    // this.setState({ openModal: false, project_id: "" });
    // this.getProjectList();
  };
  handleDelete = async () => {
    let response = await ApiService.patchAPI(
      `contractor/project/delete/${this.state.delete_data.project_id}`,
      {}
    );
    if (response.success) {
      showSuccess(this, response.message);
      this.setState({ delete_data: {} });
      this.closeModal();
      //   this.getProjectList();
    } else {
      showError(this, response.message);
    }
  };

  setQueryStatus = () => {
    let queryStatus = this.props.location?.search;
    let queryObj = returnSearchObject(queryStatus);
    if (Object.keys(queryObj).includes("status")) {
      this.setState(
        {
          statusSearch: queryObj.status ?? "",
          page: parseInt(queryObj.page) ?? "",
          query: returnSearchObject(queryStatus),
        },
        () => {
          this.state.statusSearch === "all"
            ? this.setState({ input_value: false })
            : this.setState({ input_value: true });
          pushToFilteredPage(this.props, { ...this.state.query });
          this.getLeadList();
        }
      );
    } else {
      this.setState(
        {
          query: {
            ...queryObj,
            page: 1,
            limit: 10,
          },
        },
        () => {
          pushToFilteredPage(this.props, { ...this.state.query });
          this.getLeadList();
        }
      );
    }
  };



  getLeadList = async () => {
    let { filterOption, status, inputSearch, from, to, query } = this.state;

    const queryObj = { ...query }
    if (filterOption && inputSearch) queryObj[filterOption] = inputSearch

    let response = await ApiService.getAPI(
      `contractor/lead${buildQueryString(queryObj)}`
    );
    this.setState({ isLoading: false });
    if (response.success) {
      let { leads } = response.data;
      this.setState({ leadList: leads });
    }

  };

  redirectDetailPage = (id) => {
    this.props.history.push(`projects/detail/${id}`);
  };

  setPage = (page) => {
    this.props.history.push(page);
  };
  dateChangeHandler = (e, value) => {
    this.setState({ [value]: e });
  };

  editProject = async (data) => {
    this.setState(
      {
        lead_id: data,
      },
      () => {
        this.setState({ open: true });
      }
    );
  };

  getRequestsList = async () => {
    let { filterOption, status, inputSearch, from, to } = this.state;
    let queryParams = "";
    if (filterOption !== "" && inputSearch !== "")
      queryParams += filterQuery(filterOption, inputSearch);
    if (status !== "") {
      queryParams += filterQuery("status", status);
    }
    else {
      let response = await ApiService.getAPI(
        `contractor/open_request${buildQueryString(this.state.query)}`
      );

      if (response.success) {
        let { open_request, total } = response.data;
        this.setState({
          requestList: open_request,
          total: total,
          isLoading: false,
        });
      }
    }
  };

  getProjectList = async () => {
    let { filterOption, status, inputSearch, from, to } = this.state;
    let queryParams = "";
    if (filterOption !== "" && inputSearch !== "")
      queryParams += filterQuery(filterOption, inputSearch);
    if (status !== "") {
      queryParams += filterQuery("status", status);
    }

    if (from !== "") queryParams += filterQuery("from", getIsoString(from));
    if (to !== "") queryParams += filterQuery("to", getIsoString(to));
    if (from > to) {
      showError(this, "Please provide valid From and To date");
    } else {
      let response = await ApiService.getAPI(
        `contractor/project/${buildQueryString(this.state.query)}`
      );

      if (response.success) {
        this.setState({
          isLoading: false,
          project_list: response.data.projects,
        });
      }
    }
  };

  leadConversionUpdate = (value, data) => {
    this.setState({ openModal: true, type: value, lead_id: data });
  };

  createProjectHandler = async () => {
    let {
      name,
      client_name,
      start_date,
      end_date,
      actual_start,
      client_id,
      desc,
      project_id,
      edit,
      tags,
    } = this.state;
    if (!name || !client_name || !start_date || !end_date || !actual_start)
      return showError(this, "Please fill all the required fields");
    this.setState({ btnLoad: true });
    let data = {
      name: name,
      client: {
        id: client_id,
        name: client_name,
      },
      start_date: start_date,
      end_date: end_date,
      actual_start: actual_start,
      desc: desc,
      tags: tags,
    };
    let response = edit
      ? await ApiService.patchAPI(`contractor/project/${project_id}`, data)
      : await ApiService.postAPI("contractor/project", data);
    if (response.success) {
      showSuccess(this, response.message);
      this.setState({
        btnLoad: false,
        open: false,
        name: "",
        start_date: "",
        end_date: "",
        actual_start: "",
        desc: "",
        tags: [],
        client_name: "",
        client_id: "",
      });
      //   this.getProjectList();
    } else {
      this.setState({ btnLoad: false });
      showError(this, response.message);
    }
  };

  handleTagsChange = (tags) => {
    this.setState({ tags: tags });
  };
  handleInputChange = (event) => {
    this.setState({ inputText: event.target.value });
  };

  handleEnterKey = (event) => {
    if (event.key === "Enter" && this.state.inputText.trim() !== "") {
      this.setState((prevState) => ({
        inputText: "",
        items: [...prevState.items, prevState.inputText],
      }));
    }
  };
  toggleFilterMenu = () => {
    this.setState({ filterToggle: !this.state.filterToggle });
  };
  onChangeHandler = (e) => {

    this.setState({ [e.target.name]: e.target.value });
  };
  setFilterSearch = (e) => {

    this.setState({ filterOption: "name" })
  }
  updateStateValues = (key, value) => {
    this.setState(
      {
        [key]: value,
      },
      function () {
        if (value === null) {
          this.setState({
            [key]: "",
          });
        }
      }
    );
  };
  handleOnToChange = (e) => {
    this.updateStateValues("to", e ? new Date(e) : '');
  };
  handleOnFromChange = (e) => {
    this.updateStateValues("from", e ? new Date(e) : '');
  };
  handleIncrementPage = () => {
    if (this.state.leadList.length >= 10) {
      this.setState(
        {
          page: this.state.page + 1,
          query: {
            ...this.state.query,
            page: this.state.page + 1,
            limit: 10,
          },
        },
        () => this.getLeadList()
      );
    }
  };
  handleDecrementPage = () => {
    if (this.state.page > 1) {
      this.setState(
        {
          page: this.state.page - 1,
          query: {
            ...this.state.query,
            page: this.state.page - 1,
            limit: 10,
          },
        },
        () =>
          // this.handleFilterStatus()
          this.getLeadList()
      );
    }
  };
  capitalizeFirstLetter = (str) => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  imageHandler = async (e, subStatus) => {
    let { image } = this.state;
    let file = e.target.files[0];

    if (e.target.files.length > 0) {
      this.setState({ imageLoading: true });
    }

    if (
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/jpeg" ||
      file.type === "application/pdf"
    ) {
      if (file.size < 1000000) {
        let data = await uploadCloudinary(file, "host");
        if (data.url) {
          let imgArr = [...image];
          let imageObj = {
            format: data.format,
            public_id: data.public_id,
            version: `v${data.version}`,
            type: "image",
          };
          imgArr.push(imageObj);
          this.setState({
            image: imgArr,
            imageLoading: false,
          });
        } else {
          showError(this, "Could not upload image");
          this.setState({ imageLoading: false });
        }
      } else {
        showError(this, "File size should not exceeds 1 MB");
        this.setState({ imageLoading: false });
      }
    } else {
      showError(this, "Invalid Format");
      this.setState({ imageLoading: false });
    }
  };

  deleteImage = () => {
    this.setState({ image: [], image_name: "" });
  };

  closeTimeline = () => {
    this.setState({ isTimeLineOpen: false });
  };

  handleFilterStatus = () => {
    let staticQuery = returnSearchObject(this.props.location.search);
    staticQuery.status = this.state.status ?? "";
    staticQuery.page = this.state.page ?? "";
    if (this.state.inputSearch !== "" && this.state.filterOption !== "") {
      staticQuery[this.state.filterOption] = this.state.inputSearch;
    } else staticQuery[this.state.filterOption] = "";
    this.setState({ query: staticQuery }, () => {
      // pushToFilteredPage(this.props, { ...this.state.query });
      this.getLeadList();
    });
  };

  handleSearch = () => {
    if (this.state.filterOption) {
      this.setState({ query: { ...this.state.query, page: 1 } }, () => this.getLeadList())
    }
  };

  handleReset = () => {
    let self = this;
    self.setState(
      {
        from: "",
        to: "",
        status: "",
        filterOption: "",
        inputSearch: "",
        page: 1,
        limit: 10,
        query: {
          page: 1,
          limit: 10,
        },
      },
      () => {
        // pushToFilteredPage(this.props, { ...this.state.query });
        this.getLeadList();
      }
    );
  };

  setStatus = (status) => {
    this.setState(
      {
        status: status,
        query: {
          ...this.state.query,
          page: 1,
          limit: 10,
          status: status,
        },
      },
      () => {
        this.getLeadList();
        // this.handleFilterStatus();
      }
    );
  };

  redirectToAddRequest = async () => {
    let response = await ApiService.getAPI(`contractor/client?status=active`);

    if (response.success) {
      let { clients } = response.data;
      this.setState({
        clientsList: clients,
        edit: false,
        open: true,
      });
    }
  };

  toggleModal = () => {
    this.setState({ openModal: false });
    this.getLeadList();
  };

  editRequest = (data) => {
    this.props.history.push(`/admin/request/update/${data?.open_request_id}`);
  };

  lead_accum = (data) => {
    let lists = this.state.leadList;
    lists.push(data);
    this.setState({ leadList: lists });
  };
  Showtoast = (type, message) => {
    this.setState({
      toast: true,
      toastType: type,
      response: message,
    });
    this.clearToast();
  }
  clearToast = () => {
    setTimeout(
      () => this.setState({ toast: false, toastType: "", response: "" }),
      2000
    );
  }
  onClose = () => {
    this.setState({ open: false, project_id: "" });
    this.getLeadList();
  };

  cancelRequest = async (request) => {
    this.setState({ cancelModal: true, requestId: request.open_request_id });
  };

  resendRequest = async (request) => {
    let response = await ApiService.patchAPI(
      `contractor/open_request/resend/${request.open_request_id}`
    );
    if (response.success) {
      showSuccess(this, response.message);
      //   this.getProjectList();
    } else {
      showError(this, response.message);
    }
  };
  resendInvoice = async (request) => {
    let response = await ApiService.patchAPI(
      `contractor/open_request/invoice/${request.open_request_id}`
    );
    if (response.success) {
      showSuccess(this, response.message);
      //   this.getProjectList();
    } else {
      showError(this, response.message);
    }
  };

  addNotes = async () => {
    this.setState({ notesbtn: true });
    if (!this.state.notes) {
      this.setState({ notesbtn: false });
      return showError(this, "Please add notes");
    } else {
      let response = await ApiService.patchAPI(
        `contractor/lead/notes/${this.state.lead_id}`,
        { notes: this.state.notes }
      );
      this.setState({ notesbtn: false });
      if (response.success) {
        showSuccess(this, response.message);
        this.setState({ notesAddToggle: false });
      } else {
        return showError(this, response.message);
      }
    }
  };

  render() {
    let {
      requestList,
      isLoading,
      project_id,
      lead_id,
      page,
      status,
      toast,
      response,
      toastType,
      notes,
      clientsList,
      type,
      project_list,
      leadList,
      name,
      imageLoading,
      start_date,
      actual_start,
      desc,
      end_date,
      tags,
      client_name,
      notesAddToggle,
      btnLoad,
      inputSearch,
      filterOption,
      image,
      openModal,
      delete_data,
      open,
      edit,
      all_projects,
      active_projects,
      completed_projects,
      all_tasks,
      notesbtn,
    } = this.state;
    return isLoading ? (
      <div className="spin-loading">
        <Spinner type="border" color="#077e8b" />
      </div>
    ) : (
      <>
        <Container className="card-pad-wrapper" fluid>
          {toast && <ToastList message={response} type={toastType} />}

          <InputGroup className="mb-5 mb_mt_28 flex-content align-left">
            <div className="filters_container">
              <div className="left_filter_container">
                {" "}
                <div className="select_with_input mr-10">
                  <Dropdown variant="input-group" className="select_input_drop">
                    <select
                      id="inputGroupSelect01"
                      className="form_select fixed_select"
                      name="filterOption"
                      value={filterOption}
                      onChange={this.onChangeHandler}
                    // onClick={this.setFilterSearch}
                    >
                      <option value="">Search</option>
                      {/* <option value="username">Customer</option> */}
                      <option value="name">Name</option>
                      <option value="email">Email</option>
                      {/* <option value="property_id">Property Id</option> */}
                    </select>
                    <i className="fas fa-angle-down"></i>
                  </Dropdown>
                  <Input
                    aria-label="Text input with dropdown button"
                    className="input-filter-toggle"
                    value={inputSearch}
                    name="inputSearch"
                    onChange={this.onChangeHandler}
                  />
                </div>
                <div className="status_select ml-10">
                  <UncontrolledDropdown className="status_select_div">
                    <DropdownToggle className="pr-0 drop_down_container">
                      <Media className="capitalize">
                        {status === "" ? "All" : status}

                        <i className="fas fa-angle-down"></i>
                      </Media>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-arrow" bottom>
                      {" "}
                      <DropdownItem
                        className="dropdown_item"
                        onClick={() => this.setStatus("")}
                      >
                        All
                      </DropdownItem>{" "}
                      <DropdownItem
                        className="dropdown_item"
                        onClick={() => this.setStatus("created")}
                      >
                        created
                      </DropdownItem>
                      <DropdownItem
                        className="dropdown_item"
                        onClick={() => this.setStatus("converted")}
                      >
                        Converted
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              </div>
              <div className="right_filter_container">
                <Button
                  color="primary"
                  onClick={this.handleReset}
                  size="sm"
                  className="button-wrapping search-wrapper content-flex mr-4 button-secondary ml-4 reset-btn"
                >
                  Reset
                </Button>
                <Button
                  color="primary"
                  onClick={() => this.handleSearch()}
                  size="sm"
                  className="button-wrapping search-wrapper ml-0"
                >
                  Search
                </Button>
              </div>
            </div>
          </InputGroup>

          <Row>
            <div className="col new-table">
              <Card className="shadow card_with_paginator">
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light" style={{ borderBottom: "1px solid #f5f5f5" }}>
                    <tr>
                      {/* <th scope="col" className="pad20">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "3%",
                          }}
                        >
                          <img src={searchIcon} alt="searchIcon" />
                          <input
                            style={{
                              border: "none",
                              backgroundColor: "#f5f5f5",
                            }}
                            placeholder="Search By Lead"
                          />
                        </div>
                      </th> */}
                      <th scope="col" className="pad20 w_16">
                        Name
                      </th>
                      <th scope="col w_16">Email</th>
                      <th scope="col w_16">Phone</th>
                      <th scope="col w_16">Status</th>
                      <th scope="col w_8"></th>
                      <th scope="col" style={{ width: "0px" }}></th>
                    </tr>
                  </thead>
                  {leadList && leadList.length !== 0 ? (
                    <tbody>
                      {leadList.map((list, i) => (
                        <tr key={i}>
                          <td
                            className="capitalize pad20"
                          // onClick={() =>
                          //   this.redirectDetailPage(list.list_id)
                          // }
                          >
                            <div className="project_list_content">
                              {this.capitalizeFirstLetter(list.name)}
                            </div>
                          </td>
                          <td>
                            <div className="project_list_content">
                              {`${list.email}`}
                            </div>
                          </td>

                          <td>
                            <div className="project_list_content">
                              {" "}
                              {list.phone}
                            </div>
                          </td>

                          <td>
                            <div
                              className="project_list_status"
                              style={{
                                color: `${list.status === "converted"
                                    ? "#07A84E"
                                    : "#FFCC3D"
                                  }`, fontWeight: "500"
                              }}
                            >
                              {this.capitalizeFirstLetter(list.status)}
                            </div>
                          </td>
                          <td>
                            {list.status === "converted" ? (
                              <div
                                style={{
                                  border: "1px solid #c4c4c4",
                                  textAlign: "center",
                                  padding: "10px",
                                  borderRadius: "3px",
                                  color: "#338180",
                                  fontWeight: "500",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  this.props.props.props.history.push(
                                    `add_estimates?lead_id=${list.lead_id}`
                                  );
                                }}
                              >
                                Send Estimate
                              </div>
                            ) : (
                              <div></div>
                            )}
                          </td>

                          <td>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                gap: "20px",
                              }}
                            >
                              <div>
                                <img
                                  onClick={() =>
                                    this.setState({
                                      isTimeLineOpen: true,
                                      lead_id: list.lead_id,
                                    })
                                  }
                                  style={{ cursor: "pointer" }}
                                  src={projectTimeline}
                                ></img>
                              </div>
                              <UncontrolledDropdown
                                nav
                                className="flexy-content position_u"
                              >
                                <DropdownToggle className="pr-0 nav-notice" nav>
                                  <Media className="align-items-center">
                                    <img src={showMore} alt="show more" />
                                  </Media>
                                </DropdownToggle>
                                <DropdownMenu
                                  className="dropdown-menu-arrow min-width-drop"
                                  right
                                >
                                  <DropdownItem
                                    onClick={() => this.editProject(list)}
                                  >
                                    <span>Edit</span>
                                  </DropdownItem>
                                  {list.status === "converted" ? (
                                    <DropdownItem
                                      onClick={() =>
                                        this.leadConversionUpdate(
                                          "cancel",
                                          list
                                        )
                                      }
                                    >
                                      <span>Cancel</span>
                                    </DropdownItem>
                                  ) : (
                                    <DropdownItem
                                      onClick={() =>
                                        this.leadConversionUpdate(
                                          "convert",
                                          list
                                        )
                                      }
                                    >
                                      <span>Convert</span>
                                    </DropdownItem>
                                  )}

                                  <DropdownItem
                                    onClick={() =>
                                      this.setState({
                                        notesAddToggle: true,
                                        lead_id: list.lead_id,
                                      })
                                    }
                                  >
                                    <span>Add Notes</span>
                                  </DropdownItem>

                                  {/* <DropdownItem
                                  onClick={() => this.editProject(list)}
                                >
                                  <span>Edit</span>
                                </DropdownItem> */}
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td className="no-data">No Leads Available</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        {/* <td></td> */}
                        {/* <td></td> */}
                        {/* <td></td> */}
                      </tr>
                    </tbody>
                  )}
                </Table>
              </Card>
            </div>
          </Row>
          <Row className="align-items-center header-div jus-space new-margin-page paginator_box">
            <div className="paginate-flex-box ">
              <div
                className="paginate-box"
                onClick={() => this.handleDecrementPage()}
              >
                <p
                  className={page !== 1 ? "page_available" : "page_unavailable"}
                >
                  Previous
                </p>
              </div>
              <div
                className="paginate-box"
                onClick={() => this.handleIncrementPage()}
              >
                <p
                  className={
                    this.state.leadList.length < 10
                      ? "page_unavailable"
                      : "page_available"
                  }
                >
                  Next
                </p>
              </div>
            </div>
          </Row>

          <Card
            className={this.state.open ? "exportSideBar" : "toggle-sidebar"}
          >
            {this.state.open && (
              <AddLead
                type="lead"
                props={this}
                leadID={this.state.lead_id}
                close={this.onClose}
                addLead={this.lead_accum}
                toast={this.Showtoast}
              ></AddLead>
            )}
          </Card>
          <Card
            className={
              this.state.isTimeLineOpen ? "exportSideBar" : "toggle-sidebar"
            }
          >
            {this.state.isTimeLineOpen && (
              <TimeLine
                id={this.state.lead_id}
                closeTimelineModal={this.closeTimeline}
                from={"lead"}
              ></TimeLine>
            )}
          </Card>

          <Modal
            isOpen={notesAddToggle}
            centered={true}
            style={{ minWidth: "35rem", padding: "2rem" }}
          >
            <div style={{ padding: "20px" }}>
              <div className="conCanMod" style={{ textAlign: "center" }}>
                Add Notes
              </div>
              {/*  */}
              <textarea
                className="text-area-add-project"
                value={notes}
                style={{
                  fontSize: "14px",
                  color: "#212529",
                  border: "1px solid #f0f0f0",
                  marginBottom: "16px",
                }}
                maxLength={500}
                name="notes"
                onChange={(e) => {
                  this.setState({ notes: e.target.value });
                }}
              />
              <div style={{ justifyContent: "center" }} className="flexEnd">
                <div
                  onClick={() =>
                    this.setState({
                      notesAddToggle: false,
                      notes: "",
                      lead_id: "",
                      notesbtn: false,
                    })
                  }
                  className="cl0A8080 mr-20 point fw-500"
                >
                  Cancel
                </div>
                <Button className="btn-info" onClick={() => this.addNotes()}>
                  {" "}
                  {notesbtn ? (
                    <div className="spin-loading-save w100 h100">
                      <Spinner
                        style={{
                          height: "20px",
                          width: "20px",
                        }}
                        type="border"
                        color="#0A8080"
                      />
                    </div>
                  ) : (
                    "Add"
                  )}
                </Button>
              </div>
            </div>
          </Modal>

          {this.state.lead_id && (
            <ConvertCancelModal
              props={this}
              toggle={() => this.toggleModal()}
              isOpen={openModal}
              subject={type}
              lead={this.state.lead_id}
            ></ConvertCancelModal>
          )}
        </Container>
      </>
    );
  }
}
export default Leads;
