import { Component } from "react";
import { CardHeader, Spinner } from "reactstrap";
import closeIcon from "../../assets/img/icons/white_close_icon.svg";
import ApiService from "constants/ApiService";
import { showDatenTime, capitalize } from "constants/utils";
import { TimelineMessage } from "constants/utils";
export default class TimeLine extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeLine: [],
      estimate_status: {
        created: "created",
        resend: "resent",
        "updated and resend": "resent and updated",
      },
      isPageLoading: true,
    };
  }
  componentDidMount = () => {
    this.getTimeline();
  };

  getTimeline = async () => {
    let { from } = this.props;
    if (from === "invoices") {
      let response = await ApiService.getAPI(
        `contractor/invoice/${this.props.id}`
      );
      this.setState({ isPageLoading: false });
      if (response.success) {
        this.setState({ timeLine: response?.data?.invoice?.timeline });
      }
    } else if (from === "invoice") {
      let response = await ApiService.getAPI(
        `contractor/crmPayment/invoice/detail/timeline/${this.props.id}`
      );
      this.setState({ isPageLoading: false });
      if (response.success) {
        this.setState({ timeLine: response?.data?.invoiceDetail?.timeline });
      }
    } else if (from === "payment") {
      let response = await ApiService.getAPI(
        `contractor/crmPayment/payments/detail/timeline/${this.props.id}`
      );
      this.setState({ isPageLoading: false });
      if (response.success) {
        this.setState({ timeLine: response?.data?.invoiceDetail?.timeline });
      }
    } else {
      let detail = await ApiService.getAPI(
        `contractor/${from}/timeline/${this.props.id}`
      );
      this.setState({ isPageLoading: false });
      if (detail.success) {
        let { timeline } = detail.data[from] || detail.data;
        this.setState({ timeLine: timeline }, () => { });
      }
    }
  };

  closeModal = () => {
    this.props.closeTimelineModal(false);
  };

  isInvoice = () => {
    return this.props.from === "invoices" || this.props.from === "invoice" || this.props.from === "payment"
  }

  render() {
    let { from } = this.props;
    let { isPageLoading, estimate_status } = this.state;
    let isInvoice = this.isInvoice();
    return isPageLoading ? (
      <div className="spin-loading">
        <Spinner type="border" color="#2e1a47" />
      </div>
    ) : (
      <>
        <div>
          <CardHeader>
            <div className="flex">
              <div className="fw-500 f16" style={{ color: "white" }}>
                Timeline
              </div>
              <img
                src={closeIcon}
                onClick={() => this.closeModal()}
                className="point"
                alt="close icon"
              />
            </div>
          </CardHeader>
          <div className="cardContainer timeline_body">
            {this.state.timeLine?.length ? (
              this.state.timeLine.map((timeline, index) => (
                <div className="align-left date_info invoiceTimeline">
                  <div className="flexMidColumn mr-20">
                    <div className="timeLineRound"></div>
                    {index < this.state.timeLine.length - 1 && (
                      <div className="timeLineVerLine"></div>
                    )}
                  </div>
                  <div className="flex-column">
                    <div className="f14 mb-15 cl5A5858 fw-500 lh-12">
                      <div style={{ display: "flex", gap: "10px" }}>
                        <div>
                          {this.props.from === "estimate" || this.props.from === "lead" ? `${capitalize(from)} has been`
                            : isInvoice ? timeline.msg :
                              capitalize(from)}
                          {timeline.status &&
                            <span
                              style={{
                                color:
                                  this.props.from === "estimate" ||
                                    this.props.from === "lead"
                                    ? ""
                                    : timeline?.status === "completed" ||
                                      timeline?.status === "active" ||
                                      timeline?.status === "closed" ||
                                      timeline?.status === "approved"
                                      ? "#07A84E"
                                      : timeline?.status === "discontinued" ||
                                        timeline?.status === "cancelled"
                                        ? "#f75f18"
                                        : timeline?.status === "due"
                                          ? "#0070FD"
                                          : "#FFCC3D",
                              }}
                            >
                              {` ${timeline?.status === "active"
                                ? "Created"
                                : timeline?.status === "in_progress"
                                  ? "In Progress"
                                  : timeline?.status === "on_hold"
                                    ? "On Hold"
                                    : (timeline.status &&
                                      capitalize(timeline?.status)) ||
                                    estimate_status[timeline.action] ||
                                    timeline.action
                                }`}
                            </span>
                          }
                        </div>
                        {this.props.from === "task" && (
                          <div>{`- Progress ${timeline?.progress}%`}</div>
                        )}
                      </div>
                      {(this.props.from === "task" && timeline.updatedBy) && (
                          <div style={{marginTop:'15px',fontSize:'14px', fontWeight:'400'}}>{`Updated by `}
                            <span style={{fontWeight:'500'}}>{`${timeline?.updatedBy}`}</span>
                          </div>
                        )}
                    </div>
                    <div
                      className={
                        (timeline.notes || timeline.reason)
                          ? "fw-500 f14 linkDiv lh-12 mb-20"
                          : "fw-500 f14 linkDiv lh-12 mb-40"
                      }
                    >
                      {showDatenTime(
                        timeline.date || timeline.createdAt || timeline.time
                      )}
                    </div>
                    {(timeline.notes || timeline.reason) && (
                      <div
                        style={{ marginBottom: "30px", lineBreak: "anywhere" }}
                        className=" timelineReason f14 fw-400 mb-35"
                      >
                        <span className="f14 fw-500">{isInvoice ? "Reason : " : "Notes : "}</span>
                        {isInvoice ? timeline.reason : timeline.notes}
                      </div>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <div className="f13">No Timeline Available </div>
            )}
            <div></div>
          </div>
        </div>
      </>
    );
  }
}
