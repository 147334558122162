import React, { Component } from "react";
import { Button, Modal, Spinner } from "reactstrap";
import ApiService from "constants/ApiService";
import { showError } from "constants/utils";
import { showSuccess } from "constants/utils";
import "react-datepicker/dist/react-datepicker.css";
import { Custom_Button } from "components/Buttons/Button";
import { Button_without_Border } from "components/Buttons/Button";
import { remove_underscore_capitalize } from "constants/utils";
export default class CancelModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      btn_load: false,
    };
  }

  cancel = async () => {
    const { toast } = this.props;
    let url;
    this.props.type === "task" &&
      (url = `contractor/task/cancelled/${this.props.id}`);
    this.props.type === "change_order" &&
      (url = `contractor/changeOrder/cancelled/${this.props.id}`);
    this.setState({ btn_load: true });
    let response = await ApiService.patchAPI(url);
    this.setState({ btn_load: true });
    if (response.success) {
      toast
        ? toast("success", response.message)
        : showSuccess(this.props.props, response.message);
      this.props.closeModal(true);
    } else {
      toast
        ? this.props.toast("danger", response.message)
        : showError(this.props.props, response.message);
    }
  };

  render() {
    let { toggleModal, closeModal, modalState, type, name, onSubmit, width } =
      this.props;
    return (
      <Modal
        className="modal-dialog-centered"
        isOpen={modalState}
        toggle={toggleModal}
      >
        <>
          <div className="modal-invite">
            <div
              style={{
                textAlign: "center",
                fontWeight: "500",
                fontSize: "20px",
                color: "#212121",
              }}
            >
              {`Cancel ${remove_underscore_capitalize(type)}`}
            </div>
            <div
              style={{
                textAlign: "center",
                color: "rgba(0, 0, 0, 0.5);",
                padding: "20px 0px",
              }}
            >
              {`Are you sure want to cancel the ${remove_underscore_capitalize(
                type
              )}`}
              <br />
              {`${name} ?`}
            </div>

            <div className="center_the_buttons">
              <Button_without_Border
                btnLabel={"No"}
                click={() => closeModal(false)}
              />

              <Custom_Button
                style={{ marginLeft: "20px" }}
                isPrimary
                btnLabel={"yes"}
                click={() => this.cancel()}
                isLoading={this.state.btn_load}
              />
            </div>
          </div>
        </>
      </Modal>
    );
  }
}
