import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  FormGroup,
} from "reactstrap";
class RejectModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let { open, close, reject_reason, onInputChange, onSubmit, id } =
      this.props;
    return (
      <Modal className="modal-dialog-centered" isOpen={open}>
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Reject {id === "maintenance" ? "Maintenance" : "Request"}
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={close}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <Col lg="12">
              <FormGroup className="mb-0">
                <label
                  className="form-control-label label-in mb-3 ft_family ft_15 ft_500"
                  htmlFor="input-username"
                >
                  Please enter your reason to reject this{" "}
                  {id === "maintenance" ? "maintenance" : "request"}*
                </label>

                <textarea
                  className="reject-text-area"
                  value={reject_reason}
                  name="reject_reason"
                  placeholder="Your reason ..."
                  onChange={onInputChange}
                />
              </FormGroup>
            </Col>
          </Row>
          <p className=""></p>
        </div>
        <div className="modal-footer">
          <div className="add-filter-wrap ">
            <div
              className="filter-wrap mr-3 edit-prop change-profile-pass"
              onClick={close}
            >
              <label className="profile-cancel">Cancel</label>
            </div>
          </div>
          <Button
            color="primary"
            type="button"
            onClick={onSubmit}
            className="button-wrapping change-profile-button"
          >
            Reject
          </Button>
        </div>
      </Modal>
    );
  }
}
export default RejectModal;
