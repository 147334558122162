import { Component } from "react";
import { CardHeader , Spinner , Button} from "reactstrap";
import closeIcon from "../../assets/img/brand/close.svg";
import { showError } from "constants/utils";

export default class RejectNotes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notes: "",
      btnLoad:false
    };
  }

  closeModal = () => {
    this.props.closeNotesModal(false);
  };

  submitNotes = () => {
    let {notes,btnLoad} = this.state;
    if(!notes) return showError(this.props.props,'Please enter the reason')
    this.setState({btnLoad:true})
    this.props.closeNotesModal(true,notes)
    this?.props?.getTaskList()
  }
 
  render() {
    let {btnLoad} = this.state;
    return (
      <>
        <div>
          <CardHeader>
            <div className="flex">
              <div className="fw-500 f16 cl000000"></div>
              <img
                src={closeIcon}
                onClick={() => this.closeModal()}
                className="point"
              />
            </div>
          </CardHeader>
          <div className="cardContainer">
            <div>Reason *</div>
            <textarea
              className="text-area-add-project"
              style={{
                fontSize: "14px",
                color: "#212529",
                border: "1px solid #f0f0f0",
                marginTop:'10px'
              }}
              name="notes"
              onChange={(e) => {
                this.setState({ notes: e.target.value });
              }}
            />
             <div style={{marginTop:'20px'}} className="flexEnd">
              <div
                className="mr-20 point f14 button-secondary"
                style={{fontWeight:'500'}}
                onClick={() => this.closeModal()}
              >
                Cancel
              </div>
              <Button
                color="primary"
                className="button-wrapping "
                size="sm"
                onClick={() => this.submitNotes()}
              >
                {btnLoad ? (
                  <div  className="spin-loading-save w100 h100">
                    <Spinner
                      style={{
                        height: "20px",
                        width: "20px",
                      }}
                      type="border"
                      color="#0A8080"
                    />
                  </div>
                ) : 'Submit'}
              </Button>
            </div>
          </div>
         
        </div>
      </>
    );
  }
}
