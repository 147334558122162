import React, { Component } from "react";
import {
  returnSearchObject,
  pushToFilteredPage,
  filterQuery,
  buildQueryString,
  getIsoString,
  showError,
} from "constants/utils";
import ToastList from "components/Toast/ToastList";
import ApiService from "constants/ApiService";
import {
  Badge,
  Card,
  Table,
  Container,
  Row,
  Breadcrumb,
  Col,
  Button,
  InputGroup,
  InputGroupText,
  CardBody,
  Spinner,
  DropdownMenu,
  DropdownItem,
  Media,
  UncontrolledDropdown,
  DropdownToggle,
  Modal,
} from "reactstrap";
import { Progress } from "reactstrap";
import projectTimeline from "../../assets/img/brand/projectTimeline.svg";
import showMore from "../../assets/img/brand/showMore.svg";
import { CustomListingContainer } from "components/Container/CustomListingContainer";
import { ListingHeaderLayout } from "components/Container/CustomListingContainer";
import { CustomDatePicker } from "components/FilterFields/Filterfields";
import { CustomDropDown } from "components/FilterFields/Filterfields";
import { Button_without_Border } from "components/Buttons/Button";
import { showUtcDate } from "constants/utils";
import { remove_underscore_capitalize } from "constants/utils";
import TimeLine from "../../components/Timeline/Timeline";
let project_id;
class ProjectDetailTaskList extends Component {
  constructor(props) {
    super(props);
    project_id = this.props.match.params.id;
    this.state = {
      requestList: [],
      total: 0,
      filterToggle: false,
      from: "",
      to: "",
      page: 1,
      inputSearch: "",
      filterOption: "",
      statusSearch: this.props.location?.search ? "pending" : "",
      toast: false,
      response: "",
      toastType: "",
      isLoading: true,
      query: {},
      requestId: "",
      cancelModal: false,
      input_value: false,
      tasklists: [],
      timeLineOpen: false,
      timeLineId: null,
    };
  }

  componentDidMount = async () => {
    this.setQueryStatus();
  };

  setQueryStatus = () => {
    const { state } = this.props.location;
    let queryStatus = this.props.location.search;
    let queryObj = returnSearchObject(queryStatus);
    // this.setState({projectId:})
    if (queryStatus) {
      this.setState(
        {
          // from: queryObj.from ? new Date(queryObj.from) : "",
          // to: queryObj.to ? new Date(queryObj.to) : "",
          statusSearch: queryObj.status ?? "",
          page: parseInt(queryObj.page) ?? "",
          query: returnSearchObject(queryStatus),
        },
        () => {
          (this.state.fom || this.state.to || this.state.statusSearch) &&
            this.setState({ input_value: true });
          pushToFilteredPage(this.props, { ...this.state.query });
          this.getTaskLists();
        }
      );
    } else {
      this.setState(
        {
          query: {
            ...this.state.query,
            page: 1,
            limit: 10,
          },
        },
        () => {
          pushToFilteredPage(this.props, this.state.query);
          this.getTaskLists();
        }
      );
    }
  };

  onTimLineClick = (taskId) => {
    this.setState({ timeLineOpen: true, timeLineId: taskId });
  };

  closeTimeline = () => {
    this.setState({ timeLineOpen: false, timeLineId: null });
  };

  getTaskLists = async () => {
    this.setState({ isLoading: true });
    let taskList = await ApiService.getAPI(
      `contractor/task?project_id=${project_id}`
    );
    this.setState({ isLoading: false, tasklists: taskList.data.tasks });
  };

  render() {
    let {
      requestList,
      isLoading,
      tasklists,
      page,
      statusSearch,
      toast,
      response,
      toastType,
      cancelModal,
      requestId,
      input_value,
      from,
      to,
      timeLineOpen,
      timeLineId,
    } = this.state;

    return isLoading ? (
      <div className="spin-loading">
        <Spinner type="border" color="#077e8b" />
      </div>
    ) : (
      <>
        <>
          {toast && <ToastList message={response} type={toastType} />}
          <div style={{ padding: "20px", backgroundColor: "#fafafa" }}>
            <CustomListingContainer>
              <ListingHeaderLayout
                isPrimary
                primary_buttonLabel={"add_task"}
                label={"Tasks"}
                click={this.redirectToAddRequest}
              ></ListingHeaderLayout>
              <div style={{ margin: "24px" }}>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "35% 65%",
                  }}
                >
                  <div
                    style={{ display: "grid", gridTemplateColumns: "70% 45%" }}
                  >
                    {/* <CustomDatePicker
                      from={from}
                      to={to}
                      fromChange={this.handleOnFromChange}
                      toChange={this.handleOnToChange}
                    ></CustomDatePicker> */}
                    <CustomDropDown
                      width={"126px"}
                      setStatus={this.setStatus}
                      status={statusSearch}
                      statusList={[
                        "all",
                        "accepted",
                        "pending",
                        "cancelled",
                        "rejected",
                      ]}
                    ></CustomDropDown>
                  </div>
                  {input_value && (statusSearch || from || to) && (
                    <div className="right_filter_container">
                      <Button_without_Border
                        click={this.handleReset}
                        btnLabel={"clear_filter"}
                      ></Button_without_Border>
                    </div>
                  )}
                </div>
              </div>
              <Row>
                <div className="col new-table">
                  <Card className="shadow card_with_paginator">
                    <Table
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead className="thead-light">
                        <tr>
                          <th style={{width:'25%'}} scope="col">Name</th>
                          <th style={{width:'25%'}} scope="col">Assigned To</th>
                          <th style={{width:'25%'}} scope="col">Due On</th>
                          <th style={{width:'25%'}} scope="col">Progress</th>
                          <th style={{width:'25%'}} scope="col">Status</th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      {tasklists && tasklists.length !== 0 ? (
                        <tbody>
                          {tasklists.map((task, i) => (
                            <tr key={i}>
                              <td
                                className="cursor-point capitalize"
                                style={{ width: "100px" }}
                              >
                                <div className="add_eclipse">{task.name}</div>
                              </td>
                              <td className="text-capitalize">
                                {task.sub_contractor.name}
                              </td>
                              <td className="text-capitalize">
                                {showUtcDate(task.end_date)}
                              </td>
                              <td className="text-capitalize">
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "4%",
                                  }}
                                >
                                  {" "}
                                  <div>
                                    {" "}
                                    <Progress
                                      color="#0A8080"
                                      value={task.progress}
                                    />
                                  </div>
                                  <div className="project_list_percentage">
                                    {task.progress ?? 0} %
                                  </div>
                                </div>
                              </td>
                              <td
                                className="project_list_status"
                                style={{
                                  color: `${
                                    task.status === "completed" ||
                                    task.status === "approved" ? "#07A84E"
                                      : task.status === "pending" ? "#FFCC3D"
                                      : "#FFCC3D"
                                  }`,fontWeight:"500"
                                }}
                              >
                                {remove_underscore_capitalize(task.status)}
                              </td>
                              <td style={{display:'flex',alignItems:'center',gap:'20px'}}>
                                <div>
                                  <img
                                    onClick={() =>
                                      this.onTimLineClick(task.task_id)
                                    }
                                    style={{ cursor: "pointer" }}
                                    src={projectTimeline}
                                    alt="timeline"
                                  ></img>
                                </div>
                                {task.status !== "approved" && (
                                  <div className="project_list_content">
                                    {" "}
                                    <UncontrolledDropdown
                                      nav
                                      className="flexy-content position_u"
                                    >
                                      <DropdownToggle
                                        className="pr-0 nav-notice"
                                        nav
                                      >
                                        <Media className="align-items-center">
                                          <img src={showMore} alt="show more" />
                                        </Media>
                                      </DropdownToggle>
                                      <DropdownMenu
                                        className="dropdown-menu-arrow min-width-drop"
                                        right
                                      >
                                        {task.status === "completed" ? (
                                          <>
                                            <DropdownItem>
                                              <span>Approve</span>
                                            </DropdownItem>
                                            <DropdownItem>
                                              <span>Reject</span>
                                            </DropdownItem>
                                          </>
                                        ) : (
                                          <>
                                            <DropdownItem>
                                              <span>Edit</span>
                                            </DropdownItem>
                                          </>
                                        )}
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                  </div>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      ) : (
                        <tbody>
                          <tr>
                            <td className="no-data">No Request Available</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        </tbody>
                      )}
                    </Table>
                  </Card>
                </div>
              </Row>
              <Row className="align-items-center header-div jus-space new-margin-page paginator_box">
                <div className="paginate-flex-box ">
                  <div
                    className="paginate-box"
                    onClick={() => this.handleDecrementPage()}
                  >
                    <p
                      className={
                        page !== 1 ? "page_available" : "page_unavailable"
                      }
                    >
                      Previous
                    </p>
                  </div>
                  <div
                    className="paginate-box"
                    onClick={() => this.handleIncrementPage()}
                  >
                    <p
                      className={
                        requestList.length < 10
                          ? "page_unavailable"
                          : "page_available"
                      }
                    >
                      Next
                    </p>
                  </div>
                </div>
              </Row>
            </CustomListingContainer>
          </div>
        </>
        <Card className={timeLineOpen ? "exportSideBar" : "toggle-sidebar"}>
          {timeLineOpen && (
            <TimeLine
              id={timeLineId}
              closeTimelineModal={this.closeTimeline}
              from={"task"}
            ></TimeLine>
          )}
        </Card>
        ;
      </>
    );

    // eslint-disable-next-line no-unreachable
  }
}

export default ProjectDetailTaskList;
