import { useState, useEffect } from "react";
import closeIcon from "../../assets/img/brand/close.svg";
import { isInvalidEmail } from "constants/utils";

const TagInputs = (props) => {
  const [tags, setTags] = useState([]);
  const [error,setError] = useState(props.error)
  useEffect(() => {
    setError(props.error)
  },[props.error])

  useEffect(() => {
    if (props.issueList) {
      setTags(props.issueList);
    }
  }, [props.issueList]);

  const addAssignee = (event) => {
    if (event.key === "Enter" && event.target.value !== "") {
      setTags([...tags, event.target.value]);
      props.issues([...tags, event.target.value]);
      event.target.value = "";
    }
  };

  const removeAssignee = (index) => {
    setTags([...tags.filter((tag) => tags.indexOf(tag) !== index)]);
    props.issues([...tags.filter((tag) => tags.indexOf(tag) !== index)]);
  };

  return (
    <div className={`tagInputDiv ${error ? 'error' : ''}`}>
      {tags.length > 0 && (
        <ul>
          {tags.map((tag, i) => (
            <li className="mb_8">
              <span>{tag}</span>
              <img
                src={closeIcon}
                onClick={() => removeAssignee(i)}
                className="point"
                width="15px"
                height="15px"
              />
            </li>
          ))}
        </ul>
      )}
      <input
        type="text"
        placeholder={(props.disabled ? '' : "Enter Issues")}
        onKeyUp={(event) => addAssignee(event)}
        className={`assigneeInput`}
        style={{backgroundColor:props.disabled ? 'white' : ''}}
        disabled={(props.disabled ?? false)}
      />
    </div>
  );
};

export default TagInputs;
