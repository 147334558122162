import React, { Component } from "react";
import moment from "moment";
import {
  Card,
  Table,
  Row,
  DropdownMenu,
  DropdownItem,
  Media,
  UncontrolledDropdown,
  DropdownToggle,
} from "reactstrap";
import AddChangeOrder from "../addChangeOrder";
import projectTimeline from "../../../assets/img/brand/projectTimeline.svg";
import showMore from "../../../assets/img/brand/showMore.svg";
import ApiService from "constants/ApiService";
import { showUtcDate } from "constants/utils";

export default class Details_change_orders extends Component {
  constructor(props) {
    super(props);

    this.state = {
      orders: [],
      openChangeOrder: false,
      orderId: "",
    };
  }

  componentDidMount = () => {
    this.getOrders();
  };

  capitalizeFirstLetter = (str) => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  successClose = () => {
    this.setState({ openChangeOrder: false, orderId: "" });
    this.getOrders();
  };

  getOrders = () => {
    new Promise(async (response, resolve) => {
      const orderResponse = await ApiService.getAPI(
        `contractor/changeOrder/${this.props.projectId}`
      );
      if (orderResponse.success) {
        this.setState({ orders: orderResponse.data });
      }
    });
  };

  editOrder = (id) => {
    this.setState({ openChangeOrder: true, orderId: id });
  };

  render() {
    const { orders, openChangeOrder, orderId } = this.state;
    const { projectId } = this.props;
    return (
      <>
        <div className="top_container_hero">
          <div
            className="breadcrumb"
            style={{ fontWeight: "500", fontSize: "18px", color: "#303030" }}
          >
            Change Orders
          </div>
          <div className="top_right">
            <div
              style={{
                color: "#338180",
                fontSize: "14px",
                cursor: "pointer",
              }}
              onClick={() => this.setState({ openChangeOrder: true })}
            >
              + Add
            </div>
            <div
              style={{
                color: "#338180",
                fontSize: "14px",
                cursor: "pointer",
              }}
              onClick={() =>
                this.props.props.props.history.push("/admin/changeOrders")
              }
            >
              View All
            </div>
          </div>
        </div>
        <div style={{ padding: " 0% 3%" }}>
          <Row>
            <div className="col new-table">
              <Card className="shadow card_with_crm">
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th
                        style={{ borderColor: "#f5f5f5" }}
                        scope="col"
                        className="ptb20 w_30"
                      >
                        Name
                      </th>
                      <th
                        className="ptb20 w_25"
                        style={{ borderColor: "#f5f5f5" }}
                        scope="col"
                      >
                        Requested Date
                      </th>
                      <th className="w_18 ptb20" scope="col">
                        Due Date
                      </th>

                      <th className="w_18 ptb20" scope="col">
                        Amount
                      </th>
                      <th
                        className="ptb20 w_18"
                        style={{ borderColor: "#f5f5f5" }}
                        scope="col"
                      >
                        Status
                      </th>
                      {orders.length > 0 && (
                        <th
                          className="ptb20"
                          style={{
                            width: "4%",
                            cursor: "pointer",
                            fontWeight: "500",
                            color: "#338180",
                            borderColor: "#f5f5f5",
                          }}
                          scope="col"
                          onClick={this.redirectToListWithQuery}
                        ></th>
                      )}
                    </tr>
                  </thead>
                  {orders && orders.length !== 0 ? (
                    <tbody>
                      {orders.map((order, i) => (
                        <tr key={i}>
                          <td
                            className="cursor-point capitalize ptb20"
                            // onClick={() =>
                            //   task.status === "accepted" &&
                            //   this.props.history.push(
                            //     `/admin/requests/detail/${task?.request_id}`
                            //   )
                            // }
                            style={{ width: "100px" }}
                          >
                            <div className="project_list_content">
                              {this.capitalizeFirstLetter(order.title)}
                            </div>
                          </td>
                          <td className="ptb20">
                            <div className="project_list_content">
                              {" "}
                              {showUtcDate(order.createdAt)}
                            </div>
                          </td>

                          <td className="ptb20">
                            <div className="project_list_content">
                              {" "}
                              {showUtcDate(order.due_date)}
                            </div>
                          </td>

                          <td
                            className="cursor-point capitalize ptb20"
                            style={{ width: "100px" }}
                          >
                            <div className="project_list_content">
                              {order.amount}
                            </div>
                          </td>

                          <td className="ptb20">
                            <div
                              className="project_list_status"
                              style={{
                                color: `${order.status === "completed" ||
                                    order.status === "approved"
                                    ? "#07A84E"
                                    : order.status === "pending"
                                      ? "#FF4949"
                                      : order.status === "due"
                                        ? "#0070FD"
                                        : order.status === "rejected"
                                          ? "#F70e0e"
                                          : "#FFCC3D"
                                  }`,
                              }}
                            >
                              {" "}
                              {order.status === "in_progress"
                                ? "In Progress"
                                : this.capitalizeFirstLetter(order.status)}
                            </div>
                          </td>

                          <td
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-end",
                              // justifyContent: "center",
                              borderTop: "none",
                              gap: "10px",
                            }}
                          >
                            <div>
                              <img
                                onClick={() =>
                                  this.setState({
                                    isTimeLineOpen: true,
                                    order_id: order.order_id,
                                  })
                                }
                                style={{ cursor: "pointer" }}
                                src={projectTimeline}
                                alt="timeline"
                              ></img>
                            </div>
                            {order.status !== "approved" && (
                              <div className="project_list_content">
                                {" "}
                                <UncontrolledDropdown
                                  nav
                                  className="flexy-content position_u"
                                >
                                  <DropdownToggle
                                    className="pr-0 nav-notice"
                                    nav
                                  >
                                    <Media className="align-items-center">
                                      <img src={showMore} alt="show more" />
                                    </Media>
                                  </DropdownToggle>
                                  <DropdownMenu
                                    className="dropdown-menu-arrow min-width-drop"
                                    right
                                  >
                                    <>
                                      <DropdownItem
                                        onClick={() =>
                                          this.editOrder(order.changeOrder_id)
                                        }
                                      >
                                        <span>Edit</span>
                                      </DropdownItem>
                                    </>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </div>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td className="no-data">No orders Available</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  )}
                </Table>
              </Card>
            </div>
          </Row>
        </div>
        <Card className={openChangeOrder ? "exportSideBar" : "toggle-sidebar"}>
          {openChangeOrder && (
            <AddChangeOrder
              props={this.props.props}
              projectId={projectId}
              orderId={orderId}
              successClose={this.successClose}
              close={() =>
                this.setState({ openChangeOrder: false, orderId: "" })
              }
            ></AddChangeOrder>
          )}
        </Card>
      </>
    );
  }
}
