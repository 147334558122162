import React, { Component } from "react";
import {
  Card,
  Table,
  Container,
  Row,
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Button,
  InputGroup,
  InputGroupText,
  Spinner,
  DropdownMenu,
  DropdownItem,
  Media,
  UncontrolledDropdown,
  DropdownToggle,
  Modal,
  Input,
} from "reactstrap";
import NoData from "../../assets/Lotties/noData.json";
import moment from "moment";
import Lottie from "react-lottie";
import editIcon from "../../assets/img/brand/edit.svg";
import ApiService from "constants/ApiService";
import projectTimeline from "../../../src/assets/img/brand/projectTimeline.svg";
import "react-datepicker/dist/react-datepicker.css";
import sendChat from "../../assets/img/brand/send_chat.svg";
import { getIsoString, filterQuery } from "constants/utils";
import ToastList from "components/Toast/ToastList";
import CrmPhotos from "../../assets/img/icons/crm_photo.svg";
import CrmDocument from "../../assets/img/icons/crm_documents.svg";
import { showError } from "constants/utils";
import { returnSearchObject } from "constants/utils";
import { pushToFilteredPage } from "constants/utils";
import { buildQueryString } from "constants/utils";
import pksLogo from "../../assets/img/new Logo/faviconN.svg";
import showMore from "../../assets/img/brand/showMore.svg";
import { showSuccess } from "constants/utils";
import TimeLine from "components/Timeline/Timeline";
import { uploadCloudinary } from "constants/utils";
import { getCloudinaryImage } from "constants/utils";
import DeleteModal from "./DeleteModal";
import AddProject from "./addProject";
import UpdateStatus from "../Project/updateStatus";
import RejectNotes from "./rejectNotes";
import AddPhotosDocuments from "./addPhotosOrDocuments";
import Details_change_orders from "./projectDetails/Details_change_orders";
import { showUtcDate } from "constants/utils";
let overlap = document.querySelector(":root");

let project_id;
let selectedTab;
class ProjectDetail extends Component {
  constructor(props) {
    super(props);
    project_id = this.props.match.params.project_id;
    this.textareaRef = React.createRef();
    this.state = {
      projectId:"",
      requestList: [],
      payments: [],
      upd_btn: false,
      total: 0,
      filterToggle: false,
      from: "",
      to: "",
      page: 1,
      delete_data: {},
      open: false,
      inputSearch: "",
      filterOption: "",
      statusSearch: this.props.location?.search ? "pending" : "",
      toast: false,
      response: "",
      toastType: "",
      isLoading: true,
      name: "",
      actual_start: "",
      end_date: "",
      start_date: "",
      inputText: "",
      image: [],
      edit_project: false,
      btnLoad: false,
      open_project: false,
      subContractor_name: "",
      subContractor_id: "",
      subContractor_list: [],
      clientsList: [],
      final_project: {},
      desc: "",
      task_image: [],
      project_image: [],
      project: {},
      tasks: [],
      query: {},
      requestId: "",
      cancelModal: false,
      openModal: false,
      tags: [],
      task_id: "",
      openOrder:false,
      project_id: project_id,
      notesOpen: false,
      isTimeLineOpen: false,
      type: "",
      content: [
        // {
        //   name: "Comments",
        //   img: CrmComment,
        //   selected: true,
        // },
        {
          name: "Documents",
          img: CrmDocument,
          selected: false,
        },
        {
          name: "Photos",
          img: CrmPhotos,
          selected: false,
        },
      ],
      comments: [
        {
          content: "hi",
          date: new Date(),
        },
        {
          content: "hi",
          date: new Date(),
        },
        {
          content: "hi",
          date: new Date(),
        },
        {
          content: "hi",
          date: new Date(),
        },
        {
          content: "hi",
          date: new Date(),
        },
        {
          content: "hi",
          date: new Date(),
        },
      ],
      selectedContent: "Documents",
      openAddDocumentsOrPhotos: false,
      photos: [],
      documents: [],
    };
  }


  componentDidMount() {
   
    selectedTab = this.props.location?.state?.from

    overlap.style.setProperty("--width", "600px");
    overlap.style.setProperty("--backgroundColor", "transparent");
    if (project_id) this.getProjectDetails();
    // this.editTask()
  }

  componentWillUnmount() {
    overlap.style.setProperty("--width", "450px");
    overlap.style.setProperty("--backgroundColor", "#f5f5f5");
  }

  updateStatus = () => {
    this.setState({ upd_btn: true });
  };

  getProjectDetails = async () => {
    let [response, taskList] = await Promise.all([
      ApiService.getAPI(`contractor/project/${project_id}`),
      ApiService.getAPI(`contractor/task/inside?project_id=${project_id}`),
    ]);
    if (!response.success) return this.setState({ isLoading: false });
    let data = response.data.project;

    this.setState(
      {
        project: data,
        final_project: data,
        projectId:data.project_id,
        tasks: taskList?.data?.tasks?.slice(0, 5) || [],
      },
      () => {
        this.setState({ isLoading: false });
      }
    );
  };
  setQueryStatus = () => {
    let queryStatus = this.props.location.search;
    if (queryStatus) {
      let queryObj = returnSearchObject(queryStatus);

      this.setState(
        {
          from: queryObj.from ? new Date(queryObj.from) : "",
          to: queryObj.to ? new Date(queryObj.to) : "",
          statusSearch: queryObj.status ?? "",
          page: parseInt(queryObj.page) ?? "",
          query: returnSearchObject(queryStatus),
        },
        () => {
          pushToFilteredPage(this.props, { ...this.state.query });
          this.getRequestsList();
        }
      );
    } else {
      this.setState(
        {
          query: {
            ...this.state.query,
            page: 1,
            limit: 10,
          },
        },
        () => {
          pushToFilteredPage(this.props, this.state.query);
          this.getRequestsList();
        }
      );
    }
  };

  

  onClose = () => {
    this.setState(
      {
        open: false,
        openOrder:false,
        edit_project: false,
        tags: [],
        desc: "",
        name: "",
        start_date: "",
        actual_start: "",
        subContractor_name: "",
        subContractor_id: "",
        end_date: "",
      },
      () => this.getProjectDetails()
    );
  };

  closeModal = () => {
    this.setState({ openModal: false }, () => {
      this.getProjectDetails();
    });
  };

  handleDelete = async () => {
    let response = await ApiService.patchAPI(
      `contractor/task/delete/${this.state.task_id}`,
      {}
    );
    if (response.success) {
      showSuccess(this, response.message);
      this.setState({ delete_data: {} });
      this.closeModal();
      this.getProjectDetails();
    } else {
      showError(this, response.message);
    }
  };

  editTask = async (data) => {
    this.setState(
      {
        // tags: data.tags,
        task_id: data.task_id,
        project_id: data.project_id,
        // desc: data.desc,
        // subContractor_name: data.sub_contractor.name,
        // subContractor_id: data.sub_contractor.id,
      },
      () => {
        this.setState({ open: true });
      }
    );
  };
  getRequestsList = async () => {
    let { filterOption, statusSearch, inputSearch, from, to } = this.state;
    let queryParams = "";
    if (filterOption !== "" && inputSearch !== "")
      queryParams += filterQuery(filterOption, inputSearch);
    if (statusSearch !== "") {
      queryParams += filterQuery("status", statusSearch);
    }
    if (from !== "") queryParams += filterQuery("from", getIsoString(from));
    if (to !== "") queryParams += filterQuery("to", getIsoString(to));
    if (from > to) {
      showError(this, "Please provide valid From and To date");
    } else {
      let response = await ApiService.getAPI(
        `contractor/open_request${buildQueryString(this.state.query)}`
      );

      if (response.success) {
        let { open_request, total } = response.data;
        this.setState({
          requestList: open_request,
          total: total,
          isLoading: false,
        });
      }
    }
  };
  capitalizeFirstLetter = (str) => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  createProjectHandler = async () => {
    let { final_project } = this.state;
    if (
      !final_project.name ||
      !final_project.client.name ||
      !final_project.start_date ||
      !final_project.end_date ||
      !final_project.actual_start
    )
      return showError(this, "Please fill all the required fields");
    this.setState({ btnLoad: true });

    let response = await ApiService.patchAPI(
      `contractor/project/${project_id}`,
      final_project
    );

    if (response.success) {
      showSuccess(this, response.message);
      this.setState({ btnLoad: false, open: false, edit_project: false });
      this.getProjectDetails();
    } else {
      this.setState({ btnLoad: false });
      showError(this, response.message);
    }
  };
  createTaskHandler = async () => {
    let {
      name,
      subContractor_name,
      start_date,
      end_date,
      actual_start,
      subContractor_id,
      desc,
      edit,
      task_id,
      tags,
    } = this.state;

    if (
      !name ||
      !subContractor_name ||
      !start_date ||
      !end_date ||
      !actual_start
    )
      return showError(this, "Please fill all the required fields");
    this.setState({ btnLoad: true });
    let data = {
      name: name,
      sub_contractor: {
        id: subContractor_id,
        name: subContractor_name,
      },
      start_date: start_date,
      end_date: end_date,
      actual_start: actual_start,
      desc: desc,
      tags: tags,
    };
    let response = edit
      ? await ApiService.patchAPI(`contractor/task/${task_id}`, data)
      : await ApiService.postAPI(`contractor/task/${project_id}`, data);
    if (response.success) {
      showSuccess(this, response.message);
      this.setState({
        btnLoad: false,
        open: false,
        name: "",
        start_date: "",
        end_date: "",
        actual_start: "",
        desc: "",
        tags: [],
        subContractor_name: "",
        subContractor_id: "",
      });
      this.getProjectDetails();
    } else {
      this.setState({ btnLoad: false });
      showError(this, response.message);
    }
  };

  toggleFilterMenu = () => {
    this.setState({ filterToggle: !this.state.filterToggle });
  };
  onChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  updateStateValues = (key, value) => {
    this.setState(
      {
        [key]: value,
      },
      function () {
        if (value === null) {
          this.setState({
            [key]: "",
          });
        }
      }
    );
  };

  update = (e) => {
    const textarea = this.textareaRef.current;
    textarea.style.height = "28px";
    textarea.style.height = `${textarea.scrollHeight}px`;
    this.setState({
      inputMessage: e.target.value,
    });
  };

  handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey && !e.ctrlKey) {
      e.preventDefault();
    } else if (e.key === "Enter" && (e.shiftKey || e.ctrlKey) && !e.repeat) {
      this.insertLineBreak();
      e.preventDefault();
    }
    e.key === "Enter" &&
      !e.repeat &&
      !e.shiftKey &&
      !e.ctrlKey &&
      this.sendMessage();
    this.date = [];
  };

  sendMessage = () => {
    const textarea = this.textareaRef.current;
    textarea.style.height = "28px";
  };
  insertLineBreak() {
    const textarea = this.textareaRef.current;
    const { selectionStart, selectionEnd, value } = textarea;
    const newValue =
      value.substring(0, selectionStart) + "\n" + value.substring(selectionEnd);
    textarea.value = newValue;
    textarea.setSelectionRange(selectionStart + 1, selectionStart + 1);
    textarea.style.height = `${textarea.scrollHeight}px`;
  }

  handleOnToChange = (e) => {
    this.updateStateValues("to", e ? new Date(e) : '');
  };
  handleOnFromChange = (e) => {
    this.updateStateValues("from", e ? new Date(e) : '');
  };
  handleIncrementPage = () => {
    if (this.state.requestList.length >= 10) {
      this.setState({ page: this.state.page + 1 }, () =>
        this.handleFilterStatus()
      );
    }
  };
  handleDecrementPage = () => {
    if (this.state.page > 1) {
      this.setState({ page: this.state.page - 1 }, () =>
        this.handleFilterStatus()
      );
    }
  };
  redirectDetailPage = (id) => {
    this.props.history.push(`requests/detail/${id}`);
  };

  handleFilterStatus = () => {
    let staticQuery = returnSearchObject(this.props.location.search);
    staticQuery.from = getIsoString(this.state.from) ?? "";
    staticQuery.to = getIsoString(this.state.to) ?? "";

    staticQuery.status = this.state.statusSearch ?? "";
    staticQuery.page = this.state.page ?? "";

    this.setState({ query: staticQuery }, () => {
      pushToFilteredPage(this.props, { ...this.state.query });
      this.getRequestsList();
    });
  };

  handleSearch = () => {
    this.setState({ page: 1 }, () => {
      this.handleFilterStatus();
    });
  };

  handleReset = () => {
    let self = this;
    self.setState(
      {
        from: "",
        to: "",
        statusSearch: "",
        filterOption: "",
        inputSearch: "",
        page: 1,
        limit: 10,
        query: {
          page: 1,
          limit: 10,
        },
      },
      () => {
        pushToFilteredPage(this.props, { ...this.state.query });
        this.getRequestsList();
      }
    );
  };

  setStatus = (status) => {
    this.setState({ statusSearch: status, page: 1 }, () => {
      this.handleFilterStatus();
    });
  };

  redirectToAddRequest = async () => {
    this.setState({
      open: true,
      task_id: "",
      project_id: project_id,
    });
  };

  editProject = async (data) => {
    this.setState({
      edit_project: true,
    });
  };

  imageHandler = async (e, type) => {
    let dynamicImageValue = this.state[type];
    let file = e.target.files[0];

    if (e.target.files.length > 0) {
      this.setState({ imageLoading: true });
    }

    if (
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/jpeg" ||
      file.type === "application/pdf"
    ) {
      if (file.size < 1000000) {
        let data = await uploadCloudinary(file, "host");
        if (data.url) {
          let imgArr = [...dynamicImageValue];
          let imageObj = {
            format: data.format,
            public_id: data.public_id,
            version: `v${data.version}`,
            type: "image",
          };
          imgArr.push(imageObj);
          this.setState({
            [type]: imgArr,
            imageLoading: false,
          });
        } else {
          showError(this, "Could not upload image");
          this.setState({ imageLoading: false });
        }
      } else {
        showError(this, "File size should not exceeds 1 MB");
        this.setState({ imageLoading: false });
      }
    } else {
      showError(this, "Invalid Format");
      this.setState({ imageLoading: false });
    }
  };

  deleteImage = (type) => {
    this.setState({ [type]: [] });
  };

  cancelRequest = async (request) => {
    this.setState({ cancelModal: true, requestId: request.open_request_id });
  };
  handleNewOneChange = (newTags) => {
    this.setState({ tags: newTags });
  };
  handleProjectChange = (tags) => {
    this.setState((prevState) => ({
      final_project: {
        ...prevState.final_project,

        tags: tags,
      },
    }));
  };

  redirectToListWithQuery = () => {
    this.props.history.push(
      `/admin/tasks_lists?project_id=${project_id}&assignType=inside`
    );
  };

  closeUpdateModal = () => {
    this.setState({ upd_btn: false }, () => {
      this.getProjectDetails();
    });
  };

  closeRejectNotes = (boolean, notes) => {
    this.setState({ notesOpen: false });
    boolean && this.approveOrReject(this.state.type, this.state.task_id, notes);
  };

  approveOrReject = async (status, id, notes) => {
    let data = {
      notes: notes,
    };
    let response = await ApiService.patchAPI(
      `contractor/task/${status}/${id}`,
      data
    );
    if (response.success) {
      showSuccess(this, response.message);
      this.getProjectDetails();
    } else {
      showError(this, response.message);
    }
  };

  onReasonOpen = (task, type) => {
    this.setState({
      notesOpen: true,
      task_id: task.task_id,
      type: type,
    });
  };

  closeTimeline = () => {
    this.setState({ isTimeLineOpen: false, project_id: "" });
  };

  selectContent = (index) => {
    let obj = this.state.content;
    obj.forEach((each) => {
      each.selected = false;
    });
    obj[index].selected = true;
    this.setState({ content: obj, selectedContent: obj[index].name });
  };

  addDocumentsOrPhotos = () => {
    this.setState({ openAddDocumentsOrPhotos: true });
  };

  viewAll = () => {
    this.props.history.push(
      `/admin/${this.state.selectedContent.toLowerCase()}`
    );
  };

  viewPayments = () => {
    this.props.history.push("/admin/payments");
  };

  render() {
    const NodataFound = {
      loop: true,
      autoplay: true,
      animationData: NoData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    const EmptyMessage = (
      <>
        <div className="no_data_dashboard" style={{ height: "100%" }}>
          <Lottie options={NodataFound} height={100} width={100} />
          <div className="mt_20">
            <p>No {this.state.selectedContent} Found</p>
          </div>
        </div>
      </>
    );

    let {
      requestList,
      isLoading,
      page,
      imageLoading,
      notesOpen,
      task_image,
      project_image,
      toast,
      response,
      toastType,
      selectedContent,
      open,
      project_id,
      clientsList,
      name,
      actual_start,
      end_date,
      content,
      openAddDocumentsOrPhotos,
      final_project,
      payments,
      btnLoad,

      subContractor_name,
      upd_btn,
      subContractor_list,
      project,
      tags,
      desc,
      tasks,
      edit_project,
      edit,
      task_id,
      openModal,
      delete_data,
      comments,
      photos,
      documents,
    } = this.state;
    return isLoading ? (
      <div className="spin-loading">
        <Spinner type="border" color="#077e8b" />
      </div>
    ) : (
      <>
        {this.state.openModal && (
          <DeleteModal
            modalState={openModal}
            type="task"
            id={this.state.task_id}
            name={delete_data.name}
            closeModal={this.closeModal}
            onSubmit={this.handleDelete}
            props={this}
          />
        )}

        <Container className="mt-8 card-pad-wrapper" fluid>
          {toast && <ToastList message={response} type={toastType} />}
          <div>
            <Breadcrumb></Breadcrumb>
          </div>
          <Row className="align-items-center header-div">
            <Col xs="" className="manage-con">
              <Breadcrumb>
                <BreadcrumbItem
                  onClick={() => this.props.history.push({
                    pathname:'/admin/project_overview',
                    state:{selectedTab:selectedTab}
                  })}
                  className="bread-cursor"
                >
                  Project
                </BreadcrumbItem>
                <BreadcrumbItem active style={{ color: "#72569c" }}>Details</BreadcrumbItem>
              </Breadcrumb>
              {/* <Breadcrumb className="">Project Detail</Breadcrumb> */}
            </Col>
            <Col
              xs="4"
              className="manage-con flexy-content flexEnd"
              style={{ gap: "5%" }}
            >
              <div className="status_select ml-10" style={{ width: "29%" }}>
                <UncontrolledDropdown className="status_select_div">
                  <DropdownToggle className="pr-0 drop_down_container">
                    <Media className="capitalize">
                      Overview
                      <i className="fas fa-angle-down"></i>
                    </Media>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-arrow" bottom>
                    {" "}
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() => this.props.history.push("/admin/project_overview")}
                    >
                      Overview
                    </DropdownItem>{" "}
                    <DropdownItem
                      className="dropdown_item"
                      onClick={this.redirectToListWithQuery}
                    >
                      Tasks
                    </DropdownItem>{" "}
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() => this.props.history.push("/admin/payments")}
                    >
                      Payments
                    </DropdownItem>
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() => this.props.history.push("/admin/purchase_orders")}
                      // onClick={() => this.setPage("clients")}
                    >
                      Purchase Orders
                    </DropdownItem>
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() => this.props.history.push("/admin/changeOrders")}
                    >
                      Change Orders
                    </DropdownItem>{" "}
                    <DropdownItem
                      onClick={() => this.props.history.push("/admin/comments")}
                      className="dropdown_item"
                      // onClick={() => this.setPage("proposal")}
                    >
                      Comments
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => this.props.history.push("/admin/documents")}
                      className="dropdown_item"
                      // onClick={() => this.setPage("projects")}
                    >
                      Documents
                    </DropdownItem>
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() => this.props.history.push("/admin/photos")}
                      // onClick={() => this.setPage("clients")}
                    >
                      Photos
                    </DropdownItem>
                 
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
              {project.status !== "closed" && project.status !== "cancelled" && (
              // <Col xs="4" className="manage-con flexy-content flexEnd">
                <div>
                  <Button
                    color="primary"
                    size="sm"
                    className="button-wrapping"
                    onClick={() => this.editProject()}
                  >
                    Edit
                  </Button>
                </div>
              // </Col>
            )}
            </Col>
       
          </Row>

          <Row>
            <div style={{ width: "100%", padding: " 2% 1%" }}>
              <Card
                style={{
                  width: "100%",
                  padding: "2% 1%",
                  // display: "flex",
                  // flexDirection: "row",
                  // justifyContent: "space-between",
                  border:"1px solid #dadce0",borderRadius:"6px"
                }}

              >
                <div
                  style={{
                    display: "flex",
                    gap: "4%",
                    // width: "85%",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      // gap: "2%",
                    }}
                  >
                    {/* <div>
                      <img
                        style={{ borderRadius: "5px" }}
                        src={
                          project?.image?.length > 0
                            ? getCloudinaryImage(
                                100,
                                100,
                                project.image[0],
                                "c_fill"
                              )
                            : pksLogo
                        }
                        alt="logo"
                      />
                    </div> */}

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "2%",
                      }}
                    >
                      <div
                        className="proj_heading"
                        style={{ marginTop: "-10px",fontSize:"18px" }}
                      >
                        {this.capitalizeFirstLetter(project.name)}
                      </div>

                      <div
                        style={{
                          display: "flex",
                          marginTop: "2.5%",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <div className="project_detail_data">
                            <p
                              style={{ paddingRight: "10px" }}
                              className="project_detail_client mb-remove"
                            >
                              Client
                            </p>
                            <p
                              style={{ paddingRight: "10px" }}
                              className="project_detail_client mb-remove"
                            >
                              :
                            </p>
                            <p
                              style={{
                                paddingRight: "10px",
                                borderRight: "1px solid #E2E0E0",
                                marginRight: "20px",
                              }}
                              className="project_detail_value mb-remove"
                            >
                              {this.capitalizeFirstLetter(project.client.name)}
                            </p>
                          </div>
                        </div>
                   
                        <div>
                          <div className="project_detail_data">
                            <p
                              style={{ paddingRight: "10px" }}
                              className="project_detail_client mb-remove"
                            >
                              Actual Date
                            </p>
                            <p
                              style={{ paddingRight: "10px" }}
                              className="project_detail_client mb-remove"
                            >
                              :
                            </p>
                            <p
                              style={{
                                paddingRight: "10px",
                                borderRight: "1px solid #E2E0E0",
                                marginRight: "20px",
                              }}
                              className="project_detail_value mb-remove"
                            >
                              {showUtcDate(project.actual_start)}
                            </p>
                          </div>
                        </div>
                   
                        {/* <div>
                          <div className="project_detail_data">
                            <p
                              style={{ paddingRight: "10px" }}
                              className="project_detail_client mb-remove"
                            >
                              Status
                            </p>
                            <p
                              style={{ paddingRight: "10px" }}
                              className="project_detail_client mb-remove"
                            >
                              :
                            </p>
                            <p
                              className="status_change_value mb-remove"
                              style={{
                                color: `${
                                  project.status === "completed" ||
                                  project.status === "active" ||
                                  project.status === "closed"
                                    ? "#07A84E"
                                    : project.status === "discontinued" ||
                                      project.status === "cancelled"
                                    ? "#f75f18"
                                    : project.status === "due"
                                    ? "#0070FD"
                                    : "#FFCC3D"
                                }`,
                              }}
                            >
                              {project.status === "on_hold"
                                ? "On Hold"
                                : this.capitalizeFirstLetter(project.status)}
                            </p>
                            <img
                              onClick={this.updateStatus}
                              style={{
                                marginLeft: "10px",
                                cursor: "pointer",
                              }}
                              width={15}
                              height={15}
                              src={editIcon}
                              alt="edit"
                            ></img>
                          </div>
                        </div> */}
                      </div>
                    </div>
                    <div></div>
                  </div>
                  {/* <div>
                    <img
                      style={{ borderRadius: "5px" }}
                      src={
                        project?.image?.length > 0
                          ? getCloudinaryImage(
                              100,
                              100,
                              project.image[0],
                              "c_fill"
                            )
                          : pksLogo
                      }
                      alt="logo"
                    />
                  </div> */}
                  {/* <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      paddingBottom: "2%",
                      gap: "2%",
                    }}
                  >
                    <div
                      className="desc_heading"
                      style={{ paddingBottom: "1%" }}
                    >
                      Description
                    </div>
                    <div className="desc_sub_text">{project.desc}</div>
                  </div> */}
                  {/* <div style={{ display: "flex", flexDirection: "column" }}>
                    <div
                      className="desc_heading"
                      style={{ paddingBottom: "1%" }}
                    >
                      Tags
                    </div>

                    <div
                      style={{
                        display: "flex",
                        gap: "2%",
                        marginBottom: "1%",
                        marginTop: "0.7%",
                      }}
                      className="desc_sub_text"
                    >
                      {project?.tags.length < 0 ? (
                        <div> </div>
                      ) : (
                        project.tags.map((val) => (
                          <div
                            style={{
                              borderRadius: "30px",
                              background: "#EFEFEF",
                              padding: "5px 15px",
                            }}
                          >
                            <span>{val}</span>{" "}
                          </div>
                        ))
                      )}
                    </div>
                  </div> */}
                </div>
                {/* <div>
                  {" "}
                  <Progressbar progress={project.progress} />
                </div> */}
              </Card>
            </div>
          </Row>

          {/* <Row className="align-items-center header-div">
            <Col xs="8" className="manage-con">
              <div>
                <Breadcrumb className="">Tasks</Breadcrumb>
              </div>
            </Col>
            <Col xs="4" className="manage-con flexy-content flexEnd">
              {project.status === "active" && (
                <div>
                  <Button
                    color="primary"
                    size="sm"
                    className="button-wrapping"
                    onClick={() => this.redirectToAddRequest()}
                  >
                    Add Task
                  </Button>
                </div>
              )}

            </Col>
          </Row> */}

          <div className="twoContainer">
            <div className="left"  style={{ border:"1px solid #dadce0",borderRadius:"6px"}}>
              <div className="top_container_hero">
                <div className="breadcrumb" style={{ fontWeight: "500", fontSize: "18px", color: "#303030" }}>Tasks</div>
                <div className="top_right">
                  <div
                    style={{
                      color: "#338180",
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                    onClick={() => {this.setState({open:true})}}
                  >
                    + Add
                  </div>
                  <div
                    style={{
                      color: "#338180",
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                    onClick={this.redirectToListWithQuery}
                  >
                    View All
                  </div>
                </div>
              </div>
              <div style={{ padding: " 0% 3%" }}>
                <Row>
                  <div className="col new-table">
                    <Card className="shadow card_with_crm">
                      <Table
                        className="align-items-center table-flush"
                        responsive
                      >
                        <thead className="thead-light">
                          <tr>
                            <th
                              style={{ width: "15%", borderColor: "#f5f5f5" }}
                              scope="col"
                              className="crm ptb20"
                            >
                              Name
                            </th>

                            {/* <th style={{ width: "15%" }} scope="col">
                            Start
                          </th> */}
                            {/* <th style={{ width: "15%" }} scope="col">
                            Actual Start
                          </th> */}
                            <th
                              className="ptb20"
                              style={{ width: "15%", borderColor: "#f5f5f5" }}
                              scope="col"
                            >
                              Due On
                            </th>
                            {/* <th style={{ width: "15%" }} scope="col">
                            Assigned To
                          </th> */}
                            {/* <th style={{ width: "15%" }} scope="col">
                            Progress
                          </th> */}
                            <th
                              className="ptb20"
                              style={{ width: "15%", borderColor: "#f5f5f5" }}
                              scope="col"
                            >
                              Status
                            </th>
                            <th
                              className="ptb20"
                              style={{
                                width: "4%",
                                cursor: "pointer",
                                fontWeight: "500",
                                color: "#338180",
                                borderColor: "#f5f5f5",
                              }}
                              scope="col"
                              onClick={this.redirectToListWithQuery}
                            >
                              {tasks.length ? <div></div> : <></>}
                            </th>
                          </tr>
                        </thead>
                        {tasks && tasks.length !== 0 ? (
                          <tbody>
                            {tasks.map((task, i) => (
                              <tr key={i}>
                                <td
                                  className="cursor-point capitalize ptb20"
                                  onClick={() =>
                                    task.status === "accepted" &&
                                    this.props.history.push(
                                      `/admin/requests/detail/${task?.request_id}`
                                    )
                                  }
                                  style={{ width: "100px" }}
                                >
                                  <div className="project_list_content">
                                    {this.capitalizeFirstLetter(task.name)}
                                  </div>
                                </td>
                                {/* <td>
                                <div className="project_list_content">
                                  {" "}
                                  {moment(task.start_date).format(
                                    "DD MMM, YYYY"
                                  )}
                                </div>
                              </td> */}

                                {/* <td>
                                <div className="project_list_content">
                                  {" "}
                                  {moment(task.actual_start).format(
                                    "DD MMM, YYYY"
                                  )}
                                </div>
                              </td> */}
                                <td className="ptb20">
                                  <div className="project_list_content">
                                    {" "}
                                    {showUtcDate(task.end_date)}
                                  </div>
                                </td>

                                {/* <td>
                                <div className="project_list_content">
                                  {" "}
                                  {this.capitalizeFirstLetter(
                                    task.sub_contractor?.name
                                  )}{" "}
                                </div>
                              </td> */}
                                {/* <td
                                className={
                                  task?.invoice?.status === "paid" ||
                                  task?.invoice?.status === "assigned"
                                    ? "text-capitalize status_accepted general_status_style"
                                    : task?.invoice?.status === "cancelled" ||
                                      task?.invoice?.status === "rejected"
                                    ? "text-capitalize red general_status_style"
                                    : task?.invoice?.status === "created" ||
                                      task?.invoice?.status === "pending"
                                    ? "text-capitalize yellow general_status_style"
                                    : task?.invoice?.status === "completed"
                                    ? "text-capitalize status_completed general_status_style"
                                    : task?.invoice?.status === "closed"
                                    ? "text-capitalize primary general_status_style"
                                    : "text-capitalize"
                                }
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "4%",
                                  }}
                                >
                                  {" "}
                                  <div>
                                    {" "}
                                    <Progress
                                      color="#0A8080"
                                      value={task.progress}
                                    />
                                  </div>
                                  <div className="project_list_percentage">
                                    {task.progress ?? 0}%
                                  </div>
                                </div>
                              </td> */}

                                <td className="ptb20">
                                  <div
                                    className="project_list_status"
                                    style={{
                                      color: `${
                                        task.status === "completed" ||
                                        task.status === "approved"
                                          ? "#07A84E"
                                          : task.status === "pending"
                                          ? "#FF4949"
                                          : task.status === "due"
                                          ? "#0070FD"
                                          : task.status === "rejected"
                                          ? "#F70e0e"
                                          : "#FFCC3D"
                                      }`,
                                    }}
                                  >
                                    {" "}
                                    {task.status === "in_progress"
                                      ? "In Progress"
                                      : this.capitalizeFirstLetter(task.status)}
                                  </div>
                                </td>

                                <td
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-end",
                                    // justifyContent: "center",
                                    borderTop: "none",
                                    gap: "10px",
                                   borderBottom:"1px solid #f5f5f5"
                                  }}
                                  className="ptb20"
                                >
                                  <div>
                                    <img
                                      onClick={() =>
                                        this.setState({
                                          isTimeLineOpen: true,
                                          task_id: task.task_id,
                                        })
                                      }
                                      style={{ cursor: "pointer" }}
                                      src={projectTimeline}
                                      alt="timeline"
                                    ></img>
                                  </div>
                                  {task.status !== "approved" && (
                                    <div className="project_list_content">
                                      {" "}
                                      <UncontrolledDropdown
                                        nav
                                        className="flexy-content position_u"
                                      >
                                        <DropdownToggle
                                          className="pr-0 nav-notice"
                                          nav
                                        >
                                          <Media className="align-items-center">
                                            <img
                                              src={showMore}
                                              alt="show more"
                                            />
                                          </Media>
                                        </DropdownToggle>
                                        <DropdownMenu
                                          className="dropdown-menu-arrow min-width-drop"
                                          right
                                        >
                                          {task.status === "completed" ? (
                                            <>
                                              <DropdownItem
                                                // onClick={() =>
                                                //   this.approveOrReject(
                                                //     "approved",
                                                //     task.task_id
                                                //   )
                                                // }
                                                onClick={() =>
                                                  this.onReasonOpen(
                                                    task,
                                                    "approved"
                                                  )
                                                }
                                              >
                                                <span>Approve</span>
                                              </DropdownItem>
                                              <DropdownItem
                                                onClick={() =>
                                                  this.onReasonOpen(
                                                    task,
                                                    "rejected"
                                                  )
                                                }
                                              >
                                                <span>Reject</span>
                                              </DropdownItem>
                                            </>
                                          ) : (
                                            <>
                                              <DropdownItem
                                                onClick={() =>
                                                  this.editTask(
                                                    task,
                                                    task.task_id
                                                  )
                                                }
                                              >
                                                <span>Edit</span>
                                              </DropdownItem>
                                            </>
                                          )}
                                        </DropdownMenu>
                                      </UncontrolledDropdown>
                                    </div>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        ) : (
                          <tbody>
                            <tr>
                              <td className="no-data">No Tasks Available</td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                          </tbody>
                        )}
                      </Table>
                    </Card>
                  </div>
                </Row>
              </div>
            </div>
            <div className="right"   style={{ border:"1px solid #dadce0",borderRadius:"6px"}}>
              <Details_change_orders projectId= {this.state.projectId} props={this}/>
            </div>
          </div>

          <div className="twoContainer">
            <div className="left"  style={{ border:"1px solid #dadce0",borderRadius:"6px"}}>
              <div className="top_container_hero">
                <div className="breadcrumb" style={{ fontWeight: "500", fontSize: "18px", color: "#303030" }}>Payments</div>
                <div
                  style={{
                    color: "#338180",
                    fontSize: "14px",
                    cursor: "pointer",
                  }}
                  onClick={this.viewPayments}
                >
                  View All
                </div>
              </div>
              <div style={{ padding: "0% 3%" }}>
                <Row>
                  <div className="col new-table">
                    <Card className="shadow card_with_crm">
                      <Table
                        className="align-items-center table-flush"
                        responsive
                      >
                        <thead className="thead-light">
                          <tr>
                            <th
                              style={{ width: "15%" }}
                              scope="col"
                              className="ptb20"
                            >
                              Name
                            </th>

                            {/* <th style={{ width: "15%" }} scope="col">
                            Start
                          </th> */}
                            {/* <th style={{ width: "15%" }} scope="col">
                            Actual Start
                          </th> */}
                            <th
                              className="ptb20"
                              style={{ width: "15%" }}
                              scope="col"
                            >
                              Due On
                            </th>
                            {/* <th style={{ width: "15%" }} scope="col">
                            Assigned To
                          </th> */}
                            {/* <th style={{ width: "15%" }} scope="col">
                            Progress
                          </th> */}
                            <th
                              className="ptb20"
                              style={{ width: "15%" }}
                              scope="col"
                            >
                              Status
                            </th>
                            <th
                              style={{
                                width: "4%",
                                cursor: "pointer",
                                fontWeight: "500",
                                color: "#338180",
                              }}
                              className="ptb20"
                              scope="col"
                              onClick={this.redirectToListWithQuery}
                            >
                              {<div></div>}
                            </th>
                          </tr>
                        </thead>
                        {payments && payments.length !== 0 ? (
                          <tbody></tbody>
                        ) : (
                          <tbody>
                            <tr>
                              <td className="no-data ptb20">
                                No Payments Available
                              </td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                          </tbody>
                        )}
                      </Table>
                    </Card>
                  </div>
                </Row>
              </div>
            </div>
            <div className="right"  style={{ border:"1px solid #dadce0",borderRadius:"6px"}}>
              <div className="right_switchable ">
                <div className="switchable_slides">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "1%",
                    }}
                  >
                    <div className="breadcrumb" style={{ fontWeight: "500", fontSize: "18px", color: "#303030" }} >{selectedContent}</div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "20px",
                      }}
                     
                    >
                      {(selectedContent === "Documents" ||
                        selectedContent === "Photos") && (
                        <div
                          style={{
                            color: "#338180",
                            fontSize: "14px",
                            cursor: "pointer",
                          }}
                          onClick={this.addDocumentsOrPhotos}
                        >
                          + Add
                        </div>
                      )}
                      <div
                        style={{
                          color: "#338180",
                          fontSize: "14px",
                          cursor: "pointer",
                        }}
                        onClick={this.viewAll}
                      >
                        View All
                      </div>
                    </div>
                    {/* <div
                      style={{
                        color: "#338180",
                        fontSize: "14px",
                        cursor: "pointer",
                      }}
                    >
                      View All
                    </div> */}
                  </div>
                  {selectedContent === "Comments" && (
                    <>
                      <div
                        className="crm_comments"
                        style={{ marginTop: "20px" }}
                      >
                        {comments.length
                          ? comments.map((obj) => (
                              <div className="crm_individual_comments">
                                <div>
                                  <img
                                    width="50px"
                                    height="50px"
                                    src={
                                      project?.image?.length > 0
                                        ? getCloudinaryImage(
                                            100,
                                            100,
                                            project.image[0],
                                            "c_fill"
                                          )
                                        : pksLogo
                                    }
                                    alt="logo"
                                  ></img>
                                </div>
                                <div className="crm_comment_content">
                                  <div className="crm_comment_date">
                                    {moment(obj.date)
                                      .startOf("day")
                                      .format("DD MMMM")}
                                  </div>
                                  <div className="crm_comment_date">
                                    {obj.content}
                                  </div>
                                </div>
                              </div>
                            ))
                          : EmptyMessage}
                      </div>
                      <div className="crm_comments_input">
                        <div>
                          <img
                            width="40px"
                            height="40px"
                            src={
                              project?.image?.length > 0
                                ? getCloudinaryImage(
                                    100,
                                    100,
                                    project.image[0],
                                    "c_fill"
                                  )
                                : pksLogo
                            }
                            alt="logo"
                          ></img>
                        </div>
                        <div className=" comment_footer">
                          <div className="ft_1">
                            <textarea
                              ref={this.textareaRef}
                              style={{ height: this.state.textAreaHeight }}
                              onInput={this.update}
                              value={this.state.inputMessage}
                              onKeyDown={this.handleKeyDown}
                              placeholder="Type here..."
                            />
                            <div className="ft_img">
                              {this.state.messageSendloader ? (
                                // <Spinner color="#012a4d">Loading...</Spinner>
                                <div className="image-loading spinner-cut">
                                  <Spinner
                                    style={{ width: "30px", height: "30px" }}
                                    type="border"
                                    color="#012A4D"
                                  />
                                </div>
                              ) : (
                                <img
                                  style={{ cursor: "pointer" }}
                                  onClick={this.sendMessage}
                                  src={sendChat}
                                  alt="chat"
                                ></img>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {selectedContent === "Photos" &&
                    (photos.length ? <>sdsd</> : EmptyMessage)}
                  {selectedContent === "Documents" &&
                    (photos.length ? <>sdsd</> : EmptyMessage)}
                </div>
                <div className="contents_listing">
                  {content.map((obj, index) => (
                    <div
                      onClick={() => this.selectContent(index)}
                      style={{
                        backgroundColor: obj.selected
                          ? "rgb(250, 250, 250)"
                          : "",
                      }}
                      className="content_img"
                    >
                      <img src={obj.img} alt="content"></img>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <Card className={open ? "exportSideBar" : "toggle-sidebar"}>
            {open && (
              <AddProject
                props={this}
                taskId={task_id}
                projectId={project_id}
                close={() => this.onClose()}
                type="task"
              />
            )}
          </Card>

          <Card className={edit_project ? "exportSideBar" : "toggle-sidebar"}>
            {edit_project && (
              <AddProject
                type="project"
                props={this}
                projectId={project_id}
                close={() => this.onClose()}
              />
            )}
          </Card>

          <Card className={upd_btn ? "exportSideBar" : "toggle-sidebar"}>
            {upd_btn && (
              <UpdateStatus
                id={project_id}
                closeUpdateModal={this.closeUpdateModal}
                props={this}
              />
            )}
          </Card>

          <Card className={notesOpen ? "exportSideBar" : "toggle-sidebar"}>
            {notesOpen && (
              <RejectNotes
                props={this}
                closeNotesModal={this.closeRejectNotes}
              ></RejectNotes>
            )}
          </Card>

          <Card
            className={
              this.state.isTimeLineOpen ? "exportSideBar" : "toggle-sidebar"
            }
          >
            {this.state.isTimeLineOpen && (
              <TimeLine
                id={this.state.task_id}
                closeTimelineModal={this.closeTimeline}
                from={"task"}
              ></TimeLine>
            )}
          </Card>

          <Card
            className={
              openAddDocumentsOrPhotos ? "exportSideBar" : "toggle-sidebar"
            }
          >
            {openAddDocumentsOrPhotos && (
              <AddPhotosDocuments
                selectedPannel={selectedContent}
                photDocClose={() =>
                  this.setState({ openAddDocumentsOrPhotos: false })
                }
              ></AddPhotosDocuments>
            )}
          </Card>
        </Container>
      </>
    );
  }
}

export default ProjectDetail;
