import {
  Card,
  CardBody,
  FormGroup,
  Row,
  Col,
  Container,
  CardFooter,
  Spinner,
} from "reactstrap";
import React, { Component } from "react";
import ApiService from "constants/ApiService";
import Lottie from "react-lottie";
import Verify from "../../assets/Lotties/verifyEmail.json";
import VerifyFailure from "../../assets/Lotties/failureLottie.json";
import VerifySuccess from "../../assets/Lotties/verifySuccess.json";
import ToastList from "components/Toast/ToastList";
import { showSuccess } from "constants/utils";
// let path;
let searchQuery;

class VerifyEmail extends Component {
  constructor(props) {
    super(props);
    // path = this.props.location?.pathname;
    searchQuery = this.props.location?.search;
    this.state = {
      email: this.props.location.email_id,
      signUp: this.props.location?.signUp,
      counter: 59,
      verifyCounter: 8,
      successModal: false,
      loadingModule: false,
      verfiedEmail: "",
      toast: false,
      response: "",
      toastType: "",
    };
  }
  componentDidMount = () => {
    const { counter, email, verifyCounter, signUp } = this.state;
    if (searchQuery) {
      setInterval(() => {
        if (verifyCounter === 0 || verifyCounter < 0) return;
        else this.setState({ verifyCounter: this.state.verifyCounter - 1 });
      }, 1000);
    } else {
      setInterval(() => {
        if (counter === 0 || counter < 0) return;
        else this.setState({ counter: this.state.counter - 1 });
      }, 1000);
    }
    if (!email && !searchQuery) this.props.history.push("/login");
    if (searchQuery) {
      this.setState({
        loadingModule: true,
      });
      setTimeout(() => this.getQueryParams(), 2500);
    }
    if (signUp !== "signUp") this.sendEmail();
  };
  componentWillUnmount = () => {
    clearInterval();
  };
  getQueryParams = async () => {
    let queryParams = new URLSearchParams(searchQuery);
    let email_ = queryParams.get("email");
    let token_ = queryParams.get("token");
    let data = {
      email: email_,
      token: token_,
    };
    let response = await ApiService.postAPI(
      "auth/contractor/is_verified",
      data
    );
    if (response.success) {
      this.setState({
        successModal: true,
        loadingModule: false,
        verfiedEmail: "success",
      });
    } else {
      this.setState({
        successModal: false,
        loadingModule: false,
        verfiedEmail: "success",
      });
    }
  };
  sendEmail = async (key) => {
    let { email } = this.state;
    let data = {
      email,
    };
    let response = await ApiService.postAPI("auth/contractor/verify", data);
    if (response.success) {
      key && showSuccess(this, response.message);
    }
  };
  redirectLoginPage = () => {
    this.props.history.push("/login");
  };
  // Toast Handler
  render() {
    let {
      counter,
      verifyCounter,
      successModal,
      signUp,
      loadingModule,
      verfiedEmail,
      toast,
      response,
      toastType,
    } = this.state;
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: Verify,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    const SuccessVerifyOptions = {
      loop: true,
      autoplay: true,
      animationData: VerifySuccess,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    const FailureVerifyOptions = {
      loop: true,
      autoplay: true,
      animationData: VerifyFailure,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    return (
      <>
        {" "}
        <div className="main-content new-pro-design w_100">
          <Container
            className={
              searchQuery
                ? "success-verify-max-width"
                : "verify-email-max-width reset-push-top"
            }
          >
            {toast && <ToastList message={response} type={toastType} />}
            <Row className="justify-content-center">
              <Col lg="5" md="7" className="login-flexing ">
                <Card className="bg-secondary shadow_div border-0 radius-log-verify">
                  {searchQuery ? (
                    <>
                      <CardBody className="px-lg-5  pad-log-bot-verify pad-left-right-2">
                        <div className="profile-body">
                          <Row>
                            <Col lg="12" className="mt-5 verify-flex">
                              {loadingModule && (
                                <>
                                  <div className="spin-loading-profile-wait">
                                    <Spinner type="border" color="#077e8b" />
                                  </div>
                                  <label className="info-text">
                                    Please Wait. We are verifying your email.
                                  </label>
                                </>
                              )}
                              {successModal && verfiedEmail === "success" && (
                                <>
                                  <Lottie
                                    options={SuccessVerifyOptions}
                                    height={170}
                                    width={170}
                                  />
                                  <label className="verify-text mt-4 mb-0">
                                    Well Done!
                                  </label>
                                  <FormGroup className="margin-level-verify">
                                    <label
                                      className="label-verify mb-3 mt-3"
                                      htmlFor="input-username"
                                    >
                                      Your Email has been verified successfully
                                    </label>
                                  </FormGroup>
                                </>
                              )}
                              {!successModal && verfiedEmail === "success" && (
                                <>
                                  <Lottie
                                    options={FailureVerifyOptions}
                                    height={170}
                                    width={170}
                                  />
                                  <label className="verify-text mt-4 mb-0">
                                    Oops!
                                  </label>
                                  <FormGroup className="margin-level-verify">
                                    <label
                                      className="label-verify mb-3 mt-3"
                                      htmlFor="input-username"
                                    >
                                      Your Email verification process has
                                      failed! Please try again.
                                    </label>
                                  </FormGroup>
                                </>
                              )}
                            </Col>
                          </Row>
                        </div>
                        <div className="line-divider"></div>
                      </CardBody>
                      {!loadingModule && (
                        <CardFooter className="text-center foot-verify">
                          <label className="receive-email mb-0">
                            You will be redirected to login page{" "}
                            {verifyCounter > -1 ? (
                              <span className="disable-span">
                                {" "}
                                in 00:
                                {verifyCounter < 10
                                  ? `0${verifyCounter}`
                                  : verifyCounter}
                              </span>
                            ) : (
                              this.redirectLoginPage()
                            )}
                          </label>
                        </CardFooter>
                      )}{" "}
                    </>
                  ) : (
                    <>
                      {" "}
                      <CardBody className="px-lg-5  pad-log-bot-verify pad-left-right-2">
                        <div className="profile-body">
                          <Row>
                            <Col lg="12" className="mt-5 verify-flex">
                              <Lottie
                                options={defaultOptions}
                                height={150}
                                width={150}
                              />
                              <label className="verify-text mt-4 mb-0">
                                Verify your Email
                              </label>
                              <FormGroup className="margin-level-verify">
                                <label
                                  className="label-verify mb-3 mt-3"
                                  htmlFor="input-username"
                                >
                                  To complete your{" "}
                                  {signUp === "signUp" ? "signup" : "login"} and
                                  start your contact with parknstays you need to
                                  verify the link sent to your email Id.
                                </label>
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>
                        <div className="line-divider"></div>
                      </CardBody>
                      <CardFooter className="text-center foot-verify d_flex_align_justify">
                        <label className="receive-email mb-0">
                          Didn’t receive an email?{" "}
                          {counter > 0 ? (
                            <span className="disable-span">
                              {" "}
                              Resend in 00:
                              {counter < 10 ? `0${counter}` : counter}
                            </span>
                          ) : (
                            <span onClick={() => this.sendEmail("email")}>
                              Resend
                            </span>
                          )}
                        </label>
                      </CardFooter>{" "}
                    </>
                  )}
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}
export default VerifyEmail;
