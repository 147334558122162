import React, { Component } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Modal,
  Breadcrumb,
  Table,
  Col,
  Spinner,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
// core components
import ApiService from "constants/ApiService";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getUSFormat } from "constants/utils";
import { getCloudinaryImage } from "constants/utils";
import { uploadCloudinary } from "constants/utils";
import defaultProfile from "../../assets/img/brand/defaultUser.svg";
import Lottie from "react-lottie";
import DateSvg from "../../assets/img/brand/date-pick.svg";
import Remove from "../../assets/img/brand/remove.svg";
import AnimateData from "../../assets/Lotties/successLottie.json";
import FailureData from "../../assets/Lotties/failureLottie.json";
import { replaceToNormalNumber } from "constants/utils";
import ReactTooltip from "react-tooltip";
import ToastList from "components/Toast/ToastList";
import { showError } from "constants/utils";
import { showSuccess } from "constants/utils";
import requestCall from "../../../src/assets/img/brand/reqCall.svg";
import requestEmail from "../../../src/assets/img/brand/reqEmail.svg";
import requestMarker from "../../../src/assets/img/brand/profile_marker.svg";
import ProfileCam from "../../../src/assets/img/brand/profileCam.svg";
import RatingStar from "../../../src/assets/img/brand/ratingStar.svg";
import add from "../../../src/assets/img/brand/add.svg";
import PdfImage from "../../assets/img/brand/pdf.png";
import CloudIcon from "../../assets/img/brand/cloud.svg";
import { showUtcDate } from "constants/utils";
import EyeIcon from "../../assets/img/brand/EyeIcon.svg";
import Trash from "../../assets/img/brand/Trash.svg";
import Edit from "../../assets/img/brand/editData.svg";
import DatePickerImage from "../../assets/img/brand/datePickerImage.svg";
import removeService from "../../assets/img/brand/removeService.svg";
import moment from "moment";
import MapContainer from "components/mapPopUp/GoogleMapsTest";
import info from "../../assets/img/brand/infoIcon.svg";
import AddService from "./addServiceSidedragger";
import { maxLengthCheck } from "constants/utils";
// import { data } from "jquery";
import { isInvalidEmail } from "constants/utils";
import { Button_without_Border } from "components/Buttons/Button";
import { CustomListingContainer } from "components/Container/CustomListingContainer";
import { ListingHeaderLayout } from "components/Container/CustomListingContainer";
import { ListingBodyLayout } from "components/Container/CustomListingContainer";
import { CustomInputFields } from "components/FilterFields/Filterfields";
import { CustomDropDownSearch } from "components/FilterFields/Filterfields";
import { CustomDropDown } from "components/FilterFields/Filterfields";
import { CustomSingleDatePicker } from "components/FilterFields/Filterfields";
import { CustomTextArea } from "components/FilterFields/Filterfields";
import { Radio, Select, Space } from "antd";
import { remove_underscore_capitalize } from "constants/utils";
import { Custom_Button } from "components/Buttons/Button";
import closeIcon from "../../assets/img/icons/closeIcon.svg";
import CustomSpinner from "components/CustomSpinner/CustomSpinner";
import profileCamera from "../../assets/img/new Logo/profileCamera.svg";
const types = [
  {
    name: "company",
  },
  {
    name: "individual",
  },
];
const { Option } = Select;
class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: {},
      name: "",
      email: "",
      dub_email: "",
      dub_phoneNumber: "",
      phone: "",
      curPassword: "",
      password: "",
      newPassword: "",
      type: "password",
      pType: "password",
      nType: "password",
      modalOpen: false,
      toggleModal: false,
      image: {},
      exp_date_profile: "",
      exp_date_employer: "",
      bank_name: "",
      ref: "",
      document_type: "",
      aType: "",
      company: "",
      btnLoad: false,
      account_no: "",
      bank_info: false,
      profile_docs: {},
      employer_docs: {},
      isLoading: false,
      imageLoading: false,
      DocImageLoading: false,
      previousWorkLoading: false,
      isBankLoading: this.props.location.search ? true : false,
      renderContent: false,
      bankModal: this.props.location.search ? true : false,
      isUpdateLoading: false,
      activeTab: "1",
      website: "",
      description: "",
      business_name: "",
      dummyDoc: {},
      isProfileDoc: false,
      city: [],
      state: [],
      address_line: "",
      zip: "",
      working_from: "",
      work_experience: "",
      OpenDeleteModal: false,
      today: new Date(),
      isMapVisible: false,
      service_list: [],
      previous_works: [],
      geo: "",
      isShowPlaces: false,
      placeList: [],
      location: {
        loc: [],
        address: "",
      },
      locationData: {},
      state_id: "",
      city_id: "",
      stateList: [""],
      cityList: [""],
      passLoad: false,
      einId: "",
      addserviceModal: false,
      placeLoad: false,
      contractor_det: {},
      editEmailAndPhone: false,
      modeType: "",
      payoutStatus: "",
      error: false,
    };
  }
  componentDidMount = async () => {
    let { bankModal } = this.state;
    this.getProfile();
    // this.getServiceList();
    if (bankModal) {
      setTimeout(() => this.handleBankInfo(), 10000);
    }
    this.goToElement();
  };

  goToElement = () => {
    let url = window.location.href;
    if (url.includes("/password")) {
      this.toggleModal();
    }
  };

  getProfile = async () => {
    let self = this;
    let response = await ApiService.getAPI("contractor");
    if (response.success) {
      localStorage.setItem("contractor_details", JSON.stringify(response.data));
      this.setState(self.returnProfileData(response), () => {
        setTimeout(() => {
          this.setState({
            bankModal: false,
          });
          this.props.location.search &&
            this.props.history.push("/admin/user-profile");
        }, 13000);
      });
    }
  };

  EnDisableServices = () => {
    let cont_set = JSON.parse(localStorage.getItem("contractor_details"));
    const services = this.state.service_list;
    const profile_services = cont_set.contractor.service_list;
    profile_services.forEach((el) => {
      if (el.status === "pending") {
        services.push(el);
      }
    });
    services.map((el) => {
      el.isEnabled = false;

      profile_services.forEach((ele) => {
        if (ele.name == el.name && ele.status == "approved") {
          el.isEnabled = !el.isEnabled;
        }
      });
    });

    this.setState({ service_list: services });
  };

  getServiceList = async () => {
    let queryparams = `?status=approved`;
    let response = await ApiService.getAPI(`contractor/services${queryparams}`);
    if (response.success) {
      this.setState({ service_list: response?.data?.services }, () => {
        this.EnDisableServices();
      });
    }
  };

  returnProfileData = (response) => {
    let profile = response.data.contractor;
    let address = profile.address;
    let loc = profile.loc;
    let { name, phone, email, image } = profile;
    let stripe = profile?.stripe;
    let docs = profile?.docs;
    this.setState(
      {
        profile: response.data.contractor,
        name,
        phone: getUSFormat(phone?.national_number),
        email,
        dub_email: email,
        dub_phoneNumber: getUSFormat(phone?.national_number),
        image,
        bank_name: stripe?.name,
        account_no: stripe?.ac_number,
        ref: stripe?.route_number,
        bank_info: stripe?.is_bank === true ? true : false,
        aType: profile.type,
        business_name: profile?.business_name ?? "",
        docs: profile.docs && profile.docs,
        profile_docs: docs && docs.profile,
        employer_docs: docs && docs.employer,
        website:
          profile.business_profile.website && profile.business_profile.website,
        previous_works:
          profile.business_profile.previous_works &&
          profile.business_profile.previous_works,
        description:
          profile.business_profile &&
          profile.business_profile.description &&
          profile.business_profile.description,
        dummyDoc: null,
        address_line: address?.line_1,
        city_id: address?.city?.city_id ?? "",
        city: address?.city?.city ?? "",
        state: address?.state?.state ?? "",
        state_id: address?.state.state_id ?? "",
        zip: address?.zip,
        payoutStatus: stripe?.payout_enabled
          ? "Enabled"
          : "Information Required",
        locationData: {
          loc,
        },
        work_experience:
          profile?.business_profile?.working_from &&
          this.workingChangeDate(
            new Date(profile.business_profile.working_from)
          ),

        working_from:
          profile.business_profile.working_from &&
          new Date(profile.business_profile.working_from),
        einId: profile.ein,
      },
      () => {
        this.getServiceList();
        this.getStates();
        // this.getCityList();
      }
    );
  };

  handleInputChange = (e, from) => {
    if (from === "einId") {
      this.setState({
        [from]: e.replace(/[^\w\s]/gi, "").toUpperCase(),
        error: false,
      });
    } else {
      this.setState({ [from]: e, error: false });
    }
  };

  returnDate = (docObj) => {
    let key = "expiry_date";
    return docObj && docObj[key] ? new Date(docObj[key]) : "";
  };

  handleOnPhoneNumberChange = (evt, from) => {
    this.setState({ [from]: evt, error: false });
  };

  handleTypeInputChange = (e) => {
    this.setState({ aType: e, einId: "" });
    if (e === "individual") {
      this.setState({ business_name: "", website: "" });
    }
  };

  updateChanges = async () => {
    let {
      name,
      email,
      phone,
      image,
      aType,
      city,
      state,
      zip,
      address_line,
      business_name,
      service_list,
      description,
      locationData,
    } = this.state;
    this.setState({ error: true });
    if (name === "") {
      showError(this, "Please enter your name");
    } else if (email === "") {
      showError(this, "Please enter your email");
    } else if (phone === "" || phone.length < 16) {
      showError(this, "Please enter your phone number");
    } else if (aType === "") {
      showError(this, "Please select a type");
    } else if (
      aType === "company" &&
      (business_name === "" ||
        business_name === null ||
        business_name === undefined)
    ) {
      showError(this, "Please enter valid business name");
    } else if (
      description === "" ||
      description === null ||
      description === undefined
    ) {
      showError(this, "Please fill about field");
    } else if (
      this.state.address_line === "" ||
      address_line === undefined ||
      address_line === null
    ) {
      showError(this, "Please enter  address");
    } else if (state === "" || state === undefined || state === null) {
      showError(this, "Please select a state");
    } else if (city === "" || city === undefined || city === null) {
      showError(this, "Please select a city");
    } else if (zip === "" || zip === undefined || zip === null) {
      showError(this, "Please enter zip code");
    } else {
      this.setState({ error: false });
      let serv_list = [];
      if (service_list.length > 0) {
        service_list.map((el) => {
          if (el.isEnabled || el.status === "pending") {
            serv_list.push({
              name: el.name,
              service_id: el.service_id,
              status: el.status,
            });
          }
        });
      }

      let data = {
        name: name,
        email: email,
        phone: {
          country_code: "1",
          national_number: replaceToNormalNumber(phone),
        },
        image,
        type: aType,
        business_name: business_name,
        business_profile: {
          previous_works: this.state.previous_works,
          description: this.state.description,
          website: this.state.website,
          working_from: this.state.working_from && this.state.working_from,
        },
        address: {
          line_1: this.state.address_line,
          city: {
            city: this.state.city,
            city_id: this.state.city_id,
          },
          state: {
            state: this.state.state,
            state_id: this.state.state_id,
          },
          zip: this.state.zip,
        },
        loc: locationData.loc,
        ein: this.state.einId,
        service_list: serv_list,
      };

      this.setState({ isUpdateLoading: true }, () => {
        this.updateContractor(data);
      });
    }
  };

  updateEmailAndPhoneNumber = async () => {
    let { dub_email, dub_phoneNumber, modeType } = this.state;
    if (modeType === "email") {
      if (!dub_email) {
        showError(this, "Please enter email address");
      } else if (isInvalidEmail(dub_email)) {
        showError(this, "Please enter valid email address");
      } else {
        this.setState({ btnLoad: true });
        let data = {
          email: dub_email,
        };
        let response = await ApiService.patchAPI(
          "contractor/email_update",
          data
        );
        if (response.success) {
          this.setState({ btnLoad: false });
          showSuccess(this, response.message);
          this.handleCancel();
          this.getProfile();
        }
      }
    } else {
      if (!dub_phoneNumber) {
        showError(this, "Please enter phone number");
      } else if (dub_phoneNumber.length < 16) {
        showError(this, "Please enter valid phone number");
      } else {
        this.setState({ btnLoad: true });
        let data = {
          phone: {
            country_code: "91",
            national_number: replaceToNormalNumber(dub_phoneNumber),
          },
        };
        let response = await ApiService.patchAPI(
          "contractor/phone_update",
          data
        );
        if (response.success) {
          this.setState({ btnLoad: false });
          showSuccess(this, response.message);
          this.handleCancel();
          this.getProfile();
        }
      }
    }
  };

  updateContractor = async (data) => {
    let response = await ApiService.patchAPI("contractor", data);
    if (response.success) {
      showSuccess(this, response.message);
      this.setState({ OpenDeleteModal: false, isUpdateLoading: false });
      setTimeout(() => window.location.reload(), 2000);
    } else {
      this.setState({ isUpdateLoading: false });
      showError(this, response.message);
    }
  };

  handleBankInfo = () => {
    this.setState({
      isBankLoading: false,
      renderContent: true,
    });
    this.getProfile();
  };
  toggleModal = () => {
    this.setState({ modalOpen: !this.state.modalOpen, link: false });
  };
  toggleEyeIcon = (key) => {
    if (key === "curPass") {
      this.setState({
        type: this.state.type === "password" ? "text" : "password",
      });
    } else if (key === "pass") {
      this.setState({
        pType: this.state.pType === "password" ? "text" : "password",
      });
    } else if (key === "newPass") {
      this.setState({
        nType: this.state.nType === "password" ? "text" : "password",
      });
    }
  };
  handleChangePassword = async () => {
    let { curPassword, password, newPassword } = this.state;
    if (curPassword === "") {
      showError(this, "Please enter current password");
    } else if (password === "") {
      showError(this, "Please enter new password");
    } else if (password.length <= 6) {
      showError(
        this,
        "New password length should be greater than six characters"
      );
    } else if (newPassword === "") {
      showError(this, "Please enter confirm password");
    } else if (password !== newPassword) {
      showError(this, "New password and confirm password does not match");
    } else {
      this.setState({ passLoad: true });
      let data = {
        current_password: curPassword,
        new_password: password,
      };
      let response = await ApiService.patchAPI("contractor/changepwd", data);
      if (response.success) {
        this.setState({ passLoad: false });
        showSuccess(this, response.message);
        this.setState({ modalOpen: false });
      } else {
        this.setState({ passLoad: false });
        showError(this, response.message);
      }
    }
  };
  onImageChangeHandler = async (e) => {
    let self = this;
    let { image } = this.state;
    if (e.target.files.length > 0) {
      let file = e.target.files[0];
      this.setState({ imageLoading: true });
      if (
        file.type === "image/png" ||
        file.type === "image/jpg" ||
        file.type === "image/jpeg"
      ) {
        if (file.size < 1000000) {
          let data = await uploadCloudinary(file, "contractor");
          if (data.url) {
            self.setState(
              {
                image: {
                  format: data.format,
                  public_id: data.public_id,
                  version: `v${data.version}`,
                  type: "image",
                },
                imageLoading: false,
              },
              // () => {
              //   this.updateChanges();
              // }
            );
          } else {
            showError(this, "Could not upload image");
            this.setState({ imageLoading: false });
          }
        } else {
          showError(this, "File size should not exceeds 1 MB");
          this.setState({ imageLoading: false });
        }
      } else {
        showError(this, "Invalid Format");
        this.setState({ imageLoading: false });
      }
    }
  };

  toggleCloseModal = () => {
    this.setState({
      modalOpen: false,
      curPassword: "",
      password: "",
      newPassword: "",
    });
  };
  toggleBankModal = () => {
    this.setState({
      bankModal: true,
      isBankLoading: true,
    });
  };
  toggleBankCloseModal = () => {
    this.setState({
      bankModal: false,
    });
  };
  handleLinkAccount = async () => {
    let data = {
      refresh_url: `${window.location.href}?status=success`,
      return_url: `${window.location.href}?status=success`,
    };
    this.setState({
      isLoading: true,
    });
    setTimeout(() => this.setState({ isLoading: false }), 6000);
    let response = await ApiService.postAPI("contractor/link_bank", data);
    if (response.success) {
      window.open(response.data.link, "_self");
    } else {
      showError(this, response.message);
    }
  };
  uploadDocument = (isProfileDoc, actDoc) => {
    this.setState({
      toggleModal: true,
      dummyDoc: actDoc,
      isProfileDoc: isProfileDoc,
    });
  };

  closeDocumentModel = (type, isdataAvailable) => {
    this.setState({
      dummyDoc: null,
      toggleModal: false,
    });
  };

  onChangeDate = (date) => {
    let dummyDoc = this.state.dummyDoc;
    dummyDoc = dummyDoc ? dummyDoc : {};
    dummyDoc["expiry_date"] = date;
    this.setState({ dummyDoc: dummyDoc });
  };
  workingChangeDate = (date) => {
    let experience;
    const TOTALMONTHS = 12;
    if (date) {
      this.setState({ working_from: date });
      let selectedDate = moment(date);
      let today = moment(new Date());
      let result = today.diff(selectedDate, "months");
      if (result < TOTALMONTHS) {
        experience = `${result} ${result > 1 ? "months" : "month"}  `;
      } else if (result > TOTALMONTHS) {
        let month = result % TOTALMONTHS;
        experience =
          result &&
          `${Math.floor(result / TOTALMONTHS)} years  ${month > 0 ? `${month} ${month > 1 ? "months" : "month"}` : ""
          }`;
      } else {
        experience = `1 year`;
      }
      this.setState({ work_experience: experience });
    }
  };

  handlechange = (e, date) => {
    this.setState({ working_from: e, error: false });
  };

  returnDate = (docObj) => {
    let key = "expiry_date";
    return docObj && docObj[key] ? new Date(docObj[key]) : "";
  };

  onDocumentImageHandler = async (e) => {
    let file = e.target.files[0];
    if (
      file.type === "application/pdf" ||
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/jpeg"
    ) {
      if (file.size < 1000000) {
        this.setState({ DocImageLoading: true });
        let data = await uploadCloudinary(file, "documents");
        if (data) {
          let dummyDoc = this.state.dummyDoc;
          dummyDoc = dummyDoc ? dummyDoc : {};
          dummyDoc["url"] = data.url;
          dummyDoc["docType"] = data.format;
          this.setState({ DocImageLoading: false, dummyDoc });
        } else {
          showError(this, "Could not upload image");
          this.setState({ imageLoading: false });
        }
      } else {
        showError(this, "File size should not exceeds 1 MB");
        this.setState({ imageLoading: false });
      }
    } else {
      showError(this, "Please upload Image or PDF files");
      this.setState({ imageLoading: false });
    }
  };

  deleteImage = () => {
    this.setState({
      dummyDoc: {
        ...this.state.dummyDoc,
        url: "",
        docType: "",
      },
    });
  };

  onPreviousWorkUpload = async (e) => {
    let file = e.target.files[0];
    if (
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/jpeg"
    ) {
      if (file.size < 1000000) {
        this.setState({ previousWorkLoading: true });
        let data = await uploadCloudinary(file, "documents");

        if (data.url) {
          let image = {
            format: data.format,
            public_id: data.public_id,
            version: `v${data.version}`,
            type: "image",
          };
          let newArray = [...this.state.previous_works];
          newArray.push(image);
          this.setState({
            previousWorkLoading: false,
            previous_works: newArray,
          });
        } else {
          this.setState({ previousWorkLoading: false });
          showError(this, "Could not upload image");
        }
      } else {
        this.setState({ previousWorkLoading: false });
        showError(this, "File size should not exceeds 1 MB");
      }
    } else {
      showError(this, "Please upload Image files");
      this.setState({ previousWorkLoading: false });
    }
  };
  removeImage = (item, index) => {
    this.state.previous_works.map((item, i) => {
      if (index === i) {
        this.state.previous_works.splice(index, 1);
        this.setState({ previous_works: this.state.previous_works });
      }
    });
  };

  submitDocUpload = () => {
    let { isProfileDoc, dummyDoc } = this.state;
    if (!dummyDoc) showError(this, "Please complete fileds");
    else if (isProfileDoc && !dummyDoc.expiry_date) {
      showError(this, "Please select the expiry date");
    } else if (isProfileDoc && (!dummyDoc.url || dummyDoc.url === "")) {
      showError(this, "Please upload document");
    } else {
      this.setState({ toggleModal: false, dummyDoc: null });
      let fDocs = {
        profile: this.state.profile_docs,
        employer: this.state.employer_docs,
      };
      dummyDoc["createdAt"] = new Date();
      fDocs[isProfileDoc ? "profile" : "employer"] = dummyDoc;
      this.updateContractor({ docs: fDocs });
    }
  };

  toggleTab = (tabValue) => {
    this.setState({ activeTab: tabValue });
  };

  handleCheckBox = (i, item) => {
    const { service_list } = this.state;
    let index = service_list.findIndex((el) => {
      return el.service_id === item.service_id;
    });
    service_list[index].isEnabled = !item.isEnabled;
    this.setState({ service_list }, () => { });
  };

  openEditDocument = (type, docType) => {
    this.setState({
      toggleModal: true,
      isProfileDoc: type,
      dummyDoc: docType,
    });
  };

  deleteDocumentData = (type, key) => {
    this.setState(
      {
        [type]: null,
      },
      () => {
        let data = {
          docs: {
            profile: this.state.profile_docs,
            employer: this.state.employer_docs,
          },
        };
        this.updateContractor(data);
      }
    );
  };

  openDeleteModal = (value) => {
    this.setState({ OpenDeleteModal: value });
  };

  toggleModalDelete = () => {
    this.setState({ OpenDeleteModal: !this.state.OpenDeleteModal });
  };

  onMapIconClick = () => {
    this.setState({ isMapVisible: !this.state.isMapVisible });
  };

  toggleMapModal = () => {
    this.setState({ isMapVisible: false });
  };

  getPlaces = async (e) => {
    this.setState({ placeLoad: true });
    let searchTerm = e;
    this.setState({ address_line: searchTerm });
    let response = await ApiService.getAPI(`common/search?input=${searchTerm}`);
    if (response.success) {
      this.setState({
        isShowPlaces: true,
        placeLoad: false,
        placeList: response.data.place.predictions,
      });
    }
  };

  getPlaceData = async (place) => {
    this.setState({ placeLoad: true });
    let response = await ApiService.getAPI(
      `contractor/maps/place/${place.place_id}`
    );
    if (response.success) {
      let locData = response.data;
      let datas = locData;
      datas.state["state_id"] = datas.state["id"];
      datas.city["city_id"] = datas.city["id"];
      delete datas.state["id"];
      delete datas.city["id"];
      this.setState(
        {
          address_line: place.description,
          isShowPlaces: false,
          placeLoad: false,
          locationData: datas,
          state: datas?.state ? datas.state.name : "",
          state_id: datas?.state ? datas.state.state_id : "",
          zip: datas?.zip_code ? datas.zip_code : "",
        },
        () => {
          datas?.state && this.getStates();
        }
      );
    } else {
      this.setState({
        isShowPlaces: false,
      });
    }
  };

  onSave = (formatted_address, latlng) => {
    let location = this.state.location;
    location.address = formatted_address;
    location.loc = latlng;
    this.setState({ location }, () =>
      this.setState({
        geo: formatted_address,
      })
    );
    this.onMapIconClick();
  };

  handleStateInputChange = (value, option) => {
    this.setState({ state: value, state_id: option.state_id }, () => {
      this.getCityList();
    });
  };

  handleCityInputChange = (value, option) => {
    this.setState({ city: value, city_id: option.city_id }, () => {
    });
  };

  getStates = async () => {
    let { state_id } = this.state;
    let response = await ApiService.getAPI("contractor/states");
    if (response.success) {
      this.setState(
        { stateList: response.data.states },
        () => state_id && this.getCityList()
      );
    }
  };

  getCityList = async () => {
    let { state_id, locationData } = this.state;
    let id = state_id;
    let response = await ApiService.getAPI(`contractor/states/cities/${id}`);
    if (response.success) {
      this.setState(
        {
          cityList: response.data.cities,
          city: locationData?.city ? locationData?.city?.name : "",
          city_id: locationData?.city?.city_id,
        },
        () => { }
      );
    }
  };

  openAddService = () => {
    this.setState({
      addserviceModal: true,
    });
  };

  closeServiceSideDragger = () => {
    this.setState({ addserviceModal: false });
  };

  displayErrorMessage = (msg) => {
    showError(this, msg);
  };
  addServiceInList = async (service_name) => {
    let data = { service: service_name };
    let response = await ApiService.postAPI("contractor/services", data);
    setTimeout(() => {
      if (response.success) {
        showSuccess(this, response.message);
        this.closeServiceSideDragger();
        this.getProfile();
      }
    }, 1000);
  };

  camelize = (str) => {
    return str.replace(/\w+/g, (w) => {
      return w[0].toUpperCase() + w.slice(1).toLowerCase();
    });
  };
  editModal = (val) => {
    this.setState({
      modeType: val,
      editEmailAndPhone: true,
    });
  };
  handleCancel = () => {
    this.setState({
      editEmailAndPhone: false,
      modeType: "",
      dub_email: this.state.email,
      dub_phoneNumber: this.state.phone,
    });
  };
  editHandleInputChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  render() {
    let {
      name,
      email,
      phone,
      modalOpen,
      curPassword,
      password,
      newPassword,
      type,
      pType,
      nType,
      image,
      bank_name,
      ref,
      aType,
      account_no,
      bank_info,
      bankModal,
      isBankLoading,
      toast,
      response,
      toastType,
      isLoading,
      imageLoading,
      DocImageLoading,
      previousWorkLoading,
      profile_docs,
      employer_docs,
      profile,
      activeTab,
      website,
      description,
      city,
      state,
      zip,
      address_line,
      isUpdateLoading,
      isMapVisible,
      location,
      stateList,
      cityList,
      business_name,
      isShowPlaces,
      placeList,
      passLoad,
      placeLoad,
      addserviceModal,
      editEmailAndPhone,
      dub_email,
      dub_phoneNumber,
      modeType,
      btnLoad,
      payoutStatus,
      error,
      city_id
    } = this.state;
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: AnimateData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    const defaultFailureOptions = {
      loop: true,
      autoplay: true,
      animationData: FailureData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    const loading = (
      <div className="spin-loading">
        <Spinner type="border" color="#077e8b" />
      </div>
    );
    return (
      <>
        <Modal
          className="modal-dialog-centered"
          isOpen={editEmailAndPhone}
          toggle={() => this.handleCancel()}
        >
          <Card>
            <CardBody>
              <div className="flex mb-20">
                <div className="fw500 fc-b">
                  {modeType === "email"
                    ? "Update Email"
                    : "Update Phone Number"}
                </div>
                <div
                  className="clpaid x_close flexmid point fw500 font-24"
                  onClick={() => this.handleCancel()}
                >
                  <img width={20} src={closeIcon} alt="close" />
                </div>
              </div>
              <div className="mb-30">
                {/* <label className="f13 fw500 clA0A6AD">Email*</label> */}
                <label className="form-control-label label-in ">
                  {modeType === "email" ? "Email" : "Phone Number"}
                </label>
                {modeType === "email" ? (
                  <Input
                    type="text"
                    name="dub_email"
                    onChange={this.editHandleInputChange}
                    value={dub_email}
                    placeholder={
                      modeType === "email" ? "Email" : "Phone Number"
                    }
                    style={{ color: "black" }}
                  />
                ) : (
                  <Input
                    type="text"
                    name="dub_phoneNumber"
                    onChange={this.editHandleInputChange}
                    value={dub_phoneNumber}
                    placeholder={
                      modeType === "email" ? "Email" : "Phone Number"
                    }
                    style={{ color: "black" }}
                  />
                )}
              </div>
              <div className="flexEnd">
                <Button_without_Border
                  style={{ marginRight: "10px" }}
                  btnLabel="cancel"
                  click={this.handleCancel}
                />
                <Custom_Button
                  btnLabel="update"
                  isPrimary
                  isLoading={btnLoad}
                  click={this.updateEmailAndPhoneNumber}
                />
              </div>
            </CardBody>
          </Card>
        </Modal>
        <Modal isOpen={this.state.toggleModal} centered>
          <>
            {" "}
            <div className="modal-header flex">
              <label className="fw500 fc-b" id="exampleModalLabel">
                Upload Document
              </label>
              <div
                className="clpaid x_close flexmid point fw500 font-24"
                onClick={() => this.closeDocumentModel()}
              >
                <img width={20} src={closeIcon} alt="close" />
              </div>
            </div>{" "}
            <div className="modal-body profile-body">
              <Row>
                <Col lg="12">
                  <FormGroup>
                    <label
                      className="form-control-label label-in mb-2"
                      htmlFor="input-username"
                    >
                      Type
                    </label>
                    <InputGroup className="input-group-alternative new-input-login">
                      <Input
                        className="form-control-alternative email-new-in invite-email-in"
                        value={
                          this.state.isProfileDoc
                            ? "Profile ID"
                            : "Employer Letter"
                        }
                        placeholder=""
                        disabled
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="12">
                  <FormGroup>
                    <label
                      className="form-control-label label-in mb-2"
                      htmlFor="input-username"
                    >
                      Expiry Date *
                    </label>
                    <div className="form-relative flexStart mb-30 ">
                      <DatePicker
                        selected={this.returnDate(this.state.dummyDoc)}
                        dateFormat="MM-dd-yyyy"
                        placeholderText=""
                        className="mark_payment_date  point"
                        ariaDescribedBy="basic-addon2"
                        onChange={this.onChangeDate}
                        minDate={new Date()}
                        name={this.returnDate(this.state.dummyDoc)}
                        id="exp_date"
                        style={{ fontWeight: "500" }}
                        showYearDropdown
                        showMonthDropdown
                      />

                      <label className="mb-remove" htmlFor="exp_date">
                        <img
                          src={DateSvg}
                          alt="Date-Picker Icon"
                          className="dateIcon point"
                        />
                      </label>
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="12">
                  <FormGroup>
                    <div className="dotted_document_div">
                      {DocImageLoading && (
                        <div className=" image_spinner mr_20 ">
                          <Spinner type="border" color="#077e8b" />
                        </div>
                      )}
                      {this.state.dummyDoc && this.state.dummyDoc["url"] ? (
                        <div className="image_relative">
                          <img
                            className="uploaded_image_div"
                            src={
                              this.state.dummyDoc.docType === "pdf"
                                ? PdfImage
                                : this.state.dummyDoc.url
                            }
                          ></img>
                          <img
                            src={Remove}
                            className="ni ni-fat-remove pdf-remove"
                            onClick={() => this.deleteImage()}
                          />
                        </div>
                      ) : (
                        <>
                          {!DocImageLoading && (
                            <>
                              <img
                                src={CloudIcon}
                                className="pdf-add-img mb-1"
                              />
                              <p className="col_primary_2 mb_0 mt_20">
                                Upload your Document or Picture
                              </p>

                              <Input
                                className="form-control-alternative form-control opac-hide pdf-add-input"
                                type="file"
                                id="imageUpload"
                                onChange={(event) =>
                                  this.onDocumentImageHandler(event)
                                }
                              />
                            </>
                          )}{" "}
                        </>
                      )}
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <div className="flexEnd" style={{ marginBottom: "20px" }}>
                <Button_without_Border
                  click={this.closeDocumentModel}
                  btnLabel="cancel"
                />
                <Custom_Button
                  isPrimary
                  click={this.submitDocUpload}
                  btnLabel="submit"
                />
              </div>
            </div>
          </>
        </Modal>
        {toast && <ToastList message={response} type={toastType} />}
        <div>
          <CustomListingContainer style={{ margin: "90px 20px 20px 20px" }}>
            <div className="relative" style={{ paddingTop: "50px" }}>
              <div className="absolute" style={{ transform: imageLoading ? "translate(28%, -65%)" : "translate(32%, -96%)" }}>
                {!imageLoading ?
                  <div className="name_profile">
                    <div className="profile_image_data_map_div ">
                      <div className="profile_page relative" style={{ width: "80%" }}>
                        {
                          !image?.public_id ? (
                            <>
                              {" "}
                              <div className="w_100 relative">
                                <img
                                  src={defaultProfile}
                                  alt="profile image"
                                  className="profile_icon" />
                              </div>
                            </>
                          ) : (
                            <div className="w_100 relative">
                              <img
                                width={"40%"}
                                src={getCloudinaryImage(120, 130, image, "c_fill")}
                                alt="profile image"
                                className="profile_icon"
                              />
                            </div>
                          )}
                        <div style={{ position: "absolute", transform: "translate(215%, -85%)" }}>
                          <>
                            <Input
                              className="ft_family upload_profile_image point"
                              type="file"
                              id="imageUpload"
                              accept="image/*"
                              onChange={(event) => this.onImageChangeHandler(event)}
                              placeholder="Enter Name"
                            />
                            <div className="d_flex_align_justify point" style={{ background: "white", borderRadius: "50%" }}>
                              <img className="profile_cam_icon" src={profileCamera} />{" "}
                            </div>
                          </>

                        </div>
                      </div>
                    </div>
                  </div>
                  :
                  <div style={{ transform: "translate(-4%, 22%)" }}>
                    <div className="image-loading profile_icon" style={{ transform: "translate(0.6rem, -3.4rem)" }}>
                      <Spinner type="border" color="#077e8b" />
                    </div>
                    <div className="d_flex_align_justify point" style={{ transform: " translate(3rem, -5.4rem)", borderRadius: "50%" }}>
                      <img className="profile_cam_icon" src={profileCamera} />{" "}
                    </div>
                  </div>
                }
              </div>
              <div className="flex align_center">
                <label className="name-profile capitalize justCenter" style={{ minWidth: "153px", maxWidth: "153px", marginLeft: "25px" }}>
                  {profile.name}
                </label>
                <div className="flexEnd" style={{ margin: "0px 24px 10px 0px" }}>
                  <Custom_Button
                    style={{ marginTop: "20px" }}
                    // isPrimary
                    btnLabel={"change_password"}
                    click={() => this.toggleModal()}
                    sec_buttonLabel={"change_password"}
                  />
                  <Custom_Button
                    style={{ marginTop: "20px", marginLeft: "10px" }}
                    isPrimary
                    btnLabel={"save_changes"}
                    click={() => this.updateChanges()}
                    isLoading={isUpdateLoading}
                  />
                </div>
              </div>
              <div className="d_flex_space_align profile_border_bottom_div" >
                <div style={{ marginLeft: "24px" }}>
                  {" "}
                  <Nav tabs className="tab_border">
                    <NavItem
                      className={
                        activeTab === "1"
                          ? "active_profile_tab col_primary_2 fw-500"
                          : "inactive_profile_tab"
                      }
                    >
                      <NavLink
                        onClick={() => {
                          this.toggleTab("1");
                        }}
                        className={
                          activeTab === "1"
                            ? "active_profile_tab col_primary_2 fw-500 f16"
                            : "inactive_profile_tab f16"
                        }
                        style={{ padding: "10px 20px" }}
                      >
                        About
                      </NavLink>
                    </NavItem>
                    <NavItem
                      className={
                        activeTab === "2"
                          ? "active_profile_tab col_primary_2 fw-500 f16"
                          : "inactive_profile_tab f16"
                      }
                    >
                      <NavLink
                        className={
                          activeTab === "2"
                            ? "active_profile_tab col_primary_2 fw-500 f16"
                            : "inactive_profile_tab f16"
                        }
                        onClick={() => {
                          this.toggleTab("2");
                        }}
                        style={{ padding: "10px 20px" }}
                      >
                        Service List
                      </NavLink>
                    </NavItem>
                    <NavItem
                      className={
                        activeTab === "3"
                          ? "active_profile_tab f16"
                          : "inactive_profile_tab f16"
                      }
                    >
                      <NavLink
                        className={
                          activeTab === "3"
                            ? "active_profile_tab f16"
                            : "inactive_profile_tab f16"
                        }
                        onClick={() => {
                          this.toggleTab("3");
                        }}
                        style={{ padding: "10px 20px" }}
                      >
                        Previous Works
                      </NavLink>
                    </NavItem>{" "}
                    <NavItem
                      className={
                        activeTab === "4"
                          ? "active_profile_tab f16"
                          : "inactive_profile_tab f16"
                      }
                    >
                      <NavLink
                        className={
                          activeTab === "4"
                            ? "active_profile_tab f16"
                            : "inactive_profile_tab f16"
                        }
                        onClick={() => {
                          this.toggleTab("4");
                        }}
                        style={{ padding: "10px 20px" }}
                      >
                        Documents
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
                <div style={{ marginRight: "24px" }} className="flexStart">
                  {activeTab === "2" && (
                    <Button_without_Border
                      style={{ marginRight: "20px" }}
                      btnLabel={"add"}
                      click={this.openAddService}
                    />
                  )}
                  {/* <Button_without_Border
                    isPrimary
                    btnLabel={"save_changes"}
                    click={() => this.updateChanges()}
                    isLoading={isUpdateLoading}
                  /> */}
                </div>
              </div>

              <TabContent
                activeTab={this.state.activeTab}
              // className="tab_content"
              >
                <TabPane tabId="1">
                  <div className="">
                    {/* <CustomListingContainer className="mb-24"> */}
                    {/* <ListingHeaderLayout label={"basic_information"}>
                      <Button_without_Border
                        btnLabel={"Change_email"}
                        click={() => this.editModal("email")}
                      />
                      <Button_without_Border
                        btnLabel={"change_phone_number"}
                        click={() => this.editModal("phoneNumber")}
                      /> */}
                    {/* </ListingHeaderLayout> */}
                    <label className="name-profile capitalize pad24p">
                      Basic Information
                    </label>
                    <ListingBodyLayout>
                      <div style={{ padding: "0px 24px 24px 24px" }}>
                        <div className="inputFields-grid1">
                          <div>
                            <div className="customProfileLabel">Name *</div>
                            <CustomInputFields
                              inputValue={name}
                              type="text"
                              change={this.handleInputChange}
                              error={error}
                              from="name"
                              placeholder={"Enter the name"}
                            />
                          </div>
                          <div>
                            <div className="customProfileLabel">Email *</div>
                            <CustomInputFields
                              inputValue={email}
                              type="email"
                              change={this.handleInputChange}
                              error={error}
                              from="email"
                              disabled
                              btnLabel={"Change"}
                              onClick={() => this.editModal("email")}
                            />
                          </div>
                          <div>
                            <div className="customProfileLabel">Phone Number *</div>
                            <CustomInputFields
                              inputValue={phone}
                              type="text"
                              change={this.handleOnPhoneNumberChange}
                              error={error}
                              from="phone"
                              disabled
                              btnLabel={"Change"}
                              onClick={() => this.editModal("phoneNumber")}
                            />
                          </div>
                          <div>
                            <div className="customProfileLabel">Type *</div>
                            <Select
                              size={"large"}
                              defaultValue="a1"
                              onChange={this.handleTypeInputChange}
                              style={{
                                width: "100%",
                              }}
                              options={[
                                "select",
                                "individual",
                                "company",
                              ].map((type) => ({
                                value: remove_underscore_capitalize(type),
                              }))}
                              value={aType}
                            />
                          </div>
                          {aType === "company" && (
                            <div>
                              <div className="customProfileLabel">
                                Business Name *
                              </div>
                              <CustomInputFields
                                inputValue={business_name}
                                type="text"
                                change={() => this.handleInputChange()}
                                error={error}
                                from="business_name"
                              />
                            </div>
                          )}
                          {aType === "company" && (
                            <div>
                              <div className="customProfileLabel">website</div>
                              <CustomInputFields
                                inputValue={website}
                                type="text"
                                change={() => this.handleInputChange()}
                                error={error}
                                from="website"
                              />
                            </div>
                          )}

                          {aType === "company" && (
                            <div>
                              <div className="customProfileLabel">EIN or Tax ID</div>
                              <CustomInputFields
                                inputValue={this.state.einId}
                                type="text"
                                change={() => this.handleInputChange()}
                                error={error}
                                from="einId"
                              />
                            </div>
                          )}

                          <div>
                            <div className="customProfileLabel">
                              Working From
                              <img
                                style={{ marginLeft: "5px" }}
                                src={info}
                                data-tip="How long you have been in this field ?"
                                data-iscapture="true"
                                data-for="workingInfo"
                                data-place="top"
                                className="point"
                              />
                              <ReactTooltip
                                place="left"
                                type="info"
                                effect="solid"
                                id="workingInfo"
                                html={true}
                              />
                            </div>

                            <CustomSingleDatePicker
                              value={this.state.working_from}
                              change={this.handlechange}
                              error={error}
                            />
                          </div>

                          <div>
                            <div className="customProfileLabel">Experience</div>
                            <CustomInputFields
                              inputValue={this.state.work_experience}
                              type="text"
                              change={this.handleInputChange}
                              error={error}
                              from="work_experience"
                            />
                          </div>
                        </div>
                        <div style={{ marginTop: "15px" }}>
                          <div className="customProfileLabel">
                            About
                            <img
                              src={info}
                              style={{ marginLeft: "5px" }}
                              data-tip="Please share some details about you and your work"
                              data-iscapture="true"
                              data-for="aboutInfo"
                              data-place="top"
                              className="point"
                            />
                            <ReactTooltip
                              place="left"
                              type="info"
                              effect="solid"
                              id="aboutInfo"
                              html={true}
                            />
                          </div>
                          <CustomTextArea
                            inputvalue={description}
                            change={this.handleInputChange}
                            from="description"
                            error={error}
                            style={{ minHeight: "115px" }}
                          />
                        </div>
                      </div>
                    </ListingBodyLayout>
                    <ListingBodyLayout>
                      <label className="name-profile capitalize" style={{ marginLeft: "24px" }}>
                        Address
                      </label>
                      <div style={{ padding: "24px" }}>
                        <div className="inputFields-grid1">
                          <div>
                            <div className="customProfileLabel">Address *</div>
                            <div style={{ width: "100%" }}>
                              <CustomDropDownSearch
                                toSearch="description"
                                style={{ width: "100%" }}
                                filteredData={placeList}
                                onChange={this.getPlaces}
                                listClick={this.getPlaceData}
                                error={error}
                                value={address_line}
                                placeHolder="Enter the address"
                              />
                            </div>
                          </div>
                          <div>
                            <div className="customProfileLabel">State *</div>
                            <div style={{ width: "100%" }}>
                              <Select
                                size={"large"}
                                defaultValue="a1"
                                onChange={this.handleStateInputChange}
                                style={{
                                  width: "100%",
                                }}
                                options={stateList.map((state) => ({
                                  value: state?.name,
                                  state_id: state.state_id,
                                }))}
                                value={state}
                              />
                            </div>
                          </div>
                          <div>
                            <div className="customProfileLabel">City *</div>
                            <div style={{ width: "100%" }}>
                              <Select
                                size={"large"}
                                defaultValue="a1"
                                onChange={this.handleCityInputChange}
                                style={{
                                  width: "100%",
                                }}
                                options={cityList.map((city) => ({
                                  value: city.name,
                                  state_id: city.city_id,
                                }))}
                                value={city}
                              />
                            </div>
                          </div>
                          <div>
                            <div className="customProfileLabel">Zip Code *</div>
                            <div style={{ width: "100%" }}>
                              <CustomInputFields
                                inputValue={zip}
                                type="number"
                                change={this.handleInputChange}
                                error={error}
                                from="zip"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* </ListingBodyLayout> */}
                      <label className="name-profile capitalize" style={{ margin: " 6px 0px 0px 24px" }}>
                        Account Details
                      </label>
                      {/* <ListingBodyLayout> */}
                      <div style={{ padding: "24px" }}>
                        <div className="inputFields-grid1">
                          <div>
                            <div className="customProfileLabel">Bank Name</div>
                            <div style={{ width: "100%" }}>
                              <CustomInputFields
                                inputValue={bank_name}
                                type="text"
                                change={this.handleInputChange}
                                error={error}
                                from="bank_name"
                                disabled
                              />
                            </div>
                          </div>
                          <div>
                            <div className="customProfileLabel">Account No</div>
                            <div style={{ width: "100%" }}>
                              <CustomInputFields
                                inputValue={account_no}
                                type="text"
                                change={this.handleInputChange}
                                error={error}
                                from="account_no"
                                disabled
                              />
                            </div>
                          </div>
                          <div>
                            <div className="customProfileLabel">Route Number</div>
                            <div style={{ width: "100%" }}>
                              <CustomInputFields
                                inputValue={ref}
                                type="text"
                                change={this.handleInputChange}
                                error={error}
                                from="ref"
                                disabled
                              />
                            </div>
                          </div>
                          <div>
                            <div className="customProfileLabel">Payout Status</div>
                            <div style={{ width: "100%" }}>
                              <CustomInputFields
                                inputValue={payoutStatus}
                                type="text"
                                change={this.handleInputChange}
                                error={error}
                                from="payoutStatus"
                                disabled
                                color={
                                  payoutStatus === "Enabled" ? "green" : "red"
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </ListingBodyLayout>
                  </div>
                </TabPane>
                <TabPane tabId="2">
                  <Col className="pad24p" style={{ marginTop: "10px" }} sm="12">
                    <Row>
                      {" "}
                      {/* {this.state.serviceList.map((item, i) => (
                      <Col xs="3" className="mb_40 d_flex_align">
                        <Input
                          className="checkBox_service"
                          checked={item.isEnabled}
                          name="ref"
                          id="input-email"
                          placeholder="Reference No"
                          type="checkbox"
                          onChange={() => this.handleCheckBox(i)}
                        />
                        <label className="mb_0 ml_20">
                          {this.camelize(item.name)}
                        </label>
                      </Col>
                    ))} */}
                      {this.state.service_list.map((item, i) => (
                        <Col xs="3" className="mb_40 d_flex_align">
                          <Input
                            className={
                              item.status == "pending"
                                ? "checkBox_service cursor_default"
                                : "checkBox_service"
                            }
                            checked={item.isEnabled}
                            name="ref"
                            id="input-email"
                            placeholder="Reference No"
                            type="checkbox"
                            disabled={item.status == "pending" ? true : false}
                            onChange={() => this.handleCheckBox(i, item)}
                          />
                          <label
                            className={
                              item.status == "pending"
                                ? "opac_5 mb_0 ml_20"
                                : "mb_0 ml_20"
                            }
                          >
                            {this.camelize(item.name)}
                          </label>
                        </Col>
                      ))}
                    </Row>
                  </Col>
                </TabPane>
                <TabPane tabId="3">
                  <Col className="pad24p" sm="12">
                    <Row style={{ marginLeft: "0px", marginRight: "0px" }}>
                      {" "}
                      {this.state.previous_works &&
                        this.state.previous_works.map((item, i) => (
                          <div className=" d_flex_align image_holder">
                            <div className="w_100 h_100 relative_div">
                              <img
                                onClick={() => this.removeImage(item, i)}
                                className="remove_service"
                                src={removeService}
                              />
                              <img
                                src={getCloudinaryImage(
                                  120,
                                  130,
                                  item,
                                  "c_fill"
                                )}
                              />
                            </div>
                          </div>
                        ))}
                      {previousWorkLoading && (
                        <div className=" image_spinner mr_20 ">
                          <Spinner type="border" color="#077e8b" />
                        </div>
                      )}
                      <div className="add_works_image relative_div cursor_pointer">
                        <img src={add} />

                        <p>Add</p>
                        <Input
                          className="ft_family upload_profile_image"
                          type="file"
                          id="imageUpload"
                          accept="image/*"
                          onChange={(event) => this.onPreviousWorkUpload(event)}
                          placeholder="Enter Name"
                        />
                      </div>
                    </Row>
                  </Col>
                </TabPane>
                <TabPane tabId="4">
                  <div className="mb_40" style={{ marginTop: "24px" }}>
                    <div className="">
                      <Card className="shadow p_0">
                        <Table
                          className="align-items-center table-flush"
                          responsive
                        >
                          <thead className="thead-light">
                            <tr>
                              <th scope="col">Type</th>
                              <th scope="col">Uploaded At</th>
                              <th scope="col">Expiry</th>
                              <th style={{ width: "9%" }} scope="col">
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="add_eclipse">Profile Id</td>

                              <td className="add_eclipse">
                                {profile_docs?.createdAt
                                  ? showUtcDate(profile_docs.createdAt)
                                  : "-"}
                              </td>
                              <td className="add_eclipse">
                                {" "}
                                {profile_docs && profile_docs.expiry_date
                                  ? showUtcDate(profile_docs.expiry_date)
                                  : "-"}
                              </td>
                              <td className="add_eclipse green_text ft_15 ft_500">
                                {profile_docs && profile_docs.url ? (
                                  <>
                                    <a
                                      className="mr_15 cursor-point"
                                      href={profile_docs.url}
                                      target="_blank"
                                    >
                                      <img src={EyeIcon} />
                                    </a>
                                    <>
                                      <img
                                        src={Edit}
                                        className="mr_15 cursor-point"
                                        onClick={() =>
                                          this.openEditDocument(
                                            true,
                                            this.state.profile_docs
                                          )
                                        }
                                      />
                                      <img
                                        className="mr_15 cursor-point"
                                        src={Trash}
                                        onClick={() =>
                                          this.openDeleteModal(true)
                                        }
                                      />
                                    </>
                                  </>
                                ) : (
                                  <>
                                    <p
                                      className="m_0 col_primary_2 ft_15 ft_500 cursor-point"
                                      onClick={() =>
                                        this.uploadDocument(
                                          true,
                                          this.state.profile_docs
                                        )
                                      }
                                    >
                                      {" "}
                                      Upload
                                    </p>
                                  </>
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td className="add_eclipse">Employement</td>
                              <td className="add_eclipse">
                                {" "}
                                {employer_docs && employer_docs.createdAt
                                  ? showUtcDate(employer_docs.createdAt)
                                  : "-"}
                              </td>
                              <td className="add_eclipse">
                                {" "}
                                {employer_docs && employer_docs.expiry_date
                                  ? showUtcDate(employer_docs.expiry_date)
                                  : "-"}
                              </td>
                              <td className="add_eclipse green_text ft_15 ft_500">
                                {employer_docs && employer_docs.url ? (
                                  <>
                                    <a
                                      className="mr_15 cursor-point"
                                      href={employer_docs.url}
                                      target="_blank"
                                    >
                                      <img src={EyeIcon} />
                                    </a>
                                    <>
                                      <img
                                        src={Edit}
                                        className="mr_15 cursor-point"
                                        onClick={() =>
                                          this.openEditDocument(
                                            false,
                                            this.state.employer_docs
                                          )
                                        }
                                      />
                                      <img
                                        className="mr_15 cursor-point"
                                        src={Trash}
                                        onClick={() =>
                                          this.deleteDocumentData(
                                            "employer_docs",
                                            "employer"
                                          )
                                        }
                                      />
                                    </>
                                  </>
                                ) : (
                                  <>
                                    <p
                                      className="m_0 col_primary_2 ft_15 ft_500 cursor-point"
                                      style={{ marginBottom: "0px" }}
                                      onClick={() =>
                                        this.uploadDocument(
                                          false,
                                          this.state.employer_docs
                                        )
                                      }
                                    >
                                      Upload
                                    </p>
                                  </>
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                        <Row className="align-items-center">
                          <Col xs="8">
                            <h3 className="mb-0 mt_0"></h3>
                          </Col>
                        </Row>
                      </Card>
                    </div>
                  </div>
                </TabPane>
              </TabContent>
            </div>
            <Row>
              <Col className="order-xl-1 mt-2" xl="8">
                <Modal
                  className="modal-dialog-centered"
                  isOpen={modalOpen}
                  toggle={() => this.toggleModal()}
                >
                  <div className=" modal-header flex">
                    <label className="fw500 fc-b" id="exampleModalLabel">
                      Change Password
                    </label>
                    <div
                      className="clpaid x_close flexmid point fw500 font-24"
                      onClick={() => this.toggleModal()}
                    >
                      <img width={20} src={closeIcon} alt="close" />
                    </div>
                    {/* <img src={closeIcon}/> */}
                    {/* <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => this.toggleModal()}
                  >
                    <span aria-hidden={true}>×</span>
                  </button> */}
                  </div>
                  <div className="modal-body profile-body">
                    {" "}
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label label-in mb-3"
                            htmlFor="input-username"
                          >
                            Current Password*
                          </label>
                          <InputGroup className="input-group-alternative new-input-login">
                            {/* <Input
                              className="form-control-alternative email-new-in"
                              value={curPassword}
                              name="curPassword"
                              placeholder="Current Password"
                              type={type}
                              onChange={this.handleInputChange}
                              from="curPassword"
                            /> */}
                            <CustomInputFields
                              inputValue={curPassword}
                              name="curPassword"
                              type={type}
                              change={this.handleInputChange}
                              error={error}
                              from="curPassword"
                              placeholder="Current Password"
                              divStyle={{ width: '90%' }}
                              style={{ borderRadius: "5px 0px 0px 5px" }}
                            />
                            <InputGroupAddon addonType="append">
                              <InputGroupText
                                onClick={() => this.toggleEyeIcon("curPass")}
                                className="new-border"
                              >
                                <i
                                  className={
                                    type === "password"
                                      ? "fa fa-eye-slash"
                                      : "fa fa-eye"
                                  }
                                ></i>
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label label-in mb-3"
                            htmlFor="input-username"
                          >
                            New Password*
                          </label>
                          <InputGroup className="input-group-alternative new-input-login">
                            {/* <Input
                              className="form-control-alternative email-new-in"
                              value={password}
                              name="password"
                              placeholder="New Password"
                              type={pType}
                              onChange={this.handleInputChange}
                              from="password"
                            /> */}
                            <CustomInputFields
                              inputValue={password}
                              name="password"
                              type={type}
                              change={this.handleInputChange}
                              error={error}
                              from="password"
                              placeholder="New Password"
                              divStyle={{ width: '90%' }}
                            />
                            <InputGroupAddon addonType="append">
                              <InputGroupText
                                onClick={() => this.toggleEyeIcon("pass")}
                                className="new-border"
                              >
                                <i
                                  className={
                                    pType === "password"
                                      ? "fa fa-eye-slash"
                                      : "fa fa-eye"
                                  }
                                ></i>
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label label-in mb-3"
                            htmlFor="input-username"
                          >
                            Confirm Password*
                          </label>
                          <InputGroup className="input-group-alternative new-input-login">
                            {/* <Input
                              className="form-control-alternative email-new-in"
                              value={newPassword}
                              name="newPassword"
                              placeholder="Confirm Password"
                              type={nType}
                              onChange={this.handleInputChange}
                            /> */}
                            <CustomInputFields
                              inputValue={newPassword}
                              name="newPassword"
                              type={type}
                              change={this.handleInputChange}
                              error={error}
                              from="newPassword"
                              placeholder="Confirm Password"
                              divStyle={{ width: '90%' }}
                            />
                            <InputGroupAddon addonType="append">
                              <InputGroupText
                                onClick={() => this.toggleEyeIcon("newPass")}
                                className="new-border"
                              >
                                <i
                                  className={
                                    nType === "password"
                                      ? "fa fa-eye-slash"
                                      : "fa fa-eye"
                                  }
                                ></i>
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  {/* </div> */}
                  <div className="modal-footer">
                    {/* <Button_without_Border
                      btnLabel="cancel"
                      click={this.toggleCloseModal}
                      style={{ marginRight: "10px" }}
                    /> */}
                    <Custom_Button
                      click={this.toggleCloseModal}
                      // isLoading={passLoad}
                      btnLabel="cancel"
                      style={{ marginRight: "8px" }}

                    />
                    <Custom_Button
                      isPrimary
                      click={this.handleChangePassword}
                      isLoading={passLoad}
                      btnLabel="submit"
                    />
                  </div>
                </Modal>
              </Col>
            </Row>
          </CustomListingContainer >
        </div >

        <Modal
          className="modal-dialog-centered map-marker"
          isOpen={isMapVisible}
          toggle={() => this.toggleMapModal()}
        // onKeyDown={(event) => this.onEscPress(event)}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Select the property location on the map
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.onMapIconClick()}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            {" "}
            <MapContainer
              onMapIconClick={this.onMapIconClick}
              onSave={this.onSave}
              courtAddress={location}
            />
          </div>
        </Modal>
        <Card className={addserviceModal ? "exportSideBar" : "toggle-sidebar"}>
          <AddService
            close={this.closeServiceSideDragger}
            isOpen={addserviceModal}
            toast={this.displayErrorMessage}
            add={this.addServiceInList}
          />
        </Card>
      </>
    );
  }
}

export default Profile;
