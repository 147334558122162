import React, { Component } from "react";
import {
    CardHeader,
    Input,
    DropdownToggle,
    UncontrolledDropdown,
    Media,
    DropdownMenu,
    DropdownItem,
    InputGroup,
    InputGroupText,
    Spinner,
    Button,
    Tag,
    Card,
    Dropdown,
    FormGroup,
} from "reactstrap";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import closeIcon from "../../assets/img/brand/close.svg";
import dateLight from "../../assets/img/brand/datePicker.svg";
import moment from "moment";
import uploadIcon from "../../assets/img/icons/upload_icon.svg";
import { getCloudinaryImage } from "constants/utils";
import removeIcon from "../../assets/img/brand/removeIcon.svg";
import Tags from "components/Tags/Tags";
import ApiService from "constants/ApiService";
import Add from "../../assets/img/brand/addIcon.svg";
import {
    showSuccess,
    Isvalidname,
    showError,
    uploadCloudinary,
    isValidEmail,
    getUSFormat,
} from "constants/utils";
// import AddClient from "./addClient";
import TaskList from "views/Task/TaskList";
import { buildQueryString } from "constants/utils";

class AddTax extends Component {
    constructor(props) {
        super(props);
        this.inputKey = Date.now();
        this.fileInputRef = React.createRef();
        this.myElementRef = React.createRef();
        this.myInputElementRef = React.createRef();
        this.state = {
            name: "",
            email: "",
            phone: "",
            lead_name: "",
            client_name: "",
            clientsList: [],
            subContractor_name: "",
            sub_contractorList: [],
            start_date: "",
            image: [],
            actual_start: "",
            end_date: "",
            lead_name: "",
            imageLoading: false,
            desc: "",
            tags: [],
            btnLoad: false,
            edit: false,
            initAddProjectLoading: true,
            tag: "",
            subContractor_id: "",
            clientAdd: false,
            isSearchOpen: false,
            activeIndex: -1,
            disabled: false,
            isApiCallPending: false,
            file: null,
            projectStartDate: null,
            projectActualStarDate: null,
            projectEndDate: null,
            leadSource: ["Advertisement", "Chat", "Call", "Email", "Website"],
            add_lead: {},
            showLeads: false,
            listsLoad: false,
            listsBoxOpen: false,
            isNameAlreadyPresent: false
        };
    }

    componentDidMount = async () => {
        this.setState({ initAddProjectLoading: true });
        this.getClientLists();
        if (this.props.leadID) {
            this.setState({
                edit: true,
                name: this.props.leadID.name,
                email: this.props.leadID.email,
                phone: this.props.leadID.phone,
                desc: this.props.leadID.description,
                lead_name: this.props.leadID.source,
            });
        } else {
            this.setState({
                name: "",
                email: "",
                phone: "",
                desc: "",
                lead_name: "",
            });
        }
    };

    handleInputChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };
    imageHandler = async (e) => {
        let file = e.target.files[0];
        let { image } = this.state;
        if (file) {
            this.setState({ imageLoading: true });
        }
        if (
            file.type === "image/png" ||
            file.type === "image/jpg" ||
            file.type === "image/jpeg"
        ) {
            if (file.size < 2e6) {
                let data = await uploadCloudinary(file, "host");
                if (data.url) {
                    let imgArr = [...image];
                    let imageObj = {
                        format: data.format,
                        public_id: data.public_id,
                        version: `v${data.version}`,
                        type: "image",
                    };
                    imgArr.push(imageObj);
                    this.setState({
                        image: imgArr,
                        imageLoading: false,
                    });
                } else {
                    this.setState({ imageLoading: false });
                    e.target.value = null;
                    return showError(this.props.props, "Could not upload image");
                }
            }
        }
    };

    addClient = () => {
        this.setState({ clientAdd: true });
    };

    deleteImage = () => {
        this.setState({ image: [], image_name: "" });
    };
    handlePhoneChange = (e) => {
        const { name, value } = e.target;

        const numericValue = value.replace(/\D/g, "");

        const limitedValue = numericValue.slice(0, 10);

        let formattedPhone = "";

        if (limitedValue.length > 0) {
            formattedPhone = `(${limitedValue.slice(0, 3)}`;
            if (limitedValue.length > 3) {
                formattedPhone += `) ${limitedValue.slice(3, 6)}`;
                if (limitedValue.length > 6) {
                    formattedPhone += ` - ${limitedValue.slice(6, 10)}`;
                }
            }
        }

        this.setState({ [name]: formattedPhone });
    };

    onInputSearch = (e) => {
        let tempLists = this.state.clientsList;
        let searchName = e.target.value;
        // if(!searchName.length) return tempLists = []
        const tempArray = tempLists.filter((obj) => {
            let name = obj.name;
            // return obj.name.trim().toLowercase().includes(searchName) && obj
        });
    };

    imageUploader = async (file) => {
        let { image } = this.state;
        if (this.fileInputRef.current) {
            this.fileInputRef.current.value = "";
        }
        if (file) {
            this.setState({ imageLoading: true });
        }
        if (
            file.type === "image/png" ||
            file.type === "image/jpg" ||
            file.type === "image/jpeg"
        ) {
            if (file.size < 2e6) {
                let data = await uploadCloudinary(file, "host");
                if (data.url) {
                    let imgArr = [...image];
                    let imageObj = {
                        format: data.format,
                        public_id: data.public_id,
                        version: `v${data.version}`,
                        type: "image",
                    };
                    imgArr.push(imageObj);
                    this.setState({
                        image: imgArr,
                        imageLoading: false,
                    });
                } else {
                    this.setState({ imageLoading: false });
                    return showError(this.props.props, "Could not upload image");
                }
            } else {
                this.setState({ imageLoading: false });
                return showError(this.props.props, "File size should not exceeds 2 MB");
            }
        } else {
            this.setState({ imageLoading: false });
            return showError(
                this.props.props,
                "Invalid Format , upload only .png, .jpg, .jpeg"
            );
        }
    };

    addClient = () => {
        this.setState({ clientAdd: true });
    };

    deleteImage = () => {
        this.setState({ image: [], image_name: "" });
    };

    closeModal = () => {
        this.setState({
            start_date: "",
            actual_start: "",
            client_name: "",
            name: "",
            end_date: "",
            tags: [],
            desc: "",
        });
        this.props.close();
    };
    handlePhoneChange = (e) => {
        const { name, value } = e.target;

        const numericValue = value.replace(/\D/g, "");

        const limitedValue = numericValue.slice(0, 10);

        let formattedPhone = "";

        if (limitedValue.length > 0) {
            formattedPhone = `(${limitedValue.slice(0, 3)}`;
            if (limitedValue.length > 3) {
                formattedPhone += `) ${limitedValue.slice(3, 6)}`;
                if (limitedValue.length > 6) {
                    formattedPhone += ` - ${limitedValue.slice(6, 10)}`;
                }
            }
        }

        this.setState({ [name]: formattedPhone });
    };
    createLead = async () => {
        let {
            name,
            client_name,
            start_date,
            end_date,
            actual_start,
            client_id,
            subContractor_id,
            desc,
            edit,
            tags,
            image,
            email,
            phone,
            lead_name,
            subContractor_name,
        } = this.state;
        let { type } = this.props;
        if (!name) return showError(this.props.props, "Please enter a name");
        else if (!Isvalidname(name))
            return showError(this.props.props, "Please enter a valid name");
        else if (!email) return showError(this.props.props, "Please enter a email");
        else if (!isValidEmail(email))
            return showError(this.props.props, "Please enter a valid email");
        else if (!phone)
            return showError(this.props.props, "Please enter a phone number");
        else if (phone.length < 16)
            return showError(this.props.props, "Please enter a valid phone number");
        else if (!lead_name)
            return showError(this.props.props, "Please select the lead source");
        let data = {};
        data.name = name;
        data.email = email;
        data.phone = phone;
        data.status = type === "estimate" ? "converted" : "pending";
        data.description = desc;
        data.source = lead_name;
        this.setState({ btnLoad: true, isApiCallPending: true });
        let response = edit
            ? await ApiService.patchAPI(
                `contractor/lead/${this.props.leadID.lead_id}`,
                data
            )
            : await ApiService.postAPI(`contractor/lead`, data);

        this.setState({ isApiCallPending: false, btnLoad: false });

        if (response.success) {
            showSuccess(this.props.props, response.message);
            this.props.type === 'estimate' && this.props?.dataFromParent()
            this.closeModal();
        } else {
            showError(this.props.props, response.message);
        }
    };

    onInputSearch = (e) => {
        let tempLists = this.state.clientsList;
        let searchName = e.target.value;
        // if(!searchName.length) return tempLists = []
        const tempArray = tempLists.filter((obj) => {
            let name = obj.name;
            // return obj.name.trim().toLowercase().includes(searchName) && obj
        });
    };

    onTagChange = (e) => {
        let { tag, tags } = this.state;
        if (["Enter", "Tab"].includes(e.key)) {
            e.preventDefault();
            let value = tag.trim();
            if (value.length) {
                tags.push(value);
                this.setState({ tags: [...tags], tag: "" });
            }
        } else if (["Backspace"].includes(e.key) && !tag) {
            if (tags.length) {
                tags.pop();
                this.setState({ tags: [...tags], tag: "" });
            }
        }
    };

    getListDetail = async (data) => {
        let response = await ApiService.getAPI(
            `contractor/client/${data.client_id}`
        );
        let { client } = response.data;
        this.setState(
            {
                name: client.name,
                email: client.email,
                phone: getUSFormat(client.phone.national_number),
            },
            () => {
                this.setState({ listsBoxOpen: false, isNameAlreadyPresent: true });
            }
        );
    };

    dateContion = (from, event) => {
        if (from === "start") {
            if (this.props.type === "project") {
                this.setState({ start_date: event });
            }
        }
    };

    removeTag = (index) => {
        let { tag, tags } = this.state;
        let tempTags = tags;
        tempTags.splice(index, 1);
        this.setState({ tags: [...tempTags] });
    };

    onTagAreaClick = () => {
        this.myInputElementRef.current.focus();
    };

    onClientClose = () => {
        this.setState({ clientAdd: false }, () => {
            this.getClientLists();
        });
    };

    handleInputChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    getClientLists = async (e) => {
        this.setState({ listsLoad: true, isNameAlreadyPresent: false, email: '', phone: '' });
        let searchTerm = e?.target?.value || "";
        this.setState({ name: searchTerm });
        let response = await ApiService.getAPI(
            `contractor/client?status=active&name=${searchTerm}`
        );
        this.setState({ initAddProjectLoading: false });
        if (response.success) {
            let { clients } = response.data;
            this.setState(
                {
                    clientsList: clients,
                    listsLoad: false,
                },
                () => {
                    this.state.clientsList.length
                        ? this.setState({ listsBoxOpen: true })
                        : this.setState({ listsBoxOpen: false });
                }
            );
        }
    };

    render() {
        const {
            name,
            desc,
            btnLoad,
            edit,
            email,
            listsLoad,
            initAddProjectLoading,
            clientsList,
            listsBoxOpen,
        } = this.state;
        return initAddProjectLoading ? (
            <div className="spin-loading">
                <Spinner
                    style={{
                        height: "30px",
                        width: "30px",
                    }}
                    type="border"
                    color="#012A4D"
                />
            </div>
        ) : (
            <>
                <div>
                    <CardHeader>
                        <div className="flex">
                            <div className="fw-500 f16 cl000000"></div>
                            <img
                                src={closeIcon}
                                onClick={() => this.closeModal()}
                                className="point"
                                alt="close"
                            />
                        </div>
                    </CardHeader>
                    <div className="">
                        <div style={{ padding: "20px" }}>
                            <FormGroup className="geoLocation">
                                <label
                                    className="form-control-label cl2A1E17 mb-15 f14 fw-400 drawer_sub_heading"
                                    htmlFor="input-username"
                                >
                                    Name*
                                </label>
                                <Input
                                    value={name}
                                    name="name"
                                    style={{
                                        fontSize: "14px",
                                        color: "#212529",
                                        border: "1px solid #f0f0f0",
                                    }}
                                    type="text"
                                    onChange={this.getClientLists}
                                />
                                {name && listsBoxOpen && (
                                    <ul>
                                        {listsLoad ? (
                                            <div className="flexmid mt-10 w100 h100">
                                                <Spinner
                                                    type="border"
                                                    color="#0A8080"
                                                    style={{ color: "#0A8080" }}
                                                />
                                            </div>
                                        ) : (
                                            clientsList.map((list) => (
                                                <li onClick={() => this.getListDetail(list)}>
                                                    {list.name}
                                                </li>
                                            ))
                                        )}
                                    </ul>
                                )}
                            </FormGroup>

                            <>
                                {" "}
                                <div
                                    className="cl2A1E17 mb-15 f14 fw-400 drawer_sub_heading"
                                    style={{ marginTop: "25px" }}
                                >
                                    Percentage*
                                </div>
                                <Input
                                    disabled={this.state.isNameAlreadyPresent}
                                    style={{
                                        fontSize: "14px",
                                        color: "#212529",
                                        border: "1px solid #f0f0f0",
                                    }}
                                    type="number"
                                    value={email}
                                    name="email"
                                    onChange={this.handleInputChange}
                                    className="mb-20"
                                />
                            </>

                            <div style={{ marginTop: "20px" }}>
                                <div
                                    style={{ marginBottom: "15px" }}
                                    className="drawer_sub_heading"
                                >
                                    Description
                                </div>
                                <div>
                                    {" "}
                                    <textarea
                                        className="text-area-add-project"
                                        value={desc}
                                        style={{
                                            fontSize: "14px",
                                            color: "#212529",
                                            border: "1px solid #f0f0f0",
                                        }}
                                        maxLength={500}
                                        name="notes"
                                        onChange={(e) => {
                                            this.setState({ desc: e.target.value });
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="flexEnd" style={{ marginTop: "40px" }}>
                                <div
                                    className="mr-20 point f14 button-secondary"
                                    style={{ fontWeight: "500" }}
                                    onClick={() => this.closeModal()}
                                >
                                    Cancel
                                </div>
                                <Button
                                    color="primary"
                                    className="button-wrapping "
                                    size="sm"
                                    onClick={() => this.createLead()}
                                    disabled={this.state.isApiCallPending}
                                >
                                    {btnLoad ? (
                                        <div className="spin-loading-save w100 h100">
                                            <Spinner
                                                style={{
                                                    height: "20px",
                                                    width: "20px",
                                                }}
                                                type="border"
                                                color="#0A8080"
                                            />
                                        </div>
                                    ) : edit ? (
                                        "Update"
                                    ) : (
                                        "Save"
                                    )}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        );
    }
}
export default AddTax;
