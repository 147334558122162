import React, { Component } from "react";
import {
  Card,
  Table,
  Container,
  Row,
  Breadcrumb,
  Col,
  Button,
  InputGroup,
  Spinner,
  DropdownMenu,
  DropdownItem,
  Media,
  UncontrolledDropdown,
  DropdownToggle,
  Dropdown,
  CardHeader,
  Input,
  // Tooltip,
} from "reactstrap";
// import { Drawer } from "antd";
import closeIcon from "../../assets/img/brand/close.svg";
import moment from "moment";
import { Tooltip } from "antd";
import ApiService from "constants/ApiService";

import "react-datepicker/dist/react-datepicker.css";

import { getIsoString, filterQuery } from "constants/utils";

import ToastList from "components/Toast/ToastList";
import { showError, isInvalidEmail } from "constants/utils";
import { returnSearchObject } from "constants/utils";
import { pushToFilteredPage } from "constants/utils";
import { buildQueryString } from "constants/utils";
import DeleteModal from "./DeleteModal";
import showMore from "../../assets/img/brand/showMore.svg";
import { showSuccess } from "constants/utils";
import { getUSFormat, truncate } from "constants/utils";
import ClientStatusModal from "./ClientStatusModal";
import AddClient from "./addClient";
import { CustomListingContainer } from "components/Container/CustomListingContainer";
import { ListingHeaderLayout } from "components/Container/CustomListingContainer";
import { CustomDropDown } from "components/FilterFields/Filterfields";
import { Button_without_Border } from "components/Buttons/Button";
import { CustomSearch } from "components/FilterFields/Filterfields";
import { Custom_Button } from "components/Buttons/Button";
let overlap = document.querySelector(":root");

class Client extends Component {
  constructor(props) {
    super(props);
    this.textWidth = React.createRef();
    this.state = {
      requestList: [],
      client_list: [],
      filterToggle: false,
      from: "",
      to: "",
      open: false,
      total: 0,
      page: 1,
      inputSearch: "",
      filterOption: "name",
      filters: ["name"],
      status: this.props.location?.search ? "pending" : "",
      toast: false,
      response: "",
      toastType: "",
      isLoading: true,
      btnLoad: false,
      edit: false,
      edit_data: {},
      name: "",
      email: "",
      phone: "",
      query: {},
      openModal: false,
      delete_data: {},
      requestId: "",
      cancelModal: false,
      client_id: "",
      parentElement: "",
      tooltipOpen: false,
      toolTip: "",
      input_value: false,
      statusSearch: "",
      timoutId: "",
    };
  }

  componentDidMount = async () => {
    // overlap.style.setProperty("--width", "600px");

    this.setQueryStatus();
  };

  componentDidUpdate = () => {
    // this.setState({
    //   parentElement: this.textWidth,
    // });
  };

  toggle() {
    // this.setState({ tooltipOpen: !this.state.tooltipOpen });
  }

  componentWillUnmount() {
    // overlap.style.setProperty("--width", "450px");
  }

  capitalizeFirstLetter = (str) => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  initCheckClerFilters = () => {
    this.state.filters.includes(this.state.filterOption) ||
      this.state.statusSearch ||
      this.state.inputSearch ||
      this.state.page > 1
      ? this.setState({ input_value: true })
      : this.setState({ input_value: false });
  };

  setQueryStatus = () => {
    let queryStatus = this.props.location?.search;
    let queryObj = returnSearchObject(queryStatus);
    if (queryStatus) {
      this.setState(
        {
          filterOption: queryObj.name ? "name" : "name",
          inputSearch: queryObj.name ? queryObj.name : "",
          statusSearch: queryObj.status ?? "",
          page: parseInt(queryObj.page) ?? "",
          query: returnSearchObject(queryStatus),
        },
        () => {
          this.initCheckClerFilters();
          pushToFilteredPage(this.props, { ...this.state.query });
          this.getClientsList();
        }
      );
    } else {
      this.setState(
        {
          query: {
            ...queryObj,
            page: 1,
            limit: 10,
          },
        },
        () => {
          pushToFilteredPage(this.props, { ...this.state.query });
          this.getClientsList();
        }
      );
    }
  };

  setPage = (page) => {
    this.props.history.push(page);
  };
  handleClickChange = async () => {
    let { name, email, phone, edit, client_id } = this.state;
    if (!name) return showError(this, "Please enter the name");
    else if (!email) return showError(this, "Please enter the email");
    else if (isInvalidEmail(email))
      return showError(this, "Please enter a valid email");
    else if (!phone) return showError(this, "Please enter a phone number");
    else if (phone.length < 16)
      return showError(this, "Please enter a valid phone number");
    this.setState({ btnLoad: true });
    let data = {
      name: name,
      phone: phone.replace(/\D/g, ""),
      email: email,
    };

    let response = edit
      ? await ApiService.patchAPI(`contractor/client/${client_id}`, data)
      : await ApiService.postAPI("contractor/client", data);
    if (response.success) {
      showSuccess(this, response.message);
      this.setState({ btnLoad: false, open: false });
      this.getClientsList();
    } else {
      this.setState({ btnLoad: false });
      showError(this, response.message);
    }
  };

  statusUpdate = async (client) => {
    if (client.active) {
      this.setState({
        openModal: true,
        delete_data: client,
        client_id: client.client_id,
      });
    } else {
      let response = await ApiService.patchAPI(
        `contractor/client/statusChange/${client.client_id}`,
        {}
      );
      if (response.success) {
        showSuccess(this, response.message);

        this.getClientsList();
      } else {
        showError(this, response.message);
      }
    }
  };

  onClose = () => {
    this.setState({ open: false, client_id: "" }, () => this.getClientsList());
  };
  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handlePhoneChange = (e) => {
    const { name, value } = e.target;

    const numericValue = value.replace(/\D/g, "");

    const limitedValue = numericValue.slice(0, 10);

    let formattedPhone = "";

    if (limitedValue.length > 0) {
      formattedPhone = `(${limitedValue.slice(0, 3)}`;
      if (limitedValue.length > 3) {
        formattedPhone += `) ${limitedValue.slice(3, 6)}`;
        if (limitedValue.length > 6) {
          formattedPhone += ` - ${limitedValue.slice(6, 10)}`;
        }
      }
    }

    this.setState({ [name]: formattedPhone });
  };
  getRequestsList = async () => {
    let { filterOption, status, inputSearch, from, to } = this.state;
    let queryParams = "";
    if (filterOption !== "" && inputSearch !== "")
      queryParams += filterQuery(filterOption, inputSearch);
    if (status !== "") {
      queryParams += filterQuery("status", status);
    }
    if (from !== "") queryParams += filterQuery("from", getIsoString(from));
    if (to !== "") queryParams += filterQuery("to", getIsoString(to));
    if (from > to) {
      showError(this, "Please provide valid From and To date");
    } else {
      let response = await ApiService.getAPI(
        `contractor/open_request${buildQueryString(this.state.query)}`
      );

      if (response.success) {
        let { open_request, total } = response.data;
        this.setState({
          requestList: open_request,
          total: total,
          isLoading: false,
        });
      }
    }
  };

  editClient = async (data) => {
    this.setState(
      {
        edit: true,
        edit_data: data,
        name: data.name,
        phone: data.phone,
        email: data.email,
        client_id: data.client_id,
      },
      () => {
        this.setState({ open: true });
      }
    );
  };

  getClientsList = async () => {
    let { filterOption, status, inputSearch, from, to } = this.state;
    let queryParams = "";
    if (filterOption !== "" && inputSearch !== "")
      queryParams += filterQuery(filterOption, inputSearch);
    if (status !== "") {
      queryParams += filterQuery("status", status);
    }
    if (from !== "") queryParams += filterQuery("from", getIsoString(from));
    if (to !== "") queryParams += filterQuery("to", getIsoString(to));
    if (from > to) {
      showError(this, "Please provide valid From and To date");
    } else {
      let response = await ApiService.getAPI(
        `contractor/client${buildQueryString(this.state.query)}`
      );
      if (response.success) {
        let { clients } = response.data;
        this.setState({
          client_list: clients.map((client) => {
            return { ...client, ref: React.createRef() };
          }),
          isLoading: false,
        });
      }
    }
  };

  toggleFilterMenu = () => {
    this.setState({ filterToggle: !this.state.filterToggle });
  };

  onChangeHandler = (e, from) => {
    this.setState(
      { [from]: e === "select" ? "" : e, input_value: true },
      () => {
        this.checkForClearFilter();
      }
    );
    clearTimeout(this.state.timoutId);
    const timoutId = setTimeout(() => {
      this.state.filterOption && this.handleSearch();
    }, 1000);
    this.setState({ timoutId });
  };

  updateStateValues = (key, value) => {
    this.setState(
      {
        [key]: value,
      },
      function () {
        if (value === null) {
          this.setState({
            [key]: "",
          });
        }
      }
    );
  };

  handleOnToChange = (e) => {
    this.updateStateValues("to", e ? new Date(e) : '');
  };

  handleOnFromChange = (e) => {
    this.updateStateValues("from", e ? new Date(e) : '');
  };

  handleIncrementPage = () => {
    if (this.state.client_list.length >= 10) {
      this.setState(
        {
          page: this.state.page + 1,
          input_value: true,
        },
        () => {
          this.handleFilterStatus();
        }
      );
    }
  };

  handleDecrementPage = () => {
    if (this.state.page > 1) {
      this.setState(
        {
          page: this.state.page - 1,
          input_value: this.state.page - 1 === 1 ? false : true,
        },
        () => {
          this.handleFilterStatus();
        }
      );
    }
  };
  redirectDetailPage = (id) => {
    this.props.history.push(`requests/detail/${id}`);
  };

  handleFilterStatus = () => {
    let staticQuery = returnSearchObject(this.props.location.search);
    staticQuery.status = this.state.statusSearch ?? "";
    staticQuery.page = this.state.page ?? "";
    for (let key in staticQuery) {
      if (key === "select" || key === "name") {
        delete staticQuery[key];
      }
    }
    staticQuery[this.state.filterOption] = this.state.inputSearch;
    this.setState({ query: staticQuery }, () => {
      if (this.state.filterOption !== "select") {
        pushToFilteredPage(this.props, { ...this.state.query });
        this.getClientsList();
      }
    });
  };

  handleSearch = () => {
    if (this.state.filterOption) {
      this.setState({ page: 1 }, () => {
        this.handleFilterStatus();
      });
    }
  };

  handleReset = () => {
    let self = this;
    self.setState(
      {
        from: "",
        to: "",
        statusSearch: "",
        filterOption: "name",
        inputSearch: "",
        page: 1,
        limit: 10,
        query: {
          page: 1,
          limit: 10,
        },
        input_value: false,
      },
      () => {
        pushToFilteredPage(this.props, { ...this.state.query });
        this.getClientsList();
      }
    );
  };

  setStatus = (status) => {
    this.setState(
      {
        statusSearch: status === "all" ? "" : status,
        page: 1,
      },
      () => {
        this.checkForClearFilter();
        this.handleFilterStatus();
      }
    );
  };
  handleDelete = async () => {
    let response = await ApiService.patchAPI(
      `contractor/client/statusChange/${this.state.delete_data.client_id}`,
      {}
    );
    if (response.success) {
      showSuccess(this, response.message);
      this.setState({ delete_data: {} });
      this.closeModal();
      this.getClientsList();
    } else {
      showError(this, response.message);
    }
  };

  checkForClearFilter = () => {
    this.setState({
      input_value:
        !this.state.statusSearch &&
          (this.state.filterOption === "select" || !this.state.filterOption) &&
          !this.state.inputSearch
          ? false
          : true,
    });
  };

  redirectToAddRequest = () => {
    this.setState({
      client_id: "",
      open: true,
      edit: false,
      name: "",
      email: "",
      phone: "",
    });
  };

  showToolTip = (element, line) => {
    let defaultLine = "";
    // let childDivWidth;
    // setTimeout(() => {
    //   parentDivWidth = element?.current?.clientWidth;
    //   childDivWidth = element?.current?.children[0]?.lastChild?.offsetWidth;
    // }, 1000);
    // setTimeout(() => {
    //   const childWidth = element?.current?.childNodes[0]?.childNodes[0]?.offsetWidth;
    //   const parentWidth = element?.current?.offsetWidth
    //  (childWidth > parentWidth) && (defaultLine = line)
    // },100)
    return line;
  };

  isBig = (element) => {
    let text = "";
    setTimeout(() => {
      let childWidth =
        element?.current?.childNodes[0]?.childNodes[0]?.offsetWidth;
      let ptWidth = element?.current?.offsetWidth;
      if (childWidth > ptWidth) {
        this.setState({ toolTip: element?.current?.outerText });
      } else this.setState({ toolTip: text });
    }, 100);
  };

  editRequest = (data) => {
    this.props.history.push(`/admin/request/update/${data?.open_request_id}`);
  };

  cancelRequest = async (request) => {
    this.setState({ cancelModal: true, requestId: request.open_request_id });
  };

  resendRequest = async (request) => {
    let response = await ApiService.patchAPI(
      `contractor/open_request/resend/${request.open_request_id}`
    );
    if (response.success) {
      showSuccess(this, response.message);
      this.getRequestsList();
    } else {
      showError(this, response.message);
    }
  };
  resendInvoice = async (request) => {
    let response = await ApiService.patchAPI(
      `contractor/open_request/invoice/${request.open_request_id}`
    );
    if (response.success) {
      showSuccess(this, response.message);
      this.getRequestsList();
    } else {
      showError(this, response.message);
    }
  };
  closeModal = () => {
    this.setState({ openModal: false }, () => this.getClientsList());
  };

  render() {
    let {
      requestList,
      isLoading,
      page,
      status,
      toast,
      response,
      toastType,
      client_list,
      filterOption,
      name,
      phone,
      inputSearch,
      btnLoad,
      email,
      open,
      edit,
      client_id,
      openModal,
      delete_data,
      input_value,
      statusSearch,
      filters,
    } = this.state;
    return isLoading ? (
      <div className="spin-loading">
        <Spinner type="border" color="#077e8b" />
      </div>
    ) : (
      <>
        {/* <ClientStatusModal
          modalState={openModal}
          name={delete_data.name}
          closeModal={this.closeModal}
          onSubmit={this.handleDelete}
        /> */}
        {this.state.openModal && (
          <DeleteModal
            modalState={openModal}
            type="client"
            name={delete_data.name}
            closeModal={this.closeModal}
            props={this}
            id={this.state.client_id}
          />
        )}
        <div style={{ padding: "20px", backgroundColor: "#fafafa" }}>
          {toast && <ToastList message={response} type={toastType} />}
          <CustomListingContainer>
            <ListingHeaderLayout
              primary_buttonLabel={"Add Client"}
              isPrimary
              label={"Clients"}
              click={() => {
                this.setState({ open: true });
              }}
            ></ListingHeaderLayout>
            <div style={{ margin: "20px" }}>
              <div
                // className="d_flex_sb"
                style={{
                  display: "grid",
                  gridTemplateColumns: "65% 35%",
                }}
              >
                <div style={{ display: "flex" }}>
                  {/* <div style={{ display: "flex", width: "80%" , justifyContent:'space-between'}}> */}
                  <CustomSearch
                    style={{ marginRight: "20px" }}
                    options={filters}
                    change={this.onChangeHandler}
                    filterOption={filterOption}
                    inputSearch={inputSearch}
                  />
                  {/* </div> */}

                  {/* <CustomDropDown
                    width={"126px"}
                    setStatus={this.setStatus}
                    status={statusSearch}
                    statusList={["all", "active"]}
                  ></CustomDropDown> */}
                </div>
                {(input_value && inputSearch) && (
                  <div className="right_filter_container">
                    <Button_without_Border
                      click={this.handleReset}
                      btnLabel={"clear_filter"}
                    ></Button_without_Border>
                  </div>
                )}
              </div>
            </div>

            <Row>
              <div className="col new-table">
                <Card className="shadow card_with_paginator">
                  <Table className="align-items-center table-flush" responsive>
                    <thead
                      className="thead-light"
                      style={{ borderBottom: "1px solid #f5f5f5" }}
                    >
                      <tr>
                        <th
                          style={{ width: "15%" }}
                          scope="col"
                          className="pad10"
                        >
                          Name
                        </th>
                        <th style={{ width: "15%" }} scope="col">
                          Email
                        </th>
                        <th style={{ width: "15%" }} scope="col">
                          Phone
                        </th>
                        {/* <th style={{ width: "15%" }} scope="col">
                          Status
                        </th> */}
                        <th
                          style={{ width: "15%" }}
                          scope="col"
                          id={`${"address"}`}
                        >
                          Location
                        </th>
                        <th style={{ width: "5%" }} scope="col"></th>
                      </tr>
                    </thead>
                    {client_list && client_list.length !== 0 ? (
                      <tbody>
                        {client_list.map((client, i) => (
                          <tr key={i}>
                            <td>
                              <div>
                                {" "}
                                {this.capitalizeFirstLetter(client.name)}
                              </div>
                            </td>
                            <td>
                              <div> {client.email}</div>
                            </td>

                            <td>
                              <div> {client.phone.national_number ? getUSFormat(client.phone.national_number) : '-'}</div>
                            </td>

                            {/* <td>
                              <div
                                className="project_list_status"
                                style={{
                                  color: `${
                                    client.active === true
                                      ? "#07A84E"
                                      : "#FF4949"
                                  }`,
                                }}
                              >
                                {" "}
                                {`${client.active ? "Active" : "Terminated"}`}
                              </div>
                            </td> */}
                            <td
                              ref={client.ref}
                              className="project_list_status text-wrap"
                              style={{
                                lineHeight: "1.5",
                                whiteSpace: "nowrap",
                                maxWidth: "0px",
                              }}
                            >
                              <div
                                style={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                                onMouseEnter={() => {
                                  this.isBig(client.ref);
                                }}
                              >
                                <Tooltip
                                  placement="topLeft"
                                  title={this.state.toolTip}
                                >
                                  <span>
                                    {client?.address
                                      ? typeof client?.address?.state ===
                                        "object"
                                        ? client?.address?.state.name
                                        : client?.address?.state
                                      : "-"}
                                  </span>
                                </Tooltip>
                              </div>
                              {/* <Tooltip
                              placement="top"
                              isOpen={this.state.tooltipOpen}
                              autohide={false}
                              target="toolTip"
                              toggle={() => this.toggle()}
                            >
                              Try to select this text!
                            </Tooltip> */}
                            </td>
                            <td>
                              <div className="project_list_content">
                                {" "}
                                <UncontrolledDropdown
                                  nav
                                  className="flexy-content position_u"
                                >
                                  <DropdownToggle
                                    className="pr-0 nav-notice"
                                    nav
                                  >
                                    <Media className="align-items-center">
                                      <img src={showMore} alt="show more" />
                                    </Media>
                                  </DropdownToggle>
                                  <DropdownMenu
                                    className="dropdown-menu-arrow min-width-drop"
                                    right
                                  >
                                    <DropdownItem
                                      onClick={() => this.editClient(client)}
                                    >
                                      <span>Edit</span>
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td className="no-data">No Client Available</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                    )}
                  </Table>
                </Card>
              </div>
            </Row>
            <Row className="align-items-center header-div jus-space new-margin-page paginator_box">
              <div className="paginate-flex-box ">
                <div
                  className="paginate-box"
                  onClick={() => this.handleDecrementPage()}
                >
                  <p
                    className={
                      page !== 1 ? "page_available" : "page_unavailable"
                    }
                  >
                    Previous
                  </p>
                </div>
                <div
                  className="paginate-box"
                  onClick={() => this.handleIncrementPage()}
                >
                  <p
                    className={
                      client_list.length < 10
                        ? "page_unavailable"
                        : "page_available"
                    }
                  >
                    Next
                  </p>
                </div>
              </div>
            </Row>
            <Card
              className={this.state.open ? "exportSideBar" : "toggle-sidebar"}
            >
              {this.state.open && (
                <AddClient
                  close={this.onClose}
                  props={this}
                  id={client_id}
                  cancel={() => {
                    this.setState({ open: false });
                  }}
                />
              )}
            </Card>
          </CustomListingContainer>
        </div>
      </>
    );
  }
}

export default Client;
