import { Component } from "react";
import {
  Breadcrumb,
  Col,
  Spinner,
  Row,
  BreadcrumbItem,
} from "reactstrap";
import Lottie from "react-lottie";
import pksLogo from "../../../assets/img/brand/logoPark.svg";
import NoData from "../../../assets/Lotties/noData.json";
export default class ClientDocument extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initPageLoading: false,
      toast: false,
      response: "",
      toastType: "",
      addDocuments: false,
      documents: [],
    };
  }

  render() {
    const NodataFound = {
      loop: true,
      autoplay: true,
      animationData: NoData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    let {
      initPageLoading,
        documents
    } = this.state;
    return (
      <>
        <div className="clientBody">
          <div>
            <Breadcrumb></Breadcrumb>
          </div>
          <Row style={{ padding: "1%" }} className="">
            <Col xs="" className="manage-con">
              <Breadcrumb>
                <BreadcrumbItem
                  onClick={() => this.props.history.push("/client/123/123")}
                  className="bread-cursor"
                >
                  Project
                </BreadcrumbItem>
                <BreadcrumbItem active style={{ color: "#72569c" }}>Documents</BreadcrumbItem>
              </Breadcrumb>
            </Col>
          </Row>
          {initPageLoading ? (
            <>
              <div className="spin-loading mt-8">
                <Spinner type="border" color="#077e8b" />
              </div>
            </>
          ) : (
            <>
              {documents.length ? (
                <></>
              ) : (
                <>
                  <div className="no_data_dashboard">
                    <Lottie options={NodataFound} height={100} width={100} />
                    <div className="mt_20">
                      <p>No Documents Found</p>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </>
    );
  }
}
