import React, { Component } from "react";
import {
    Card,
    Table,

    Row,
    DropdownMenu,
    DropdownItem,
    Media,
    UncontrolledDropdown,
    DropdownToggle,

} from "reactstrap";

import moment from "moment";
import projectTimeline from "../../../src/assets/img/brand/projectTimeline.svg";

import showMore from "../../assets/img/brand/showMore.svg";
import RejectNotes from "views/Project/rejectNotes";
import { showUtcDate } from "constants/utils";


class ProjectDetailTable extends Component {
    constructor(props) {
        super(props);
        this.state = {

            tableHead: {
                "task": ["Name", "Due On", "Status"],
                "changeOrders": ["Title", "Created On", "Due On", "Cost", "Status"],
                "workOrders": ["Title", "Assigned To", "Created On", "Due On", "Cost", "Status"],
                "invoices": ["ID", "Invoice Date", "Created At", "Amount", "Status"],
                "Payments": ["ID", "Payment Date", "Created At", "Amount", "Status"]
            },


        };

    }




    capitalizeFirstLetter = (str) => {
        if (!str) return "";
        return str.charAt(0).toUpperCase() + str.slice(1);
    };
    render() {

        let {
            tasks,
            tableHead,
            changeOrders
        } = this.state;
        return (
            <>
                <Row>
                    <div className="col new-table">
                        <Card className="shadow card_with_crm">
                            <Table
                                className="align-items-center table-flush"
                                responsive
                                style={{ minHeight: "20rem" }}
                            >
                                <thead className="thead-light" >
                                    <tr>

                                        {((this.props?.tasks?.length > 0 && this.props.type === 'tasks') ||
                                            (this.props?.changeOrders?.length > 0 && this.props.type === 'changeOrders') ||
                                            (this.props?.workOrders?.length > 0 && this.props.type === 'workOrders')) &&
                                            tableHead[this.props.type]?.map((val, index) => (
                                                <th
                                                    key={index}
                                                    style={{ width: "0.75%", borderColor: "#f5f5f5" }}
                                                    scope="col"
                                                    className="crm ptb20"
                                                >
                                                    {val}
                                                </th>
                                            ))
                                        }


                                        {((this.props?.tasks?.length > 0 || this.props?.changeOrders?.length > 0 || this.props?.workOrders?.length > 0) &&
                                            <th
                                                className="ptb20"
                                                style={{

                                                    width: "4%",
                                                    cursor: "pointer",
                                                    fontWeight: "500",
                                                    color: "#338180",
                                                    borderColor: "#f5f5f5",

                                                }}
                                                scope="col"
                                            >

                                            </th>
                                        )}
                                    </tr>
                                </thead>
                                {(this.props.type === "task" && this.props.tasks && this.props.tasks.length !== 0) ? (
                                    <tbody>
                                        {this.props.tasks.map((task, i) => (
                                            <tr key={i}>
                                                <td
                                                    className="cursor-point capitalize ptb20"
                                                    onClick={() =>
                                                        task.status === "accepted"

                                                    }
                                                    style={{ width: "100px" }}
                                                >
                                                    <div className="project_list_content">
                                                        {this.capitalizeFirstLetter(task.name)}
                                                    </div>
                                                </td>

                                                <td className="ptb20">
                                                    <div className="project_list_content">
                                                        {" "}
                                                        {showUtcDate(task.end_date)}
                                                    </div>
                                                </td>
                                                <td className="ptb20">
                                                    <div
                                                        className="project_list_status"
                                                        style={{
                                                            color: `${task.status === "completed" ||
                                                                task.status === "approved"
                                                                ? "#07A84E"
                                                                : task.status === "pending"
                                                                    ? "#FF4949"
                                                                    : task.status === "due"
                                                                        ? "#0070FD"
                                                                        : task.status === "rejected"
                                                                            ? "#F70e0e"
                                                                            : "#FFCC3D"
                                                                }`,
                                                        }}
                                                    >
                                                        {" "}
                                                        {task.status === "in_progress"
                                                            ? "In Progress"
                                                            : this.capitalizeFirstLetter(task.status)}
                                                    </div>
                                                </td>

                                                <td
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "flex-end",
                                                        // justifyContent: "center",
                                                        borderTop: "none",
                                                        gap: "10px",
                                                        borderBottom: "1px solid #f5f5f5"
                                                    }}
                                                    className="ptb20"
                                                >
                                                    <div>
                                                        <img
                                                            onClick={() =>
                                                                this.props.taskTimelineOpen(task, "task", task.task_id)
                                                            }
                                                            style={{ cursor: "pointer" }}
                                                            src={projectTimeline}
                                                            alt="timeline"
                                                        ></img>
                                                    </div>
                                                    {(
                                                        <div style={{ visibility: task.status !== "approved" ? "visible" : "hidden" }} className="project_list_content">
                                                            {" "}
                                                            <UncontrolledDropdown
                                                                nav
                                                                className="flexy-content position_u"
                                                            >
                                                                <DropdownToggle
                                                                    className="pr-0 nav-notice"
                                                                    nav
                                                                >
                                                                    <Media className="align-items-center">
                                                                        <img
                                                                            src={showMore}
                                                                            alt="show more"
                                                                        />
                                                                    </Media>
                                                                </DropdownToggle>
                                                                <DropdownMenu
                                                                    className="dropdown-menu-arrow min-width-drop"
                                                                    right
                                                                >
                                                                    {task.status === "completed" ? (
                                                                        <>
                                                                            <DropdownItem

                                                                                onClick={() =>
                                                                                    this.props.onReasonOpen(
                                                                                        task,
                                                                                        "approved"
                                                                                    )
                                                                                }
                                                                            >
                                                                                <span>Approve</span>
                                                                            </DropdownItem>
                                                                            <DropdownItem
                                                                                onClick={() =>
                                                                                    this.props.onReasonOpen(
                                                                                        task,
                                                                                        "rejected"
                                                                                    )
                                                                                }
                                                                            >
                                                                                <span>Reject</span>
                                                                            </DropdownItem>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <DropdownItem
                                                                                onClick={() =>
                                                                                    this.props.taskEdit(
                                                                                        task
                                                                                    )
                                                                                }
                                                                            >
                                                                                <span>Edit</span>
                                                                            </DropdownItem>

                                                                        </>
                                                                    )}
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </div>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                )
                                    : (this?.props?.type === "changeOrders" && this.props.changeOrders?.length > 0) ?
                                        (<tbody>
                                            {this.props?.changeOrders.map((changeOrder, i) => (
                                                <tr key={i} >
                                                    <td

                                                        className="cursor-point capitalize ptb20"
                                                        onClick={() =>
                                                            changeOrder.status === "accepted"

                                                        }
                                                        style={{ width: "100px" }}
                                                    >
                                                        <div className="project_list_content">
                                                            {this.capitalizeFirstLetter(changeOrder.title)}
                                                        </div>
                                                    </td>

                                                    <td className="ptb20">
                                                        <div className="project_list_content">
                                                            {" "}
                                                            {showUtcDate(changeOrder.createdAt)}
                                                        </div>
                                                    </td>
                                                    <td className="ptb20">
                                                        <div className="project_list_content">
                                                            {" "}
                                                            {showUtcDate(changeOrder.due_date)}
                                                        </div>
                                                    </td>
                                                    <td className="ptb20">
                                                        <div className="project_list_content">
                                                            ${changeOrder.amount}
                                                        </div>
                                                    </td>
                                                    <td className="ptb20">
                                                        <div
                                                            className="project_list_status"
                                                            style={{
                                                                color: `${changeOrder.status === "completed" ||
                                                                    changeOrder.status === "approved"
                                                                    ? "#07A84E"
                                                                    : changeOrder.status === "pending"
                                                                        ? "#FF4949"
                                                                        : changeOrder.status === "due"
                                                                            ? "#0070FD"
                                                                            : changeOrder.status === "rejected"
                                                                                ? "#F70e0e"
                                                                                : "#FFCC3D"
                                                                    }`,
                                                            }}
                                                        >
                                                            {" "}
                                                            {changeOrder.status === "in_progress"
                                                                ? "In Progress"
                                                                : this.capitalizeFirstLetter(changeOrder.status)}
                                                        </div>
                                                    </td>

                                                    <td
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "flex-end",
                                                            // justifyContent: "center",
                                                            borderTop: "none",
                                                            gap: "10px",
                                                            borderBottom: "1px solid #f5f5f5"
                                                        }}
                                                        className="ptb20"
                                                    >
                                                        <div>
                                                            <img
                                                                onClick={() =>
                                                                    this.props.taskTimelineOpen(changeOrder, "changeOrder", changeOrder.changeOrder_id)
                                                                }
                                                                style={{ cursor: "pointer" }}
                                                                src={projectTimeline}
                                                                alt="timeline"
                                                            ></img>
                                                        </div>
                                                        {(
                                                            <div style={{ visibility: changeOrder.status !== "approved" ? "visible" : "hidden" }} className="project_list_content">
                                                                {" "}
                                                                <UncontrolledDropdown
                                                                    nav
                                                                    className="flexy-content position_u"
                                                                >
                                                                    <DropdownToggle
                                                                        className="pr-0 nav-notice"
                                                                        nav
                                                                    >
                                                                        <Media className="align-items-center">
                                                                            <img
                                                                                src={showMore}
                                                                                alt="show more"
                                                                            />
                                                                        </Media>
                                                                    </DropdownToggle>
                                                                    <DropdownMenu
                                                                        className="dropdown-menu-arrow min-width-drop"
                                                                        right
                                                                    >
                                                                        {changeOrder.status === "completed" ? (
                                                                            <>
                                                                                <DropdownItem

                                                                                    onClick={() =>
                                                                                        this.props.onReasonOpen(
                                                                                            changeOrder,
                                                                                            "approved"
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <span>Approve</span>
                                                                                </DropdownItem>
                                                                                <DropdownItem
                                                                                    onClick={() =>
                                                                                        this.props.onReasonOpen(
                                                                                            changeOrder,
                                                                                            "rejected"
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <span>Reject</span>
                                                                                </DropdownItem>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <DropdownItem
                                                                                    onClick={() => {
                                                                                      

                                                                                        this.props.orderEdit(
                                                                                            changeOrder.changeOrder_id
                                                                                        )

                                                                                    }
                                                                                    }
                                                                                >
                                                                                    <span>Edit</span>
                                                                                </DropdownItem>

                                                                            </>
                                                                        )}
                                                                    </DropdownMenu>
                                                                </UncontrolledDropdown>
                                                            </div>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>)
                                        : (this?.props?.type === "workOrders" && this.props.workOrders.length !== 0) ?
                                            (<tbody>
                                                {this.props?.workOrders.map((workOrder, i) => (
                                                    <tr key={i} >
                                                        <td

                                                            className="cursor-point capitalize ptb20"
                                                            onClick={() =>
                                                                workOrder.status === "accepted"

                                                            }
                                                            style={{ width: "100px" }}
                                                        >
                                                            <div className="project_list_content">
                                                                {this.capitalizeFirstLetter(workOrder.name)}
                                                            </div>
                                                        </td>

                                                        <td className="ptb20">
                                                            <div className="project_list_content">
                                                                {" "}
                                                                {workOrder.sub_contractor?.name}
                                                            </div>
                                                        </td>
                                                        <td className="ptb20">
                                                            <div className="project_list_content">
                                                                {" "}
                                                                {showUtcDate(workOrder.createdAt)}
                                                            </div>
                                                        </td>
                                                        <td className="ptb20">
                                                            <div className="project_list_content">
                                                                {" "}
                                                                {showUtcDate(workOrder.due_date)}
                                                            </div>
                                                        </td>
                                                        <td className="ptb20">
                                                            <div className="project_list_content">
                                                                ${workOrder.amount}
                                                            </div>
                                                        </td>
                                                        <td className="ptb20">
                                                            <div
                                                                className="project_list_status"
                                                                style={{
                                                                    color: `${workOrder.status === "completed" ||
                                                                        workOrder.status === "approved"
                                                                        ? "#07A84E"
                                                                        : workOrder.status === "pending"
                                                                            ? "#FF4949"
                                                                            : workOrder.status === "due"
                                                                                ? "#0070FD"
                                                                                : workOrder.status === "rejected"
                                                                                    ? "#F70e0e"
                                                                                    : "#FFCC3D"
                                                                        }`,
                                                                }}
                                                            >
                                                                {" "}
                                                                {workOrder.status === "in_progress"
                                                                    ? "In Progress"
                                                                    : this.capitalizeFirstLetter(workOrder.status)}
                                                            </div>
                                                        </td>

                                                        <td
                                                            style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "flex-end",
                                                                // justifyContent: "center",
                                                                borderTop: "none",
                                                                gap: "10px",
                                                                borderBottom: "1px solid #f5f5f5"
                                                            }}
                                                            className="ptb20"
                                                        >
                                                            <div>
                                                                <img
                                                                    onClick={() =>
                                                                        this.props.taskTimelineOpen(workOrder, "workOrder", workOrder.workOrder_id)
                                                                    }
                                                                    style={{ cursor: "pointer" }}
                                                                    src={projectTimeline}
                                                                    alt="timeline"
                                                                ></img>
                                                            </div>
                                                            {(
                                                                <div style={{ visibility: workOrder.status !== "approved" ? "visible" : "hidden" }} className="project_list_content">
                                                                    {" "}
                                                                    <UncontrolledDropdown
                                                                        nav
                                                                        className="flexy-content position_u"
                                                                    >
                                                                        <DropdownToggle
                                                                            className="pr-0 nav-notice"
                                                                            nav
                                                                        >
                                                                            <Media className="align-items-center">
                                                                                <img
                                                                                    src={showMore}
                                                                                    alt="show more"
                                                                                />
                                                                            </Media>
                                                                        </DropdownToggle>
                                                                        <DropdownMenu
                                                                            className="dropdown-menu-arrow min-width-drop"
                                                                            right
                                                                        >
                                                                            <>
                                                                                <DropdownItem
                                                                                    onClick={() =>
                                                                                        this.props.orderEdit(
                                                                                            workOrder
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <span>Edit</span>
                                                                                </DropdownItem>

                                                                            </>
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                </div>
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>)
                                            : (
                                                <tbody>
                                                    <tr>
                                                        <td ></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td>{`No ${this.capitalizeFirstLetter(this.props.type)} Available`}</td>
                                                        <td></td>

                                                    </tr>

                                                </tbody>
                                            )
                                }
                            </Table>
                        </Card>
                    </div>
                </Row>

            </>
        );
    }
}

export default ProjectDetailTable;
