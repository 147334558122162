import React, { Component } from "react";
import {
  CardHeader,
  Input,
  DropdownToggle,
  UncontrolledDropdown,
  Media,
  DropdownMenu,
  DropdownItem,
  InputGroup,
  InputGroupText,
  Spinner,
  Button,
  Tag,
  Card,
  Dropdown,
} from "reactstrap";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import closeIcon from "../../assets/img/brand/close.svg";
import dateLight from "../../assets/img/brand/datePicker.svg";
import moment from "moment";
import uploadIcon from "../../assets/img/icons/upload_icon.svg";
import { getCloudinaryImage } from "constants/utils";
import removeIcon from "../../assets/img/brand/removeIcon.svg";
import Tags from "components/Tags/Tags";
import ApiService from "constants/ApiService";
import Add from "../../assets/img/brand/addIcon.svg";
import {
  showSuccess,
  isInvalidName,
  showError,
  uploadCloudinary,
} from "constants/utils";
import AddClient from "./addClient";
import TaskList from "views/Task/TaskList";
import Autocomplete from "components/AutoComplete/AutoComplete";
import { showUtcDate } from "constants/utils";

class AddOrder extends Component {
  constructor(props) {
    super(props);
    this.inputKey = Date.now();
    this.fileInputRef = React.createRef();
    this.myElementRef = React.createRef();
    this.myInputElementRef = React.createRef();
    this.state = {
      name: "",
      client_name: "",
      clientsList: [],
      subContractor_name: "",
      sub_contractorList: [],
      start_date: "",
      image: [],
      selected_team: "",
      actual_start: "",
      end_date: "",
      contractor_type:"",
      subContractor:{},
      imageLoading: false,
      desc: "",
      tags: [],
      contractorInput:"",
      btnLoad: false,
      edit: false,
      initAddProjectLoading: true,
      tag: "",
      subContractor_id: "",
      clientAdd: false,
      isSearchOpen: false,
      activeIndex: -1,
      disabled: false,
      isApiCallPending: false,
      file: null,
      projectStartDate: null,
      projectActualStarDate: null,
      projectEndDate: null,
      otherProObj: {},
    };
    this.debouncedApiCall = this.debounce(this.createProjectHandler, 1000);
  }

  debounce(func, delay) {
    let timeoutId;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(context, args);
      }, delay);
    };
  }

  componentDidMount = async () => {
    this.props.type === "project" &&
      this.props.projectId &&
      this.setState({ edit: true });
    this.props.type === "task" &&
      this.props.taskId &&
      this.setState({ edit: true });

    if (this.props.type === "project") {
      let response = await ApiService.getAPI(`contractor/client?status=active`);
      !this.state.edit && this.setState({ initAddProjectLoading: false });
      if (response.success) {
        let { clients } = response.data;
        this.setState({
          clientsList: clients,
        });
      }
      // await this.getClientLists()
      if (this.state.edit) {
        let projectDetail = await ApiService.getAPI(
          `contractor/project/${this.props.projectId}`
        );

        if (projectDetail.success) {
          let { project } = projectDetail.data;
          this.setState({
            initAddProjectLoading: false,
            name: project.name,
            client_name: project.client.name,
            start_date: new Date(project.start_date),
            actual_start: new Date(project.actual_start),
            end_date: new Date(project.end_date),
            image: project.image,
            desc: project.desc,
            tags: project.tags,
          });
        }
      }
    } else if (this.props.type === "task") {
      let response = await ApiService.getAPI(`contractor/all`);
      let projectDetail = await ApiService.getAPI(
        `contractor/project/${this.props.projectId}`
      );
      if (projectDetail.success) {
        let { start_date, actual_start, end_date } = projectDetail.data.project
        this.setState({ projectStartDate: start_date, projectActualStarDate: actual_start, projectEndDate: end_date })
      }
      !this.state.edit && this.setState({ initAddProjectLoading: false });
      if (response.success) {
        let { contractors } = response.data;
        this.setState({ sub_contractorList: contractors });
      }
      if (this.state.edit) {
        let taskDetail = await ApiService.getAPI(
          `contractor/task/${this.props.taskId}`
        );
        if (taskDetail.success) {
          let { task } = taskDetail.data;
          this.setState({
            initAddProjectLoading: false,
            name: task.name,
            selected_team:task.assignType,
            otherProObj:task.sub_contractor,
            subContractor_name: task.sub_contractor.name,
            subContractor_id: task.sub_contractor.id,
            start_date: new Date(task.start_date),
            actual_start: new Date(task.actual_start),
            end_date: new Date(task.end_date),
            // image: task.image,
            desc: task.desc,
            tags: task.tags,
          });
        }
      }
    }
  };

  getClientLists = async () => {
    let response = await ApiService.getAPI(`contractor/client?status=active`);
    !this.state.edit && this.setState({ initAddProjectLoading: false });
    if (response.success) {
      let { clients } = response.data;
      this.setState({
        clientsList: clients,
      });
    }
  };

  imageHandler = async (e) => {
    // this.setState({file:e.target.files[0]},() => {this.imageUploader(this.state.file)})
    let file = e.target.files[0];
    let { image } = this.state;
    if (file) {
      this.setState({ imageLoading: true });
    }
    if (
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/jpeg"
    ) {
      if (file.size < 2e6) {
        let data = await uploadCloudinary(file, "host");
        if (data.url) {
          let imgArr = [...image];
          let imageObj = {
            format: data.format,
            public_id: data.public_id,
            version: `v${data.version}`,
            type: "image",
          };
          imgArr.push(imageObj);
          this.setState({
            image: imgArr,
            imageLoading: false,
          });
        } else {
          this.setState({ imageLoading: false });
          e.target.value = null;
          return showError(this.props.props, "Could not upload image");
        }
      } else {
        this.setState({ imageLoading: false });
        e.target.value = null;
        return showError(this.props.props, "File size should not exceeds 2 MB");
      }
    } else {
      this.setState({ imageLoading: false });
      e.target.value = null;
      return showError(
        this.props.props,
        "Invalid Format , upload only .png, .jpg, .jpeg"
      );
    }
  };

  imageUploader = async (file) => {
    let { image } = this.state;
    if (this.fileInputRef.current) {
      this.fileInputRef.current.value = "";
    }
    if (file) {
      this.setState({ imageLoading: true });
    }
    if (
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/jpeg"
    ) {
      if (file.size < 2e6) {
        let data = await uploadCloudinary(file, "host");
        if (data.url) {
          let imgArr = [...image];
          let imageObj = {
            format: data.format,
            public_id: data.public_id,
            version: `v${data.version}`,
            type: "image",
          };
          imgArr.push(imageObj);
          this.setState({
            image: imgArr,
            imageLoading: false,
          });
        } else {
          this.setState({ imageLoading: false });
          return showError(this.props.props, "Could not upload image");
        }
      } else {
        this.setState({ imageLoading: false });
        return showError(this.props.props, "File size should not exceeds 2 MB");
      }
    } else {
      this.setState({ imageLoading: false });
      return showError(
        this.props.props,
        "Invalid Format , upload only .png, .jpg, .jpeg"
      );
    }
  };

  addClient = () => {
    this.setState({ clientAdd: true });
  };

  deleteImage = () => {
    this.setState({ image: [], image_name: "" });
  };

  closeModal = () => {
    this.setState({
      start_date: "",
      actual_start: "",
      client_name: "",
      name: "",
      end_date: "",
      tags: [],
      desc: "",
    });
    this.props.close();
  };
  handleSelect = (contractor) => {

    this.setState({ otherProObj: { id: contractor.contractor_id, name: contractor.name, phone: contractor.phone, email: contractor.email },contractorInput:contractor.userInput })

  }
  createProjectHandler = async () => {
    let {
      name,
      client_name,
      start_date,
      end_date,
      actual_start,
      client_id,
      subContractor_id,
      desc,
      edit,
      tags,
      image,
      subContractor_name,
      otherProObj,
      selected_team,
      contractorInput
    } = this.state;

    let { type } = this.props;
    if (!name) return showError(this.props.props, "Please enter a name");

    else if (isInvalidName(name))
      return showError(this.props.props, "Please enter a valid name");
    else if (type === "project" ? !client_name : ((Object.keys(otherProObj).length === 0) || !contractorInput))
      return showError(
        this.props.props,
        `Please select a ${type === "project" ? "Client" : "Sub contractor"}`
      );
    else if (!start_date)
      return showError(this.props.props, "Please select a start date");
    else if (type === 'task' && moment(start_date).isBefore(this.state.projectStartDate)) {
      return showError(this.props.props, `Start date should be greater than ${showUtcDate(this.state.projectStartDate)} `)
    }
    else if (!actual_start)
      return showError(this.props.props, "Please select a actual start date");
    else if (!end_date)
      return showError(this.props.props, "Please select a end date");
    else if (type === 'task' && moment(end_date).isAfter(this.state.projectEndDate)) {
      return showError(this.props.props, `End date should be less than ${showUtcDate(this.state.projectEndDate)}`)
    }
    // else if ( (start_date > actual_start )) return showError(this.props.props,"Actual start could not be lesser than start date")
    this.setState({ btnLoad: true, isApiCallPending: true });
    if (this.props.type === "project") {
      let data = {
        name: name,
        client: {
          id: client_id,
          name: client_name,
        },
        start_date: start_date,
        end_date: end_date,
        actual_start: actual_start,
        desc: desc,
        tags: tags,
        image: image,
      };
      let response = edit
        ? await ApiService.patchAPI(
          `contractor/project/${this.props.projectId}`,
          data
        )
        : await ApiService.postAPI("contractor/project", data);
      this.setState({ disabled: false, isApiCallPending: false });
      if (response.success) {
        showSuccess(this.props.props, response.message);
        this.setState({
          btnLoad: false,
          open: false,
          name: "",
          start_date: "",
          end_date: "",
          actual_start: "",
          desc: "",
          tags: [],
          client_name: "",
          client_id: "",
        });
        this.closeModal();
      } else {
      }

      this.setState({ btnLoad: false });
      showError(this.props.props, response.message);
    } else if (this.props.type === "task") {

      let sub_contractor = { ...otherProObj }

      let data = {
        name: name,
        sub_contractor: sub_contractor,
        start_date: start_date,
        end_date: end_date,
        actual_start: actual_start,
        desc: desc,
        tags: tags,
        image: image,
        assignType:selected_team
      };
      let response = edit
        ? await ApiService.patchAPI(
          `contractor/task/${this.props.taskId}`,
          data
        )
        : await ApiService.postAPI(
          `contractor/task/${this.props.projectId}`,
          data
        );
      if (response.success) {
        showSuccess(this.props.props, response.message);
        this.setState({
          btnLoad: false,
          open: false,
          name: "",
          start_date: "",
          end_date: "",
          actual_start: "",
          desc: "",
          tags: [],
          subContractor_name: "",
          subContractor_id: "",
        });
        this.closeModal();
      } else {
      }

      this.setState({ btnLoad: false });
    }
  };

  onInputSearch = (e) => {
    let tempLists = this.state.clientsList;
    let searchName = e.target.value;
    // if(!searchName.length) return tempLists = []
    const tempArray = tempLists.filter((obj) => {
      let name = obj.name;
      // return obj.name.trim().toLowercase().includes(searchName) && obj
    });
  };

  onTagChange = (e) => {
    let { tag, tags } = this.state;
    if (["Enter", "Tab"].includes(e.key)) {
      e.preventDefault();
      let value = tag.trim();
      if (value.length) {
        tags.push(value);
        this.setState({ tags: [...tags], tag: "" });
      }
    } else if (["Backspace"].includes(e.key) && !tag) {
      if (tags.length) {
        tags.pop();
        this.setState({ tags: [...tags], tag: "" });
      }
    }
  };

  dateContion = (from, event) => {
    if (from === 'start') {
      if (this.props.type === "project") {
        this.setState({ start_date: event })
      };
    }
  };

  removeTag = (index) => {
    let { tag, tags } = this.state;
    let tempTags = tags;
    tempTags.splice(index, 1);
    this.setState({ tags: [...tempTags] });
  };

  onTagAreaClick = () => {
    this.myInputElementRef.current.focus();
  };

  onClientClose = () => {
    this.setState({ clientAdd: false }, () => {
      this.getClientLists();
    });
  };

  render() {
    const {
      name,
      client_name,
      clientsList,
      start_date,
      actual_start,
      end_date,
      image,
      imageLoading,
      desc,
      tags,
      btnLoad,
      edit,
      subContractor_name,
      sub_contractorList,
      otherProObj,
      initAddProjectLoading,
      selected_team,
      isSearchOpen,
      activeIndex,
    } = this.state;
    return initAddProjectLoading ? (
      <div className="spin-loading">
        <Spinner
          style={{
            height: "30px",
            width: "30px",
          }}
          type="border"
          color="#012A4D"
        />
      </div>
    ) : (
      <>
        <div>
          <CardHeader>
            <div className="flex">
              <div className="fw-500 f16 cl000000"></div>
              <img
                src={closeIcon}
                onClick={() => this.closeModal()}
                className="point"
              />
            </div>
          </CardHeader>
          <div className="cardContainer">
            <div style={{ marginBottom: "20px" }}>
              {" "}
              <div
                className="cl2A1E17 mb-15 f14 fw-400 drawer_sub_heading"
                style={{ marginTop: "15px" }}
              >
                Name*
              </div>
              <Input
                style={{
                  fontSize: "14px",
                  color: "#212529",
                  border: "1px solid #f0f0f0",
                }}
                type="text"
                value={name}
                name="name"
                onChange={(e) => {
                  this.setState({ name: e.target.value });
                }}
                className="mb-20"
              />
            </div>
            <div style={{ marginBottom: "20px" }}>
              {" "}
              <div
                className="cl2A1E17 mb-15 f14 fw-400 drawer_sub_heading"
                style={{ marginTop: "15px" }}
              >
                Price*
              </div>
              <Input
                style={{
                  fontSize: "14px",
                  color: "#212529",
                  border: "1px solid #f0f0f0",
                }}
                type="Number"
                value={start_date}
                name="name"
                onChange={(e) => {
                  this.setState({ name: e.target.value });
                }}
                className="mb-20"
              />
            </div>
        

            <div >
           
                <div className="cl2A1E17 mb-15 f14 fw-400 drawer_sub_heading">
                  Purchase Date*
                </div>
                <InputGroup
                  style={{ border: "1px solid #f0f0f0" }}
                  className="mb-0  flex-content notice-date point"
                >
                  <DatePicker
                    selected={start_date}
                    minDate={this.props.type === 'project' ? new Date() : new Date(moment(this.state.projectStartDate))}
                    // minDate={start_date}
                    // minDate={ this.props.type === 'project' ? new Date() :  }
                    maxDate={this.props.type === 'project' ? new Date(moment(actual_start).subtract(1, "days")) : new Date(moment(this.state.projectEndDate))}
                    dateFormat="MM-dd-yyyy"
                    placeholderText=""
                    className="checkOutDatePick point border_trans"
                    onChange={(e) => {
                      this.setState({ start_date: e });
                    }}
                    // onChange = {(e) => this.dateContion('start',e)}
                    name="start_date"
                    id="start_date"
                    value={start_date}
                    showYearDropdown
                    showMonthDropdown
                    style
                    ariaDescribedBy="basic-addon2"
                  />

                  <InputGroupText
                    id="basic-addon2"
                    className=""
                    style={{ border: "transparent" }}
                  >
                    <label
                      className="mb-remove cursor-point"
                      htmlFor="scheduleOn"
                    >
                      <img
                        src={dateLight}
                        alt="Date-Picker Icon"
                        className="date-svg"
                      />
                    </label>
                  </InputGroupText>
                </InputGroup>
             
            
                  
            </div>

            {this.props.type === "project" && (
              <div style={{ marginTop: "20px" }}>
                <div
                  style={{ paddingBottom: "15px" }}
                  className="drawer_sub_heading"
                >
                  Image
                </div>
                {
                  <div
                    className="requestImageUploader point form-relative"
                    style={{
                      width: "100px",
                      height: "100px",
                      cursor: "pointer",
                    }}
                  >
                    {image?.length > 0 && !imageLoading && (
                      <>
                        <img
                          src={getCloudinaryImage(100, 50, image[0], "c_fill")}
                          alt="Logo Preview"
                        // style={{ maxWidth: "100%", maxHeight: "50px" }}
                        />
                        <img
                          onClick={this.deleteImage}
                          style={{
                            position: "absolute",
                            top: "3px",
                            maxWidth: "13px",
                            marginLeft: "2px",
                            cursor: "pointer",
                            right: "3px",
                          }}
                          src={removeIcon}
                          alt="delete"
                        />
                      </>
                    )}
                    {image?.length < 1 && (
                      <div className="point form-relative">
                        {imageLoading ? (
                          <div className="spin-loading3">
                            <Spinner
                              style={{
                                height: "20px",
                                width: "20px",
                              }}
                              type="border"
                              color="#0A8080"
                            />
                          </div>
                        ) : (
                          <div>
                            <Input
                              type="file"
                              key={this.inputKey}
                              ref={this.fileInputRef}
                              accept=".jpg, .png, .jpeg,"
                              onChange={this.imageHandler}
                              // onClick={(e) => this.imageHandler(e)}
                              className="form-control-alternative form-control opac-hide "
                              id="ImageUpload"
                              multiple={false}
                            />
                            <label htmlFor="ImageUpload " className="mb-0">
                              <div className="flexmid point">
                                <img src={Add} className="point" alt="ADD" />
                              </div>
                            </label>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                }
              </div>
            )}

            <div style={{ marginTop: "20px" }}>
              <div
                style={{ marginBottom: "15px" }}
                className="drawer_sub_heading"
              >
                Description
              </div>
              <div>
                {" "}
                <textarea
                  className="text-area-add-project"
                  value={desc}
                  style={{
                    fontSize: "14px",
                    color: "#212529",
                    border: "1px solid #f0f0f0",
                  }}
                  name="notes"
                  onChange={(e) => {
                    this.setState({ desc: e.target.value });
                  }}
                />
              </div>
            </div>

        
            <div style={{marginTop:'30px'}} className="flexEnd">
              <div
                className="mr-20 point f14 button-secondary"
                style={{ fontWeight: "500" }}
                onClick={() => this.closeModal()}
              >
                Cancel
              </div>
              <Button
                color="primary"
                className="button-wrapping "
                size="sm"
                onClick={() => this.createProjectHandler()}
                disabled={this.state.isApiCallPending}
              >
                {btnLoad ? (
                  <div className="spin-loading-save w100 h100">
                    <Spinner
                      style={{
                        height: "20px",
                        width: "20px",
                      }}
                      type="border"
                      color="#0A8080"
                    />
                  </div>
                ) : edit ? (
                  "Update"
                ) : (
                  "Save"
                )}
              </Button>
            </div>
          </div>
        </div>
        <Card
          className={this.state.clientAdd ? "exportSideBar" : "toggle-sidebar"}
        >
          {this.state.clientAdd && (
            <AddClient
              props={this}
              from="project"
              closeClientModal={this.onClientClose}
            />
          )}
        </Card>
      </>
    );
  }
}
export default AddOrder;
