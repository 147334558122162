import React, { Component } from "react";
import info from "../../assets/img/brand/infoIcon.svg";
import { remove_underscore_capitalize } from "constants/utils";

class TableFormatter extends Component {
  constructor(props) {
    super(props);
    this.state= {
      headClass: ["name", "desc" , "qty" , "labor" , "material" , "markup" , "cost" ,"amount"],
    };
  }
  render() {
    const { children } = this.props;
    const rowsValues = Object.values(this.props.rows);
    const rowKeys = Object.keys(this.props.rows);
    return (
      <table
        className="estimateTableTable"
        style={{ width: "100%" }}
        responsive
      >
        <thead className="thead-light bordered-table">
          <tr>
            {rowsValues.map((row, index) => (
              <th
                key={index}
                scope="col"
                id={row.id}
                className={`font_standard ${this.state.headClass[index]}`}
              >
                {remove_underscore_capitalize(row.name)}

                {["quantity", "labor", "material", "markup", "cost"].includes(
                  row.name
                ) && (
                  <i
                    className={
                      row.is_enabled
                        ? "fa fa-eye cursor-point"
                        : "fa fa-eye-slash cursor-point"
                    }
                    onClick={() => this.props.eyeFunction(rowKeys[index])}
                    style={{ marginLeft: "7px" }}
                  ></i>
                )}
              </th>
            ))}
          </tr>
        </thead>
        {children}
      </table>
    );
  }
}

export default TableFormatter;
