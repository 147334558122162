import { allowOnlyText } from "constants/utils";
import React, { Component } from "react";
import { Card, CardBody, CardHeader, Spinner, Button, Input } from "reactstrap";
import closeIcon from "../../assets/img/icons/whiteCloseIcon.svg";

class AddService extends Component {
  constructor(props) {
    super(props);
    this.state = {
      btnLoading: false,
      service_name: "",
    };
  }

  handleInputChange = (e) => {
    this.setState({
      service_name: allowOnlyText(e.target.value),
    });
  };

  closeModal = () => {
    this.props.close();
    this.setState({ service_name: "", btnLoading: false });
  };

  addService = async () => {
    let { service_name } = this.state;
    let { toast } = this.props;

    if (
      service_name === "" ||
      service_name === null ||
      service_name === undefined
    ) {
      toast("Please enter service name");
    } else {
      this.setState({ btnLoading: true });
      this.props.add(service_name);
    }
  };

  render() {
    let { btnLoading, service_name } = this.state;
    return (
      <div>
        {this.props.isOpen && (
          <Card className="bnone">
            <CardHeader>
              <div className="flex">
                <div className="fw-500 f15 col_white">Add Service</div>
                <img
                  src={closeIcon}
                  onClick={() => this.closeModal()}
                  className="point"
                  alt="close icon"
                />
              </div>
            </CardHeader>
            <CardBody className="pad30p ">
              <div>
                <div className="mb-15 cl2A1E17 f14 fw-400">
                  Service Name <span style={{ color: "red" }}>*</span>
                </div>
                <Input
                  type="text"
                  value={service_name}
                  name="service_name"
                  onChange={this.handleInputChange}
                  className="mb-50"
                  maxLength={50}
                />
              </div>
              <div className="flexEnd">
                <div
                  className="mr-20 point f14 button-secondary"
                  onClick={() => this.closeModal()}
                >
                  Cancel
                </div>
                <Button
                  color="primary"
                  className="button-wrapping "
                  size="sm"
                  onClick={() => this.addService()}
                >
                  {btnLoading ? (
                    <div className="spin-loading-save w100 h100">
                      <Spinner type="border" color="#0A8080" />
                    </div>
                  ) : (
                    <div>Add</div>
                  )}
                </Button>
              </div>
            </CardBody>
          </Card>
        )}
      </div>
    );
  }
}

export default AddService;
