/* eslint-disable react/jsx-pascal-case */
import React, { Component, useEffect, useState } from "react";
import projectEdit from "../../../../src/assets/img/icons/project_edit.svg";
import { Custom_Button } from "components/Buttons/Button";
import moment from "moment";
import error from "../../../assets/img/new Logo/error.svg";
import { Progress } from "antd";
import { Progress as reactProgress } from "reactstrap";

import projectTimeline from "../../../../src/assets/img/brand/projectTimeline.svg";
import { CustomListingContainer } from "components/Container/CustomListingContainer";
import timelineIcon from "../../../assets/img/brand/invoiceTimeline.svg";
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Card,
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Media,
  Modal,
} from "reactstrap";
import ProcessingLottie from "../../../assets/Lotties/processingLottie copy.json";
import VerifySuccess from "../../../assets/Lotties/successLottie.json";
import Env from "constants/Env";
// import UpdateTask from  ;
import Lottie from "react-lottie";
import showMore from "../../../assets/img/brand/showMore.svg";
import ApiService from "constants/ApiService";
import CustomSpinner from "../../../components/CustomSpinner/CustomSpinner";
import { Button_without_Border } from "components/Buttons/Button";
import TimeLine from "components/Timeline/Timeline";
import AddProject from "../addProject";
import AddChangeOrder from "../addChangeOrder";
import AddWorkOrder from "../addWorkOrder";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

import { Spinner } from "@react-pdf-viewer/core";
import UpdateStatus from "../updateStatus";

import {
  capitalizeFirstLetter,
  formatAmountWithCommas,
  CopyEstimateLink,
} from "constants/utils";

import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import AddSchedules from "views/Crm/addSchedule";
import {
  showError,
  returnSearchObject,
  showSuccess,
  getCookie,
  remove_underscore_capitalize,
  getAuthToken,
} from "constants/utils";
import PayMethod from "./payMethod";
import { showUtcDate, downloadDocuments } from "constants/utils";
import ReactTooltip from "react-tooltip";
import MarkPaymentSideBar from "views/Invoices/MarkPaymentSideDragger";
import ToastList from "components/Toast/ToastList";
import CancelInvoiceModal from "views/Invoices/CancelInvoiceModal";
import DeleteModal from "views/Project/DeleteModal";
import { getTabPanelUtilityClass } from "@mui/lab";
import UpdateTask from "views/Task/updateTask";
import { ListingHeaderLayout } from "components/Container/CustomListingContainer";
import { checkFileType } from "constants/utils";
import { checkDocType } from "constants/utils";
import { uploadCloudinary } from "constants/utils";
import { AddDocument } from "components/AddDocument/addDocument";
import AddDocumentSidebar from "./AddDocumentSidebar";
import CancelModal from "views/Project/cancelModal";
import ToolTip from "components/ToolTip/ToolTip";
import ChangeOrderList from "../changeOrderList";
import WorkOrderList from "../workOrderList";
let project_id;

function ProjectDetail_overview({
  project_details,
  props,
  updateProjectDetail,
}) {
  const location = useLocation();
  const history = useHistory();
  const [openDocumentSidebar, setOpenDocumentSidebar] = useState(false);
  const [project, setProject] = useState(project_details);
  const [activeTab, setActiveTab] = useState(1);
  const [bottomActiveTab, setBottomActiveTab] = useState(1);
  const [bottomSpinner, setBottomSpinner] = useState(false);
  const [taskLists, setTaskLists] = useState([]);
  const [ordersLists, setOrderLists] = useState([]);
  const [workOrderLists, setWorkOrderLists] = useState([]);
  const [timeLineOpen, setTimeLineOpen] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [isUpdateOpen, setIsUpDateOpen] = useState(false);
  const [openChangeOrder, setOpenChangeOrder] = useState(false);
  const [openWorkOrders, setOpenWorkOrders] = useState(false);
  const [taskId, setTaskId] = useState();
  const [changeOrderId, setChangeOrderId] = useState();
  const [worKOrderId, setWorkOrderId] = useState(null);
  const [id, setId] = useState("");
  const [projectId, setProjectId] = useState(project_details.project_id);
  const [timeLine, setTimeLine] = useState({});
  const [from, setFrom] = useState("task");
  const [invoiceList, setInvoiceList] = useState({});
  const [documentList, setDocumentsList] = useState({});
  const [paymentList, setPaymentList] = useState({});
  const [openPayemnt, setOpenPayemnt] = useState(false);
  const [taskbtnLoad, setTaskbtnLoad] = useState(false);
  const [invoiceAmount, setInvoiceAmount] = useState(null);
  const [invoiceId, setInvoiceId] = useState(null);
  const [statusDetail, setStatusDetail] = useState();
  const [uploaded, setUploaded] = useState([]);
  const [image, setImage] = useState({});
  const [deleteDocModal, setDeleteDocModal] = useState(false);
  const [status, setStatus] = useState(false);
  const [deleteDocId, setDeleteDocId] = useState("");
  const [acceptOrReject, setAcceptOrReject] = useState({
    isOpen: false,
    approve: false,
    reject: false,
    reason: null,
    id: null,
  });
  const [toastType, setToastType] = useState("");
  const [response, setResponse] = useState("");
  const [toast, setToast] = useState("");
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [openPaymentStatusModal, setOpenPaymentStatusModel] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [popupLoading, setPopLoading] = useState(true);
  const [markPaymentModal, setMarkPaymentModal] = useState(false);
  const [cancelInvoiceModal, setCancelInvoiceModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [openProgressModel, setOpenProgressModel] = useState(false);
  const [viewAllChangeOrder, setViewAllChangeOrder] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const styleForDropDown = { padding: "0.7rem 1rem" };
  // const [toast, setToast] = useState({
  //   toast: false,
  //   toastType: "",
  //   response: "",
  // });
  const WHICH_TAB_1 = {
    1: () => setOpenAdd(true),
    2: () => setOpenChangeOrder(true),
    3: () => {
      history.push({
        pathname: `/admin/projects/detail/workOrder/${projectId}`,
      });
    },
  };
  const WHICH_TAB_2 = {
    2: () => setOpenPayemnt(true),
  };
  useEffect(() => {
    const state = props.props?.location?.state;
    if (state?.tab) {
      setActiveTab(state.tab);
    }
  }, []);

  useEffect(() => {
    setProject(project_details);
  }, [project_details]);

  useEffect(() => {
    getPaymentStatus();
  }, []);

  useEffect(() => {
    acceptOrReject.approve && approve_reject();
  }, [acceptOrReject.approve]);

  // useEffect(() => {
  //   taskId && setOpenProgressModel(true);
  // }, [taskId]);

  // useEffect(() => {},[openProgressModel])

  useEffect(() => {
    getTaskLists();
    getWorkOrderList();
    getInvoiceDetails();
    getPaymentDetails();
    getChangeOrderList();
    getDocuments();
  }, []);

  const ProcessingOptions = {
    loop: true,
    autoplay: true,
    animationData: ProcessingLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const SuccessVerifyOptions = {
    loop: true,
    autoplay: true,
    animationData: VerifySuccess,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const getInvoiceDetails = async () => {
    setBottomSpinner(true);
    let response = await ApiService.getAPI(
      `contractor/crmPayment/invoices/${projectId}?page=1&limit=5`
    );
    setBottomSpinner(false);
    if (response.success) {
      setInvoiceList(response.data.invoices);
    }
  };

  const getDocuments = async () => {
    setDeleteDocId("");
    setDeleteDocModal(false);
    setBottomSpinner(true);
    let response = await ApiService.getAPI(
      `contractor/project/document/${projectId}`
    );
    setBottomSpinner(false);
    if (response.success) {
      setDocumentsList(response.data.documents);
    }
  };

  function getPaymentStatus() {
    let queryStatus = props.props.location.search;
    let queryObj = returnSearchObject(queryStatus);
    if (queryStatus) {
      if (queryObj.pay_status === "success") {
        setOpenPaymentStatusModel(true);
        setTimeout(() => {
          getData();
        }, 10000);
      } else if (queryObj.pay_status === "failure") {
        setPaymentStatus("failure");
        setPopLoading(false);
        setOpenPaymentStatusModel(true);
        setTimeout(() => {
          setOpenPaymentStatusModel(false);
        }, 10000);
      }
      removeSearchFromUrl();
    }
  }

  function updateProgress(id) {
    setOpenProgressModel(true);
    setTaskId(id);
  }

  function removeSearchFromUrl() {
    const { pathname, hash } = location;
    history.replace(pathname + hash); // Replace current URL with a new one without search part
  }

  async function getData() {
    let id = getCookie("invoice_id");
    let response = await ApiService.getAPI(
      `contractor/crmPayment/transaction/${id}`
    );
    if (response.success) {
      if (response.data.transaction.status === "paid") {
        setStatusDetail(response.data.transaction);
        setPaymentStatus("success");
        setPopLoading(false);
        setTimeout(() => {
          setOpenPaymentStatusModel(false);
          getInvoiceDetails();
        }, 10000);
      } else if (response.data.transaction.status === "processing") {
        setPaymentStatus("processing");
        setPopLoading(false);
        setTimeout(() => {
          setOpenPaymentStatusModel(false);
          getInvoiceDetails();
        }, 10000);
      } else {
        setPaymentStatus("failure");
        setPopLoading(false);
        setTimeout(() => {
          setOpenPaymentStatusModel(false);
        }, 10000);
      }
    }
  }

  const getPaymentDetails = async () => {
    setBottomSpinner(true);
    let response = await ApiService.getAPI(
      `contractor/crmPayment/payments/${projectId}?page=1&limit=5`
    );
    setBottomSpinner(false);
    if (response.success) {
      setPaymentList(response.data.invoices);
    }
  };

  // function Showtoast(type, message) {
  //   setToast({
  //     toast: true,
  //     toastType: type,
  //     response: message,
  //   });
  //   clearToast();
  // }

  // function clearToast() {
  //   setTimeout(
  //     () => setToast({ toast: false, toastType: "", response: "" }),
  //     2000
  //   );
  // }

  function toggleTab(tab) {
    setActiveTab(tab);
    setTimeLineOpen();
  }

  function toggleBottomTab(tab) {
    setBottomActiveTab(tab);
  }

  function calculateTotalAmount(array) {
    return array.reduce(
      (accumulator, currentValue) =>
        accumulator + currentValue.quantity * currentValue.rate,
      0
    );
  }

  async function getTaskLists() {
    setSpinner(true);
    let taskList = await ApiService.getAPI(
      `contractor/task?project_id=${project.project_id}&page=1&&limit=5`
    );
    setSpinner(false);
    setTaskLists(taskList?.data?.tasks || []);
    updateProjectDetail();
  }

  async function getChangeOrderList() {
    // setSpinner(true);
    let response = await ApiService.getAPI(
      `contractor/changeOrder/${project.project_id}?page=1&limit=5`
    );
    // setSpinner(false);
    if (response.success) {
      setOrderLists(response.data.slice(0, 5) || []);
    }
  }

  async function getWorkOrderList() {
    // setSpinner(true);

    let response = await ApiService.getAPI(
      `contractor/workOrder?project_id=${project.project_id}&page=1&limit=5`
    );
    // setSpinner(false);

    if (response.success) {
      setWorkOrderLists(response.data || []);
    }
  }

  function onTimLineClick(obj, from) {
    setTimeLineOpen(true);
    setTimeLine({
      id:
        from === "task"
          ? obj.task_id
          : from === "order"
          ? obj.changeOrder_id
          : from === "invoice"
          ? obj.invoice_id
          : from === "payment"
          ? obj.invoice_id
          : from === "workOrder"
          ? obj.workOrder_id
          : from === "changeOrder"
          ? obj.changeOrder_id
          : "",
      from: from,
    });
  }

  function closeTimeline() {
    setTimeLineOpen(false);
    setTimeLine({
      id: "",
      from: "",
    });
  }

  function download(data) {
    fetch(Env.BASE_URL + `contractor/estimate/download/${data.workOrder_id}`, {
      method: "GET",
      headers: { "x-consumer-username": getAuthToken() },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${data.workOrder_id}`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {});
  }

  function handlePreview(order) {
    history.push({
      pathname: "/admin/estimates/preview",
      search: `?workOrder_id=${order.workOrder_id}`,
      state: {
        from: "list",
        isWorkOrder: true,
        projectId: projectId,
      },
    });
  }

  function accept_or_Reject(action, id) {
    setAcceptOrReject((prev) => ({
      ...prev,
      id: id,
      reason: "",
      approve: action === "approve" ? true : false,
      reject: action === "reject" ? true : false,
      isOpen: action === "approve" ? false : true,
    }));
  }

  function cancelWorkOrder(id) {
    setCancelModal(true);
    setChangeOrderId(id);
  }

  function closeDeleteModal(val) {
    worKOrderId && setDeleteModal(false);
    setWorkOrderId(null);
    val && worKOrderId && getWorkOrderList();
  }

  function closeCancelModal(val) {
    setCancelModal(false);
    taskId && setTaskId(null);
    changeOrderId && setChangeOrderId(null);
    val && taskId && getTaskLists();
    val && changeOrderId && getChangeOrderList();
  }

  function closeProgressModal(val) {
    taskId && setTaskId(null);
    setOpenProgressModel(false);
    val && taskId && getTaskLists();
  }

  async function approve_reject() {
    const { approve, reject, reason } = acceptOrReject;
    if (reject && !reason) return showError(props, "Please enter the reason");
    setTaskbtnLoad(true);
    let response = await ApiService.patchAPI(
      `contractor/task/${approve ? "approved" : "rejected"}/${
        acceptOrReject.id
      }`,
      {
        notes: reason || "",
      }
    );
    setTaskbtnLoad(false);
    setAcceptOrReject((prev) => ({
      isOpen: false,
      id: null,
      reason: null,
      approve: false,
      reject: false,
    }));
    if (response.success) {
      showSuccess(props, response.message);
      getTaskLists();
      getInvoiceDetails();
    } else {
      showError(props, response.message);
    }
  }

  function handleDelete() {}

  function add(which, tab) {
    if (which === "upper") WHICH_TAB_1[tab]();
    else if (which === "bottom") WHICH_TAB_2[tab]();
  }

  function onAddClose(value) {
    setId("");
    if (value) setOpenAdd(false);
    else {
      setOpenAdd(false);
      activeTab === 1 && getTaskLists();
      // activeTab === 2 && getChangeOrderList();
    }
  }

  function openChangeOrderDetail(e, id) {
    setId(id);
    setOpenChangeOrder(true);
  }

  async function cancelTask(id) {
    setCancelModal(true);
    setTaskId(id);
    // let response = await ApiService.patchAPI(`contractor/task/cancelled/${id}`);
    // if (response.success) {
    //   Showtoast("success", response.message);
    //   getTaskLists();
    // } else {
    //   Showtoast("danger", response.message);
    // }
  }

  function onEdit(obj, splitUp = false) {
    activeTab === 1 && setId(obj.task_id);
    // activeTab === 2 && setId(obj.changeOrder_id);
    activeTab === 3 &&
      history.push({
        pathname: `/admin/projects/detail/workOrder/${projectId}`,
        search: `?workOrder_id=${obj.workOrder_id}`,
        state: { splitUp: splitUp },
      });
    setOpenAdd(true);
  }

  async function resendWorkOrder(request) {
    let response = await ApiService.postAPI(
      `contractor/workOrder/resend/${request.workOrder_id}`
    );
    if (response.success) {
      Showtoast("success", response.message);
      this.getWorkOrderList();
    } else {
      Showtoast("danger", response.message);
    }
  }

  function onUpdateClose(value) {
    if (value) setIsUpDateOpen(false);
    else {
      updateProjectDetail();
      setIsUpDateOpen(false);
    }
  }

  function editProgress() {}

  const openMarkPaymentSideDragger = (id) => {
    setMarkPaymentModal(true);
    setInvoiceId(id);
  };

  const closeMarkPaymentSideDraggger = (msg) => {
    if (msg) {
      showSuccess(props, msg);
      // getInvoiceDetails();
      getPaymentDetails();
    }
    setMarkPaymentModal(false);
  };
  const displayErrorMessage = (msg) => {
    if (msg) {
      showError(props, msg);
    }
  };
  const toggleCancelInvoiceModal = (msg) => {
    setCancelInvoiceModal(false);
    if (msg) {
      showSuccess(props, msg);
      getInvoiceDetails();
    }
  };

  async function duplicate(id) {
    try {
      let response = await ApiService.postAPI(
        `contractor/workOrder/duplicate/${id}`
      );
      if (response.success) {
        Showtoast("success", response.message);
        let { workOrder_id } = response.data;
        setTimeout(() => {
          props.props.history.push({
            pathname: `/admin/projects/detail/workOrder/${projectId}`,
            search: `?workOrder_id=${workOrder_id}`,
          });
        }, 1000);
      } else throw new Error(response.message);
    } catch (err) {
      Showtoast("danger", err.message);
    }
  }

  async function markAsComplete(e, id) {
    e.stopPropagation();
    try {
      let response = await ApiService.patchAPI(
        `contractor/changeOrder/completed/${id}`
      );
      if (response.success) {
        Showtoast("success", response.message);
        getChangeOrderList();
      }
    } catch (err) {
      Showtoast("danger", err.message);
    }
  }

  function onLinkClick(id) {
    CopyEstimateLink(id, "work_order");
    Showtoast("success", "Link Copied");
  }

  function Showtoast(type, message) {
    setToast({
      toast: true,
      toastType: type,
      response: message,
    });
    clearToast();
  }

  function getTaskDetail(id, status) {
    setOpenAdd(true);
    setId(id);
    // setStatus(status);
  }

  function getWorkOrderDetailPage(id) {
    history.push({
      pathname: `/admin/projects/detail/workOrder/${projectId}`,
      search: `?workOrder_id=${id}`,
    });
  }

  function clearToast() {
    setTimeout(
      () => setToast({ toast: false, toastType: "", response: "" }),
      2000
    );
  }

  function closeChangeOrder(val) {
    setOpenChangeOrder(false);
    setId("");
    val && getChangeOrderList();
  }

  const openCancelInvoicePopup = (id) => {
    setCancelInvoiceModal(true);
    setInvoiceId(id);
  };

  const addDocumentImg = async (e) => {
    //   if (e.target.files.length > 0) {
    //     let file = e.target.files[0];
    //     setSpinner(true)
    //     if (
    //       file.type === "image/png" ||
    //       file.type === "image/jpg" ||
    //       file.type === "image/jpeg"
    //     ) {
    //       if (file.size < 1000000) {
    //         let data = await uploadCloudinary(file, "addDocument");
    //         if (data.url) {
    //           setImage({ format: data.format, public_id: data.public_id, version: `v${data.version}`, type: "image" })
    //           // uploadDocument()
    //           setSpinner(false)
    //         } else {
    //           showError("Could not upload image");
    //           setSpinner(false)
    //         }
    //       } else {
    //         showError("File size should not exceeds 1 MB");
    //         setSpinner(false)
    //       }
    //     } else {
    //       showError("Invalid Format");
    //       setSpinner(false)
    //     }
    //   }
  };
  const onClose = () => {
    setOpenDocumentSidebar(false);
  };
  const completed = () => {
    setOpenDocumentSidebar(false);
    getDocuments();
  };

  return (
    <>
      {toast.toast ? (
        <ToastList message={toast.response} type={toast.toastType} />
      ) : (
        ""
      )}
      <div
        style={{
          borderRadius: "10px",
          border: "1px solid #E1E8F0",
          backgroundColor: "#FFFFFF",
          padding: "20px 4px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            padding: "0 1rem",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "2rem",
              }}
            >
              <div
                style={{
                  fontSize: "20px",
                  fontWeight: "500",
                  color: "#333",
                }}
              >
                {project?.name}
              </div>
              {/* <div>
                <img
                  src={projectEdit}
                  alt="project edit"
                  style={{ width: "17px", cursor: "pointer" }}
                  onClick={() => this.editProject()}
                />
              </div> */}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <div>
                <Custom_Button
                  isPrimary={true}
                  btnLabel="Update Status"
                  click={() => {
                    setIsUpDateOpen(true);
                  }}
                />
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "12px",
              gap: "4rem",
            }}
          >
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                }}
              >
                <p
                  style={{
                    paddingRight: "10px",
                    color: "#757575",
                    fontSize: "14px",
                  }}
                  className="project_detail_client mb-remove"
                >
                  Client
                </p>
                {project?.client?.name ? (
                  <p
                    style={{
                      color: "#333",
                      fontSize: "15px",
                      fontWeight: "500",
                    }}
                  >
                    {project?.client?.name}
                  </p>
                ) : (
                  <div>-</div>
                )}
              </div>
            </div>

            <div class="vertical-border"></div>
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                }}
              >
                <p
                  style={{
                    paddingRight: "10px",
                    color: "#757575",
                    fontSize: "14px",
                  }}
                  className="project_detail_client mb-remove"
                >
                  Start Date
                </p>
                {project.start_date ? (
                  <p
                    style={{
                      color: "#333",
                      fontSize: "15px",
                      fontWeight: "500",
                    }}
                  >
                    {showUtcDate(project.start_date)}
                  </p>
                ) : (
                  <div>-</div>
                )}
              </div>
            </div>
            <div class="vertical-border"></div>
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                }}
              >
                <p
                  style={{
                    paddingRight: "10px",
                    color: "#757575",
                    fontSize: "14px",
                  }}
                  className="project_detail_client mb-remove"
                >
                  End Date
                </p>
                {project.end_date ? (
                  <p
                    style={{
                      color: "#333",
                      fontSize: "15px",
                      fontWeight: "500",
                    }}
                  >
                    {showUtcDate(project.end_date)}
                  </p>
                ) : (
                  <div>-</div>
                )}
              </div>
            </div>
            <div class="vertical-border"></div>
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                }}
              >
                <p
                  style={{
                    paddingRight: "10px",
                    color: "#757575",
                    fontSize: "14px",
                  }}
                  className="project_detail_client mb-remove"
                >
                  Status
                </p>
                {project.status ? (
                  <p
                    style={{
                      color: "#333",
                      fontSize: "15px",
                      fontWeight: "500",
                    }}
                  >
                    {remove_underscore_capitalize(project.status)}
                  </p>
                ) : (
                  <div>-</div>
                )}
              </div>
            </div>
            <div class="vertical-border"></div>
            <div style={{ width: "13rem" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                }}
              >
                <p
                  style={{
                    paddingRight: "10px",
                    color: "#757575",
                    fontSize: "14px",
                  }}
                  className="project_detail_client mb-remove"
                >
                  {/* {(project.status)} */}
                  Progress
                </p>

                <div className="project_detail_bar">
                  <Progress
                    color="#0A8080"
                    percent={Math.round(project.progress)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomListingContainer style={{ marginTop: "20px" }}>
        <div
          className="d_flex_space_align"
          style={{ borderBottom: "1px solid #E1E8F0" }}
        >
          <div style={{ marginLeft: "24px" }}>
            <Nav tabs className="tab_border">
              <NavItem
                className={
                  activeTab === 1
                    ? "active_profile_tab"
                    : "inactive_profile_tab"
                }
              >
                <NavLink
                  onClick={() => {
                    toggleTab(1);
                  }}
                  className={
                    activeTab === 1
                      ? "active_profile_tab"
                      : "inactive_profile_tab"
                  }
                  style={{ padding: "10px 20px" }}
                >
                  Tasks
                </NavLink>
              </NavItem>
              <NavItem
                className={
                  activeTab === 2
                    ? "active_profile_tab"
                    : "inactive_profile_tab"
                }
              >
                <NavLink
                  className={
                    activeTab === 2
                      ? "active_profile_tab"
                      : "inactive_profile_tab"
                  }
                  onClick={() => {
                    toggleTab(2);
                  }}
                  style={{ padding: "10px 20px" }}
                >
                  Change Orders
                </NavLink>
              </NavItem>
              <NavItem
                className={
                  activeTab === 3
                    ? "active_profile_tab"
                    : "inactive_profile_tab"
                }
              >
                <NavLink
                  className={
                    activeTab === 3
                      ? "active_profile_tab"
                      : "inactive_profile_tab"
                  }
                  onClick={() => {
                    toggleTab(3);
                  }}
                  style={{ padding: "10px 20px" }}
                >
                  Work Orders
                </NavLink>
              </NavItem>
            </Nav>
          </div>
          {!["completed", "closed", "cancelled", "discontinued"].includes(
            project.status
          ) && (
            <div style={{ marginRight: "24px" }} className="flexStart">
              <Button_without_Border
                style={{ marginRight: "20px" }}
                btnLabel={"+ Add"}
                click={() => {
                  add("upper", activeTab);
                }}
              />
              <Button_without_Border
                isPrimary
                btnLabel={"view_all"}
                click={() => {
                  history.push({
                    pathname: `${projectId}/${
                      activeTab === 1
                        ? "project_task_lists"
                        : activeTab === 2
                        ? "project_change_order_lists"
                        : activeTab === 3
                        ? "project_work_order_lists"
                        : ""
                    }`,
                  });
                  setViewAllChangeOrder(true);
                }}
              />
            </div>
          )}
        </div>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={1}>
            {/* <CustomListingContainer className="mb-24"> */}
            <div>
              <Row>
                <div className="col new-table">
                  {spinner && activeTab === 1 ? (
                    <div
                      style={{
                        minHeight: "300px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div className="CustomSpinner">
                        <Spin
                          style={{ color: "#2e1a47" }}
                          indicator={
                            <LoadingOutlined
                              className="custom_antd_spinner"
                              style={{
                                fontSize: 16,
                              }}
                            />
                          }
                        />
                      </div>
                    </div>
                  ) : taskLists.length ? (
                    <div
                      style={{
                        minHeight: "300px",
                      }}
                    >
                      <Table
                        className="align-items-center table-flush"
                        responsive
                      >
                        <thead className="thead-light">
                          <tr>
                            <th style={{ paddingLeft: "25px" }} scope="col">
                              Name
                            </th>
                            <th scope="col">Assigned To</th>
                            <th scope="col">Due On</th>
                            <th scope="col">Completion</th>
                            <th scope="col">Status</th>
                            <th style={{ width: "5%" }} scope="col"></th>
                          </tr>
                        </thead>
                        {taskLists.length > 0 && (
                          <tbody>
                            {taskLists.map((task, i) => (
                              <tr
                                onClick={() =>
                                  getTaskDetail(task.task_id, task.status)
                                }
                                className="cursor-point"
                                key={i}
                              >
                                <td
                                  style={{ paddingLeft: "25px" }}
                                  className="text-capitalize"
                                >
                                  {task.name}
                                </td>
                                {/* <td className="">
                                  {moment(task.start_date).format("DD MMM, YYYY")}
                                </td> */}
                                <td className="text-capitalize">
                                  {task.sub_contractor?.name &&
                                    task.sub_contractor?.name}
                                </td>
                                <td className="text-capitalize">
                                  {showUtcDate(task.end_date)}
                                </td>
                                <td>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "4%",
                                    }}
                                  >
                                    <div></div>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "20%",
                                      }}
                                    >
                                      <Progress
                                        type="circle"
                                        percent={task.progress ?? 0}
                                        size={20}
                                      />
                                      {task.progress ?? 0} %
                                    </div>
                                  </div>
                                </td>
                                <td
                                  style={{
                                    color: `${
                                      task.status === "completed" ||
                                      task.status === "approved"
                                        ? "#07A84E"
                                        : task.status === "pending"
                                        ? "#FFCC3D"
                                        : task.status === "due"
                                        ? "#0070FD"
                                        : task.status === "rejected" ||
                                          task.status === "cancelled"
                                        ? "#F70e0e"
                                        : "#FFCC3D"
                                    }`,
                                    fontWeight: "500",
                                  }}
                                  className="text-capitalize"
                                >
                                  {remove_underscore_capitalize(task.status)}
                                </td>

                                <td
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-end",
                                    gap: "20px",
                                    paddingLeft: "20px",
                                  }}
                                >
                                  <div
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                  >
                                    <ToolTip label="Timeline">
                                      <img
                                        onClick={
                                          () => onTimLineClick(task, "task")
                                          //   this.setState({
                                          //     isTimeLineOpen: true,
                                          //     task_id: task.task_id,
                                          //     project_id: task.project_id,
                                          //   })
                                        }
                                        style={{ cursor: "pointer" }}
                                        src={projectTimeline}
                                        alt="timeline"
                                      ></img>
                                    </ToolTip>
                                  </div>
                                  {/* {task.status !== "approved" && ( */}
                                  {/* {!['completed', 'closed', 'cancelled', 'discontinued'].includes(project.status) */}
                                  {/* && */}
                                  <div
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                    style={{
                                      visibility: [
                                        "approved",
                                        "cancelled",
                                      ].includes(task.status)
                                        ? "hidden"
                                        : [
                                            "completed",
                                            "closed",
                                            "cancelled",
                                            "discontinued",
                                          ].includes(project.status)
                                        ? "hidden"
                                        : "",
                                    }}
                                    className="project_list_content"
                                  >
                                    {" "}
                                    <UncontrolledDropdown
                                      nav
                                      className="flexy-content position_u"
                                    >
                                      <DropdownToggle
                                        className="pr-0 nav-notice"
                                        nav
                                      >
                                        <Media className="align-items-center">
                                          <img src={showMore} alt="show more" />
                                        </Media>
                                      </DropdownToggle>
                                      <DropdownMenu
                                        className="dropdown-menu-arrow min-width-drop"
                                        right
                                      >
                                        {task.status === "completed" ? (
                                          <>
                                            <DropdownItem
                                              style={styleForDropDown}
                                              onClick={() => {
                                                accept_or_Reject(
                                                  "approve",
                                                  task.task_id
                                                );
                                              }}
                                            >
                                              <span>Approve</span>
                                            </DropdownItem>
                                            <DropdownItem
                                              style={styleForDropDown}
                                              onClick={() => {
                                                accept_or_Reject(
                                                  "reject",
                                                  task.task_id
                                                );
                                              }}
                                            >
                                              <span>Reject</span>
                                            </DropdownItem>
                                          </>
                                        ) : (
                                          <>
                                            <DropdownItem
                                              style={styleForDropDown}
                                              onClick={() => onEdit(task)}
                                            >
                                              <span>Edit</span>
                                            </DropdownItem>
                                            {task.status === "pending" && (
                                              <DropdownItem
                                                style={styleForDropDown}
                                                onClick={() =>
                                                  cancelTask(task.task_id)
                                                }
                                              >
                                                <span>Cancel</span>
                                              </DropdownItem>
                                            )}
                                            <DropdownItem
                                              style={styleForDropDown}
                                              onClick={() =>
                                                updateProgress(task.task_id)
                                              }
                                            >
                                              <span>Update Progress</span>
                                            </DropdownItem>
                                          </>
                                        )}
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                  </div>
                                  {/* } */}
                                  {/* )} */}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        )}
                      </Table>
                    </div>
                  ) : (
                    <>
                      <Table className="align-items-center table-flush">
                        <thead className="thead-light">
                          <tr>
                            <th style={{ paddingLeft: "25px" }} scope="col">
                              Name
                            </th>
                            <th scope="col">Assigned To</th>
                            <th scope="col">Due On</th>
                            <th scope="col">Status</th>
                            <th style={{ width: "5%" }} scope="col"></th>
                          </tr>
                        </thead>
                      </Table>
                      <div
                        style={{
                          minHeight: "300px",
                        }}
                      >
                        <div className="no-data">No Tasks Available</div>
                      </div>
                    </>
                  )}
                </div>
              </Row>
            </div>

            {/* </CustomListingContainer> */}
          </TabPane>
          <TabPane tabId={2}>
            <div>
              <Row>
                <div className="col new-table">
                  {spinner && activeTab === 2 ? (
                    <div
                      style={{
                        minHeight: "300px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div className="CustomSpinner">
                        <Spin
                          style={{ color: "#2e1a47" }}
                          indicator={
                            <LoadingOutlined
                              className="custom_antd_spinner"
                              style={{
                                fontSize: 16,
                              }}
                            />
                          }
                        />
                      </div>
                    </div>
                  ) : ordersLists.length ? (
                    <div
                      style={{
                        minHeight: "300px",
                      }}
                    >
                      <Table
                        className="align-items-center table-flush"
                        responsive
                      >
                        <thead className="thead-light">
                          <tr>
                            <th scope="col" style={{ paddingLeft: "25px" }}>
                              ID
                            </th>
                            <th>Title</th>
                            <th scope="col">Created On</th>
                            <th scope="col">Due On</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Status</th>
                            <th style={{ width: "5%" }} scope="col"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {ordersLists.map((order, i) => (
                            <tr
                              className="cursor-point"
                              onClick={(e) =>
                                openChangeOrderDetail(e, order.changeOrder_id)
                              }
                              key={i}
                            >
                              <td
                                className="text-capitalize"
                                style={{ paddingLeft: "25px" }}
                              >
                                {order.changeOrder_id}
                              </td>

                              <td className="text-capitalize">{order.title}</td>
                              <td className="">
                                {showUtcDate(order.createdAt)}
                              </td>
                              <td className="text-capitalize">
                                {showUtcDate(order.due_date)}
                              </td>
                              <td className="text-capitalize">
                                {"$" +
                                  formatAmountWithCommas(
                                    order.amount.toFixed(2)
                                  )}
                              </td>
                              <td
                                style={{
                                  color: `${
                                    order.status === "approved" ||
                                    order.status === "accepted" ||
                                    order.status === "completed"
                                      ? "#07A84E"
                                      : order.status === "pending"
                                      ? "#FF4949"
                                      : order.status === "paid"
                                      ? "#0070FD"
                                      : order.status === "rejected" ||
                                        order.status === "cancelled"
                                      ? "#F70e0e"
                                      : "#FFCC3D"
                                  }`,
                                  fontWeight: "500",
                                }}
                                className="text-capitalize"
                              >
                                {order.status}
                              </td>
                              <td
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "flex-end",
                                  gap: "20px",
                                  paddingLeft: "20px",
                                }}
                              >
                                <div
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                >
                                  <ToolTip label="Timeline">
                                    <img
                                      onClick={
                                        () =>
                                          onTimLineClick(order, "changeOrder")
                                        //   this.setState({
                                        //     isTimeLineOpen: true,
                                        //     task_id: task.task_id,
                                        //     project_id: task.project_id,
                                        //   })
                                      }
                                      style={{ cursor: "pointer" }}
                                      src={projectTimeline}
                                      alt="timeline"
                                    ></img>
                                  </ToolTip>
                                </div>
                                <div
                                  style={{
                                    visibility: [
                                      "rejected",
                                      "accepted",
                                      "cancelled",
                                      "paid",
                                    ].includes(order.status)
                                      ? "hidden"
                                      : "",
                                  }}
                                  className="project_list_content"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                >
                                  <UncontrolledDropdown
                                    nav
                                    className="flexy-content position_u"
                                  >
                                    <DropdownToggle
                                      className="pr-0 nav-notice"
                                      nav
                                    >
                                      <Media className="align-items-center">
                                        <img src={showMore} alt="show more" />
                                      </Media>
                                    </DropdownToggle>
                                    {order.status === "created" && (
                                      <DropdownMenu
                                        className="dropdown-menu-arrow min-width-drop"
                                        right
                                      >
                                        <>
                                          <DropdownItem
                                            style={styleForDropDown}
                                            onClick={(e) =>
                                              openChangeOrderDetail(
                                                e,
                                                order.changeOrder_id
                                              )
                                            }
                                          >
                                            <span>Edit</span>
                                          </DropdownItem>
                                          <DropdownItem
                                            style={styleForDropDown}
                                            onClick={() =>
                                              cancelWorkOrder(
                                                order.changeOrder_id
                                              )
                                            }
                                          >
                                            <span>Cancel</span>
                                          </DropdownItem>
                                        </>
                                      </DropdownMenu>
                                    )}
                                  </UncontrolledDropdown>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  ) : (
                    <>
                      <Table className="align-items-center table-flush">
                        <thead className="thead-light">
                          <tr>
                            <th scope="col" style={{ paddingLeft: "25px" }}>
                              ID
                            </th>
                            <th scope="col">Title</th>
                            <th scope="col">Created On</th>
                            <th scope="col">Due On</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Status</th>
                            <th style={{ width: "5%" }} scope="col"></th>
                          </tr>
                        </thead>
                      </Table>
                      <div
                        style={{
                          minHeight: "300px",
                        }}
                      >
                        <div className="no-data">
                          No Change Orders Available
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </Row>
            </div>
          </TabPane>
          <TabPane tabId={3}>
            <div>
              <Row>
                <div className="col new-table">
                  {spinner && activeTab === 3 ? (
                    <div
                      style={{
                        minHeight: "300px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div className="CustomSpinner">
                        <Spin
                          style={{ color: "#2e1a47" }}
                          indicator={
                            <LoadingOutlined
                              className="custom_antd_spinner"
                              style={{
                                fontSize: 16,
                              }}
                            />
                          }
                        />
                      </div>
                    </div>
                  ) : workOrderLists.length ? (
                    <div
                      style={{
                        minHeight: "300px",
                      }}
                    >
                      <Table
                        className="align-items-center table-flush"
                        responsive
                      >
                        <thead className="thead-light">
                          <tr>
                            <th style={{ paddingLeft: "25px" }} scope="col">
                              ID
                            </th>
                            <th scope="col">Pro</th>
                            <th scope="col">Subject</th>
                            <th scope="col">Sent On</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Status</th>
                            <th style={{ width: "5%" }} scope="col"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {workOrderLists.map((order, i) => (
                            <tr
                              className="cursor-point"
                              onClick={() =>
                                getWorkOrderDetailPage(order.workOrder_id)
                              }
                              key={i}
                            >
                              <td
                                style={{ paddingLeft: "25px" }}
                                className="text-capitalize"
                              >
                                {order.workOrder_id}
                              </td>
                              <td className="text-capitalize">
                                {order.sub_contractor.name}
                              </td>
                              <td className="">{order.subject}</td>
                              <td className="text-capitalize">
                                {showUtcDate(order.createdAt)}
                              </td>
                              <td className="text-capitalize">
                                {/* {"$" +
                                  formatAmountWithCommas(
                                    calculateTotalAmount(order.items).toFixed(2)
                                  )} */}
                                {"$" +
                                  formatAmountWithCommas(
                                    order.itemsTotal.totalAmount.toFixed(2)
                                  )}
                              </td>
                              <td
                                style={{
                                  color: `${
                                    order.status === "completed" ||
                                    order.status === "accepted" ||
                                    order.status === "closed"
                                      ? "#07A84E"
                                      : order.status === "discontinued" ||
                                        order.status === "cancelled" ||
                                        order.status === "rejected"
                                      ? "#f75f18"
                                      : order.status === "due" ||
                                        order.status === "sent" ||
                                        order.status === "paid"
                                      ? "#0070FD"
                                      : "#FFCC3D"
                                  }`,
                                  fontWeight: "500",
                                }}
                                className="text-capitalize"
                              >
                                {order.status}
                              </td>
                              <td
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "flex-end",
                                  gap: "20px",
                                  paddingLeft: "20px",
                                }}
                              >
                                <div
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                >
                                  <ToolTip label="Timeline">
                                    <img
                                      onClick={
                                        () => onTimLineClick(order, "workOrder")
                                        //   this.setState({
                                        //     isTimeLineOpen: true,
                                        //     task_id: task.task_id,
                                        //     project_id: task.project_id,
                                        //   })
                                      }
                                      style={{ cursor: "pointer" }}
                                      src={projectTimeline}
                                      alt="timeline"
                                    ></img>
                                  </ToolTip>
                                </div>
                                <div
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                  className="project_list_content"
                                >
                                  <UncontrolledDropdown
                                    nav
                                    className="flexy-content position_u"
                                  >
                                    <DropdownToggle
                                      className="pr-0 nav-notice"
                                      nav
                                    >
                                      <Media className="align-items-center">
                                        <img src={showMore} alt="show more" />
                                      </Media>
                                    </DropdownToggle>
                                    <DropdownMenu
                                      className="dropdown-menu-arrow min-width-drop"
                                      right
                                    >
                                      {order.status === "draft" && (
                                        <>
                                             <DropdownItem
                                            style={styleForDropDown}
                                            onClick={() => onEdit(order)}
                                          >
                                            <span>Edit</span>
                                          </DropdownItem>
                                          <DropdownItem
                                            style={styleForDropDown}
                                            onClick={() =>
                                              onLinkClick(order.workOrder_id)
                                            }
                                          >
                                            <span>Copy Link</span>
                                          </DropdownItem>
                                          <DropdownItem
                                            style={styleForDropDown}
                                            onClick={() => handlePreview(order)}
                                          >
                                            <span>Preview</span>
                                          </DropdownItem>
                                          <DropdownItem
                                            style={styleForDropDown}
                                            onClick={() =>
                                              duplicate(order.workOrder_id)
                                            }
                                          >
                                            <span>Duplicate</span>
                                          </DropdownItem>
                                          <DropdownItem
                                            style={styleForDropDown}
                                            onClick={() => {
                                              setDeleteModal(true);
                                              setWorkOrderId(
                                                order.workOrder_id
                                              );
                                            }}
                                          >
                                            <span>Delete</span>
                                          </DropdownItem>
                                        </>
                                      )}
                                      {order.status === "sent" && (
                                        <>
                                          <DropdownItem
                                            style={styleForDropDown}
                                            onClick={() => onEdit(order)}
                                          >
                                            <span>Edit</span>
                                          </DropdownItem>
                                          <DropdownItem
                                            style={styleForDropDown}
                                            onClick={() =>
                                              onLinkClick(order.workOrder_id)
                                            }
                                          >
                                            <span>Copy Link</span>
                                          </DropdownItem>
                                          <DropdownItem
                                            style={styleForDropDown}
                                            onClick={() =>
                                              resendWorkOrder(order)
                                            }
                                          >
                                            <span>Resend Email</span>
                                          </DropdownItem>
                                          <DropdownItem
                                            style={styleForDropDown}
                                            onClick={() => handlePreview(order)}
                                          >
                                            <span>Preview</span>
                                          </DropdownItem>
                                          <DropdownItem
                                            style={styleForDropDown}
                                            // onClick={() => {
                                            //   this.duplicate(
                                            //     order.estimate_id
                                            //   );
                                            // }}
                                            onClick={() =>
                                              duplicate(order.workOrder_id)
                                            }
                                          >
                                            <span>Duplicate</span>
                                          </DropdownItem>
                                          <DropdownItem
                                            style={styleForDropDown}
                                            onClick={() =>
                                              downloadDocuments(
                                                order.workOrder_id,
                                                "workOrder"
                                              )
                                            }
                                          >
                                            <span>Download</span>
                                          </DropdownItem>
                                       
                                        </>
                                      )}
                                      {order.status === "paid" && (
                                        <>
                                          <DropdownItem
                                            style={styleForDropDown}
                                            onClick={() => onEdit(order)}
                                          >
                                            <span>Edit</span>
                                          </DropdownItem>
                                          <DropdownItem
                                            style={styleForDropDown}
                                            onClick={() =>
                                              onLinkClick(order.workOrder_id)
                                            }
                                          >
                                            <span>Copy Link</span>
                                          </DropdownItem>
                                          <DropdownItem
                                            style={styleForDropDown}
                                            onClick={() =>
                                              resendWorkOrder(order)
                                            }
                                          >
                                            <span>Resend Email</span>
                                          </DropdownItem>
                                          <DropdownItem
                                            style={styleForDropDown}
                                            onClick={() => handlePreview(order)}
                                          >
                                            <span>Preview</span>
                                          </DropdownItem>
                                          <DropdownItem
                                            style={styleForDropDown}
                                            // onClick={() => {
                                            //   this.duplicate(
                                            //     order.estimate_id
                                            //   );
                                            // }}
                                            onClick={() =>
                                              duplicate(order.workOrder_id)
                                            }
                                          >
                                            <span>Duplicate</span>
                                          </DropdownItem>
                                          <DropdownItem
                                            style={styleForDropDown}
                                            onClick={() =>
                                              downloadDocuments(
                                                order.workOrder_id,
                                                "workOrder"
                                              )
                                            }
                                          >
                                            Download
                                          </DropdownItem>
                                        </>
                                      )}
                                      {order.status === "accepted" && (
                                        <>
                                          <DropdownItem
                                            style={styleForDropDown}
                                            onClick={() => handlePreview(order)}
                                          >
                                            <span>Preview</span>
                                          </DropdownItem>
                                          <DropdownItem
                                            style={styleForDropDown}
                                            onClick={() =>
                                              duplicate(order.workOrder_id)
                                            }
                                          >
                                            <span>Duplicate</span>
                                          </DropdownItem>
                                          <DropdownItem
                                            style={styleForDropDown}
                                            onClick={() =>
                                              downloadDocuments(
                                                order.workOrder_id,
                                                "workOrder"
                                              )
                                            }
                                          >
                                            <span>Download</span>
                                          </DropdownItem>
                                          {(
                                            order.itemsTotal.totalAmount -
                                            order.totalSplitUp
                                          ).toFixed(2) > 0 && (
                                            <DropdownItem
                                              style={styleForDropDown}
                                              onClick={() =>
                                                onEdit(order, true)
                                              }
                                            >
                                              <span>Add Split Up</span>
                                            </DropdownItem>
                                          )}
                                        </>
                                      )}
                                      {order.status === "rejected" && (
                                        <>
                                            <DropdownItem
                                            style={styleForDropDown}
                                            onClick={() =>
                                              duplicate(order.workOrder_id)
                                            }
                                          >
                                            <span>Duplicate</span>
                                          </DropdownItem>
                                          <DropdownItem
                                            style={styleForDropDown}
                                            onClick={() =>
                                              downloadDocuments(
                                                order.workOrder_id,
                                                "workOrder"
                                              )
                                            }
                                          >
                                            <span>Download</span>
                                          </DropdownItem>
                                        </>
                                      )}
                                      {order.status === "cancelled" && (
                                        <>
                                          {/* <DropdownItem>
                                              <span>Duplicate</span>
                                            </DropdownItem> */}
                                          <DropdownItem
                                            style={styleForDropDown}
                                            onClick={() =>
                                              downloadDocuments(
                                                order.workOrder_id,
                                                "workOrder"
                                              )
                                            }
                                          >
                                            <span>Download</span>
                                          </DropdownItem>
                                        </>
                                      )}
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  ) : (
                    <>
                      <Table className="align-items-center table-flush">
                        <thead className="thead-light">
                          <tr>
                            <th style={{ paddingLeft: "25px" }} scope="col">
                              ID
                            </th>
                            <th scope="col">Pro</th>
                            <th scope="col">Subject</th>
                            <th scope="col">Sent on</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Status</th>
                            <th style={{ width: "5%" }} scope="col"></th>
                          </tr>
                        </thead>
                      </Table>
                      <div
                        style={{
                          minHeight: "300px",
                        }}
                      >
                        <div className="no-data">No Work Orders Available</div>
                      </div>
                    </>
                  )}
                </div>
              </Row>
            </div>
          </TabPane>
        </TabContent>
      </CustomListingContainer>

      <CustomListingContainer style={{ marginTop: "20px" }}>
        <div
          className="d_flex_space_align"
          // style={{ borderBottom: "1px solid #E1E8F0" }}
        >
          <div style={{ marginLeft: "24px" }}>
            <Nav tabs className="tab_border">
              <NavItem
                className={
                  bottomActiveTab === 1
                    ? "active_profile_tab"
                    : "inactive_profile_tab"
                }
              >
                <NavLink
                  onClick={() => {
                    toggleBottomTab(1);
                  }}
                  className={
                    bottomActiveTab === 1
                      ? "active_profile_tab"
                      : "inactive_profile_tab"
                  }
                  style={{ padding: "10px 20px" }}
                >
                  Invoices
                </NavLink>
              </NavItem>
              <NavItem
                className={
                  bottomActiveTab === 2
                    ? "active_profile_tab"
                    : "inactive_profile_tab"
                }
              >
                <NavLink
                  className={
                    bottomActiveTab === 2
                      ? "active_profile_tab"
                      : "inactive_profile_tab"
                  }
                  onClick={() => {
                    toggleBottomTab(2);
                  }}
                  style={{ padding: "10px 20px" }}
                >
                  Payments
                </NavLink>
              </NavItem>
            </Nav>
          </div>
          <div style={{ marginRight: "24px" }} className="flexStart">
            <Button_without_Border
              style={{ marginRight: "20px" }}
              btnLabel={[3].includes(bottomActiveTab) ? "+ Add" : ""}
              click={() => {
                add("bottom", bottomActiveTab);
              }}
            />
            <Button_without_Border
              isPrimary
              btnLabel={"view_all"}
              click={() => {
                history.push({
                  pathname: `${projectId}/${
                    bottomActiveTab === 1
                      ? "project_invoice_lists"
                      : bottomActiveTab === 2
                      ? "project_payment_lists"
                      : ""
                  }`,
                });
              }}
            />
          </div>
        </div>
        <TabContent activeTab={bottomActiveTab}>
          <TabPane tabId={1}>
            <div>
              <Row>
                <div className="col new-table">
                  {bottomSpinner && bottomActiveTab === 1 ? (
                    <div
                      style={{
                        minHeight: "300px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div className="CustomSpinner">
                        <Spin
                          style={{ color: "#2e1a47" }}
                          indicator={
                            <LoadingOutlined
                              className="custom_antd_spinner"
                              style={{
                                fontSize: 16,
                              }}
                            />
                          }
                        />
                      </div>
                    </div>
                  ) : invoiceList?.length ? (
                    <div
                      style={{
                        minHeight: "300px",
                      }}
                    >
                      <Table
                        className="align-items-center table-flush"
                        responsive
                      >
                        <thead className="thead-light">
                          <tr>
                            <th
                              style={{ paddingLeft: "25px", width: "14.2%" }}
                              scope="col"
                            >
                              ID
                            </th>
                            <th scope="col">Title</th>
                            <th scope="col">Invoice To</th>
                            <th scope="col">Created On</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Status</th>
                            <th style={{ width: "5%" }} scope="col"></th>
                            <th style={{ width: "5%" }} scope="col"></th>
                          </tr>
                        </thead>
                        {invoiceList.length > 0 && (
                          <tbody>
                            {invoiceList.map((invoice, i) => (
                              <tr key={i}>
                                <td
                                  style={{ paddingLeft: "25px" }}
                                  className="text-capitalize"
                                >
                                  {invoice.invoice_id}
                                </td>
                                <td>
                                  {invoice.workOrder_id
                                    ? invoice.workOrder_id
                                    : invoice.title}
                                </td>
                                <td className="text-capitalize">
                                  {invoice.sub_contractor?.name}
                                </td>
                                <td>{showUtcDate(invoice.createdAt)}</td>
                                <td className="text-capitalize">
                                  $
                                  {formatAmountWithCommas(
                                    invoice.amount.toFixed(2)
                                  )}
                                </td>
                                <td
                                  style={{
                                    color: `${
                                      invoice.status === "processing"
                                        ? "#FF4949"
                                        : invoice.status === "paid"
                                        ? "#07A84E"
                                        : invoice.status === "created"
                                        ? "#FFCC3D"
                                        : invoice.status === "cancelled"
                                        ? "red"
                                        : "#FFCC3D"
                                    }`,
                                    fontWeight: "500",
                                  }}
                                  className="text-capitalize"
                                >
                                  {remove_underscore_capitalize(invoice.status)}
                                </td>
                                <td>
                                  {![
                                    "paid",
                                    "processing",
                                    "cancelled",
                                  ].includes(invoice.status) && (
                                    <Custom_Button
                                      isPrimary
                                      btnLabel={"pay_now"}
                                      click={() => {
                                        setOpenPaymentModal(true);
                                        setInvoiceAmount(invoice.amount);
                                        setInvoiceId(invoice.invoice_id);
                                      }}
                                      style={{
                                        padding: "8px 15px",
                                        fontSize: "12px",
                                        minWidth: "50px",
                                      }}
                                    />
                                  )}
                                </td>
                                <td>
                                  <div
                                    style={{ gap: "20px" }}
                                    className="flexStart"
                                  >
                                    <ToolTip label="Timeline">
                                      <img
                                        onClick={() =>
                                          onTimLineClick(invoice, "invoice")
                                        }
                                        className="point"
                                        src={projectTimeline}
                                        alt="timeline"
                                      />
                                    </ToolTip>
                                    {invoice.status === "created" ||
                                    invoice.status === "paid" ? (
                                      <UncontrolledDropdown
                                        nav
                                        className="flexy-content position_u"
                                      >
                                        <DropdownToggle
                                          className="pr-0 nav-notice"
                                          nav
                                        >
                                          <Media className="align-items-center">
                                            <img src={showMore} />
                                          </Media>
                                        </DropdownToggle>
                                        <DropdownMenu
                                          className="dropdown-menu-arrow min-width-drop"
                                          right
                                        >
                                          {invoice.status === "created" && (
                                            <DropdownItem
                                              onClick={() =>
                                                openCancelInvoicePopup(
                                                  invoice.invoice_id
                                                )
                                              }
                                            >
                                              <span>Cancel</span>
                                            </DropdownItem>
                                          )}
                                          <DropdownItem
                                            onClick={() =>
                                              downloadDocuments(
                                                invoice.invoice_id,
                                                "invoice"
                                              )
                                            }
                                          >
                                            <span>Download Invoice</span>
                                          </DropdownItem>
                                        </DropdownMenu>
                                      </UncontrolledDropdown>
                                    ) : invoice.status === "cancelled" ? (
                                      <div
                                        data-tip={invoice?.cancel_reason}
                                        data-iscapture="true"
                                        data-for="toolTip1"
                                        data-place="top"
                                        className="new-border-request no_border mr-10 cladb5bd"
                                      >
                                        <i className="fa fa-info-circle verticalMidAlign"></i>
                                        <ReactTooltip
                                          place="top"
                                          type="info"
                                          effect="solid"
                                          id="toolTip1"
                                          html={true}
                                        />
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        )}
                      </Table>
                    </div>
                  ) : (
                    <>
                      <Table
                        className="align-items-center table-flush"
                        responsive
                      >
                        <thead className="thead-light">
                          <tr>
                            <th style={{ paddingLeft: "25px" }} scope="col">
                              ID
                            </th>
                            <th scope="col">Title</th>
                            <th scope="col">Invoice To</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Status</th>
                            <th style={{ width: "5%" }} scope="col"></th>
                            <th style={{ width: "5%" }} scope="col"></th>
                          </tr>
                        </thead>
                      </Table>
                      <div
                        style={{
                          minHeight: "300px",
                        }}
                      >
                        <div className="no-data">No Invoices Available</div>
                      </div>
                    </>
                  )}
                </div>
              </Row>
            </div>

            {/* </CustomListingContainer> */}
          </TabPane>
          <TabPane tabId={2}>
            <div>
              <Row>
                <div className="col new-table">
                  {bottomSpinner && bottomActiveTab === 2 ? (
                    <div
                      style={{
                        minHeight: "300px",
                        display: spinner ? "flex" : "",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div className="CustomSpinner">
                        <Spin
                          style={{ color: "#2e1a47" }}
                          indicator={
                            <LoadingOutlined
                              className="custom_antd_spinner"
                              style={{
                                fontSize: 16,
                              }}
                            />
                          }
                        />
                      </div>
                    </div>
                  ) : paymentList.length ? (
                    <div
                      style={{
                        minHeight: "300px",
                      }}
                    >
                      <Table
                        className="align-items-center table-flush"
                        responsive
                      >
                        <thead className="thead-light">
                          <tr>
                            <th
                              style={{ paddingLeft: "25px", width: "20%" }}
                              scope="col"
                            >
                              ID
                            </th>
                            <th scope="col">Title</th>
                            <th scope="col">Created On</th>
                            <th scope="col">Due On</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Status</th>
                            <th style={{ width: "6%" }} scope="col"></th>
                          </tr>
                        </thead>
                        {paymentList.length > 0 && (
                          <tbody>
                            {paymentList.map((order, i) => (
                              <tr key={i}>
                                <td
                                  style={{ paddingLeft: "25px" }}
                                  className="text-capitalize"
                                >
                                  {order.invoice_id}
                                </td>
                                <td className="text-capitalize">
                                  {order?.title}
                                </td>

                                <td className="">
                                  {showUtcDate(order.createdAt)}
                                </td>
                                <td className="text-capitalize">
                                  {showUtcDate(order.due)}
                                </td>
                                <td className="text-capitalize">
                                  {"$" +
                                    formatAmountWithCommas(
                                      order.amount.toFixed(2)
                                    )}
                                </td>
                                <td
                                  style={{
                                    color: `${
                                      order.status === "processing"
                                        ? "#FF4949"
                                        : order.status === "paid"
                                        ? "#07A84E"
                                        : order.status === "created"
                                        ? "#FFCC3D"
                                        : "#FFCC3D"
                                    }`,
                                    fontWeight: "500",
                                  }}
                                  className="text-capitalize"
                                >
                                  {remove_underscore_capitalize(
                                    order.status === "created"
                                      ? "unpaid"
                                      : order.status
                                  )}
                                </td>
                                <td style={{ paddingLeft: "20px" }}>
                                  <div
                                    style={{ gap: "20px" }}
                                    className="flexStart"
                                  >
                                    <ToolTip label="Timeline">
                                      <img
                                        onClick={() =>
                                          onTimLineClick(order, "payment")
                                        }
                                        className="point"
                                        src={projectTimeline}
                                        alt="timeline"
                                      />
                                    </ToolTip>
                                    {order.status === "created" ||
                                    order.status === "paid" ? (
                                      <UncontrolledDropdown
                                        nav
                                        className="flexy-content position_u"
                                      >
                                        <DropdownToggle
                                          className="pr-0 nav-notice"
                                          nav
                                        >
                                          <Media className="align-items-center">
                                            <img src={showMore} />
                                          </Media>
                                        </DropdownToggle>
                                        <DropdownMenu
                                          className="dropdown-menu-arrow min-width-drop"
                                          right
                                        >
                                          {order.status === "created" && (
                                            <>
                                              <DropdownItem
                                                onClick={() =>
                                                  openCancelInvoicePopup(
                                                    order.invoice_id
                                                  )
                                                }
                                              >
                                                <span>Cancel</span>
                                              </DropdownItem>
                                              <DropdownItem
                                                onClick={() =>
                                                  openMarkPaymentSideDragger(
                                                    order.invoice_id
                                                  )
                                                }
                                              >
                                                <span>Mark Payment</span>
                                              </DropdownItem>
                                            </>
                                          )}
                                          <DropdownItem
                                          // onClick={() =>
                                          //   this.downloadInvoice(
                                          //     order.invoice_id
                                          //   )
                                          // }
                                          >
                                            <span>Download Invoice</span>
                                          </DropdownItem>
                                        </DropdownMenu>
                                      </UncontrolledDropdown>
                                    ) : order.status === "cancelled" ? (
                                      <div
                                        data-tip={order?.cancel_reason}
                                        data-iscapture="true"
                                        data-for="toolTip1"
                                        data-place="top"
                                        className="new-border-request no_border mr-10 cladb5bd"
                                      >
                                        <i className="fa fa-info-circle verticalMidAlign"></i>
                                        <ReactTooltip
                                          place="top"
                                          type="info"
                                          effect="solid"
                                          id="toolTip1"
                                          html={true}
                                        />
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        )}
                      </Table>
                    </div>
                  ) : (
                    <>
                      <Table
                        className="align-items-center table-flush"
                        responsive
                      >
                        <thead className="thead-light">
                          <tr>
                            <th style={{ paddingLeft: "25px" }} scope="col">
                              ID
                            </th>
                            <th scope="col">Title</th>
                            <th scope="col">created On</th>
                            <th scope="col">Due On</th>
                            <th scope="col">Cost</th>
                            <th scope="col">Status</th>
                            <th style={{ width: "5%" }} scope="col"></th>
                          </tr>
                        </thead>
                      </Table>
                      <div
                        style={{
                          minHeight: "300px",
                        }}
                      >
                        <div className="no-data">No Payments Available</div>
                      </div>
                    </>
                  )}
                </div>
              </Row>
            </div>
          </TabPane>
        </TabContent>
      </CustomListingContainer>
      <CustomListingContainer style={{ marginTop: "20px" }}>
        <div
          className="d_flex_space_align"
          style={{ borderBottom: "1px solid #E1E8F0" }}
        >
          <div
            className="inactive_profile_tab tab_border"
            style={{
              padding: "15px 20px",
              color: "#212121",
              fontWeight: "500",
            }}
          >
            Documents and Photos
          </div>
          <div style={{ marginRight: "24px" }} className="flexStart">
            <Button_without_Border
              style={{ marginRight: "20px" }}
              btnLabel="+ Add"
              click={() => setOpenDocumentSidebar(true)}
            />
            {/* <Button_without_Border
              style={{ marginRight: "20px" }}
              btnLabel="View all"
            click={() => {
              add("bottom", bottomActiveTab);
            }}
            /> */}
          </div>
        </div>
        <div style={{ margin: "24px" }}>
          <div className="">
            <AddDocument
              document={documentList}
              name={`uploadDocuments`}
              title={"Upload a file"}
              fileSizeType={`Up to 10MB`}
              handleChange={addDocumentImg}
              props={props}
              completed={() => completed()}
              // removeFile={(name, i) =>
              //   handleRemoveFile(name, i)
              // }
            />
          </div>
        </div>
      </CustomListingContainer>
      {viewAllChangeOrder && (
        <ChangeOrderList props={props} project_details={ordersLists} />
      )}
      <workOrderList props={props} workOrderLists={workOrderLists} />
      <invoiceList props={props} invoiceLists={invoiceList} />
      <Card
        className={openDocumentSidebar ? "exportSideBar" : "toggle-sidebar"}
      >
        {openDocumentSidebar && (
          <AddDocumentSidebar
            project_id={projectId}
            props={props}
            isOpen={openDocumentSidebar}
            close={() => onClose()}
            completed={() => completed()}
            document={uploaded}
            name={`gallery`}
            title={"Upload a file"}
            fileSizeType={`Up to 10MB`}
            handleChange={addDocumentImg}
            // props={props}
            // taskId={id}
            // projectId={projectId}
            // type="task"
          />
        )}
      </Card>
      <Card
        style={{ zIndex: "1000" }}
        className={markPaymentModal ? "exportSideBar" : "toggle-sidebar"}
      >
        {markPaymentModal && (
          <MarkPaymentSideBar
            close={closeMarkPaymentSideDraggger}
            isOpen={markPaymentModal}
            toast={displayErrorMessage}
            id={invoiceId}
          />
        )}
      </Card>
      <Modal
        isOpen={cancelInvoiceModal}
        toggle={() => toggleCancelInvoiceModal()}
        centered
      >
        <CancelInvoiceModal close={toggleCancelInvoiceModal} id={invoiceId} />
      </Modal>
      <Card className={timeLineOpen ? "exportSideBar" : "toggle-sidebar"}>
        {timeLineOpen && (
          <TimeLine
            id={timeLine.id}
            closeTimelineModal={closeTimeline}
            from={timeLine.from}
          ></TimeLine>
        )}
      </Card>

      <Card className={openAdd ? "exportSideBar" : "toggle-sidebar"}>
        {openAdd && (
          <AddProject
            props={props}
            taskId={id}
            projectId={projectId}
            close={onAddClose}
            type="task"
            // isDetailPage={['completed','approved'].includes(status)? true  : false}
          />
        )}
      </Card>

      <Card className={openChangeOrder ? "exportSideBar" : "toggle-sidebar"}>
        {openChangeOrder && (
          <AddChangeOrder
            props={props}
            projectId={projectId}
            orderId={id ?? ""}
            close={closeChangeOrder}
          ></AddChangeOrder>
        )}
      </Card>

      <Card className={openWorkOrders ? "exportSideBar" : "toggle-sidebar"}>
        {openWorkOrders && (
          <AddWorkOrder
            type="workOrder"
            props={props}
            orderId={id ?? ""}
            projectId={projectId}
            close={() => setOpenWorkOrders(false)}
          />
        )}
      </Card>

      <Card className={openPayemnt ? "exportSideBar" : "toggle-sidebar"}>
        {openPayemnt && (
          <AddSchedules
            props={props}
            // data={estimateSchema}
            close={() => {
              setOpenPayemnt(false);
            }}
            // amount={calculateTotal()}
            // editSplitup={{
            //   edit: splitupEdit,
            //   detail: splitUpDetail || {},
            // }}
            //   remainingValue={this.state.remainingValue}
            //   cancel={() => this.setState({ open: false, editSplitup: false })}
          ></AddSchedules>
        )}
      </Card>

      <Card className={isUpdateOpen ? "exportSideBar" : "toggle-sidebar"}>
        {isUpdateOpen && (
          <UpdateStatus id={projectId} close={onUpdateClose} props={props} />
        )}
      </Card>

      <Modal
        isOpen={acceptOrReject.isOpen}
        centered={true}
        style={{ minWidth: "35rem", padding: "2rem" }}
      >
        <div style={{ padding: "20px" }}>
          <div className="conCanMod" style={{ textAlign: "center" }}>
            Reason For Rejection
          </div>
          {/*  */}
          <textarea
            className="text-area-add-project"
            value={acceptOrReject.reason}
            style={{
              fontSize: "14px",
              color: "#212529",
              border: "1px solid #f0f0f0",
              marginBottom: "16px",
            }}
            maxLength={500}
            name="notes"
            onChange={(e) => {
              setAcceptOrReject((prev) => ({
                ...prev,
                reason: e.target.value,
              }));
            }}
          />
          <div style={{ justifyContent: "center" }} className="flexEnd">
            <Button_without_Border
              btnLabel={"Cancel"}
              style={{ marginRight: "20px" }}
              click={() => {
                setAcceptOrReject((prev) => ({
                  ...prev,
                  isOpen: false,
                }));
              }}
            />
            <Custom_Button
              style={{ minWidth: "50px" }}
              isPrimary={true}
              isLoading={taskbtnLoad}
              btnLabel={"Reject"}
              click={() => approve_reject()}
            />
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={openPaymentModal}
        centered={true}
        style={{ minWidth: "650px", padding: "2rem" }}
      >
        {openPaymentModal && (
          <PayMethod
            // props={this.props}
            invoice_id={invoiceId}
            close={() => {
              setOpenPaymentModal(false);
            }}
            amount={invoiceAmount}
          />
        )}
      </Modal>

      <Modal
        isOpen={openPaymentStatusModal}
        className={
          paymentStatus === "success"
            ? "modal-dialog-payment-success modal-dialog-centered"
            : "modal-dialog-payment-failure modal-dialog-centered"
        }
        toggle={() => {
          setOpenPaymentStatusModel(false);
        }}
      >
        {popupLoading ? (
          <div className="spin-loading1">
            <div className="flex-column d_flex_align">
              <div style={{ marginBottom: "20px" }}>
                <CustomSpinner />
              </div>
              <div className="f14 fw-400">
                Please wait while we are processing your payment
              </div>
            </div>
          </div>
        ) : (
          <div>
            {paymentStatus === "success" && (
              <div className="paymentSuccess">
                <Lottie
                  options={SuccessVerifyOptions}
                  minHeight={170}
                  width={170}
                />
                <div className="paymentTitle align_midd">
                  Payment Successful
                </div>
                <div className="align_midd amount paymentTitle">
                  ${statusDetail?.amount}
                </div>
                <div className="align_midd mb-2r">
                  Your Payment has been successfully processed
                </div>
                <div className="flex">
                  <div className="fw-500">Transaction id</div>
                  <div>{statusDetail?.transaction_id}</div>
                </div>
              </div>
            )}
            {paymentStatus === "processing" && (
              <div className="paymentSuccess">
                <Lottie
                  options={ProcessingOptions}
                  minHeight={170}
                  width={170}
                />
                <div className="paymentTitle align_midd mt-20">
                  Payment Processing
                </div>

                <div className="align_midd mb-2r">
                  Your Payment is being processed.Will update your payment
                  automatically.
                </div>
              </div>
            )}
            {paymentStatus === "failure" && (
              <div className="paymentFailed">
                <img src={error} alt="error" className="paymentError" />
                <div className="paymentTitle">Payment Failed</div>
                <div className="align_midd">
                  Your Payment was not successfully processed. Please try again
                  after sometime.
                </div>
              </div>
            )}
          </div>
        )}
      </Modal>

      <Card
        className={openProgressModel ? "exportSideBar" : "toggle-sidebar"}
        style={{ zIndex: "1000" }}
      >
        {openProgressModel && (
          <UpdateTask
            closeUpdateModal={closeProgressModal}
            taskId={taskId}
            functional_component={true}
            toast={Showtoast}
          ></UpdateTask>
        )}
      </Card>

      {deleteModal && (
        <DeleteModal
          modalState={deleteModal}
          name={worKOrderId}
          type="WorkOrder"
          props={this}
          toast={Showtoast}
          closeModal={closeDeleteModal}
          id={worKOrderId}
          width={200}
        />
      )}

      {cancelModal && (
        <CancelModal
          modalState={cancelModal}
          name={taskId || changeOrderId}
          type={taskId ? "task" : "change_order"}
          props={this}
          toast={Showtoast}
          closeModal={closeCancelModal}
          id={taskId || changeOrderId}
          width={200}
        />
      )}
    </>
  );
}
export default ProjectDetail_overview;
