import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
// import AcceptOrRejectProposal from "views/externalPages/AcceptOrRejectProposal";
import AuthLayout from "../layouts/Auth";
import ClientPage from "views/externalPages/client/clientPage";
import ClientProjectDetail from "views/externalPages/client/clientProjectDetail";
import { IsClientPannel } from "constants/utils";
import ClientPayment from "views/externalPages/client/clientPaymentPage";
import ClientDocument from "views/externalPages/client/clientDocuments";
import AcceptOrRejectProposal from "views/externalPages/AcceptOrRejectProposal";
class PublicRouter extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {

  }
  render = () => {
    return (
      <>
        <Switch>
          <Route path="/" render={(props) => <AuthLayout {...{ ...props }} />} />
          {/* <Route
            path="/accept_reject_proposal/:id"
            component={AcceptOrRejectProposal}
          /> */}
          {/* <Route path="/client/:id/:id/payments" component={ClientPayment} />
          <Route path="/client/:id/:id" component={ClientProjectDetail} />
          <Route path="/client/:id" component={ClientPage} /> */}
          {/* <Route path="/client/:id" render={() => <ClientDocument />} /> */}
          {/* <Redirect from="/" to="/login" /> */}
        </Switch>
      </>
    );
  };
}

export default PublicRouter;
