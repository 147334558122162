import React, { Component } from "react";
import Select from "react-select";
import {
  Card,
  Table,
  Container,
  Row,
  Breadcrumb,
  Col,
  Button,
  InputGroup,
  Spinner,
} from "reactstrap";
// core components
import ApiService from "constants/ApiService";
import { returnFirstDegreeObjValue } from "constants/utils";
import returnSecondDegreeObjValue from "constants/utils";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { filterQuery } from "constants/utils";
import { displayPrice } from "constants/utils";
import { showUtcDate } from "constants/utils";
import { getIsoString } from "constants/utils";
import { showError } from "constants/utils";
import { displayPercentage } from "constants/utils";
import PayoutSideBar from "./PayoutSideBar";
import { pushToFilteredPage } from "constants/utils";
import { returnSearchObject } from "constants/utils";
import { buildQueryString } from "constants/utils";
import ToastList from "components/Toast/ToastList";
import { CustomListingContainer } from "components/Container/CustomListingContainer";
import { ListingHeaderLayout } from "components/Container/CustomListingContainer";
import { CustomDatePicker } from "components/FilterFields/Filterfields";
import { Button_without_Border } from "components/Buttons/Button";

let manager_id;
class Payouts extends Component {
  constructor(props) {
    super(props);
    manager_id = this.props.location?.manager_id;
    this.state = {
      payoutList: [],
      response: "",
      total: 0,
      filterToggle: false,
      from: "",
      to: "",
      page: 1,
      inputSearch: "",
      filterOption: "",
      statusSearch: "",
      modalOpen: false,
      isLoading: true,
      invoiceDetail: {},
      lateFee: 0,
      payoutModal: false,
      payoutData: "",
      query: {},
      toast: false,
      toastType: "",
      input_value: false
    };
  }

  componentDidMount() {
    this.setQueryStatus();
  }
  onChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  setQueryStatus = () => {
    let queryStatus = this.props.location.search;
    if (queryStatus) {
      let queryObj = returnSearchObject(queryStatus);

      this.setState(
        {
          from: queryObj.from ? new Date(queryObj.from) : "",
          to: queryObj.to ? new Date(queryObj.to) : "",
          page: parseInt(queryObj.page) ?? "",
          query: returnSearchObject(queryStatus),
        },
        () => {
          (this.state.from || this.state.to) ? this.setState({ input_value: true }) : this.setState({ input_value: false })
          pushToFilteredPage(this.props, { ...this.state.query });
          this.getpayoutList();
        }
      );
    } else {
      this.setState(
        {
          query: {
            ...this.state.query,
            page: 1,
            limit: 10,
            from: getIsoString(this.state.from),
            to: getIsoString(this.state.to),
          },
        },
        () => {
          pushToFilteredPage(this.props, this.state.query);
          this.getpayoutList();
        }
      );
    }
  };

  getpayoutList = async () => {
    let { page, filterOption, statusSearch, inputSearch, from, to } =
      this.state;
    let queryParams = manager_id ? `&manager_id=${manager_id}` : "";
    if (filterOption !== "" && inputSearch !== "")
      queryParams += filterQuery(filterOption, inputSearch);
    if (statusSearch !== "") {
      queryParams += filterQuery("status", statusSearch);
    }
    if (from !== "") queryParams += filterQuery("from", getIsoString(from));
    if (to !== "") queryParams += filterQuery("to", getIsoString(to));
    if (from > to) {
      showError(this, "Please provide valid From and To date");
    } else {
      // let response = await ApiService.getAPI(
      //   `contractor/payout?page=${page}&limit=10${queryParams}`
      // );

      let response = await ApiService.getAPI(
        `contractor/payout${buildQueryString(this.state.query)}`
      );

      if (response.success) {
        let { payouts } = response.data;
        this.setState({ payoutList: payouts, isLoading: false });
      }
    }
  };
  updateStateValues = (key, value) => {
    const updatedValue = value == null ? "" : value;
    this.setState({ [key]: updatedValue, input_value: true }, () => {
      if (this.state.from && this.state.to) {
        this.handleFilterStatus();
      }
    });
  };
  handleOnToChange = (e) => {
    this.updateStateValues("to", e ? new Date(e) : '');
  };
  handleOnFromChange = (e) => {
    this.updateStateValues("from", e ? new Date(e) : '');
  };
  handleIncrementPage = () => {
    if (this.state.payoutList.length >= 10) {
      this.setState({ page: this.state.page + 1 }, () =>
        this.handleFilterStatus()
      );
    }
  };
  handleDecrementPage = () => {
    if (this.state.page > 1) {
      this.setState({ page: this.state.page - 1 }, () =>
        this.handleFilterStatus()
      );
    }
  };

  handleFilterStatus = () => {
    let staticQuery = returnSearchObject(this.props.location.search);
    staticQuery.from = getIsoString(this.state.from) ?? "";
    staticQuery.to = getIsoString(this.state.to) ?? "";
    staticQuery.status = this.state.statusSearch ?? "";
    staticQuery.page = this.state.page ?? "";
    this.setState({ query: staticQuery }, () => {
      pushToFilteredPage(this.props, { ...this.state.query });
      this.getpayoutList();
    });
  };

  handleSearch = () => {
    this.setState({ page: 1 }, () => {
      this.handleFilterStatus();
    });
  };

  handleReset = () => {
    let self = this;
    self.setState(
      {
        from: "",
        to: "",
        page: 1,
        limit: 10,
        query: {
          page: 1,
          limit: 10,
        },
        input_value: false
      },
      () => {
        pushToFilteredPage(this.props, { ...this.state.query });
        this.getpayoutList();
      }
    );
  };
  toggleModal = (invoice_id) => {
    this.setState(
      {
        modalOpen: !this.state.modalOpen,
      },
      () => this.state.modalOpen && this.getInvoiceDetail(invoice_id)
    );
  };
  getInvoiceDetail = async (id) => {
    let response = await ApiService.getAPI(`host/invoice/${id}`);
    if (response.success) {
      this.setState({
        invoiceDetail: response.data.invoices,
      });
    }
  };
  handleCloseModal = () => {
    this.setState({ modalOpen: false });
  };
  handleSubLateFee = (amount, base_price) => {
    let lateFee = amount - base_price;
    this.setState({
      lateFee,
    });
  };

  setStatus = (status) => {
    this.setState({ statusSearch: status });
  };

  openPayoutDetails = (data) => {
    this.setState({ payoutData: data, payoutModal: true });
  };
  closePayoutSideBar = () => {
    this.setState({ payoutData: "", payoutModal: false });
  };

  render() {
    let { toast, response, toastType, from, to, input_value } = this.state;
    const selectionRange = {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    };
    let { payoutList, isLoading, page, payoutModal, payoutData } = this.state;
    return isLoading ? (
      <div className="spin-loading">
        <Spinner type="border" color="#077e8b" />
      </div>
    ) : (
      <>
        {toast && <ToastList message={response} type={toastType} />}
        <div style={{ padding: "20px", backgroundColor: '#fafafa' }}>
          <CustomListingContainer>
            <ListingHeaderLayout label={"payouts"} />
            <div style={{ margin: "24px" }}>
              <div
                className="d_flex_sb"
                style={{
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ display: "flex", width: "80%" }}>
                    <CustomDatePicker
                      from={from}
                      to={to}
                      fromChange={this.handleOnFromChange}
                      toChange={this.handleOnToChange}
                    ></CustomDatePicker>
                  </div>
                  {input_value && (from || to) && (
                    <div className="right_filter_container">
                      <Button_without_Border
                        click={this.handleReset}
                        btnLabel={"clear_filter"}
                      ></Button_without_Border>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <Row>
              <div className="col new-table">
                <Card className="shadow card_with_paginator">
                  {payoutList.length !== 0 ? (
                    <Table className="align-items-center table-flush" responsive>
                      <thead className="thead-light">
                        <tr>
                          {/* <th scope="col">Payout ID</th> */}
                          <th scope="col">Created On</th>
                          <th scope="col">No of Invoices</th>
                          <th scope="col">Overall Amount</th>
                          <th scope="col">Commission</th>
                          <th style={{ width: '10%' }} scope="col">Settlement</th>
                        </tr>
                      </thead>
                      <tbody>
                        {payoutList.map((payout, i) => (
                          <tr
                            key={i}
                            className="point"
                            onClick={() =>
                              payout.items.length > 1 &&
                              this.openPayoutDetails(payout)
                            }
                          >
                            {/* <td>
                            {returnFirstDegreeObjValue(payout, "payout_id")}
                          </td> */}
                            <td>
                              {showUtcDate(
                                returnFirstDegreeObjValue(payout, "createdAt")
                              )}
                            </td>
                            <td> {payout?.items?.length}</td>

                            <td>
                              {displayPrice(
                                returnFirstDegreeObjValue(
                                  payout,
                                  "overall_amount"
                                )
                              )}{" "}
                            </td>
                            <td>
                              {payout?.commission?.type === "percentage" ? (
                                <>
                                  {displayPrice(
                                    returnSecondDegreeObjValue(
                                      payout,
                                      "commission",
                                      "amount"
                                    )
                                  )}{" "}
                                  (
                                  {displayPercentage(
                                    returnSecondDegreeObjValue(
                                      payout,
                                      "commission",
                                      "value"
                                    )
                                  )}
                                  )
                                </>
                              ) : (
                                displayPrice(
                                  returnSecondDegreeObjValue(
                                    payout,
                                    "commission",
                                    "amount"
                                  )
                                )
                              )}
                            </td>

                            <td>
                              {displayPrice(
                                returnFirstDegreeObjValue(payout, "amount")
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    <>
                      {" "}
                      <Table
                        className="align-items-center table-flush"
                        responsive
                      >
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">Settlement Date</th>
                            <th scope="col">No. of Transactions</th>
                            <th scope="col">Amount</th>
                            <th scope="col">To</th>
                            <th scope="col">Transaction Date</th>
                            <th scope="col">Status</th>
                          </tr>
                        </thead>
                      </Table>
                      <Card className="card_with_paginator no_data_card">
                        {" "}
                        <div className="no-data">No Payouts Available</div>
                      </Card>
                    </>
                  )}
                </Card>
              </div>
            </Row>
            <Row className="align-items-center header-div jus-space new-margin-page paginator_box">
              <div className="paginate-flex-box ">
                <div
                  className="paginate-box"
                  onClick={() => this.handleDecrementPage()}
                >
                  <p
                    className={page !== 1 ? "page_available" : "page_unavailable"}
                  >
                    Previous
                  </p>
                </div>
                <div
                  className="paginate-box"
                  onClick={() => this.handleIncrementPage()}
                >
                  <p
                    className={
                      payoutList.length < 10
                        ? "page_unavailable"
                        : "page_available"
                    }
                  >
                    Next
                  </p>
                </div>
              </div>
            </Row>
          </CustomListingContainer>


          <Card className={payoutModal ? "payoutSideWidth" : "toggle-sidebar"}>
            <PayoutSideBar
              data={payoutData}
              close={this.closePayoutSideBar}
              isOpen={payoutModal}
            />
          </Card>
        </div>
      </>
    );
  }
}

export default Payouts;
