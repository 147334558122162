import React, { Component } from "react";
import ToastList from "components/Toast/ToastList";
import closeIcon from "../../assets/img/brand/close.svg";
import {
  CardHeader,
  Input,

  Spinner,
  Button,

} from "reactstrap";


let overlap = document.querySelector(":root");

class EmailModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      initPageLoading: false,
      subject: "",
      btnLoad: false,
      content: ""
    };
  }

  componentDidMount = async () => {
    overlap.style.setProperty("--width", "600px");

    // this.getProjectList();
  };


  sendData = () => {
    let { subject, content } = this.state
    this.props.sendData({ subject: subject, content: content })
  }


  render() {
    let {
      initPageLoading,
      subject,
      content,
      btnLoad,
    } = this.state;
    return initPageLoading ? (
      <div className="spin-loading">
        <Spinner type="border" color="#077e8b" />
      </div>
    ) : (
      <>
        <div>
          <CardHeader>
            <div className="flex">
              <div className="fw-500 f16 cl000000">Estimate to johnson</div>
              <img
                src={closeIcon}
                onClick={() => this.props.close()}
                className="point"
              />
            </div>
          </CardHeader>
          <div className="cardContainer">
            <div style={{marginBottom:"2rem"}}>
              <div
                className="cl2A1E17 mb-15 f14 fw-400 drawer_sub_heading"
                style={{ marginTop: "15px" }}
              >
                Subject*
              </div>
              <Input
                style={{
                  fontSize: "14px",
                  color: "#212529",
                  border: "1px solid #f0f0f0",
                }}
                type="text"
                value={subject}
                name="subject"
                onChange={(e) => {
                  this.setState({ subject: e.target.value });
                }}
              />
            </div>





            <div>
              <div
                className="cl2A1E17 mb-15 f14 fw-400 drawer_sub_heading"
                style={{ marginTop: "15px" }}
              >
                Email Content*
              </div>
              <div>
                {" "}
                <textarea
                  className="text-area-add-project"
                  value={content}
                  style={{
                    fontSize: "14px",
                    color: "#212529",
                    border: "1px solid #f0f0f0",
                  }}
                  name="content"
                  onChange={(e) => {
                    this.setState({ content: e.target.value });
                  }}
                />
              </div>
            </div>

            <div style={{ marginTop: "30px" }} className="flexEnd">
              <div
                className="mr-20 point f14 button-secondary"
                style={{ fontWeight: "500" }}
                onClick={() => this.props.close()}
              >
                Cancel
              </div>
              <Button
                color="primary"
                className="button-wrapping "
                size="sm"
                onClick={this.sendData}
                disabled={this.state.isApiCallPending}
              >
                {btnLoad ? (
                  <div className="spin-loading-save w100 h100">
                    <Spinner
                      style={{
                        height: "20px",
                        width: "20px",
                      }}
                      type="border"
                      color="#0A8080"
                    />
                  </div>
                ) : (
                  "Send"
                )}
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default EmailModal;
