import React, { Component } from "react";
import {
    CardHeader,
    Input,
    DropdownToggle,
    UncontrolledDropdown,
    Media,
    DropdownMenu,
    DropdownItem,
    InputGroup,
    InputGroupText,
    Spinner,
    Button,
    Tag,
    Card,
    Dropdown,
} from "reactstrap";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import closeIcon from "../../assets/img/brand/close.svg";
import dateLight from "../../assets/img/brand/datePicker.svg";
import moment from "moment";
import uploadIcon from "../../assets/img/icons/upload_icon.svg";
import onePersonIcon from "../../assets/img/icons/onePersonIcon.svg";
import twoPersonIcon from "../../assets/img/icons/twoPersonIcon.svg";
import { getCloudinaryImage } from "constants/utils";
import removeIcon from "../../assets/img/brand/removeIcon.svg";
import Tags from "components/Tags/Tags";
import ApiService from "constants/ApiService";
import Add from "../../assets/img/brand/addIcon.svg";
import pksLogo from "../../assets/img/brand/pkslogo.svg";
import sendChat from "../../assets/img/brand/send_chat.svg";
import Lottie from "react-lottie";
import NoData from "../../assets/Lotties/noData.json";
import {
    showSuccess,
    isInvalidName,
    showError,
    uploadCloudinary,
    Isvalidname,
} from "constants/utils";
import AddClient from "./addClient";
import TaskList from "views/Task/TaskList";
import Autocomplete from "components/AutoComplete/AutoComplete";
let overlap = document.querySelector(":root");

class ProjectChat extends Component {
    constructor(props) {
       
        super(props);
        this.inputKey = Date.now();
        this.fileInputRef = React.createRef();
        this.myElementRef = React.createRef();
        this.myInputElementRef = React.createRef();
        this.state = {
            estimate_id: "",
            name: "",
            client_name: "",
            clientsList: [],
            subContractor_name: "",
            sub_contractorList: [],
            start_date: "",
            image: [],
            selected_team: "",
            actual_start: "",
            end_date: "",
            contractor_type: "",
            subContractor: {},
            imageLoading: false,
            desc: "",
            tags: [],
            contractorInput: "",
            btnLoad: false,
            edit: false,
            initAddProjectLoading: true,
            tag: "",
            subContractor_id: "",
            clientAdd: false,
            isSearchOpen: false,
            activeIndex: -1,
            disabled: false,
            isApiCallPending: false,
            file: null,
            projectStartDate: null,
            projectActualStarDate: null,
            projectEndDate: null,
            otherProObj: {},
            initPageLoading: false,
            toast: false,
            response: "",
            toastType: "",
            activeChat: "client",
            comments: [
                {
                    name: "john",
                    content: "Hello, I am Pritesh. How Can I help you? Lorem ipsum dolor sit amet",
                    date: new Date(),
                },
                {
                    name: "john",
                    content: "hi",
                    date: new Date(),
                },
                {
                    name: "john",
                    content: "hi",
                    date: new Date(),
                },
                {
                    name: "john",
                    content: "hi",
                    date: new Date(),
                },
                {
                    name: "john",
                    content: "hi",
                    date: new Date(),
                },
                {
                    name: "john",
                    content: "hi",
                    date: new Date(),
                },
                {
                    name: "john",
                    content: "hi",
                    date: new Date(),
                },
                {
                    name: "john",
                    content: "hi",
                    date: new Date(),
                },
                {
                    name: "myself",
                    content: "hi",
                    date: new Date(),
                },
                {
                    name: "myself",
                    content: "Hello, I am Pritesh. How Can I help you? Lorem ipsum dolor sit amet",
                    date: new Date(),
                },
                {
                    name: "myself",
                    content: "hi",
                    date: new Date(),
                },
            ],
            project: {},

        };

        this.debouncedApiCall = this.debounce(this.createProjectHandler, 1000);
    }

    debounce(func, delay) {
        let timeoutId;
        return function () {
            const context = this;
            const args = arguments;
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                func.apply(context, args);
            }, delay);
        };
    }

    componentDidMount = async () => {
        overlap.style.setProperty("--width", "500px");
        this.setState({ initAddProjectLoading: false })
    };
    componentDidMount() {
        this.getProjectDetails();
    }

    update = (e) => {
        const textarea = this.textareaRef.current;
        textarea.style.height = "28px";
        textarea.style.height = `${textarea.scrollHeight}px`;
        this.setState({
            inputMessage: e.target.value,
        });
    };

    handleKeyDown = (e) => {
        if (e.key === "Enter" && !e.shiftKey && !e.ctrlKey) {
            e.preventDefault();
        } else if (e.key === "Enter" && (e.shiftKey || e.ctrlKey) && !e.repeat) {
            this.insertLineBreak();
            e.preventDefault();
        }
        e.key === "Enter" &&
            !e.repeat &&
            !e.shiftKey &&
            !e.ctrlKey &&
            this.sendMessage();
        this.date = [];
    };

    sendMessage = () => {
        const textarea = this.textareaRef.current;
        textarea.style.height = "28px";
    };

    insertLineBreak() {
        const textarea = this.textareaRef.current;
        const { selectionStart, selectionEnd, value } = textarea;
        const newValue =
            value.substring(0, selectionStart) + "\n" + value.substring(selectionEnd);
        textarea.value = newValue;
        textarea.setSelectionRange(selectionStart + 1, selectionStart + 1);
        textarea.style.height = `${textarea.scrollHeight}px`;
    }

    render() {

        const NodataFound = {
            loop: true,
            autoplay: true,
            animationData: NoData,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
            },
        };
        let { initPageLoading, toast, response, toastType, comments, activeChat } = this.state;
        return initPageLoading ? (
            <div className="spin-loading">
                <Spinner type="border" color="#077e8b" />
            </div>
        ) : (
            <>
                <div>
                    <CardHeader>
                        <div className="flex">
                            <div className="fw-500 f16 cl000000"></div>
                            <img
                                src={closeIcon}
                                onClick={() => this.props.close()}
                                className="point"
                            />
                        </div>
                    </CardHeader>


                    <div className="crm_comments_module" style={{ border: "0" }}>
                        <>
                            <div
                                className="bread-cursor"
                                style={{ padding: "1rem", fontWeight: "500", display: "flex", maxHeight: "4rem", gap: "2rem", borderBottom: "1px solid #F0F0F0" }}
                            >
                                <div style={{ display: "flex", alignItems: "center", gap: "0.5rem", borderBottom: activeChat === "team" ? "2px solid #0A8080" : "", padding: activeChat === "team" ? "1rem 0rem 2rem 0" : "" }} onClick={() => { this.setState({ activeChat: "team" }) }}>
                                    <img src={onePersonIcon} alt="client" />
                                    <div>Team</div>


                                </div>
                                <div style={{ display: "flex", alignItems: "center", gap: "0.5rem", borderBottom: activeChat === "client" ? "2px solid #0A8080" : "", padding: activeChat === "client" ? "1rem 0rem 2rem 0" : "" }} onClick={() => { this.setState({ activeChat: "client" }) }}>
                                    <img src={twoPersonIcon} alt="client" />
                                    <div>Client</div>

                                </div>
                            </div>





                            <div>
                                <div
                                    className="crm_comments"
                                    style={{ height: "500px", marginTop: "0px" }}
                                >
                                    {comments.length ? (
                                        comments.map((obj) => (
                                            (obj.name === "myself") ?
                                                <div style={{ marginLeft: "1rem", minWidth: "10rem", marginBottom: "1rem", marginRight: "1rem" }}>
                                                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                                        <div style={{ backgroundColor: "white", position: "relative", color: "#757575", padding: "1rem 4rem 1rem 1rem", maxWidth: "71%", borderRadius: "0px 8px 8px 8px", border: "1px solid #E4E9EE" }}>{obj.content}

                                                            <span style={{ position: "absolute", bottom: "3%", right: "2%", color: "#757575", fontSize: "12px", fontWeight: "400", padding: "0.25rem" }}>{moment(obj.date).format("hh:mm A")}</span>
                                                        </div>


                                                    </div>
                                                </div> :
                                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", padding: "1rem", gap: "1rem" }}>
                                                    <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                                                        <img
                                                            width="50px"
                                                            height="50px"
                                                            src={
                                                                // project?.image?.length > 0
                                                                //   ? getCloudinaryImage(
                                                                //       100,
                                                                //       100,
                                                                //       project.image[0],
                                                                //       "c_fill"
                                                                //     )
                                                                //   :
                                                                pksLogo
                                                            }
                                                            alt="logo"
                                                        ></img>
                                                        <div>{obj.name}</div>
                                                    </div>
                                                    <div style={{ marginLeft: "1rem", minWidth: "10rem" }}>
                                                        <div style={{ display: "flex" }}>
                                                            <div style={{ backgroundColor: "#0A8080", position: "relative", color: "white", padding: "1rem 4rem 1rem 1rem", maxWidth: "71%", borderRadius: "0px 8px 8px 8px" }}>{obj.content}

                                                                <span style={{ position: "absolute", bottom: "3%", right: "2%", color: "#FFFFFF", fontSize: "12px", fontWeight: "400", padding: "0.25rem" }}>{moment(obj.date).format("hh:mm A")}</span>
                                                            </div>


                                                        </div>
                                                    </div>
                                                </div>
                                        ))
                                    )
                                        : (
                                            <>
                                                <div className="no_data_dashboard">
                                                    <Lottie options={NodataFound} height={100} width={100} />
                                                    <div className="mt_20">
                                                        <p>No Comments Found</p>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                </div>
                                <div className="crm_comments_input">

                                    <div className=" comment_footer">
                                        <div className="ft_1">
                                            <textarea
                                                ref={this.textareaRef}
                                                style={{ height: this.state.textAreaHeight }}
                                                onInput={this.update}
                                                value={this.state.inputMessage}
                                                onKeyDown={this.handleKeyDown}
                                                placeholder="Type here..."
                                            />
                                            <div className="ft_img">
                                                {this.state.messageSendloader ? (
                                                    // <Spinner color="#012a4d">Loading...</Spinner>
                                                    <div className="image-loading spinner-cut">
                                                        <Spinner
                                                            style={{ width: "30px", height: "30px" }}
                                                            type="border"
                                                            color="#012A4D"
                                                        />
                                                    </div>
                                                ) : (
                                                    <img
                                                        style={{ cursor: "pointer" }}
                                                        onClick={this.sendMessage}
                                                        src={sendChat}
                                                    ></img>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    </div>




                </div>




            </>
        );
    }
}
export default ProjectChat;
