import PropTypes from "prop-types";
import { Modal, Button, Spinner } from "reactstrap";
import accepted from "../../assets/img/new Logo/Accepted.svg";
import rejected from "../../assets/img/new Logo/rejectedestimate1.svg";
import alreadyacc from "../../assets/img/new Logo/alreadyAccepted.svg";
import alreadyrej from "../../assets/img/new Logo/rejectedestimate1.svg";
import notfound from "../../assets/img/new Logo/404.svg";
import React, { Component } from "react";
import { Custom_Button } from "components/Buttons/Button";
import { Link } from "@mui/material";
import ApiService from "constants/ApiService";
import Env from "constants/Env";
import { getAuthToken } from "constants/utils";
import { showError } from "constants/utils";

export default class StatusShowModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this?.props?.props?.props?.match?.params?.id,
      client_id: this?.props?.client_id,
      btnLoading: false,
      statusType: {
        accepted: `${
          this.props.component === "workorder" ? "Work order" : "Estimate"
        } has been  accepted successfully`,
        rejected: `${
          this.props.component === "workorder" ? "Work order" : "Estimate"
        } has been  rejected successfully`,
        drafts: `${
          this.props.component === "workorder" ? "Work order" : "Estimate"
        } not found`,
        alreadyAccept: `${
          this.props.component === "workorder" ? "Work order" : "Estimate"
        } has been already  accepted `,
        alreadyReject: `${
          this.props.component === "workorder" ? "Work order" : "Estimate"
        } has been  already rejected`,
      },
    };
  }

  statusSet = (status) => {
    if (status === "alreadyAccept") return this.state.statusType.alreadyAccept;
    else if (status === "alreadyReject")
      return this.state.statusType.alreadyReject;
    else if (status === "accepted") return this.state.statusType.accepted;
    else if (status === "draft_no_response")
      return this.state.statusType.drafts;
    else return this.state.statusType.rejected;
  };

  imageSet = (status) => {
    if (status === "alreadyAccept") return alreadyacc;
    else if (status === "alreadyReject") return alreadyrej;
    else if (status === "accepted") return accepted;
    else if (status === "rejected") return rejected;
    else return notfound;
  };

  download = async () => {
    this.setState({ btnLoading: true });

    let url;
    if (this.props.component === "workorder") {
      url =
        Env.BASE_URL + `auth/contractor/workOrder/download/${this.state.id}`;
    } else {
      url =
        Env.BASE_URL +
        `auth/contractor/estimate/download/${this.state.client_id}/${this.state.id}`;
    }

    try {
      const response = await fetch(url);
      const blob = await response.blob();

      // Create a temporary URL for the Blob
      const blobUrl = window.URL.createObjectURL(blob);

      // Create a link element and trigger the download
      const a = document.createElement("a");
      a.href = blobUrl;
      a.download = this.state.id ? this.state.id.toString() : "download"; // Set desired filename
      document.body.appendChild(a);
      a.click();

      // Clean up by revoking the temporary URL
      window.URL.revokeObjectURL(blobUrl);

      this.setState({ btnLoading: false });
    } catch (error) {
      this.setState({ btnLoading: false });
    }
  };

  render() {
    const { btnLoading } = this.state;
    return (
      <div>
        <Modal isOpen={this.props?.isOpen} centered="true">
          <div
            style={{
              width: "500px",
              height: "371px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {}
            {/* <div style={{
                            color: "#303030",
                            borderBottom: "1px solid #E1E8F0",
                            fontSize: '18px',
                            fontWeight: "600",
                            padding: '24px 0px 19px 24px'

                        }}>
                            Status
                        </div> */}

            <div
              style={{
                height: "225px",
                width: "100%",
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src={this.imageSet(this.props.status)}
                style={{
                  height: "170px",
                  width: "170px",
                }}
                alt="status"
              />
            </div>

            <div
              style={{
                padding: "",
                fontSize: "16px",
                fontWeight: "600",
                color: "#333333",
                textAlign: "center",
              }}
            >
              {this.statusSet(this.props?.status)}
            </div>

            <div>
              {["alreadyAccept", "accepted"].includes(this.props.status) && (
                <div style={{ marginTop: "30px" }}>
                  <Custom_Button
                    isLoading={btnLoading}
                    isPrimary
                    btnLabel={`Download ${
                      this.props.component === "workorder"
                        ? "workorder"
                        : "estimate"
                    }`}
                    click={() => this.download()}
                  />
                </div>
              )}
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
