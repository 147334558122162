import { Component } from "react";
import moment from "moment";
import { showUtcDate } from "constants/utils";
let projectLists = [
  { name: "Project 1", due: new Date(), status: "active", id: "123" },
  { name: "Project 2", due: new Date(), status: "progress", id: "12234" },
  { name: "Project 3", due: new Date(), status: "on_hold", id: "123441" },
  { name: "Project 4", due: new Date(), status: "started", id: "92034" },
  { name: "Project 5", due: new Date(), status: "accepted", id: "123" },
  { name: "Project 6", due: new Date(), status: "completed", id: "90980" },
  { name: "Project 7", due: new Date(), status: "cancelled", id: "809182309" },
  { name: "Project 8", due: new Date(), status: "pending", id: "93091283" },
  { name: "Project 9", due: new Date(), status: "pending", id: "8329893" },
];
export default class ProjectLists extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectLists: projectLists,
    };
  }

  redirectToClientProjectDetail(obj) {
    this.props.props.props.history.push(`/client/123/${obj.id}`);
  }

  render() {
    let { projectLists } = this.state;
    return (
      <>
        <div className="projectLists">
          {projectLists.map((obj, i) => (
            <div
              key={i}
              onClick={() => this.redirectToClientProjectDetail(obj)}
              className="ProjectCard"
            >
              <div className="projectName">{obj.name}</div>
              <div className="project_bottom">
                <div className="project_left">
                  <div className="due_date">Due Date</div>
                  <div className="">
                    {showUtcDate(obj.due)}
                  </div>
                </div>
                <div className="project_right">{obj.status}</div>
              </div>
            </div>
          ))}
        </div>
      </>
    );
  }
}
