import React, { Component } from "react";
import {
  Card,
  Table,
  Container,
  Row,
  Breadcrumb,
  Col,
  Button,
  InputGroup,
  InputGroupText,
  Spinner,
  DropdownMenu,
  DropdownItem,
  Media,
  UncontrolledDropdown,
  DropdownToggle,
  Dropdown,
  Modal,
  CardHeader,
  CardBody,
  Input,
} from "reactstrap";
import projectTimeline from "../../../src/assets/img/brand/projectTimeline.svg";

import TimeLine from "components/Timeline/Timeline";

import AddProject from "../Project/addProject";
import CountUp from "react-countup";

import { Drawer } from "antd";
import removeIcon from "../../assets/img/brand/removeIcon.svg";

import dateLight from "../../assets/img/brand/datePicker.svg";

import uploadIcon from "../../assets/img/icons/upload_icon.svg";
import ApiService from "constants/ApiService";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { getIsoString, filterQuery } from "constants/utils";

import ToastList from "components/Toast/ToastList";
import AllProjectIcon from "../../../src/assets/img/icons/all_project_icon.svg";
import ActiveProjectIcon from "../../../src/assets/img/icons/active_project_icon.svg";

import { showError } from "constants/utils";
import { returnSearchObject } from "constants/utils";
import { pushToFilteredPage } from "constants/utils";
import { buildQueryString } from "constants/utils";
import moment from "moment";

import showMore from "../../assets/img/brand/showMore.svg";
import { showSuccess } from "constants/utils";
import { Progress, BreadcrumbItem } from "reactstrap";
import closeIcon from "../../assets/img/brand/close.svg";

import Tags from "components/Tags/Tags";
import { uploadCloudinary } from "constants/utils";
import { getCloudinaryImage } from "constants/utils";
import DeleteModal from "./DeleteModal";
import { calculatePercentageNumber } from "constants/utils";
import AllTasksIcon from "../../../src/assets/img/icons/all_tasks_icon.svg";
import CompletedProjectIcon from "../../../src/assets/img/icons/completed_project_icon.svg";
import AddOrder from "./addOrder";
import { showUtcDate } from "constants/utils";

let overlap = document.querySelector(":root");

class PurchaseOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requestList: [],
      project_list: [],
      total: 0,
      filterToggle: false,
      from: "",
      to: "",
      page: 1,
      open: false,
      client_id: "",
      inputSearch: "",
      filterOption: "",
      statusSearch: this.props.location?.search ? "pending" : "",
      toast: false,
      response: "",
      image: [],
      iamge_name: "",
      toastType: "",
      isLoading: true,
      clientsList: [],
      btnLoad: false,
      query: {},
      name: "",
      client: {},
      start_date: "",
      actual_start: "",
      imageLoading: false,
      client_name: "",
      inputText: "",
      items: [],
      desc: "",
      tags: [],
      end_date: "",
      requestId: "",
      openModal: false,
      delete_data: {},
      cancelModal: false,
      project_id: "",
      all_projects: 0,
      active_projects: 0,
      completed_projects: 0,
      all_tasks: 0,
      isTimeLineOpen: false,
    };
  }
  componentDidMount = async () => {
    overlap.style.setProperty("--width", "600px");
    this.setQueryStatus();
  };

  componentWillUnmount() {
    overlap.style.setProperty("--width", "450px");
  }

  closeModal = () => {
    this.setState({ openModal: false, project_id: "" });
    this.getProjectList();
  };
  handleDelete = async () => {
    let response = await ApiService.patchAPI(
      `contractor/project/delete/${this.state.delete_data.project_id}`,
      {}
    );
    if (response.success) {
      showSuccess(this, response.message);
      this.setState({ delete_data: {} });
      this.closeModal();
      this.getProjectList();
    } else {
      showError(this, response.message);
    }
  };

  setQueryStatus = () => {
    let queryStatus = this.props.location.search;
    if (queryStatus) {
      let queryObj = returnSearchObject(queryStatus);
      this.setState(
        {
          from: queryObj.from ? new Date(queryObj.from) : "",
          to: queryObj.to ? new Date(queryObj.to) : "",
          statusSearch: queryObj.status ?? "",
          page: parseInt(queryObj.page) ?? "",
          query: returnSearchObject(queryStatus),
        },
        () => {
          pushToFilteredPage(this.props, { ...this.state.query });
          this.getProjectList();
        }
      );
    } else {
      this.setState(
        {
          query: {
            ...this.state.query,
            page: 1,
            limit: 10,
          },
        },
        () => {
          pushToFilteredPage(this.props, this.state.query);
          this.getProjectList();
        }
      );
    }
  };

  redirectDetailPage = (id) => {
    this.props.history.push(`projects/detail/${id}`);
  };

  setPage = (page) => {
    this.props.history.push(page);
  };
  dateChangeHandler = (e, value) => {
    this.setState({ [value]: e });
  };

  editProject = async (data) => {
    this.setState(
      {
        project_id: data.project_id,
      },
      () => {
        this.setState({ open: true });
      }
    );
  };
  getRequestsList = async () => {
    let { filterOption, statusSearch, inputSearch, from, to } = this.state;
    let queryParams = "";
    if (filterOption !== "" && inputSearch !== "")
      queryParams += filterQuery(filterOption, inputSearch);
    if (statusSearch !== "") {
      queryParams += filterQuery("status", statusSearch);
    }
    if (from !== "") queryParams += filterQuery("from", getIsoString(from));
    if (to !== "") queryParams += filterQuery("to", getIsoString(to));
    if (from > to) {
      showError(this, "Please provide valid From and To date");
    } else {
      let response = await ApiService.getAPI(
        `contractor/open_request${buildQueryString(this.state.query)}`
      );

      if (response.success) {
        let { open_request, total } = response.data;
        this.setState({
          requestList: open_request,
          total: total,
          isLoading: false,
        });
      }
    }
  };

  getProjectList = async () => {
    let { filterOption, statusSearch, inputSearch, from, to } = this.state;
    let queryParams = "";
    if (filterOption !== "" && inputSearch !== "")
      queryParams += filterQuery(filterOption, inputSearch);
    if (statusSearch !== "") {
      queryParams += filterQuery("status", statusSearch);
    }

    if (from !== "") queryParams += filterQuery("from", getIsoString(from));
    if (to !== "") queryParams += filterQuery("to", getIsoString(to));
    if (from > to) {
      showError(this, "Please provide valid From and To date");
    } else {
      let response = await ApiService.getAPI(
        `contractor/project/${buildQueryString(this.state.query)}`
      );

      if (response.success) {
        this.setState({
          isLoading: false,
          project_list: response.data.projects,
        });
      }
    }
  };
  createProjectHandler = async () => {
    let {
      name,
      client_name,
      start_date,
      end_date,
      actual_start,
      client_id,
      desc,
      project_id,
      edit,
      tags,
    } = this.state;
    if (!name || !client_name || !start_date || !end_date || !actual_start)
      return showError(this, "Please fill all the required fields");
    this.setState({ btnLoad: true });
    let data = {
      name: name,
      client: {
        id: client_id,
        name: client_name,
      },
      start_date: start_date,
      end_date: end_date,
      actual_start: actual_start,
      desc: desc,
      tags: tags,
    };
    let response = edit
      ? await ApiService.patchAPI(`contractor/project/${project_id}`, data)
      : await ApiService.postAPI("contractor/project", data);
    if (response.success) {
      showSuccess(this, response.message);
      this.setState({
        btnLoad: false,
        open: false,
        name: "",
        start_date: "",
        end_date: "",
        actual_start: "",
        desc: "",
        tags: [],
        client_name: "",
        client_id: "",
      });
      this.getProjectList();
    } else {
      this.setState({ btnLoad: false });
      showError(this, response.message);
    }
  };

  handleTagsChange = (tags) => {
    this.setState({ tags: tags });
  };
  handleInputChange = (event) => {
    this.setState({ inputText: event.target.value });
  };

  handleEnterKey = (event) => {
    if (event.key === "Enter" && this.state.inputText.trim() !== "") {
      this.setState((prevState) => ({
        inputText: "",
        items: [...prevState.items, prevState.inputText],
      }));
    }
  };
  toggleFilterMenu = () => {
    this.setState({ filterToggle: !this.state.filterToggle });
  };
  onChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  updateStateValues = (key, value) => {
    this.setState(
      {
        [key]: value,
      },
      function () {
        if (value === null) {
          this.setState({
            [key]: "",
          });
        }
      }
    );
  };
  handleOnToChange = (e) => {
    this.updateStateValues("to", e ? new Date(e) : '');
  };
  handleOnFromChange = (e) => {
    this.updateStateValues("from", e ? new Date(e) : '');
  };
  handleIncrementPage = () => {
    if (this.state.project_list.length >= 10) {
      this.setState({ page: this.state.page + 1 }, () =>
        this.handleFilterStatus()
      );
    }
  };
  handleDecrementPage = () => {
    if (this.state.page > 1) {
      this.setState({ page: this.state.page - 1 }, () =>
        this.handleFilterStatus()
      );
    }
  };
  capitalizeFirstLetter = (str) => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  imageHandler = async (e, subStatus) => {
    let { image } = this.state;
    let file = e.target.files[0];

    if (e.target.files.length > 0) {
      this.setState({ imageLoading: true });
    }

    if (
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/jpeg" ||
      file.type === "application/pdf"
    ) {
      if (file.size < 1000000) {
        let data = await uploadCloudinary(file, "host");
        if (data.url) {
          let imgArr = [...image];
          let imageObj = {
            format: data.format,
            public_id: data.public_id,
            version: `v${data.version}`,
            type: "image",
          };
          imgArr.push(imageObj);
          this.setState({
            image: imgArr,
            imageLoading: false,
          });
        } else {
          showError(this, "Could not upload image");
          this.setState({ imageLoading: false });
        }
      } else {
        showError(this, "File size should not exceeds 1 MB");
        this.setState({ imageLoading: false });
      }
    } else {
      showError(this, "Invalid Format");
      this.setState({ imageLoading: false });
    }
  };

  deleteImage = () => {
    this.setState({ image: [], image_name: "" });
  };

  closeTimeline = () => {
    this.setState({ isTimeLineOpen: false });
  };

  handleFilterStatus = () => {
    let staticQuery = returnSearchObject(this.props.location.search);
    staticQuery.from = getIsoString(this.state.from) ?? "";
    staticQuery.to = getIsoString(this.state.to) ?? "";
    staticQuery.status = this.state.statusSearch ?? "";
    staticQuery.page = this.state.page ?? "";
    if (this.state.inputSearch !== "" && this.state.filterOption !== "") {
      staticQuery[this.state.filterOption] = this.state.inputSearch;
    } else staticQuery[this.state.filterOption] = "";
    this.setState({ query: staticQuery }, () => {
      pushToFilteredPage(this.props, { ...this.state.query });
      this.getProjectList();
    });
  };

  handleSearch = () => {
    this.setState({ page: 1 }, () => {
      this.handleFilterStatus();
    });
  };

  handleReset = () => {
    let self = this;
    self.setState(
      {
        from: "",
        to: "",
        statusSearch: "",
        filterOption: "",
        inputSearch: "",
        page: 1,
        limit: 10,
        query: {
          page: 1,
          limit: 10,
        },
      },
      () => {
        pushToFilteredPage(this.props, { ...this.state.query });
        this.getProjectList();
      }
    );
  };

  setStatus = (status) => {
    this.setState({ statusSearch: status, page: 1 }, () => {
      this.handleFilterStatus();
    });
  };

  redirectToAddRequest = async () => {
    let response = await ApiService.getAPI(`contractor/client?status=active`);

    if (response.success) {
      let { clients } = response.data;
      this.setState({
        clientsList: clients,
        edit: false,
        open: true,
      });
    }
  };

  editRequest = (data) => {
    this.props.history.push(`/admin/request/update/${data?.open_request_id}`);
  };

  onClose = () => {
    this.setState({ open: false, project_id: "" });
    this.getProjectList();
  };

  cancelRequest = async (request) => {
    this.setState({ cancelModal: true, requestId: request.open_request_id });
  };

  resendRequest = async (request) => {
    let response = await ApiService.patchAPI(
      `contractor/open_request/resend/${request.open_request_id}`
    );
    if (response.success) {
      showSuccess(this, response.message);
      this.getProjectList();
    } else {
      showError(this, response.message);
    }
  };
  resendInvoice = async (request) => {
    let response = await ApiService.patchAPI(
      `contractor/open_request/invoice/${request.open_request_id}`
    );
    if (response.success) {
      showSuccess(this, response.message);
      this.getProjectList();
    } else {
      showError(this, response.message);
    }
  };

  render() {
    let {
      requestList,
      isLoading,
      project_id,
      page,
      statusSearch,
      toast,
      response,
      toastType,
      clientsList,

      project_list,

      name,
      imageLoading,
      start_date,
      actual_start,
      desc,
      end_date,
      tags,
      client_name,

      btnLoad,
      inputSearch,
      filterOption,
      image,
      openModal,
      delete_data,
      open,
      edit,
      all_projects,
      active_projects,
      completed_projects,
      all_tasks,
    } = this.state;
    return isLoading ? (
      <div className="spin-loading">
        <Spinner type="border" color="#077e8b" />
      </div>
    ) : (
      <>
        {openModal && (
          <DeleteModal
            modalState={openModal}
            type="project"
            name={delete_data.name}
            closeModal={this.closeModal}
            props={this}
            id={this.state.project_id}
          />
        )}

        <Container className="mt-8 card-pad-wrapper" fluid>
          {toast && <ToastList message={response} type={toastType} />}
          <div>
            <Breadcrumb></Breadcrumb>
          </div>

          <Row className="align-items-center header-div">
            <Col xs="8" className="manage-con">
              <div>
                <Breadcrumb className="">
                  <BreadcrumbItem
                    // onClick={() => this.props.history.push("project_overview")}
                    className="bread-cursor"
                  >
                    Purchase Orders
                  </BreadcrumbItem>
                  {/* <BreadcrumbItem active>List</BreadcrumbItem> */}
                </Breadcrumb>
              </div>
            </Col>
            <Col
              xs="4"
              className="manage-con flexy-content flexEnd"
              style={{ gap: "5%" }}
            >
              <div className="status_select ml-10" style={{ width: "35%" }}>
              <UncontrolledDropdown className="status_select_div">
                  <DropdownToggle className="pr-0 drop_down_container">
                    <Media className="capitalize">
                     Purchase Orders
                      <i className="fas fa-angle-down"></i>
                    </Media>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-arrow" bottom>
                    {" "}
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() => this.props.history.push("/admin/project_overview")}
                    >
                      Overview
                    </DropdownItem>{" "}
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() => this.props.history.push("/admin/tasks_lists")}
                    >
                      Tasks
                    </DropdownItem>{" "}
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() => this.props.history.push("/admin/payments")}
                    >
                      Payments
                    </DropdownItem>
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() => this.props.history.push("/admin/purchase_orders")}
                      // onClick={() => this.setPage("clients")}
                    >
                      Purchase Orders
                    </DropdownItem>
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() => this.props.history.push("/admin/changeOrders")}
                    >
                      Change Orders
                    </DropdownItem>{" "}
                    <DropdownItem
                      onClick={() => this.props.history.push("/admin/comments")}
                      className="dropdown_item"
                      // onClick={() => this.setPage("proposal")}
                    >
                      Comments
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => this.props.history.push("/admin/documents")}
                      className="dropdown_item"
                      // onClick={() => this.setPage("projects")}
                    >
                      Documents
                    </DropdownItem>
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() => this.props.history.push("/admin/photos")}
                      // onClick={() => this.setPage("clients")}
                    >
                      Photos
                    </DropdownItem>
                 
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
              <div>
                <Button
                  color="primary"
                  size="sm"
                  className="button-wrapping"
                  onClick={() => this.setState({ open: true })}
                >
                  Add Purchase Order
                </Button>
              </div>
              {/* <h3 className="mb-0 managers-crumb"></h3> */}
            </Col>
          </Row>

          <InputGroup className="mb-5 mb_mt_28 flex-content align-left">
            <div className="filters_container">
              <div className="left_filter_container">
                {" "}
                <div className="select_with_input mr-10">
                  <Dropdown variant="input-group" className="select_input_drop">
                    <select
                      id="inputGroupSelect01"
                      className="form_select fixed_select"
                      name="filterOption"
                      value={filterOption}
                      onChange={this.onChangeHandler}
                    >
                      <option value="">Search</option>
                      {/* <option value="username">Customer</option> */}
                      <option value="name">Name</option>
                      {/* <option value="property_id">Property Id</option> */}
                    </select>
                    <i className="fas fa-angle-down"></i>
                  </Dropdown>
                  <Input
                    aria-label="Text input with dropdown button"
                    className="input-filter-toggle"
                    value={inputSearch}
                    name="inputSearch"
                    onChange={this.onChangeHandler}
                  />
                </div>
             
              </div>
              <div className="right_filter_container">
                <Button
                  color="primary"
                  onClick={this.handleReset}
                  size="sm"
                  className="button-wrapping search-wrapper content-flex mr-4 button-secondary ml-4 reset-btn"
                >
                  Reset
                </Button>
                <Button
                  color="primary"
                  onClick={() => this.handleSearch()}
                  size="sm"
                  className="button-wrapping search-wrapper ml-0"
                >
                  Search
                </Button>
              </div>
            </div>
          </InputGroup>
          <Row>
            <div className="col new-table">
              <Card className="shadow card_with_paginator">
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col" className="pad20">
                        Name
                      </th>
                      <th scope="col">Purchased Date</th>
                      <th scope="col">Price</th>
                      <th scope="col">Description</th>
                    
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  {project_list && project_list.length !== 0 ? (
                    <tbody>
                      {project_list.map((project, i) => (
                        <tr key={i}>
                          <td
                            className="cursor-point capitalize pad20"
                            onClick={() =>
                              this.redirectDetailPage(project.project_id)
                            }
                            style={{ width: "20%" }}
                          >
                            <div className="project_list_content" style={{ width: "20%" }}>
                              {this.capitalizeFirstLetter(project.name)}
                            </div>
                          </td>
                       

                          <td>
                            <div className="project_list_content" style={{ width: "20%" }}>
                              {" "}
                              {showUtcDate(project.start_date)}
                            </div>
                          </td>
                          <td>
                            <div className="project_list_content" style={{ width: "20%" }}>
                           $100
                            </div>
                          </td>

                          <td>
                            <div className="project_list_content" style={{ width: "20%" }}>
                        lorem asdfadsf asdfasdf asdfasdfadsf 
                            </div>
                          </td>
                        

                     

                          <td
                            style={{
                         
                              borderTop:'none'
                            }}
                          >
                          
                            <div className="project_list_content"></div>
                            <UncontrolledDropdown
                              nav
                              className="flexy-content position_u"
                            >
                              <DropdownToggle className="pr-0 nav-notice" nav>
                                <Media className="align-items-center">
                                  <img src={showMore} alt="show more" />
                                </Media>
                              </DropdownToggle>
                              <DropdownMenu
                                className="dropdown-menu-arrow min-width-drop"
                                right
                              >
                                <DropdownItem
                                  onClick={() => this.editProject(project)}
                                >
                                  <span>Edit</span>
                                </DropdownItem>
                                {/* 
                                <DropdownItem
                                  onClick={() =>
                                    this.setState({
                                      openModal: true,
                                      delete_data: project,
                                      project_id: project.project_id,
                                    })
                                  }
                                >
                                  <span>Delete</span>
                                </DropdownItem> */}
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td className="no-data">No Project Available</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  )}
                </Table>
              </Card>
            </div>
          </Row>
          <Row className="align-items-center header-div jus-space new-margin-page paginator_box">
            <div className="paginate-flex-box ">
              <div
                className="paginate-box"
                onClick={() => this.handleDecrementPage()}
              >
                <p
                  className={page !== 1 ? "page_available" : "page_unavailable"}
                >
                  Previous
                </p>
              </div>
              <div
                className="paginate-box"
                onClick={() => this.handleIncrementPage()}
              >
                <p
                  className={
                    project_list.length < 10
                      ? "page_unavailable"
                      : "page_available"
                  }
                >
                  Next
                </p>
              </div>
            </div>
          </Row>

          <Card
            className={this.state.open ? "exportSideBar" : "toggle-sidebar"}
          >
            {this.state.open && (
              <AddOrder
                type="project"
                props={this}
                projectId={this.state.project_id}
                close={this.onClose}
              ></AddOrder>
            )}
          </Card>

          <Card
            className={
              this.state.isTimeLineOpen ? "exportSideBar" : "toggle-sidebar"
            }
          >
            {this.state.isTimeLineOpen && (
              <TimeLine
                from={"project"}
                id={this.state.project_id}
                closeTimelineModal={this.closeTimeline}
              ></TimeLine>
            )}
          </Card>
        </Container>
      </>
    );
  }
}

export default PurchaseOrders;
