import React, { useEffect, useState } from "react";
import "quill/dist/quill.snow.css";
import ReactQuill from "react-quill";

function TextEditor({ ...props }) {
  const [description, setDescription] = useState(null);

  useEffect(() => {
    setDescription(props.desc);
  }, [props.desc]);

  var modules = {
    toolbar: [
      // [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"],
      // [
      //   { list: "ordered" },
      //   { list: "bullet" },
      //   { indent: "-1" },
      //   { indent: "+1" },
      //   { align: [] },
      // ],
      // [
      //   {
      //     color: [
      //       "#000000",
      //       "#e60000",
      //       "#ff9900",
      //       "#ffff00",
      //       "#008a00",
      //       "#0066cc",
      //       "#9933ff",
      //       "#ffffff",
      //       "#facccc",
      //       "#ffebcc",
      //       "#ffffcc",
      //       "#cce8cc",
      //       "#cce0f5",
      //       "#ebd6ff",
      //       "#bbbbbb",
      //       "#f06666",
      //       "#ffc266",
      //       "#ffff66",
      //       "#66b966",
      //       "#66a3e0",
      //       "#c285ff",
      //       "#888888",
      //       "#a10000",
      //       "#b26b00",
      //       "#b2b200",
      //       "#006100",
      //       "#0047b2",
      //       "#6b24b2",
      //       "#444444",
      //       "#5c0000",
      //       "#663d00",
      //       "#666600",
      //       "#003700",
      //       "#002966",
      //       "#3d1466",
      //       "custom-color",
      //     ],
      //   },
      // ],
    ],
  };

  var formats = [
    "header",
    "height",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "color",
    "bullet",
    "indent",
    "link",
    "image",
    "align",
    "size",
  ];

  const handleProcedureContentChange = (value) => {
    let anyChanges = false
    setDescription((val) => {
      if(val !== value) {
        anyChanges = true
        return value
      }
    })
    // setDescription(value);
    anyChanges && props.updateDescription(value);
  };

  return (
    <div>
      {/* {JSON.stringify(Boolean(description.replace(/(<([^>]+)>)/gi, "")))} */}
      <div style={{ display: "grid", position:'relative'}}>
        <ReactQuill
          theme="snow"
          modules={modules}
          formats={formats}
          placeholder={`${props.placeholder}`}
          onChange={handleProcedureContentChange}
          style={{ maxHeight: "650px" }}
          value={description?.replace(/(<([^>]+)>)/gi, "") ? description : " "}
        ></ReactQuill>
      {props.additionalContent && <div onClick={() => props.resetContent()} className="additional-content">{props.additionalContent}</div>}
      </div>
    </div>
  );
}

export default TextEditor;
