import React, { useEffect, useState, useRef, startTransition } from "react";
import {
  DropdownMenu,
  DropdownItem,
  Media,
  UncontrolledDropdown,
  DropdownToggle,
  Dropdown,
  Input,
} from "reactstrap";
import { DatePicker, Select } from "antd";
import { remove_underscore_capitalize } from "constants/utils";
import { getUSFormat, isValidEmail } from "constants/utils";
import dayjs from "dayjs";
import { AutoComplete, DatePickerProps } from "antd";
import moment from "moment";
import customParseFormat from "dayjs/plugin/customParseFormat";

const disabledDate = (current) => {
  return current && current < moment().add(-1, "days");
};

export function CustomDatePicker({
  fromChange,
  toChange,
  from,
  to,
  style,
  inputRef,
  customFormat,
  disabled,
  error,
  className,
  change,
}) {
  const dateFormat = "YYYY-MM-DD";
  const [value, setValue] = useState("");
  useEffect(() => {
  }, [fromChange, toChange]);

  return (
    <div
      className="mr-10"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <div className="left_filter_container" style={{ width: "340px" }}>
        <div className="date-merge w25">Date</div>
        <div className="w40 fromToDate ">
          <DatePicker
            suffixIcon={null}
            placeholder=""
            style={{ style, borderRight: "none" }}
            ref={inputRef}
            format={customFormat}
            disabled={disabled}
            status={error ? "error" : ""}
            className={`${
              className === "without_borders"
                ? "without_borders"
                : className === "paymentDate"
                ? "paymentDate"
                : "customSingleDatePicker"
            }`}
            onChange={(e) => fromChange(e)}
            value={from ? dayjs(from) : undefined}
          />
        </div>
        <div className="todate-merge">to</div>
        <div className="w40 fromToDate">
          <DatePicker
            suffixIcon={null}
            placeholder=""
            style={{
              style,
              borderRadius: "0px 5px 5px 0px",
              borderLeft: "none",
            }}
            format={customFormat}
            disabled={disabled}
            status={error ? "error" : ""}
            className={`${
              className === "without_borders"
                ? "without_borders"
                : "customSingleDatePicker"
            }`}
            onChange={(e) => toChange(e)}
            value={to ? dayjs(to) : undefined}
          />
        </div>
      </div>
    </div>
  );
}

export function CustomSingleDatePicker({
  change,
  error,
  from,
  value,
  style,
  className,
  disabled,
  format,
  setMinMaxDate,
  externalParentDateClass
}) {
  const dateFormat = "MM/DD/YYYY";
  const inputRef = useRef(null);
  const customFormat = (value) => `${value.format(dateFormat)}`;
  useEffect(() => {}, [value]);
  dayjs.extend(customParseFormat);
  return (
    <div className={externalParentDateClass}>
      <DatePicker
        style={style}
        ref={inputRef}
        format={customFormat}
        disabled={disabled ? disabled : false}
        status={error ? "error" : ""}
        className={`${
          className === "without_borders"
            ? "without_borders"
            : "customSingleDatePicker"
        }`}
        onChange={(e) => change(e)}
        value={value ? dayjs(value) : undefined}
        disabledDate={setMinMaxDate}
      />
    </div>
  );
}

export function CustomDropDown({
  inSearch,
  statusList,
  setStatus,
  status,
  width,
  style,
  innerwidth,
  height,
  innerStyle,
  placeHolder,
  inputField = false,
  outerDivClass
}) {
  useEffect(() => {}, [status]);

  function setValue() {
    if (inputField && status) return status;
    else return null;
  }

  return (
    <div
      style={{ width: `${width}`, minHeight: `${height}`, ...style }}
      className={`status_select ${outerDivClass}`}
    >
      <Select
        style={{ ...innerStyle ,height:"100%"}}
        className={
          inputField ? "indivi_customSelect_input_field" : "indivi_customSelect"
        }
        onChange={(e) => setStatus(e)}
        options={statusList?.map((opt) => ({
          value: opt,
          label: remove_underscore_capitalize(opt),
        }))}
        placeholder={placeHolder}
        value={
          (status && inputField) || (status && !inputField)
            ? status
            : inputField
            ? null
            : "All"
        }
      />
    </div>
  );
}

export function CustomSearch({
  options,
  filterOption,
  inputSearch,
  change,
  inputWidth,
  selectWidth,
  from,
  style,
}) {
  const handleChange = (value) => {};

  useEffect(() => {}, [change, inputSearch]);
  return (
    <>
      <div style={{ ...style }} className="customSelectContainer">
        <Select
          className="customSelect"
          style={{ width: 100 }}
          onChange={(e) => change(e, "filterOption")}
          options={options?.map((opt) => ({
            value: opt,
            label: remove_underscore_capitalize(opt),
          }))}
          value={filterOption}
        />
        <Input
          style={{ width: inputWidth }}
          aria-label="Text input with dropdown button"
          className="input-filter-toggle"
          value={inputSearch}
          onChange={(e) => change(e.target.value, "inputSearch")}
        />
      </div>
    </>
  );
}

export function CustomDropDownSearch({
  rightCornerImage,
  error,
  filteredData,
  onChange,
  listClick,
  value,
  placeHolder,
  toSearch,
  anotherSearch,
  style,
  disabled,
  type,
}) {
  const inputRef = useRef(null);
  const [inputError, setInputError] = useState(error);
  const [isValid, setIsValid] = useState(false);
  const [toShowList, setToShowList] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {}, [value, data]);

  useEffect(() => {
    checkforValue();
  }, [error]);

  useEffect(() => {
    setData(filteredData);
  }, [filteredData]);

  const checkforValue = () => {
    setToShowList(true);
    if (inputRef?.current?.value) {
      setIsValid(true);
    } else {
      setIsValid(false);
      error && setInputError(!inputRef?.current?.value);
    }
  };

  const onListClick = (value, object) => {
    setToShowList(false);
    listClick(object.object);
    setData([]);
  };

  return (
    <>
      <div class="input-container">
        <AutoComplete
          options={data?.map((opt, i) => ({
            value: anotherSearch
              ? opt[anotherSearch] + " / " + opt[toSearch]
              : opt[toSearch],
            key: i + 1,
            object: opt,
          }))}
          style={{ ...style, borderRadius: "5px" }}
          onSelect={onListClick}
          onSearch={(text) => onChange(text)}
          placeholder={placeHolder}
          value={value}
          disabled={disabled ? disabled : false}
          type={type}
        />
      </div>
    </>
  );
}

export function CustomInputFields({
  type,
  change,
  placeHolder,
  inputValue,
  error,
  from,
  disabled,
  color,
  style,
  btnLabel,
  onClick,
  divStyle,
}) {
  const [inputError, setInputError] = useState(error);
  const [isValid, setIsValid] = useState(false);
  const [value, setValue] = useState(inputValue || "");
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputValue !== value) {
      setValue(inputValue || "");
    }
  }, [inputValue]);

  useEffect(() => {
    checkforValue();
  }, [error]);

  useEffect(() => {
    if (!disabled) {
      checkforValue();
      change(value, from);
    }
  }, [value]);

  useEffect(() => {}, []);

  function checkforValue() {
    if (from === "email") {
      error && setInputError(!isValidEmail(inputRef?.current?.value));
    } else if (from === "phone") {
      error &&
        setInputError(inputRef.current.value.length !== 16 ? true : false);
    } else {
      if (inputRef?.current?.value) {
        setInputError(false);
      } else {
        setIsValid(false);
        error && setInputError(!inputRef?.current?.value);
      }
    }
  }

  return (
    // <>{btnLabel ?
    <div className={btnLabel && "flex change"} style={{ ...divStyle }}>
      <input
        style={{ width: "100%", color: color, ...style, borderRadius: "5px" }}
        onChange={(e) => setValue(e.target.value)}
        ref={inputRef}
        type={type}
        id={`myInput`}
        // disabled={disabled ?? false}
        disabled={disabled}
        className={`${inputError ? "error" : ""} ${isValid ? "valid" : ""} ${
          btnLabel && "borderNone"
        }`}
        placeholder={placeHolder}
        value={
          from === "phone"
            ? getUSFormat(value) || inputValue
            : value || inputValue
        }
        name={from ? from : ""}
      />
      {btnLabel && (
        <span
          className="col_primary_2 f13 pointer"
          style={{ marginRight: "10px" }}
          onClick={onClick}
        >
          {btnLabel}
        </span>
      )}
    </div>
  );
}

export function CustomTextArea({
  inputvalue,
  change,
  from,
  style,
  placeHolder,
  disabled = false,
  maxLength,
}) {
  const textRef = useRef(null);
  const [value, setValue] = useState(inputvalue || "");

  useEffect(() => {
    // textRef.current.style.height = "28px";
    // textRef.current.style.height = `${textRef.current.scrollHeight}px`;
    change(value, from);
  }, [value]);

  useEffect(() => {
    setValue(inputvalue);
  }, [inputvalue]);

  return (
    <textarea
      rows={3}
      ref={textRef}
      style={style}
      value={value || inputvalue}
      onChange={(e) => setValue(e.target.value)}
      className="customTestArea"
      name={from}
      placeholder={placeHolder ?? ""}
      disabled={disabled}
      maxlength={maxLength}
    ></textarea>
  );
}

export const AmountInput = ({ amount, change, style, disabled = false }) => {
  const [value, setValue] = useState(amount);

  useEffect(() => {
    change(value);
  }, [value]);

  function formatAmountWithCommas(amount) {
    return amount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const handleChange = (e) => {
    const newValue = e.target.value.replace(/[$,]/g, "");
    const formattedValue = newValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    setValue(newValue);
  };

  return (
    <Input
      style={{ ...style, color: "rgba(0, 0, 0, 0.88)" }}
      className="customSingleDatePicker"
      value={"$" + formatAmountWithCommas(amount)}
      onChange={change && handleChange}
      placeholder="Enter amount"
      disabled={disabled}
    />
  );
};
