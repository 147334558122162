import React, { useState, useEffect, useRef, useContext } from "react";
import { getCloudinaryImage } from "constants/utils";
import { redirectLoginPage } from "constants/utils";
import cookie from "react-cookies";
import { Link, useHistory } from "react-router-dom";
import { AddClassToBody } from "constants/utils";
import calenderIcon from "../../assets/img/sideBar_icons/calender_inactive.svg";
import GoogleTranslate from "components/GoogleTranslate/GoogleTranslate";
import { Select } from "antd";
import { Dropdown, Menu } from "antd";
import { Tooltip } from "antd";
import collapseIcon from "../../assets/img/sideBar_icons/collapse_new.svg"
// import calenderIcon from "../../assets/img/icons/common/DateRangeFilled.svg";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media,
} from "reactstrap";
import defaultProfile from "../../assets/img/brand/defaultUser.svg";
import DropdownProfileIcon from "../../assets/img/brand/dropdownProfile.svg";
import DropdownLogoutIcon from "../../assets/img/brand/dropdownLogout.svg";
import languageicon from "../../assets/img/brand/language.svg";
import passwordIcon from "../../assets/img/brand/passwordNavIcon.svg";
import ApiService from "constants/ApiService";
// import GoogleTranslate from "components/GoogleTranslate/GoogleTranslate";
import { PageTitle } from "layouts/Admin";
const { Option } = Select;

const AdminNavbar = (props) => {
  const history = useHistory();
  const lang = cookie.load("googtrans");
  const { title } = useContext(PageTitle);
  const [switch_value, setSwitch] = useState();
  const [contractorName, setContractorName] = useState("");
  const [profileImage, setImageProp] = useState("");
  const [view, setView] = useState(false);

  const menu = (
    <Menu>
      <Menu.Item key="1" className="antdNavitem" onClick={handleItemClick}>
        <Link to="/admin/user-profile" className="align_center">
          <img
            src={DropdownProfileIcon}
            alt="Option 1"
            style={{ width: "17px", marginRight: "8px" }}
          />
          <div>My Profile</div>
        </Link>
      </Menu.Item>
      <Menu.Item key="2" className="antdNavitem" onClick={handleItemClick}>
        <Link to="/admin/password" className="align_center">
          <img
            src={passwordIcon}
            alt="Option 2"
            style={{ width: "17px", marginRight: "8px" }}
          />
          <div>Change Password</div>
        </Link>
      </Menu.Item>
      <Menu.Item key="3" className="antdNavitem" onClick={handleLogout}>
        <Link className="align_center">
          <img
            src={DropdownLogoutIcon}
            alt="Option 2"
            style={{ width: "17px", marginRight: "8px" }}
          />
          <div>Log out</div>
        </Link>
      </Menu.Item>
      {/* Add more menu items as needed */}
    </Menu>
  );

  useEffect(() => {
    getContractorDetails();
    let user = localStorage.getItem("user");
    setSwitch(user);
  }, []);

  // useEffect(() => {
  // },[view])

  function handleLogout() {
    cookie.remove("contractor_token", { path: "/" });
    cookie.remove("host_id");
    localStorage.removeItem("sub_contractor");
    localStorage.removeItem("contractor_details");
    window.location.reload();
  }

  window.addEventListener("storage", (e) => {
    if (e.storageArea === localStorage) {
      let contractor = localStorage.getItem("contractor_details");
      if (contractor === null || contractor === undefined) {
        redirectLoginPage("contractor_details");
      }
    }
  });

  const getContractorDetails = async () => {
    let response = await ApiService.getAPI("contractor");
    if (response.success) {
      let name = response.data?.contractor?.name;
      // setContractorName(name);
      let type = response.data?.contractor?.sub_contractor;
      localStorage.setItem("sub_contractor", type);
      setImageProp(response.data?.contractor?.image);
    }
  };

  const switchChange = (value) => {
    setSwitch(value);
    localStorage.setItem("user", value);
  };

  const changeView = (view_value) => {
    setView(view_value);
    if (view_value === true) {
      setView(view_value);
      props.collapse(view_value);
      localStorage.setItem("screen", "full");
      AddClassToBody();
    } else {
      setView(view_value);
      props.collapse(view_value);
      localStorage.setItem("screen", "half");
      AddClassToBody();
    }
  };

  const calender = () => {
    history.push("/admin/calender");
  };

  const getElement = (e) => {
    e.target.childNodes.forEach((element) => {
      // element.innerHtml = "abc";
      if (element.value === "es") {
        element.textContent = "Español";
      }
    });
  };

  function handleItemClick(value) {
    const { key } = value;
    if (key === 1) {
      history.push("/");
    } else if (key === 2) {
      history.push("/admin/password");
    } else if (key === 3) {
      this.handleLogout();
    }
  }

  return (
    <>
      <Navbar
        // className={
        //   props?.design
        //     ? "navbar-top navbar-dark new-nav-dash"
        //     : "navbar-top navbar-dark"
        // }
        style={{
          padding: "10px 20px",
          boxShadow: "0px 4px 5px 0px #00000003",
          borderBottom: "1px solid #E1E8F0",
          backgroundColor: "white",
        }}
        expand="md"
        id="navbar-main"
      >
        <Container fluid className="container_box">
          <div className="toogle_side_bar" style={{ minWidth: "400px" }}>
            <div>
              <img className={view ? 'collapse_sideBar' : 'expand_sidebar'} onClick={() => changeView(!view)} alt="hamburger" src={collapseIcon} />
            </div>
            <div
              className="col_primary_2"
              style={{ fontWeight: "500", fontSize: "14px", color: "#212121" }}
            >
              {title}
            </div>
          </div>
          <div className="nav_bar_right_side">
            <div id="google_translate_element" className="googDropDown mr-20">
              <img src={languageicon} className="translateIcon" />
              <GoogleTranslate />
            </div>
            <Tooltip title="Calender">
              <img
                src={calenderIcon}
                alt="Calender"
                height="100%"
                onClick={() => calender()}
                style={{ cursor: "pointer" }}
                className="disFl"
              />
            </Tooltip>

            <Nav
              className="align-items-center d-none d-md-flex navbar_options"
            >
              <UncontrolledDropdown nav className="navBar">
                <DropdownToggle className="pr-0" nav>
                  <Dropdown overlay={menu} trigger={["click"]}>
                    {!profileImage ? (
                      <div className="flex profilePicName point">
                        <label
                          htmlFor="imageUpload"
                          style={{ marginBottom: "0px" }}
                          className="mb-remove mr-10"
                        >
                          <img
                            src={defaultProfile}
                            alt="profile image"
                            className="avatar avatar-sm rounded-circle point"
                          />
                        </label>
                        <div className="cl212121 f13 fw-600 ">
                          {contractorName !== "" ? contractorName : ""}
                        </div>
                      </div>
                    ) : (
                      <div className="flex profilePicName point">
                        <label
                          htmlFor="imageUpload"
                          style={{ marginBottom: "0px" }}
                          className="mb-remove mr-10"
                        >
                          {profileImage && (
                            <img
                              src={getCloudinaryImage(
                                100,
                                100,
                                profileImage,
                                "c_fill"
                              )}
                              alt="profile image"
                              className="avatar avatar-sm rounded-circle point"
                            />
                          )}
                        </label>
                        <div className="col_primary_1 f13 fw-600 ">
                          {contractorName !== "" ? contractorName : ""}
                        </div>
                      </div>
                    )}
                  </Dropdown>
                </DropdownToggle>
              </UncontrolledDropdown>
            </Nav>
          </div>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
