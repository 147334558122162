import { Custom_Button } from "components/Buttons/Button";
import {
  capitalizeFirstLetter,
  remove_underscore_capitalize,
} from "../../constants/utils";
import React, { useEffect } from "react";
import { Button_without_Border } from "components/Buttons/Button";

export function CustomListingContainer({ children, style, className }) {
  return (
    <div className={`customListingContainer ${className}`} style={{ ...style }}>
      {children}
    </div>
  );
}

export function ListingHeaderLayout({
  buttonStyle,
  label,
  className,
  mainClass,
  value,
  click,
  primary_buttonLabel,
  children,
  btn_loading,
  image,
  imageClick,
  sec_buttonLabel,
  isPrimary,
  endKey,
  endData,
  style,
  header,
}) {
  useEffect(() => {
  }, []);

  const handleButtonClick = (buttonLabel) => () => {
    click(buttonLabel);
  };

  return (
    <div className={`d_flex_sb_ac listingHeader  ${mainClass}`}>
      <div
        className={`containerListing_name   ${className}`}
        style={{ wordBreak: "break-all" }}
        onClick={imageClick}
      >
        {label ? capitalizeFirstLetter(label) : " "}
        {header ? header[0]?.toUpperCase() + header?.slice(1) : ""}
        {image && (
          <span
            // onClick={imageClick}
            style={{ marginLeft: "20px", cursor: "pointer" }}
          >
            {image}
          </span>
        )}
      </div>
      <div
        style={{
          display: "flex",
          gap: "10%",
          width: "18%",
          justifyContent: "flex-end",
          style,
        }}
      >
        {sec_buttonLabel && isPrimary ? (
          <Custom_Button
            style={buttonStyle}
            btnLabel={sec_buttonLabel}
            click={handleButtonClick(sec_buttonLabel)}
          />
        ) : (
          <Button_without_Border
            style={buttonStyle}
            btnLabel={sec_buttonLabel}
            click={handleButtonClick(sec_buttonLabel)}
          />
        )}

        {endKey && (
          // <Custom_Button
          //   btnLabel={sec_buttonLabel}

          //   click={handleButtonClick(sec_buttonLabel)}
          // />
          <div
            style={{
              padding: "23px 24px 24px 0px",
              fontSize: "16px",
              color: "#757575",
              fontWeight: "500",
              whiteSpace: "nowrap",
            }}
          >
            {`${endKey} :`}
            <span style={{ color: "#303030", whiteSpace: "nowrap" }}>
              {" "}
              {endData}
            </span>
          </div>
        )}
        {value !== 0 && primary_buttonLabel && (
          <Custom_Button
            isPrimary={isPrimary}
            borderColor={"#2e1a47"}
            isLoading={btn_loading}
            btnLabel={primary_buttonLabel}
            click={handleButtonClick(primary_buttonLabel)}
          />
        )}
      </div>
      {children && <div>{children}</div>}
    </div>
  );
}

export function ListingBodyLayout({ children, style, insideStyle }) {
  return (
    <div style={{ ...style }}>
      <div style={{ ...insideStyle }}>{children}</div>
    </div>
  );
}
