import React, { Component } from "react";
import {
  Card,
  Table,
  Container,
  Row,
  Breadcrumb,
  Col,
  Button,
  InputGroup,
  Spinner,
  DropdownMenu,
  DropdownItem,
  Media,
  UncontrolledDropdown,
  DropdownToggle,
} from "reactstrap";
// core components
import ApiService from "constants/ApiService";
import { returnFirstDegreeObjValue } from "constants/utils";
import returnSecondDegreeObjValue from "constants/utils";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getIsoString, filterQuery } from "constants/utils";
import { showUtcDate } from "constants/utils";
import ToastList from "components/Toast/ToastList";
import { showError } from "constants/utils";
import { returnSearchObject } from "constants/utils";
import { pushToFilteredPage } from "constants/utils";
import { buildQueryString } from "constants/utils";
import { getCommissionAmount } from "constants/utils";
import { isSub_Contractor } from "constants/utils";
import { displayPrice } from "constants/utils";
import { Button_without_Border } from "components/Buttons/Button";
import { CustomDatePicker } from "components/FilterFields/Filterfields";
import { CustomDropDown } from "components/FilterFields/Filterfields";
import { CustomListingContainer } from "components/Container/CustomListingContainer";
import { ListingHeaderLayout } from "components/Container/CustomListingContainer";
class Requests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maintenanceList: [],
      total: 0,
      filterToggle: false,
      from: "",
      to: "",
      page: 1,
      inputSearch: "",
      filterOption: "",
      statusSearch: this.props.location?.search ? "pending" : "",
      toast: false,
      response: "",
      toastType: "",
      isLoading: true,
      query: {},
      input_value: false,
    };
  }
  componentDidMount = () => {
    this.setQueryStatus();
  };

  setQueryStatus = () => {
    let queryStatus = this.props.location.search;
    let queryObj = returnSearchObject(queryStatus);
    if (queryStatus) {
      this.setState(
        {
          from: queryObj.from ? new Date(queryObj.from) : "",
          to: queryObj.to ? new Date(queryObj.to) : "",
          statusSearch: queryObj.status ?? "",
          page: parseInt(queryObj.page) ?? "",
          query: returnSearchObject(queryStatus),
        },
        () => {
          Object.keys(queryObj).map((str) => {
            ["from", "to", "status"].includes(str)
              ? this.setState({ input_value: true })
              : this.setState({ input_value: false });
          });
          pushToFilteredPage(this.props, { ...this.state.query });
          this.getMaintenanceList();
        }
      );
    } else {
      this.setState(
        {
          query: {
            ...this.state.query,
            page: 1,
            limit: 10,
          },
        },
        () => {
          this.setState({ input_value: false });
          pushToFilteredPage(this.props, this.state.query);
          this.getMaintenanceList();
        }
      );
    }
  };

  getMaintenanceList = async () => {
    let { page, filterOption, statusSearch, inputSearch, from, to } =
      this.state;
    let queryParams = "";
    if (filterOption !== "" && inputSearch !== "")
      queryParams += filterQuery(filterOption, inputSearch);
    if (statusSearch !== "") {
      queryParams += filterQuery("status", statusSearch);
    }
    if (from !== "") queryParams += filterQuery("from", getIsoString(from));
    if (to !== "") queryParams += filterQuery("to", getIsoString(to));
    if (from > to) {
      showError(this, "Please provide valid From and To date");
    } else {
      let response = await ApiService.getAPI(
        `contractor/maintenance${buildQueryString(this.state.query)}`
      );
      if (response.success) {
        let { total, maintenance } = response.data;
        this.setState({
          contractor: JSON.parse(localStorage.getItem("contractor_details"))
            .contractor,
          maintenanceList: maintenance,
          total: total,
          isLoading: false,
        });
      }
    }
  };
  toggleFilterMenu = () => {
    this.setState({ filterToggle: !this.state.filterToggle });
  };
  onChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  updateStateValues = (key, value) => {
    const updatedValue = value == null ? "" : value;
    this.setState({ [key]: updatedValue }, () => {
      if (this.state.from && this.state.to) {
        this.handleFilterStatus();
      }
    });
  };
  handleOnToChange = (e) => {
    this.setState({ input_value: true }, () => {
      this.updateStateValues("to", e ? new Date(e) : '');
    });
  };
  handleOnFromChange = (e) => {
    this.setState({ input_value: true }, () => {
      this.updateStateValues("from", e ? new Date(e) : '');
    });
  };
  handleIncrementPage = () => {
    if (this.state.maintenanceList.length >= 10) {
      this.setState({ page: this.state.page + 1 }, () =>
        this.handleFilterStatus()
      );
    }
  };
  handleDecrementPage = () => {
    if (this.state.page > 1) {
      this.setState({ page: this.state.page - 1 }, () =>
        this.handleFilterStatus()
      );
    }
  };
  redirectDetailPage = (id) => {
    this.props.history.push(`maintenances/detail/${id}`);
  };
  // Toast Handler

  handleFilterStatus = () => {
    let staticQuery = returnSearchObject(this.props.location.search);
    staticQuery.from = getIsoString(this.state.from) ?? "";
    staticQuery.to = getIsoString(this.state.to) ?? "";

    staticQuery.status = this.state.statusSearch ?? "";
    staticQuery.page = this.state.page ?? "";

    this.setState({ query: staticQuery }, () => {
      pushToFilteredPage(this.props, { ...this.state.query });
      this.getMaintenanceList();
    });
  };

  handleSearch = () => {
    this.setState({ page: 1 }, () => {
      this.handleFilterStatus();
    });
  };
  getSubContractorCommission = (maintenanceData) => {
    let amt;
    let { sub_contractor, main_contractor_amount, service_charge } =
      maintenanceData;
    if (sub_contractor?.id && main_contractor_amount?.type) {
      if (main_contractor_amount?.type === "percentage") {
        amt =
          main_contractor_amount?.amount *
          (main_contractor_amount?.commission / 100);
      } else {
        amt = main_contractor_amount?.commission;
      }
    }

    return amt ? amt : 0;
  };
  handleReset = () => {
    let self = this;
    self.setState(
      {
        from: "",
        to: "",
        statusSearch: "",
        filterOption: "",
        inputSearch: "",
        page: 1,
        limit: 10,
        query: {
          page: 1,
          limit: 10,
        },
        input_value: false,
      },
      () => {
        pushToFilteredPage(this.props, { ...this.state.query });
        this.getMaintenanceList();
      }
    );
  };

  setStatus = (status) => {
    this.setState(
      {
        statusSearch: status === "all" ? "" : status,
        page: 1,
        input_value: status === "all" ? false : true,
      },
      () => {
        this.handleFilterStatus();
      }
    );
  };
  isMainContractor = (maintenanceData) => {
    return (
      maintenanceData?.sub_contractor?.id ===
      this.state.contractor?.contractor_id
    );
  };
  isOutside = (maintenanceData) => {
    return maintenanceData?.assign_type === "outside";
  };
  render() {
    let {
      maintenanceList,
      isLoading,
      page,
      statusSearch,
      toast,
      response,
      toastType,
      input_value,
      from,
      to,
    } = this.state;
    return isLoading ? (
      <div className="spin-loading">
        <Spinner type="border" color="#077e8b" />
      </div>
    ) : (
      <>
        {toast && <ToastList message={response} type={toastType} />}
        <div style={{ padding: "20px", backgroundColor: "#fafafa" }}>
          {" "}
          <CustomListingContainer>
            <ListingHeaderLayout label={"maintenance"}></ListingHeaderLayout>
            <div style={{ margin: "24px" }}>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "35% 65%",
                }}
              >
                <div
                  style={{ display: "grid", gridTemplateColumns: "70% 45%" }}
                >
                  <CustomDatePicker
                    from={from}
                    to={to}
                    fromChange={this.handleOnFromChange}
                    toChange={this.handleOnToChange}
                  ></CustomDatePicker>
                  <CustomDropDown
                    width={"126px"}
                    setStatus={this.setStatus}
                    status={statusSearch}
                    statusList={[
                      "all",
                      "assigned",
                      "accepted",
                      "Scheduled",
                      "Rescheduled",
                      "in_progress",
                      "completed",
                      "cancelled",
                    ]}
                  ></CustomDropDown>
                </div>
                {input_value && (statusSearch || (from || to)) && (
                  <div className="right_filter_container">
                    <Button_without_Border
                      click={this.handleReset}
                      btnLabel={"clear_filter"}
                    ></Button_without_Border>
                  </div>
                )}
              </div>
            </div>
            <Row>
              <div className="col new-table">
                <Card className="shadow card_with_paginator">
                  {maintenanceList && maintenanceList.length !== 0 ? (
                    <Table
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">Property</th>
                          <th scope="col">Service</th>
                          <th scope="col">Scheduled On</th>
                          <th scope="col">Amount</th>
                          <th style={{ width: "10%" }} scope="col">
                            Status
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {maintenanceList.map((maintenance, i) => (
                          <tr key={i}>
                            <td
                              className="cursor-point capitalize"
                              onClick={() =>
                                this.redirectDetailPage(maintenance.history_id)
                              }
                            >
                              {" "}
                              {returnSecondDegreeObjValue(
                                maintenance,
                                "property",
                                "name"
                              )}
                            </td>
                            <td className="text-capitalize">
                              {returnFirstDegreeObjValue(
                                maintenance,
                                "service_name"
                              )}
                            </td>
                            <td className="text-capitalize">
                              {showUtcDate(maintenance.service_date)}
                            </td>
                            <td>
                              {!maintenance.main_contractor_amount ||
                                Object.keys(maintenance.main_contractor_amount)
                                  .length === 0
                                ? displayPrice(
                                  returnFirstDegreeObjValue(
                                    maintenance,
                                    "service_charge"
                                  ) === "-"
                                    ? maintenance?.main_contractor_amount
                                      ?.amount
                                    : returnFirstDegreeObjValue(
                                      maintenance,
                                      "service_charge"
                                    )
                                )
                                : isSub_Contractor() ||
                                  (this.isMainContractor(maintenance) &&
                                    this.isOutside(maintenance))
                                  ? maintenance &&
                                  displayPrice(
                                    parseFloat(
                                      maintenance?.main_contractor_amount
                                        ?.amount
                                    ) -
                                    parseFloat(
                                      this.getSubContractorCommission(
                                        maintenance
                                      )
                                    )
                                  )
                                  : displayPrice(
                                    this.getSubContractorCommission(maintenance)
                                  )}
                            </td>

                            <td
                              className={
                                maintenance.status === "accepted" ||
                                  maintenance.status === "assigned"
                                  ? "text-capitalize status_accepted"
                                  : maintenance.status === "cancelled" ||
                                    maintenance.status === "rejected"
                                    ? "text-capitalize red"
                                    : maintenance.status === "in_progress" ||
                                      maintenance.status === "pending" ||
                                      maintenance.status === "scheduled" ||
                                      maintenance.status === "rescheduled"
                                      ? "text-capitalize yellow"
                                      : maintenance.status === "completed"
                                        ? "text-capitalize status_completed"
                                        : maintenance.status === "closed"
                                          ? "text-capitalize primary"
                                          : "text-capitalize"
                              }
                              style={{ fontSize: "13px", fontWeight: "500" }}
                            >
                              {returnFirstDegreeObjValue(
                                maintenance,
                                "status"
                              ) === "in_progress"
                                ? "In Progress"
                                : returnFirstDegreeObjValue(
                                  maintenance,
                                  "status"
                                )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    <>
                      {" "}
                      <Table
                        className="align-items-center table-flush"
                        responsive
                      >
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">Property</th>
                            <th scope="col">Service</th>
                            <th scope="col">Scheduled On</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Status</th>
                          </tr>
                        </thead>
                      </Table>
                      <Card className="card_with_paginator no_data_card">
                        {" "}
                        <div className="no-data">No Maintenance Available</div>
                      </Card>
                    </>
                  )}
                </Card>
              </div>
            </Row>
            <Row className="align-items-center header-div jus-space new-margin-page paginator_box">
              <div className="paginate-flex-box ">
                <div
                  className="paginate-box"
                  onClick={() => this.handleDecrementPage()}
                >
                  <p
                    className={
                      page !== 1 ? "page_available" : "page_unavailable"
                    }
                  >
                    Previous
                  </p>
                </div>
                <div
                  className="paginate-box"
                  onClick={() => this.handleIncrementPage()}
                >
                  <p
                    className={
                      maintenanceList?.length < 10
                        ? "page_unavailable"
                        : "page_available"
                    }
                  >
                    Next
                  </p>
                </div>
              </div>
            </Row>
          </CustomListingContainer>
        </div>
      </>
    );
  }
}

export default Requests;
