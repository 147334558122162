import React, { Component } from "react";
import {
  Card,
  Table,
  Container,
  Row,
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Button,
  InputGroup,
  InputGroupText,
  Spinner,
  DropdownMenu,
  DropdownItem,
  Media,
  UncontrolledDropdown,
  DropdownToggle,
  Modal,
  Input,
} from "reactstrap";
import ToastList from "components/Toast/ToastList";
import pksLogo from "../../assets/img/brand/pkslogo.svg";
import shortLogo from "../../assets/img/new Logo/faviconN.svg"
import moment from "moment";
import sendChat from "../../assets/img/brand/send_chat.svg";
import Lottie from "react-lottie";
import NoData from "../../assets/Lotties/noData.json";

export default class Comments extends Component {
  constructor(props) {
    super(props);
    this.textareaRef = React.createRef();
    this.state = {
      initPageLoading: false,
      toast: false,
      response: "",
      toastType: "",
      comments: [
        {
          content: "hi",
          date: new Date(),
        },
        {
          content: "hi",
          date: new Date(),
        },
        {
          content: "hi",
          date: new Date(),
        },
        {
          content: "hi",
          date: new Date(),
        },
        {
          content: "hi",
          date: new Date(),
        },
        {
          content: "hi",
          date: new Date(),
        },
        {
          content: "hi",
          date: new Date(),
        },
        {
          content: "hi",
          date: new Date(),
        },
        {
          content: "hi",
          date: new Date(),
        },
      ],
      project: {},
    };
  }

  componentDidMount() {
    this.getProjectDetails();
  }

  update = (e) => {
    const textarea = this.textareaRef.current;
    textarea.style.height = "28px";
    textarea.style.height = `${textarea.scrollHeight}px`;
    this.setState({
      inputMessage: e.target.value,
    });
  };

  handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey && !e.ctrlKey) {
      e.preventDefault();
    } else if (e.key === "Enter" && (e.shiftKey || e.ctrlKey) && !e.repeat) {
      this.insertLineBreak();
      e.preventDefault();
    }
    e.key === "Enter" &&
      !e.repeat &&
      !e.shiftKey &&
      !e.ctrlKey &&
      this.sendMessage();
    this.date = [];
  };

  sendMessage = () => {
    const textarea = this.textareaRef.current;
    textarea.style.height = "28px";
  };

  insertLineBreak() {
    const textarea = this.textareaRef.current;
    const { selectionStart, selectionEnd, value } = textarea;
    const newValue =
      value.substring(0, selectionStart) + "\n" + value.substring(selectionEnd);
    textarea.value = newValue;
    textarea.setSelectionRange(selectionStart + 1, selectionStart + 1);
    textarea.style.height = `${textarea.scrollHeight}px`;
  }

  getProjectDetails = async () => {
    // let [response, taskList] = await Promise.all([
    //   ApiService.getAPI(`contractor/project/${project_id}`),
    //   ApiService.getAPI(`contractor/task/inside?project_id=${project_id}`),
    // ]);
    // if (!response.success) return this.setState({ isLoading: false });
    // let data = response.data.project;
    // this.setState(
    //   {
    //     project: data,
    //     final_project: data,
    //     tasks: taskList?.data?.tasks?.slice(0, 5) || [],
    //   },
    //   () => {
    //     this.setState({ isLoading: false });
    //   }
    // );
  };

  render() {
    const NodataFound = {
      loop: true,
      autoplay: true,
      animationData: NoData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    let { initPageLoading, toast, response, toastType, comments } = this.state;
    return initPageLoading ? (
      <div className="spin-loading">
        <Spinner type="border" color="#077e8b" />
      </div>
    ) : (
      <Container className="mt-8 card-pad-wrapper" fluid>
        {toast && <ToastList message={response} type={toastType} />}
        <div>
          <Breadcrumb></Breadcrumb>
        </div>
        <Row className="align-items-center header-div">
          <Col xs="" className="manage-con">
            <Breadcrumb>
              <BreadcrumbItem
                onClick={() => this.props.history.push("/admin/projects")}
                className="bread-cursor"
              >
                Project
              </BreadcrumbItem>
              <BreadcrumbItem
                className="bread-cursor"
                onClick={() => this.props.history.goBack()}
                active
              >
                Details
              </BreadcrumbItem>
              <BreadcrumbItem active style={{ color: "#72569c" }}>Comments</BreadcrumbItem>
            </Breadcrumb>
            {/* <Breadcrumb className="">Project Detail</Breadcrumb> */}
          </Col>
          <Col
              xs="4"
              className="manage-con flexy-content flexEnd"
              style={{ gap: "5%" }}
            >
              <div className="status_select ml-10" style={{ width: "29%" }}>
                <UncontrolledDropdown className="status_select_div">
                  <DropdownToggle className="pr-0 drop_down_container">
                    <Media className="capitalize">
                      Comments
                      <i className="fas fa-angle-down"></i>
                    </Media>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-arrow" bottom>
                    {" "}
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() =>
                        this.props.history.push(
                          "/admin/projects/detail/UVSkm0ZUMY"
                        )
                      }
                    >
                      Overview
                    </DropdownItem>{" "}
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() => {this.props.history.push("/admin/tasks_lists")}}
                    >
                      Tasks
                    </DropdownItem>{" "}
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() => this.props.history.push("/admin/payments")}
                    >
                      Payments
                    </DropdownItem>
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() =>
                        this.props.history.push("/admin/purchase_orders")
                      }
                      // onClick={() => this.setPage("clients")}
                    >
                      Purchase Orders
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => this.props.history.push("/admin/comments")}
                      className="dropdown_item"
                      // onClick={() => this.setPage("proposal")}
                    >
                      Comments
                    </DropdownItem>
                    <DropdownItem
                      onClick={() =>
                        this.props.history.push("/admin/documents")
                      }
                      className="dropdown_item"
                      // onClick={() => this.setPage("projects")}
                    >
                      Documents
                    </DropdownItem>
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() => this.props.history.push("/admin/photos")}
                      // onClick={() => this.setPage("clients")}
                    >
                      Photos
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
              {/* <h3 className="mb-0 managers-crumb"></h3> */}
            </Col>
        </Row>
        <div className="crm_comments_module">
          <>
            <div
              className="bread-cursor"
              style={{ padding: "1%", fontWeight: "500" }}
            >
              Comments
            </div>
            <div>
              <div
                className="crm_comments"
                style={{ height: "500px", marginTop: "0px" }}
              >
                {comments.length ? (
                  comments.map((obj) => (
                    <div className="crm_individual_comments_module">
                      <div>
                        <img
                          // width="50px"
                          // height="50px"
                          src={
                            // project?.image?.length > 0
                            //   ? getCloudinaryImage(
                            //       100,
                            //       100,
                            //       project.image[0],
                            //       "c_fill"
                            //     )
                            //   :
                            shortLogo
                          }
                          alt="logo"
                        ></img>
                      </div>
                      <div className="crm_comment_content">
                        <div className="crm_comment_date">
                          {moment(obj.date).startOf("day").format("DD MMMM")}
                        </div>
                        <div className="crm_comment_date">{obj.content}</div>
                      </div>
                    </div>
                  ))
                ) : (
                  <>
                    <div className="no_data_dashboard">
                      <Lottie options={NodataFound} height={100} width={100} />
                      <div className="mt_20">
                        <p>No Comments Found</p>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="crm_comments_input">
                <div>
                  <img
                    // width="40px"
                    // height="40px"
                    src={
                      // project?.image?.length > 0
                      //   ? getCloudinaryImage(100, 100, project.image[0], "c_fill")
                      //   :
                      shortLogo
                    }
                    alt="logo"
                  ></img>
                </div>
                <div className=" comment_footer">
                  <div className="ft_1">
                    <textarea
                      ref={this.textareaRef}
                      style={{ height: this.state.textAreaHeight }}
                      onInput={this.update}
                      value={this.state.inputMessage}
                      onKeyDown={this.handleKeyDown}
                      placeholder="Type here..."
                    />
                    <div className="ft_img">
                      {this.state.messageSendloader ? (
                        // <Spinner color="#012a4d">Loading...</Spinner>
                        <div className="image-loading spinner-cut">
                          <Spinner
                            style={{ width: "30px", height: "30px" }}
                            type="border"
                            color="#012A4D"
                          />
                        </div>
                      ) : (
                        <img
                          style={{ cursor: "pointer" }}
                          onClick={this.sendMessage}
                          src={sendChat}
                        ></img>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      </Container>
    );
  }
}
