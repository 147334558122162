import React, { Component } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Table,
  Spinner,
  Row,
  Col,
  Button,
  Input,
} from "reactstrap";
import ToastList from "components/Toast/ToastList";
import { showError, showSuccess } from "../../constants/utils";
import ApiService from "constants/ApiService";

class CancelModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reason: "",
      toast: false,
      toastType: "",
      response: "",
      btnLoad: false,
    };
  }

  handleInputChange = (e) => {
    this.setState({ reason: e.target.value });
  };
  confirmCancel = async () => {
    let { reason } = this.state;
    if (reason === "") {
      showError(this, "Please enter valid reason");
    } else {
      this.setState({ btnLoad: true });
      let { id } = this.props;
      let data = {
        cancel_reason: reason,
      };
      let response = await ApiService.patchAPI(
        `contractor/open_request/cancel/${id}`,
        data
      );
      if (response.success) {
        this.setState({ btnLoad: false });

        this.props.close(response.message);
      } else {
        this.setState({ btnLoad: false });

        showError(this, response.message);
      }
    }
  };

  render() {
    let { reason, toast, response, toastType, btnLoad } = this.state;
    return (
      <div>
        {toast && <ToastList message={response} type={toastType} />}

        <Card>
          <CardBody className="pad25">
            <div className="mb-30 flex">
              <div className=" cl262626 fw-600 f16">Cancel Request</div>
              <div
                className="col_primary_2 closeModal fw-500 point"
                onClick={() => this.props.close()}
              >
                x
              </div>
            </div>
            <label className="mb-10 cl555555 f14 fw-400" htmlFor="reason">
              Please mention the reason for cancelling the request *
            </label>
            <Input
              type="textarea"
              onChange={this.handleInputChange}
              value={reason}
              placeholder="Reason"
              className="mb-30"
              rows={4}
              maxLength={50}
            />

            <div className="flexEnd">
              <Button
                color="primary"
                size="lg"
                className="button-wrapping search-wrapper  button-secondary ml-4 cancelbtn"
                onClick={() => this.props.close()}
              >
                No
              </Button>
              <Button
                color="primary"
                size="lg"
                className="button-wrapping search-wrapper checkoutSubmit"
                onClick={() => this.confirmCancel()}
              >
                {btnLoad ? (
                  <div className="spin-loading-save w100 h100">
                    <Spinner type="border" color="#0A8080" />
                  </div>
                ) : (
                  "Yes"
                )}
              </Button>
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default CancelModal;
