import { Component } from "react";
import {
  Card,
  Table,
  Container,
  Row,
  Breadcrumb,
  Col,
  Button,
  InputGroup,
  Spinner,
  BreadcrumbItem,
  DropdownMenu,
  DropdownItem,
  Media,
  UncontrolledDropdown,
  DropdownToggle,
  Dropdown,
  Progress,
  Input,
} from "reactstrap";
import {
    calculatePercentageNumber
  } from "constants/utils";
import moment from "moment";
import showMore from "../../assets/img/brand/showMore.svg";
import { showUtcDate } from "constants/utils";
export default class projectChangeOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initPageLoading: true,
      filterOption: "",
      inputSearch: "",
      payment_lists: [],
      statusSearch: this.props.location?.search ? "pending" : "",
    };
  }

  getPayments = () => {
    this.setState({ initPageLoading: false });
  };

  componentDidMount() {
    this.getPayments();
  }

  render() {
    let {
      initPageLoading,
      filterOption,
      inputSearch,
      statusSearch,
      payment_lists,
    } = this.state;

    return initPageLoading ? (
      <>
        <div className="spin-loading mt-8">
          <Spinner type="border" color="#077e8b" />
        </div>
      </>
    ) : (
      <Container className="mt-8 card-pad-wrapper" fluid>
        <div>
          <Breadcrumb></Breadcrumb>
        </div>
        <Row className="align-items-center header-div">
          <Col xs="8" className="manage-con">
            <Breadcrumb>
              <BreadcrumbItem
                onClick={() => this.props.history.push("project_overview")}
                className="bread-cursor"
              >
                Project
              </BreadcrumbItem>
              <BreadcrumbItem active style={{ color: "#72569c" }}>Change Orders</BreadcrumbItem>
            </Breadcrumb>
            {/* <Breadcrumb className="">Project Detail</Breadcrumb> */}
          </Col>
          <Col
              xs="4"
              className="manage-con flexy-content flexEnd"
              style={{ gap: "5%" }}
            >
              <div className="status_select ml-10" style={{ width: "32%" }}>
                <UncontrolledDropdown className="status_select_div">
                  <DropdownToggle className="pr-0 drop_down_container">
                    <Media className="capitalize">
                      Change Orders
                      <i className="fas fa-angle-down"></i>
                    </Media>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-arrow" bottom>
                    {" "}
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() => this.props.history.push("/admin/projects/detail/UVSkm0ZUMY")}
                    >
                       Overview
                    </DropdownItem>{" "}
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() => this.props.history.push("/admin/tasks_lists")}
                    >
                      Tasks
                    </DropdownItem>{" "}
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() => this.props.history.push("/admin/payments")}
                    >
                      Payments
                    </DropdownItem>
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() => this.props.history.push("/admin/changeOrders")}
                    >
                      Change Orders
                    </DropdownItem>{" "}
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() => this.props.history.push("/admin/purchase_orders")}
                      // onClick={() => this.setPage("clients")}
                    >
                      Purchase Orders
                    </DropdownItem>
                
                    <DropdownItem
                      onClick={() => this.props.history.push("/admin/comments")}
                      className="dropdown_item"
                      // onClick={() => this.setPage("proposal")}
                    >
                      Comments
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => this.props.history.push("/admin/documents")}
                      className="dropdown_item"
                      // onClick={() => this.setPage("projects")}
                    >
                      Documents
                    </DropdownItem>
                    <DropdownItem
                      className="dropdown_item"
                      onClick={() => this.props.history.push("/admin/photos")}
                      // onClick={() => this.setPage("clients")}
                    >
                      Photos
                    </DropdownItem>
                 
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
              <div>
                <Button
                  color="primary"
                  size="sm"
                  className="button-wrapping"
                  // onClick={() => this.redirectToAddRequest()}
                >
                  Add Change Order
                </Button>
              </div>
              {/* <h3 className="mb-0 managers-crumb"></h3> */}
            </Col>
        </Row>
        <div>
          <InputGroup className="mb-5 mb_mt_28 flex-content align-left">
            <div className="filters_container">
              <div className="left_filter_container">
                {" "}
                <div className="select_with_input mr-10">
                  <Dropdown variant="input-group" className="select_input_drop">
                    <select
                      id="inputGroupSelect01"
                      className="form_select fixed_select"
                      name="filterOption"
                      value={filterOption}
                      onChange={this.onChangeHandler}
                    >
                      <option value="">Search</option>

                      <option value="name">Name</option>
                    </select>
                    <i className="fas fa-angle-down"></i>
                  </Dropdown>
                  <Input
                    aria-label="Text input with dropdown button"
                    className="input-filter-toggle"
                    value={inputSearch}
                    name="inputSearch"
                    onChange={this.onChangeHandler}
                  />
                </div>
                <div className="status_select ml-10">
                  <UncontrolledDropdown className="status_select_div">
                    <DropdownToggle className="pr-0 drop_down_container">
                      <Media className="capitalize">
                        {statusSearch === "in_progress"
                          ? "In Progress"
                          : statusSearch === ""
                          ? "All"
                          : statusSearch}

                        <i className="fas fa-angle-down"></i>
                      </Media>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-arrow" bottom>
                      {" "}
                      <DropdownItem
                        className="dropdown_item"
                        onClick={() => this.setStatus("")}
                      >
                        All
                      </DropdownItem>{" "}
                      <DropdownItem
                        className="dropdown_item"
                        onClick={() => this.setStatus("accepted")}
                      >
                        Accepted
                      </DropdownItem>
                      <DropdownItem
                        className="dropdown_item"
                        onClick={() => this.setStatus("pending")}
                      >
                        Pending
                      </DropdownItem>
                      <DropdownItem
                        className="dropdown_item"
                        onClick={() => this.setStatus("in_progress")}
                      >
                        Inprogress
                      </DropdownItem>
                      <DropdownItem
                        className="dropdown_item"
                        onClick={() => this.setStatus("completed")}
                      >
                        Completed
                      </DropdownItem>
                      <DropdownItem
                        className="dropdown_item"
                        onClick={() => this.setStatus("cancelled")}
                      >
                        Cancelled
                      </DropdownItem>
                      <DropdownItem
                        className="dropdown_item"
                        onClick={() => this.setStatus("rejected")}
                      >
                        Rejected
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              </div>
              <div className="right_filter_container">
                <Button
                  color="primary"
                  onClick={this.handleReset}
                  size="sm"
                  className="button-wrapping search-wrapper content-flex mr-4 button-secondary ml-4 reset-btn"
                >
                  Reset
                </Button>
                <Button
                  color="primary"
                  onClick={() => this.handleSearch()}
                  size="sm"
                  className="button-wrapping search-wrapper ml-0"
                >
                  Search
                </Button>
              </div>
            </div>
          </InputGroup>
          <Row>
            <div className="col new-table">
              <Card className="shadow card_with_paginator">
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th
                        style={{ width: "15%" }}
                        scope="col"
                        className="pad20"
                      >
                        Title
                      </th>
                      <th style={{ width: "15%" }} scope="col">
                        Requested On
                      </th>
                      <th style={{ width: "15%" }} scope="col">
                        Due On
                      </th>
                      <th style={{ width: "15%" }} scope="col">
                        Amount
                      </th>
                      <th style={{ width: "15%" }} scope="col">
                        Status
                      </th>
                    </tr>
                  </thead>
                  {payment_lists && payment_lists.length !== 0 ? (
                    <tbody>
                      {payment_lists.map((project, i) => (
                        <tr key={i}>
                          <td
                            className="cursor-point capitalize pad20"
                            onClick={() =>
                              this.redirectDetailPage(project.project_id)
                            }
                            style={{ width: "100px" }}
                          >
                            <div className="project_list_content">
                              {this.capitalizeFirstLetter(project.name)}
                            </div>
                          </td>
                          <td>
                            <div className="project_list_content">
                              {" "}
                              {this.capitalizeFirstLetter(project.client.name)}
                            </div>
                          </td>
                          {/* 
                          <td>
                            <div className="project_list_content">
                              {" "}
                              {moment(project.start_date).format(
                                "DD MMM, YYYY"
                              )}
                            </div>
                          </td> */}
                          {/* <td>
                            <div className="project_list_content">
                              {" "}
                              {moment(project.actual_start).format(
                                "DD MMM, YYYY"
                              )}
                            </div>
                          </td> */}

                          <td>
                            <div className="project_list_content">
                              {" "}
                              {showUtcDate(project.end_date)}
                            </div>
                          </td>
                          <td
                            className={
                              project?.invoice?.status === "paid" ||
                              project?.invoice?.status === "assigned"
                                ? "text-capitalize status_accepted general_status_style"
                                : project?.invoice?.status === "cancelled" ||
                                  project?.invoice?.status === "rejected"
                                ? "text-capitalize red general_status_style"
                                : project?.invoice?.status === "created" ||
                                  project?.invoice?.status === "pending"
                                ? "text-capitalize yellow general_status_style"
                                : project?.invoice?.status === "completed"
                                ? "text-capitalize status_completed general_status_style"
                                : project?.invoice?.status === "closed"
                                ? "text-capitalize primary general_status_style"
                                : "text-capitalize"
                            }
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "4%",
                              }}
                            >
                              {" "}
                              <div>
                                {" "}
                                <Progress
                                  color="#0A8080"
                                  value={
                                    calculatePercentageNumber(
                                      project.completed_tasks,
                                      project.total_tasks
                                    ) ?? 0
                                  }
                                />
                              </div>
                              <div className="project_list_percentage">
                                {calculatePercentageNumber(
                                  project.completed_tasks,
                                  project.total_tasks
                                ) ?? 0}{" "}
                                %
                              </div>
                            </div>
                          </td>

                          <td>
                            <div
                              className="project_list_status"
                              style={{
                                color: `${
                                  project.status === "Completed"
                                    ? "#07A84E"
                                    : project.status === "Pending"
                                    ? "FF4949"
                                    : "FFCC3D"
                                }`,
                              }}
                            >
                              {" "}
                              {this.capitalizeFirstLetter(project.status)}
                            </div>
                          </td>

                          <td>
                            <div className="">
                              <UncontrolledDropdown
                                nav
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <DropdownToggle className="pr-0 nav-notice" nav>
                                  <img src={showMore} alt="show more" />
                                </DropdownToggle>
                                <DropdownMenu
                                  className="dropdown-menu-arrow min-width-drop"
                                  right
                                >
                                  <DropdownItem
                                    onClick={() => this.editProject(project)}
                                  >
                                    <span>Edit</span>
                                  </DropdownItem>

                                  <DropdownItem
                                    onClick={() =>
                                      this.setState({
                                        openModal: true,
                                        delete_data: project,
                                        project_id: project.project_id,
                                      })
                                    }
                                  >
                                    <span>Delete</span>
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td className="no-data">No Change Orders Available</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  )}
                </Table>
              </Card>
            </div>
          </Row>
        </div>
      </Container>
    );
  }
}
