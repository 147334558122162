import { useState, useEffect } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { Button } from "reactstrap";
import ApiService from "constants/ApiService";
import ReactTooltip from "react-tooltip";
import infoIcon from "../../assets/img/brand/infoIcon.svg";
import calenderIcon from "../../assets/img/brand/google-calendarIcon.svg";

const GoogleEvent = (props) => {
  const login = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      let data = {
        code: codeResponse.code,
      };
      let response = await ApiService.postAPI(`contractor/google/sync`, data);
      if (response.success) {
        props.success(response.message);
      } else {
        props.error(response.message);
      }
    },
    flow: "auth-code",
    scope: "https://www.googleapis.com/auth/calendar.events",
  });

  return (
    <div onClick={() => login()} className=" flexStart point">
      <img
        src={calenderIcon}
        className="mr-10"
        data-tip="Click to sync your Google Calendar Events with Parknstays"
        data-iscapture="true"
        data-for="syncInfo"
        data-place="left"
      />
      <ReactTooltip
        place="left"
        type="info"
        effect="solid"
        id="syncInfo"
        html={true}
      />
      <div className="f14 fw-500 col_primary_2">Sync with Google Calendar</div>
    </div>
  );
};

export default GoogleEvent;
