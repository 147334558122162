import React, { Component } from "react";
import {
  Card,
  Table,
  Container,
  Row,
  Breadcrumb,
  Col,
  Button,
  Spinner,
  DropdownMenu,
  DropdownItem,
  Media,
  UncontrolledDropdown,
  DropdownToggle,
  InputGroup,
  Dropdown,
  Modal,
  Input,
} from "reactstrap";
import AddProject from "views/Project/addProject";
import ApiService from "constants/ApiService";
import "react-datepicker/dist/react-datepicker.css";
import projectTimeline from "../../../src/assets/img/brand/projectTimeline.svg";
import { getIsoString, filterQuery } from "constants/utils";

import ToastList from "components/Toast/ToastList";

import { showError } from "constants/utils";
import { returnSearchObject } from "constants/utils";
import { pushToFilteredPage } from "constants/utils";
import { buildQueryString } from "constants/utils";
import moment from "moment";

import showMore from "../../assets/img/brand/showMore.svg";
import { showSuccess } from "constants/utils";
import { BreadcrumbItem } from "reactstrap";

import { uploadCloudinary } from "constants/utils";
import searchIcon from "../../assets/img/brand/searchIcon.svg";
// import DeleteModal from "./DeleteModal";
import AddLead from "./addLead";
import TimeLine from "components/Timeline/Timeline";
import { CustomListingContainer } from "components/Container/CustomListingContainer";
import { ListingHeaderLayout } from "components/Container/CustomListingContainer";
import { CustomDropDown } from "components/FilterFields/Filterfields";
import { ListingBodyLayout } from "components/Container/CustomListingContainer";
import { CustomSearch } from "components/FilterFields/Filterfields";
import { Button_without_Border } from "components/Buttons/Button";
import { showUtcDate } from "constants/utils";

let overlap = document.querySelector(":root");

class Proposal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requestList: [],
      estimate_list: [],
      lead: "",
      total: 0,
      filterToggle: false,
      from: "",
      projectModuleOpen: false,
      to: "",
      page: 1,
      open: false,
      client_id: "",
      inputSearch: "",
      filterOption: "",
      status: this.props.location?.search ? "pending" : "",
      toast: false,
      response: "",
      image: [],
      iamge_name: "",
      toastType: "",
      isLoading: true,
      clientsList: [],
      btnLoad: false,
      query: {},
      name: "",
      client: {},
      start_date: "",
      actual_start: "",
      imageLoading: false,
      client_name: "",
      inputText: "",
      isTimeLineOpen: false,
      timeline_estimate_id: "",
      estimate_id: "",
      items: [],
      desc: "",
      tags: [],
      end_date: "",
      requestId: "",
      openModal: false,
      delete_data: {},
      cancelModal: false,
      project_id: "",
      all_projects: 0,
      active_projects: 0,
      completed_projects: 0,
      cancel_data: {},
      all_tasks: 0,
      cancelModal: false,
      input_value: false,
      timeoutId: null,
    };
  }
  componentDidMount = async () => {
    overlap.style.setProperty("--width", "600px");
    this.setQueryStatus();
  };

  componentWillUnmount = () => {
    clearTimeout(this.state.timeoutId);
  };

  onSuccessAddProject = () => {
    this.setState({ projectModuleOpen: false }, () => {
      this.props.tabChange("projects", "all");
    });
  };

  componentWillUnmount() {
    overlap.style.setProperty("--width", "450px");
  }

  closeModal = () => {
    this.setState({ openModal: false, project_id: "" });
    this.getProjectList();
  };
  handleDelete = async () => {
    let response = await ApiService.patchAPI(
      `contractor/project/delete/${this.state.delete_data.project_id}`,
      {}
    );
    if (response.success) {
      showSuccess(this, response.message);
      this.setState({ delete_data: {} });
      this.closeModal();
      this.getProjectList();
    } else {
      showError(this, response.message);
    }
  };

  setQueryStatus = () => {
    let queryStatus = this.props.location?.search;
    if (queryStatus) {
      let queryObj = returnSearchObject(queryStatus);
      this.setState(
        {
          from: queryObj.from ? new Date(queryObj.from) : "",
          to: queryObj.to ? new Date(queryObj.to) : "",
          status: queryObj.status ?? "",
          page: parseInt(queryObj.page) ?? "",
          query: returnSearchObject(queryStatus),
        },
        () => {
          this.setState({ input_value: true });
          // pushToFilteredPage(this.props.props, { ...this.state.query });
          this.getProjectList();
        }
      );
    } else {
      this.setState(
        {
          query: {
            ...this.state.query,
            page: 1,
            limit: 10,
          },
        },
        () => {
          // pushToFilteredPage(this.props.props, this.state.query);
          this.getProjectList();
        }
      );
    }
  };

  redirectDetailPage = (id) => {
    this.props.history.push(`add_estimates?estimate_id=${id}`);
  };

  setPage = (page) => {
    this.props.history.push(page);
  };
  dateChangeHandler = (e, value) => {
    this.setState({ [value]: e });
  };

  editProject = async (data) => {
    this.setState(
      {
        project_id: data.project_id,
      },
      () => {
        this.setState({ open: true });
      }
    );
  };
  getRequestsList = async () => {
    let { filterOption, status, inputSearch, from, to } = this.state;
    let queryParams = "";
    if (filterOption !== "" && inputSearch !== "")
      queryParams += filterQuery(filterOption, inputSearch);
    if (status !== "") {
      queryParams += filterQuery("status", status);
    }
    if (from !== "") queryParams += filterQuery("from", getIsoString(from));
    if (to !== "") queryParams += filterQuery("to", getIsoString(to));
    if (from > to) {
      showError(this, "Please provide valid From and To date");
    } else {
      let response = await ApiService.getAPI(
        `contractor/open_request${buildQueryString(this.state.query)}`
      );

      if (response.success) {
        let { open_request, total } = response.data;
        this.setState({
          requestList: open_request,
          total: total,
          isLoading: false,
        });
      }
    }
  };

  getProjectList = async () => {
    let { filterOption, status, inputSearch, from, to, query } = this.state;
    const queryObj = { ...query };
    if (filterOption && inputSearch) queryObj[filterOption === 'client' ? 'name' : filterOption] = inputSearch;
    let response = await ApiService.getAPI(
      `contractor/estimate${buildQueryString(queryObj)}`
    );

    if (response.success) {
      this.setState({
        isLoading: false,
        estimate_list: response.data.estimates,
      });
    }
  };
  createProjectHandler = async () => {
    let {
      name,
      client_name,
      start_date,
      end_date,
      actual_start,
      client_id,
      desc,
      project_id,
      edit,
      tags,
    } = this.state;
    if (!name || !client_name || !start_date || !end_date || !actual_start)
      return showError(this, "Please fill all the required fields");
    this.setState({ btnLoad: true });
    let data = {
      name: name,
      client: {
        id: client_id,
        name: client_name,
      },
      start_date: start_date,
      end_date: end_date,
      actual_start: actual_start,
      desc: desc,
      tags: tags,
    };
    let response = edit
      ? await ApiService.patchAPI(`contractor/project/${project_id}`, data)
      : await ApiService.postAPI("contractor/project", data);
    if (response.success) {
      showSuccess(this, response.message);
      this.setState({
        btnLoad: false,
        open: false,
        name: "",
        start_date: "",
        end_date: "",
        actual_start: "",
        desc: "",
        tags: [],
        client_name: "",
        client_id: "",
      });
      this.getProjectList();
    } else {
      this.setState({ btnLoad: false });
      showError(this, response.message);
    }
  };

  handleTagsChange = (tags) => {
    this.setState({ tags: tags });
  };
  handleInputChange = (event) => {
    this.setState({ inputText: event.target.value });
  };

  handleEnterKey = (event) => {
    if (event.key === "Enter" && this.state.inputText.trim() !== "") {
      this.setState((prevState) => ({
        inputText: "",
        items: [...prevState.items, prevState.inputText],
      }));
    }
  };
  toggleFilterMenu = () => {
    this.setState({ filterToggle: !this.state.filterToggle });
  };

  onChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value, input_value: true });
    clearTimeout(this.state.timeoutId);
    const timeoutId = setTimeout(() => {
      this.state.filterOption && this.handleSearch();
    }, 1000);
    this.setState({ timeoutId });
  };

  updateStateValues = (key, value) => {
    this.setState(
      {
        [key]: value,
      },
      function () {
        if (value === null) {
          this.setState({
            [key]: "",
          });
        }
      }
    );
  };
  handleOnToChange = (e) => {
    this.updateStateValues("to", e ? new Date(e) : '');
  };
  handleOnFromChange = (e) => {
    this.updateStateValues("from", e ? new Date(e) : '');
  };
  handleIncrementPage = () => {
    if (this.state.estimate_list.length >= 10) {
      this.setState(
        {
          page: this.state.page + 1,
          query: {
            ...this.state.query,
            page: this.state.page + 1,
            limit: 10,
          },
        },
        () => this.getProjectList()
      );
    }
  };
  handleDecrementPage = () => {
    if (this.state.page > 1) {
      this.setState(
        {
          page: this.state.page - 1,
          query: {
            ...this.state.query,
            page: this.state.page - 1,
            limit: 10,
          },
        },
        () => this.getProjectList()
      );
    }
  };
  capitalizeFirstLetter = (str) => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  imageHandler = async (e, subStatus) => {
    let { image } = this.state;
    let file = e.target.files[0];

    if (e.target.files.length > 0) {
      this.setState({ imageLoading: true });
    }

    if (
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/jpeg" ||
      file.type === "application/pdf"
    ) {
      if (file.size < 1000000) {
        let data = await uploadCloudinary(file, "host");
        if (data.url) {
          let imgArr = [...image];
          let imageObj = {
            format: data.format,
            public_id: data.public_id,
            version: `v${data.version}`,
            type: "image",
          };
          imgArr.push(imageObj);
          this.setState({
            image: imgArr,
            imageLoading: false,
          });
        } else {
          showError(this, "Could not upload image");
          this.setState({ imageLoading: false });
        }
      } else {
        showError(this, "File size should not exceeds 1 MB");
        this.setState({ imageLoading: false });
      }
    } else {
      showError(this, "Invalid Format");
      this.setState({ imageLoading: false });
    }
  };

  deleteImage = () => {
    this.setState({ image: [], image_name: "" });
  };

  closeTimeline = () => {
    this.setState({ isTimeLineOpen: false });
  };

  addProject = (list) => {
    this.setState({
      projectModuleOpen: true,
      lead: list.lead,
      estimate_id: list.estimate_id,
    });
  };

  handleFilterStatus = () => {
    let staticQuery = returnSearchObject(this.props.location.search);
    staticQuery.from = getIsoString(this.state.from) ?? "";
    staticQuery.to = getIsoString(this.state.to) ?? "";
    staticQuery.status = this.state.status ?? "";
    staticQuery.page = this.state.page ?? "";
    if (this.state.inputSearch !== "" && this.state.filterOption !== "") {
      staticQuery[this.state.filterOption] = this.state.inputSearch;
    } else staticQuery[this.state.filterOption] = "";
    this.setState({ query: staticQuery }, () => {
      pushToFilteredPage(this.props, { ...this.state.query });
      this.getProjectList();
    });
  };

  handleSearch = () => {
    if (this.state.filterOption) {
      this.setState({ query: { ...this.state.query, page: 1 } }, () =>
        this.getProjectList()
      );
    }
  };

  handleReset = () => {
    let self = this;
    self.setState(
      {
        from: "",
        to: "",
        status: "",
        filterOption: "",
        inputSearch: "",
        page: 1,
        limit: 10,
        query: {
          page: 1,
          limit: 10,
        },
        input_value: false,

      },
      () => {
        // pushToFilteredPage(this.props.props, { ...this.state.query });
        this.getProjectList();
      }
    );
  };

  setStatus = (status) => {
    this.setState(
      {
        status: status,
        page: 1,
        query: {
          ...this.state.query,
          page: 1,
          limit: 10,
          status: status,
        },
      },
      () => {
        // this.handleFilterStatus();
        this.getProjectList();
      }
    );
  };

  cancelModal = (estimate) => {
    this.setState({
      cancelModal: true,
      cancel_data: { id: estimate.estimate_id, name: estimate.name },
    });
  };

  cancelEstimate = async () => {
    let { id } = this.state.cancel_data;
    let response = await ApiService.patchAPI(
      `contractor/estimate/${id}/cancelled`
    );
    if (response.success) {
      showSuccess(this, response.message);
      this.setState({ cancelModal: false });
      this.getProjectList();
    } else {
      showError(this, response.message);
    }
  };
  resendEstimate = async (estimate) => {
    let data = { lead: estimate.lead };
    let response = await ApiService.postAPI(
      `contractor/estimate/resend/${estimate.estimate_id}`,
      data
    );
    if (response.success) {
      showSuccess(this, response.message);
      this.getProjectList();
    } else {
      showError(this, response.message);
    }
  };
  redirectToAddRequest = async () => {
    let response = await ApiService.getAPI(`contractor/client?status=active`);

    if (response.success) {
      let { clients } = response.data;
      this.setState({
        clientsList: clients,
        edit: false,
        open: true,
      });
    }
  };

  onClose = () => {
    this.setState({ open: false, project_id: "", projectModuleOpen: false });
    this.getProjectList();
  };
  
  Showtoast = (type, message) => {
    this.setState({
      toast: true,
      toastType: type,
      response: message,
    });
    this.clearToast();
  }
  clearToast = () => {
    setTimeout(
      () => this.setState({ toast: false, toastType: "", response: "" }),
      2000
    );
  }
  cancelRequest = async (request) => {
    this.setState({ cancelModal: true, requestId: request.open_request_id });
  };

  resendRequest = async (request) => {
    let response = await ApiService.patchAPI(
      `contractor/open_request/resend/${request.open_request_id}`
    );
    if (response.success) {
      showSuccess(this, response.message);
      this.getProjectList();
    } else {
      showError(this, response.message);
    }
  };

  proposalEditPage = (estimate) => {
    // this.props.props.props.history.push({
    //   pathname: `/admin/add_estimates?estimate_id=${estimate.estimate_id}`,
    //   state: {
    //     from: "estimates",
    //   },
    // });
    this.props.history.push(
      //   {
      //   pathname: `/admin/add_estimates?estimate_id=${estimate.estimate_id}`,
      // }
      `/admin/add_estimates?estimate_id=${estimate.estimate_id}`
    );
  };



  resendInvoice = async (request) => {
    let response = await ApiService.patchAPI(
      `contractor/open_request/invoice/${request.open_request_id}`
    );
    if (response.success) {
      showSuccess(this, response.message);
      this.getProjectList();
    } else {
      showError(this, response.message);
    }
  };

  render() {
    let {
      requestList,
      isLoading,
      project_id,
      page,
      status,
      toast,
      response,
      toastType,
      clientsList,
      projectModuleOpen,
      estimate_list,
      lead,
      name,
      imageLoading,
      start_date,
      actual_start,
      desc,
      end_date,
      tags,
      client_name,
      estimate_id,
      btnLoad,
      inputSearch,
      filterOption,
      image,
      openModal,
      delete_data,
      open,
      edit,
      all_projects,
      active_projects,
      completed_projects,
      statusSearch,
      all_tasks,
      timeoutId,
      input_value,
    } = this.state;
    return isLoading ? (
      <div className="spin-loading">
        <Spinner type="border" color="#077e8b" />
      </div>
    ) : (
      <>
        {toast && <ToastList message={response} type={toastType} />}
        <div>

        </div>
        <CustomListingContainer>
          <ListingHeaderLayout label={"Estimates"}></ListingHeaderLayout>
          <ListingBodyLayout>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "35% 65%",
                marginBottom: "1.8rem",
              }}
            >
              <div style={{ display: "grid", gridTemplateColumns: "65% 45%" }}>
                <CustomSearch
                  selectWidth={'90%'}
                  inputwidth={"200px"}
                  options={["client", "id", "subject"]}
                  change={this.onChangeHandler}
                  filterOption={filterOption || "Select"}
                  inputSearch={inputSearch}
                />
                <CustomDropDown
                  width={"126px"}
                  statusList={[
                    "all",
                    "created",
                    "accepted",
                    "rejected",
                    "cancelled",
                  ]}
                  status={status}
                  setStatus={this.setStatus}
                />
              </div>
              {input_value && (
                <div className="right_filter_container">
                  <Button_without_Border
                    click={this.handleReset}
                    btnLabel={"clear_filter"}
                  ></Button_without_Border>
                </div>
              )}
            </div>
          </ListingBodyLayout>
          <Row>
            <div className="col new-table">
              <Card className="shadow card_with_paginator">
                <Table className="align-items-center table-flush" responsive>
                  <thead
                    className="thead-light"
                    style={{ borderBottom: "1px solid #f5f5f5" }}
                  >
                    <tr>
                      <th scope="col" className="">
                        Id
                      </th>
                      <th scope="col" className="pad20 w_20">
                        Client
                      </th>
                      <th scope="col w_20">Subject</th>
                      <th scope="col w_20">Sent On</th>
                      <th scope="col w_20">Total</th>
                      <th scope="col w_20">Status</th>
                      <th scope="col w_8"></th>
                      <th scope="col" style={{ width: "0px" }}></th>
                    </tr>
                  </thead>
                  {estimate_list && estimate_list.length !== 0 ? (
                    <tbody>
                      {estimate_list.map((estimate, i) => (
                        <tr key={i}>
                          <td>
                            <div onClick={() => this.getDetailPage(estimate.estimate_id)} className="add_eclipse cursor-point">
                              {estimate.estimate_id}
                            </div>
                          </td>
                          <td
                            className="capitalize pad20"
                          // style={{
                          //   cursor:
                          //     estimate.status !== "cancelled"
                          //       ? "default"
                          //       : "default",
                          // }}
                          // onClick={
                          //   estimate.status !== "cancelled"
                          //     ? () =>
                          //         this.redirectDetailPage(
                          //           estimate.estimate_id
                          //         )
                          //     : undefined
                          // }
                          >
                            <div className="add_eclipse">
                              {this.capitalizeFirstLetter(estimate.lead?.name)}
                            </div>
                          </td>
                          <td>
                            <div className="add_eclipse">
                              {estimate.subject}
                            </div>
                          </td>

                          <td>
                            <div className="add_eclipse">
                              {" "}
                              {showUtcDate(estimate.createdAt)}
                            </div>
                          </td>

                          <td>
                            <div className="add_eclipse">
                              {estimate.totalAmount ? `$${estimate.totalAmount}` : '-'}
                            </div>
                          </td>

                          <td>
                            <div
                              className="add_eclipse"
                              style={{
                                color: `${estimate.status === "completed" ||
                                    estimate.status === "accepted" ||
                                    estimate.status === "closed"
                                    ? "#07A84E"
                                    : estimate.status === "discontinued" ||
                                      estimate.status === "cancelled"
                                      ? "#f75f18"
                                      : estimate.status === "due"
                                        ? "#0070FD"
                                        : "#FFCC3D"
                                  }`, fontWeight: "500"
                              }}
                            >
                              {" "}
                              {this.capitalizeFirstLetter(
                                estimate.status === "on_hold"
                                  ? "On Hold"
                                  : estimate.status
                              )}
                            </div>
                          </td>

                          <td>
                            {estimate.status === "created" ? (
                              <div
                                style={{
                                  border: "1px solid #c4c4c4",
                                  textAlign: "center",
                                  padding: "10px",
                                  borderRadius: "3px",
                                  color: "#338180",
                                  fontWeight: "500",
                                  cursor: "pointer",
                                }}
                                onClick={() => this.addProject(estimate)}
                              >
                                Create Project
                              </div>
                            ) : (
                              <div></div>
                            )}
                          </td>

                          <td>
                            {/* {estimate.status !== "cancelled" && ( */}
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end",
                                borderTop: "none",
                                gap: "20px",
                              }}
                            >
                              <div>
                                <img
                                  onClick={() =>
                                    this.setState({
                                      timeline_estimate_id:
                                        estimate.estimate_id,
                                      isTimeLineOpen: true,
                                    })
                                  }
                                  alt="timeline"
                                  style={{ cursor: "pointer" }}
                                  src={projectTimeline}
                                ></img>
                              </div>

                              <div
                                style={{
                                  visibility:
                                    estimate.status === "cancelled"
                                      ? "hidden"
                                      : "visible",
                                }}
                              >
                                <UncontrolledDropdown
                                  nav
                                  className="flexy-content position_u"
                                >
                                  <DropdownToggle
                                    className="pr-0 nav-notice"
                                    nav
                                  >
                                    <Media className="align-items-center">
                                      <img src={showMore} alt="show more" />
                                    </Media>
                                  </DropdownToggle>
                                  <DropdownMenu
                                    className="dropdown-menu-arrow min-width-drop"
                                    right
                                  >
                                    <DropdownItem
                                      onClick={() =>
                                        this.proposalEditPage(estimate)
                                      }
                                    >
                                      <span>Edit</span>
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={() =>
                                        this.resendEstimate(estimate)
                                      }
                                    >
                                      <span>Resend</span>
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={() =>
                                        // this.cancelEstimate(
                                        //   estimate.estimate_id
                                        // )
                                        this.cancelModal(estimate)
                                      }
                                    >
                                      <span>Cancel</span>
                                    </DropdownItem>

                                    {/* 
                                <DropdownItem
                                  onClick={() =>
                                    this.setState({
                                      openModal: true,
                                      delete_data: project,
                                      project_id: project.project_id,
                                    })
                                  }
                                >
                                  <span>Delete</span>
                                </DropdownItem> */}
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </div>
                            </div>
                            {/* )} */}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td className="no-data">No Estimates Available</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  )}
                </Table>
              </Card>
            </div>
          </Row>
          <Row className="align-items-center header-div jus-space new-margin-page paginator_box">
            <div className="paginate-flex-box ">
              <div
                className="paginate-box"
                onClick={() => this.handleDecrementPage()}
              >
                <p
                  className={page !== 1 ? "page_available" : "page_unavailable"}
                >
                  Previous
                </p>
              </div>
              <div
                className="paginate-box"
                onClick={() => this.handleIncrementPage()}
              >
                <p
                  className={
                    estimate_list.length < 10
                      ? "page_unavailable"
                      : "page_available"
                  }
                >
                  Next
                </p>
              </div>
            </div>
          </Row>
        </CustomListingContainer>
        <Container className="pad24p" fluid>

          <Card
            className={this.state.open ? "exportSideBar" : "toggle-sidebar"}
          >
            {this.state.open && (
              <AddLead
                type="lead"
                props={this}
                projectId={this.state.project_id}
                close={this.onClose}
                toast={this.Showtoast}
              ></AddLead>
            )}
          </Card>
          <Card
            className={
              this.state.isTimeLineOpen ? "exportSideBar" : "toggle-sidebar"
            }
          >
            {this.state.isTimeLineOpen && (
              <TimeLine
                id={this.state.timeline_estimate_id}
                closeTimelineModal={this.closeTimeline}
                from={"estimate"}
              ></TimeLine>
            )}
          </Card>

          <Modal
            isOpen={this.state.cancelModal}
            centered="true"
            style={{ minWidth: "25rem", padding: "2rem" }}
          >
            <div style={{ padding: "24px" }}>
              <div className="conCanMod">Cancel</div>
              <div style={{ textAlign: "center", wordBreak: "break-word" }}>
                Are you sure want to cancel {this.state.cancel_data.name} ?
              </div>
              <div
                className="flexEnd"
                style={{
                  marginTop: "16px",
                  justifyContent: "center",
                  gap: "5%",
                }}
              >
                <div
                  className="point f14 button-secondary"
                  style={{ fontWeight: "500" }}
                  disabled={this.state.isApiCallPending}
                >
                  {btnLoad ? (
                    <div className="spin-loading-save w100 h100">
                      <Spinner
                        style={{
                          height: "20px",
                          width: "20px",
                        }}
                        type="border"
                        color="#0A8080"
                      />
                    </div>
                  ) : (
                    <div onClick={this.cancelEstimate}>Yes</div>
                  )}
                </div>

                <Button
                  color="primary"
                  className="button-wrapping "
                  size="sm"
                  onClick={() => this.setState({ cancelModal: false })}
                >
                  No
                </Button>
              </div>
            </div>
          </Modal>
        </Container>
        <Card
          className={projectModuleOpen ? "exportSideBar" : "toggle-sidebar"}
        >
          {projectModuleOpen && Object.keys(lead).length && (
            <AddProject
              type="estimate"
              props={this}
              estimateId={estimate_id}
              lead={lead}
              close={() => this.onSuccessAddProject()}
              cancel={() => {
                this.setState({ projectModuleOpen: false });
              }}
            />
          )}
        </Card>
      </>
    );
  }
}

export default Proposal;
