import React, { Component } from "react";
import {
  Badge,
  Card,
  Table,
  Container,
  Row,
  Breadcrumb,
  Col,
  Button,
  InputGroup,
  InputGroupText,
  CardBody,
  Spinner,
  DropdownMenu,
  DropdownItem,
  Media,
  UncontrolledDropdown,
  DropdownToggle,
  Modal,
} from "reactstrap";
// core components
import ApiService from "constants/ApiService";
import { returnFirstDegreeObjValue } from "constants/utils";
import returnSecondDegreeObjValue from "constants/utils";
import { returnThirdDegreeObjValue } from "constants/utils";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import DateSvg from "../../assets/img/brand/date-pick.svg";
import { getIsoString, filterQuery } from "constants/utils";
import { getMonthFilter } from "constants/utils";
import { getFinalMonthFilter } from "constants/utils";
import { showUtcDate } from "constants/utils";
import ToastList from "components/Toast/ToastList";
import TotalInvoice from "../../../src/assets/img/brand/total_invoice.svg";
import completedInvoice from "../../../src/assets/img/brand/completedInvoice.svg";
import acceptedInvoice from "../../../src/assets/img/brand/acceptedInvoice.svg";
import totalEarnedInvoice from "../../../src/assets/img/brand/totalEarnedInvoice.svg";
import { showError } from "constants/utils";
import { returnSearchObject } from "constants/utils";
import { pushToFilteredPage } from "constants/utils";
import { buildQueryString } from "constants/utils";
import moment from "moment";
import { displayPrice } from "constants/utils";
import { returnCustomerFirsLastName } from "constants/utils";
import showMore from "../../assets/img/brand/showMore.svg";
import { showSuccess } from "constants/utils";
import CancelModal from "./CancelModal";
import { Button_without_Border } from "components/Buttons/Button";
import { CustomDatePicker } from "components/FilterFields/Filterfields";
import { CustomDropDown } from "components/FilterFields/Filterfields";
import { CustomListingContainer } from "components/Container/CustomListingContainer";
import { ListingHeaderLayout } from "components/Container/CustomListingContainer";
const color = {
  "paid": "#0a8080",
  "created": "#ffc043",
  "pending": "#ffc043",
  "completed": "#2cb67d",
  "cancelled": "#f83245"
}
class OpenRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requestList: [],
      total: 0,
      filterToggle: false,
      from: "",
      to: "",
      page: 1,
      inputSearch: "",
      filterOption: "",
      statusSearch: this.props.location?.search ? "pending" : "",
      toast: false,
      response: "",
      toastType: "",
      isLoading: true,
      query: {},
      requestId: "",
      cancelModal: false,
      input_value: false,
    };
  }

  componentDidMount = async () => {
    this.setQueryStatus();
  };

  setQueryStatus = () => {
    let queryStatus = this.props.location.search;
    if (queryStatus) {
      let queryObj = returnSearchObject(queryStatus);
      this.setState(
        {
          from: queryObj.from ? new Date(queryObj.from) : "",
          to: queryObj.to ? new Date(queryObj.to) : "",
          statusSearch: queryObj.status ?? "",
          page: parseInt(queryObj.page) ?? "",
          query: returnSearchObject(queryStatus),
        },
        () => {
          (this.state.fom || this.state.to || this.state.statusSearch) && this.setState({ input_value: true });
          pushToFilteredPage(this.props, { ...this.state.query });
          this.getRequestsList();
        }
      );
    } else {
      this.setState(
        {
          query: {
            ...this.state.query,
            page: 1,
            limit: 10,
          },
        },
        () => {
          pushToFilteredPage(this.props, this.state.query);
          this.getRequestsList();
        }
      );
    }
  };

  getRequestsList = async () => {
    let { page, filterOption, statusSearch, inputSearch, from, to } =
      this.state;
    let queryParams = "";
    if (filterOption !== "" && inputSearch !== "")
      queryParams += filterQuery(filterOption, inputSearch);
    if (statusSearch !== "") {
      queryParams += filterQuery("status", statusSearch);
    }
    if (from !== "") queryParams += filterQuery("from", getIsoString(from));
    if (to !== "") queryParams += filterQuery("to", getIsoString(to));
    if (from > to) {
      showError(this, "Please provide valid From and To date");
    } else {
      let response = await ApiService.getAPI(
        `contractor/open_request${buildQueryString(this.state.query)}`
      );
      if (response.success) {
        let { open_request, total } = response.data;
        this.setState({
          requestList: open_request,
          total: total,
          isLoading: false,
        });
      }
    }
  };

  toggleFilterMenu = () => {
    this.setState({ filterToggle: !this.state.filterToggle });
  };

  onChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  updateStateValues = (key, value) => {
    const updatedValue = value == null ? "" : value;
    this.setState({ [key]: updatedValue }, () => {
      if (this.state.from && this.state.to) {
        this.handleFilterStatus();
      }
    });
  };

  handleOnToChange = (e) => {
    this.setState({ input_value: true }, () => {
      this.updateStateValues("to", e ? new Date(e) : "");
    });
  };

  handleOnFromChange = (e) => {
    this.setState({ input_value: true }, () => {
      this.updateStateValues("from", e ? new Date(e) : "");
    });
  };
  handleIncrementPage = () => {
    if (this.state.requestList.length >= 10) {
      this.setState({ page: this.state.page + 1 }, () =>
        this.handleFilterStatus()
      );
    }
  };
  handleDecrementPage = () => {
    if (this.state.page > 1) {
      this.setState({ page: this.state.page - 1 }, () =>
        this.handleFilterStatus()
      );
    }
  };
  redirectDetailPage = (id) => {
    this.props.history.push(`requests/detail/${id}`);
  };
  // Toast Handler

  handleFilterStatus = () => {
    let staticQuery = returnSearchObject(this.props.location.search);
    staticQuery.from = getIsoString(this.state.from) ?? "";
    staticQuery.to = getIsoString(this.state.to) ?? "";

    staticQuery.status = this.state.statusSearch ?? "";
    staticQuery.page = this.state.page ?? "";
    this.setState({ query: staticQuery }, () => {
      pushToFilteredPage(this.props, { ...this.state.query });
      this.getRequestsList();
    });
  };

  handleSearch = () => {
    this.setState({ page: 1 }, () => {
      this.handleFilterStatus();
    });
  };

  handleReset = () => {
    let self = this;
    self.setState(
      {
        from: "",
        to: "",
        statusSearch: "",
        filterOption: "",
        inputSearch: "",
        page: 1,
        limit: 10,
        query: {
          page: 1,
          limit: 10,
        },
        input_value: false,
      },
      () => {
        pushToFilteredPage(this.props, { ...this.state.query });
        this.getRequestsList();
      }
    );
  };

  setStatus = (status) => {
    this.setState({ statusSearch: status === "all" ? "" : status, page: 1 }, () => {
      !this.state.statusSearch ? this.setState({ input_value: false }) : this.setState({ input_value: true })
      this.handleFilterStatus();
    });
  };

  redirectToAddRequest = () => {
    this.props.history.push(`/admin/request/add`);
  };
  editRequest = (data) => {
    this.props.history.push(`/admin/request/update/${data?.open_request_id}`);
  };
  cancelRequest = async (request) => {
    this.setState({ cancelModal: true, requestId: request.open_request_id });
  };

  resendRequest = async (request) => {
    let response = await ApiService.patchAPI(
      `contractor/open_request/resend/${request.open_request_id}`
    );
    if (response.success) {
      showSuccess(this, response.message);
      this.getRequestsList();
    } else {
      showError(this, response.message);
    }
  };
  resendInvoice = async (request) => {
    let response = await ApiService.patchAPI(
      `contractor/open_request/invoice/${request.open_request_id}`
    );
    if (response.success) {
      showSuccess(this, response.message);
      this.getRequestsList();
    } else {
      showError(this, response.message);
    }
  };

  closeModal = (msg) => {
    this.setState({ cancelModal: false });
    if (msg) {
      showSuccess(this, msg);
      this.getRequestsList();
    }
  };

  render() {
    let {
      requestList,
      isLoading,
      page,
      statusSearch,
      toast,
      response,
      toastType,
      cancelModal,
      requestId,
      input_value,
      from,
      to,
    } = this.state;
    return isLoading ? (
      <div className="spin-loading">
        <Spinner type="border" color="#077e8b" />
      </div>
    ) : (
      <div>
        {toast && <ToastList message={response} type={toastType} />}
        <div style={{ padding: "20px", backgroundColor: '#fafafa' }}>
          <CustomListingContainer>
            <ListingHeaderLayout isPrimary primary_buttonLabel={'add_request'} label={'Open Requests'} click={this.redirectToAddRequest}/>
            <div style={{ margin: '24px' }}>
              <div
                // className="d_flex_sb"
                style={{
                  display: "grid",
                  gridTemplateColumns: '35% 65%',
                }}
              >
                <div style={{ display: 'grid', gridTemplateColumns: '70% 45%' }}>
                  <CustomDatePicker
                    from={from}
                    to={to}
                    fromChange={this.handleOnFromChange}
                    toChange={this.handleOnToChange}
                  ></CustomDatePicker>
                  <CustomDropDown
                    width={'126px'}
                    setStatus={this.setStatus}
                    status={statusSearch}
                    statusList={[
                      "all",
                      "accepted",
                      "pending",
                      "cancelled",
                      "rejected",
                    ]}
                  ></CustomDropDown>
                </div>
                {input_value &&( statusSearch || (from || to) )&& (
                  <div className="right_filter_container">
                    <Button_without_Border
                      click={this.handleReset}
                      btnLabel={"clear_filter"}
                    ></Button_without_Border>
                  </div>
                )}
              </div>
            </div>

            <Row>
              <div className="col new-table">
                <Card className="shadow card_with_paginator">
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">
                          Property
                        </th>
                        <th scope="col">Request ID</th>
                        <th scope="col">Created On</th>
                        <th scope="col">Scheduled On</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Invoice Status</th>
                        <th scope="col">Status</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    {requestList && requestList.length !== 0 ? (
                      <tbody>
                        {requestList.map((request, i) => (
                          <tr key={i}>
                            <td
                              className="cursor-point capitalize"
                              onClick={() =>
                                request.status === "accepted" &&
                                this.props.history.push(
                                  `/admin/requests/detail/${request?.request_id}`
                                )
                              }
                              style={{ width: "100px" }}
                            >
                              <div className="add_eclipse">
                                {returnSecondDegreeObjValue(
                                  request,
                                  "property",
                                  "name"
                                )}
                              </div>
                            </td>
                            <td className="text-capitalize">
                              {returnFirstDegreeObjValue(request, "request_id")}
                            </td>

                            <td className="text-capitalize">
                              {showUtcDate(request?.createdAt)}
                            </td>
                            <td className="text-capitalize">
                              {showUtcDate(request?.schedule_date)}
                            </td>

                            <td>
                              {request.status === "completed"
                                ? displayPrice(
                                  returnFirstDegreeObjValue(request, "amount")
                                )
                                : displayPrice(
                                  returnFirstDegreeObjValue(
                                    request,
                                    "approximate_amount"
                                  )
                                )}
                            </td>
                            <td
                              className={
                                request?.invoice?.status === "paid" ||
                                  request?.invoice?.status === "assigned"
                                  ? "text-capitalize status_accepted "
                                  : request?.invoice?.status === "cancelled" ||
                                    request?.invoice?.status === "rejected"
                                    ? "text-capitalize red "
                                    : request?.invoice?.status === "created" ||
                                      request?.invoice?.status === "pending"
                                      ? "text-capitalize yellow"
                                      : request?.invoice?.status === "completed"
                                        ? "text-capitalize status_completed "
                                        : request?.invoice?.status === "closed"
                                          ? "text-capitalize primary "
                                          : "text-capitalize"
                              }
                              // className="text-capitalize"
                              style={{ fontSize: '13px', fontWeight: '500' }}
                            >
                              {returnSecondDegreeObjValue(
                                request,
                                "invoice",
                                "status"
                              )}
                            </td>

                            <td
                              className={
                                request.status === "accepted" ||
                                  request.status === "assigned"
                                  ? "text-capitalize status_accepted "
                                  : request.status === "cancelled" ||
                                    request.status === "rejected"
                                    ? "text-capitalize red "
                                    : request.status === "in_progress" ||
                                      request.status === "pending"
                                      ? "text-capitalize yellow "
                                      : request.status === "completed"
                                        ? "text-capitalize status_completed "
                                        : request.status === "closed"
                                          ? "text-capitalize primary "
                                          : "text-capitalize"
                              }
                              style={{ fontSize: '13px', fontWeight: '500' }}

                            // className="text-capitalize"
                            // style={{color:`${color[request?.invoice?.status]}`}}
                            >
                              {returnFirstDegreeObjValue(request, "status") ===
                                "in_progress"
                                ? "In Progress"
                                : returnFirstDegreeObjValue(request, "status")}
                            </td>
                            <td style={{ padding: "10px" }}>
                              <div className="flexmid">
                                {(request.status === "pending" ||
                                  (request.status === "completed" &&
                                    request?.invoice?.status === "created")) && (
                                    <UncontrolledDropdown nav>
                                      <DropdownToggle
                                        className="pr-0 nav-notice"
                                        nav
                                      >
                                        <Media className="align-items-center">
                                          <img src={showMore} />
                                        </Media>
                                      </DropdownToggle>
                                      <DropdownMenu
                                        className="dropdown-menu-arrow min-width-drop"
                                        bottom
                                      >
                                        {request.status === "pending" && (
                                          <>
                                            <DropdownItem
                                              className="dropdown_item"
                                              onClick={() =>
                                                this.editRequest(request)
                                              }
                                            >
                                              Edit
                                            </DropdownItem>{" "}
                                            <DropdownItem
                                              className="dropdown_item"
                                              onClick={() =>
                                                this.resendRequest(request)
                                              }
                                            >
                                              Resend Request
                                            </DropdownItem>
                                            <DropdownItem
                                              className="dropdown_item"
                                              onClick={() =>
                                                this.cancelRequest(request)
                                              }
                                            >
                                              Cancel Request
                                              <Modal
                                                isOpen={cancelModal}
                                                toggle={() => this.closeModal()}
                                                centered
                                              >
                                                <CancelModal
                                                  id={requestId}
                                                  close={this.closeModal}
                                                />
                                              </Modal>
                                            </DropdownItem>
                                          </>
                                        )}
                                        {request.status === "completed" &&
                                          request?.invoice?.status ===
                                          "created" && (
                                            <DropdownItem
                                              onClick={() =>
                                                this.resendInvoice(request)
                                              }
                                            >
                                              Resend Invoice
                                            </DropdownItem>
                                          )}
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                  )}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td className="no-data">No Request Available</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                    )}
                  </Table>
                </Card>
              </div>
            </Row>
            <Row className="align-items-center header-div jus-space new-margin-page paginator_box">
              <div className="paginate-flex-box ">
                <div
                  className="paginate-box"
                  onClick={() => this.handleDecrementPage()}
                >
                  <p
                    className={page !== 1 ? "page_available" : "page_unavailable"}
                  >
                    Previous
                  </p>
                </div>
                <div
                  className="paginate-box"
                  onClick={() => this.handleIncrementPage()}
                >
                  <p
                    className={
                      requestList.length < 10
                        ? "page_unavailable"
                        : "page_available"
                    }
                  >
                    Next
                  </p>
                </div>
              </div>
            </Row>
          </CustomListingContainer>
        </div>

      </div>
    );
  }
}

export default OpenRequest;
