import React, { Component } from "react";
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Breadcrumb,
  Col,
  Button,
  InputGroup,
  Spinner,
  DropdownMenu,
  DropdownItem,
  Media,
  UncontrolledDropdown,
  DropdownToggle,
  Input,
  CardBody,
} from "reactstrap";
import { showSuccess, showError } from "constants/utils";

import ControllableProgressBar from "components/Progress/ControllableProgressbar";
import ApiService from "constants/ApiService";
import closeIcon from "../../assets/img/icons/white_close_icon.svg";
import { Button_without_Border } from "components/Buttons/Button";
import { Custom_Button } from "components/Buttons/Button";

class UpdateTask extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      btnLoad: false,
      progress: "",
      notes: "",
    };
  }

  closeModal = (boolean) => {
    this.props.closeUpdateModal(boolean);
  };

  updateProgress = (value) => {
    this.setState({ progress: value });
  };

  componentDidMount = () => {
    this.getTaskDetail();
  };

  getTaskDetail = async () => {
    let response = await ApiService.getAPI(
      `contractor/task/${this.props.taskId}`
    );
    this.setState({ isLoading: false });
    if (response.success) {
      let task = response.data.task;
      this.setState({ progress: task.progress, notes: task.notes });
    }
  };

  createProjectHandler = async () => {
    const { functional_component, toast } = this.props;
    let { progress, notes } = this.state;
    this.setState({ btnLoad: true });
    let data = {
      notes: notes,
      progress: progress,
    };
    let response = await ApiService.patchAPI(
      `contractor/task/progress/${this.props.taskId}`,
      data
    );
    this.setState({ btnLoad: false });
    if (response.success) {
      if (functional_component) {
        toast ?  toast("success", response.message) : showSuccess(this.props.props,response.message)
      } else showSuccess(this.props.props, response.message);
      this.setState({
        btnLoad: false,
        openEditModal: false,
        edit_detail: {},
        notes: "",
      });
      this.closeModal(true);
    } else {
      if (functional_component) {
        toast ?  toast("danger", response.message) : showError(this.props.props,response.message)
      } else showError(this.props.props, response.message);
    }
  };

  render() {
    let { isLoading, btnLoad, progress, notes } = this.state;
    return isLoading ? (
      <div className="spin-loading">
        <Spinner type="border" color="#2e1a47" />
      </div>
    ) : (
      <>
        <CardHeader>
          <div className="flex">
            <div className="fw-500 f16" style={{ color: "white" }}>
              Update
            </div>
            <img
              src={closeIcon}
              onClick={() => this.closeModal(false)}
              className="point"
            />
          </div>
        </CardHeader>
        <div className="cardContainer">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "1.5rem",
            }}
          >
            <div
              style={{ paddingBottom: "10px" }}
              className="drawer_sub_heading"
            >
              Percentage of completion
            </div>
            <div style={{ marginTop: "1rem" }}>
              {" "}
              <ControllableProgressBar
                editProgress={this.updateProgress}
                value={progress ?? 0}
              />
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: ".5rem",
              }}
            >
              <div>{progress ?? 0}%</div>
              <div>100%</div>
            </div>
          </div>

          <div style={{ marginTop: "20px" }}>
            <div
              style={{ paddingBottom: "10px" }}
              className="drawer_sub_heading"
            >
              Notes
            </div>
            <div style={{ marginTop: "2%" }}>
              {" "}
              {/* <Input
                  style={{ padding: "3rem" }}
                  type="text"
                  value={notes}
                  name="notes"
                  onChange={(e) => {
                    this.setState({ notes: e.target.value });
                  }}
                  className="mb-20 drawer_desc"
                /> */}
              <textarea
                value={notes}
                onChange={(e) => {
                  this.setState({ notes: e.target.value });
                }}
                rows={3}
                className="mb-20 drawer_desc"
                style={{
                  width: "100%",
                  borderRadius: "5px",
                  padding: "5px 10px",
                  border: "1px solid #f0f0f0",
                }}
              ></textarea>
            </div>
          </div>
          <div className="flexEnd" style={{ marginTop: "4rem" }}>
            <Button_without_Border
              btnLabel="cancel"
              style={{ marginRight: "20px" }}
              click={() => this.closeModal()}
            />
            <Custom_Button
              isPrimary
              btnLabel="Update"
              isLoading={btnLoad}
              click={() => this.createProjectHandler()}
            />
          </div>
        </div>
      </>
    );
  }
}
export default UpdateTask;
