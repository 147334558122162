import React, { Component } from "react";
import closeIcon from "../../assets/img/brand/close.svg";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Media,
  Spinner,
  UncontrolledDropdown,
  FormGroup,
  InputGroup,
} from "reactstrap";
import ReactTooltip from "react-tooltip";
import "react-datepicker/dist/react-datepicker.css";
import { maxLengthCheck } from "constants/utils";
import ApiService from "constants/ApiService";
import Add from "../../assets/img/brand/addIcon.svg";
import removeIcon from "../../assets/img/brand/removeIcon.svg";
import pdfImage from "../../assets/img/brand/pdf.png";
import { LightgalleryItem } from "react-lightgallery";
import { getCloudinaryImage } from "constants/utils";
import { uploadCloudinary } from "constants/utils";
import { showError, replaceToNormalNumber } from "constants/utils";
import moment from "moment";
import assignRequestImg from "../../assets/img/brand/requestFirstStaging.svg";
import { isInvalidEmail, getUSFormat } from "constants/utils";
import infoIcon from "../../assets/img/brand/infoIcon.svg";
import Autocomplete from "components/AutoComplete/AutoComplete";
import white_close_icon from "../../assets/img/icons/white_close_icon.svg";
import { Button_without_Border } from "components/Buttons/Button";
import { Custom_Button } from "components/Buttons/Button";

class AssignSubcontractors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      btnLoading: false,
      pageLoading: false,
      subContractorList: [],
      subcontractor: "",
      amount: "",
      commissionMode: "fixed",
      commissionAmount: "",
      estimatedAmount: "",
      email: "",
      phoneNumber: "",
      step1: true,
      step2: false,
      assignTo: "",
      otherProObj: {},
    };
  }

  componentDidMount() {
    this.getSubcontractorList();
  }
  getSubcontractorList = async () => {
    let response = await ApiService.getAPI(`contractor/sub_contractors/all`);
    if (response.success) {
      this.setState({ subContractorList: response.data.contractors });
    }
  };

  handleAmountChange = (e) => {
    let { commissionMode, amount, commissionAmount } = this.state;

    this.setState({ amount: e.target.value }, () => {
      this.setEstimatedAmount();
    });
  };

  setCommissionMode = (mode) => {
    this.setState({ commissionMode: mode, commissionAmount: "" });
  };

  setCommissionAmount = (e) => {
    let { commissionMode, amount } = this.state;
    this.setState({ commissionAmount: e.target.value }, () => {
      this.setEstimatedAmount();
    });
  };

  setEstimatedAmount = () => {
    let { commissionMode, amount, commissionAmount } = this.state;
    if (commissionMode === "percentage") {
      let percent = (commissionAmount / 100) * amount;
      this.setState({ estimatedAmount: percent });
    } else {
      this.setState({ estimatedAmount: commissionAmount });
    }
  };

  closeModal = () => {
    this.setState({
      amount: "",
      estimatedAmount: "",
      commissionAmount: "",
      commissionMode: "fixed",
      subcontractor: "",
      step1: true,
      step2: false,
      email: "",
      phoneNumber: "",
      assignTo: "",
    });
    this.props.close(false);
  };

  assignContractor = async () => {
    let {
      subcontractor,
      amount,
      commissionAmount,
      commissionMode,
      step1,
      step2,
      assignTo,
      otherProObj,
      email,
      phoneNumber,
    } = this.state;
    if (step1) {
      if (!assignTo) {
        this.props.toast(
          "Please select assign to subcontractor or work order "
        );
      } else {
        this.setState({ step2: true, step1: false });
      }
    }

    if (step2) {
      if (assignTo === "sub_contractor" && subcontractor === "") {
        this.props.toast("Please Select a Subcontractor");
      } else if (
        assignTo === "other_pros" &&
        Object.keys(otherProObj).length === 0
      ) {
        this.props.toast("Please Select other pro");
      } else if (assignTo === "work_order" && !email && !phoneNumber) {
        this.props.toast("Please enter email or phone number");
      } else if (assignTo === "work_order" && email && isInvalidEmail(email)) {
        this.props.toast("Please enter valid email");
      } else if (
        assignTo === "work_order" &&
        phoneNumber &&
        phoneNumber.length < 16
      ) {
        this.props.toast("Please enter valid phone number");
      } else if (amount === undefined || amount === null || amount === "") {
        this.props.toast("Please enter total amount");
      } else if (amount <= 0) {
        this.props.toast("Please enter valid total amount");
      } else if (
        commissionMode === undefined ||
        commissionMode === null ||
        commissionMode === ""
      ) {
        this.props.toast("Please Select Commission Mode");
      } else if (
        commissionAmount === undefined ||
        commissionAmount === null ||
        commissionAmount === ""
      ) {
        this.props.toast(
          `Please Enter ${commissionMode === "fixed" ? "Amount" : "Percentage"}`
        );
      } else if (
        commissionMode === "percentage" &&
        (commissionAmount <= 0 || commissionAmount > 100)
      ) {
        this.props.toast("Percentage should be between 1 and 100");
      } else if (commissionMode === "fixed" && commissionAmount < 1) {
        this.props.toast("Please enter a valid amount");
      } else if (
        commissionMode === "fixed" &&
        parseInt(commissionAmount) > parseInt(amount)
      ) {
        this.props.toast("Fixed amount should be less than total amount");
      } else {
        this.setState({ btnLoading: true });
        let response;
        let data;
        if (assignTo === "sub_contractor") {
          data = {
            sub_contractor: {
              name: subcontractor.name,
              id: subcontractor.contractor_id,
              email: subcontractor.email,
            },
            type: commissionMode,
            contractor_commission: commissionAmount,
            amount: amount,
            assign_type: "inside",
          };
        } else if (assignTo === "work_order") {
          data = {
            email: email,
            phone: {
              national_number: replaceToNormalNumber(phoneNumber),
              country_code: "1",
            },
            work_order: {
              od_type: this.props.maintenanceId ? "maintenance" : "request",
              id: this.props.maintenanceId
                ? this.props.maintenanceId
                : this.props.requestId,
              type: commissionMode,
              contractor_commission: commissionAmount,
              amount: amount,
            },
          };
        } else if (assignTo === "other_pros") {
          data = {
            sub_contractor: {
              name: otherProObj.name,
              id: otherProObj.id,
              email: otherProObj.email,
              phone: otherProObj.phone,
            },
            type: commissionMode,
            contractor_commission: commissionAmount,
            amount: amount,
            assign_type: "outside",
          };
        }
        if (this.props.maintenanceId) {
          data["service_charge"] = amount;
        }
        if (this.props.requestId) {
          let id = this.props.requestId;
          if (assignTo === "sub_contractor" || assignTo === "other_pros") {
            response = await ApiService.patchAPI(
              `contractor/requests/assign/${id}`,
              data
            );
          } else if (assignTo === "work_order") {
            response = await ApiService.postAPI(`contractor/invite`, data);
          }
        } else if (this.props.maintenanceId) {
          let id = this.props.maintenanceId;

          if (assignTo === "sub_contractor" || assignTo === "other_pros") {
            response = await ApiService.patchAPI(
              `contractor/maintenance/assign/${id}`,
              data
            );
          } else if (assignTo === "work_order") {
            response = await ApiService.postAPI(`contractor/invite`, data);
          }
        }

        if (response.success) {
          this.setState({ btnLoading: false });
          if (assignTo === "work_order") {
            this.props.close(response.message, response?.data?.link);
          } else {
            this.props.close(response.message);
          }

          this.setState({
            amount: "",
            estimatedAmount: "",
            commissionAmount: "",
            commissionMode: "fixed",
            subcontractor: "",
            assignTo: "",
            email: "",
            phoneNumber: "",
          });
        } else {
          this.setState({ btnLoading: false });

          this.props.toast(response.message);
        }
      }
    }
  };
  showToast = (msg) => {
    this.props.toast(msg);
  };

  setContractor = (data, i) => {
    this.setState({ subcontractor: data });
    this.state.subContractorList.map((contractor) => {});
  };

  handleSelect = (contractor) => {
    this.setState({
      otherProObj: {
        id: contractor.contractor_id,
        name: contractor.name,
        phone: contractor.phone,
        email: contractor.email,
      },
    });
  };
  handleRadioChange = (e, key) => {
    this.setState({ [key]: e?.target?.value ? e.target.value : e });
  };
  handlePhoneNumberChange = (e) => {
    this.setState({ phoneNumber: getUSFormat(e.target.value) });
  };
  goBack = () => {
    const { step2, step1 } = this.state;
    if (step1) {
      this.closeModal();
    } else if (step2) {
      this.setState({ step2: false, step1: true });
    }
  };
  handleEmailChange = (e) => {
    this.setState({ email: e.target.value });
  };
  render() {
    let {
      btnLoading,
      pageLoading,
      subContractorList,
      subcontractor,
      amount,
      estimatedAmount,
      commissionAmount,
      commissionMode,
      email,
      phoneNumber,
      otherProObj,
      step1,
      step2,
    } = this.state;

    return (
      <div>
        {this.props.open && (
          <div className="bnone">
            <CardHeader style={{ backgroundColor: "#72569C" }}>
              <div className="flex">
                <div className="fw-500 f16" style={{ color: "white" }}>
                  {"Assign Request"}
                </div>
                <img
                  src={white_close_icon}
                  onClick={() => this.closeModal(false)}
                  className="point"
                  alt="Close"
                />
              </div>
            </CardHeader>
            <CardBody className="pad30p">
              <>
                {this.state.step1 && (
                  <div className="fade_effect ">
                    {/* {contractorAssignStage === "1" && ()} */}
                    <div>
                      <h4 className="ft_family mb_40">
                        To whom do you want to assign this work?
                      </h4>
                    </div>
                    <div>
                      <img
                        src={assignRequestImg}
                        alt=""
                        className="assignFrstImg"
                      />
                    </div>
                    <div className="input_radio_div">
                      <FormGroup className="mb_30">
                        <InputGroup className="d_flex_align">
                          <input
                            className="radio_button mt_0 mr_10 point"
                            placeholder=""
                            type="radio"
                            value="sub_contractor"
                            id="selfAssign"
                            checked={this.state.assignTo === "sub_contractor"}
                            onChange={(e) =>
                              this.handleRadioChange(e, "assignTo")
                            }
                          />
                          <label
                            className="form-control-label label-in mb_0 point"
                            htmlFor="selfAssign"
                            onClick={() =>
                              this.handleRadioChange(
                                "sub_contractor",
                                "assignTo"
                              )
                            }
                          >
                            Assign to My Team
                          </label>
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb_30">
                        <InputGroup className="d_flex_align">
                          <input
                            className="radio_button mt_0 mr_10 point"
                            placeholder=""
                            type="radio"
                            value="other_pros"
                            id="otherPro"
                            checked={this.state.assignTo === "other_pros"}
                            onChange={(e) =>
                              this.handleRadioChange(e, "assignTo")
                            }
                          />
                          <label
                            className="form-control-label label-in mb_0 point"
                            htmlFor="otherPro"
                            onClick={() =>
                              this.handleRadioChange("other_pros", "assignTo")
                            }
                          >
                            Assign to Other Pros
                          </label>
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb_50">
                        <InputGroup className="d_flex_align">
                          <input
                            className="radio_button mt_0 mr_10 point"
                            placeholder=""
                            type="radio"
                            id="contractorAssign"
                            value="work_order"
                            checked={this.state.assignTo === "work_order"}
                            onChange={(e) =>
                              this.handleRadioChange(e, "assignTo")
                            }
                          />
                          <label
                            className="form-control-label label-in mb_0 point"
                            htmlFor="contractorAssign"
                            onClick={() =>
                              this.handleRadioChange("work_order", "assignTo")
                            }
                          >
                            Send Work Order
                            <img
                              src={infoIcon}
                              data-tip="Assign this work to new service pro through invite."
                              data-iscapture="true"
                              data-for="workingInfo"
                              data-place="bottom"
                              className="point fa fa-info-circle info_icon"
                            ></img>
                            <ReactTooltip
                              place="left"
                              type="info"
                              effect="solid"
                              id="workingInfo"
                              html={true}
                            />
                          </label>
                        </InputGroup>
                      </FormGroup>
                    </div>
                  </div>
                )}
                {this.state.step2 && (
                  <>
                    {pageLoading ? (
                      <div className="spin-loading3 w100 h100">
                        <Spinner type="border" color="#0A8080" />
                      </div>
                    ) : (
                      <>
                        {this.state.assignTo === "sub_contractor" && (
                          <>
                            {" "}
                            <div className="cl2A1E17 mb-15 f14 fw-400">
                              Select Sub contractor *
                            </div>
                            <div className="status_select w100 mb-20">
                              <UncontrolledDropdown className="status_select_div">
                                <DropdownToggle
                                  className={
                                    subcontractor
                                      ? "pr-0 drop_down_container"
                                      : "pr-0 drop_down_container h35"
                                  }
                                >
                                  <Media className="capitalize">
                                    {this.state.subcontractor.name}

                                    <i className="fas fa-angle-down"></i>
                                  </Media>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-arrow w100">
                                  {this.state.subContractorList.map(
                                    (contractor, i) => (
                                      <DropdownItem
                                        className="dropdown_item "
                                        onClick={() =>
                                          this.setContractor(contractor, i)
                                        }
                                      >
                                        {contractor.name}
                                      </DropdownItem>
                                    )
                                  )}
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>
                          </>
                        )}
                        {this.state.assignTo === "other_pros" && (
                          <>
                            {" "}
                            <div className="cl2A1E17 mb-15 f14 fw-400">
                              Select Other Pros *
                            </div>
                            <Autocomplete
                              onSelect={this.handleSelect}
                              type="outside_contractor"
                              value={otherProObj}
                            />
                          </>
                        )}
                        {this.state.assignTo === "work_order" && (
                          <>
                            <div className="cl2A1E17 mb-15 f14 fw-400">
                              Email*
                            </div>
                            <Input
                              type="email"
                              value={email}
                              name="Email"
                              onChange={this.handleEmailChange}
                              className="mb-20"
                              // maxLength={5}
                              // onInput={maxLengthCheck}
                            />
                          </>
                        )}
                        {this.state.assignTo === "work_order" && (
                          <>
                            <div className="cl2A1E17 mb-15 f14 fw-400">
                              Phone Number *
                            </div>
                            <Input
                              value={phoneNumber}
                              onChange={this.handlePhoneNumberChange}
                              name="phone"
                              placeholder=""
                              type="text"
                              maxLength="16"
                            />
                          </>
                        )}

                        <div
                          className="cl2A1E17 mb-15 f14 fw-400"
                          style={{ marginTop: "15px" }}
                        >
                          Total Amount *
                        </div>
                        <Input
                          type="number"
                          value={amount}
                          name="Amount"
                          onChange={this.handleAmountChange}
                          className="mb-20"
                          maxLength={5}
                          onInput={maxLengthCheck}
                        />
                        <div className="cl2A1E17 mb-15 f14 fw-400">
                          Commission Mode *
                        </div>
                        <div className="status_select w100 mb-20">
                          <UncontrolledDropdown className="status_select_div">
                            <DropdownToggle className="pr-0 drop_down_container">
                              <Media className="capitalize">
                                {commissionMode}

                                <i className="fas fa-angle-down"></i>
                              </Media>
                            </DropdownToggle>
                            <DropdownMenu
                              className="dropdown-menu-arrow w100"
                              bottom
                            >
                              <DropdownItem
                                className="dropdown_item "
                                onClick={() => this.setCommissionMode("fixed")}
                              >
                                Fixed
                              </DropdownItem>
                              <DropdownItem
                                className="dropdown_item "
                                onClick={() =>
                                  this.setCommissionMode("percentage")
                                }
                              >
                                Percentage
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </div>
                        <div className="cl2A1E17 mb-15 f14 fw-400">
                          Enter{" "}
                          {commissionMode === "fixed"
                            ? "Amount"
                            : "Percentage (%)"}{" "}
                          *
                        </div>
                        <Input
                          value={commissionAmount}
                          type="number"
                          name="commissionAmount"
                          onChange={this.setCommissionAmount}
                          className="mb-20"
                          maxLength={commissionMode === "percentage" ? 3 : 5}
                          onInput={maxLengthCheck}
                        />
                        {commissionAmount && amount && (
                          <>
                            <div className="cl2A1E17 mb-15 f14 fw-400">
                              Commision Amount
                            </div>
                            <Input
                              value={estimatedAmount}
                              type="text"
                              name="estimatedAmount"
                              className="mb-20"
                              readOnly
                            />
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
                <div className="flexEnd">
                  <Button_without_Border
                    style={{marginRight:'20px'}}
                    btnLabel={step1 ? "Cancel" : "Back"}
                    click={() => this.goBack()}
                  />
                  <Custom_Button
                    isPrimary
                    btnLabel={step1 ? "Next" : "Assign"}
                    isLoading={btnLoading}
                    click={() => this.assignContractor()}
                  />
                </div>
              </>
            </CardBody>
          </div>
        )}
      </div>
    );
  }
}

export default AssignSubcontractors;
