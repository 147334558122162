import React, { Component } from "react";
import {
  Card,
  Container,
  Row,
  Breadcrumb,
  Col,
  Button,
  Spinner,
  DropdownMenu,
  DropdownItem,
  Media,
  UncontrolledDropdown,
  DropdownToggle,
} from "reactstrap";
import AddClient from "../../Project/addClient";
import AddProject from "../../Project/addProject";
import ApiService from "constants/ApiService";
import "react-datepicker/dist/react-datepicker.css";
import { getIsoString, filterQuery } from "constants/utils";
import ToastList from "components/Toast/ToastList";
import { showError } from "constants/utils";
import { returnSearchObject } from "constants/utils";
import { pushToFilteredPage } from "constants/utils";
import { buildQueryString } from "constants/utils";
import { showSuccess } from "constants/utils";
import { uploadCloudinary, capitalizeFirstLetter } from "constants/utils";
import DeleteModal from "../../Project/DeleteModal";
import TimeLine from "components/Timeline/Timeline";
import Leads from "views/Crm/Leads";
import Proposal from "views/Crm/Proposal";
import Project from "../../Project/Project";
import Tax from "views/Crm/Tax";
import Clients from "../../Project/Client";
import AddLead from "views/Crm/addLead";
import Overview from "../../Project/overviewPage";
import { Custom_Button } from "components/Buttons/Button";
let overlap = document.querySelector(":root");
let CommonSelectedtab = "";
class ProjectOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectDetail: {},
      requestList: [],
      project_list: [],
      total: 0,
      filterToggle: false,
      from: "",
      to: "",
      inintAddProjectLoading: true,
      // page: 1,
      image_dir: "",
      open: false,
      client_id: "",
      inputSearch: "",
      filterOption: "",
      image_name: "",
      statusSearch: this.props.location?.search ? "pending" : "",
      toast: false,
      response: "",
      toastType: "",
      isLoading: true,
      clientsList: [],
      btnLoad: false,
      delete_data: {},
      query: {},
      pageNav: "",
      name: "",
      client: {},
      all_projects: 0,
      active_projects: 0,
      completed_projects: 0,
      all_tasks: 0,
      start_date: "",
      actual_start: "",
      client_name: "",
      inputText: "",
      items: [],
      image: [],
      imageLoading: false,
      desc: "",
      tags: [],
      end_date: "",
      requestId: "",
      openModal: false,
      cancelModal: false,
      project_id: "",
      isTimeLineOpen: false,
      selectedTab: "",
      openLead: false,
      leadReload: false,
      openClient: false,
      clientReload: false,
      projectListReload: false,
      filter: "all",
    };
  }

  componentDidMount = async () => {
    overlap.style.setProperty("--width", "440px");
    overlap.style.setProperty("--backgroundColor", "transparent");
    this.setQueryStatus();
  };

  componentWillUnmount() {
    overlap.style.setProperty("--width", "450px");
    overlap.style.setProperty("--backgroundColor", "#f5f5f5");
  }

  capitalizeFirstLetter = (str) => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  setQueryStatus = () => {
    this.setState({
      selectedTab: this.props.location.state?.selectedTab
        ? this.props.location.state?.selectedTab
        : "overview",
    });
  };

  imageHandler = async (e) => {
    let { image } = this.state;
    let file = e.target.files[0];

    if (e.target.files.length > 0) {
      this.setState({ imageLoading: true });
    }

    if (
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/jpeg" ||
      file.type === "application/pdf"
    ) {
      if (file.size < 1000000) {
        let data = await uploadCloudinary(file, "host");
        if (data.url) {
          let imgArr = [...image];
          let imageObj = {
            format: data.format,
            public_id: data.public_id,
            version: `v${data.version}`,
            type: "image",
          };
          imgArr.push(imageObj);
          this.setState({
            image: imgArr,
            imageLoading: false,
          });
        } else {
          showError(this, "Could not upload image");
          this.setState({ imageLoading: false });
        }
      } else {
        showError(this, "File size should not exceeds 1 MB");
        this.setState({ imageLoading: false });
      }
    } else {
      showError(this, "Invalid Format");
      this.setState({ imageLoading: false });
    }
  };

  deleteImage = () => {
    this.setState({ image: [], image_name: "" });
  };
  redirectDetailPage = (id) => {
    this.props.history.push(`projects/detail/${id}`);
  };

  onAddLeadClose = () => {
    this.setState({ openLead: false, leadReload: true }, () => {
      this.setState({ leadReload: false });
    });
  };

  onAddClientClose = () => {
    this.setState({ openClient: false, clientReload: true }, () => {
      this.setState({ clientReload: false });
    });
  };
  onCancelClientPanel = () => {
    this.setState({ openClient: false });
  };
  onCancelLeadPanel = () => {
    this.setState({ openLead: false });
  };
  onCancelAddProjectPanel = () => {
    this.setState({ open: false });
  };
  dateChangeHandler = (e, value) => {
    this.setState({ [value]: e });
  };
  Showtoast = (type, message) => {
    this.setState({
      toast: true,
      toastType: type,
      response: message,
    });
    this.clearToast();
  }
  clearToast = () => {
    setTimeout(
      () => this.setState({ toast: false, toastType: "", response: "" }),
      2000
    );
  }
  editProject = async (data) => {
    this.setState(
      {
        // edit: true,
        // name: data?.name,
        // start_date: new Date(data?.start_date),
        // actual_start: new Date(data?.actual_start),
        // end_date: new Date(data?.end_date),
        // tags: data.tags,
        project_id: data.project_id,
        // desc: data.desc,
        // client_name: data.client.name,
        // client_id: data.client.id,
        // image: data.image,
        // projectDetail: data,
      },
      () => {
        this.setState({ open: true });
      }
    );
  };

  getRequestsList = async () => {
    let { filterOption, statusSearch, inputSearch, from, to } = this.state;
    let queryParams = "";
    if (filterOption !== "" && inputSearch !== "")
      queryParams += filterQuery(filterOption, inputSearch);
    if (statusSearch !== "") {
      queryParams += filterQuery("status", statusSearch);
    }
    if (from !== "") queryParams += filterQuery("from", getIsoString(from));
    if (to !== "") queryParams += filterQuery("to", getIsoString(to));
    if (from > to) {
      showError(this, "Please provide valid From and To date");
    } else {
      let response = await ApiService.getAPI(
        `contractor/open_request${buildQueryString(this.state.query)}`
      );
      this.setState({ isLoading: false });
      if (response.success) {
        let { open_request, total } = response.data;
        this.setState({
          requestList: open_request,
          total: total,
        });
      }
    }
  };

  changeTab = (e, filter) => {
    filter = filter === "all_projects" ? "all" : filter === "active_projects" ? "active" : filter === "completed_projects" ? "completed" : filter

    this.setState({ selectedTab: e, filter: filter });
    // this.props.history.push(`/admin/project_overview?tab=${e}&filter=${filter}`);
    this.props.history.push(`/admin/project_overview`);
  };

  getProjectList = async () => {
    let { filterOption, statusSearch, inputSearch, from, to, selectedTab } =
      this.state;
    let queryParams = "";
    if (filterOption !== "" && inputSearch !== "")
      queryParams += filterQuery(filterOption, inputSearch);
    if (statusSearch !== "") {
      queryParams += filterQuery("status", statusSearch);
    }
    if (from !== "") queryParams += filterQuery("from", getIsoString(from));
    if (to !== "") queryParams += filterQuery("to", getIsoString(to));
    if (from > to) {
      showError(this, "Please provide valid From and To date");
    } else {
      let response = await ApiService.getAPI(`contractor/project/dashboard`);
      let projectListResponse = await ApiService.getAPI(`contractor/project`);

      this.setState({ isLoading: false });
      if (projectListResponse.success) {
        this.setState({
          project_list: projectListResponse.data.projects.slice(0, 5),
        });
      }
      if (response.success) {
        let {
          all_projects,
          active_projects,
          completed_projects,
          all_tasks,
          project_list,
        } = response.data;
        this.setState({
          all_projects: all_projects,
          active_projects: active_projects,
          completed_projects: completed_projects,
          all_tasks: all_tasks,
        });
      }
    }
  };
  createProjectHandler = async () => {
    let {
      name,
      client_name,
      start_date,
      end_date,
      actual_start,
      client_id,
      desc,
      project_id,
      edit,
      tags,
    } = this.state;
    if (!name || !client_name || !start_date || !end_date || !actual_start)
      return showError(this, "Please fill all the required fields");
    this.setState({ btnLoad: true });
    let data = {
      name: name,
      client: {
        id: client_id,
        name: client_name,
      },
      start_date: start_date,
      end_date: end_date,
      actual_start: actual_start,
      desc: desc,
      tags: tags,
    };
    let response = edit
      ? await ApiService.patchAPI(`contractor/project/${project_id}`, data)
      : await ApiService.postAPI("contractor/project", data);
    if (response.success) {
      showSuccess(this, response.message);
      this.setState({
        btnLoad: false,
        open: false,
        name: "",
        start_date: "",
        end_date: "",
        actual_start: "",
        desc: "",
        tags: [],
        client_name: "",
        client_id: "",
      });
      this.getProjectList();
    } else {
      this.setState({ btnLoad: false });
      showError(this, response.message);
    }
  };

  tabChange = (event) => {
    event === "projects" && this.setState({ filter: "all" });
    this.setState({ selectedTab: event });

    // if (event === "")
    //   this.props.history.push("/admin/project_overview?tab=overview");
    // else {
    //   this.props.history.push(`/admin/project_overview?tab=${event}`);
    // }
  };

  handleInputChange = (event) => {
    this.setState({ inputText: event.target.value });
  };

  handleEnterKey = (event) => {
    if (event.key === "Enter" && this.state.inputText.trim() !== "") {
      this.setState((prevState) => ({
        inputText: "",
        items: [...prevState.items, prevState.inputText],
      }));
    }
  };
  toggleFilterMenu = () => {
    this.setState({ filterToggle: !this.state.filterToggle });
  };
  onChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  updateStateValues = (key, value) => {
    this.setState(
      {
        [key]: value,
      },
      function () {
        if (value === null) {
          this.setState({
            [key]: "",
          });
        }
      }
    );
  };

  handleOnToChange = (e) => {
    this.updateStateValues("to", e ? new Date(e) : '');
  };

  handleOnFromChange = (e) => {
    this.updateStateValues("from", e ? new Date(e) : '');
  };

  handleFilterStatus = () => {
    let staticQuery = returnSearchObject(this.props.location.search);
    staticQuery.from = getIsoString(this.state.from) ?? "";
    staticQuery.to = getIsoString(this.state.to) ?? "";
    staticQuery.status = this.state.statusSearch ?? "";
    staticQuery.page = this.state.page ?? "";
    if (this.state.inputSearch !== "" && this.state.filterOption !== "") {
      staticQuery[this.state.filterOption] = this.state.inputSearch;
    }
    this.setState({ query: staticQuery }, () => {
      pushToFilteredPage(this.props, { ...this.state.query });
      this.getProjectList();
    });
  };

  handleSearch = () => {
    this.handleFilterStatus();
  };

  handleReset = () => {
    let self = this;
    self.setState(
      {
        from: "",
        to: "",
        statusSearch: "",
        filterOption: "",
        inputSearch: "",
        query: {},
      },
      () => {
        pushToFilteredPage(this.props, { ...this.state.query });
        this.getProjectList();
      }
    );
  };

  setPage = (page) => {
    this.props.history.push(page);
  };

  addSelectedTab = () => {
    (this.state.selectedTab === "overview" ||
      this.state.selectedTab === "projects") &&
      this.addProject();
    this.state.selectedTab === "leads" && this.addLead();
    this.state.selectedTab === "estimates" && this.addEstimate();
    this.state.selectedTab === "taxes" && this.addEstimate();
    this.state.selectedTab === "clients" && this.addClients();
  };

  addClients = () => {
    this.setState({ openClient: true });
  };
  addEstimate = () => {
    this.props.history.push({
      pathname: "/admin/add_estimates",
      state: { from: "estimates" },
    });
  };

  addLead = async () => {
    this.setState({ openLead: true });
  };

  addProject = async () => {
    this.setState({ open: true });
    // let response = await ApiService.getAPI(`contractor/client?status=active`);
    // if (response.success) {
    //   let { clients } = response.data;
    //   this.setState({
    //     clientsList: clients,
    //     edit: false,
    //     inintAddProjectLoading: false,
    //   });
    // }
  };

  closeModal = () => {
    this.setState({ openModal: false, project_id: "" });
    this.getProjectList();
  };

  handleDelete = async () => {
    let response = await ApiService.patchAPI(
      `contractor/project/delete/${this.state.delete_data.project_id}`,
      {}
    );
    if (response.success) {
      showSuccess(this, response.message);
      this.setState({ delete_data: {} });
      this.closeModal();
      this.getProjectList();
    } else {
      showError(this, response.message);
    }
  };

  editRequest = (data) => {
    this.props.history.push(`/admin/request/update/${data?.open_request_id}`);
  };

  onClose = () => {
    this.setState(
      { open: false, project_id: "", projectListReload: true },
      () => {
        this.setState({ projectListReload: false });
      }
    );
  };

  ProjectList = () => {
    this.props.history.push("/admin/project_overview?tab=projects");
    this.setState({ selectedTab: "projects" });
  };

  cancelRequest = async (request) => {
    this.setState({ cancelModal: true, requestId: request.open_request_id });
  };

  resendRequest = async (request) => {
    let response = await ApiService.patchAPI(
      `contractor/open_request/resend/${request.open_request_id}`
    );
    if (response.success) {
      showSuccess(this, response.message);
      this.getProjectList();
    } else {
      showError(this, response.message);
    }
  };
  resendInvoice = async (request) => {
    let response = await ApiService.patchAPI(
      `contractor/open_request/invoice/${request.open_request_id}`
    );
    if (response.success) {
      showSuccess(this, response.message);
      this.getProjectList();
    } else {
      showError(this, response.message);
    }
  };

  handleTagsChange = (tags) => {
    this.setState({ tags: tags });
  };

  closeTimeline = () => {
    this.setState({ isTimeLineOpen: false, project_id: "" });
  };

  toProjectLists = () => { };

  render() {
    let {
      isLoading,
      statusSearch,
      toast,
      response,
      toastType,
      project_list,
      openModal,
      all_projects,
      delete_data,
      selectedTab,
      openLead,
      leadReload,
      openClient,
      clientReload,
      projectListReload,
      filter,
    } = this.state;
    return (
      <>
        {openModal && (
          <DeleteModal
            modalState={openModal}
            type="project"
            name={delete_data.name}
            closeModal={this.closeModal}
            props={this}
            id={this.state.project_id}
          />
        )}

        <div
          style={{ padding: "20px", backgroundColor: '#fafafa' }}
          className="primary-background-color base_background"
        >
          {toast && <ToastList message={response} type={toastType} />}

          <Card
            className={this.state.open ? "exportSideBar" : "toggle-sidebar"}
          >
            {this.state.open && (
              <AddProject
                type="project"
                props={this}
                projectId={this.state.project_id}
                close={() => this.onClose()}
                cancel={this.onCancelAddProjectPanel}
              />
            )}
          </Card>
          <Card
            className={
              this.state.isTimeLineOpen ? "exportSideBar" : "toggle-sidebar"
            }
          >
            {this.state.isTimeLineOpen && (
              <TimeLine
                from={"project"}
                id={this.state.project_id}
                closeTimelineModal={this.closeTimeline}

              ></TimeLine>
            )}
          </Card>
          <Card className={openLead ? "exportSideBar" : "toggle-sidebar"}>
            {openLead && (
              <AddLead
                type="lead"
                props={this}
                leadID={this.state.lead_id}
                close={this.onAddLeadClose}
                addLead={this.lead_accum}
                cancel={this.onCancelLeadPanel}
                toast={this.Showtoast}
              ></AddLead>
            )}
          </Card>
          <Card className={openClient ? "exportSideBar" : "toggle-sidebar"}>
            {openClient && (
              <AddClient
                closeClientModal={this.onAddClientClose}
                props={this}
                cancel={() => this.setState({ openClient: false })}
              />
            )}
          </Card>
          {selectedTab === "overview" && !projectListReload && (
            <Overview tabChange={this.changeTab} props={this} />
          )}
          {selectedTab === "leads" && !leadReload && <Leads props={this} />}
          {selectedTab === "estimates" && (
            <Proposal tabChange={this.changeTab} props={this} />
          )}
          {selectedTab === "projects" && <Project filter={filter} props={this} />}
          {selectedTab === "taxes" && <Tax props={this} />}
          {selectedTab === "clients" && !clientReload && <Clients props={this} />}


        </div>


      </>
    );
  }
}

export default ProjectOverview;
