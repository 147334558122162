import React, { Component } from "react";
import { Button, Modal, Spinner } from "reactstrap";
import ApiService from "constants/ApiService";
import { showError } from "constants/utils";
import { showSuccess } from "constants/utils";
import "react-datepicker/dist/react-datepicker.css";
import { Custom_Button } from "components/Buttons/Button";
import { Button_without_Border } from "components/Buttons/Button";
import { remove_underscore_capitalize } from "constants/utils";
import { SplitCellsOutlined } from "@ant-design/icons";

export default class DeleteModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      btn_load: false,
      split_details: null,
    };
    
  }
  componentDidMount = () => {
    if (this.props.type === "Splitup") {
      this.setState({split_details:this.props.lastSplitUpObj},() => {
      })
    }
  };

  handleDelete = async () => {
    let url;
    if (!this.props.id && this.props.type === "Splitup")
      return this.props.deleteSplitModal();
    this.props.type === "project" &&
      (url = `contractor/project/delete/${this.props.id}`);
    this.props.type === "client" &&
      (url = `contractor/client/statusChange/${this.props.id}`);
    this.props.type === "task" &&
      (url = `contractor/task/delete/${this.props.id}`);
    this.props.type === "estimate" &&
      (url = `contractor/estimate/${this.props.id}`);
    this.props.type === "WorkOrder" &&
      (url = `contractor/workOrder/delete/${this.props.id}`);
    this.props.type === "Splitup" &&
      (url = `contractor/paymentSplitup/delete/${this.props.id}`);
    this.setState({ btn_load: true });
    let response = await ApiService[
      ["estimate"].includes(this.props.type)
        ? "deleteAPI"
        : "patchAPI"
    ](
      url,
      (this.props.type === "Splitup" && this.props.lastSplitUpObj) ? { splitup_id:this.props.lastSplitUpObj.splitup_id , remainingCost :this.props.lastSplitUpObj.amount.remainingCost } : {}
    );
    this.setState({ btn_load: false });
    if (response.success) {
      this.props.type === "Splitup" || this.props.type === "WorkOrder"
        ? this.props.toast("success", response.message)
        : showSuccess(this.props.props, response.message);
      this.setState({ delete_data: {} });
      this.props.closeModal(true);
    } else {
      this.props.type === "Splitup" || this.props.type === "WorkOrder"
        ? this.props.toast("danger", response.message)
        : showError(this.props.props, response.message);
    }
  };
  render() {
    let { toggleModal, closeModal, modalState, type, name, onSubmit, width } =
      this.props;
    return (
      <Modal
        className="modal-dialog-centered"
        isOpen={modalState}
        toggle={toggleModal}
      >
        <>
          <div className="modal-invite">
            <div
              style={{
                textAlign: "center",
                fontWeight: "500",
                fontSize: "20px",
                color: "#212121",
              }}
              // id="exampleModalLabel"
            >
              {this.props.type === "project" ||
              this.props.type === "task" ||
              this.props.type === "estimate" ||
              this.props.type === "Splitup" ||
              this.props.type === "WorkOrder"
                ? `Delete ${remove_underscore_capitalize(type)} ?`
                : "Terminate Client?"}
            </div>
            {/* <div>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={closeModal}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div> */}
            <div
              style={{
                textAlign: "center",
                color: "rgba(0, 0, 0, 0.5);",
                padding: "20px 0px",
              }}
            >
              {`Are you sure want to ${
                type === "project" ||
                type === "task" ||
                type === "estimate" ||
                type === "Splitup" ||
                type === "WorkOrder"
                  ? "delete"
                  : "terminate"
              }  the ${remove_underscore_capitalize(type)} `}
              <br />
              {`${name} ?`}
            </div>

            <div className="center_the_buttons">
              <Button_without_Border
                btnLabel={
                  type === "estimate" || type === "WorkOrder" ? "No" : "cancel"
                }
                click={() => closeModal(false)}
              />
              {/* <div className="add-filter-wrap ">
              <div
                className="filter-wrap mr-3 edit-prop change-profile-pass "
                onClick={closeModal}
              >
                <label className="profile-cancel">Cancel</label>
              </div>
            </div> */}
              <Custom_Button
                style={{ marginLeft: "20px" }}
                isPrimary
                btnLabel={
                  type === "estimate" || type || "WorkOrder" ? "Yes" : "ok"
                }
                click={this.handleDelete}
                isLoading={this.state.btn_load}
              />
              {/* <Button
              color="primary"
              type="button"
              onClick={this.handleDelete}
              className="button-wrapping change-profile-button"
            >
              {this.state.btn_load ? (
                <div className="spin-loading-save w100 h100">
                  <Spinner
                    style={{
                      height: "20px",
                      width: "20px",
                    }}
                    type="border"
                    color="#0A8080"
                  />
                </div>
              ) : (
                "Ok"
              )}
            </Button> */}
            </div>
          </div>
        </>
      </Modal>
    );
  }
}
