import React, { Component } from "react";
import ToastList from "components/Toast/ToastList";
import closeIcon from "../../assets/img/icons/white_close_icon.svg";
import {
  CardHeader,
  Input,
  DropdownToggle,
  UncontrolledDropdown,
  Media,
  DropdownMenu,
  DropdownItem,
  InputGroup,
  InputGroupText,
  Spinner,
  Button,
  Tag,
  Card,
  Dropdown,
  FormGroup,
} from "reactstrap";
import DatePicker from "react-datepicker";
import moment from "moment";
import dateLight from "../../assets/img/brand/datePicker.svg";
import { returnSearchObject, showSuccess, showError } from "constants/utils";
import { Button_without_Border } from "components/Buttons/Button";
import { Custom_Button } from "components/Buttons/Button";
import { CustomInputFields } from "components/FilterFields/Filterfields";
import { CustomDatePicker } from "components/FilterFields/Filterfields";
import { CustomSingleDatePicker } from "components/FilterFields/Filterfields";
import { CustomTextArea } from "components/FilterFields/Filterfields";
import { roundOff } from "constants/utils";
import { getUSFormat } from "constants/utils";
import { AmountInput } from "components/FilterFields/Filterfields";
import { data } from "jquery";
import Estimates from "./Estimates/estimate";

let overlap = document.querySelector(":root");

class AddSchedules extends Component {
  constructor(props) {
    super(props);

    this.state = {
      initPageLoading: false,
      due_date: "",
      remainingCost: "",
      amount: "",
      description: "",
      percentageSplitUp: 1,
      percentage: "",
      value: "",
      remaining_value: 0,
      overall: "",
      splitAmount: "",
      reducedAmount: 0,
      edit: false,
      btnLoad: false,
      splitname: "",
      tempSplitups: [],
      splitUpIndex: null,
    };
  }

  componentDidMount = async () => {
    let { edit, detail } = this.props?.editSplitup;
    let { name, due_date, desc, amount, splitup_id } =
      this.props?.editSplitup?.detail;
    let { data, index } = this.props;
    if (edit) {
      let totalAmount =
        data.splitup.length > 0
          ? data.splitup?.reduce(
              (accumulator, currentValue) =>
                accumulator + parseFloat(currentValue.amount.amount),
              0
            )
          : 0;
      let constructObj = {
        splitname: name,
        description: desc,
        due_date: due_date,
        overall: this.props?.amount && roundOff(this?.props.amount),
        remaining_value: +totalAmount - +this.props?.amount,
        // remaining_value: this.props.amount,
        splitAmount: amount?.amount,
        percentageSplitUp: amount?.type === "percentage" ? 1 : 0,
        percentage:
          amount?.type === "percentage" ? this.getPercentage(data, index) : 0,
        value: amount?.type === "fixed" ? amount?.value : 0,
        edit: edit,
        splitup_id: splitup_id,
        tempSplitups: data.splitup,
        splitUpIndex: index,
      };
      this.setState({ splitname: name }, () => {
        this.setState(constructObj);
      });

      this.setspitUpDetials();
    } else this.setspitUpDetials();
    // this.getProjectList();
  };

  getPercentage = (data, index) => {
    const amount = data.splitup[index].amount.amount;
    return roundOff((amount / this.props?.amount) * 100);
  };

  updateAmount = (e) => {
    this.setState({ amount: e.target.value });
  };

  handleDueDate = (e, date) => {
    this.setState({ due_date: new Date(e) });
  };

  filteredSplitUp = (index) => {
    const { tempSplitups } = this.state;
    return tempSplitups.filter((obj, i) => index !== i);
  };

  calculateEditSplitUpAmount = (array) => {
    return array.reduce((acc, cur) => acc + cur.amount.amount, 0);
  };

  setspitUpDetials = () => {
    const { data, amount } = this.props;
    const { edit, splitup_id, tempSplitups } = this.state;
    let generatedAmount = 0;
    let localSplitups;
    if (edit) {
      localSplitups = [...tempSplitups];
    } else {
      localSplitups = [...data.splitup];
    }
    // localSplitups = localSplitups.filter((dataItem) => splitup_id !== dataItem.splitup_id);
    generatedAmount =
      localSplitups.length > 0
        ? localSplitups?.reduce(
            (accumulator, currentValue) =>
              accumulator + parseFloat(currentValue.amount.amount),
            0
          )
        : 0;
    this.setState(
      {
        overall: roundOff(amount),
        remaining_value: amount - generatedAmount,
        tempSplitups: data.splitup,
      },
      () => {}
    );
  };

  setValue = (e) => {
    const { overall, tempSplitups, edit, splitAmount, value, remaining_value , splitUpIndex } =
      this.state;
    if (edit && tempSplitups.length === 1) {
      const amount = e;
      if (overall - e >= 0) {
        this.setState({ value: e }, () => {
          this.calculateAmount();
          this.setspitUpDetials();
        });
      } else return this.props.toast("danger",`Amount should be less than or equal to ${overall.toFixed(2)}`)
    } else if (edit && tempSplitups.length > 1) {
      const filtered = this.filteredSplitUp(splitUpIndex);
      const previousAmounts = filtered.reduce((acc,cur) => acc + cur.amount.amount,0)
      if((overall - previousAmounts).toFixed(2) - e >= 0){
        this.setState({ value: e }, () => {
          this.calculateAmount();
          this.setspitUpDetials();
        });
      } else {
        return this.props.toast(
          "danger",
          `Amount should be less than or equal to ${(overall-previousAmounts).toFixed(2)}`
        );
      }
    } else if (!edit && !tempSplitups.length) {
        if(overall - e >=0) {
          this.setState({ value: e }, () => {
                this.calculateAmount();
                this.setspitUpDetials();
              });
        } else return this.props.toast(
          "danger",
          `Amount should be less than or equal to ${overall.toFixed(2)}`
        );
    } else if (!edit && tempSplitups.length === 1) {
      const previousAmount = tempSplitups[0].amount.amount;
      const remainingAmount = (overall - previousAmount).toFixed(2)
      if((remainingAmount - e) >= 0) {
        this.setState({ value: e }, () => {
              this.calculateAmount();
              this.setspitUpDetials();
            });
      } else {
        return this.props.toast(
          "danger",
          `Amount should be less than or equal to ${remainingAmount.toFixed(2)}`
        );
      }
    } else if (!edit && tempSplitups.length > 1) {
      const previousAmount = tempSplitups.reduce(
        (acc, cur) => acc + cur.amount.amount,
        0
      );
      const remainingAmount = (overall - previousAmount).toFixed(2)
      if(remainingAmount - e >= 0) {
        this.setState({ value: e }, () => {
              this.calculateAmount();
              this.setspitUpDetials();
            });
      } else {
        return this.props.toast(
          "danger",
          `Amount should be less than or equal to ${remainingAmount}`
        );
      }
    }
    // else {
    //   this.setState({ value: e }, () => {
    //     this.calculateAmount();
    //     this.setspitUpDetials();
    //   });
    // }
  };

  updatePercentage = (e) => {
    const { overall, tempSplitups, edit, splitUpIndex } = this.state;
    if (e <= 100) {
      if (!edit && tempSplitups.length === 1) {
        const percentageAmount = overall * (e / 100);
        const previousAmount = tempSplitups[0].amount.amount;
        const remainingPercentage = 100 - (+previousAmount / +overall) * 100;
        if (overall - (previousAmount + percentageAmount) >= 0) {
          this.setState({ percentage: e }, () => {
            this.calculateAmount();
            this.setspitUpDetials();
          });
        } else
          return this.props.toast(
            "danger",
            `Percentage amount should be less than or equal to ${remainingPercentage.toFixed(3)}`
          );
      } else if (!edit && tempSplitups.length > 1) {
        const percentageAmount = overall * (e / 100);
        const previousAmount = tempSplitups.reduce(
          (acc, cur) => acc + cur.amount.amount,
          0
        );
        const remainingPercentage = 100 - (+previousAmount / +overall) * 100;
        if (overall - (previousAmount + percentageAmount) >= 0) {
          this.setState({ percentage: e }, () => {
            this.calculateAmount();
            this.setspitUpDetials();
          });
        } else
          return this.props.toast(
            "danger",
            `Percentage amount should be less than or equal to ${remainingPercentage.toFixed(
              2
            )}`
          );
      } else if (edit && tempSplitups.length === 1) {
        const percentageAmount = overall * (e / 100);
        if (overall - percentageAmount >= 0) {
          this.setState({ percentage: e }, () => {
            this.calculateAmount();
            this.setspitUpDetials();
          });
        }
      } else if (edit && tempSplitups.length > 1) {
        const filtered = this.filteredSplitUp(splitUpIndex);
        const percentageAmount = overall * (e / 100);
        const previousAmount = filtered.reduce(
          (acc, cur) => acc + cur.amount.amount,
          0
        ).toFixed(2);
        const remainingPercentage = (100 - (parseFloat(previousAmount) / parseFloat(overall)) * 100).toFixed(3);
        if ( (overall - (+this.calculateEditSplitUpAmount(filtered) + +percentageAmount)).toFixed(2) >=
          0
        ) {
          this.setState({ percentage: e }, () => {
            this.calculateAmount();
            this.setspitUpDetials();
          });
        } else
          return this.props.toast(
            "danger",
            `Percentage amount should be less than or equal to ${remainingPercentage}`
          );
      } else {
        this.setState({ percentage: e }, () => {
          this.calculateAmount();
          this.setspitUpDetials();
        });
      }
    }
  };

  calculateSplitUpAmount = (index) => {
    let res = 0;
    const { tempSplitups } = this.state;
    for (let i = 0; i < index; i++) {
      res += tempSplitups[i].amount.amount;
    }
    return index
      ? res
      : tempSplitups.reduce((acc, cur) => acc + cur.amount.amount, 0);
  };

  calculateAmount = () => {
    let { edit } = this.props?.editSplitup;
    let total = 0;
    let remaining;
    const { remainingValue } = this.props;
    const {
      overall,
      remaining_value,
      percentage,
      percentageSplitUp,
      value,
      tempSplitups,
    } = this.state;
    if (percentageSplitUp) {
      total = (percentage / 100) * overall;
    } else {
      total = +value ?? 0;
    }
    if (total < 0) total = 0;
    if (this.getRemainingCost() < 0)
      return this.props.toast(
        "danger",
        "Amount should not be greater than overall cost"
      );
    this.setState({ splitAmount: total, reducedAmount: total }, () => {});
  };

  formatAmount = (number) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(number);
  };

  formatNumber = (num) => {
    if (num % 1 === 0) {
      return num.toFixed(0);
    } else {
      return num.toFixed(2);
    }
  };

  getAmount = () => {
    const { overall, percentage, percentageSplitUp, value, edit } = this.state;
    return percentageSplitUp
      ? roundOff(overall * (percentage / 100)).toFixed(2)
      : value;
  };

  percentageAmount = () => {
    const { overall, percentage } = this.state;
    return overall - overall * (+percentage / 100);
  };

  getRemainingCost = () => {
    const {
      overall,
      remaining_value,
      tempSplitups,
      value,
      percentage,
      percentageSplitUp,
      splitUpIndex,
    } = this.state;
    const { edit } = this.props.editSplitup;
    if (edit && !tempSplitups.length) {
      return roundOff(overall - this.getAmount()).toFixed(2) < 0
        ? 0
        : roundOff(overall - this.getAmount()).toFixed(2);
    } else if (edit && tempSplitups.length === 1) {
      if (percentageSplitUp) {
        return percentageSplitUp
          ? (overall - (+percentage / 100) * overall).toFixed(2) < 0
            ? 0
            : (overall - (+percentage / 100) * overall).toFixed(2)
          : (overall - value).toFixed(2) < 0
          ? 0
          : (overall - value).toFixed(2);
      } else {
        return (overall - value).toFixed(2) < 0
          ? 0
          : (overall - value).toFixed(2);
      }
    } else if (edit && tempSplitups.length > 1) {
      const filtered = this.filteredSplitUp(splitUpIndex);
      return (
        overall -
        (
          +this.calculateEditSplitUpAmount(filtered) + +this.getAmount()
        ).toFixed(2)
      ).toFixed(2) < 0
        ? 0
        : (
            overall -
            (
              +this.calculateEditSplitUpAmount(filtered) + +this.getAmount()
            ).toFixed(2)
          ).toFixed(2);
    } else if (!edit && tempSplitups.length === 1) {
      if (percentageSplitUp) {
        return percentage
          ? (
              +overall -
              (+this.calculateSplitUpAmount() + +this.getAmount())
            ).toFixed(2) < 0
            ? 0
            : (
                +overall -
                (+this.calculateSplitUpAmount() + +this.getAmount())
              ).toFixed(2)
          : remaining_value.toFixed(2) < 0
          ? 0
          : remaining_value.toFixed(2);
      } else {
        return value
          ? (remaining_value - value).toFixed(2) < 0
            ? 0
            : (remaining_value - value).toFixed(2)
          : remaining_value.toFixed(2) < 0
          ? 0
          : remaining_value.toFixed(2);
      }
    } else if (!edit && tempSplitups.length > 1) {
      if (percentageSplitUp) {
        return percentageSplitUp
          ? (remaining_value - this.getAmount()).toFixed(2) < 0
            ? 0
            : (remaining_value - this.getAmount()).toFixed(2)
          : remaining_value.toFixed(2) < 0
          ? 0
          : remaining_value.toFixed(2);
      } else {
        return value
          ? (remaining_value - value).toFixed(2) < 0
            ? 0
            : Math.abs((remaining_value - value).toFixed(2))
          : (overall - this.calculateSplitUpAmount()).toFixed(2) < 0
          ? 0
          : (overall - this.calculateSplitUpAmount()).toFixed(2);
      }
    } else if (!edit && !tempSplitups.length) {
      if (percentageSplitUp) {
        return percentageSplitUp
          ? (overall - this.getAmount()).toFixed(2) < 0
            ? 0
            : (overall - this.getAmount()).toFixed(2)
          : remaining_value.toFixed(2) < 0
          ? 0
          : remaining_value.toFixed(2);
      } else {
        return value
          ? (remaining_value - value).toFixed(2) < 0
            ? 0
            : (remaining_value - value).toFixed(2)
          : remaining_value.toFixed(2) < 0
          ? 0
          : remaining_value.toFixed(2);
      }
    } else {
      return remaining_value.toFixed(2) < 0 ? 0 : remaining_value.toFixed(2);
    }
  };

  setMinDate = (current) => {
    return current && current < moment().add(-1, "days");
  };

  handleInputChange = (e) => {
    this.setState({ splitname: e }, () => {});
  };

  setDescritpion = (value) => {
    this.setState({ description: value });
  };

  checkForError = () => {
    let error = false;
    if (this.state.percentageSplitUp) {
    }
  };

  createSplitUp = async () => {
    const {
      splitname,
      due_date,
      splitAmount,
      overall,
      remaining_value,
      description,
      percentageSplitUp,
      percentage,
      btnLoad,
      value,
      tempSplitups,
    } = this.state;
    const amount = overall * (percentage / 100);
    if (!splitname) this.props.toast("danger", "Please enter name");
    else if (!due_date) this.props.toast("danger", "Please enter the due date");
    else if (!splitAmount) this.props.toast("danger", "Please enter the value");
    else if (
      +this.getRemainingCost() < 0 &&
      percentageSplitUp &&
      tempSplitups.length === 1
    ) {
      this.props.toast(
        "danger",
        "Given amount is greater than remaining value"
      );
    } else if (
      +this.getRemainingCost() < 0 &&
      percentageSplitUp &&
      amount > remaining_value &&
      remaining_value &&
      tempSplitups.length > 1
    ) {
      this.props.toast(
        "danger",
        "Given amount is greater than remaining value"
      );
    } else if (
      !percentageSplitUp &&
      +this.getRemainingCost() < 0 &&
      value > remaining_value
    ) {
      this.props.toast("danger", "Remaining Amount is lesser than 0");
    } else {
      this.setState({ btnLoad: true });
      let data = {
        name: splitname,
        due_date: due_date,
        amount: {
          totalCost: overall,
          remainingCost: remaining_value,
          amount: splitAmount,
          type: percentageSplitUp ? "percentage" : "fixed",
          value: percentageSplitUp ? percentage : value,
        },
        createdAt:new Date(),
        edit: this.state.edit,
        desc: description,
      };

      await this.props.populateObj(data);
      this.setState({ btnLoad: false });
    }
  };

  render() {
    let {
      splitUp,
      initPageLoading,
      name,
      percentageSplitUp,
      due_date,
      remaining_value,
      overall,
      percentage,
      remainingCost,
      amount,
      description,
      btnLoad,
      splitAmount,
      reducedAmount,
      value,
      splitname,
      splitup_id,
    } = this.state;
    return initPageLoading ? (
      <div className="spin-loading">
        <Spinner type="border" color="#077e8b" />
      </div>
    ) : (
      <>
        <div>
          <CardHeader style={{ backgroundColor: "#72569C" }}>
            <div className="flex">
              <div className="fw-500 f16" style={{ color: "white" }}>
                Add Schedule
              </div>
              <img
                src={closeIcon}
                onClick={() => this.props.close(false)}
                className="point"
                alt="Close"
              />
            </div>
          </CardHeader>
          <div className="cardContainer">
            <label className="customLabel">Name*</label>
            <CustomInputFields
              style={{ marginBottom: "20px" }}
              inputValue={splitname}
              type="text"
              change={this.handleInputChange}
              from="name"
              placeHolder="Enter the name of the schedule"
            />
            <label className="customLabel">Due On*</label>
            <CustomSingleDatePicker
              value={due_date}
              style={{ marginBottom: "20px" }}
              change={this.handleDueDate}
              setMinMaxDate={this.setMinDate}
            />
            <div style={{ display: "flex", marginBottom: "20px" }}>
              <div style={{ width: "100%" }}>
                <label className="customLabel">Value*</label>

                {/* <div style={{ display: "flex", gap: "10px" }}> */}
                <div style={{ display: "flex", width: "90%" }}>
                  <div className="percentageModule">
                    <UncontrolledDropdown
                      className="percentage_select_div"
                      group
                    >
                      <DropdownToggle
                        style={{
                          marginRight: "0px",
                          padding: "10px",
                          border: "1px solid #E6E6E6",
                        }}
                        className="drop_down_container"
                      >
                        <Media
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                          className="capitalize"
                        >
                          {percentageSplitUp ? "%" : "$"}
                          <i className="fas fa-angle-down"></i>
                        </Media>
                      </DropdownToggle>
                      <DropdownMenu>
                        {percentageSplitUp ? (
                          <DropdownItem
                            onClick={() =>
                              this.setState({
                                percentageSplitUp: 0,
                                splitAmount: 0,
                              })
                            }
                          >
                            $
                          </DropdownItem>
                        ) : (
                          <DropdownItem
                            onClick={() =>
                              this.setState({
                                percentageSplitUp: 1,
                                splitAmount: 0,
                              })
                            }
                          >
                            %
                          </DropdownItem>
                        )}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                  <div style={{ width: "100%" }}>
                    <Input
                      style={{
                        width: "100%",
                        height: "43px",
                        borderLeft: "transparent",
                      }}
                      className="percentageInputbar"
                      value={percentageSplitUp ? percentage : value}
                      name="lead"
                      id="email"
                      type="number"
                      // min={remaining_value}
                      onChange={(e) =>
                        percentageSplitUp
                          ? this.updatePercentage(e.target.value)
                          : this.setValue(e.target.value)
                      }
                    />
                  </div>
                </div>
              </div>
              <div style={{ width: "100%" }}>
                <label className="customLabel">Amount*</label>

                {/* <div
                  className="cl2A1E17 mb-15 f14 fw-400 drawer_sub_heading"
                  style={{ marginTop: "15px" }}
                >
                  Amount *
                </div> */}
                <div
                  style={{
                    alignItems: "center",
                    width: "50%%",
                  }}
                >
                  {/* <div style={{ marginRight: "10px" }}>$</div> */}
                  <div>
                    <Input
                      style={{
                        width: "100%",
                        height: "43px",
                        borderRadius: "5px",
                        // borderLeft: "transparent",
                      }}
                      className="percentageInputbar"
                      disabled={true}
                      name="lead"
                      // value={this.formatAmount(splitAmount)}
                      value={percentageSplitUp ? this.getAmount() : splitAmount}
                      id="amount"
                      type="text"
                    />
                  </div>
                </div>
              </div>
            </div>
            <label className="customLabel">Overall Cost*</label>
            <AmountInput
              style={{ marginBottom: "20px" }}
              amount={overall}
              disabled={true}
              change={() => {}}
            />
            <label className="customLabel">Remaining Cost*</label>
            <AmountInput
              style={{ marginBottom: "20px" }}
              // amount={
              //   reducedAmount
              //     ? roundOff(remaining_value - reducedAmount).toFixed(2)
              //     : roundOff(remaining_value).toFixed(2)
              // }
              amount={this.getRemainingCost()}
              disabled={true}
              change={() => {}}
            />

            <label className="customLabel">Description</label>
            <CustomTextArea
              inputValue={description}
              change={this.setDescritpion}
              from="notes"
              placeHolder="Enter the description"
            />

            <div style={{ marginTop: "30px" }} className="flexEnd">
              <Button_without_Border
                style={{ marginRight: "20px" }}
                btnLabel="cancel"
                click={() => this.props.close(false)}
              />
              <Custom_Button
                isPrimary
                btnLabel="save"
                click={this.createSplitUp}
                isLoading={btnLoad}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default AddSchedules;
