import React from 'react';
import Chart from 'react-apexcharts';

class ColumnChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        chart: {
          type: 'bar',
          toolbar: {
            show: false, // Hide the toolbar (including the menu icon)
          },
          contextMenu: {
            show: false, // Hide the context menu (including the menu icon in the top right corner)
          },
        },
        xaxis: {
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May','Jun','July','Aug','Sept','Oct','Nov','Dec'],
          
          axisTicks: {
            show: false, // Hide x-axis ticks
          },
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: false, // Hide data labels on bars
            },
            colors: {
              ranges: [{
                from: 0,
                to: 0,
                color: '#3B82F6', // Set your desired color for all bars
              }],
              hover: {
                color: '#3B82F6', // Set the same color for hover state
              },
            },
          },
        },
        tooltip: {
          enabled: false, // Hide tooltip on hover
        },
      },
      series: [
        {
          name: 'Sales',
          data: [30, 40, 35, 50, 49],
        },
      ],
    };
  }

  render() {
    return (
      <div>
        <Chart options={this.state.options} series={this.state.series} type="bar" height={500} />
      </div>
    );
  }
}

export default ColumnChart;
