import React, { Component } from "react";
import ApiService from "constants/ApiService";
import {
  filterQuery,
  returnThirdDegreeObjValue,
  returnFirstDegreeObjValue,
  showLocaldate,
  displayPrice,
} from "constants/utils";

import {
  Card,
  CardBody,
  CardHeader,
  Table,
  Spinner,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  DropdownMenu,
  DropdownItem,
  InputGroup,
  InputGroupText,
  Button,
  Input,
} from "reactstrap";
import { displayPercentage } from "constants/utils";
import closeIcon from "../../assets/img/icons/white_close_icon.svg";
import DateSvg from "../../assets/img/brand/date-pick.svg";
import Select from "react-select";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { CustomSingleDatePicker } from "components/FilterFields/Filterfields";
import { CustomDropDown } from "components/FilterFields/Filterfields";
import { CustomTextArea } from "components/FilterFields/Filterfields";
import { Button_without_Border } from "components/Buttons/Button";
import { Custom_Button } from "components/Buttons/Button";

class MarkPaymentSideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payDate: "",
      payMode: "online_transfer",
      notes: "",
      btnLoading: false,
      error: false,
    };
  }

  handleDateChange = (e) => {
    this.setState({ payDate: new Date(e) });
  };
  handleInputChange = (e) => {
    this.setState({ notes: e });
  };
  setPayMode = (mode) => {
    this.setState({ payMode: mode });
  };

  closeModal = () => {
    this.props.close();
    this.setState({ payMode: "online_transfer", payDate: "", notes: "" });
  };
  markPayment = async () => {
    let { payDate, payMode, notes } = this.state;
    let { toast, id } = this.props;
    if (payDate === "" || payDate === null || payDate === undefined) {
      toast("Please select Payment Date");
    } else if (payMode === "" || payMode === null || payMode === undefined) {
      toast("Please select Payment Mode");
    } else {
      this.setState({ btnLoading: true });
      let data = {
        mode: payMode,
        date: payDate,
        notes: notes,
      };
      let response = await ApiService.patchAPI(
        `contractor/invoice/paid/${id}`,
        data
      );
      this.setState({ btnLoading: false });
      if (response.success) {
        this.props.close(response.message);
        this.setState({
          payMode: "cash",
          payDate: "",
          notes: "",
        });
      } else {
        toast(response.message);
        this.props.toast(response.message);
      }
    }
  };
  minMaxStartDate = (current) => {
    const today = moment().startOf("day");
    const minDate = moment().subtract(62, "days").startOf("day");
    const maxDate = this.state.end_date
      ? moment(this.state.end_date)
      : moment();
    return current && (current < minDate || current > maxDate);
  };
  render() {
    let { payDate, payMode, notes, btnLoading, error } = this.state;

    return (
      <div>
        {this.props.isOpen && (
          <Card className="bnone">
            <CardHeader>
              <div className="flex">
                <div className="fw-500 f16" style={{ color: "white" }}>
                  Mark Payment
                </div>
                <img
                  src={closeIcon}
                  onClick={() => this.closeModal()}
                  className="point"
                  alt="close"
                />
              </div>
            </CardHeader>
            <CardBody className="pad24p ">
              <div style={{ marginBottom: "20px" }}>
                <div className="customLabel">Date *</div>
                <CustomSingleDatePicker
                  value={payDate}
                  change={this.handleDateChange}
                  error={error}
                  setMinMaxDate={this.minMaxStartDate}
                />
              </div>
              <div style={{ marginBottom: "20px" }}>
                <div className="customLabel">Mode of Payment *</div>

                <div className="invoiceStatusView">
                  <CustomDropDown
                    inputField
                    innerStyle={{ width: "100%" }}
                    width="100%"
                    setStatus={this.setPayMode}
                    status={payMode}
                    statusList={[
                      "online_transfer",
                      "cash",
                      "cheque",
                      "zelle",
                      "others",
                    ]}
                  />
                </div>
              </div>
              <div style={{ marginBottom: "20px" }}>
                <div className="customLabel">Notes</div>
                {/* <Input
                  type="textarea"
                  value={notes}
                  name="Notes"
                  rows={4}
                  onChange={this.handleInputChange}
                  className="mb-50"
                  maxLength={50}
                /> */}
                <CustomTextArea
                  change={this.handleInputChange}
                  inputvalue={notes}
                />
              </div>
              <div className="flexEnd">
                <Button_without_Border
                  style={{ marginRight: "20px" }}
                  btnLabel="cancel"
                  click={() => this.closeModal()}
                />
                <Custom_Button
                  isPrimary
                  btnLabel="mark_as_paid"
                  click={() => this.markPayment()}
                  isLoading={btnLoading}
                />
              </div>
            </CardBody>
          </Card>
        )}
      </div>
    );
  }
}

export default MarkPaymentSideBar;
